var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "confirmation-modal-overlay"
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.onCancel,
      expression: "onCancel"
    }],
    staticClass: "confirmation-modal"
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_vm.textStartIcon ? _c('img', {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      "src": require(`@/assets/images/${_vm.textStartIcon}`),
      "alt": ""
    }
  }) : _vm._e(), _vm._t("default")], 2), _c('div', {
    staticClass: "confirmation-modal-buttons-container"
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "label": _vm.$t('Cancel')
    },
    on: {
      "click": _vm.onCancel
    }
  }), _c('BaseButton', {
    attrs: {
      "primary": true,
      "size": "large",
      "label": _vm.$t('Confirm')
    },
    on: {
      "click": _vm.onConfirm
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }