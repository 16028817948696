<template>
  <div class="user-info-container">
    <div class="user-info" @click="toggleMenu">
      <div class="user-icon">
        <span class="font-20-medium">
          {{ user.name.charAt(0).toUpperCase() }}
        </span>
      </div>
      <span class="user-name">
        {{ user.name }}
      </span>
      <img src="../../assets/images/chevron_down_20_20.svg" alt=""/>
    </div>

    <div class="info-menu"
         v-if="dropdownOpen"
         v-click-outside="closeMenu"
    >
      <div
          v-for="(item, index) in menuItems"
          :key="'menu-item-' + index"
          class="menu-item"
          @click="() => selectItem(item.name)"
      >
        <img
            :src="require(`../../assets/images/${item.iconUrl}`)"
            alt=""
            class="img16"
        />

        <div class="content">
          {{ item.field }}
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import Cookies from "js-cookie";

export default {
  name: "Info",
  components: {
  },
  data() {
    return {
      dropdownOpen: false,
      menuItems: [
        {name: 'settings', iconUrl: 'admin.svg', field: `${this.$t('MyDetails')}`},
        {name: 'logout', iconUrl: 'log-out.svg', field: `${this.$t('Logout')}`},
      ],
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    ...mapActions(["logout"]),
    toggleMenu() {
      this.dropdownOpen = !this.dropdownOpen
    },
    closeMenu() {
      this.dropdownOpen = false;
    },
    selectItem(itemName) {
      switch (itemName) {
        case "settings":
          this.$router.push({name: "BrokerSettings"});
          break;
        case "logout":
          this.logOut();
          break;
      }
      this.closeMenu()
    },
    logOut() {
      this.logout();
      this.$router.push("/login");
      Cookies.remove("token");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.user-info-container {
  display: flex;
  justify-content: flex-end;
}

.menu-item {
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-self: stretch;
  padding: 12px 16px;
  align-items: center;
}
.info-menu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: absolute;
  margin-top: 45px;
  max-height: 300px;
  overflow: hidden;
  z-index: 20;
  background-color: white;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
  0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}


.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 8px;
  height: 32px;

  &:hover {
    cursor: pointer;
  }
}

.user-icon {
  border-radius: 50%;
  padding: 0 4px;
  background: #000000;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-name {
  color: black;
  font-size: 14px;
  overflow-wrap: break-word;
  margin-right: auto;
}

</style>