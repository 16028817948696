<template>
  <div class="tasks-and-events-container">
    <div
      v-if="showToggleButton"
      :class="{ 'tasks-header': tasks.length > 0 || showNoTasksMessage }"
    >
      <BaseToggle v-model="showCompletedTasks" :label="$t('ShowCompleted')" />
    </div>
    <div
      :key="'tasks-' + tasksKeyUpdater"
      v-if="tasksLoaded && tasks.length > 0"
      class="tasks-content"
    >
      <div
        v-if="sortedTasks[findTaskCollectionByOrder(1)[0]].length > 0"
        class="tasks-content-section"
        :class="{
          'section-label-present-bottom':
            findTaskCollectionByOrder(1)[1].label &&
            !findTaskCollectionByOrder(2)[1].label,
        }"
      >
        <div v-if="findTaskCollectionByOrder(1)[1].label" class="label12">
          {{ $t(`${returnLabelTranslation(findTaskCollectionByOrder(1)[0])}`) }}
        </div>
        <div
          v-for="task of sortedTasks[findTaskCollectionByOrder(1)[0]]"
          :key="task._id"
        >
          <Task
            @taskUpdated="emitNewTasks"
            @addParticipants="
              (value) => openTaskAddWidget(value, 'participants')
            "
            @openTaskViewWidget="openTaskViewWidget"
            :task="task"
            :end-date-passed="
              findTaskCollectionByOrder(1)[0] === 'overDueTasks'
            "
          />
        </div>
      </div>
      <div
        v-if="sortedTasks[findTaskCollectionByOrder(2)[0]].length > 0"
        class="tasks-content-section"
        :class="{
          'section-label-present': findTaskCollectionByOrder(2)[1].label,
          'section-label-present-bottom':
            findTaskCollectionByOrder(2)[1].label &&
            !findTaskCollectionByOrder(3)[1].label,
        }"
      >
        <div v-if="findTaskCollectionByOrder(2)[1].label" class="label12">
          {{ $t(`${returnLabelTranslation(findTaskCollectionByOrder(2)[0])}`) }}
        </div>
        <div
          v-for="task of sortedTasks[
            Object.entries(labelsAndOrder).find(
              (item) => item[1].order === 2
            )[0]
          ]"
          :key="task._id"
        >
          <Task
            @taskUpdated="emitNewTasks"
            @addParticipants="
              (value) => openTaskAddWidget(value, 'participants')
            "
            @openTaskViewWidget="openTaskViewWidget"
            :task="task"
            :end-date-passed="
              Object.entries(labelsAndOrder).find(
                (item) => item[1].order === 2
              )[0] === 'overDueTasks'
            "
          />
        </div>
      </div>
      <div
        v-if="sortedTasks[findTaskCollectionByOrder(3)[0]].length > 0"
        class="tasks-content-section"
        :class="{
          'section-label-present': findTaskCollectionByOrder(3)[1].label,
          'section-label-present-bottom':
            findTaskCollectionByOrder(3)[1].label &&
            !findTaskCollectionByOrder(4)[1].label,
        }"
      >
        <div v-if="findTaskCollectionByOrder(3)[1].label" class="label12">
          {{ $t(`${returnLabelTranslation(findTaskCollectionByOrder(3)[0])}`) }}
        </div>
        <div
          v-for="task of sortedTasks[findTaskCollectionByOrder(3)[0]]"
          :key="task._id"
        >
          <Task
            @taskUpdated="emitNewTasks"
            @addParticipants="
              (value) => openTaskAddWidget(value, 'participants')
            "
            @openTaskViewWidget="openTaskViewWidget"
            :task="task"
            :end-date-passed="
              findTaskCollectionByOrder(3)[0] === 'overDueTasks'
            "
          />
        </div>
      </div>
      <div
        v-if="sortedTasks[findTaskCollectionByOrder(4)[0]].length > 0"
        class="tasks-content-section"
        :class="{
          'section-label-present': findTaskCollectionByOrder(4)[1].label,
        }"
      >
        <div v-if="findTaskCollectionByOrder(4)[1].label" class="label12">
          {{ $t(`${returnLabelTranslation(findTaskCollectionByOrder(4)[0])}`) }}
        </div>
        <div
          v-for="task of sortedTasks[findTaskCollectionByOrder(4)[0]]"
          :key="task._id"
        >
          <Task
            @taskUpdated="emitNewTasks"
            @addParticipants="
              (value) => openTaskAddWidget(value, 'participants')
            "
            @openTaskViewWidget="openTaskViewWidget"
            :task="task"
            :end-date-passed="
              findTaskCollectionByOrder(4)[0] === 'overDueTasks'
            "
          />
        </div>
      </div>
    </div>
    <div
      v-if="tasksLoaded && tasks.length < 1 && showNoTasksMessage"
      class="tasks-content-empty"
    >
      <div class="subheading">
        {{ $t("NoTasksEvents") }}
      </div>
      <div class="content-small">
        {{ $t("Click") }}
        <span style="font-weight: bold; padding: 0 4px">
          {{ $t("Add") }}
        </span>
        {{ $t("ToAddNewTaskOrEvent") }}
      </div>
    </div>
    <div v-if="!tasksLoaded" class="tasks-content-empty">
      <div class="loader"></div>
    </div>
    <TaskAddWidget
      @closeWidget="closeTaskAddWidget"
      @getTasks="getTasks"
      v-if="taskAddWidgetOpen"
      @clearFocusField="focusOnTaskAddField = null"
      :focus-field="focusOnTaskAddField"
      :new-task-time="taskAddWidgetTaskTime"
      :linked-object="{
        name: view.name,
        id: view.itemId,
        itemName: view.itemName,
      }"
      :task-to-edit="taskToEdit"
    />
    <TaskViewWidget
      @taskUpdated="emitNewTasks"
      :task="taskViewWidgetValue"
      @closeWidget="closeTaskViewWidget"
      @openProjectSidepanel="openProject"
      @openContactSidepanel="openContactSidepanel"
      @addParticipants="(value) => openTaskAddWidget(value, 'participants')"
      @openDeleteModal="openDeleteModal"
      @openTask="openParentTask"
      @editTask="openTaskAddWidget"
      v-if="taskViewWidgetOpen && !isDeleteModalOpen"
    />
    <DeleteModal
      @canceled="isDeleteModalOpen = false"
      @approved="deleteTask"
      v-if="isDeleteModalOpen"
    />
    <ClientSidepanel
      @updated="getTasks"
      :name="view.name"
      v-if="selectedContactId"
      :customer-data="selectedContactId"
      @panelClosed="selectedContactId = null"
    />
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import taskTypes from "@/components/CalendarView/taskTypes";
import { mapGetters } from "vuex";

export default {
  name: "BaseTasksAndEvents",
  components: {
    DeleteModal: () => import("@/components/common/DeleteModal"),
    TaskAddWidget: () => import("@/components/CalendarView/TaskAddWidget"),
    TaskViewWidget: () => import("@/components/CalendarView/TaskViewWidget"),
    BaseToggle: () => import("@/components/common/BaseToggle"),
    Task: () => import("@/components/CalendarView/Task"),
    ClientSidepanel: () =>
      import("@/components/Kliendiregister/ClientSidepanel.vue"),
  },
  props: {
    showNoTasksMessage: {
      type: Boolean,
      default: true,
    },
    showToggleButton: {
      type: Boolean,
      default: false,
    },
    labelsAndOrder: {
      type: Object,
      default: () => {
        return {
          overDueTasks: {
            label: true,
            order: 1,
          },
          noDueDateTasks: {
            label: true,
            order: 2,
          },
          todayTasks: {
            label: true,
            order: 3,
          },
          upcomingTasks: {
            label: true,
            order: 4,
          },
        };
      },
    },
    view: {
      name: { type: String },
      itemId: { type: String },
      itemName: { type: String },
      required: true,
      type: Object,
    },
    openNewTaskAddWidget: Number,
  },
  data() {
    return {
      taskAddWidgetTaskTime: null,
      showCompletedTasks: false,
      tasksLoaded: true,
      tasks: [],
      sortedTasks: {
        overDueTasks: [],
        noDueDateTasks: [],
        todayTasks: [],
        upcomingTasks: [],
      },
      tasksKeyUpdater: 0,
      taskViewWidgetOpen: false,
      taskViewWidgetValue: null,
      taskAddWidgetOpen: false,
      taskToEdit: null,
      focusOnTaskAddField: null,
      isDeleteModalOpen: false,
      selectedContactId: null,
      allowCloseTaskViewWidget: true,
    };
  },
  created() {
    this.tasksLoaded = false;
    this.initialize();
  },
  watch: {
    tasks() {
      this.divideTasksToGroups(this.tasks);
      this.emitNewTasks();
      this.tasksKeyUpdater++;
    },
    async showCompletedTasks() {
      await this.getTasks();
      this.divideTasksToGroups(this.tasks);
    },
    openNewTaskAddWidget() {
      if (!this.taskAddWidgetOpen) {
        this.openTaskAddWidget();
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    initialize() {
      this.getTasks();
    },
    emitNewTasks() {
      this.$emit("tasks", this.tasks);
    },
    async getTasks() {
      if (this.view.name === "dashboard") {
        await axios
          .get(`/api/tasks/active/assignee/${this.user._id}`)
          .then((response) => {
            if (
              response &&
              response.status &&
              response.status === 200 &&
              response.data
            ) {
              this.tasks = response.data;
              this.getSharedResources();
            }
          })
          .catch((e) => {
            console.error(e);
          });
        return;
      }

      let requestString;
      if (this.showCompletedTasks) {
        requestString = `/api/tasks/${this.view.name}/${this.view.itemId}`;
      } else {
        requestString = `/api/tasks/${this.view.name}/unfinished/${this.view.itemId}`;
      }
      await axios
        .get(requestString)
        .then((response) => {
          if (response && response.status === 200) {
            this.tasks = response.data;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    findTaskCollectionByOrder(orderNumber) {
      return Object.entries(this.labelsAndOrder).find(
        (item) => item[1].order === orderNumber
      );
    },
    async openParentTask(taskId) {
      const response = await axios.get(`/api/task/${taskId}`);
      if (response && response.data) {
        this.openTaskViewWidget(response.data)
      }
    },
    returnLabelTranslation(value) {
      switch (value) {
        case "overDueTasks":
          return "Overdue";
        case "noDueDateTasks":
          return "NoDueDate";
        case "todayTasks":
          return "Today";
        case "upcomingTasks":
          return "DateNext";
      }
    },
    openContactSidepanel(contactId) {
      if (this.view.name === "contact") {
        return;
      }
      this.selectedContactId = contactId;
    },
    async openProject(projectId) {
      if (this.view.name === "project") {
        return;
      }
      await axios.get(`/api/project/${projectId}`).then((response) => {
        if (response && response.status === 200) {
          this.$router.push({
            path: `/projects/${projectId}`,
            name: "dealDetail",
          });
          location.reload();
        } else if (response.status === 403) {
          this.toastError(this.$t("noProjectAccess"));
        }
      })
    },
    divideTasksToGroups(tasks) {
      this.tasksLoaded = false;
      this.clearTaskGroups();
      tasks.forEach((task) => {
        if (task.dates.endDate) {
          const isTaskOverdue = this.isTaskOverdue(task);
          if (isTaskOverdue) {
            this.sortedTasks.overDueTasks.push(task);
            return;
          }
          if (this.isToday(task)) {
            this.sortedTasks.todayTasks.push(task);
          } else {
            this.sortedTasks.upcomingTasks.push(task);
          }
        } else {
          this.sortedTasks.noDueDateTasks.push(task);
        }
      });
      this.sortTasks();
      this.tasksLoaded = true;
    },
    isTaskOverdue(task) {
      const currentDateTime = moment();
      return moment(task.dates.endDate).isBefore(currentDateTime);
    },
    isToday(task) {
      const startOfToday = moment().startOf("day").format();
      const endOfToday = moment().endOf("day").format();
      return !!(
        moment(task.dates.endDate).isSameOrAfter(startOfToday) &&
        moment(task.dates.endDate).isSameOrBefore(endOfToday)
      );
    },
    clearTaskGroups() {
      this.sortedTasks.overDueTasks = [];
      this.sortedTasks.noDueDateTasks = [];
      this.sortedTasks.upcomingTasks = [];
      this.sortedTasks.todayTasks = [];
    },
    sortTasks() {
      this.sortedTasks.overDueTasks = this.sortedTasks.overDueTasks.sort(
        (a, b) =>
          moment(a.dates.endDate).valueOf() - moment(b.dates.endDate).valueOf()
      );
      this.sortedTasks.upcomingTasks = this.sortedTasks.upcomingTasks.sort(
        (a, b) =>
          moment(a.dates.endDate).valueOf() - moment(b.dates.endDate).valueOf()
      );
      this.sortedTasks.todayTasks = this.sortedTasks.todayTasks.sort(
        (a, b) =>
          moment(a.dates.endDate).valueOf() - moment(b.dates.endDate).valueOf()
      );
      this.sortedTasks.noDueDateTasks =
        this.sortedTasks.noDueDateTasks.reverse();
    },
    openTaskViewWidget(value) {
      window.addEventListener("click", this.taskClickListener);
      this.taskViewWidgetOpen = true;
      this.taskViewWidgetValue = value;
    },
    closeTaskViewWidget() {
      window.removeEventListener("click", this.taskClickListener);
      if (
        this.selectedContactId ||
        !this.allowCloseTaskViewWidget ||
        this.taskAddWidgetOpen
      ) {
        this.allowCloseTaskViewWidget = true;
        this.taskViewWidgetOpen = false;
        return;
      }
      this.taskViewWidgetOpen = false;
      this.taskViewWidgetValue = null;
    },
    openTaskAddWidget(task = undefined, focusField = null) {
      if (task) {
        this.taskToEdit = task;
        if (focusField) {
          this.focusOnTaskAddField = focusField;
        }
        this.closeTaskViewWidget();
        this.taskAddWidgetTaskTime = null;
      } else {
        const currentDate = moment().add(30, 'm')
        let startDate = moment(currentDate);
        startDate = moment(this.roundTime(startDate))
        let endDate = moment(currentDate);
        endDate = moment(this.roundTime(endDate, false))
        const date = {
          startTime: startDate.format("HH:mm"),
          endTime: endDate.format("HH:mm"),
          startDate: startDate,
          endDate: endDate,
        }
        this.taskAddWidgetTaskTime = date;
      }
      this.taskAddWidgetOpen = true;
    },
    roundTime(time, down = true) {
      const roundTo = 60; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
          ? time - (time % roundDownTime)
          : time + (roundDownTime - (time % roundDownTime));
    },
    async closeTaskAddWidget() {
      this.taskAddWidgetOpen = false;
      if (this.taskToEdit) {
        await this.getTasks();
        this.openTaskViewWidget(
          this.tasks.find((task) => task._id === this.taskToEdit._id)
        );
      }
      this.taskToEdit = undefined;
    },
    openDeleteModal() {
      this.isDeleteModalOpen = true;
    },
    async getSharedResources() {
      await axios.get("/api/admin/get-shared-resources").then((response) => {
        if (
          response &&
          response.status &&
          response.status === 200 &&
          response.data
        ) {
          const taskType = taskTypes.find(
            (taskType) => taskType.value === "resource"
          );
          taskType.dropdownItems = response.data;
        }
      });
    },
    async deleteTask() {
      let msToken = null;
      if (this.user.microsoft) {
        const access = await this.getMsalAccessToken();
        if (access) {
          msToken = access.accessToken;

        }
      }
      await axios
        .post(`/api/task/delete/${this.taskViewWidgetValue._id}`, {
          accessToken: msToken,
        })
        .then((response) => {
          if (response && response.status === 200) {
            this.toastSuccess(this.$t("Deleted"));
            this.closeTaskViewWidget();
            this.isDeleteModalOpen = false;
            this.getTasks();
          } else {
            this.toastError(this.$t("ErrorDeleting"));
          }
        })
        .catch(() => {
          this.toastError(this.$t("ErrorDeleting"));
        });
    },
    taskClickListener(event) {
      const path = event.path || (event.composedPath && event.composedPath());
      let anyPathTrue = false;
      path.forEach((innerPath) => {
        if (
          innerPath.classList &&
          innerPath.classList.contains("task-container")
        ) {
          anyPathTrue = true;
        }
      });
      if (anyPathTrue) {
        this.allowCloseTaskViewWidget = false;
      } else {
        this.allowCloseTaskViewWidget = true;
      }
    },
  },
};
</script>

<style scoped>
.tasks-and-events-container {
  display: flex;
  flex-direction: column;
}

.tasks-header {
  margin-bottom: 24px;
}

.tasks-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 8px;
}

.tasks-content-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.section-label-present {
  margin-top: 24px;
}

.section-label-present-bottom {
  margin-bottom: 32px;
}

.tasks-content-empty {
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  height: 100%;
  align-items: center;
}
</style>
