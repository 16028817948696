import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en.json";
import et from "./et.json";

const defaultLocale = "et";
const messages = {
  en: en,
  et: et,
};

Vue.use(VueI18n);
export default new VueI18n({
  locale: defaultLocale,
  fallbackLocale: "et",
  messages,
  silentTranslationWarn: true,
  silentFallbackWarn: false,
});
