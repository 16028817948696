var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function () {
        return _vm.isOpen = false;
      },
      expression: "() => (isOpen = false)"
    }],
    staticClass: "main",
    on: {
      "focusin": _vm.handleFocus
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "background": "white"
    },
    attrs: {
      "prepend-inner-icon": "mdi-magnify",
      "autocomplete": "off",
      "label": _vm.placeholder,
      "error": _vm.errorKey,
      "outlined": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "focusin": function ($event) {
        _vm.isOpen = true;
      },
      "click:clear": function ($event) {
        return _vm.clearSelection();
      }
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm.items.length > -1 && _vm.isOpen ? _c('div', {
    staticClass: "items"
  }, [_c('div', {
    staticClass: "item-wrapper"
  }, [_c('div', {
    key: _vm.resultsUpdater
  }, [_vm.search && _vm.searchResults.length > 0 ? _c('div', _vm._l(_vm.searchResults, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      on: {
        "click": function ($event) {
          return _vm.chooseResultItem(item);
        }
      }
    }, [_c('p', [_vm._v(" " + _vm._s(item[_vm.nameKey]) + " ")])]);
  }), 0) : _vm.search && _vm.searchResults.length === 0 ? _c('div', [_c('p', {
    staticClass: "no-results"
  }, [_vm._v(_vm._s(_vm.$t("NoResultsFound")))])]) : _c('div', _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      on: {
        "click": function ($event) {
          return _vm.chooseResultItem(item);
        }
      }
    }, [_c('p', [_vm._v(" " + _vm._s(item[_vm.nameKey]) + " ")])]);
  }), 0)])])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }