<template>
  <div class="overlay" id="overlay-development">
    <div id="panel-wrapper-development" class="modal-wrapper">
      <div
        @click="closeContactAdd"
        style="padding-left: 24px"
        id="wrapped-header-development"
      >
        <h4 style="align-self: center">
          {{ isEdit ? $t("ChangeDevelopment") : $t("AddNewDevelopment") }}
        </h4>
      </div>
      <div
        class="content-overlay"
        style="height: 100%; display: flex; flex-direction: column"
        id="content-wrapper"
        v-if="loaded"
      >
        <div class="sticky-header" style="z-index: 126">
          <v-row class="top-row" style="height: 24px; padding-left: 32px"
            ><h4 v-if="!isEdit" style="height: 24px">
              {{ $t("AddNewDevelopment") + ' ' + $t("Project").toLowerCase() }}
            </h4>
            <h4 v-if="isEdit" style="height: 24px">
              {{ $t("ChangeDevelopment") }}
            </h4>
          </v-row>
        </div>
        <div
          style="
            padding: 24px 32px 128px 32px;
            height: 100%;
            overflow-y: scroll;
          "
        >
          <v-row style="padding-bottom: 12px">
            <h6>{{ $t("GeneralInformation") }}</h6>
          </v-row>
          <v-row style="gap: 8px">
            <BaseInput
              :required="true"
              :id="'property-developer-name-field'"
              :placeholder="$t('ProjectName')"
              v-model="form.projectName"
            />

            <v-row
          >
            <div
                v-if="user.access.company._id !== 'xmwei_Rt9ryfpkxL4BL8'"
              style="
                display: flex;
                margin-top: 16px;
                margin-bottom: 16px;
                flex-direction: column;
              "
            >
              <div class="content-bold">{{ $t("AddressType") }}</div>
              <div class="radio-group-label" style="margin-top: 16px">
                <v-radio-group v-model="form.addressType" row>
                  <v-radio
                    v-if="!user.isItaly"
                    id="address-type-est"
                    @click="switchAddressType"
                    color="#FF5C01"
                    value="estonia"
                    class="radio-button"
                    :label="$t('Estonian')"
                  ></v-radio>
                  <v-radio
                    id="address-type-generic"
                    @click="switchAddressType"
                    color="#FF5C01"
                    value="generic"
                    class="radio-button"
                    :label="
                      $i18n.locale === 'et'
                        ? $t('ForeignCountry')
                        : $t('Generic')
                    "
                  ></v-radio>
                </v-radio-group>
                <div
                  class="disclaimer-row"
                  v-if="form.addressType === 'generic' && $i18n.locale === 'et'"
                >
                  <img src="../../assets/images/infoMark20.svg" alt="" />
                  <p class="content-small">
                    Välismaa aadressiga tehtud kuulutust ei saa eksportida
                    kinnisvara portaali
                  </p>
                </div>
              </div>
            </div>
            <div style="z-index: 125; width: 100%">
              <AddressFinder
                v-if="form.addressType === 'estonia'"
                ref="addressFinder"
                :object="form.address ? form.address : ''"
                :placeholder="$t('Address')"
                :show-error="showErrors && !isFieldValid('object')"
                :error-message="errors && errors.object ? errors.object : ''"
                @setObject="setAddressObject"
                @removeObject="removeAddressObject"
                @loaded="loadedAF"
              />
              <div v-if="listingAddressToggle" class="transfer-listings-addresses">
                <BaseToggle
                  :label="$t('ChangeListingsAddress')"
                  v-model="changeListingAddress"
                  type="number"
                />
              </div>
            </div>
            <div
              v-if="form.addressType === 'generic'"
              style="width: 100%"
            >
              <GoogleAddressSearch
                v-model="googleFormObjects"
                :editMode="true"
                :id="'project-generic-address-search'"
                :placeholder="$t('Address')"
                required="true"
                @change="setAddressObject"
                :return-object="true"
                :error="showErrors && !isFieldValid('object')"
                :error-message="errors && errors.object ? errors.object : ''"
              />
            </div>
          </v-row>
            
           <!--  <AddressFinder
              :object="form.address ? form.address : ''"
              :placeholder="$t('Address')"
              :show-error="showErrors && !isFieldValid('object')"
              :error-message="errors && errors.object ? errors.object : ''"
              ref="addressFinder"
              @setObject="setAddressObject"
              @removeObject="removeAddressObject"
              @loaded="loadedAF"
            /> -->
          </v-row>

          <v-row style="gap: 8px; margin-top: 8px">
            <BaseDropdown
              :required="true"
              :id="'property-developer-currency-field'"
              v-model="form.assetType"
              :items="assetTypes"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="true"
              :placeholder="$t('DealType1')"
              :type="'dropdown'"
            />
            <BaseDropdown
              :required="true"
              :id="'property-developer-currency-field'"
              v-model="form.assetClass"
              :items="assetClasses"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="true"
              :placeholder="$t('EstateType')"
              :type="'dropdown'"
            />
         <!--    <BaseInput
              :id="'property-developer-project-website'"
              :placeholder="$t('ProjectWebsite')"
              v-model="form.projectWebsite"
            />
            <textarea
              :id="'project-developer-description'"
              v-model="form.description"
              :placeholder="$t('Description')"
            /> -->
          </v-row>

          <v-row style="margin-top: 32px">
            <h6>{{ $t("Contact") }}</h6>
            <ClientListSearch
              style="margin-top: 24px"
              id="project-contact-search"
              :placeholder="$t('Contact')"
              :icon-left="'clients.svg'"
              v-model="form.contactPerson"
              @addNew="openContactAdd"
              @itemChosen="contactPersonSelected"
              :required="true"
              :update-component="updateSelectedContact"
              :error="showErrors && !isFieldValid('contactPerson')"
              :error-message="
                errors ? $t('FormErrors.' + errors.contactPerson) : ''
              "
            >
            </ClientListSearch>
          </v-row>
          <v-row v-if="form.contactPerson" style="padding-bottom: 12px">
            <div
              style="padding-bottom: 12px; padding-top: 12px; font-weight: 600"
            >
              {{ $t("Client") }}
            </div>
            <ClientSelection
              :isEdit="isEdit"
              :existingClient="initialClient"
              :projectContact="form.contactPerson"
              :contactId="form.contactPerson.contactId"
              :newProjectClient="newProjectClient"
              @openAddPanel="addNewPrivateContact"
              @clientSelected="setProjectClient"
            />
          </v-row>

          <v-row style="margin-top: 32px">
            <h6>{{ $t("Image") }}</h6>
          </v-row>
          <v-row style="margin-top: 24px; gap: 12px">
            <UploadImages
              v-if="!isEdit"
              :Imgs="[]"
              :upload-msg="$t('UploadImage')"
              @changed="putImages($event)"
              class="image-uploader"
            />
            <UploadImages
              v-if="isEdit"
              :Imgs2="form.images"
              class="image-uploader"
              :upload-msg="$t('UploadImage')"
              @changed="updateImages($event)"
            />
          </v-row>
        </div>
        <div class="modal-footer">
          <div class="buttons-row" style="padding-left: 24px">
            <button
              v-if="isEdit && hasAccess(form, 'developments', 'delete')"
              class="delete-button"
              color="blue darken-1"
              text
              @click="isDeleteModalOpen = true"
            >
              <img
                style="padding-right: 8px"
                src="../../assets/images/TrashBin.svg"
                alt=""
              />
              {{ $t("Delete") }}
            </button>
            <button
              v-if="!isEdit"
              @click="changeSidepanelStateDevelopments(0)"
              class="cancel"
            >
              {{ $t("Cancel") }}
            </button>
            <button v-if="isEdit" @click="closeModal()" class="cancel">
              {{ $t("Cancel") }}
            </button>
            <button
              v-if="isEdit"
              class="save-button"
              color="blue darken-1"
              text
              @click="updateDevelopment()"
            >
              <img
                style="padding-right: 8px"
                src="../../assets/images/Submit.svg"
                alt=""
              />
              {{ $t("Submit") }}
            </button>
            <button
              v-if="!isEdit"
              class="save-button"
              @click="saveDevelopment()"
            >
              <img
                style="padding-right: 8px"
                src="../../assets/images/Submit.svg"
                alt=""
              />
              {{ $t("Submit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <AddModal
      style="z-index: 120"
      @newAdded="chooseNewClient"
      @closed="closeContactAdd"
      :accordion="true"
      :not-contact-view-route="true"
      v-if="clientAddOpen"
    />
    <DeleteModal
      :removing="false"
      @canceled="isDeleteModalOpen = false"
      @approved="deleteDevelopment"
      v-if="isDeleteModalOpen"
    />
    <ConfirmationModal
      v-if="openAddressConfirmation && isEdit"
      @onCancel="handleListingAddressCancel"
      @onConfirm="handleListingAddress()"
    >
      <div class="confirmation-wrapper">
        <div class="confirmation-header">
          <img
            style="margin-right: 8px"
            :src="require(`@/assets/images/info.svg`)"
            alt=""
          />
          <div>
            {{ $t("ListingsAddresses") }}
          </div>
        </div>
        <div class="new-shared-broker">
          {{ newAddress.address }}
        </div>
        <div>
          <div>
            <BaseToggle
              :label="$t('ChangeListingsAddress')"
              v-model="changeListingAddress"
              type="number"
            />
          </div>
        </div>
      </div>
    </ConfirmationModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import nanoid from "nanoid";
import ClickOutside from "vue-click-outside";
import DeleteModal from "@/components/common/DeleteModal";
import UploadImages from "../../components/common/vue-upload-drop-images/dist/vue-upload-drop-images";
import BaseDropdown from "@/components/common/BaseDropdown";
import BaseInput from "@/components/common/BaseInput";
// import BaseTextarea from "@/components/common/BaseTextarea";
import AddressFinder from "@/components/Deals/AddressFinder";
import ClientListSearch from "@/components/common/ClientListSearch.vue";
import AddModal from "@/components/Kliendiregister/AddModal.vue";
import moment from "moment";
import ClientSelection from "@/components/common/ClientSelection.vue";
import contactApi from "@/http/contact";
import GoogleAddressSearch from "@/components/common/GoogleAddressSearch";
import ConfirmationModal from "@/components/common/ConfirmationModal.vue";
import BaseToggle from "@/components/common/BaseToggle.vue";

export default {
  components: {
    UploadImages,
    DeleteModal,
    BaseDropdown,
    BaseInput,
    // BaseTextarea,
    AddressFinder,
    ClientListSearch,
    AddModal,
    ClientSelection,
    GoogleAddressSearch,
    ConfirmationModal,
    BaseToggle
  },
  name: "SidepanelAdd",
  props: ["developmentData", "isEdit", "noRefresh", "fromAnotherPanel"],
  directives: {
    ClickOutside,
  },
  data() {
    return {
      loaded: false,
      showErrors: false,
      clientAddOpen: false,
      isDeleteModalOpen: false,
      noteContent: "",
      validate: ["name", "object"],
      assetTypes: [
        { name: this.$t("Sale"), value: "sale" },
        { name: this.$t("LeaseOut"), value: "LeaseOut" },
      ],
      assetClasses: [
        { name: this.$t("ApartmentBuilding"), value: "apartment" },
        { name: this.$t("House"), value: "house" },
        { name: this.$t("HousePart"), value: "house_part" },
        { name: this.$t("CommercialSpace"), value: "commercial" },
        { name: this.$t("LandPlot"), value: "land" },
      ],
      dateChanged: false,
      errors: null,
      object: null,
      form: {
        addressType: "estonia",
        projectName: "",
        address: null,
        price: null,
        propertySize: null,
        closedNetArea: null,
        businessId: null,
        assetType: null,
        assetClass: null,
        projectWebsite: null,
        images: [],
        description: "",
        contactPerson: null,
      },
      initialClient: null,
      updateSelectedContact: 0,
      newProjectClient: null,
      addFromClientSelection: false,
      changeListingAddress: true,
      openAddressConfirmation: false,
      newAddress: "",
      isAddressTransfer: false,
      listingAddressToggle: false
    };
  },
  created() {
    //oli mountedis
    //this.mapToFormData();
    if (this.user.access.company._id === 'xmwei_Rt9ryfpkxL4BL8') {
      this.form.addressType = "generic";
    }
    if (this.isEdit) {
      this.mapToFormData();
    }
  },
  mounted() {
    this.hideOverlay();
    this.errors = this.getEmptyErrors();
    this.mapToFormData();
    this.loaded = true;
  },
  watch: {
    'developmentData.address': {
      handler(newAddress) {
        if (newAddress) {
          this.newAddress = newAddress;
          this.openAddressConfirmation = true;
        }
      },
    },
  },
  beforeDestroy() {
    if (!this.fromAnotherPanel) {
      document.documentElement.style.overflow = "visible";
    }
  },
  computed: {
    ...mapGetters(["user", "customers"]),
    googleFormObjects() {
      if (this.isEdit) {
        return this.form.address;
      } else {
        return this.form.address;
      }
    },
  },
  methods: {
    ...mapActions(["changeSidepanelStateDevelopments"]),
    closeModal() {
      this.$emit("closed");
      this.changeSidepanelStateDevelopments(0);
    },
    handleListingAddress() {
      if (this.changeListingAddress) {
        this.isAddressTransfer = true
      }
      this.openAddressConfirmation = false;
      this.listingAddressToggle = true;
    },
    handleListingAddressCancel() {
      this.openAddressConfirmation = false;
      this.listingAddressToggle = true;
    },
    async transferListingAddress() {
      let payload = {
        linkedDevelopment: this.form._id,
        newAddress: this.newAddress
      }
      await axios.post("/api/listing/transfer-address/developments",  payload );
    },
    switchAddressType() {
      if (!this.isEdit) {
        this.form.address = null;
      } else {
        this.form.address = null;
      }
    },
    async loadedAF() {
      if (this.isEdit) {
        let address = this.form.address ? this.form.address.address : ''
        const addressFinderLoad = setInterval(() => {
          this.$refs.addressFinder.aadressSearch.setAddress(
            address
          );
          this.$refs.addressFinder.focusInput();
          clearInterval(addressFinderLoad);
        }, 30);
      } 
      if (!this.form.address) {
        this.form.address = null
      }
    },
    updateImages(event) {
      this.form.images = event;
    },
    async saveDevelopment() {
      let development = this.form;
      development._id = nanoid(8);
      development = this.mapDevelopment(development);
      if (development.addressType !== 'estonia') {
          development.address.foreignCountry = true;
      }
      let response = await axios.post("/api/development", { development });
      if (response.data === 0) {
        this.toastError(this.$t("ErrorCreating"));
      } else {
        this.$emit("saveResponse", response.data);
        await this.changeSidepanelStateDevelopments(0);
        this.toastSuccess(
          this.$t("Development") + " " + this.$t("Created").toLowerCase()
        );
        await this.$router.push({
          name: "DevelopmentDetail",
          params: { id: response.data._id },
        });
      }
    },
    async updateDevelopment() {
      let development = this.form;
      development = this.mapDevelopment(development);
      
      if (!development.address) {
        this.errors.object = "FieldRequired";
        this.showErrors = true;
        return false;
      }
      if (!development.contactPerson) {
        this.errors.contactPerson = "FieldRequired";
        this.showErrors = true;
        return false;
      }
      if (development.addressType !== 'estonia') {
          development.address.foreignCountry = true;
      }

      if (this.isAddressTransfer && this.changeListingAddress) {
          await this.transferListingAddress();
          this.isAddressTransfer = false;
      }

      const response = await axios.patch(
        "/api/development/update",
        development
      );
      if (response.data === 0) {
        this.toastError(this.$t("ErrorUpdating"));
        return;
      } else {
        await this.changeSidepanelStateDevelopments(0);
        if (!this.noRefresh && !this.isEdit) {
          await this.$router.go(0);
        }
        await this.$emit("closed");
        this.toastSuccess(
          this.$t("Development") + " " + this.$t("Updated").toLowerCase()
        );
      }
    },
    async deleteDevelopment() {
      const deleteResponse = await axios.post(
        `/api/development/delete/${this.form._id}`
      );
      if (deleteResponse.data === 0) {
        this.toastError(this.$t("ErrorDeleting"));
        return;
      } else {
        await this.$router.push({ name: "PropertyDevelopment" });
        this.toastSuccess(
          this.$t("Development") + " " + this.$t("Deleted").toLowerCase()
        );
      }
    },
    isFormValid() {
      this.errors = this.getEmptyErrors();
      return this.validate
        .map((field) => {
          if (field === "name" || field === "object" || field === "estateType" || field === "contactPerson")
            return true;
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      switch (fieldName) {
        case "name":
          if (!this.form.name) {
            this.errors.name = "FieldRequired";
            return false;
          }
          break;
        case "object":
          if (!this.form.address) {
            this.errors.object = "FieldRequired";
            return false;
          }
          break;
        case "contactPerson":
          if (!this.form.contactPerson) {
            this.errors.contactPerson = "FieldRequired";
            return false;
          }
          break;
      }
      return true;
    },
    mapDevelopment(development) {
      if (this.noteContent) {
        development.notes.push({
          content: this.noteContent,
          linkedTo: development._id,
          isDisabled: true,
          createdAt: moment().format("DD/MM/YYYY"),
          parentName: development.name,
        });
      }
      return development;
    },
    mapToFormData() {
      if (this.isEdit && this.developmentData) {
        this.form = this.developmentData;
      }
      if (this.isEdit && !this.developmentData.addressType) {
          this.form.addressType = 'estonia'
        }
      if (!this.isEdit) {
        this.initialClient = this.form.contactPerson;
        this.form.client = this.form.contactPerson;
      } else {
        this.initialClient = this.developmentData.client;
        this.form.contactPerson = this.developmentData.contactPerson;
        this.form.client = this.developmentData.client;
      }
    },
    getEmptyErrors() {
      return {
        name: "",
        object: "",
        contactPerson: "",
      };
    },
    putImages(e) {
      this.form.images = [];
      e.forEach((el) => {
        if (el.name) {
          this.form.images.push({ name: el.name, hidden: false });
        }
      });
    },
    setAddressObject(object) {
      if (object) {
        this.form.address = object;
      } else {
        this.form.address = ""
      }
    },
    removeAddressObject() {
      this.form.address = "";
    },
    addNewPrivateContact() {
      this.addFromClientSelection = true;
      this.openContactAdd();
    },
    setProjectClient(type, client) {
      let clientObject;
      if (type === "private") {
        if (client.value === "same_as_contact") {
          clientObject = {
            isPrivatePerson: true,
            _id: this.form.contactPerson._id,
            contactId: this.form.contactPerson.contactId,
            contactName: this.form.contactPerson.contactName,
            createdAt: this.form.contactPerson.createdAt,
          };
          this.form.client = clientObject;
        }
        if (
          client.value !== "new_contact" &&
          client.value !== "same_as_contact"
        ) {
          clientObject = {
            isPrivatePerson: true,
            _id: client._id,
            contactId: client.customerId,
            contactName: client.customerName,
            createdAt: client.createdAt,
          };
          this.form.client = clientObject;
        }
      } else {
        clientObject = {
          isPrivatePerson: false,
          _id: client._id,
          contactId: this.form.contactPerson.contactId,
          contactName: this.form.contactPerson.contactName,
          reg_code: client.reg_code,
          company_name: client.company_name,
          createdAt: this.form.contactPerson.createdAt,
        };
        this.form.client = clientObject;
      }
    },
    closeContactAdd() {
      let panel = document.getElementById("panel-wrapper-development");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-development");
      let wrappedHeader = document.getElementById("wrapped-header-development");
      let wrappedPanelCustomer = document.getElementById(
        "panel-wrapper-customer"
      );
      let modalFooter = document.getElementsByClassName("modal-footer");
      if (modalFooter.length > 0) {
        modalFooter[0].style.display = "initial";
      }
      if (wrappedPanelCustomer) {
        wrappedPanelCustomer.style.right = "560px";
      }
      wrappedHeader.style.display = "none";
      wrappedHeader.style.opacity = "0";
      panelContent.style.display = "initial";
      panel.style.overflow = "scroll";
      panel.style.minWidth = "560px";
      panel.style.width = "560px";
      panel.style.right = "0";
      overlay.style.zIndex = "101";
      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };
      this.$emit("closedClientAdd");
      //waiting for animations
      setTimeout(async () => {
        this.clientAddOpen = false;
      }, 500);
    },
    async chooseNewClient(client) {
      const object = await contactApi.getContactById(client);
      if (this.addFromClientSelection) {
        this.newProjectClient = object;
        this.addFromClientSelection = false;
      } else {
        this.form.contactPerson = {
          _id: object._id,
          contactId: object.customerId,
          contactName: object.customerName
            ? object.customerName
            : object.contactName,
          createdAt: object.createdAt,
        };
        this.chosenNewClient = object;
        this.newProjectClient = object;
      }
    },
    async contactPersonSelected(person) {
      if (!person) {
        this.form.contactPerson = null;
        return;
      }
      const companyId =
        person.organizations.length > 0 ? person.organizations[0]._id : null;

      const regCode =
        person.organizations.length > 0
          ? person.organizations[0].reg_code
          : null;
      this.form.contactPerson = {
        _id: person._id,
        company_name: person.organization ? person.organization : null,
        contactId: person.customerId,
        contactName: person.customerName
          ? person.customerName
          : person.contactName,
        companyId: companyId,
        regCode,
        createdAt: person.createdAt,
      };
    },
    openContactAdd() {
      let panel = document.getElementById("panel-wrapper-development");
      let modalFooter = document.getElementsByClassName("modal-footer");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-development");
      let wrappedPanelCustomer = document.getElementById(
        "panel-wrapper-customer"
      );
      if (wrappedPanelCustomer) {
        wrappedPanelCustomer.style.right = "638px";
        panel.style.right = "731px";
      } else {
        panel.style.right = "638px";
      }
      if (modalFooter.length > 0) {
        modalFooter[0].style.display = "none";
      }
      panelContent.style.display = "none";
      panel.style.overflow = "hidden";
      panel.style.minWidth = "94px";
      panel.style.width = "94px";
      panel.style.zIndex = "121";
      //panel.style.boxShadow = "0px 0 1px rgba(28, 14, 3, 0.2)";
      overlay.style.zIndex = "11";

      let wrappedHeader = document.getElementById("wrapped-header-development");
      wrappedHeader.style.display = "flex";
      wrappedHeader.style.opacity = "1";
      this.$emit("openedClientAdd");
      this.clientAddOpen = true;
    },
    hideOverlay() {
      document.documentElement.style.overflow = "hidden";

      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#panel-wrapper-property {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}

.transfer-listings-addresses {
  margin: 6px 0 12px 0;
}

.confirmation-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.confirmation-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.new-shared-broker {
  width: 100%;
  display: flex;
  font-weight: 600;
}

#wrapped-header-development {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}

.deal-type-lable {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a !important;
}

.v-text-field--outlined .v-label {
  top: 20px;
}

::v-deep .delete {
  padding: 0 !important;
}

::v-deep .plus {
  display: none;
}

.calender-label {
  margin-top: -5px;
  left: auto;
  right: auto;
  position: absolute;
  font-size: 12px;
  font-family: Inter;
  background-color: white;
  margin-left: 12px;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  border-bottom: 1px solid #e6e8ec;
  background: white;
  height: 97px;
  width: 560px;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}
#project-developer-description {
  width: 100%;
  padding: 8px 12px;
  gap: 10px;
  height: 96px;
  outline: unset;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.top-row {
  width: 100%;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9;
}

.flex-column-gen {
  margin-top: 17px !important;
}

::v-deep .mainMessage {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  //line-height: 20px;
  text-align: center;
  color: #000000;
}

::v-deep .beforeUpload .icon {
  height: 20px;
}

.image-uploader {
  //min-width: 896px;
  min-height: 96px;
  border: 1px dashed #c3c4c6;
  box-sizing: border-box;
  border-radius: 8px;
}

.project-type-selected-con-Sale {
  background: $lightgreen !important;
  color: $green !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-LeaseOut {
  background: $lightpink !important;
  color: $magenta !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Purchase {
  background: $lightblue !important;
  color: $blue !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-AquireLease {
  background: $beige !important;
  color: $orange !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Referral {
  background: $lightbrown !important;
  color: $brown !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-con {
  font-style: normal;
  font-weight: normal;
  border: 1px solid $grey500;
  box-sizing: border-box;
  border-radius: 8px;

  background: #fff;
  color: #000;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
}

.modal-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  padding-left: 0px;
  z-index: 7;
  width: 560px;
  border: 1px solid #e6e8ec;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.modal-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.modal-content {
  font-size: 14px;
  padding: 110px 32px 140px 32px;
}

.modal-footer {
  justify-content: flex-end;
  z-index: 126;
  border-top: 1px solid #e6e8ec;
  background: white;
  width: 560px;
}

.faded-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $grey950;
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 50px 64px;
  height: 112px;
  font-family: Inter !important;
  color: #000000;
  width: 560px;
  background-color: white;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

::v-deep .theme--light.v-select .v-select__selections {
  color: #000000;
  line-height: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 16px;
  cursor: pointer;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 18px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 18px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 49.2%;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  height: 56px;
  padding: 0px !important;

  &:focus {
    outline: none !important;
  }
}

.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  text-transform: none;
  width: 103px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.cancel-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400 !important;
  line-height: 16px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.textarea {
  width: 100%;
  height: 96px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
}

.delete-button {
  margin-right: 168px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}


::v-deep .v-messages {
  display: none;
}

::v-deep .radio-group-label .v-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
</style>
