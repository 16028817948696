<template>
  <vue-draggable-resizable
    :z="10"
    :resizable="false"
    drag-handle=".drag-area"
    class="widget-container"
    @dragstop="onDragStop"
    v-click-outside="handleOutsideClick"
  >
    <div class="link-listing-widget">
      <div class="widget-header drag-area">
        <div>
          <img src="@/assets/images/menu.svg" alt="" />
        </div>
        <div class="close-button">
          <img
            src="@/assets/images/close_grey.svg"
            alt=""
            @click="closePanel"
          />
        </div>
      </div>
      <div class="" style="color: black; padding: 12px 24px 0 24px">
        <h5>{{ $t("ChangeLinkedProject") }}</h5>
      </div>
      <div style="padding: 0 24px; height: 60px">
        <v-radio-group row style="padding-top: 12px" v-model="module">
          <v-radio
            color="#FF5C01"
            value="project"
            class="radio-button"
            :label="$t('Project')"
          ></v-radio>
          <v-radio
            color="#FF5C01"
            value="development"
            class="radio-button"
            :label="$t('Development')"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="dropdown-row">
        <ItemSearch
          v-if="module == 'project'"
          :items="foundItems"
          :placeholder="$t('Search')"
          :name-key="'name'"
          @itemChosen="newLink"
          @focus-event="onFocus"
        />
        <ItemSearch
          v-if="module == 'development'"
          :items="foundItems"
          :placeholder="$t('Search')"
          :name-key="'projectName'"
          @itemChosen="setListingsLink"
          @focus-event="onFocus"
        />
      </div>
      <div
        class="dropdown-row"
        v-if="module == 'development' && buildingItems.length > 0"
      >
        <BaseDropdown
          v-model="selectedBuilding"
          :items="buildingItems"
          :show-dropdown-arrow="true"
          :dropdown-allow-null="false"
          :placeholder="$t('SelectBuilding')"
          type="dropdown"
          required="true"
        />
      </div>
      <div class="modal-content">
        <p class="content-title">{{ $t("LinkedProject") }}</p>
        <div
          style="
            font-weight: 500;
            font-size: 14px;
            padding-top: 8px;
            height: 28px;
            display: flex;
            gap: 12px;
          "
        >
          <img
            v-if="linkedEntity && linkedEntity.pipelineStep"
            src="../../assets/images/projects.svg"
            alt=""
          />
          <img
            v-if="
              linkedEntity && linkedEntity.address && linkedEntity.address.units
            "
            src="../../assets/images/PropertyDeveloper.svg"
            alt=""
          />
          <p>
            {{
              linkedEntity.name
                ? linkedEntity.name
                : linkedEntity.projectName
                ? linkedEntity.projectName
                : ""
            }}
          </p>
        </div>
      </div>
    </div>
  </vue-draggable-resizable>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import ItemSearch from "@/components/common/ItemSearch";
import axios from "axios";
import BaseDropdown from "@/components/common/BaseDropdown";

export default {
  name: "LinkListingModal",
  props: ["item", "linkedEntity", "disableClickOutside"],
  components: {
    ItemSearch,
    VueDraggableResizable,
    BaseDropdown,
  },
  data() {
    return {
      posX: 0,
      posY: 0,
      brokers: [],
      selectedItem: null,
      sharedCreators: [],
      newSharedCreators: [],
      dropdownOptions: [],
      selectedBrokerUpdater: 0,
      foundItems: [],
      module: "project",
      search: "",
      isFetching: false,
      items: [],
      totalPages: null,
      itemsPerPage: 25,
      page: 1,
      buildingItems: [],
      selectedBuilding: "",
      confirmModalOpen: false,
    };
  },
  created() {
    this.removeOverlappingOverlay();
    this.selectedItem = this.linkedEntity;
  },
  watch: {
    items() {
      this.foundItems = this.items;
    },
    selectedBuilding(id) {
      if (this.module === "development") {
        const selectedBuilding = this.buildingItems.find(item => item.value === id);
        const buildingName = selectedBuilding ? selectedBuilding.name : '';
        
        let body = {
          buildingId: id,
          linkedId: this.selectedItem._id,
          listingId: this.item._id,
          module: this.module,
          name: this.selectedItem.projectName,
          building: buildingName
        };
        this.$emit("new-link", body);
      }
    },
  },
  methods: {
    closeDropdown(creator) {
      creator.dropdownOpen = false;
      this.creator = creator;
    },
    parseBuildings(buildings) {
      const buildingItems = [];
      buildings.forEach((building) => {
        if (building.buildingName) {
          buildingItems.push({
            name: building.buildingName,
            value: building._id,
          });
        } else {
          buildingItems.push({
            name: building.addressObjects[0].address,
            value: building._id,
          });
        }
      });
      return buildingItems;
    },
    async updateLinkedDeal(body) {
      try {
        await axios.patch("/api/listing/update/linked-deal", body);
        this.toastSuccess(this.$t("ListingLinked"));
      } catch (error) {
        console.error("Error updating linked deal:", error);
      }
    },
    async setListingsLink(link) {
      this.selectedItem = link;
      if (this.module == "development") {
        this.buildingItems = this.parseBuildings(link.buildings);
      } else {
        let body = {
          linkedId: link._id,
          listingId: this.item._id,
          module: this.module,
        };
        this.updateLinkedDeal(body);
      }
    },
    getEndpoint() {
      switch (this.module) {
        case "development":
          return `/api/fetch-developments/${
            this.search ? encodeURIComponent(this.search) : ""
          }`;
        case "project":
          return `/api/fetch-projects/${
            this.search ? encodeURIComponent(this.search) : ""
          }`;
      }
    },
    linkSelected(e) {
      this.selectedItem = e;
    },
    async findItems() {
      if (this.skipFocus) {
        this.skipFocus = false;
        return;
      }
      try {
        this.loadingPage = this.page;

        const endpoint = this.getEndpoint();
        const response = await axios.get(endpoint, {
          params: {
            limit: this.itemsPerPage,
            page: this.page,
          },
        });
        this.totalPages = response.data.totalPages;
        if (this.page === this.loadingPage) {
          if (this.page === 1) {
            this.foundItems = response.data.deals;
          } else {
            this.foundItems.push(...response.data.deals);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.searchInProgress = false;
      }
    },
    onFocus() {
      this.findItems(this.search);
    },
    removeOverlappingOverlay() {
      if (document.getElementById("offers-sidepanel-overlay")) {
        document.getElementById("listing-overlay").style.position = "initial";
      }
    },
    onDragStop(left, top) {
      this.posX = left;
      this.posY = top;
    },
    handleOutsideClick() {
      if (!this.disableClickOutside) {
        this.$emit("close-modal");
      }
    },
    closePanel() {
      this.$emit("close-modal");
    },
    newLink(link) {
      this.$emit("new-link", link);
    },
  },
  computed: {
    buildingName() {
      const selected = this.buildingItems.find(item => item.value === this.selectedBuilding);
      return selected ? selected.name : '';
    }
  }
};
</script>

<style lang="scss" scoped>
.widget-container {
  position: fixed;
  left: 34%;
  top: 26% !important;
  padding-bottom: 20px;
  padding-right: 40px;
}

.link-listing-widget {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.11), 2px 1px 9px rgba(0, 0, 0, 0.11),
    0px 0px 0px rgba(0, 0, 0, 0.1);
  width: 570px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  position: fixed;
  background: white;
  color: white !important;
  border-radius: 8px;
}

.drag-area {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding-left: 10px;
}

.close-button {
  cursor: pointer;
  padding: 2px 16px 0 0;
  margin: auto 0;
}

.widget-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  width: 570px;
  background-color: #f4f5f7;
  border-radius: 8px 8px 0 0;
}

.dropdown-row {
  display: flex;
  width: 570px;
  padding: 6px 24px;
}

.modal-content {
  height: 100%;
  width: 570px;
  padding: 18px 24px 18px 24px;
}

.content-title {
  font-size: 12px;
  color: #75787a;
}

::v-deep .v-text-field .v-input__control {
  min-height: 52px !important;
  max-height: 52px !important;
}

::v-deep .v-input__slot {
  min-height: 52px !important;
  max-height: 52px !important;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 16px !important;
}

::v-deep .v-icon.v-icon {
  font-size: 24px !important;
}
</style>
