import Vue from "vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

Vue.mixin({
    computed: {
      ...mapGetters(["groups"])
    },
    methods: {
        ...mapActions(["getGroups"]),
        async updateModulePreferences(module, item, value) {
            return await axios.post(`/api/admin/preferences`, {
                module: module,
                item: item,
                value: value
            })
        },
        async getGroupUsers(groupId) {
            await this.getGroups()
            return this.groups[groupId].users;
        }
    },
});