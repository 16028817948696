var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeResizedImages,
      expression: "closeResizedImages"
    }],
    staticClass: "container",
    on: {
      "click": function ($event) {
        return _vm.closeResizedImages($event);
      },
      "dragover": function ($event) {
        $event.preventDefault();
        return _vm.dragOver.apply(null, arguments);
      },
      "dragleave": function ($event) {
        $event.preventDefault();
        return _vm.dragLeave.apply(null, arguments);
      },
      "drop": function ($event) {
        $event.preventDefault();
        return _vm.drop($event);
      }
    }
  }, [!_vm.imageLoaded && _vm.Imgs.length > 0 ? _c('div', {
    staticStyle: {
      "position": "absolute",
      "display": "flex",
      "align-content": "center",
      "align-items": "center",
      "justify-content": "center",
      "width": "100%",
      "height": "100%",
      "background-color": "#e4e5e7",
      "opacity": "0.75",
      "z-index": "2",
      "margin": "-12px",
      "border-radius": "8px"
    }
  }, [_vm._m(0)]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dropped == 2,
      expression: "dropped == 2"
    }],
    staticClass: "drop"
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.error,
      expression: "error"
    }],
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.Imgs.length == 0,
      expression: "Imgs.length == 0"
    }],
    staticClass: "beforeUpload",
    staticStyle: {
      "padding": "30px"
    }
  }, [_c('input', {
    ref: "uploadInput",
    staticStyle: {
      "z-index": "2",
      "cursor": "pointer",
      "position": "absolute",
      "top": "0",
      "left": "0",
      "height": "100%",
      "width": "100%"
    },
    attrs: {
      "type": "file",
      "accept": "image/*",
      "multiple": ""
    },
    on: {
      "change": _vm.previewImgs
    }
  }), _vm.imageLoaded ? _c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/images/image.svg"),
      "alt": ""
    }
  }) : _vm._e(), _vm.imageLoaded ? _c('p', {
    staticClass: "mainMessage"
  }, [_vm._v(" " + _vm._s(_vm.uploadMsg ? _vm.uploadMsg : _vm.$t("ClickToUploadDrop")) + " ")]) : _vm._e(), !_vm.imageLoaded ? _c('div', [_c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "25%",
      "left": "50%"
    }
  })]) : _vm._e()]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.Imgs.length > 0,
      expression: "Imgs.length > 0"
    }],
    staticClass: "imgsPreview"
  }, [!_vm.coverImageSelection ? _c('button', {
    staticClass: "clearButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.reset
    }
  }, [_vm._v(" " + _vm._s(_vm.clearAll ? _vm.clearAll : _vm.$t('ClearAll')) + " ")]) : _vm._e(), _c('draggable', {
    on: {
      "change": _vm.helloworld
    },
    model: {
      value: _vm.Imgs,
      callback: function ($$v) {
        _vm.Imgs = $$v;
      },
      expression: "Imgs"
    }
  }, _vm._l(_vm.Imgs, function (img, i) {
    return _c('div', {
      key: i,
      staticClass: "imageHolder"
    }, [img.selected ? _c('img', {
      staticClass: "cover-picture",
      attrs: {
        "src": img.name,
        "id": 'img' + (i + 1)
      }
    }) : _vm._e(), !img.hidden && !img.selected ? _c('img', {
      attrs: {
        "src": img.name,
        "id": 'img' + (i + 1)
      },
      on: {
        "click": function ($event) {
          return _vm.closeResizedImage(img, i);
        }
      }
    }) : _vm._e(), img.hidden && !img.selected ? _c('img', {
      staticClass: "hidden-picture",
      attrs: {
        "src": img.name,
        "id": 'img' + (i + 1)
      }
    }) : _vm._e(), _vm.isVisible && !_vm.coverImageSelection ? _c('span', {
      staticClass: "scalability",
      on: {
        "click": function ($event) {
          return _vm.changeSize(img, i);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require('@/assets/images/zoomImage.svg'),
        "alt": ""
      }
    })]) : _vm._e(), _vm.isVisible && !_vm.coverImageSelection ? _c('span', {
      staticClass: "visibility",
      staticStyle: {
        "color": "white"
      },
      on: {
        "click": function ($event) {
          return _vm.changeVisibility(img, i);
        }
      }
    }, [!img.hidden ? _c('img', {
      attrs: {
        "src": require("@/assets/images/openEye.svg"),
        "alt": ""
      }
    }) : _vm._e(), img.hidden ? _c('img', {
      attrs: {
        "src": require("@/assets/images/closeEye.svg"),
        "alt": ""
      }
    }) : _vm._e()]) : _vm._e(), _vm.coverImageSelection && !img.selected ? _c('span', {
      staticClass: "cover-image",
      staticStyle: {
        "color": "white"
      },
      attrs: {
        "data-hover": _vm.$t('SetCoverImage'),
        "id": 'cover-image' + (i + 1)
      },
      on: {
        "click": function ($event) {
          return _vm.setCoverImage(img, i);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/coverImage.svg"),
        "alt": ""
      }
    })]) : _vm._e(), !_vm.coverImageSelection ? _c('span', {
      staticClass: "delete",
      staticStyle: {
        "color": "white"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteImg(--i);
        }
      }
    }, [_c('svg', {
      staticClass: "icon",
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "fill": "none",
        "viewBox": "0 0 24 24",
        "stroke": "currentColor"
      }
    }, [_c('path', {
      attrs: {
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "d": "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
      }
    })])]) : _vm._e(), ++i == _vm.Imgs.length && !_vm.coverImageSelection && _vm.imageLoaded ? _c('div', {
      staticClass: "plus",
      on: {
        "click": _vm.append
      }
    }, [_vm._v(" + ")]) : _vm._e()]);
  }), 0)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "loader"
  })]);

}]

export { render, staticRenderFns }