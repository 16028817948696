import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Cookies from "js-cookie";
import createPersistedState from "vuex-persistedstate";
import contactApi from "../http/contact";
import adminApi from "../http/admin";
import _ from "lodash";
import allFilters from "../helpers/data/store/filters/allFilters";

import propDevModule from "./propertydevelopment";
import invoicesModule from "./invoices";
import optionsModule from "./general/options";

Vue.use(Vuex);

const moduleB = {
  state: {
    listingsLoaded: false,
    projectsLoaded: false,
    listingsNone: null,
    listingsFiltersReset: 0,
    projectsFiltersReset: 0,
    activeListingsView: "listings",
    activeContactsTab: "contacts",
    activeTransactionsTab: "transactions",
    activeInvoicesTab: "invoices",
    activeContactsTabUpdater: 0,
    activeProjectsTab: "projects",
    loadingDone: 0,
    scorecardTemplate: null,
    userScorecards: null,
    usersScorecards: [],
    fileData: null,
    fileName: null,
    projectAccessData: null,
  },
  mutations: {
    SET_PROJECTS_LOADED(state, status) {
      state.projectsLoaded = status;
    },
    REMOVE_FILTERS_LISTINGS(state) {
      state.listingsFiltersReset++;
    },
    REMOVE_FILTERS_PROJECTS(state) {
      state.projectsFiltersReset++;
    },
    SET_ACTIVE_LISTINGS_VIEW(state, view) {
      state.activeListingsView = view;
    },
    SET_ACTIVE_CONTACTS_VIEW(state, view) {
      state.activeContactsTab = view;
    },
    SET_ACTIVE_PROJECTS_TAB(state, view) {
      state.activeProjectsTab = view;
    },
    SET_ACTIVE_TRANSACTIONS_TAB(state, view) {
      state.activeTransactionsTab = view;
    },
    SET_ACTIVE_INVOICES_TAB(state, view) {
      state.activeInvoicesTab = view;
    },
    SET_SCORECARD_TEMPLATE(state, scorecardTemplate) {
      state.scorecardTemplate = scorecardTemplate;
    },
    SET_USER_SCORECARDS(state, userScorecards) {
      state.userScorecards = userScorecards;
    },
    SET_USERS_SCORECARDS(state, usersScorecards) {
      state.usersScorecards = usersScorecards;
    },
    SET_FILE_DATA(state, payload) {
      state.fileData = payload.fileData;
      state.fileName = payload.fileName;
    },
    CLEAR_FILE_DATA(state) {
      state.fileData = null;
      state.fileName = null;
    },
    SET_PROJECT_OWNERS_UPDATED(state, payload) {
      state.projectAccessData = payload;
    },
    CLEAR_PROJECT_OWNERS_UPDATED(state) {
      state.projectAccessData = null;
    },
  },
  actions: {
    setProjectsLoaded({ commit }, status) {
      commit("SET_PROJECTS_LOADED", status);
    },
    removeFiltersListings({ commit }) {
      commit("REMOVE_FILTERS_LISTINGS");
    },
    removeFiltersProjects({ commit }) {
      commit("REMOVE_FILTERS_PROJECTS");
    },
    setActiveListingsView({ commit }, view) {
      commit("SET_ACTIVE_LISTINGS_VIEW", view);
    },
    setActiveContactsTab({ commit }, view) {
      commit("SET_ACTIVE_CONTACTS_VIEW", view);
    },
    setActiveProjectsTab({ commit }, view) {
      commit("SET_ACTIVE_PROJECTS_TAB", view);
    },
    setActiveTransactionsTab({ commit }, view) {
      commit("SET_ACTIVE_TRANSACTIONS_TAB", view);
    },
    setActiveInvoicesTab({ commit }, view) {
      commit("SET_ACTIVE_INVOICES_TAB", view);
    },
    async setScorecardTemplate({ commit }, scorecardTemplate) {
      commit("SET_SCORECARD_TEMPLATE", scorecardTemplate);
    },
    async setUserScorecards({ commit }, userScorecards) {
      commit("SET_USER_SCORECARDS", userScorecards);
    },
    setUsersScoreCards({ commit }, usersScorecards) {
      commit('SET_USERS_SCORECARDS', usersScorecards);
    },
    clearUsersScoreCards({ commit }) {
      commit('SET_USERS_SCORECARDS', [])
    },
    setFileData({ commit }, payload) {
      commit('SET_FILE_DATA', payload);
    },
    clearFileData({ commit }) {
      commit('CLEAR_FILE_DATA');
    },
    projectOwnershipTransferred({ commit }, payload) {
      commit('SET_PROJECT_OWNERS_UPDATED', payload);
    },
    clearProjectAccessData({ commit }) {
      commit('CLEAR_PROJECT_OWNERS_UPDATED');
    },
  },
  getters: {
    listingsFiltersReset(state) {
      return state.listingsFiltersReset;
    },
    projectsFiltersReset(state) {
      return state.projectsFiltersReset;
    },
    listingsNone(state) {
      return state.listingsNone;
    },
    listingsLoaded(state) {
      return state.listingsLoaded;
    },
    projectsLoaded(state) {
      return state.projectsLoaded;
    },
    activeTab(state) {
      return state.activeListingsView;
    },
    activeContactsTab(state) {
      return state.activeContactsTab;
    },
    activeTransactionsTab(state) {
      return state.activeTransactionsTab;
    },
    activeInvoicesTab(state) {
      return state.activeInvoicesTab;
    },
    activeContactsTabUpdater(state) {
      return state.activeContactsTabUpdater;
    },
    activeProjectsTab(state) {
      return state.activeProjectsTab;
    },
    scorecardTemplate(state) {
      return state.scorecardTemplate;
    },
    userScorecards(state) {
      return state.userScorecards;
    },
    usersScorecards(state) {
      return state.usersScorecards;
    },
    getFileData(state) {
      return state.fileData;
    },
    getFileName(state) {
      return state.fileName;
    },
    projectAccessData: (state) => {
      return state.projectAccessData;
    }
  },
};

const moduleC = {
  state: {
    msalObject: null,
  },
  mutations: {
    SET_MSAL_OBJECT(state, msal) {
      state.msalObject = msal;
    },
  },
  actions: {
    setMsalObject({ commit }, msalObject) {
      commit("SET_MSAL_OBJECT", msalObject);
    },
  },
  getters: {
    msalObject(state) {
      return state.msalObject;
    },
  },
};

const moduleD = {
  state: {
    filters: JSON.parse(JSON.stringify(allFilters)),
    filterUpdater: 0,
    provisionalFilters: JSON.parse(JSON.stringify(allFilters)),
    provisionalFiltersUpdater: 0,
    previouslyLoggedInUser: null,
  },
  mutations: {
    SET_SAVED_VIEW_COLOR(state, { module, viewId, color }) {
      state.filters[module].forEach((savedView) => {
        if (savedView._id === viewId) {
          savedView.iconColor = color;
        }
      });
    },
    SET_SAVED_VIEW_ICON(state, { module, viewId, icon }) {
      state.filters[module].forEach((savedView) => {
        if (savedView._id === viewId) {
          console.log("ikoon!");
          savedView.icon = icon;
        }
      });
    },
    SET_FILTERS_BY_FILTER_NAME(state, { filterName, filters }) {
      state.filters[filterName] = JSON.parse(JSON.stringify(filters));
      state.filterUpdater++;
    },
    UPDATE_FILTER_BY_FILTER_NAME(state, { filterName, filter }) {
      const tempFilters = state.filters[filterName];
      const newFilters = [];
      tempFilters.forEach((_filter) => {
        if (_filter._id === filter._id) {
          newFilters.push(filter);
        } else {
          if (filter.isDefault) _filter.isDefault = false;
          newFilters.push(_filter);
        }
      });
      const haveSomeDefaultFilter = newFilters.some(
        (_filter) => _filter.isDefault
      );
      if (!haveSomeDefaultFilter) {
        newFilters[0].isDefault = true;
      }
      state.filters[filterName] = newFilters;
      state.filterUpdater++;
    },
    REMOVE_FILTER_BY_ID(state, { filterName, filterId }) {
      const tempFilters = state.filters[filterName];
      for (let i = 0; i < tempFilters.length; i++) {
        const tempFilter = tempFilters[i];
        if (tempFilter._id === filterId) {
          if (tempFilter.isDefault) {
            const allValuesFilter = tempFilters[0];
            allValuesFilter.isDefault = true;
            tempFilters[0] = allValuesFilter;
          }
          if (tempFilter.isSelected) {
            const allValuesFilter = tempFilters[0];
            allValuesFilter.isSelected = true;
            tempFilters[0] = allValuesFilter;
            state.provisionalFilters[filterName] = allValuesFilter.conditions;
            state.provisionalFiltersUpdater++;
          }
          tempFilters.splice(i, 1);
          break;
        }
      }
      state.filters[filterName] = tempFilters;
      state.filterUpdater++;
    },
    ADD_FILTER(state, { filterName, filter }) {
      state.filters[filterName].push(filter);
      if (filter.isDefault) {
        const tempFilters = state.filters[filterName];
        tempFilters.forEach((tempFilter) => {
          tempFilter.isDefault = tempFilter._id === filter._id;
        });
        state.filters[filterName] = tempFilters;
      }
      state.filterUpdater++;
    },
    CHANGE_PROVISIONAL_FILTER_CONDITION(
      state,
      { filterName, conditionName, value, valueTitle }
    ) {
      const conditions = JSON.parse(
        JSON.stringify(state.provisionalFilters[filterName])
      );
      let foundCondition = false;
      conditions.forEach((condition) => {
        if (condition.name === conditionName) {
          condition.value = value;
          condition.valueTitle = valueTitle;
          foundCondition = true;
        }
      });
      if (!foundCondition) {
        conditions.push({
          name: conditionName,
          value: value,
          valueTitle: valueTitle,
        });
      }

      state.provisionalFilters[filterName] = conditions;
      state.provisionalFiltersUpdater++;
    },
    CHANGE_FILTER_CONDITION(
      state,
      { filterName, conditionName, value, valueTitle }
    ) {
      const conditions = state.filters[filterName][0].conditions;
      conditions.forEach((condition) => {
        if (condition.name === conditionName) {
          condition.value = value;
          condition.valueTitle = valueTitle;
        }
      });
    },
    SET_PROVISIONAL_FILTER(state, { filterName, conditions }) {
      state.provisionalFilters[filterName] = conditions;
      state.provisionalFiltersUpdater++;
    },
    RESET_FILTERS(state, filterName) {
      state.provisionalFilters[filterName] = JSON.parse(
        JSON.stringify(allFilters[filterName])
      );
      state.filters[filterName] = JSON.parse(
        JSON.stringify(allFilters[filterName])
      );
    },
  },
  actions: {
    resetFilters({ commit }, filterName) {
      commit("RESET_FILTERS", filterName);
    },
    async addFilter({ commit }, { filterName, filter }) {
      commit("ADD_FILTER", { filterName, filter });
    },
    async setFiltersByFilterName({ commit }, { filterName, filters }) {
      commit("SET_FILTERS_BY_FILTER_NAME", { filterName, filters });
    },
    async updateFilterByFilterName({ commit }, { filterName, filter }) {
      commit("UPDATE_FILTER_BY_FILTER_NAME", { filterName, filter });
    },
    async changeProvisionalFilterCondition(
      { commit },
      { filterName, conditionName, value, valueTitle }
    ) {
      commit("CHANGE_PROVISIONAL_FILTER_CONDITION", {
        filterName,
        conditionName,
        value,
        valueTitle,
      });
    },
    async filter({ commit }, { filterName, conditionName, value, valueTitle }) {
      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        commit("CHANGE_PROVISIONAL_FILTER_CONDITION", {
          filterName,
          conditionName,
          value,
          valueTitle,
        });
      }, 1000);
    },
    async changeFilterCondition(
      { commit },
      { filterName, conditionName, value, valueTitle }
    ) {
      commit("CHANGE_FILTER_CONDITION", {
        filterName,
        conditionName,
        value,
        valueTitle,
      });
    },
    async setProvisionalFilter({ commit }, { filterName, conditions }) {
      commit("SET_PROVISIONAL_FILTER", { filterName, conditions });
    },
    async setSavedViewColor({ commit }, { module, viewId, color }) {
      commit("SET_SAVED_VIEW_COLOR", { module, viewId, color });
    },
    async setSavedViewIcon({ commit }, { module, viewId, icon }) {
      commit("SET_SAVED_VIEW_ICON", { module, viewId, icon });
    },
  },
  getters: {
    filtersItemsContact(state) {
      const filters = state.filters["contacts"];
      const defaultFilters = filters[0];
      let filtersItems = {};
      defaultFilters.conditions.forEach((filter) => {
        let filterItems = {
          name: filter.name,
          items: filter.items,
        };
        filtersItems[filterItems.name] = filterItems;
      });
      return filtersItems;
    },
    provisionalFilters(state) {
      return state.provisionalFilters;
    },
    provisionalFiltersUpdater(state) {
      return state.provisionalFiltersUpdater;
    },
    filters(state) {
      return state.filters;
    },
    filtersUpdater(state) {
      return state.filterUpdater;
    },
  },
};

const moduleA = {
  state: {
    buildVersion: 0.1,
    user: null,
    propertySelectedBuildingIndex: 0,
    selfServiceUser: null,
    groups: [],
    users: null,
    project: null,
    customers: [],
    customersCount: 0,
    customersPagination: [],
    partners: [],
    allCustomers: [],
    filteredCustomers: null,
    filteredUsers: null,
    customersUpdaterKey: 0,
    partnersUpdaterKey: 0,
    allCustomersUpdaterKey: 0,
    usersUpdaterKey: 0,
    listingsUpdater: 0,
    backButtonUpdater: 0,
    showBackButton: false,
    offersLeadsIdsWithDuplicates: [],
    customerFilter: null,
    customersBySector: null,
    customerSector: "All",
    listings: null,
    language: "en",
    languageSet: false,
    modalDeals: false,
    editPersons: false,
    sidePanelContactAdd: false,
    newAddedContact: null,
    sidepanelDealsAdd: false,
    sidepanelDeal: false,
    sidepanelPropertiesAdd: false,
    sidepanelProperty: false,
    sidepanelDevelopmentsAdd: false,
    sidepanelDevelopment: false,
    propertyDetailPanel: false,
    addNotesDeal: false,
    clientSidepanelStatus: false,
    listingSidepanelStatus: false,
    sidepanelAddRegister: false,
    popUpCalendar: false,
    selectedTask: null,
    customerOffers: null,
    activeProjectsView: "GridView",
    activeProjectsPreviousView: null,
    activePropertiesView: "GridView",
    activeDevelopmentsView: "GridView",
    activeScorecardGroupFilter: null,
    showCompletedProjectTasks: false,
    projectListViewStates: {
      qualified: true,
      contactMade: true,
      preparation: true,
      review: true,
      Finished: true,
      Done: true,
      archived: true,
    },
    projectAccordionsStates: {
      generalData: false,
      relatedPersons: false,
      marketingExpenses: false,
      invoices: false,
      tasks: false,
      contracts: false,
      notes: false,
      files: false,
    },
    propertyAccordionsStates: {
      generalData: false,
      immovables: false,
      tasks: false,
      notes: false,
      files: false,
    },
    developmentAccordionsStates: {
      generalData: false,
      immovables: false,
      interiors: false,
      tasks: false,
      notes: false,
      files: false,
    },
    adminSettingsAccordionsStates: {
      userRights: false,
      listingChannels: false,
      listingsDaysActive: false,
      SharedResources: false,
      ListingsCorporateImage: false,
      footerText: false,
      watermark: false,
      companyLogo: false,
    },
    brokerSettingsAccordionsStates: {
      feedback: false,
      achievements: false,
      myDetails: false,
      listingLastImage: false,
    },
    viewState: {
      listings: false,
    },
    navigationAfterAddingProject: false,
    invoiceSummariesWithVAT: false,
    calendarShowWeekends: true,
    calendarShowResources: false,
    calendarShowMyCalendar: true,
    calendarView: "week",
    selectedColleaguesCalendars: [],
    checkedUsers: {},
    userTasks: {},
    isCalendars: false,
    openAccordions: [],
    activeDevelopmentsTab: null,
    selectedBirthdayCategory: {},
    widgetUsers: {},
    allowOnlyFirstLevelForWidget: {}
  },
  mutations: {
    LOGIN(state, user) {
      state.user = user;
    },
    LOGINSELFUSER(state, selfServiceUser) {
      state.selfServiceUser = selfServiceUser;
    },
    LOGOUT(state) {
      state.previouslyLoggedInUser = state.user._id;
      resetState();
    },
    LOGOUTSELFUSER() {
      resetState();
    },
    SET_GROUPS(state, groups) {
      state.groups = groups;
    },
    SET_PROJECT(state, project) {
      state.project = project;
    },
    SET_INVOICE_SUMMARIES_SHOW_VAT(state, status) {
      state.invoiceSummariesWithVAT = status;
    },
    SET_CALENDAR_SHOW_WEEKENDS(state, status) {
      state.calendarShowWeekends = status;
    },
    SET_CALENDAR_SHOW_RESOURCES(state, status) {
      state.calendarShowResources = status;
    },
    SET_CALENDAR_SHOW_MY_CALENDAR(state, status) {
      state.calendarShowMyCalendar = status;
    },
    SET_CALENDAR_VIEW(state, viewName) {
      state.calendarView = viewName;
    },
    SET_COMPANY_POLICIES(state, policies) {
      state.user.companyPolicies = policies.policies;
      state.user.isItaly = policies.isItaly;
      state.user.homepage = policies.homepage;
    },
    SET_CUSTOMERS(state, customers) {
      state.customers = customers.reverse();
    },
    SET_CUSTOMERS_PAGINATION(state, customers) {
      state.customersPagination = customers;
    },
    SET_CUSTOMERS_COUNT(state, customersCount) {
      state.customersCount = customersCount;
    },
    SET_PARTNERS(state, partners) {
      state.partners = partners.reverse();
    },
    SET_ALL_CUSTOMERS(state, allCustomers) {
      state.allCustomers = allCustomers.reverse();
    },
    SET_LEADS_IDS_WITH_DUPLICATES(state, offersLeadsIdsWithDuplicates) {
      state.offersLeadsIdsWithDuplicates = offersLeadsIdsWithDuplicates;
    },
    ADD_LEAD_ID_TO_OFFERS_COUNT(state, leadId) {
      state.offersLeadsIdsWithDuplicates.push(leadId);
    },
    REMOVE_LEAD_ID_OF_OFFERS_COUNT(state, leadId) {
      state.offersLeadsIdsWithDuplicates.splice(leadId, 1);
    },
    SET_FILTERED_CUSTOMERS(state, customers) {
      state.customers = customers;
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_USER_FIELDS(state, user) {
      state.user = Object.assign({}, state.user, user)
    },
    SET_FILTERED_USERS(state, filteredUsers) {
      state.filteredUsers = filteredUsers;
    },
    SET_SHOW_COMPLETED_PROJECT_TASKS(state, status) {
      state.showCompletedProjectTasks = status;
    },
    SET_PROPERTY_SELECTED_BUILDING_INDEX(state, index) {
      state.propertySelectedBuildingIndex = index;
    },
    SET_ACCORDION_STATE(state, name) {
      state.projectAccordionsStates[name] =
        !state.projectAccordionsStates[name];
      console.log(state.projectAccordionsStates[name]);
    },
    SET_PROPERTY_ACCORDION_STATE(state, name) {
      state.propertyAccordionsStates[name] =
        !state.propertyAccordionsStates[name];
      console.log(state.propertyAccordionsStates[name]);
    },
    SET_DEVELOPMENT_ACCORDION_STATE(state, name) {
      state.developmentAccordionsStates[name] =
        !state.developmentAccordionsStates[name];
      console.log(state.developmentAccordionsStates[name]);
    },
    SET_ADMIN_SETTINGS_ACCORDION_STATE(state, name) {
      state.adminSettingsAccordionsStates[name] =
        !state.adminSettingsAccordionsStates[name];
      console.log(state.adminSettingsAccordionsStates[name]);
    },
    SET_BROKER_SETTINGS_ACCORDION_STATE(state, name) {
      state.brokerSettingsAccordionsStates[name] =
        !state.brokerSettingsAccordionsStates[name];
      console.log(state.brokerSettingsAccordionsStates[name]);
    },
    SET_PROJECT_LIST_VIEW_STATE(state, name) {
      state.projectListViewStates[name] = !state.projectListViewStates[name];
    },
    SET_ACTIVE_PROJECTS_VIEW(state, view) {
      state.activeProjectsView = view;
    },
    SET_ACTIVE_PROJECTS_PREVIOUS_VIEW(state, view) {
      state.activeProjectsPreviousView = view;
    },
    SET_ACTIVE_PROPERTIES_VIEW(state, view) {
      state.activePropertiesView = view;
    },
    SET_ACTIVE_DEVELOPMENTS_VIEW(state, view) {
      state.activeDevelopmentsView = view;
    },
    SET_ACTIVE_SCORECARD_GROUP_FILTER(state, filterValue) {
      state.activeScorecardGroupFilter = filterValue;
    },
    SET_SHOW_BACK_BUTTON(state) {
      state.showBackButton = true;
    },
    SET_NEW_ADDED_CONTACT(state, data) {
      state.newAddedContact = data;
    },
    SET_SIDEPANEL_ADD_CONTACT(state, status) {
      if (status === 0) {
        state.sidePanelContactAdd = false;
      } else {
        state.sidePanelContactAdd = !state.sidePanelContactAdd;
      }
    },
    SET_SIDEPANEL_ADD_CLIENTREGISTER(state, status) {
      if (status === 0) {
        state.sidepanelDealsAdd = false;
      } else {
        state.sidepanelDealsAdd = !state.sidepanelDealsAdd;
      }
    },
    SET_SIDEPANEL_DEALS(state, status) {
      if (status === 0) {
        state.sidepanelDealsAdd = false;
      } else {
        state.sidepanelDealsAdd = !state.sidepanelDealsAdd;
      }
    },
    SET_SIDEPANEL_DEAL(state, status) {
      if (status === 0) {
        state.sidepanelDeal = false;
      } else if (status === 1) {
        state.sidepanelDeal = true;
      } else {
        state.sidepanelDeal = !state.sidepanelDeal;
      }
    },
    SET_SIDEPANEL_PROPERTIES(state, status) {
      if (status === 0) {
        state.sidepanelPropertiesAdd = false;
      } else {
        state.sidepanelPropertiesAdd = !state.sidepanelPropertiesAdd;
      }
    },
    SET_SIDEPANEL_PROPERTY(state, status) {
      if (status === 0) {
        state.sidepanelProperty = false;
      } else if (status === 1) {
        state.sidepanelProperty = true;
      } else {
        state.sidepanelProperty = !state.sidepanelProperty;
      }
    },
    SET_SIDEPANEL_DEVELOPMENTS(state, status) {
      if (status === 0) {
        state.sidepanelDevelopmentsAdd = false;
      } else {
        state.sidepanelDevelopmentsAdd = !state.sidepanelDevelopmentsAdd;
      }
      console.log("STATUS", state.sidepanelDevelopmentsAdd);
    },
    SET_PROPERTY_DETAIL_PANEL(state, status) {
      if (status === 0) {
        state.propertyDetailPanel = false;
      } else {
        state.propertyDetailPanel = !state.propertyDetailPanel;
      }
    },
    SET_SIDEPANEL_DEVELOPMENT(state, status) {
      if (status === 0) {
        state.sidepanelDevelopment = false;
      } else if (status === 1) {
        state.sidepanelDevelopment = true;
      } else {
        state.sidepanelDevelopment = !state.sidepanelDevelopment;
      }
    },
    SET_SIDEPANEL_CLIENTREGISTER(state, status) {
      if (status === 0) {
        state.clientSidepanelStatus = false;
      } else {
        state.clientSidepanelStatus = !state.clientSidepanelStatus;
      }
    },
    SET_SIDEPANEL_LISTING(state, status) {
      if (status === 0) {
        state.listingSidepanelStatus = false;
      } else if (status === 1) {
        state.listingSidepanelStatus = true;
      } else {
        state.listingSidepanelStatus = !state.listingSidepanelStatus;
      }
    },
    SET_NAVIGATION_AFTER_ADDING_PROJECT(state, status) {
      state.navigationAfterAddingProject = status;
    },
    SET_MODAL_DEALS(state, status) {
      if (status === 0) {
        state.modalDeals = false;
      } else {
        state.modalDeals = !state.modalDeals;
      }
    },
    SET_PERSON_EDIT(state, status) {
      if (status === 0) {
        state.editPersons = false;
      } else {
        state.editPersons = !state.editPersons;
      }
    },
    SET_ADD_NOTES_DEAL(state, status) {
      if (status === 0) {
        state.addNotesDeal = false;
      } else {
        state.addNotesDeal = !state.addNotesDeal;
      }
    },
    SET_POPUP_CALENDAR(state, status) {
      if (status === 0) {
        state.popUpCalendar = false;
      } else {
        state.popUpCalendar = !state.popUpCalendar;
      }
    },
    SET_POPUP_SELECTEDTASK(state, task) {
      state.selectedTask = task;
    },
    SET_MICROSOFT_ACCOUNT(state, acc) {
      state.user.microsoft = acc;
    },
    SET_CUSTOMER_OFFERS(state, customerOffers) {
      state.customerOffers = customerOffers;
    },
    SET_LANGUAGE(state, lang) {
      state.language = lang;
      Cookies.set("language", lang, { expires: 999 });
    },
    SET_LANGUAGE_SET(state, languageSet) {
      state.languageSet = languageSet;
      Cookies.set("languageSet", languageSet, { expires: 999 });
    },
    SET_VERSION(state, version) {
      state.buildVersion = version;
    },
    UPDATE_CUSTOMERS_KEY(state) {
      state.customersUpdaterKey++;
    },
    UPDATE_PARTNERS_KEY(state) {
      state.partnersUpdaterKey++;
    },
    UPDATE_ALL_CUSTOMERS_KEY(state) {
      state.allCustomersUpdaterKey++;
    },
    UPDATE_USERS_KEY(state) {
      state.usersUpdaterKey++;
    },
    UPDATE_LISTINGS(state) {
      state.listingsUpdater++;
    },
    UPDATE_BACK_BUTTON(state) {
      state.showBackButton = false;
      state.backButtonUpdater++;
    },
    SET_VIEW_STATE(state, { viewName, value }) {
      state.viewState[viewName] = value;
    },
    SET_SELECTED_COLLEAGUES(state, payload) {
      state.selectedColleaguesCalendars = payload
    },
    SET_CHECKED_USERS(state, payload) {
      state.checkedUsers = payload;
    },
    SET_USER_TASKS(state, { userId, tasks }) {
      Vue.set(state.userTasks, userId, tasks);
    },
    REMOVE_USER_TASK(state, userId) {
      Vue.delete(state.userTasks, userId);
    },
    SET_IS_CALENDARS(state, value) {
      state.isCalendars = value;
    },
    SET_OPEN_ACCORDIONS(state, accordions) {
      state.openAccordions = accordions;
    },
    TOGGLE_ACCORDION(state, accordionId) {
      if (state.openAccordions.includes(accordionId)) {
        state.openAccordions = state.openAccordions.filter(id => id !== accordionId);
      } else {
        state.openAccordions.push(accordionId);
      }
    },
    SET_SELECTED_DEVELOPMENTS_TAB(state, tab) {
      state.activeDevelopmentsTab = tab;
    },
    SET_ACTIVE_DEVELOPMENTS_TAB_NULL(state) {
      state.activeDevelopmentsTab = null;
    },
    UPDATE_SELECTED_BIRTHDAY_CATEGORY(state, { widgetId, category }) {
      if (!state.selectedBirthdayCategory || typeof state.selectedBirthdayCategory !== 'object') {
        Vue.set(state, 'selectedBirthdayCategory', {});
      }
      Vue.set(state.selectedBirthdayCategory, widgetId, category);
    },
    SET_WIDGET_USERS(state, { widgetId, users }) {
      if (!state.widgetUsers || typeof state.widgetUsers !== 'object') {
        Vue.set(state, 'widgetUsers', {});
      }
      Vue.set(state.widgetUsers, widgetId, users);
    },
    SET_ALLOW_ONLY_FIRST_LEVEL_FOR_WIDGET(state, { widgetId, allowOnlyFirstLevel }) {
      Vue.set(state.allowOnlyFirstLevelForWidget, widgetId, allowOnlyFirstLevel);
    },
  },
  actions: {
    async getProject({commit}, projectId) {
      const response = await axios.get(`/api/project/${projectId}`);
      if (response.status === 200) {
        commit("SET_PROJECT", response.data);
      } else {
        throw new Error('No access')
      }
    },
    async getGroups({commit}) {
      const response = await axios.get('/api/groups');
      if (response && response.status === 200) {
        commit("SET_GROUPS", response.data)
      }
    },
    async setInvoiceSummariesShowVAT({ commit }, status) {
      commit("SET_INVOICE_SUMMARIES_SHOW_VAT", status);
    },
    async setCalendarShowWeekends({ commit }, status) {
      commit("SET_CALENDAR_SHOW_WEEKENDS", status);
    },
    async setCalendarShowResources({ commit }, status) {
      commit("SET_CALENDAR_SHOW_RESOURCES", status);
    },
    async setCalendarShowMyCalendar({ commit }, status) {
      commit("SET_CALENDAR_SHOW_MY_CALENDAR", status);
    },
    async setCalendarView({ commit }, status) {
      commit("SET_CALENDAR_VIEW", status);
    },
    async getCompanyPolicies({ commit }) {
      const policies = await adminApi.getPolicies();
      commit("SET_COMPANY_POLICIES", policies);
    },
    async setPropertySelectedBuildingIndex({ commit }, index) {
      commit("SET_PROPERTY_SELECTED_BUILDING_INDEX", index);
    },
    async updateListings({ commit }) {
      commit("UPDATE_LISTINGS");
    },
    async showPopUp({ commit }, status) {
      commit("SET_POPUP_CALENDAR", status);
    },
    async setShowCompletedProjectTasks({ commit }, status) {
      commit("SET_SHOW_COMPLETED_PROJECT_TASKS", status);
    },
    async setAccordionState({ commit }, name) {
      commit("SET_ACCORDION_STATE", name);
    },
    async setPropertyAccordionState({ commit }, name) {
      commit("SET_PROPERTY_ACCORDION_STATE", name);
    },
    async setDevelopmentAccordionState({ commit }, name) {
      commit("SET_DEVELOPMENT_ACCORDION_STATE", name);
    },
    async setAdminSettingsAccordionState({ commit }, name) {
      commit("SET_ADMIN_SETTINGS_ACCORDION_STATE", name);
    },
    async setBrokerSettingsAccordionState({ commit }, name) {
      commit("SET_BROKER_SETTINGS_ACCORDION_STATE", name);
    },
    async setProjectListViewState({ commit }, name) {
      commit("SET_PROJECT_LIST_VIEW_STATE", name);
    },
    async setActiveProjectsView({ commit }, view) {
      commit("SET_ACTIVE_PROJECTS_VIEW", view);
    },
    async setActiveProjectsPreviousView({ commit }, view) {
      commit("SET_ACTIVE_PROJECTS_PREVIOUS_VIEW", view);
    },
    async setActivePropertiesView({ commit }, view) {
      commit("SET_ACTIVE_PROPERTIES_VIEW", view);
    },
    async setActiveDevelopmentsView({ commit }, view) {
      commit("SET_ACTIVE_DEVELOPMENTS_VIEW", view);
    },
    async setActiveScorecardGroupFilter({ commit }, filterValue) {
      commit("SET_ACTIVE_SCORECARD_GROUP_FILTER", filterValue);
    },
    async showSelectedTask({ commit }, task) {
      commit("SET_POPUP_SELECTEDTASK", task);
    },
    async setShowBackButton({ commit }) {
      commit("SET_SHOW_BACK_BUTTON");
    },
    async updateBackButton({ commit }) {
      commit("UPDATE_BACK_BUTTON");
    },
    async saveListing(listing) {
      const response = await axios.post("/api/listing", {
        body: {
          dealType: listing.dealType,
          estateType: listing.estateType,
          county: listing.county,
          city: listing.city,
          district: listing.district,
          cityPart: listing.cityPart,
          street: listing.street,
          houseNumber: listing.houseNumber,
          apartmentNumber: listing.apartmentNumber,
          showHouseNumber: listing.showHouseNumber,
          showApartmentNumber: listing.showApartmentNumber,
          cadastralNumber: listing.cadastralNumber,
          estateNumber: listing.estateNumber,
          buildYear: listing.buildYear,
          floors: listing.floors,
          floor: listing.floor,
          rooms: listing.rooms,
          bedrooms: listing.bedrooms,
          generalSurfaceArea: listing.generalSurfaceArea,
          price: listing.price,
          condition: listing.condition,
          ownershipForm: listing.ownershipForms,
          buildingMaterial: listing.buildingMaterial,
          energyLabel: listing.energyLabel,
          expensesInSummer: listing.expensesInSummer,
          expensesInWinter: listing.expensesInWinter,
          listingOwnerName: listing.listingOwnerName,
          listingOwnerEmail: listing.listingOwnerEmail,
          listingOwnerPhone: listing.listingOwnerPhone,
          amBroker: listing.amBroker,
          additionalInfo: {
            reserved: listing.additionalInfo.reserved,
            tradable: listing.additionalInfo.tradable,
            doNotAllowToCopyListing:
              listing.additionalInfo.doNotAllowToCopyListing,
            brokersNotAllowedToDisturb:
              listing.additionalInfo.brokersNotAllowedToDisturb,
          },
          slogan: listing.slogan,
          description: listing.description,
          image: listing.image,
        },
      });
      console.log(response);
    },
    setUser({ commit }, user) {
      commit("LOGIN", user);
    },
    async login({ commit }, user) {
      let loginCredentials = user;
      const response = await axios.post("/api/login", loginCredentials, {
        withCredentials: true,
      });
      commit("LOGIN", response.data.user);
    },
    setCustomerOffers({ commit }, customerOffers) {
      commit("SET_CUSTOMER_OFFERS", customerOffers);
    },
    setMicrosoftAccount({ commit }, microsoftData) {
      commit("SET_MICROSOFT_ACCOUNT", microsoftData);
    },

    async loginSelfUser({ commit }, selfServiceUser) {
      let loginCredentials = selfServiceUser;
      const response = await axios.post(
        "/api/login/self-service",
        loginCredentials,
        {
          withCredentials: true,
        }
      );
      commit("LOGINSELFUSER", response.data.selfServiceUser);
      return response;
    },
    async previewSelfServiceUser({ commit }, selfServiceUserId) {
      let credentials = {
        contactId: selfServiceUserId,
      };
      const response = await axios.post(
        "/api/preview/self-service",
        credentials
      );
      commit("LOGINSELFUSER", response.data.selfServiceUser);
      return response;
    },
    logout({ commit }) {
      commit("LOGOUT");
    },
    logoutSelfUser({ commit }) {
      commit("LOGOUTSELFUSER");
    },
    async changeModalStateDeals({ commit }, status) {
      commit("SET_MODAL_DEALS", status);
    },
    async editPerson({ commit }, status) {
      commit("SET_PERSON_EDIT", status);
    },
    async addNotesDeal({ commit }, status) {
      commit("SET_ADD_NOTES_DEAL", status);
    },
    async getCustomers({ commit }) {
      const customers = await contactApi.getAllContacts();
      commit("SET_CUSTOMERS", customers.customers);
    },
    async getCustomersByPagination({ commit }, data) {
      const customers = await contactApi.getContactsByPagination(data);
      commit("SET_CUSTOMERS_PAGINATION", customers.filteredContacts);
      commit("SET_CUSTOMERS_COUNT", customers.filteredContactsCount);
      // commit("UPDATE_CUSTOMERS_KEY");
    },
    async getPartners({ commit }) {
      const partners = await contactApi.getAllPartners();
      commit("SET_PARTNERS", partners.partners);
      commit("UPDATE_PARTNERS_KEY");
    },
    async getAllCustomers({ commit }) {
      const allCustomers = await contactApi.getAllCustomers();
      commit("SET_ALL_CUSTOMERS", allCustomers.customers);
      commit("UPDATE_ALL_CUSTOMERS_KEY");
    },
    async setOffersLeadsIdsWithDuplicates(
      { commit },
      offersLeadsIdsWithDuplicates
    ) {
      commit("SET_LEADS_IDS_WITH_DUPLICATES", offersLeadsIdsWithDuplicates);
    },
    async addLeadIdToOffersCount({ commit }, leadId) {
      commit("ADD_LEAD_ID_TO_OFFERS_COUNT", leadId);
    },
    async removeLeadInOffersCount({ commit }, leadId) {
      commit("REMOVE_LEAD_ID_OF_OFFERS_COUNT", leadId);
    },
    async setFilteredCustomers({ commit }, customers) {
      commit("SET_FILTERED_CUSTOMERS", customers);
    },
    async setFilteredUsers({ commit }, users) {
      commit("SET_FILTERED_USERS", users);
    },
    async getUsers({ commit }) {
      const response = await axios.get("/api/users");
      commit("SET_USERS", response.data);
      commit("UPDATE_USERS_KEY");
    },
    async getUser({commit}, {userId, arrayOfFieldKeys}) {
      const response = await axios.post(`/api/user/${userId}`, {projection: arrayOfFieldKeys});
      commit("SET_USER_FIELDS", response.data)
    },
    async removeFilterById({ commit }, { filterName, filterId }) {
      commit("REMOVE_FILTER_BY_ID", { filterName, filterId });
    },
    async toggleContactAddPanel({ commit }, status) {
      commit("SET_SIDEPANEL_ADD_CONTACT", status);
    },
    async setNewAddedContact({ commit }, data) {
      commit("SET_NEW_ADDED_CONTACT", data);
    },
    async changeClientAddModal({ commit }, status) {
      commit("SET_SIDEPANEL_ADD_CLIENTREGISTER", status);
    },
    async changeSidepanelStateDeals({ commit }, status) {
      commit("SET_SIDEPANEL_DEALS", status);
    },
    async changeSidepanelStateDeal({ commit }, status) {
      commit("SET_SIDEPANEL_DEAL", status);
    },
    async changeSidepanelStateProperties({ commit }, status) {
      commit("SET_SIDEPANEL_PROPERTIES", status);
    },
    async changeSidepanelStateProperty({ commit }, status) {
      commit("SET_SIDEPANEL_PROPERTY", status);
    },
    async changeSidepanelStateDevelopments({ commit }, status) {
      commit("SET_SIDEPANEL_DEVELOPMENTS", status);
    },
    async togglePropertyDetailPanel({ commit }, status) {
      commit("SET_PROPERTY_DETAIL_PANEL", status);
    },
    async changeSidepanelStateDevelopment({ commit }, status) {
      commit("SET_SIDEPANEL_DEVELOPMENT", status);
    },
    async setClientSidepanel({ commit }, status) {
      commit("SET_SIDEPANEL_CLIENTREGISTER", status);
    },
    async setListingSidepanel({ commit }, status) {
      commit("SET_SIDEPANEL_LISTING", status);
    },
    async setNavigationAfterAddingProject({ commit }, status) {
      commit("SET_NAVIGATION_AFTER_ADDING_PROJECT", status);
    },
    async setListingFilter({ commit }, filter) {
      commit("SET_LISTING_FILTER", filter);
    },
    /* async setCustomerFilter({ commit }, filter) {
          commit("SET_CUSTOMER_FILTER", filter);
        }, */
    /* async makeFilterSector({ commit }, sector) {
          commit("SET_CUSTOMER_SECTOR", sector);
        }, */
    async setLanguage({ commit }, lang, languageSet = false) {
      commit("SET_LANGUAGE", lang);
      if (languageSet) {
        commit("SET_LANGUAGE_SET", languageSet);
      }
    },
    async setViewState({ commit }, { viewName, value }) {
      commit("SET_VIEW_STATE", { viewName, value });
    },
    async setLanguageSet({ commit }, languageSet = false) {
      commit("SET_LANGUAGE_SET", languageSet);
    },
    async setBuildVersion({ commit }, version) {
      commit("SET_VERSION", version);
    },
    setSelectedColleagues({ commit }, payload) {
      commit('SET_SELECTED_COLLEAGUES', payload)
    },
    setCheckedUsers({ commit }, payload) {
      commit('SET_CHECKED_USERS', payload);
    },
    setUserTasks({ commit }, payload) {
      commit('SET_USER_TASKS', payload);
    },
    removeUserTask({ commit }, userId) {
      commit('REMOVE_USER_TASK', userId);
    },
    toggleIsCalendars({ commit, state }) {
      commit('SET_IS_CALENDARS', !state.isCalendars);
    },
    toggleAccordion({ commit }, accordionId) {
      commit('TOGGLE_ACCORDION', accordionId);
    },
    setOpenAccordions({ commit }, accordions) {
      commit('SET_OPEN_ACCORDIONS', accordions);
    },
    setSelectedDevelopmentsTab({ commit }, tab) {
      commit('SET_SELECTED_DEVELOPMENTS_TAB', tab);
    },
    clearActiveDevelopmentsTab({ commit }) {
      commit('SET_ACTIVE_DEVELOPMENTS_TAB_NULL');
    },
    updateSelectedBirthdayCategory({ commit }, { widgetId, category }) {
      commit('UPDATE_SELECTED_BIRTHDAY_CATEGORY', { widgetId, category });
    },
    setWidgetUsers({ commit }, { widgetId, users }) {
      commit('SET_WIDGET_USERS', { widgetId, users });
    },
    setAllowOnlyFirstLevelForWidget({ commit }, { widgetId, allowOnlyFirstLevel }) {
      commit('SET_ALLOW_ONLY_FIRST_LEVEL_FOR_WIDGET', { widgetId, allowOnlyFirstLevel });
    },
  },
  getters: {
    sidePanelContactAdd(state) {
      return state.sidePanelContactAdd;
    },
    newAddedContact(state) {
      return state.newAddedContact;
    },
    invoiceSummariesWithVat(state) {
      return state.invoiceSummariesWithVAT;
    },
    calendarShowWeekends(state) {
      return state.calendarShowWeekends;
    },
    calendarShowResources(state) {
      return state.calendarShowResources;
    },
    calendarShowMyCalendar(state) {
      return state.calendarShowMyCalendar;
    },
    calendarView(state) {
      return state.calendarView;
    },
    viewState(state) {
      return state.viewState;
    },
    buildVersion(state) {
      return state.buildVersion;
    },
    customersUpdaterKey(state) {
      return state.customersUpdaterKey;
    },
    customerOffers(state) {
      return state.customerOffers;
    },
    usersUpdaterKey(state) {
      return state.usersUpdaterKey;
    },
    listingsUpdater(state) {
      return state.listingsUpdater;
    },
    showCompletedProjectTasks(state) {
      return state.showCompletedProjectTasks;
    },
    projectAccordionsStates(state) {
      return state.projectAccordionsStates;
    },
    propertyAccordionsStates(state) {
      return state.propertyAccordionsStates;
    },
    developmentAccordionsStates(state) {
      return state.developmentAccordionsStates;
    },
    adminSettingsAccordionsStates(state) {
      return state.adminSettingsAccordionsStates;
    },
    brokerSettingsAccordionsStates(state) {
      return state.brokerSettingsAccordionsStates;
    },
    propertySelectedBuildingIndex(state) {
      return state.propertySelectedBuildingIndex;
    },
    projectListViewStates(state) {
      return state.projectListViewStates;
    },
    activeProjectsView(state) {
      return state.activeProjectsView;
    },
    activeProjectsPreviousView(state) {
      return state.activeProjectsPreviousView;
    },
    activePropertiesView(state) {
      return state.activePropertiesView;
    },
    activeDevelopmentsView(state) {
      return state.activeDevelopmentsView;
    },
    activeScorecardGroupFilter(state) {
      return state.activeScorecardGroupFilter;
    },
    user(state) {
      return state.user;
    },
    selfServiceUser(state) {
      return state.selfServiceUser;
    },
    backButtonUpdater(state) {
      return state.backButtonUpdater;
    },
    showBackButton(state) {
      console.log(state.showBackButton);
      return state.showBackButton;
    },
    users(state) {
      return state.users;
    },
    groups(state) {
      return state.groups
    },
    selectedTask(state) {
      return state.selectedTask;
    },
    language(state) {
      return state.language;
    },
    languageSet(state) {
      return state.languageSet;
    },
    offersLeadsCount(state) {
      return [...new Set(state.offersLeadsIdsWithDuplicates)].length;
    },
    customers(state) {
      return state.customers;
    },
    customersPagination(state) {
      return state.customersPagination;
    },
    customersCount(state) {
      return state.customersCount;
    },
    partners(state) {
      return state.partners;
    },
    allCustomers(state) {
      return state.allCustomers;
    },
    filteredCustomers(state) {
      return state.filteredCustomers;
    },
    filteredUsers(state) {
      return state.filteredUsers;
    },
    customerFilter(state) {
      return state.customerFilter;
    },
    sidepanelDealsAdd(state) {
      return state.sidepanelDealsAdd;
    },
    sidepanelDeal(state) {
      return state.sidepanelDeal;
    },
    sidepanelPropertiesAdd(state) {
      return state.sidepanelPropertiesAdd;
    },
    sidepanelProperty(state) {
      return state.sidepanelProperty;
    },
    sidepanelDevelopmentsAdd(state) {
      return state.sidepanelDevelopmentsAdd;
    },
    sidepanelDevelopment(state) {
      return state.sidepanelDevelopment;
    },
    propertyDetailPanel(state) {
      return state.propertyDetailPanel;
    },
    clientSidepanelStatus(state) {
      return state.clientSidepanelStatus;
    },
    listingSidepanelStatus(state) {
      return state.listingSidepanelStatus;
    },
    navigationAfterAddingProject(state) {
      return state.navigationAfterAddingProject;
    },
    sidepanelAddRegister(state) {
      return state.sidepanelAddRegister;
    },
    modalDeals(state) {
      return state.modalDeals;
    },
    popUpCalendar(state) {
      return state.popUpCalendar;
    },
    editPersons(state) {
      return state.editPersons;
    },
    selectedColleaguesCalendars(state) {
      return state.selectedColleaguesCalendars
    },
    checkedUsers(state) {
      return state.checkedUsers;
    },
    getUserTasks(state) {
      return state.userTasks
    },
    isCalendars(state) {
      return state.isCalendars
    },
    openAccordions(state) {
      return  state.openAccordions
    },
    activeDevelopmentsTab(state) {
      return state.activeDevelopmentsTab;
    },
    getSelectedBirthdayCategory: (state) => (widgetId) => {
      return state.selectedBirthdayCategory[widgetId] || [];
    },
    getWidgetUsers: state => widgetId => {
      return state.widgetUsers[widgetId] || [];
    },
    getAllowOnlyFirstLevelForWidget: state => widgetId => {
      return state.allowOnlyFirstLevelForWidget[widgetId] || false;
    },
  },
};

const store = new Vuex.Store({
  modules: {
    a: moduleA,
    b: moduleB,
    c: moduleC,
    d: moduleD,
    e: propDevModule,
    f: invoicesModule,
    options: { ...optionsModule, namespaced: true },
  },

  plugins: [
    createPersistedState({
      paths: ["a", "d"],
      getItem: (key) => Cookies.getJSON(key),
      setItem: (key, value) =>
        Cookies.set(key, value, { expires: 365, secure: false }),
      removeItem: (key) => Cookies.remove(key),
    }),
  ],
});
export default store;

async function resetState() {
  const existingMsal = store.state.c.msalObject;
  const prevLoginUser = _.cloneDeep(store.state.a.previouslyLoggedInUser);
  store.state.b = store._modules.root.state.b;
  store.state.a = store._modules.root.state.a;
  store.state.a.previouslyLoggedInUser = prevLoginUser;
  store.state.a.user = null;
  store.state.c.msalObject = existingMsal;
  await getBuildVersion();
}
async function getBuildVersion() {
  const environment = window.location.host.split(".")[0];
  if (environment.includes("localhost")) return;
  const versionResponse = await axios.get(`/api/version/${environment}`);
  store.state.a.buildVersion = versionResponse.data.dateChanged;
}
