var render = function render(){
  var _vm$user$access, _vm$user$access$featu, _vm$user$access$featu2, _vm$user$access$featu3, _vm$user$access$featu4, _vm$user$access$featu5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', {
    class: {
      'top-row': _vm.$route.name !== 'DevelopmentGeneral',
      'top-row padding-left-96': _vm.$route.name === 'DevelopmentGeneral'
    }
  }, [_c('div', {
    staticClass: "left-top-row"
  }, [_vm.showBackButton ? _c('div', {
    staticClass: "back-button",
    on: {
      "click": _vm.updateBackButton
    }
  }, [_c('img', {
    attrs: {
      "height": "24",
      "width": "24",
      "src": require('@/assets/images/chevron-left.svg'),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t("Back")))])]) : _vm._e()]), _c('div', {
    staticClass: "right-top-row"
  }, [_c('button', {
    staticClass: "transparent-button",
    staticStyle: {
      "margin-right": "40px"
    },
    attrs: {
      "name": "send-offers-button"
    },
    on: {
      "click": _vm.openOffersSidepanel
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/images/ads_20_20.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("SendOffer")) + " ")]), _c('div', {
    staticClass: "offers-amount content-small",
    class: {
      'offers-amount-red': _vm.offersLeadsCount > 0
    }
  }, [_vm._v(" " + _vm._s(_vm.offersLeadsCount) + " ")])]), !_vm.user.config || _vm.user.config && (_vm$user$access = _vm.user.access) !== null && _vm$user$access !== void 0 && (_vm$user$access$featu = _vm$user$access.features) !== null && _vm$user$access$featu !== void 0 && (_vm$user$access$featu2 = _vm$user$access$featu.modules) !== null && _vm$user$access$featu2 !== void 0 && (_vm$user$access$featu3 = _vm$user$access$featu2.calendar) !== null && _vm$user$access$featu3 !== void 0 && (_vm$user$access$featu4 = _vm$user$access$featu3.features) !== null && _vm$user$access$featu4 !== void 0 && (_vm$user$access$featu5 = _vm$user$access$featu4.outlook) !== null && _vm$user$access$featu5 !== void 0 && _vm$user$access$featu5.enabled ? _c('v-menu', {
    attrs: {
      "nudge-bottom": 12,
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var menu = _ref.on,
          attrs = _ref.attrs;
        return [_c('img', _vm._g(_vm._b({
          staticStyle: {
            "width": "24px",
            "height": "24px",
            "cursor": "pointer",
            "margin-right": "16px"
          },
          attrs: {
            "src": require("../assets/images/Microsoft_logo.svg"),
            "alt": ""
          }
        }, 'img', attrs, false), Object.assign({}, menu)))];
      }
    }], null, false, 2871235226)
  }, [_c('div', _vm._g({
    staticClass: "sync-outlook",
    staticStyle: {
      "padding": "8px"
    }
  }, !_vm.user.microsoft ? {
    click: function () {
      return _vm.loginMicrosoft();
    }
  } : {
    click: function ($event) {
      return $event.preventDefault();
    }
  }), [_c('div', {
    staticClass: "data-row"
  }, [_vm.user.microsoft ? _c('img', {
    attrs: {
      "src": require("../assets/images/checkmark-14.svg"),
      "alt": ""
    }
  }) : _vm._e(), _c('div', [_c('p', {
    class: {
      'content-bold sync-outlook-text': _vm.user.microsoft,
      'content-small sync-outlook-text not-synced': !_vm.user.microsoft
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ConnectWithMicrosoftOutlook")) + " ")]), _vm.user.microsoft ? _c('p', {
    staticClass: "content-small sync-outlook-text"
  }, [_vm._v(" " + _vm._s(_vm.user.microsoft.username) + " ")]) : _vm._e()])]), _vm.user.microsoft ? _c('p', {
    staticClass: "content-small remove-outlook",
    on: {
      "click": _vm.logoutMicrosoft
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Remove")) + " ")]) : _vm._e()])]) : _vm._e(), _vm.user ? _c('Notifications', {
    staticStyle: {
      "margin-right": "12px"
    }
  }) : _vm._e(), _c('Info', {
    staticStyle: {
      "margin-right": "40px"
    }
  }), _c('LanguageToggle', {
    staticStyle: {
      "margin-right": "40px"
    }
  }), _c('UserSettingsButtonDropdown', {
    attrs: {
      "current-user": _vm.user
    }
  }), _c('div', [_vm.offersSidepanelOpen ? _c('OffersSidepanel', {
    on: {
      "closePanel": function ($event) {
        _vm.offersSidepanelOpen = false;
      },
      "openClientPanel": _vm.openClientFromOffers
    }
  }) : _vm._e(), _vm.clientSidepanelOpen ? _c('ClientSidepanel', {
    attrs: {
      "customer-data": _vm.offersCustomerId,
      "only-panel": true
    },
    on: {
      "funnelRemoved": function ($event) {
        return _vm.getListings();
      },
      "panelClosed": function ($event) {
        _vm.clientSidepanelOpen = false;
      }
    }
  }) : _vm._e()], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }