<template>
  <div class="language-toggle">
    <div
        v-for="_language in languages"
        :key="'language-' + _language.value"
        class="language-item content-small"
        :class="{
          'chosen-language': $i18n.locale === _language.value
        }"
        @click="changeLocale(_language)"
    >
      {{ _language.name }}
    </div>
  </div>
</template>
<script>

import {mapActions} from "vuex";

export default {
  name: "LanguageToggle",
  components: {
  },
  props: [],
  data() {
    return {
      languages: [
        {name: 'Est', value: 'et'},
        {name: 'Eng', value: 'en'},
      ],
    };
  },
  created() {

  },
  mounted() {
  },
  computed: {
    // ...mapGetters(["language"]),
  },

  methods: {
    ...mapActions(["setLanguage", "setLanguageSet"]),
    changeLocale(loc) {
      this.setLanguage(loc.value);
      this.setLanguageSet(true);
      this.$i18n.locale = loc.value;
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.chosen-language {
  background: $grey !important;
}

.language-item {
  background: white;
  min-width: 24px;
  padding: 8px 12px;
  cursor: pointer;
}

.language-toggle {
  background: $grey500;
  border-radius: 8px;
  border: 1px solid $grey500;
  overflow: hidden;
  display: flex;
  gap: 1px;
}

</style>
