var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay-addmodal",
    attrs: {
      "id": "overlay-customer-addmodal"
    }
  }, [_c('v-card', {
    staticClass: "modal-wrapper",
    staticStyle: {
      "border-radius": "0"
    }
  }, [_c('div', {
    staticClass: "sticky-header"
  }, [_c('div', {
    staticClass: "modal-header",
    staticStyle: {
      "padding-left": "24px"
    }
  }, [!_vm.isEdit ? _c('h4', {
    staticClass: "modal-title"
  }, [_vm._v(_vm._s(_vm.$t("AddContact")))]) : _vm._e(), _vm.isEdit ? _c('h4', {
    staticClass: "modal-title"
  }, [_vm._v(_vm._s(_vm.$t("ChangeClient")))]) : _vm._e()]), _c('v-divider', {
    staticStyle: {
      "top": "98px",
      "z-index": "111",
      "position": "fixed",
      "width": "640px"
    }
  })], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    staticStyle: {
      "padding-bottom": "106px"
    }
  }, [_c('v-card', {
    staticClass: "form-wrapper",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "98px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ContactDetails")))])]), _c('v-row', {
    staticClass: "input-wrapper",
    staticStyle: {
      "margin-top": "12px",
      "max-height": "fit-content"
    }
  }, [_c('v-row', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap",
      "gap": "12px",
      "margin-top": "4px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": 'sidepanel-contact-firstname',
      "error-message": _vm.errors && _vm.errors.firstName ? _vm.$t('FormErrors.' + _vm.errors.firstName) : '',
      "error": _vm.isFilledFirstName && !_vm.isFieldValid('firstNameNew'),
      "type": "text",
      "placeholder": _vm.$t('FirstName'),
      "required": "true"
    },
    model: {
      value: _vm.privatePerson.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.privatePerson, "firstName", $$v);
      },
      expression: "privatePerson.firstName"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": "sidepanel-contact-lastname",
      "placeholder": _vm.$t('LastName')
    },
    model: {
      value: _vm.privatePerson.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.privatePerson, "lastName", $$v);
      },
      expression: "privatePerson.lastName"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "0px !important"
    }
  }, [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ContactIsPartner')
    },
    on: {
      "click": function ($event) {
        return _vm.changeContactPartnerStatus($event, true);
      }
    },
    model: {
      value: _vm.privatePerson.contactIsPartner,
      callback: function ($$v) {
        _vm.$set(_vm.privatePerson, "contactIsPartner", $$v);
      },
      expression: "privatePerson.contactIsPartner"
    }
  })], 1), _c('BaseDropdown', {
    ref: "visibilityDropDown",
    attrs: {
      "disabled": _vm.customerData && !_vm.user.isAdmin && !_vm.user.isGroupAdmin && _vm.privatePerson.creator.userId !== _vm.user._id,
      "id": 'sidepanel-contact-ownership',
      "items": _vm.clientOwnerships,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('Visibility.Label'),
      "show-dropdown-arrow": true
    },
    model: {
      value: _vm.privatePerson.clientOwnership,
      callback: function ($$v) {
        _vm.$set(_vm.privatePerson, "clientOwnership", $$v);
      },
      expression: "privatePerson.clientOwnership"
    }
  }), _vm.privatePerson.clientOwnership === 'GROUP' ? _c('v-col', {
    staticStyle: {
      "padding": "0"
    }
  }, [_c('DropdownMulti', {
    attrs: {
      "items": _vm.contactGroupSelection,
      "selected-items": _vm.preselectedGroups,
      "height": '52px',
      "display-key": 'name',
      "value-key": 'name',
      "field-type": _vm.$t('Group'),
      "enable-search": true
    },
    on: {
      "input": _vm.updateSelectedItems
    }
  })], 1) : _vm._e(), _vm._l(_vm.privatePerson.emails, function (email, index) {
    return _c('v-row', {
      key: index,
      staticStyle: {
        "gap": "26px",
        "flex-wrap": "nowrap",
        "width": "100%",
        "margin-top": "0"
      }
    }, [_c('BaseInput', {
      attrs: {
        "id": 'sidepanel-contact-email-' + index,
        "icon-left": "email_20_20.svg",
        "name": 'email-input-' + index,
        "placeholder": _vm.$t('CustomerField.email'),
        "spaces-removal": true
      },
      model: {
        value: email.email,
        callback: function ($$v) {
          _vm.$set(email, "email", $$v);
        },
        expression: "email.email"
      }
    }), _c('div', {
      staticClass: "listing-title-row",
      staticStyle: {
        "cursor": "pointer",
        "margin-top": "18px",
        "margin-right": "14px"
      }
    }, [index == 0 ? _c('img', {
      attrs: {
        "name": "extra-email-button",
        "src": require("../../assets/images/PlusButton.svg"),
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.addEmptyForm();
        }
      }
    }) : _vm._e(), index != 0 ? _c('img', {
      attrs: {
        "src": require("../../assets/images/MinusButton.svg"),
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeForm(index);
        }
      }
    }) : _vm._e()])], 1);
  }), _vm._l(_vm.privatePerson.phoneNumbers, function (phoneNumber, index) {
    return _c('v-row', {
      key: phoneNumber._id,
      staticStyle: {
        "gap": "26px",
        "flex-wrap": "nowrap",
        "margin-top": "0",
        "width": "100%"
      }
    }, [_c('BaseInput', {
      attrs: {
        "id": 'sidepanel-contact-phone-' + index,
        "icon-left": "phone_20_20.svg",
        "placeholder": _vm.$t('CustomerField.phoneNumber')
      },
      model: {
        value: phoneNumber.phoneNumber,
        callback: function ($$v) {
          _vm.$set(phoneNumber, "phoneNumber", $$v);
        },
        expression: "phoneNumber.phoneNumber"
      }
    }), _c('div', {
      staticClass: "listing-title-row",
      staticStyle: {
        "cursor": "pointer",
        "margin-top": "18px",
        "margin-right": "14px"
      }
    }, [index == 0 ? _c('img', {
      attrs: {
        "src": require("../../assets/images/PlusButton.svg"),
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.addEmptyFormPhoneNumbers();
        }
      }
    }) : _vm._e(), index != 0 ? _c('img', {
      attrs: {
        "src": require("../../assets/images/MinusButton.svg"),
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeFormPhoneNumbers(index);
        }
      }
    }) : _vm._e()])], 1);
  }), _c('v-row', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Organization")))])]), _vm._l(_vm.privatePerson.organizations, function (organization, index) {
    return _c('v-row', {
      key: organization._id,
      staticStyle: {
        "margin-top": "0",
        "background-color": "#f4f5f7",
        "width": "480px",
        "border-radius": "8px",
        "padding": "16px"
      }
    }, [_c('v-row', {
      staticStyle: {
        "display": "flex",
        "align-items": "center",
        "justify-content": "space-between"
      }
    }, [_vm.userCountry === 'ee' ? _c('v-radio-group', {
      attrs: {
        "hide-details": "",
        "row": ""
      },
      model: {
        value: _vm.privatePerson.organizations[index].organizationSearchingMethod,
        callback: function ($$v) {
          _vm.$set(_vm.privatePerson.organizations[index], "organizationSearchingMethod", $$v);
        },
        expression: "\n                    privatePerson.organizations[index]\n                      .organizationSearchingMethod\n                  "
      }
    }, [_c('v-radio', {
      attrs: {
        "label": _vm.$t('Estonian'),
        "color": "orange darken-3",
        "value": 'Estonian'
      }
    }), _c('v-radio', {
      attrs: {
        "label": _vm.$t('Generic'),
        "value": 'Generic',
        "color": "orange darken-3"
      }
    })], 1) : _vm._e(), _c('div', {
      staticClass: "listing-title-row",
      staticStyle: {
        "cursor": "pointer"
      }
    }, [index !== 0 ? _c('v-row', {
      staticClass: "clear-row"
    }, [_c('div', {
      staticClass: "clear-button",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.removeFromOrganizations(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../assets/images/close.svg"),
        "alt": ""
      }
    }), _c('p', {
      staticClass: "content-small"
    }, [_vm._v("clear")])])]) : _vm._e()], 1)], 1), organization.organizationSearchingMethod === 'Estonian' ? _c('v-row', {
      staticStyle: {
        "width": "100%"
      }
    }, [_c('BaseDropdown', {
      attrs: {
        "id": 'sidepanel-contact-organization-' + index,
        "placeholder": _vm.$t('OrganizationsName'),
        "dropdown-allow-null": true,
        "type": "organizationsearch",
        "dropdown-offset-y": 52
      },
      model: {
        value: _vm.privatePerson.organizations[index],
        callback: function ($$v) {
          _vm.$set(_vm.privatePerson.organizations, index, $$v);
        },
        expression: "privatePerson.organizations[index]"
      }
    })], 1) : _vm._e(), organization.organizationSearchingMethod === 'Generic' ? _c('v-row', {
      staticStyle: {
        "margin-top": "12px"
      }
    }, [_c('v-row', {
      staticStyle: {
        "width": "100%"
      }
    }, [_c('BaseInput', {
      attrs: {
        "placeholder": _vm.$t('OrganizationsName')
      },
      model: {
        value: organization.organization,
        callback: function ($$v) {
          _vm.$set(organization, "organization", $$v);
        },
        expression: "organization.organization"
      }
    })], 1), _c('v-row', {
      staticStyle: {
        "width": "100%"
      }
    }, [_c('GoogleAddressSearch', {
      attrs: {
        "placeholder": _vm.$t('Object') + ' ' + _vm.$t('Address').toLowerCase()
      },
      model: {
        value: organization.address,
        callback: function ($$v) {
          _vm.$set(organization, "address", $$v);
        },
        expression: "organization.address"
      }
    })], 1), _c('v-row', {
      staticStyle: {
        "margin-top": "16px",
        "gap": "12px",
        "width": "100%",
        "display": "flex",
        "flex-wrap": "nowrap"
      }
    }, [_c('BaseInput', {
      attrs: {
        "placeholder": _vm.$t('CustomerField.phoneNumber'),
        "icon-left": "phone_20_20.svg"
      },
      model: {
        value: organization.phoneNum,
        callback: function ($$v) {
          _vm.$set(organization, "phoneNum", $$v);
        },
        expression: "organization.phoneNum"
      }
    }), _c('BaseInput', {
      attrs: {
        "placeholder": _vm.$t('CustomerField.email'),
        "icon-left": "email_20_20.svg"
      },
      model: {
        value: organization.orgEmail,
        callback: function ($$v) {
          _vm.$set(organization, "orgEmail", $$v);
        },
        expression: "organization.orgEmail"
      }
    })], 1)], 1) : _vm._e()], 1);
  }), _c('v-row', {
    staticClass: "add-another-row",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('p', {
    staticClass: "link-small",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.addEmptyFormOrganizations();
      }
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t("AddAnother")) + " ")])]), _c('v-row', {
    staticStyle: {
      "margin-top": "36px",
      "width": "100%"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("DisplayTag")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "0",
      "flex-wrap": "nowrap"
    }
  }, [_c('div', {
    staticStyle: {
      "border": "1px solid #e6e8ec",
      "border-radius": "8px",
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('v-row', {
    staticStyle: {
      "align-items": "center",
      "width": "max-content"
    }
  }, _vm._l(_vm.displayedTags, function (tag, key, index) {
    return _c('div', {
      key: 'tag-' + index,
      staticClass: "tag-container",
      class: {
        '': !_vm.privatePerson.tags[key],
        'selected-font-first': _vm.privatePerson.tags[key]
      },
      on: {
        "click": function ($event) {
          return _vm.setTag(key);
        }
      }
    }, [_c('div', {
      staticClass: "tag-abbreviation",
      style: {
        backgroundColor: tag.backgroundColor
      }
    }, [_c('p', {
      staticClass: "tag-name",
      style: {
        color: tag.textColor
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(tag.abbreviation)) + " ")])]), _c('p', {
      class: {
        'content-small': !_vm.privatePerson.tags[key],
        'content-small big-font': _vm.privatePerson.tags[key]
      },
      staticStyle: {
        "margin-right": "8px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(tag.name)) + " ")])]);
  }), 0)], 1)]), !_vm.isEdit ? _c('span', {
    staticStyle: {
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "flex-wrap": "nowrap",
      "gap": "12px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "32px",
      "display": "block"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("AddNote")))])]), _c('BaseTextarea', {
    attrs: {
      "id": 'sidepanel-contact-note',
      "placeholder": _vm.$t('Note')
    },
    model: {
      value: _vm.noteContent,
      callback: function ($$v) {
        _vm.noteContent = $$v;
      },
      expression: "noteContent"
    }
  })], 1) : _vm._e(), _c('v-row', {
    staticStyle: {
      "margin-top": "0",
      "width": "100%",
      "padding-top": "28px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("AdditionalDetails")))])]), _c('v-row', {
    staticStyle: {
      "gap": "12px",
      "display": "flex",
      "margin-top": "0",
      "width": "100%",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "id": 'sidepanel-contact-birth-date',
      "placeholder": _vm.$t('BirthdayDate'),
      "show-dropdown-arrow": true,
      "type": "date"
    },
    model: {
      value: _vm.privatePerson.birthday,
      callback: function ($$v) {
        _vm.$set(_vm.privatePerson, "birthday", $$v);
      },
      expression: "privatePerson.birthday"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "id": 'sidepanel-contact-gender',
      "items": _vm.genders,
      "show-dropdown-arrow": true,
      "placeholder": _vm.$t('CustomerField.Gender.Label')
    },
    model: {
      value: _vm.privatePerson.sex,
      callback: function ($$v) {
        _vm.$set(_vm.privatePerson, "sex", $$v);
      },
      expression: "privatePerson.sex"
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "flex",
      "width": "100%",
      "gap": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": 'sidepanel-contact-personalcode',
      "placeholder": _vm.$t('CustomerField.personalCode')
    },
    model: {
      value: _vm.privatePerson.personalCode,
      callback: function ($$v) {
        _vm.$set(_vm.privatePerson, "personalCode", $$v);
      },
      expression: "privatePerson.personalCode"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "id": 'sidepanel-contact-language',
      "items": _vm.languages,
      "dropdown-z-index": 1000,
      "placeholder": _vm.$t('CustomerField.CustomerLanguage.Label'),
      "show-dropdown-arrow": true
    },
    model: {
      value: _vm.privatePerson.customerLang,
      callback: function ($$v) {
        _vm.$set(_vm.privatePerson, "customerLang", $$v);
      },
      expression: "privatePerson.customerLang"
    }
  })], 1), _c('BaseInput', {
    attrs: {
      "id": 'sidepanel-contact-bank-account',
      "placeholder": _vm.$t('CustomerField.BankAccountNumber')
    },
    model: {
      value: _vm.privatePerson.bankAccountNumber,
      callback: function ($$v) {
        _vm.$set(_vm.privatePerson, "bankAccountNumber", $$v);
      },
      expression: "privatePerson.bankAccountNumber"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": 'sidepanel-contact-address',
      "placeholder": _vm.$t('CustomerField.address'),
      "icon-left": "navigation_20_20.svg"
    },
    model: {
      value: _vm.privatePerson.address,
      callback: function ($$v) {
        _vm.$set(_vm.privatePerson, "address", $$v);
      },
      expression: "privatePerson.address"
    }
  }), _c('v-row', {
    staticStyle: {
      "gap": "12px",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": 'sidepanel-contact-postalcode',
      "placeholder": _vm.$t('PostalCode')
    },
    model: {
      value: _vm.privatePerson.postalCode,
      callback: function ($$v) {
        _vm.$set(_vm.privatePerson, "postalCode", $$v);
      },
      expression: "privatePerson.postalCode"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "id": 'sidepanel-contact-country',
      "items": _vm.countries,
      "return-object": true,
      "placeholder": _vm.$t('CustomerField.Country.Label'),
      "show-dropdown-arrow": true,
      "dropdown-offset-y": -250
    },
    model: {
      value: _vm.privatePerson.countryCode,
      callback: function ($$v) {
        _vm.$set(_vm.privatePerson, "countryCode", $$v);
      },
      expression: "privatePerson.countryCode"
    }
  })], 1)], 2)], 1)], 1)], 1), _c('div', {
    staticClass: "buttons-container",
    staticStyle: {
      "width": "640px"
    }
  }, [_c('v-divider', {
    staticStyle: {
      "z-index": "111",
      "position": "absolute",
      "width": "640px"
    }
  }), _c('v-row', {
    class: {
      'edit-footer': _vm.isEdit
    },
    staticStyle: {
      "padding-right": "32px",
      "padding-top": "24px",
      "padding-left": "32px",
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_vm.isEdit ? _c('button', {
    staticClass: "delete-button",
    staticStyle: {
      "align-self": "flex-start"
    },
    attrs: {
      "name": "contact-delete-button",
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.deletePanelOpened = true;
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap"
    }
  }, [_c('CancelButton', {
    on: {
      "clicked": function ($event) {
        return _vm.closeModal();
      }
    }
  }), _vm.isEdit ? _c('SubmitButton', {
    attrs: {
      "loading": _vm.isLoading
    },
    on: {
      "clicked": function ($event) {
        return _vm.updateCustomer(_vm.privatePerson);
      }
    }
  }) : _vm._e(), !_vm.isEdit ? _c('SubmitButton', {
    attrs: {
      "name": "contact-save-button",
      "loading": _vm.isLoading
    },
    on: {
      "clicked": function ($event) {
        return _vm.submitCustomer();
      }
    }
  }) : _vm._e()], 1)])], 1)], 1), _vm.deletePanelOpened ? _c('DeleteModal', {
    staticStyle: {
      "z-index": "121"
    },
    attrs: {
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.deletePanelOpened = false;
      },
      "approved": function ($event) {
        return _vm.deleteCustomer();
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }