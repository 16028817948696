<template>
  <div class="sub-block">
    <h5
      v-if="!showButtons"
      class="sub-heading"
      style="padding-left: 0px; padding-bottom: 32px"
    >
      {{ $t("Files") }}
      <v-row
        v-if="!clientSidepanelOpen"
        style="padding-left: 8px; max-width: 32px; max-height: 16px"
      >
        <div class="counter-icon">
          <p class="content-small" style="color: white">{{ fileCounter }}</p>
        </div></v-row
      >
      <label
        v-if="!hideButton"
        v-bind:class="{
          'right-corner-location': buttonCornerLocation,
          'near-subheading-location': !buttonCornerLocation,
        }"
        :for="id"
        @click="uploadFiles($event)"
        type="button"
        class="add-notes"
      >
        <img src="../../assets/images/plus.svg" alt="" />
        <span class="add-note-inside">{{ $t("AddFiles") }}</span>
      </label>
    </h5>
    <div v-if="isUploading" class="overlay">
      <div class="overlay__inner">
        <div class="overlay__content"><span class="spinner"></span></div>
      </div>
    </div>
    <v-row class="file-row" v-for="(el, index) in refactoredFiles" :key="index">
      <span style="display: flex; gap: 8px">
        <img
          v-if="!el.amlId"
          src="../../assets/images/action-delete.svg"
          alt=""
          style="cursor: pointer"
          @click="
            isDeleteModalOpen = true;
            fileWaitingDeletion = el;
          "
        />

        <span class="file-name" @click="previewFile(el.fileName, el.amlId)">
          {{ el.fileName }}
        </span>
      </span>

      <span
        class="content-small"
        style="font-weight: 400; color: #75787a; gap: 8px"
      >
        <span>
          {{ el.uploaderName }}
        </span>
        <span>
          {{ moment(el.createdAt) }}
        </span>
      </span>
    </v-row>
    <input
      style="display: none"
      type="file"
      :id="id"
      multiple
      @change="uploadFiles"
    />
    <DeleteModal
      @canceled="isDeleteModalOpen = false"
      @approved="deleteFile(fileWaitingDeletion)"
      v-if="isDeleteModalOpen"
    />
    <a href="" id="download-link" download></a>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import DeleteModal from "@/components/common/DeleteModal";
import moment from "moment";
export default {
  name: "FileUpload",
  components: {
    DeleteModal,
  },
  props: [
    "id",
    "files",
    "targetId",
    "targetObject",
    "showButtons",
    "buttonCornerLocation",
    "fileCounter",
    "amlPanel",
    "clientSidepanelOpen",
    "hideButton",
  ],
  data: () => ({
    refactoredFiles: [],
    isDeleteModalOpen: false,
    fileWaitingDeletion: null,
    isUploading: false,
    hovered: false,
    loaded: false,
    srcNormal: require("../../assets/images/fileIcon.svg"),
    srcHover: require("../../assets/images/trash-red.svg"),
    fileData: null,
    fileName: null
  }),
  created() {
    console.log("loodud");
    this.files.forEach((file) => {
      let niceFile = file;
      niceFile.fileName = niceFile.fileName.split("_._").pop();
      /* let diffInMs = new Date() - niceFile.createdAt;
      let diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      diffInDays = Math.round(diffInDays); */
      this.refactoredFiles.push(niceFile);
    });
    this.loaded = true;
  },
  watch: {
    //watch for new uploaded files
    files: function () {
      let newFiles = this.files;
      let newFilesRefactored = [];
      newFiles.forEach((file) => {
        let niceFile = file;
        niceFile.fileName = niceFile.fileName.split("_._").pop();
        newFilesRefactored.push(niceFile);
      });
      this.refactoredFiles = newFilesRefactored;
    },
    deleteAllowed: function () {
      if (this.deleteAllowed === true) {
        this.deleteFile();
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(['setFileData', 'clearFileData']),
    moment: function (date) {
      return moment(date).format("DD.MM.YYYY");
    },
    async deleteFile(el) {
      let dataObject = {
        parentId: this.targetId,
        fileId: el._id,
      };
      const deleteResponse = await axios.post(
        `/api/${this.targetObject}/file/delete/${
          this.targetId + "_._" + el.fileName
        }`,
        dataObject
      );
      if (deleteResponse.status === 200) {
        this.$emit("success");
        this.isDeleteModalOpen = false;
        this.fileWaitingDeletion = null;
      }
    },
    async downloadFileAml(fileName, amlId) {
      const extension = fileName.split('.').pop().toLowerCase();
      if (['jpeg', 'jpg', 'png', 'pdf', 'JPEG', 'JPG', 'PNG', 'PDF'].includes(extension)) {
        this.clearFileData();
        const encodedFileName = encodeURIComponent(fileName);
        const companyId = this.user.access.company._id
        await axios.post(`/api/project/${companyId}/aml/${amlId}/file/${encodedFileName}`)
          .then((response) => {
            this.setFileData({
              fileData: response.data.data,
              fileName: fileName
            });
            this.$router.push({
              name: 'FilePreview',
              params: { fileName: fileName }
            });
        });
      } else {
        const encodedFileName = encodeURIComponent(fileName);
        const companyId = this.user.access.company._id
          axios.post(`/api/project/${companyId}/aml/${amlId}/file/${encodedFileName}`)
            .then((response) => {
                const downloadUrl = response.data.data;
                window.location.href = downloadUrl;
            })
            .catch((error) => {
                console.error("Error downloading the file:", error);
            });
        }
    },
    async previewFile(fileName, amlId) {
      if (!amlId) {
        const extension = fileName.split('.').pop().toLowerCase();
        if (['jpeg', 'jpg', 'png', 'pdf', 'JPEG', 'JPG', 'PNG', 'PDF'].includes(extension)) {
          this.clearFileData();
          const encodedFileName = encodeURIComponent(this.targetId + "_._" + fileName);
          axios.post(`/api/${this.targetObject}/file/${encodedFileName}`)
            .then((response) => {
              this.setFileData({
                fileData: response.data.data,
                fileName: fileName
              });
              this.$router.push({
                name: 'FilePreview',
                params: { fileName: fileName }
              });
            });
        } else {
          const encodedFileName = encodeURIComponent(this.targetId + "_._" + fileName);
          axios.post(`/api/${this.targetObject}/file/${encodedFileName}`)
            .then((response) => {
                const downloadUrl = response.data.data;
                console.log('downloadUrl: ', downloadUrl)
                window.location.href = downloadUrl;
            })
            .catch((error) => {
                console.error("Error downloading the file:", error);
            });
        }
      } else {
        this.downloadFileAml(fileName, amlId);
      }
    },
    async uploadFiles(event) {
      let selectedFiles = event.target.files;
      const formData = new FormData();
      for (const [key, value] of Object.entries(selectedFiles)) {
        console.log(key);
        formData.append("file", value);
      }
      formData.append("userName", this.user.name);

      this.isUploading = true;

      const response = await axios.post(
        `/api/${this.targetObject}/files/upload/${this.targetId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        const file = document.getElementById(this.id);
        file.value = "";
        this.$emit("success");
      } else {
        this.toastError(this.$t("ErrorUploading"));
      }

      this.isUploading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-block {
  padding: 0px;
  border-bottom: 0px solid #e6e8ec !important;
  position: relative;
}

.sub-heading {
  padding: 24px;
  padding-top: 16px;
  padding-left: 32px;
  padding-right: 56px;
}

.related-deals {
  font-size: 14px !important;
  line-height: 16px;
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 16px;

  &:hover {
    text-decoration: underline;
  }

  cursor: pointer;
}

.add-note {
  padding-top: 12px;
}

.add-notes {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  padding: 0;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}

.add-note-inside {
  height: 16px;
  margin-left: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}

.add-person {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
  padding-top: 12px;
}

.file-row {
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  background: #f4f5f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uploaded-date {
  margin-left: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
}

.uploader-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
}

.file-name {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.file-icon {
  background-image: url("../../assets/images/action-delete.svg");
  cursor: pointer;

  /*  &:hover {
    background-image: url("../../assets/images/trash-red.svg");
    cursor: pointer;
  } */
}

.overlay {
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;

  position: absolute;
  background: #d5d5d9;
  opacity: 0.5;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
.near-subheading-location {
  margin-left: 8px;
}
.right-corner-location {
  margin-left: 312px;
}
.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}
.counter-icon {
  background: #939597;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 16px;
  height: 16px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>

