import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Cookies from "js-cookie";
/*import listingApi from "@/http/listing";
import contactApi from "@/http/contact";
//import projectApi from "@/http/project";*/
import i18n from "../i18n/index";
import ToastComponent from "@/components/common/Toast";
import { toast } from "@/main";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
/*const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(async (err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      this.replace({ query: location.query });
      console.log("mhm");
      return;
    }
    // rethrow error
    return Promise.reject(err);
  });
};*/
Vue.use(VueRouter);


const toastContent = {
  component: ToastComponent,
  props: {
    type: "error",
  },
};

const routes = [
  { path: "*", redirect: { path: "/" } },
  { path: "/", redirect: { name: "Login" } },
  {
    path: "/login",
    name: "Login",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/self-register",
    name: "SelfRegister",
    component: () => import("../views/SelfRegister.vue"),
  },
  {
    path: "/self-service/login",
    name: "LoginSelfService",

    component: () => import("../views/SelfServiceLogin.vue"),
  },
  {
    path: "/self-service",
    name: "SelfServiceBoard",

    component: () => import("../views/SelfServiceBoard.vue"),
  },
  {
    path: "/reset-password/:id",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/finalize-account/:id",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/finalize-self-service-user/:id",
    name: "RegisterSelfUser",
    component: () => import("../views/RegisterSelfUser.vue"),
  },
  {
    path: "/contacts",
    name: "Kliendiregister",
    component: () => import("../views/Broker/Kliendiregister.vue"),
    meta: {
      requiresAuth: true,
      module: "contacts",
    },
  },
  {
    path: "/admin",
    name: "Admin",

    component: () => import("../views/Broker/Admin.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/brokersettings",
    name: "BrokerSettings",

    component: () => import("../views/Broker/BrokerSettings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/property-owner",
    name: "PropertyOwner",

    component: () => import("../views/PropertyOwner/PropertyOwner.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/property-owner/:id",
    name: "PropertyDetail",
    redirect: { name: "PropertyGeneral" },

    component: () => import("../views/PropertyOwner/PropertyDetail.vue"),
    children: [
      {
        path: "general",
        name: "PropertyGeneral",
        component: () =>
          import("../components/PropertyOwner/Detail/GeneralView"),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/property-development",
    name: "PropertyDevelopment",
    component: () => import("../views/PropertyDeveloper/PropertyDeveloper.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/property-developer/change/:id",
    name: "DevelopmentListingChange",
    component: () =>
      import("../components/PropertyDeveloper/Detail/Listings/ListingEdit"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/property-development/:id",
    name: "DevelopmentDetail",
    redirect: { name: "DevelopmentGeneral" },
    component: () =>
      import("../views/PropertyDeveloper/PropertyDeveloperDetail.vue"),
    children: [
      {
        path: "general",
        name: "DevelopmentGeneral",
        component: () =>
          import("../components/PropertyDeveloper/Detail/GeneralView"),
      },
    ],
    meta: {
      requiresAuth: true,
      checkPermission: true,
      dispatchMethod: "getDevelopment",
      module: "developments",
    },
  },
  {
    path: "/scorecard",
    name: "Scorecard",

    component: () => import("../views/Broker/Scorecard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "DashBoard",

    component: () => import("../views/Broker/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transactions",
    name: "Transactions",

    component: () => import("../views/Broker/Transactions.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/invoices",
    name: "Invoices",

    component: () => import("../views/Broker/Invoices.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contacts/:id",
    name: "KliendiregisterDetail",

    component: () => import("../views/Broker/Kliendiregister.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/listings",
    name: "Listings",

    component: () => import("../views/Broker/Listings.vue"),
    meta: {
      requiresAuth: true,
      module: "listings",
    },
  },
  {
    path: "/listings/lead/:id",
    name: "LeadDetail",

    component: () => import("../views/Broker/Listings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/calendar/contact/:id",
    name: "CalendarCustomerDetail",

    component: () => import("../views/Broker/CalendarView"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/calendar/project/:id",
    name: "CalendarDealDetail",
    redirect: { name: "ProjectGeneral" },
    component: () => import("../views/Broker/CalendarView"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/listings/project/:id",
    name: "ListingDealDetail",
    component: () => import("../views/Broker/ListingsAdd"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/listings/listing/:listingId",
    name: "ListingsDetail",
    component: () => import("../views/Broker/Listings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/listings/change/:id",
    name: "ListingsChange",
    component: () =>
      store.state.a.user.isItaly
        ? import("../views/Broker/ListingsAddItaly.vue")
        : import("../views/Broker/ListingsAdd.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/listings/add",
    name: "ListingsAdd",
    component: () =>
      store.state.a.user.isItaly
        ? import("../views/Broker/ListingsAddItaly.vue")
        : import("../views/Broker/ListingsAdd.vue"),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/listings/add",
    name: "ListingsAddToProject",
    component: () => import("../views/Broker/ListingsAdd.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects",
    name: "Deals",

    component: () => import("../views/Broker/Deals.vue"),
    meta: {
      requiresAuth: true,
      //  module: "projects",
    },
  },
  {
    path: "/projects/:id",
    name: "DealDetail",
    redirect: { name: "ProjectGeneral" },

    component: () => import("../views/Broker/DealsDetail.vue"),
    children: [
      {
        path: "general",
        name: "ProjectGeneral",

        component: () => import("../components/Deals/Detail/General"),
      },
      {
        path: "units",
        name: "ProjectUnits",
        component: () => import("../components/Deals/Detail/Units"),
      },
      {
        path: "listings",
        name: "ProjectListings",
        component: () => import("../components/Deals/Detail/Listings"),
      },
      {
        path: "leads",
        name: "ProjectLeads",
        component: () => import("../components/Deals/Detail/Leads"),
      },
    ],
    meta: {
      requiresAuth: true,
      checkPermission: true,
      dispatchMethod: "getProject",
      module: "projects",
    },
  },
  {
    path: "/calendar",
    name: "Calendar",

    component: () => import("../views/Broker/CalendarView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/Listings/:id",
    name: "ListingsFullDetail",
    component: () => import("../views/Broker/ListingsFullDetail.vue"),
  },
  {
    path: "/offers/:id",
    name: "Offers",
    component: () => import("../views/Offers.vue"),
  },
  {
    path: "/company-listings/:id",
    name: "CompanyListingsLander",
    component: () => import("../views/Broker/CompanyListingsLander.vue"),
  },
  {
    path: '/preview/:fileName',
    name: 'FilePreview',
    props: true,
    component: () => import("../components/common/FilePreview.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});

/*function abortApiRequests(module) {
  if (module === "listings") {
    let listingAbortController = listingApi.getAbortController();
    listingAbortController.abort();
    listingApi.newAbortController();
  }
  if (module === "contacts") {
    let contactAbortController = contactApi.getAbortController();
    contactAbortController.abort();
    contactApi.newAbortController();
  }
    if (module === "projects") {
    let projectAbortController = projectApi.getAbortController();
    projectAbortController.abort();
    projectApi.newAbortController();
  }
}*/

const openModuleItemPanel = {
  invoices: (id) => {
    store.dispatch('fetchInvoice', id)
  },
  invoicesUser: (id) => {
    store.dispatch('fetchUserInvoices', id)
  }
}

async function handleQueryParams(to) {
  for (const queryKey in to.query) {
    if (queryKey in openModuleItemPanel) {
      openModuleItemPanel[queryKey](to.query[queryKey]);
    }
  }
}

async function checkAccess(to) {
  const routeRecord = to.matched.find((record) => record.meta.checkPermission)
  if (routeRecord) {
    try {
      const itemId = to.params.id;
      await store.dispatch(routeRecord.meta.dispatchMethod, itemId)
    } catch (error) {
      toastContent.props.text = i18n.t(`noAccess.view.${routeRecord.meta.module}`)
      toast.error(toastContent)
      return false;
    }
  }
  return true
}

const saveListingsStateWhenRouteToTheseViews = ["ListingsChange"];
router.beforeEach(async (to, from, next) => {
  if (from === to) {
    // handle any error due the redundant navigation here
    // or handle any other param modification and route afterwards
    console.log(".");
    return;
  }

  if (!(to.name === 'ProjectGeneral' || to.name === 'FilePreview')) {
    store.dispatch('clearFileData');
  }

  if (to.name === "Offers" && store.state.a.languageSet === false) {
    store.dispatch("setLanguage", "et");
    i18n.locale = "et";
  }

  if (
    from.name === "ListingsDetail" &&
    saveListingsStateWhenRouteToTheseViews.includes(to.name)
  ) {
    store.dispatch("setViewState", {
      viewName: "listings",
      value: true,
    });
  }


  if (from.path.startsWith('/property-development') && !to.path.startsWith('/listings/change')) {
    store.dispatch('clearActiveDevelopmentsTab');

    next();
  }
  //const module = from.meta.module;
  //abortApiRequests(module);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let currentUser = store.state.a.user;

    let tokenValue =
      document.cookie
        .match("(^|;)\\s*" + "token" + "\\s*=\\s*([^;]+)")
        ?.pop() || "";
    if (currentUser) {
      if (tokenValue === "" && (to.name !== "ListingsFullDetail" || to.name !== "CompanyListingsLander")) {
        sessionStorage.setItem("redirectPath", to.path);
        store.dispatch("logout");
        Cookies.remove("token");
      } else if (to.name !== "ListingsFullDetail" && to.name !== "CompanyListingsLander") {
        // Check access
        if (!await checkAccess(to)) return;
        handleQueryParams(to)
      }



      next();
    } else {
      sessionStorage.setItem("redirectPath", to.path);
      next("/");
    }
  } else {
    next();
  }
});
export default router;
