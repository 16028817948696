var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-project"
    }
  }, [_c('div', {
    staticClass: "modal-wrapper",
    attrs: {
      "id": "panel-wrapper-project"
    }
  }, [_c('div', {
    staticStyle: {
      "padding-left": "24px"
    },
    attrs: {
      "id": "wrapped-header-project"
    },
    on: {
      "click": _vm.closeContactAdd
    }
  }, [_c('h4', {
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? _vm.$t("Edit") + " " + _vm.$t("Project").toLowerCase() : _vm.$t("AddNewProject")) + " ")])]), !_vm.loaded ? _c('div', [_c('div', {
    staticClass: "loader",
    staticStyle: {
      "margin-top": "24px",
      "position": "absolute",
      "top": "30%",
      "left": "50%"
    }
  })]) : _vm._e(), _vm.loaded ? _c('div', {
    staticClass: "content-overlay",
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "sticky-header",
    staticStyle: {
      "z-index": "126"
    }
  }, [_c('v-row', {
    staticClass: "top-row",
    staticStyle: {
      "height": "24px",
      "padding-left": "32px"
    }
  }, [!_vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddNewProject")) + " ")]) : _vm._e(), _vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ChangeProject")) + " ")]) : _vm._e()])], 1), !_vm.clientAddOpen ? _c('v-divider', {
    staticStyle: {
      "top": "97px",
      "z-index": "126",
      "position": "fixed",
      "width": "560px"
    }
  }) : _vm._e(), _c('div', {
    staticStyle: {
      "padding": "129px 32px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "padding-bottom": "12px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Subblock.GeneralData")))])]), _c('v-row', [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('v-row', {
    staticClass: "faded-title required",
    attrs: {
      "title": "required"
    }
  }, [_c('h6', {
    staticClass: "deal-type-lable"
  }, [_vm._v(" " + _vm._s(_vm.$t("Project") + " " + _vm.$t("Type").toLowerCase()) + " ")])]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, _vm._l(_vm.dealTypes, function (dealType, index) {
    return _c('span', {
      key: index,
      attrs: {
        "name": 'project-' + dealType
      },
      on: {
        "click": function ($event) {
          return _vm.setDealType(dealType);
        }
      }
    }, [_c('DealTypeContainer', {
      class: 'project-type-con ' + (dealType === _vm.form.dealType ? 'project-type-selected-con-' + dealType : null),
      attrs: {
        "deal-type": dealType
      }
    })], 1);
  }), 0)], 1)], 1), this.form.dealType !== 'Sale' && this.form.dealType !== 'LeaseOut' ? _c('v-row', {
    staticClass: "flex-column"
  }, [_c('BaseInput', {
    attrs: {
      "id": 'project-name-field',
      "placeholder": _vm.$t('ProjectName'),
      "required": true,
      "error": _vm.showErrors && !_vm.isFieldValid('name') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.errors.name)
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1) : _vm._e(), this.form.dealType === 'Sale' || this.form.dealType === 'LeaseOut' ? _c('v-row', [_c('div', {
    staticStyle: {
      "display": "flex",
      "margin-top": "16px",
      "margin-bottom": "16px",
      "flex-direction": "column"
    }
  }, [_c('div', {
    staticClass: "content-bold"
  }, [_vm._v(_vm._s(_vm.$t("AddressType")))]), _vm.user.access.company._id !== 'xmwei_Rt9ryfpkxL4BL8' ? _c('div', {
    staticClass: "radio-group-label",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.form.addressType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "addressType", $$v);
      },
      expression: "form.addressType"
    }
  }, [!_vm.user.isItaly ? _c('v-radio', {
    staticClass: "radio-button",
    attrs: {
      "id": "address-type-est",
      "color": "#FF5C01",
      "value": "estonia",
      "label": _vm.$t('Estonian')
    },
    on: {
      "click": _vm.switchAddressType
    }
  }) : _vm._e(), _c('v-radio', {
    staticClass: "radio-button",
    attrs: {
      "id": "address-type-generic",
      "color": "#FF5C01",
      "value": "generic",
      "label": _vm.$i18n.locale === 'et' ? _vm.$t('ForeignCountry') : _vm.$t('Generic')
    },
    on: {
      "click": _vm.switchAddressType
    }
  })], 1), _vm.form.addressType === 'generic' && _vm.$i18n.locale === 'et' ? _c('div', {
    staticClass: "disclaimer-row"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/infoMark20.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-small"
  }, [_vm._v(" Välismaa aadressiga tehtud kuulutust ei saa eksportida kinnisvara portaali ")])]) : _vm._e()], 1) : _vm._e()]), _c('div', {
    staticStyle: {
      "z-index": "125",
      "width": "100%"
    }
  }, [_vm.form.addressType === 'estonia' ? _c('AddressFinder', {
    ref: "addressFinder",
    attrs: {
      "object": _vm.form.objects ? _vm.form.objects[0] : '',
      "placeholder": _vm.$t('Object') + ' ' + _vm.$t('Address').toLowerCase(),
      "show-error": _vm.showErrors && !_vm.isFieldValid('object'),
      "error-message": _vm.errors && _vm.errors.object ? _vm.errors.object : ''
    },
    on: {
      "setObject": _vm.setObject,
      "removeObject": _vm.removeObject,
      "loaded": _vm.loadedAF
    }
  }) : _vm._e()], 1), _vm.form.addressType === 'generic' ? _c('div', {
    class: {
      'input-edit': _vm.isEdit
    },
    staticStyle: {
      "width": "100%",
      "margin-bottom": "-5px"
    }
  }, [_c('GoogleAddressSearch', {
    attrs: {
      "editMode": true,
      "id": 'project-generic-address-search',
      "placeholder": _vm.$t('Object') + ' ' + _vm.$t('Address').toLowerCase(),
      "required": "true",
      "return-object": true,
      "error": _vm.showErrors && !_vm.isFieldValid('object'),
      "error-message": _vm.errors && _vm.errors.object ? _vm.errors.object : ''
    },
    on: {
      "change": _vm.setObject
    },
    model: {
      value: _vm.googleFormObjects,
      callback: function ($$v) {
        _vm.googleFormObjects = $$v;
      },
      expression: "googleFormObjects"
    }
  })], 1) : _vm._e()]) : _vm._e(), this.form.dealType === 'Sale' || this.form.dealType === 'LeaseOut' ? _c('v-row', {
    staticClass: "flex-column",
    class: {
      'flex-column': _vm.form.addressType === 'estonian',
      'flex-column-gen': _vm.form.addressType === 'generic'
    }
  }, [_c('v-row', {
    class: {
      'input-with-apartment': _vm.showApartmentNumber
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "id": 'project-estateType-field',
      "placeholder": _vm.$t('EstateType'),
      "items": _vm.estateTypes,
      "show-dropdown-arrow": true,
      "required": "true",
      "error-message": _vm.errors && _vm.errors.estateType ? _vm.$t('FormErrors.' + _vm.errors.estateType) : '',
      "type": "dropdown",
      "error": _vm.showErrors && !_vm.isFieldValid('estateType')
    },
    on: {
      "change": function ($event) {
        return _vm.resetExtras($event);
      }
    },
    model: {
      value: _vm.form.estateType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "estateType", $$v);
      },
      expression: "form.estateType"
    }
  }), _vm.showApartmentNumber ? _c('BaseInput', {
    attrs: {
      "id": 'project-apartmentNumber',
      "placeholder": _vm.$t('ApartmentNumber')
    },
    model: {
      value: _vm.form.apartmentNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "apartmentNumber", $$v);
      },
      expression: "form.apartmentNumber"
    }
  }) : _vm._e()], 1), _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": 'project-amount-field',
      "placeholder": _vm.$t('ProjectValue')
    },
    model: {
      value: _vm.form.amount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  })], 1), _vm.correctExtraTypes && _vm.correctExtraTypes.length ? _c('v-row', [_c('DropdownMulti', {
    key: 'extras' + _vm.multiUpdater,
    attrs: {
      "custom-style-button": 'width: 100% !important; height: 52px;',
      "custom-style": 'width: 100% !important; height: 52px;',
      "items": _vm.correctExtraTypes,
      "display-key": 'name',
      "value-key": 'name',
      "width": '100%',
      "regular-size": true,
      "default-item": _vm.extraTypes.placeholder,
      "selected": _vm.form.extraTypes,
      "pre-selected-items": _vm.form.extraTypes
    },
    on: {
      "selectedItems": function ($event) {
        _vm.form.extraTypes = Array.prototype.slice.call(arguments);
      }
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _c('v-row', {
    staticClass: "client-dropdown-row"
  }, [_c('ClientListSearch', {
    attrs: {
      "id": "project-contact-search",
      "placeholder": _vm.$t('Contact'),
      "icon-left": 'clients.svg',
      "required": true,
      "update-component": _vm.updateSelectedContact,
      "error": _vm.showErrors && !_vm.isFieldValid('contactPerson'),
      "error-message": _vm.errors ? _vm.$t('FormErrors.' + _vm.errors.contactPerson) : ''
    },
    on: {
      "addNew": _vm.openContactAdd,
      "itemChosen": _vm.contactPersonSelected
    },
    model: {
      value: _vm.form.contactPerson,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contactPerson", $$v);
      },
      expression: "form.contactPerson"
    }
  })], 1), _vm.form.contactPerson ? _c('v-row', {
    staticStyle: {
      "padding-bottom": "12px"
    }
  }, [_c('div', {
    staticStyle: {
      "padding-bottom": "12px",
      "padding-top": "12px",
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Client")) + " ")]), _c('ClientSelection', {
    attrs: {
      "isEdit": _vm.isEdit,
      "existingClient": _vm.initialClient,
      "projectContact": _vm.form.contactPerson,
      "contactId": _vm.form.contactPerson.contactId,
      "newProjectClient": _vm.newProjectClient
    },
    on: {
      "openAddPanel": _vm.addNewPrivateContact,
      "clientSelected": _vm.setProjectClient
    }
  })], 1) : _vm._e(), _c('div', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('v-row', [_c('h5', [_vm._v(_vm._s(_vm.$t("BrokerageFee")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c('p', {
    staticClass: "content-small-semibold"
  }, [_vm._v(" " + _vm._s(_vm.$t("BrokerageFeeIs")) + " ")])]), _c('v-radio-group', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.form.brokerageFeeType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "brokerageFeeType", $$v);
      },
      expression: "form.brokerageFeeType"
    }
  }, [_c('v-radio', {
    staticClass: "radio-button",
    attrs: {
      "color": "#FF5C01",
      "value": "percentOfPrice",
      "label": _vm.$t('PercentageOfFinalPrice')
    }
  }), _c('v-radio', {
    staticClass: "radio-button",
    attrs: {
      "color": "#FF5C01",
      "value": "agreedPrice",
      "label": _vm.$t('AgreedAmount')
    }
  })], 1), _vm.form.brokerageFeeType === 'agreedPrice' ? _c('div', {
    staticStyle: {
      "display": "contents"
    }
  }, [_c('v-row', {
    staticStyle: {
      "gap": "12px",
      "display": "flex",
      "margin-top": "24px",
      "width": "100%"
    }
  }, [_c('p', {
    staticClass: "content-small-semibold"
  }, [_vm._v(" " + _vm._s(_vm.$t("HowMuchIsBrokerageFee")) + " ")]), _c('BaseInput', {
    attrs: {
      "type": "number",
      "format": 'price',
      "id": 'brokerage-fee-field',
      "placeholder": _vm.$t('BrokerageFee'),
      "icon-right": "euroSign.svg"
    },
    model: {
      value: _vm.form.brokerageFee,
      callback: function ($$v) {
        _vm.$set(_vm.form, "brokerageFee", $$v);
      },
      expression: "form.brokerageFee"
    }
  })], 1)], 1) : _vm.form.brokerageFeeType === 'percentOfPrice' ? _c('v-row', {
    staticStyle: {
      "gap": "12px",
      "display": "flex",
      "margin-top": "24px",
      "width": "100%",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "type": "number",
      "format": 'price',
      "id": 'project-amount-percent',
      "icon-right": "percentage.svg",
      "placeholder": _vm.$t('Percentage')
    },
    model: {
      value: _vm.form.brokerFeePercentage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "brokerFeePercentage", $$v);
      },
      expression: "form.brokerFeePercentage"
    }
  }), _c('BaseInput', {
    attrs: {
      "type": "number",
      "format": 'price',
      "id": 'project-amount-min-fee',
      "placeholder": _vm.$t('MinimumFee'),
      "icon-right": "euroSign.svg"
    },
    model: {
      value: _vm.form.minimumFee,
      callback: function ($$v) {
        _vm.$set(_vm.form, "minimumFee", $$v);
      },
      expression: "form.minimumFee"
    }
  })], 1) : _vm._e()], 1), !_vm.isEdit ? _c('span', [_c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("AddNote")))])]), _c('v-row', [_c('BaseTextarea', {
    attrs: {
      "id": 'project-add-note',
      "placeholder": _vm.$t('Note')
    },
    model: {
      value: _vm.noteContent,
      callback: function ($$v) {
        _vm.noteContent = $$v;
      },
      expression: "noteContent"
    }
  })], 1)], 1) : _vm._e(), !_vm.isEdit ? _c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Add") + " " + _vm.$t("Image").toLowerCase()))])]) : _vm._e(), _vm.isEdit ? _c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("SelectCoverImage")))])]) : _vm._e(), _c('v-row', [!_vm.isEdit ? _c('UploadImages', {
    staticClass: "image-uploader",
    attrs: {
      "Imgs": []
    },
    on: {
      "changed": function ($event) {
        return _vm.putImages($event);
      }
    }
  }) : _vm._e()], 1), _vm.isEdit && !_vm.activeListingsHaveImages && !_vm.containImages ? _c('v-row', {
    staticClass: "nothing-to-show"
  }, [_c('UploadImages', {
    staticClass: "image-uploader",
    attrs: {
      "Imgs": []
    },
    on: {
      "changed": function ($event) {
        return _vm.putImages($event);
      }
    }
  })], 1) : _vm._e(), _vm.isEdit && _vm.activeListings && _vm.activeListings.length > 0 && _vm.activeListingsHaveImages && _vm.containImages ? _c('v-row', {
    key: _vm.imagesUpdater,
    staticStyle: {
      "display": "contents"
    }
  }, _vm._l(_vm.activeListings, function (listing) {
    return _c('div', {
      key: listing._id,
      staticStyle: {
        "margin-bottom": "12px"
      }
    }, [_c('p', {
      staticStyle: {
        "margin-bottom": "8px !important"
      }
    }, [_vm._v(" " + _vm._s(listing.listingName) + " ")]), _c('UploadImages', {
      staticClass: "image-uploader",
      attrs: {
        "Imgs2": listing.images,
        "cover-image-selection": true
      },
      on: {
        "setCoverImage": _vm.newCoverImage
      }
    })], 1);
  }), 0) : _vm._e(), _vm.isEdit && _vm.activeListings && _vm.activeListings.length === 0 && !_vm.activeListingsHaveImages && _vm.containImages ? _c('v-row', {
    key: _vm.imagesUpdater,
    staticStyle: {
      "display": "contents"
    }
  }, [_c('UploadImages', {
    staticClass: "image-uploader",
    attrs: {
      "Imgs2": _vm.form.images,
      "cover-image-selection": true
    },
    on: {
      "setCoverImage": _vm.newCoverImage
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "modal-footer"
  }, [_c('v-divider', {
    staticStyle: {
      "z-index": "111",
      "position": "fixed !important",
      "width": "560px"
    }
  }), _c('div', {
    staticClass: "buttons-row",
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_vm.isEdit && _vm.dealData.statusCode === 1 && _vm.hasAccess(_vm.dealData, 'projects', 'delete') ? _c('button', {
    staticClass: "delete-button",
    attrs: {
      "id": "deal-delete-button",
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.isDeleteModalOpen = true;
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]) : _vm._e(), _vm.isEdit && _vm.dealData.statusCode === 0 ? _c('button', {
    staticClass: "restore-button",
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.restoreDeal(_vm.dealData);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Restore")) + " ")]) : _vm._e(), !_vm.isEdit ? _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        _vm.changeSidepanelStateDeals(0);
        _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]) : _vm._e(), _vm.isEdit ? _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]) : _vm._e(), _vm.isEdit ? _c('SubmitButton', {
    attrs: {
      "id": "save-edit-project-button",
      "loading": _vm.isLoading
    },
    on: {
      "clicked": function ($event) {
        return _vm.updateDeal();
      }
    }
  }) : _vm._e(), !_vm.isEdit ? _c('SubmitButton', {
    attrs: {
      "id": 'save-project-button',
      "loading": _vm.isLoading
    },
    on: {
      "clicked": function ($event) {
        return _vm.saveDeal();
      }
    }
  }) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e()]), _vm.clientAddOpen ? _c('AddModal', {
    staticStyle: {
      "z-index": "120"
    },
    attrs: {
      "accordion": true,
      "not-contact-view-route": true
    },
    on: {
      "newAdded": _vm.chooseNewClient,
      "closed": _vm.closeContactAdd
    }
  }) : _vm._e(), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    attrs: {
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": _vm.deleteDeal
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }