<script>
import draggable from "vuedraggable-rp";
import Compress from "compress.js";
import Bowser from "bowser";

export default {
  name: "VueUploadImages",
  components: {
    draggable,
  },
  data() {
    return {
      error: "",
      files: [],
      dropped: 0,
      Imgs: [],
      images: [],
      lastOpenedImage: null,
      lastOpenedIndex: null,
      imageLoaded: true,
      compressedImages: [],
      isSafari: false,
    };
  },
  props: {
    watermark: Boolean,
    max: Number,
    uploadMsg: String,
    maxError: String,
    fileError: String,
    clearAll: String,
    Imgs2: Array,
    isVisible: Boolean,
    coverImageSelection: Boolean,
  },
  watch: {
    Imgs() {
      if (!this.watermark) {
        this.$emit("changed", this.Imgs);
      }
    },
    Imgs2: function () {
      this.Imgs = this.Imgs2;
      this.imageLoaded = true;
    },
  },
  created() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    if (browser.getBrowserName() === "Safari") {
      this.isSafari = true;
    }
  },
  mounted() {
    if (this.Imgs2) {
      this.Imgs = this.Imgs2;
    }
  },
  methods: {
    dragOver() {
      this.dropped = 2;
    },
    dragLeave() {},
    drop(e) {
      let status = true;
      let files = Array.from(e.dataTransfer.files);
      if (e && files) {
        if (this.isSafari && files.length === 1 && e.dataTransfer.types.includes("text/uri-list")) {
          this.dropped = 0;
          return;
        }
        files.forEach((file) => {
          if (file.type.startsWith("image") === false) status = false;
        });
        if (status == true) {
          if (
              this.$props.max &&
              files.length + this.files.length > this.$props.max
          ) {
            this.error = this.$props.maxError
                ? this.$props.maxError
                : `Maximum files is` + this.$props.max;
          } else {
            this.files.push(...files);
            this.previewImgs();
          }
        } else {
          this.error = this.$props.fileError
              ? this.$props.fileError
              : `Unsupported file type`;
        }
      }
      this.dropped = 0;
    },
    append() {
      this.$refs.uploadInput.click();
    },
    readAsDataURL(file) {
      return new Promise(function (resolve, reject) {
        let fr = new FileReader();
        fr.onload = function () {
          resolve(fr.result);
        };
        fr.onerror = function () {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    },
    deleteImg(index) {
      this.Imgs.splice(index, 1);
      this.files.splice(index, 1);
      this.$emit("changed", this.Imgs);
      this.$refs.uploadInput.value = null;
    },
    changeVisibility(image, index) {
      image.hidden = !image.hidden;
      if (image.hidden) {
        document.getElementById("img" + index).style.backgroundColor =
            "#75787A";
        document.getElementById("img" + index).style.opacity = "0.62";
      } else {
        document.getElementById("img" + index).style.backgroundColor = "unset";
        document.getElementById("img" + index).style.opacity = "unset";
      }
    },
    changeSize(image, index) {
        if (this.lastOpenedIndex) {
          this.closeResizedImage(this.lastOpenedImage, this.lastOpenedIndex)
        }
        image.sizeLarge = !image.sizeLarge
        if (image.sizeLarge) {
          this.lastOpenedImage = image;
          this.lastOpenedIndex = index;
          document.getElementById("img" + index).style.transform =
            "scale(2.5)";
             document.getElementById("img" + index).style.position =
            "relative";
          document.getElementById("img" + index).style.zIndex = "12";
          document.getElementById("img" + index).style.width = "auto";
          document.getElementById("img" + index).style.height = "auto";
        }
        else {
          document.getElementById("img" + index).style.transform =
            "unset";
          document.getElementById("img" + index).style.position =
            "unset";
          document.getElementById("img" + index).style.zIndex = "unset";
          document.getElementById("img" + index).style.height = "unset";
          document.getElementById("img" + index).style.width = "inherit";
        }
    },
    closeResizedImage(img, index) {
      if (img.sizeLarge) {
        document.getElementById("img" + index).style.transform =
              "unset";
        document.getElementById("img" + index).style.position =
              "unset";
        document.getElementById("img" + index).style.zIndex = "unset";
        document.getElementById("img" + index).style.height = "100%";
          document.getElementById("img" + index).style.width = "inherit";
      }
      img.sizeLarge = false;
    },
    closeResizedImages(event) {
      let eventPath = event.path || (event.composedPath && event.composedPath());
      let imagePath = false;
      eventPath.forEach((inner) => {
        if (inner.classList && inner.classList.contains('imageHolder')) {
          imagePath = true;
        }
      });
      if (!imagePath) {
        this.Imgs2.forEach((image, index)=> {
           this.closeResizedImage(image, index + 1)});
      }
    },
    setCoverImage(image) {
      image.coverImage = true;
      this.$emit("setCoverImage", image);
    },
    arraysEqual(uploadedImages, compressedImages) {
      /* WARNING: arrays must not contain {objects} or behavior may be undefined */
      return JSON.stringify(uploadedImages) == JSON.stringify(compressedImages);
    },
    previewImgs(event) {
      this.imageLoaded = false;
      if (
          this.$props.max &&
          event &&
          event.currentTarget && event.currentTarget.files.length + this.files.length > this.$props.max
      ) {
        this.error = this.$props.maxError
            ? this.$props.maxError
            : `Maximum files is` + this.$props.max;
        return;
      }
      if (this.dropped === 0)
        if (event) this.files.push(...event.currentTarget.files);
      this.error = "";

      if (this.files.length > 0) {
        const compress = new Compress();
        compress
            .compress(this.files, {
              size: 1, // the max size in MB, defaults to 2MB
              quality: 0.65, // the quality of the image, max is 1,
              maxWidth: 1920, // the max width of the output image, defaults to 1920px
              maxHeight: 1920, // the max height of the output image, defaults to 1920px
              resize: true, // defaults to true, set false if you do not want to resize the image width and height
              rotate: false, // See the rotation section below
            })
            .then((data) => {
              data.forEach((compressedImg) => {
                let base64Img = compressedImg.prefix + compressedImg.data;
                if (!this.compressedImages.some((image) => image === base64Img)) {
                  this.compressedImages.push(base64Img);
                }
              });
              if (!this.files.length) return;
              let isTrue = this.arraysEqual(this.Imgs, this.compressedImages);

              let newVals = [];
              if (isTrue) {
                return;
              } else if (!isTrue) {
                if (this.Imgs.length === 0) {
                  this.compressedImages.forEach((val) => {
                    this.Imgs.push({name: val, hidden: false});
                  });
                } else {
                  this.compressedImages.forEach((val) => {
                    newVals.push({name: val, hidden: false});
                  });
                  let notPresentInData = [];

                  newVals.forEach((newImg) => {
                    let exists = false;
                    this.Imgs.forEach((oldImg) => {
                      if (newImg.name === oldImg.name) {
                        exists = true;
                      }
                    });
                    if (!exists) {
                      notPresentInData.push(newImg);
                    }
                  });

                  notPresentInData.forEach((e) => {
                    this.Imgs.push({name: e.name, hidden: false});
                  });
                }
              }
              this.$emit("changed", this.Imgs);
              this.imageLoaded = true;
              this.files = []
            }).catch(() => {
          this.imageLoaded = true;
        });

      } else {
        this.files = []
        this.imageLoaded = true;
      }

    },
    reset() {
      this.$refs.uploadInput.value = null;
      this.Imgs = [];
      this.files = [];
      this.$emit("changed", this.Imgs);
    },
    resetWithoutEmitting() {
      this.$refs.uploadInput.value = null;
      this.Imgs = [];
      this.files = [];
    },
    helloworld() {
      this.$emit("changed", this.Imgs);
    },
  },
};
</script>

<template>
  <div
      class="container"
      v-click-outside="closeResizedImages"
      @click="closeResizedImages($event)"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop($event)"
  >
    <div v-if="!imageLoaded && Imgs.length > 0"
         style="position:absolute; display:flex; align-content: center; align-items: center; justify-content: center; width:100%; height:100%; background-color:#e4e5e7; opacity:0.75; z-index: 2; margin: -12px; border-radius: 8px">
      <div>
        <div class="loader"></div>
      </div>
    </div>
    <div class="drop" v-show="dropped == 2"></div>
    <!-- Error Message -->
    <div v-show="error" class="error">
      {{ error }}
    </div>

    <!-- To inform user how to upload image -->
    <div v-show="Imgs.length == 0" class="beforeUpload" style="padding: 30px">
      <input
          type="file"
          style="
          z-index: 2;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        "
          accept="image/*"
          ref="uploadInput"
          @change="previewImgs"
          multiple
      />
      <img
          v-if="imageLoaded"
          class="icon"
          src="@/assets/images/image.svg"
          alt=""
      />
      <p class="mainMessage" v-if="imageLoaded">
        {{ uploadMsg ? uploadMsg : $t("ClickToUploadDrop") }}
      </p>
      <div v-if="!imageLoaded">
        <div
            style="position: absolute; top: 25%; left: 50%"
            class="loader"
        ></div>
      </div>
    </div>

    <div class="imgsPreview" v-show="Imgs.length > 0">
      <button
          v-if="!coverImageSelection"
          type="button"
          class="clearButton"
          @click="reset"
      >
        {{ clearAll ? clearAll : $t('ClearAll') }}
      </button>

      <draggable v-model="Imgs" @change="helloworld">
        <div v-for="(img, i) in Imgs" :key="i" class="imageHolder">
          <img
              v-if="img.selected"
              class="cover-picture"
              :src="img.name"
              :id="'img' + (i + 1)"
          />

          <img
              v-if="!img.hidden && !img.selected"
              :src="img.name"
              @click="closeResizedImage(img, i)"
              :id="'img' + (i + 1)"
          />
          <img
              v-if="img.hidden && !img.selected"
              class="hidden-picture"
              :src="img.name"
              :id="'img' + (i + 1)"
          />
          <span
              v-if="isVisible && !coverImageSelection"
              class="scalability"
              @click="changeSize(img, i)"
          >
            <img :src="require('@/assets/images/zoomImage.svg')"  alt=""/>
          </span>
          <span
              v-if="isVisible && !coverImageSelection"
              class="visibility"
              style="color: white"
              @click="changeVisibility(img, i)"
          >
            <img v-if="!img.hidden" src="@/assets/images/openEye.svg" alt=""/>
            <img v-if="img.hidden" src="@/assets/images/closeEye.svg" alt=""/>
          </span>
          <span
              :data-hover="$t('SetCoverImage')"
              :id="'cover-image' + (i + 1)"
              v-if="coverImageSelection && !img.selected"
              class="cover-image"
              style="color: white"
              @click="setCoverImage(img, i)"
          >
            <img src="@/assets/images/coverImage.svg" alt=""/>
          </span>
          <span
              v-if="!coverImageSelection"
              class="delete"
              style="color: white"
              @click="deleteImg(--i)"
          >
            <svg
                class="icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </span>
          <div
              class="plus"
              @click="append"
              v-if="++i == Imgs.length && !coverImageSelection && imageLoaded"
          >
            +
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  border: 0.5px solid #a3a8b1;
  border-radius: 8px;
  position: relative;
}

input[type="file"]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.drop {
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  position: absolute;
  background-color: #f4f6ff;
  left: 0;
  border: 3px dashed #a3a8b1;
}

.error {
  text-align: center;
  color: red;
  font-size: 15px;
}

.beforeUpload {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
}

.beforeUpload input {
  width: 100%;
  margin: auto;
  height: 100%;
  opacity: 0;
  position: absolute;
  background: red;
  display: block;
}

.beforeUpload input:hover {
  cursor: pointer;
}

.beforeUpload .icon {
  width: 150px;
  margin: auto;
  display: block;
}

.imgsPreview .imageHolder {
  width: 150px;
  height: 150px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  margin: 5px 5px;
  //display: inline-block;
  display: inline-flex;
}

.imgsPreview .imageHolder img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.imgsPreview .imageHolder .delete {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 32px;
  height: 32px;
  color: #fff;
  background: red;
  border-radius: 50%;
}

.imgsPreview .imageHolder .visibility {
  position: absolute;
  top: 4px;
  right: 41px;
  width: 32px;
  height: 32px;
  color: #fff;
  border-radius: 50%;
}

.imgsPreview .imageHolder .visibility:hover {
  cursor: pointer;
}

.imgsPreview .imageHolder .scalability {
  position: absolute;
  top: 4px;
  right: 78px;
  width: 32px;
  height: 32px;
  color: #fff;
  border-radius: 50%;
}

.imgsPreview .imageHolder .scalability:hover {
  cursor: pointer;
}

.imgsPreview .imageHolder .cover-image {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 32px;
  height: 32px;
  color: #fff;
  border-radius: 50%;
}

.imgsPreview .imageHolder .cover-image:hover {
  cursor: pointer;
}

.imgsPreview .imageHolder .cover-image:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 1s ease-in-out;

  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.imgsPreview .imageHolder .cover-image:hover:before {
  opacity: 1;
  visibility: visible;
}

.imgsPreview .imageHolder .delete:hover {
  cursor: pointer;
}

.imgsPreview .imageHolder .delete .icon {
  width: 66%;
  height: 66%;
  display: block;
  margin: 4px auto;
}

.imgsPreview .imageHolder .plus {
  color: #2d3748;
  background: #f7fafc;
  border-radius: 50%;
  font-size: 21pt;
  height: 30px;
  width: 30px;
  text-align: center;
  border: 1px dashed;
  line-height: 23px;
  position: absolute;
  right: -42px;
  bottom: 43%;
}

.plus:hover {
  cursor: pointer;
}

.clearButton {
  color: #2d3748;
  position: absolute;
  top: 7px;
  right: 7px;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.hidden-picture {
  background-color: #75787a;
  opacity: 0.62;
}

.cover-picture {
  border: 4px solid #ff5c01;
}
</style>
