import Vue from "vue";

import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import * as Msal from "@azure/msal-browser";

const msalConfig = require("../configs/msalConfig");
Vue.mixin({
  computed: {
    ...mapGetters(["user", "msalObject"]),
  },
  methods: {
    ...mapActions(["setMicrosoftAccount"]),
    getMsalAccessToken() {
      return this.getTokenPopup(msalConfig.tokenRequest)
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          await this.logError("[Microsoft] Acquire token", error);
          return null;
        });
    },
    getTokenPopup(request) {
      request.account = this.user.microsoft.account;

      return this.msalObject.acquireTokenSilent(request).catch((error) => {
        console.warn(
          "silent token acquisition fails. acquiring token using popup : ",
          error
        );
        if (error instanceof Msal.InteractionRequiredAuthError) {
          request.prompt = "consent";
          // fallback to interaction when silent call fails
          return this.msalObject
            .acquireTokenPopup(request)
            .then(async (tokenResponse) => {
              await axios.post("/api/microsoft/authorize", {
                username: tokenResponse.account.username,
                account: tokenResponse.account,
                userId: this.user._id,
              });
              this.setMicrosoftAccount({
                username: tokenResponse.account.username,
                account: tokenResponse.account,
              });
              return tokenResponse;
            })
            .catch(async (error) => {
              await this.logError("[Microsoft] Acquire token", error);
            });
        } else {
          console.warn(error);
        }
      });
    },
  },
});
