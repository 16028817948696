<template>
  <div
    :key="sidepanelUpdater"
    @keyup.esc="closePanel"
    class="overlay-detailpanel"
    id="overlay-customer"
    @click="closePanel($event)"
  >
    <div class="panel-wrapper" id="panel-wrapper-customer">
      <div
        v-if="!dataLoaded"
        class="loader"
        style="position: absolute; top: 50%; left: 50%"
      ></div>
      <div
        v-if="dataLoaded && !editModalOpen && !newCustomerAdd"
        @click="closeAccordion()"
        id="wrapped-header-customer"
      >
        <h4 v-if="customer.firstName" style="align-self: center">
          {{ customer.firstName }} {{ customer.lastName }}
        </h4>
      </div>
      <div
        id="content-wrapper"
        style="overflow-y: scroll; height: 100%"
        v-if="dataLoaded"
      >
        <v-col class="customer-row">
          <v-row style="justify-content: space-between">
            <h3 v-if="customer.firstName" style="align-self: center">
              {{ customer.firstName }} {{ customer.lastName }}
            </h3>

            <div
              v-if="customer.statusCode !== 0 && hasAccess(customer)"
              style="display: flex; gap: 8px; justify-content: flex-end"
            >
              <EditButton
                @clicked="openAddModal(customer)"
                name="contact-edit-button"
              />
            </div>
            <div
              v-if="customer.statusCode !== 0 && customer.isProspect"
              style="display: flex; gap: 8px; justify-content: flex-end"
            >
              <div @click="promoteToContact(customer)" class="promote-button">
                <img src="../../assets/images/add_contact_black.svg" alt="" />
                <p class="promote-button-text">{{ $t("PromoteToContact") }}</p>
              </div>
            </div>
          </v-row>
          <div class="broker-info" v-if="!customer.isProspect">
            <img src="../../assets/images/users.svg" alt="" />
            <span
              v-for="(creator, userKey) in customer.access.users"
              :key="userKey"
              class="button-small hoverable-info"
              style="margin-left: -2px"
              @click="(event) => openBrokerDetailPanel(event, userKey)"
            >
              {{ creator.name }}
              {{
                creator.ownershipType === "owner"
                  ? "(" + $t("Owner").toLowerCase() + ")"
                  : ""
              }}
            </span>
            <button
              v-if="hasAccess(customer)"
              class="animated-button"
              @click="openAddUsers()"
            >
              <p style="font-size: 16px; margin-top: -1px">+</p>
              <span>{{ $t("ManageUsers") }}</span>
            </button>
          </div>
        </v-col>
        <div class="main-wrapper" style="background-color: white">
          <v-row
            v-if="customer.statusCode === 0"
            style="
              margin: 32px 32px 0;
              background: #fff0f0;
              border-radius: 4px;
              display: flex;
              justify-content: center;
            "
          >
            <p class="content-bold" style="color: #ff1e24">
              {{ $t("ContactIsDeleted") }}
            </p></v-row
          >
          <div class="sub-block">
            <v-col class="sub-heading">
              <h5>
                {{ $t("Subblock.GeneralData") }}
              </h5>
            </v-col>

            <div class="main-info">
              <v-row class="customer-field-row top24" style="margin-top: 0">
                <p class="field-name">{{ $t("ContactsName") }}</p>
                <p class="field-value">
                  {{ customer.firstName }} {{ customer.lastName }}
                </p>
              </v-row>
              <v-row
                v-if="customer.customerLang"
                class="customer-field-row top16"
              >
                <p class="field-name">
                  {{ $t("CustomerField.ContactLanguage") }}
                </p>
                <p class="field-value">
                  {{ formatCustomerLanguage() }}
                </p>
              </v-row>
              <v-row
                v-if="customer.access && customer.access.visibility"
                class="customer-field-row top16"
              >
                <p class="field-name">
                  {{ $t("ContactVisibility") }}
                </p>
                <p class="field-value">
                  {{ formatClientOwnership() }}
                </p>
              </v-row>
              <v-row
                class="customer-field-row"
                v-if="
                  customer.phoneNumbers.length &&
                  customer.phoneNumbers[0].phoneNumber !== null
                "
              >
                <div>
                  <p class="field-name">{{ $t("PhoneNumber") }}</p>
                </div>
                <div>
                  <v-row
                    class="customer-field-row top12"
                    style="margin-top: 0"
                    v-for="phoneNumber in customer.phoneNumbers"
                    :key="phoneNumber._id"
                  >
                    <p
                      @click="selectPhone(phoneNumber.phoneNumber)"
                      v-if="phoneNumber.phoneNumber"
                      class="field-value underlined"
                    >
                      {{ phoneNumber.phoneNumber }}
                    </p>
                    <CopyButton
                      :toast-message="'PhoneNumberCopied'"
                      :copy-text="phoneNumber.phoneNumber"
                    />
                  </v-row>
                </div>
              </v-row>
              <v-row
                class="customer-field-row"
                v-if="
                  customer.emails.length && customer.emails[0].email !== null
                "
              >
                <div>
                  <p class="field-name">{{ $t("Email") }}</p>
                </div>
                <div>
                  <v-row
                    class="customer-field-row top12"
                    style="margin-top: 0"
                    v-for="email in customer.emails"
                    :key="email._id"
                  >
                    <p
                      @click="selectEmail(email.email)"
                      v-if="email.email"
                      class="field-value underlined"
                    >
                      {{ email.email }}
                    </p>
                    <CopyButton
                      :toast-message="'EmailCopied'"
                      :copy-text="email.email"
                    />
                  </v-row>
                </div>
              </v-row>
              <v-row
                class="customer-field-row"
                v-if="
                  customer.organizations.length &&
                  customer.organizations[0].organization !== null
                "
              >
                <div>
                  <p class="field-name">{{ $t("OrganizationsName") }}</p>
                </div>
                <div>
                  <v-row
                    class="customer-field-row top12"
                    style="margin-top: 0"
                    v-for="organization in customer.organizations"
                    :key="organization._id"
                  >
                    <p
                      v-if="organization.organization"
                      @click="openOrgPanel(organization)"
                      class="field-value pointer underlined"
                    >
                      {{ organization.organization }}
                    </p>
                    <CopyButton
                      :toast-message="'OrganizationDataCopied'"
                      :copy-text="selectOrganization(organization)"
                    />
                  </v-row>
                </div>
              </v-row>
              <v-row v-if="customer.createdAt" class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("Created") }}
                </p>
                <p class="field-value">
                  {{ formatDate(customer.createdAt) }}
                </p>
              </v-row>
              <v-row v-if="customer.address" class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("Address") }}
                </p>
                <p class="field-value">
                  {{ customer.address }}
                </p>
              </v-row>
              <v-row
                v-if="customer.countryCode"
                class="customer-field-row top16"
              >
                <p class="field-name">
                  {{ $t("Country") }}
                </p>
                <p class="field-value">
                  {{ customerCountry }}
                </p>
              </v-row>
              <v-row
                v-if="customer.customerType"
                class="customer-field-row top16"
              >
                <p class="field-name">
                  {{ $t("CustomerType") }}
                </p>
                <p class="field-value">
                  {{ customerType }}
                </p>
              </v-row>

              <v-row v-if="customer.sex" class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("Gender") }}
                </p>
                <p class="field-value">
                  {{ formatCustomerGender() }}
                </p>
              </v-row>
              <v-row v-if="customer.birthday" class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("BirthdayDate") }}
                </p>
                <p class="field-value">
                  {{ formatDate(customer.birthday) }}
                </p>
              </v-row>
              <v-row
                v-if="customer.postalCode"
                class="customer-field-row top16"
              >
                <p class="field-name">
                  {{ $t("PostalCode") }}
                </p>
                <p class="field-value">
                  {{ customer.postalCode }}
                </p>
              </v-row>
              <v-row
                v-if="customer.personalCode"
                class="customer-field-row top16"
              >
                <p class="field-name">
                  {{ $t("CustomerField.personalCode") }}
                </p>
                <p class="field-value">
                  {{ customer.personalCode }}
                </p>
              </v-row>
              <v-row
                v-if="customer.bankAccountNumber"
                class="customer-field-row top16"
              >
                <p class="field-name">
                  {{ $t("CustomerField.BankAccountNumber") }}
                </p>
                <p class="field-value">
                  {{ customer.bankAccountNumber }}
                </p>
              </v-row>
              <v-row
                class="customer-field-row top16"
                v-if="
                  customer.tags.buyer ||
                  customer.tags.landlord ||
                  customer.tags.other ||
                  customer.tags.propertyOwner ||
                  customer.tags.tenant ||
                  customer.tags.photographer ||
                  customer.tags.valuator ||
                  customer.tags.bank_representative ||
                  customer.tags.notary ||
                  customer.tags.otherInPartner
                "
              >
                <p class="field-name">{{ $t("Tag") }}</p>
                <div>
                  <v-row v-if="customer.tags.propertyOwner" style="gap: 0">
                    <div
                      style="'background-color':#b5e7cf "
                      class="tag-container"
                    >
                      <p class="tag-name">{{ $t("PO") }}</p>
                    </div>
                    <p style="padding-bottom: 6px" class="field-value">
                      {{ $t("PropertyOwner") }}
                    </p>
                  </v-row>
                  <v-row
                    v-if="customer.tags.landlord"
                    style="gap: 0; margin-top: 0"
                  >
                    <div
                      style="background-color: #b8d8ff"
                      class="tag-container"
                    >
                      <p class="tag-name">{{ $t("L") }}</p>
                    </div>
                    <p style="padding-bottom: 6px" class="field-value">
                      {{ $t("CustomerField.CustomerType.Options.LANDLORD") }}
                    </p>
                  </v-row>
                  <v-row
                    v-if="customer.tags.buyer"
                    style="gap: 0; margin-top: 0"
                  >
                    <div
                      style="background-color: #fff2b3"
                      class="tag-container"
                    >
                      <p class="tag-name">{{ $t("B") }}</p>
                    </div>
                    <p class="field-value" style="padding-bottom: 6px">
                      {{ $t("CustomerField.CustomerType.Options.BUYER") }}
                    </p>
                  </v-row>
                  <v-row
                    v-if="customer.tags.tenant"
                    style="margin-top: 0; gap: 0"
                  >
                    <div
                      style="background-color: #fcbae2"
                      class="tag-container"
                    >
                      <p class="tag-name">{{ $t("T").toUpperCase() }}</p>
                    </div>
                    <p style="padding-bottom: 6px" class="field-value">
                      {{ $t("CustomerField.CustomerType.Options.TENANT") }}
                    </p>
                  </v-row>
                  <v-row
                    v-if="customer.tags.other"
                    style="margin-top: 0; gap: 0"
                  >
                    <div
                      style="background-color: #c3c4c6"
                      class="tag-container"
                    >
                      <p class="tag-name">{{ $t("O") }}</p>
                    </div>
                    <p style="padding-bottom: 6px" class="field-value">
                      {{ $t("CustomerField.CustomerType.Options.OTHER") }}
                    </p>
                  </v-row>

                  <v-row
                    v-if="customer.tags.photographer"
                    style="margin-top: 0; gap: 0"
                  >
                    <div
                      style="background-color: #16afb9"
                      class="tag-container"
                    >
                      <p class="tag-name white-tag-text">{{ $t("PH") }}</p>
                    </div>
                    <p style="padding-bottom: 6px" class="field-value">
                      {{ $t("Photographer") }}
                    </p>
                  </v-row>
                  <v-row
                    v-if="customer.tags.valuator"
                    style="margin-top: 0; gap: 0"
                  >
                    <div
                      style="background-color: #aa086a"
                      class="tag-container"
                    >
                      <p class="tag-name white-tag-text">{{ $t("VA") }}</p>
                    </div>
                    <p style="padding-bottom: 6px" class="field-value">
                      {{ $t("Valuator") }}
                    </p>
                  </v-row>
                  <v-row
                    v-if="customer.tags.notary"
                    style="margin-top: 0; gap: 0"
                  >
                    <div
                      style="background-color: #4c8dd9"
                      class="tag-container"
                    >
                      <p class="tag-name white-tag-text">{{ $t("NO") }}</p>
                    </div>
                    <p style="padding-bottom: 6px" class="field-value">
                      {{ $t("Notary") }}
                    </p>
                  </v-row>
                  <v-row
                    v-if="customer.tags.bank_representative"
                    style="margin-top: 0; gap: 0"
                  >
                    <div
                      style="background-color: #b54616"
                      class="tag-container"
                    >
                      <p class="tag-name white-tag-text">{{ $t("BA") }}</p>
                    </div>
                    <p style="padding-bottom: 6px" class="field-value">
                      {{ $t("BankRepresentative") }}
                    </p>
                  </v-row>
                  <v-row
                    v-if="customer.tags.otherInPartner"
                    style="margin-top: 0; gap: 0"
                  >
                    <div
                      style="background-color: #75787a"
                      class="tag-container"
                    >
                      <p class="tag-name white-tag-text">{{ $t("O") }}</p>
                    </div>
                    <p style="padding-bottom: 6px" class="field-value">
                      {{ $t("Other") }}
                    </p>
                  </v-row>
                </div>
              </v-row>
            </div>
            <v-col class="content-block"> </v-col>
          </div>
          <div
            class="sub-block"
            style="padding-left: 32px; padding-right: 32px"
            v-if="user.companyId !== '_dw_1O8jj0QTYIRahjbF'"
          >
            <div class="separator-row-main">
              <td class="separator-row"></td>
            </div>
            <div>
              <h5 class="sub-heading" style="padding-left: 0">
                {{ $t("Interests") }}
                <button
                  v-if="customer.statusCode !== 0 && !customer.isProspect"
                  class="add-persons"
                  @click="openInterests()"
                  style="margin-left: 16px"
                >
                  <img src="../../assets/images/plus.svg" alt="" />
                  <span class="add-person-inside">{{ $t("AddInterest") }}</span>
                </button>
              </h5>
            </div>
            <InterestsArea
              :key="interestsUpdater"
              @editInterest="openInterests"
              @openListingPanel="openListingPanel"
              @closeListingPanel="closeListingPanel"
              @funnelRemoved="$emit('funnelRemoved')"
              @refreshLeads="$emit('refreshLeads')"
              :customer-data="customer"
              :interests-data="customer.interests"
              :listing-key="listingKey"
            />
            <div
              v-if="customer.interests.length === 0"
              style="padding-bottom: 8px"
            ></div>
            <div v-else style="padding-bottom: 32px"></div>
          </div>
          <div
            class="sub-block"
            style="
              padding-left: 32px;
              padding-right: 32px;
              padding-bottom: 32px;
            "
            v-if="!customer.isProspect"
          >
            <div class="separator-row-main">
              <td class="separator-row"></td>
            </div>
            <h5 class="sub-heading" style="padding-left: 0; padding-bottom: 0">
              {{ $t("Subblock.RelatedPersons.COMP") }}
              <button
                v-if="customer.statusCode !== 0"
                style="margin-left: 16px"
                class="add-persons-button"
                @click="addNewRelatedPerson = true"
              >
                <img src="../../assets/images/plus.svg" alt="" />
                <span class="add-person-inside">{{ $t("AddCustomer") }}</span>
              </button>
            </h5>
            <v-row v-if="addNewRelatedPerson" style="padding: 24px 24px 16px 0">
              <ClientListSearch
                id="contact-related-person-search"
                :placeholder="$t('Contact')"
                :icon-left="'clients.svg'"
                @itemChosen="addContactToRelatedPeople"
                @addNew="addNewContact"
              />
            </v-row>
            <div
              v-if="customer.relations && customer.relations.length > 0"
              style="padding-bottom: 24px"
            ></div>
            <span :key="relatedPeopleUpdater">
              <div
                style="padding-bottom: 12px"
                v-for="(relatedCustomer, index) in customer.relations"
                :key="relatedCustomer.customerId"
              >
                <div v-if="index !== 0" class="related-person-row"></div>
                <v-row>
                  <div
                    class="relationIcon"
                    @click="openCustomerDetailpanel(relatedCustomer)"
                  >
                    <span class="font-20-medium">
                      {{ relatedCustomer.customerName[0].toUpperCase() }}
                    </span>
                  </div>
                  <v-row style="justify-content: space-between">
                    <div class="related-person-name">
                      <span
                        class="content-medium related-person-customer-name"
                        @click="openCustomerDetailpanel(relatedCustomer)"
                      >
                        {{ relatedCustomer.customerName }}
                      </span>
                      <span v-if="relatedCustomer.organization"> – </span>
                      <span v-if="relatedCustomer.organization">
                        {{ relatedCustomer.organization }}
                      </span>
                    </div>
                    <div style="display: flex">
                      <p
                        style="
                          display: flex;
                          align-items: center;
                          cursor: pointer;
                        "
                        @click="openDeleteModal(relatedCustomer)"
                      >
                        <img src="@/assets/images/close_grey.svg" alt="" />
                      </p>
                    </div>
                  </v-row>
                </v-row>
                <div
                  v-if="index === customer.relations.length - 1"
                  style="margin-bottom: -12px"
                ></div>
              </div>
            </span>
          </div>
          <div
            class="sub-block"
            style="padding-left: 32px; padding-right: 32px"
          >
            <div class="separator-row-main">
              <td class="separator-row"></td>
            </div>
            <div
              v-if="!notesLoaded"
              class="loader"
              style="position: absolute; top: 50%; left: 50%"
            ></div>
            <div
              v-if="customer.statusCode !== 0"
              class="notes"
              style="padding-left: 0"
            >
              <div
                style="
                  display: flex;
                  flex-wrap: nowrap;
                  gap: 16px;
                  padding-bottom: 24px;
                "
              >
                <h5>{{ $t("Notes") }}</h5>
                <div @click="addNoteCounter++" class="note-header-content">
                  <img
                    height="16"
                    width="16"
                    src="../../assets/images/plus.svg"
                    alt=""
                  />
                  <p>{{ $t("AddNote") }}</p>
                </div>
              </div>
              <BaseNotes
                :add-new-note-counter="addNoteCounter"
                :dropdown-style="{ right: '0px' }"
                :show-no-notes-message="false"
                :view="{
                  name: 'contact',
                  itemId: this.customerPreviewId
                    ? this.customerPreviewId
                    : routeParams,
                  itemName: customer.customerName,
                }"
                @notesCounter="setNotesCount"
              />
              <div
                name="empty-notes-padding"
                :key="notesCountUpdater"
                v-bind:style="[
                  notesCount === 0
                    ? { 'padding-bottom': '8px' }
                    : { 'padding-bottom': '32px' },
                ]"
              ></div>
            </div>
            <div
              v-if="customer.statusCode === 0"
              class="notes"
              style="padding-left: 0"
            >
              <div
                style="
                  display: flex;
                  flex-wrap: nowrap;
                  gap: 16px;
                  padding-bottom: 24px;
                "
              >
                <h5>{{ $t("Notes") }}</h5>
                <div @click="addNoteCounter++" class="note-header-content">
                  <img
                    height="16"
                    width="16"
                    src="../../assets/images/plus.svg"
                    alt=""
                  />
                  <p>{{ $t("AddNote") }}</p>
                </div>
              </div>
              <BaseNotes
                :add-new-note-counter="addNoteCounter"
                :dropdown-style="{ right: '0px' }"
                :view="{
                  name: 'contact',
                  itemId: this.customerPreviewId
                    ? this.customerPreviewId
                    : routeParams,
                  itemName: customer.customerName,
                }"
              />
              <div
                name="empty-notes-padding"
                :key="notesCountUpdater"
                v-bind:style="[
                  notesCount === 0
                    ? { 'padding-bottom': '8px' }
                    : { 'padding-bottom': '32px' },
                ]"
              ></div>
            </div>
          </div>
          <div
            class="sub-block"
            style="padding-left: 32px; padding-right: 32px"
          >
            <div class="separator-row-main">
              <td class="separator-row"></td>
            </div>
            <div class="activities">
              <h5 class="sub-heading" style="padding: 0 0 0 0">
                {{ $t("TasksAndEvents") }}
                <button
                  v-if="customer.statusCode !== 0"
                  @click="openNewTask"
                  type="button"
                  style="margin-left: 16px"
                  class="add-tasks"
                >
                  <img src="../../assets/images/plus.svg" alt="" />
                  <span class="add-task-inside">{{ $t("AddTask.Title") }}</span>
                </button>
              </h5>
            </div>
            <div style="margin-bottom: 24px">
              <BaseTasksAndEvents
                :show-no-tasks-message="false"
                :view="{
                  name: 'contact',
                  itemId: customerPreviewId ? customerPreviewId : routeParams,
                }"
                :open-new-task-add-widget="openTaskAddWidgetCounter"
                @tasks="() => $emit('update')"
                :labels-and-order="{
                  overDueTasks: {
                    label: false,
                    order: 2,
                  },
                  noDueDateTasks: {
                    label: false,
                    order: 1,
                  },
                  todayTasks: {
                    label: false,
                    order: 3,
                  },
                  upcomingTasks: {
                    label: false,
                    order: 4,
                  },
                }"
                :show-toggle-button="true"
              />
            </div>
            <EditPanel
              v-if="taskEditOpen"
              :clicked-task="taskInEdit"
              @deleteTask="deleteTask"
              @updateKey="getTasks"
              @closePanel="taskEditOpen = false"
            />
            <v-row
              v-if="allTasksCounter === 0"
              style="padding-bottom: 8px"
            ></v-row>
          </div>
          <div
            class="sub-block"
            style="padding-left: 32px; padding-right: 32px"
            v-if="
              !customer.isProspect &&
              (!user.config || (user.config && user.access?.features?.modules?.projects?.enabled)) &&
              user.companyId !== '_dw_1O8jj0QTYIRahjbF'
            "
          >
            <div class="separator-row-main">
              <td class="separator-row"></td>
            </div>
            <h5
              class="sub-heading"
              style="padding-left: 0; padding-bottom: 24px"
            >
              {{ $t("RelatedProjects") }}
              <button
                v-if="customer.statusCode !== 0"
                style="margin-left: 16px"
                class="add-persons deal-button"
                @click="projectSearchOpen = true"
              >
                <img src="../../assets/images/plus.svg" alt="" />
                <span class="add-person-inside">{{ $t("AddProject") }}</span>
              </button>
            </h5>
            <div style="padding-bottom: 24px">
              <BaseToggle
                v-model="requestUndoneProjects"
                :label="$t('ShowArchived').toLowerCase()"
              />
            </div>
            <v-row v-if="projectSearchOpen" style="padding: 0 24px 16px 0">
              <BaseProjectSearch
                v-click-outside="() => (this.projectSearchOpen = false)"
                :icon-left="'search.svg'"
                :show-completed-projects="requestUndoneProjects"
                :show-dropdown-arrow="true"
                :placeholder="$t('Project')"
                @addNew="
                  () => {
                    this.projectSearchOpen = false;
                    openDealAdd();
                  }
                "
                @change="
                  (item) => {
                    addRelatedProject(item);
                    this.projectSearchOpen = false;
                  }
                "
                :dropdown-offset-y="52"
              />
            </v-row>
            <div
              v-if="!projectsLoaded"
              class="loader"
              style="position: absolute; top: 50%; left: 50%"
            ></div>
            <div
              :key="updateRelatedProjects"
              v-if="projectsLoaded && relatedDeals"
              style="padding-bottom: 8px"
            >
              <template v-if="requestUndoneProjects">
                <div
                  class="related-deals"
                  v-for="el in relatedDeals"
                  :key="el._id"
                  @click="goToDealDetail(el._id)"
                >
                  <div
                    class="related-project-container"
                    v-bind:class="{
                      'green-border':
                        (el.pipelineStep === 'archived' ||
                          el.pipelineStep === 'Done') &&
                        el.endState &&
                        el.endState[el.endState.length - 1].projectEndReason ===
                          'Won',
                      'red-border':
                        (el.pipelineStep === 'archived' ||
                          el.pipelineStep === 'Done') &&
                        el.endState &&
                        (el.endState[el.endState.length - 1]
                          .projectEndReason === 'Lost' ||
                          el.endState[el.endState.length - 1]
                            .projectEndReason === 'Cancelled'),
                      'regular-border':
                        (el.pipelineStep !== 'archived' &&
                          el.pipelineStep !== 'Done') ||
                        (el.endState &&
                          el.endState[el.endState.length - 1]
                            .projectEndReason !== 'Lost' &&
                          el.endState[el.endState.length - 1]
                            .projectEndReason !== 'Won' &&
                          el.endState[el.endState.length - 1]
                            .projectEndReason !== 'Cancelled'),
                    }"
                  >
                    <v-row class="project-type" style="gap: 8px">
                      <DealTypeContainer
                        :small-font="false"
                        :deal-type="el.dealType"
                      />
                      <div class="related-project-info">
                        {{
                          getNumberOfPipelineStage(el.pipelineStep) +
                          " " +
                          formatPipelineStep(el.pipelineStep) +
                          getProjectDoneDate(el)
                        }}
                      </div>
                    </v-row>
                    <v-row class="related-project-name">
                      {{ el.name }}
                    </v-row>
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  class="related-deals"
                  v-for="el in relatedDeals.filter(
                    (deal) => deal.pipelineStep !== 'archived'
                  )"
                  :key="el._id"
                  @click="goToDealDetail(el._id)"
                >
                  <div
                    class="related-project-container"
                    v-bind:class="{
                      'green-border':
                        (el.pipelineStep === 'archived' ||
                          el.pipelineStep === 'Done') &&
                        el.endState &&
                        el.endState[el.endState.length - 1].projectEndReason ===
                          'Won',
                      'red-border':
                        (el.pipelineStep === 'archived' ||
                          el.pipelineStep === 'Done') &&
                        el.endState &&
                        (el.endState[el.endState.length - 1]
                          .projectEndReason === 'Lost' ||
                          el.endState[el.endState.length - 1]
                            .projectEndReason === 'Cancelled'),
                      'regular-border':
                        (el.pipelineStep !== 'archived' &&
                          el.pipelineStep !== 'Done') ||
                        (el.endState &&
                          el.endState[el.endState.length - 1]
                            .projectEndReason !== 'Lost' &&
                          el.endState[el.endState.length - 1]
                            .projectEndReason !== 'Won' &&
                          el.endState[el.endState.length - 1]
                            .projectEndReason !== 'Cancelled'),
                    }"
                  >
                    <v-row class="project-type" style="gap: 8px">
                      <DealTypeContainer
                        :small-font="false"
                        :deal-type="el.dealType"
                      />
                      <div class="related-project-info">
                        {{
                          getNumberOfPipelineStage(el.pipelineStep) +
                          " " +
                          formatPipelineStep(el.pipelineStep) +
                          getProjectDoneDate(el)
                        }}
                      </div>
                    </v-row>
                    <v-row class="related-project-name">
                      {{ el.name }}
                    </v-row>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="customer.statusCode !== 0 && !customer.isProspect"
            class="sub-block"
            style="padding-left: 32px; padding-right: 32px"
          >
            <div class="separator-row-main">
              <td class="separator-row"></td>
            </div>
            <div style="padding-top: 16px; padding-bottom: 40px">
              <FileUpload
                @success="getCustomer(customer.customerId)"
                :files.sync="customer.files"
                :clientSidepanelOpen="clientSidepanelOpen"
                :targetId="customer.customerId"
                :target-object="'contact'"
                :id="'customer-upload'"
                :buttonCornerLocation="false"
                :file-counter="customer.files.length"
                :aml-panel="0"
              />
            </div>
          </div>
          <div
            v-if="customer.statusCode === 0"
            class="sub-block"
            style="padding-left: 32px; padding-right: 32px"
          >
            <div class="separator-row-main">
              <td class="separator-row"></td>
            </div>
            <div style="padding-top: 16px; padding-bottom: 40px">
              <FileUpload
                @success="getCustomer(customer.customerId)"
                :files.sync="customer.files"
                :clientSidepanelOpen="clientSidepanelOpen"
                :targetId="customer.customerId"
                :target-object="'contact'"
                :hide-button="true"
                :id="'customer-upload'"
                :buttonCornerLocation="false"
                :file-counter="customer.files.length"
                :aml-panel="0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <SidepanelAdd
      :contactPerson="customer"
      :fromAnotherPanel="true"
      @closed="closeDealAdd()"
      @saveResponse="closeDealAdd"
      v-if="dealAddOpen"
    />
    <OrganizationSidepanel
      :selected-organization="clickedOrg"
      @closed="closeDealAdd()"
      v-if="orgDetailOpen"
      @panelClosed="closePanel()"
    />
    <InterestPanel
      :customer-id="customer.customerId"
      :existingInterest="interestToEdit"
      :customer-data="customer"
      v-if="interestsOpen"
      @closed="closeInterests()"
    />
    <ListingSidepanel
      @overlayClicked="closeListingPanel"
      :listing-preview-id="chosenListingPreviewId"
      v-if="listingPanelOpen"
      :contact-view="true"
    />
    <AddModal
      style="z-index: 120"
      v-if="editModalOpen || newCustomerAdd"
      :customerData="newCustomerAdd ? null : customer"
      :isEdit="editModalOpen"
      @customerUpdated="customerUpdated"
      @newAddedContact="addContactToRelatedPeople"
      :not-contact-view-route="true"
      @closed="closeAddModal"
      @deleted="customerDeleted"
    />
    <AddBrokerModal
      v-if="isAddBrokerOpen"
      :item.sync="customer"
      @close-modal="isAddBrokerOpen = false"
      @updated="setupContact()"
      :customer-modal="true"
    />
    <DeleteModal
      style="z-index: 121"
      :removing="true"
      @canceled="isDeleteModal = false"
      @approved="deleteRelatedCustomer()"
      v-if="isDeleteModal"
    />
    <BrokerInfoPanel
      v-if="brokerDetailOpened"
      :item="clickedBroker"
      @closed="() => (brokerDetailOpened = false)"
      :position="brokerPanelPosition"
    />
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import FileUpload from "../common/FileUpload.vue";
import nanoid from "nanoid";
import AddModal from "../../components/Kliendiregister/AddModal.vue";
import InterestPanel from "@/components/Kliendiregister/InterestPanel";
import DeleteModal from "@/components/common/DeleteModal";
import SidepanelAdd from "@/components/Deals/SidepanelAdd";
import ListingSidepanel from "@/components/Listings/ListingSidepanel";
import OrganizationSidepanel from "@/components/Organization/OrganizationSidepanel";
import contactApi from "@/http/contact";
import EditPanel from "@/components/Calendar/EditPanel";
import EditButton from "@/components/common/EditButton";
import BaseProjectSearch from "@/components/common/BaseProjectSearch";
import BaseNotes from "@/components/common/BaseNotes";
import BaseTasksAndEvents from "@/components/common/BaseTasksAndEvents";
import CopyButton from "@/components/common/CopyButton";
import BaseToggle from "@/components/common/BaseToggle";
import InterestsArea from "@/components/Kliendiregister/InterestsArea";
import DealTypeContainer from "@/components/common/DealTypeContainer";
import ClientListSearch from "@/components/common/ClientListSearch";
import BrokerInfoPanel from "@/components/common/BrokerInfoPanel";
import AddBrokerModal from "@/components/common/AddBrokerModal";

const countries_et = require("../../components/common/countries_et.js");
const countries_en = require("../../components/common/countries_en.js");
export default {
  name: "SidepanelDetail",
  props: [
    "customerData",
    "customerDataLeadsList",
    "fromProjectGeneral",
    "onlyPanel",
    "customerPreviewId",
    "promptPanelClose",
  ],
  components: {
    ClientListSearch,
    BaseTasksAndEvents,
    BaseNotes,
    BaseProjectSearch,
    EditButton,
    OrganizationSidepanel,
    ListingSidepanel,
    InterestPanel,
    EditPanel,
    FileUpload,
    SidepanelAdd,
    AddModal,
    DeleteModal,
    CopyButton,
    BaseToggle,
    InterestsArea,
    DealTypeContainer,
    BrokerInfoPanel,
    AddBrokerModal,
  },
  data: () => ({
    brokerPanelPosition: null,
    brokerDetailOpened: false,
    sidepanelUpdater: 0,
    notesCount: 0,
    isAddBrokerOpen: false,
    notesCountUpdater: 0,
    requestUndoneProjects: false,
    interestsUpdater: 0,
    interestToEdit: null,
    openTaskAddWidgetCounter: 0,
    addNoteCounter: 0,
    notesLoading: false,
    projectSearchOpen: false,
    allTasksCounter: 0,
    taskEditOpen: false,
    showCompletedTasks: "",
    taskInEdit: null,
    clientSidepanelOpen: true,
    relatedPeopleUpdater: 0,
    newCustomerAdd: false,
    updateRelatedProjects: 0,
    addNewRelatedPerson: false,
    newTaskOpen: false,
    orgRegCode: null,
    orgDetailOpen: false,
    clickedOrg: null,
    listingPanelOpen: false,
    listing: null,
    chosenListingPreviewId: null,
    chosenInterestId: null,
    routeParams: null,
    listingKey: 0,
    allDeals: [],
    dealAddOpen: false,
    interestsOpen: false,
    isDeleteModal: false,
    deletable: null,
    clickedBroker: null,
    notes: null,
    editModalOpen: false,
    newNote: {
      content: "",
      linkedTo: null,
      createdAt: null,
      parentName: null,
    },
    dataLoaded: false,
    selectedRelation: null,
    autoSuggestionsLoaded: false,
    relationsLoaded: false,
    tasksLoaded: false,
    notesLoaded: false,
    projectsLoaded: false,
    tasks: [],
    task: null,
    relatedDeals: [],
    newTask: {
      id: nanoid(20),
      content: "",
      linkedTo: null,
      createdAt: null,
      isCompleted: false,
      parentName: null,
    },
    newSelfServiceUser: {
      name: "",
      contactId: "",
      email: "",
      companyId: "",
    },
    customer: null,
    editMode: false,
    countries: [
      "Estonia",
      "Russia",
      "Finland",
      "Germany",
      "Spain",
      "Poland",
      "UK",
    ],
    clientTypes: [
      "Buyer",
      "Seller",
      "Landlord",
      "Tenant",
      "Looking to buy",
      "Looking to rent",
      "Partner",
      "Broker partner",
    ],
    customerLanguages: [
      "Estonian",
      "Russian",
      "Spanish",
      "Japanese",
      "Latvian",
      "Finnish",
    ],
  }),
  async created() {
    await this.setupContact();
  },
  mounted() {
    setTimeout(() => {
      document.documentElement.style.overflow = "hidden";
    }, 1);
    if (this.$route.params.openedOrganizationRegCode) {
      let orgCode = this.$route.params.openedOrganizationRegCode;
      let contentWrapper = null;
      const orgPanelCheck = setInterval(() => {
        contentWrapper = document.getElementById("content-wrapper");
        if (contentWrapper) {
          this.openOrgPanel(orgCode);
          clearInterval(orgPanelCheck);
        }
      }, 100);
    }
  },
  beforeDestroy() {
    if (this.onlyPanel) {
      this.$route.params.openedOrganizationRegCode = null;
      return;
    }
    if (!this.customerPreviewId) {
      setTimeout(() => {
        document.documentElement.style.overflow = "visible";
      }, 10);
    }
    this.$route.params.openedOrganizationRegCode = null;
  },
  computed: {
    ...mapGetters(["user"]),
    customerCountry() {
      let formattedCountry = null;
      if (this.customer.countryCode === "EE") {
        return this.$t("Estonia");
      } else {
        this.countries.forEach((country) => {
          if (country.value === this.customer.countryCode.value) {
            formattedCountry = country.text;
          }
        });
        return formattedCountry;
      }
    },
    customerType: function () {
      if (this.customer.customerType === "Buyer") {
        return "Ostja";
      }
      if (this.customer.customerType === "Seller") {
        return "Müüja";
      }
      if (this.customer.customerType === "Landlord") {
        return "Maaomanik";
      }
      if (this.customer.customerType === "Tenant") {
        return "Üürnik";
      }
      if (this.customer.customerType === "Looking to buy") {
        return "Soovivad osta";
      }
      if (this.customer.customerType === "Looking to rent") {
        return "Soovivad üürida";
      }
      return 0;
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  methods: {
    ...mapActions([
      "setClientSidepanel",
      "changeModalStateDeals",
      "setShowCompletedProjectTasks",
      "getProspects",
    ]),
    async setupContact() {
      this.showCompletedTasks = this.showCompletedProjectTasks;
      this.task = this.getEmptyTask();
      this.setRouteParams();
      if (this.$i18n.locale === "et") {
        this.countries = Object.values(countries_et);
        this.countries = this.countries[0];
        this.countries = Object.keys(this.countries).map((key) => ({
          value: key,
          text: this.countries[key],
        }));
      }
      if (this.$i18n.locale === "en") {
        this.countries = Object.values(countries_en);
        this.countries = this.countries[0];
        this.countries = Object.keys(this.countries).map((key) => ({
          value: key,
          text: this.countries[key],
        }));
      }
      if (!this.customerPreviewId) {
        this.getCustomer(this.routeParams);
      } else {
        this.getCustomer(this.customerPreviewId);
      }
      document.onkeydown = (evt) => {
        evt = evt || window.event;
        if (evt.key === "Escape") {
          this.closePanel();
          document.onkeydown = null;
        }
      };
    },
    async promoteToContact(prospect) {
      await axios.post(
        `/api/development/${prospect.linkedDevelopmentId}/prospects/promote`,
        {
          prospectId: prospect.customerId,
        }
      );

      await this.getCustomer(prospect.customerId);
      await this.getProspects(prospect.linkedDevelopmentId);
    },
    openBrokerDetailPanel(event, person) {
      this.brokerPanelPosition = {
        left: event.clientX + "px",
        top: event.clientY + "px",
      };
      this.brokerDetailOpened = true;
      this.clickedBroker = person;
    },
    formatPipelineStep(pipelineStep) {
      if (pipelineStep === "qualified") {
        return this.$t("NewLeads");
      } else if (pipelineStep === "contactMade") {
        return this.$t("NegotiatingRepresentation");
      } else if (pipelineStep === "review") {
        return this.$t("ShowingAndAdvertising");
      } else if (pipelineStep === "Finished") {
        return this.$t("FinalizingDeal");
      } else if (pipelineStep === "Done") {
        return this.$t("DoneDone");
      } else if (pipelineStep === "archived") {
        return this.$t("Archived");
      } else if (pipelineStep === "preparation") {
        return this.$t("Preparation");
      }
    },
    getNumberOfPipelineStage(pipelineStep) {
      if (pipelineStep === "qualified") {
        return 1;
      } else if (pipelineStep === "contactMade") {
        return 2;
      } else if (pipelineStep === "preparation") {
        return 3;
      } else if (pipelineStep === "review") {
        return 4;
      } else if (pipelineStep === "Finished") {
        return 5;
      } else if (pipelineStep === "Done") {
        return 6;
      } else {
        return "";
      }
    },
    getProjectDoneDate(project) {
      if (project.doneDate && project.pipelineStep === "Done") {
        return " • " + project.doneDate;
      } else {
        return "";
      }
    },
    selectPhone(phoneNumber) {
      window.open(`tel:${phoneNumber}`, "popup");
    },
    selectEmail(email) {
      window.open(`mailto:${email}`, "popup");
    },
    selectOrganization(org) {
      let copyString = this.$t("CompanyName") + "    " + org.company_name;
      if (org.reg_code) {
        copyString +=
          `\n${this.$t("RegistrationNumber")}` + "    " + org.reg_code;
      }
      if (org.kmkr) {
        copyString += `\n KMKR` + "    " + org.reg_code;
      }
      return copyString;
    },
    async addRelatedProject(project) {
      if (this.relatedDeals) {
        let found = this.relatedDeals.find((item) => item._id === project._id);
        if (!found) {
          this.relatedDeals.unshift(project);
        } else {
          return;
        }
      }
      //this.relatedDeals.unshift(project);
      let res = await axios.get(
        `/api/contact/relatedProjects/add/${this.customer.customerId}/${project._id}`
      );
      if (res.status !== 200) {
        this.toastError("Error: adding related project");
      }
    },
    async openCustomerDetailpanel(client) {
      if (this.fromProjectGeneral) {
        this.routeParams = client.customerId;
        this.getCustomer(this.routeParams);
      } else {
        await this.$router.push({
          name: "KliendiregisterDetail",
          params: { id: client.customerId },
        });
      }
      this.sidepanelUpdater++;
    },
    async addContactToRelatedPeople(contact) {
      this.closeAddModal();
      this.addNewRelatedPerson = false;
      if (!contact) return;

      const relatedCustomer = {
        customerName: contact.customerName,
        organization: contact.organization,
        customerId: contact.customerId,
      };
      if (this.customer.customerRelations) {
        let found = this.customer.customerRelations.find(
          (item) => item === relatedCustomer.customerId
        );
        if (!found) {
          this.customer.relations.unshift(relatedCustomer);
        } else {
          return;
        }
      }

      this.relatedPeopleUpdater++;
      await axios.post(
        `/api/contact/relation/add/${this.customer.customerId}`,
        { relationId: relatedCustomer.customerId }
      );
    },
    openAddUsers() {
      this.isAddBrokerOpen = true;
    },
    async deleteTask(taskId) {
      let msToken = null;
      if (this.user.microsoft) {
        const access = await this.getMsalAccessToken();
        if (access) {
          msToken = access.accessToken;
        }
      }
      const res = await axios.post(`/api/task/delete/${taskId}`, {
        accessToken: msToken,
      });

      if (res.status === 200) {
        this.tasks = this.tasks.filter((task) => task._id !== taskId);
        this.taskUpdater++;
        this.toastSuccess(this.$t("Deleted"));
      } else {
        this.toastError(this.$t("ErrorDeleting"));
      }
    },
    getEmptyTask() {
      return {
        _id: null,
        createdAt: null,
        content: "",
        assignedTo: {
          userId: null,
          name: null,
        },
        isCompleted: false,
        type: "generic",
        category: "task",
        dates: {
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        },
        linkedProject: {
          id: null,
          name: null,
        },
        linkedContact: {
          id: null,
          name: null,
        },
        isPrivate: false,
      };
    },
    openNewTask() {
      this.openTaskAddWidgetCounter++;
    },
    async addNewTask(taskName) {
      this.newTaskOpen = false;

      if (taskName === "" || /^\s*$/.test(taskName)) {
        this.tasks.splice(0, 1);
        return;
      }

      this.mapTask(taskName);

      const saveResponse = await axios.post("/api/task", this.task);

      if (saveResponse.status === 200) {
        console.log(saveResponse.data);
      } else {
        console.log("task add failed");
      }
      await this.getTasks();
      this.task = this.getEmptyTask();
    },
    mapTask(taskName) {
      this.task.content = taskName;
      this.task.linkedContact.id = this.$route.params.id;
      this.task.linkedContact.name = this.customer.customerName;
      this.task.createdAt = moment().format();
    },
    addNewContact() {
      this.closeListingPanel();
      this.editModalOpen = false;
      this.newCustomerAdd = true;
      document.getElementById("panel-wrapper-customer").style.display = "none";
    },
    async sendInvitation() {
      const userToPost = {
        name: this.customer.customerName,
        contactId: this.customer.customerId,
        email: this.customer.email,
        companyId: this.user.companyId,
      };
      await axios
        .post("/api/project/self-service/create-user", userToPost)
        .then(async (res) => {
          if (res.status === 201) {
            this.toastSuccess(this.$t("SelfServiceUserAdded"));
            this.customer.selfUserExists = true;
            await contactApi.updateContact(this.customer);
          } else if (res.status === 422) {
            this.toastError(this.$t("PleaseAddEmailToContact"));
          } else if (res.status === 409) {
            this.toastError(this.$t("UserAlreadyAdded"));
          } else {
            this.toastError(this.$t("ErrorAdding"));
          }
        });
    },
    formatClientOwnership() {
      if (this.customer.access.visibility === "company") {
        return this.$t("Company");
      } else if (this.customer.access.visibility === "group") {
        return this.$t("Group");
      } else if (this.customer.access.visibility === "private+") {
        return this.$t("Private+");
      } else if (this.customer.access.visibility === "private") {
        return this.$t("Private");
      }
    },
    formatCustomerGender() {
      if (this.customer.sex === "MALE") {
        return this.$t("Male");
      } else if (this.customer.sex === "FEMALE") {
        return this.$t("Female");
      }
    },
    formatCustomerLanguage() {
      if (this.customer.customerLang === "ESTONIAN") {
        return this.$t("Estonian");
      } else if (this.customer.customerLang === "ENGLISH") {
        return this.$t("English");
      } else if (this.customer.customerLang === "RUSSIAN") {
        return this.$t("Russian");
      } else if (this.customer.customerLang === "FINNISH") {
        return this.$t("Finnish");
      } else if (this.customer.customerLang === "GERMAN") {
        return this.$t("German");
      }
    },
    ...mapActions([
      "getListings",
      "setClientSidepanel",
      "addNotes",
      "changeModalStateDeals",
      "setShowCompletedProjectTasks",
    ]),
    openListingPanel(listingId, interestId) {
      if (
        this.chosenListingPreviewId === listingId &&
        this.chosenInterestId === interestId
      ) {
        this.closeListingPanel(listingId, interestId);
      } else {
        this.closeListingPanel();
        this.chosenListingPreviewId = listingId;
        this.chosenInterestId = interestId;
        this.listingPanelOpen = true;

        document.getElementById("panel-wrapper-customer").style.right = "636px";
        document.getElementById("panel-wrapper-customer").style.borderRight =
          "7px solid #e6e8ec";
      }
    },
    closeListingPanel(listingId, interestId) {
      if (!listingId && !interestId) {
        listingId = this.chosenListingPreviewId;
        interestId = this.chosenInterestId;
      }
      if (listingId && interestId) {
        this.chosenListingPreviewId = null;

        this.listingPanelOpen = false;

        document.getElementById("panel-wrapper-customer").style.right = "0";
        document.getElementById("panel-wrapper-customer").style.borderRight =
          "0px solid #e6e8ec";
      }
    },
    goToDealDetail(dealId) {
      if (
        this.user.isAdmin ||
        this.relatedDeals.find((deal) => deal.creator.userId === this.user._id)
      ) {
        this.$router
          .push({
            name: "DealDetail",
            params: { id: dealId },
          })
          .then(() => {});
      } else {
        this.toastError(this.$t("noProjectAccess"));
      }
    },
    setRouteParams() {
      if (this.customerData) {
        if (this.customerData.customerId) {
          this.routeParams = this.customerData.customerId;
        } else if (this.customerData.contactId) {
          this.routeParams = this.customerData.contactId;
        } else {
          this.routeParams = this.customerData;
        }
      } else if (this.customerDataLeadsList) {
        this.routeParams = this.customerDataLeadsList;
      } else {
        this.routeParams = this.$route.params.id;
      }
    },

    openDeleteModal(relatedCustomer) {
      this.isDeleteModal = true;
      this.deletable = relatedCustomer;
    },
    async deleteRelatedCustomer() {
      let dataObject = {
        customerId: this.customer.customerId,
        relatedPersonId: this.deletable.customerId,
      };
      await axios.post(`/api/contact/relation/delete`, dataObject);
      this.isDeleteModal = false;
      this.$router.go();
    },
    closeAccordion() {
      if (this.dealAddOpen) {
        this.closeDealAdd();
      } else if (this.interestsOpen) {
        this.closeInterests();
      } else if (this.orgDetailOpen) {
        this.closeOrgPanel();
      }
    },
    openAddModal() {
      this.closeListingPanel();
      this.editModalOpen = true;
    },
    closeAddModal() {
      this.editModalOpen = false;
      this.newCustomerAdd = false;
      document.getElementById("panel-wrapper-customer").style.display =
        "initial";
      document.documentElement.style.overflow = "hidden";
    },
    customerUpdated() {
      this.getCustomer(this.routeParams);
      this.$emit("updated");
    },
    moment: function () {
      return moment();
    },
    closeOrgPanel() {
      let panel = document.getElementById("panel-wrapper-customer");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-customer");
      let wrappedHeader = document.getElementById("wrapped-header-customer");
      wrappedHeader.style.display = "none";
      wrappedHeader.style.opacity = "0";
      panelContent.style.display = "initial";
      panel.style.overflow = "scroll";
      panel.style.minWidth = "640px";
      panel.style.width = "640px";
      panel.style.right = "0";
      overlay.style.zIndex = "101";
      setTimeout(async () => {
        this.orgDetailOpen = false;
      }, 500);
    },
    async closeInterests() {
      this.$emit("funnelRemoved");
      let panel = document.getElementById("panel-wrapper-customer");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-customer");
      let wrappedHeader = document.getElementById("wrapped-header-customer");
      wrappedHeader.style.display = "none";
      wrappedHeader.style.opacity = "0";
      panelContent.style.display = "initial";
      panel.style.overflow = "scroll";
      panel.style.minWidth = "640px";
      panel.style.width = "640px";
      panel.style.right = "0";
      overlay.style.zIndex = "101";
      setTimeout(async () => {
        this.interestsOpen = false;
      }, 500);
      await this.getCustomer(this.routeParams);

      this.interestsUpdater++;
    },
    closeDealAdd(project) {
      let panel = document.getElementById("panel-wrapper-customer");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-customer");
      let wrappedHeader = document.getElementById("wrapped-header-customer");
      wrappedHeader.style.display = "none";
      wrappedHeader.style.opacity = "0";
      panelContent.style.display = "initial";
      panel.style.overflow = "scroll";
      panel.style.minWidth = "640px";
      panel.style.width = "640px";
      panel.style.right = "0";
      overlay.style.zIndex = "101";

      setTimeout(async () => {
        this.dealAddOpen = false;
      }, 500);
      if (project) {
        this.relatedDeals.unshift(project);
      }
    },
    openOrgPanel(orgRegCode) {
      this.closeListingPanel();
      if (orgRegCode.reg_code) {
        this.clickedOrg = { regCode: orgRegCode.reg_code };
      } else {
        this.clickedOrg = orgRegCode;
      }
      let panel = document.getElementById("panel-wrapper-customer");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-customer");
      panelContent.style.display = "none";
      panel.style.overflow = "hidden";
      panel.style.minWidth = "94px";
      panel.style.width = "94px";
      panel.style.right = "640px";
      //panel.style.boxShadow = "0px 0 1px rgba(28, 14, 3, 0.2)";
      overlay.style.zIndex = "5";
      let wrappedHeader = document.getElementById("wrapped-header-customer");
      wrappedHeader.style.display = "flex";
      wrappedHeader.style.opacity = "1";
      this.orgDetailOpen = true;
    },
    openInterests(interest) {
      this.interestToEdit = null;
      if (interest) {
        this.interestToEdit = interest;
      }
      this.closeListingPanel();
      let panel = document.getElementById("panel-wrapper-customer");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-customer");
      panelContent.style.display = "none";
      panel.style.overflow = "hidden";
      panel.style.minWidth = "94px";
      panel.style.width = "94px";
      panel.style.right = "558px";
      //panel.style.boxShadow = "0px 0 1px rgba(28, 14, 3, 0.2)";
      overlay.style.zIndex = "12";
      let wrappedHeader = document.getElementById("wrapped-header-customer");
      wrappedHeader.style.display = "flex";
      wrappedHeader.style.opacity = "1";
      this.interestsOpen = true;
    },
    openDealAdd() {
      this.closeListingPanel();
      let panel = document.getElementById("panel-wrapper-customer");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-customer");
      panelContent.style.display = "none";
      panel.style.overflow = "hidden";
      panel.style.minWidth = "94px";
      panel.style.width = "94px";
      panel.style.right = "558px";
      //panel.style.boxShadow = "0px 0 1px rgba(28, 14, 3, 0.2)";
      overlay.style.zIndex = "5";
      let wrappedHeader = document.getElementById("wrapped-header-customer");
      wrappedHeader.style.display = "flex";
      wrappedHeader.style.opacity = "1";
      this.dealAddOpen = true;
    },
    customerDeleted(e) {
      this.closePanel(e);
      this.$emit("updated");
    },
    closePanel(e) {
      if (this.listingPanelOpen && e.target.id === "overlay-customer") {
        this.closeListingPanel();
        /*  var els = document.querySelectorAll(".funnel-row.listing-preview-opened");
        var els2 = document.getElementById("close-white-cross");
        for (var i = 0; i < els.length; i++) {
            els[i].classList.remove('listing-preview-opened')
        }
        els2.style.display = "none" */
        this.listingKey++;
        return;
      }
      if (this.isAddBrokerOpen) return;

      if (!e) {
        this.changeModalStateDeals(0);
        this.setClientSidepanel(0);
        this.$emit("panelClosed");
        if (!this.customerData && this.fromProjectGeneral) {
          this.$emit("updated");
          this.$router.back();
        }
      } else if (e.target.id === "overlay-customer") {
        this.changeModalStateDeals(0);
        this.setClientSidepanel(0);
        this.$emit("panelClosed");
        // this.$emit("updated");
        if (
          (this.$route.params.openedOrganizationRegCode ||
            !this.customerData) &&
          !this.customerPreviewId
        ) {
          // this.$emit("updated");
          this.$router.go(-1);
        }
      }
    },
    setNotesCount(count) {
      this.notesCount = count;
      this.notesCountUpdater++;
      this.notesLoaded = true;
    },
    async getCustomer(customerId) {
      this.customer = await contactApi.getContactById(customerId);
      if (this.customer.statusCode === 0) {
        this.customer.interests = this.customer.interests.filter(
          (item) => item.statusCode !== 0
        );
      }

      this.dataLoaded = true;
      let relationsArray = this.customer.customerRelations;
      let payload = { relationsArray };
      const relationsResponse = await axios.post(
        "/api/contact/relation/get",
        payload
      );
      this.customer.relations = relationsResponse.data.foundRelations;
      this.relationsLoaded = true;

      await this.getDeal();
      await this.getTasks();

      this.newNote.linkedTo = this.customer.customerId;
      this.newNote.parentName = this.customer.customerName;
      this.newTask.parentName = this.customer.customerName;
    },
    async getTasks() {
      let response;
      let customerId;
      if (!this.customerPreviewId) {
        customerId = this.routeParams;
      } else {
        customerId = this.customerPreviewId;
      }
      if (this.showCompletedTasks) {
        response = await axios.get(`/api/tasks/contact/${customerId}`);
      } else {
        response = await axios.get(
          `/api/tasks/contact/unfinished/${customerId}`
        );
      }
      this.tasks = response.data;
      this.groupTasks();
      this.tasksLoaded = true;
    },
    groupTasks() {
      const dateNow = moment().add(-1, "d");
      this.tasks.forEach((task) => {
        if (!task.dates.endDate) {
          task.dueDate = "none";
        } else if (this.isDateOver(task.dates, dateNow)) {
          task.dueDate = "over";
        } else {
          task.dueDate = "upcoming";
        }
      });
    },
    isDateOver(obsDates, dateNow) {
      const date = moment(obsDates.endDate);
      if (obsDates.endTime) {
        const endTime = obsDates.endTime.split(":");
        date.set({ h: endTime[0], m: endTime[1] });
      } else {
        date.set({ h: 0, m: 0 });
      }
      return date.isBefore(dateNow);
    },
    async getDeal() {
      const response = await axios.post(
        `/api/contact/projects/${this.customer.customerId}`,
        { projectsIds: this.customer.relatedProjects }
      );
      this.relatedDeals = response.data.deals;
      this.projectsLoaded = true;
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  watch: {
    dataLoaded() {
      if (this.$route.params.openedOrganizationRegCode) {
        this.orgRegCode = this.$route.params.openedOrganizationRegCode;
        this.$nextTick(function () {
          if (/^\d+$/.test(this.orgRegCode)) {
            this.openOrgPanel(this.orgRegCode);
          } else {
            this.openOrgPanel(this.orgRegCode);
          }
        });
      }
    },
    "$route.params.id": function () {
      this.setRouteParams();
      this.getCustomer(this.routeParams);
    },
    customerPreviewId: function () {
      this.getCustomer(this.customerPreviewId);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

.broker-info {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.hoverable-info {
  cursor: pointer;
  background-color: #f4f5f7;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #ff5c01;
    color: #ffffff;
    font-weight: bold;
  }
}

.no-matches {
  padding: 24px;
  border-radius: 0 0 8px 8px;
  border: 1px solid $grey500;
  border-top: none;
  color: $grey950;
}

.related-person-row {
  margin-top: 12px;
  gap: 12px !important;
  padding: 0 24px 0 0;
}

.related-person-name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.related-person-customer-name {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.underlined {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.subinfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  p {
    color: #75787a !important;
  }
}

.interest-title-wrapper {
  padding: 12px;
  cursor: pointer;
  border: 1px solid #e6e8ec;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.bottom-radius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.no-bottom-radius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.interest-title-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.all-funnels-wrapper {
  display: flex;
  flex-direction: column;
}

.suggestions-wrapper {
  background: #f4f5f7;
  width: 100%;
  padding: 12px 12px 0 12px;
  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  border-bottom: 1px solid #e6e8ec;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.self-service-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  font-size: 14px;
  width: 100px;
  height: 32px;
  font-family: "Inter", sans-serif;
  text-transform: none;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.note-row {
  flex-flow: row;
  margin-top: 16px !important;
  cursor: pointer;
  max-width: 100%;
}

.note-text {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  padding-left: 4px;
  box-sizing: border-box;
}

.note-text:hover {
  cursor: pointer;
  border-radius: 8px;
}

.params {
  display: flex;
}

.relationIcon {
  border-radius: 50%;
  padding: 0 4px;
  background: $blue;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.related-deal {
  font-size: 14px !important;
  line-height: 16px;

  &:hover {
    text-decoration: underline;
  }
}

.v-text-field {
  padding: 0 !important;
}

.text-field-class {
  padding: 0 !important;
  max-width: 40%;

  .v-text-field ::v-deep input {
    padding: 0 !important;
  }
}

.registration-num {
  padding: 0 !important;
}

.birth-class {
  border: 1px solid #939597;
  border-radius: 8px;
  width: 40%;
  height: 36px;
  padding: 0 !important;

  &:focus {
    outline: none !important;
  }
}

.related-persons {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 0 !important;
}

.register-code {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  align-items: baseline;
}

.relations {
  padding-left: 0;
  padding-right: 12px;
  padding-top: 0;
}

.interests-row {
  padding-left: 12px;
  padding-right: 12px;
}

.notes {
  padding-left: 0;
  padding-top: 32px;
}

.activities {
  padding-right: 56px;
  padding-bottom: 24px;
  padding-top: 32px;
}

.contact-types {
  width: 84px;
  height: 16px;
  left: 48px;
  top: 18px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
  padding-left: 48px;
  margin-top: -18px !important;
}

.alignedRow-relationitem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-person-inside {
  height: 16px;
  margin-left: 8px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
}

.createdAt {
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;
  margin-bottom: 0 !important;

  color: #939597;
}

.add-person {
  padding: 12px 56px 24px 24px;
}

.add-persons {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}

.add-persons-button {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}

.add-field {
  margin-bottom: 0 !important;
  width: 100% !important;
}

.add-notes {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter, sans-serif;
  border-radius: 8px;
  height: 40px;
  justify-content: space-between;
}

.add-note-inside {
  width: 73px;
  height: 16px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}

.text-area {
  height: fit-content !important;
}

.delete {
  background-color: #ff1e24;
  color: white;
}

.theme--light.v-input--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border: 1px solid #e6e8ec;
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 12px 12px 12px;
  background-color: white;
  border-radius: 8px;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
  }
}

.pipeline-header {
  margin-top: 48px;
  width: 30%;

  p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
}

.inputFields {
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 1.2;
  gap: 8px 24px;
  display: flex;
  flex-flow: row wrap;
}

.stageSelector {
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 1.2;
  width: 100%;
  display: flex;
}

.hover-open-delete {
  background-image: url("../../assets/images/trash-red.svg");
}

.stageSelector > input {
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.stageSelector > * {
  margin-right: 16px;
}

.arrowLabel {
  width: 173px;
  cursor: pointer;
  height: 40px;
  display: flex;
  position: relative;
  font-size: 12px;
  background: #e6e8ec;
  align-items: center;
  justify-content: center;
}

.stageSelector > input:checked + label:before {
  border-left-color: #75787a;
}

.arrowLabel:before {
  right: -10px;
  z-index: 2;
}

.arrowLabel:before {
  width: 0;
  bottom: 0;
  height: 0;
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-left: 10px solid #e6e8ec;
  border-bottom: 20px solid transparent;
}

.stageSelector > input:checked + label {
  color: #ffffff;
  background: #75787a;
}

.panel-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  width: 640px;
  z-index: 102;
  min-width: 640px;
  max-width: 640px;
  overflow: hidden;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

#panel-wrapper-customer {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}

#wrapped-header-customer {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;
  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}

.row {
  gap: 16px;
}

.overlay-detailpanel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 101;
}

.top-row {
  width: 100%;
  justify-content: flex-end;
  padding: 12px;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 100%;

  border: 1px solid #e6e8ec;
  margin-bottom: 24px;
  margin-top: 16px;
  left: 0;
  right: 0;
}

.main-wrapper {
  border-top: 1px solid #e6e8ec;
}

.sub-block {
  position: relative;
  padding: 0;
}

.sub-block-wrapper {
  padding: 12px;
}

.content-block {
  padding: 0 56px 32px 32px;
}

.content-item-item {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  text-align: right;
  color: #000000;
}

.content-items {
  justify-content: space-between;
}

.no-active {
  height: 40px;
  max-width: 65%;
}

.textarea {
  width: 100%;
  height: 110px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}

.no-active.v-input.v-input__slot {
  border-radius: 20px;
  border: 1px solid #e6e8ec !important;
}

.v-text-field--outlined > fieldset {
  border: 1px solid #e6e8ec;
}

.probability {
  padding-right: 20%;
}

.customer-row {
  border-top: 1px solid #e6e8ec;
  border-radius: 8px;
  margin-bottom: 12px;
  padding-left: 32px;
  padding-right: 24px;
  padding-top: 48px;
  background-color: #ffffff;
}

.sub-heading {
  padding: 32px 56px 24px 32px;
}

.content-item-row {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a;
}

.vertical-line {
  background-color: #e6e8ec;
  width: 1px;
  height: 16px;
}

.description {
  border: none !important;
  outline: none !important;
  width: 100%;
  resize: none;
}

.customer-field-row {
  gap: 8px !important;
}

.top16 {
  margin-top: 16px;
}

.top24 {
  margin-top: 24px;
}

.object-link:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.save {
  background-color: #0aaf60;
  color: white;
  border-color: #e6e8ec;
}

.related-deals {
  font-size: 14px !important;
  line-height: 16px;
  padding-left: 0;
  padding-right: 56px;
  padding-bottom: 16px;
  max-width: fit-content;

  /*  &:hover {
    text-decoration: underline;
  } */

  cursor: pointer;
}
.related-project-name {
  &:hover {
    text-decoration: underline;
  }
}

.contact-type {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
}

.related-person-info {
  text-align: right;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.name-wrapper {
  min-width: 45%;
  max-width: 45%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.right-wrapper {
  display: flex;
  min-width: 70%;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}

.step-wrapper {
  text-align: right;
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0;

  img {
    cursor: pointer;
  }
}

.steps-menu-row {
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #e6e8ec;
  }
}

.date-wrapper {
  min-width: fit-content;
  margin-left: 24px;
  text-align: right;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex: 1;
  gap: 12px;

  img {
    height: 100%;
    width: auto;
    cursor: pointer;
  }
}

.fit {
  min-width: 100%;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.field-name {
  color: #75787a;
}
.field-name {
  width: 208px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.field-value {
  line-height: 20px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.main-info {
  padding-left: 32px;
}

.point-separator {
  margin-left: 8px;
  margin-right: 8px;
}

.add-tasks {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}

.add-task-inside {
  height: 16px;
  margin-left: 8px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}

.outputs-modal {
  background-color: white;
}

.separator-row-main {
  border: 0.01px solid #e6e8ec;
}

.tag-name {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding: 4px 2.5px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.tag-container {
  width: 20px;
  height: 20px;
  background-color: #b5e7cf;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
}

.white-tag-text {
  color: white;
}

.note-header-content {
  padding: 4px 8px;
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;
  border: 1px solid #e6e8ec;
  height: 28px;
  border-radius: 8px;
  cursor: pointer;
}

.note-header-content p {
  font-size: 12px;
  font-family: Inter, sans-serif;
}
.related-project-info {
  background-color: #f4f5f7;
  height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 2px 4px;
}
.red-border {
  border: 2px solid #ffb4b6;
}
.green-border {
  border: 2px solid #b5e7cf;
}
.regular-border {
  border: 1px solid #e6e8ec;
}
.related-project-container {
  width: 552px;
  height: 84px;
  border-radius: 8px;
  padding: 16px;
}
.promote-button {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 8px;
  gap: 8px;
  height: 28px;
  border: 1px solid #e6e8ec;
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 0;
  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }
}
.promote-button-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;
}
.animated-button {
  border: 1px solid $grey500;
  border-radius: 6px;
  height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  font-size: 12px;
  &:hover {
    color: #ffffff;
    background-color: #000000;
    border: 1px solid #000000;
  }
}

.animated-button span {
  max-width: 0;
  -webkit-transition: max-width 0.1s;
  transition: max-width 0.1s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
}

.animated-button:hover span {
  padding: 0px 2px;
  max-width: 7rem;
}

.animated-button:hover p {
  color: #ffffff;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
