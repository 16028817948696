export const locations = {
  counties: [
    {
      TASE: "1",
      KOOD: "37",
      NIMETUS: "Harju maakond",
      NIMETUS_LIIGIGA: "Harju maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK00000037",
    },
    {
      TASE: "1",
      KOOD: "39",
      NIMETUS: "Hiiu maakond",
      NIMETUS_LIIGIGA: "Hiiu maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK00000039",
    },
    {
      TASE: "1",
      KOOD: "74",
      NIMETUS: "Saare maakond",
      NIMETUS_LIIGIGA: "Saare maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK00000074",
    },
    {
      TASE: "1",
      KOOD: "84",
      NIMETUS: "Viljandi maakond",
      NIMETUS_LIIGIGA: "Viljandi maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK00000084",
    },
    {
      TASE: "1",
      KOOD: "45",
      NIMETUS: "Ida-Viru maakond",
      NIMETUS_LIIGIGA: "Ida-Viru maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK03656568",
    },
    {
      TASE: "1",
      KOOD: "56",
      NIMETUS: "Lääne maakond",
      NIMETUS_LIIGIGA: "Lääne maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK03657055",
    },
    {
      TASE: "1",
      KOOD: "52",
      NIMETUS: "Järva maakond",
      NIMETUS_LIIGIGA: "Järva maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK03656437",
    },
    {
      TASE: "1",
      KOOD: "60",
      NIMETUS: "Lääne-Viru maakond",
      NIMETUS_LIIGIGA: "Lääne-Viru maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK03657545",
    },
    {
      TASE: "1",
      KOOD: "87",
      NIMETUS: "Võru maakond",
      NIMETUS_LIIGIGA: "Võru maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK03659384",
    },
    {
      TASE: "1",
      KOOD: "50",
      NIMETUS: "Jõgeva maakond",
      NIMETUS_LIIGIGA: "Jõgeva maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK03659957",
    },
    {
      TASE: "1",
      KOOD: "64",
      NIMETUS: "Põlva maakond",
      NIMETUS_LIIGIGA: "Põlva maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK03659213",
    },
    {
      TASE: "1",
      KOOD: "81",
      NIMETUS: "Valga maakond",
      NIMETUS_LIIGIGA: "Valga maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK03658658",
    },
    {
      TASE: "1",
      KOOD: "79",
      NIMETUS: "Tartu maakond",
      NIMETUS_LIIGIGA: "Tartu maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK03658125",
    },
    {
      TASE: "1",
      KOOD: "71",
      NIMETUS: "Rapla maakond",
      NIMETUS_LIIGIGA: "Rapla maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK03659248",
    },
    {
      TASE: "1",
      KOOD: "68",
      NIMETUS: "Pärnu maakond",
      NIMETUS_LIIGIGA: "Pärnu maakond",
      YLEMKOMP_TASE: "0",
      YLEMKOMP_KOOD: "0",
      ADS_OID: "MK03658462",
    },
  ],
  cities: [
    {
      TASE: "2",
      KOOD: "198",
      NIMETUS: "Harku vald",
      NIMETUS_LIIGIGA: "Harku vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV00000198",
    },
    {
      TASE: "2",
      KOOD: "245",
      NIMETUS: "Jõelähtme vald",
      NIMETUS_LIIGIGA: "Jõelähtme vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV00000245",
    },
    {
      TASE: "2",
      KOOD: "251",
      NIMETUS: "Jõhvi vald",
      NIMETUS_LIIGIGA: "Jõhvi vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "45",
      ADS_OID: "OV00000251",
    },
    {
      TASE: "2",
      KOOD: "272",
      NIMETUS: "Kadrina vald",
      NIMETUS_LIIGIGA: "Kadrina vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "60",
      ADS_OID: "OV00000272",
    },
    {
      TASE: "2",
      KOOD: "296",
      NIMETUS: "Keila linn",
      NIMETUS_LIIGIGA: "Keila linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV00000296",
    },
    {
      TASE: "2",
      KOOD: "303",
      NIMETUS: "Kihnu vald",
      NIMETUS_LIIGIGA: "Kihnu vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "68",
      ADS_OID: "OV00000303",
    },
    {
      TASE: "2",
      KOOD: "317",
      NIMETUS: "Kohila vald",
      NIMETUS_LIIGIGA: "Kohila vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "71",
      ADS_OID: "OV00000317",
    },
    {
      TASE: "2",
      KOOD: "353",
      NIMETUS: "Kuusalu vald",
      NIMETUS_LIIGIGA: "Kuusalu vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV00000353",
    },
    {
      TASE: "2",
      KOOD: "424",
      NIMETUS: "Loksa linn",
      NIMETUS_LIIGIGA: "Loksa linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV00000424",
    },
    {
      TASE: "2",
      KOOD: "432",
      NIMETUS: "Luunja vald",
      NIMETUS_LIIGIGA: "Luunja vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "79",
      ADS_OID: "OV00000432",
    },
    {
      TASE: "2",
      KOOD: "446",
      NIMETUS: "Maardu linn",
      NIMETUS_LIIGIGA: "Maardu linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV00000446",
    },
    {
      TASE: "2",
      KOOD: "478",
      NIMETUS: "Muhu vald",
      NIMETUS_LIIGIGA: "Muhu vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "74",
      ADS_OID: "OV00000478",
    },
    {
      TASE: "2",
      KOOD: "511",
      NIMETUS: "Narva linn",
      NIMETUS_LIIGIGA: "Narva linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "45",
      ADS_OID: "OV00000511",
    },
    {
      TASE: "2",
      KOOD: "528",
      NIMETUS: "Nõo vald",
      NIMETUS_LIIGIGA: "Nõo vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "79",
      ADS_OID: "OV00000528",
    },
    {
      TASE: "2",
      KOOD: "651",
      NIMETUS: "Raasiku vald",
      NIMETUS_LIIGIGA: "Raasiku vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV00000651",
    },
    {
      TASE: "2",
      KOOD: "653",
      NIMETUS: "Rae vald",
      NIMETUS_LIIGIGA: "Rae vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV00000653",
    },
    {
      TASE: "2",
      KOOD: "663",
      NIMETUS: "Rakvere linn",
      NIMETUS_LIIGIGA: "Rakvere linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "60",
      ADS_OID: "OV00000663",
    },
    {
      TASE: "2",
      KOOD: "689",
      NIMETUS: "Ruhnu vald",
      NIMETUS_LIIGIGA: "Ruhnu vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "74",
      ADS_OID: "OV00000689",
    },
    {
      TASE: "2",
      KOOD: "735",
      NIMETUS: "Sillamäe linn",
      NIMETUS_LIIGIGA: "Sillamäe linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "45",
      ADS_OID: "OV00000735",
    },
    {
      TASE: "2",
      KOOD: "784",
      NIMETUS: "Tallinn",
      NIMETUS_LIIGIGA: "Tallinn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV00000784",
    },
    {
      TASE: "2",
      KOOD: "890",
      NIMETUS: "Viimsi vald",
      NIMETUS_LIIGIGA: "Viimsi vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV00000890",
    },
    {
      TASE: "2",
      KOOD: "897",
      NIMETUS: "Viljandi linn",
      NIMETUS_LIIGIGA: "Viljandi linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "84",
      ADS_OID: "OV00000897",
    },
    {
      TASE: "2",
      KOOD: "907",
      NIMETUS: "Vormsi vald",
      NIMETUS_LIIGIGA: "Vormsi vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "56",
      ADS_OID: "OV00000907",
    },
    {
      TASE: "2",
      KOOD: "919",
      NIMETUS: "Võru linn",
      NIMETUS_LIIGIGA: "Võru linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "87",
      ADS_OID: "OV00000919",
    },
    {
      TASE: "2",
      KOOD: "338",
      NIMETUS: "Kose vald",
      NIMETUS_LIIGIGA: "Kose vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV03290434",
    },
    {
      TASE: "2",
      KOOD: "321",
      NIMETUS: "Kohtla-Järve linn",
      NIMETUS_LIIGIGA: "Kohtla-Järve linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "45",
      ADS_OID: "OV03657750",
    },
    {
      TASE: "2",
      KOOD: "514",
      NIMETUS: "Narva-Jõesuu linn",
      NIMETUS_LIIGIGA: "Narva-Jõesuu linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "45",
      ADS_OID: "OV03657753",
    },
    {
      TASE: "2",
      KOOD: "792",
      NIMETUS: "Tapa vald",
      NIMETUS_LIIGIGA: "Tapa vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "60",
      ADS_OID: "OV03657735",
    },
    {
      TASE: "2",
      KOOD: "191",
      NIMETUS: "Haljala vald",
      NIMETUS_LIIGIGA: "Haljala vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "60",
      ADS_OID: "OV03658019",
    },
    {
      TASE: "2",
      KOOD: "903",
      NIMETUS: "Viru-Nigula vald",
      NIMETUS_LIIGIGA: "Viru-Nigula vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "60",
      ADS_OID: "OV03658030",
    },
    {
      TASE: "2",
      KOOD: "431",
      NIMETUS: "Lääne-Harju vald",
      NIMETUS_LIIGIGA: "Lääne-Harju vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV03657496",
    },
    {
      TASE: "2",
      KOOD: "714",
      NIMETUS: "Saaremaa vald",
      NIMETUS_LIIGIGA: "Saaremaa vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "74",
      ADS_OID: "OV03656820",
    },
    {
      TASE: "2",
      KOOD: "803",
      NIMETUS: "Toila vald",
      NIMETUS_LIIGIGA: "Toila vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "45",
      ADS_OID: "OV03656569",
    },
    {
      TASE: "2",
      KOOD: "567",
      NIMETUS: "Paide linn",
      NIMETUS_LIIGIGA: "Paide linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "52",
      ADS_OID: "OV03657053",
    },
    {
      TASE: "2",
      KOOD: "184",
      NIMETUS: "Haapsalu linn",
      NIMETUS_LIIGIGA: "Haapsalu linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "56",
      ADS_OID: "OV03657056",
    },
    {
      TASE: "2",
      KOOD: "834",
      NIMETUS: "Türi vald",
      NIMETUS_LIIGIGA: "Türi vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "52",
      ADS_OID: "OV03656438",
    },
    {
      TASE: "2",
      KOOD: "661",
      NIMETUS: "Rakvere vald",
      NIMETUS_LIIGIGA: "Rakvere vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "60",
      ADS_OID: "OV03657546",
    },
    {
      TASE: "2",
      KOOD: "255",
      NIMETUS: "Järva vald",
      NIMETUS_LIIGIGA: "Järva vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "52",
      ADS_OID: "OV03656484",
    },
    {
      TASE: "2",
      KOOD: "141",
      NIMETUS: "Anija vald",
      NIMETUS_LIIGIGA: "Anija vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV03656482",
    },
    {
      TASE: "2",
      KOOD: "442",
      NIMETUS: "Lüganuse vald",
      NIMETUS_LIIGIGA: "Lüganuse vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "45",
      ADS_OID: "OV03659388",
    },
    {
      TASE: "2",
      KOOD: "142",
      NIMETUS: "Antsla vald",
      NIMETUS_LIIGIGA: "Antsla vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "87",
      ADS_OID: "OV03659385",
    },
    {
      TASE: "2",
      KOOD: "917",
      NIMETUS: "Võru vald",
      NIMETUS_LIIGIGA: "Võru vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "87",
      ADS_OID: "OV03659386",
    },
    {
      TASE: "2",
      KOOD: "130",
      NIMETUS: "Alutaguse vald",
      NIMETUS_LIIGIGA: "Alutaguse vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "45",
      ADS_OID: "OV03659390",
    },
    {
      TASE: "2",
      KOOD: "480",
      NIMETUS: "Mulgi vald",
      NIMETUS_LIIGIGA: "Mulgi vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "84",
      ADS_OID: "OV03659392",
    },
    {
      TASE: "2",
      KOOD: "615",
      NIMETUS: "Põhja-Sakala vald",
      NIMETUS_LIIGIGA: "Põhja-Sakala vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "84",
      ADS_OID: "OV03659394",
    },
    {
      TASE: "2",
      KOOD: "928",
      NIMETUS: "Väike-Maarja vald",
      NIMETUS_LIIGIGA: "Väike-Maarja vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "60",
      ADS_OID: "OV03659391",
    },
    {
      TASE: "2",
      KOOD: "486",
      NIMETUS: "Mustvee vald",
      NIMETUS_LIIGIGA: "Mustvee vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "50",
      ADS_OID: "OV03659958",
    },
    {
      TASE: "2",
      KOOD: "284",
      NIMETUS: "Kanepi vald",
      NIMETUS_LIIGIGA: "Kanepi vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "64",
      ADS_OID: "OV03659214",
    },
    {
      TASE: "2",
      KOOD: "899",
      NIMETUS: "Viljandi vald",
      NIMETUS_LIIGIGA: "Viljandi vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "84",
      ADS_OID: "OV03659215",
    },
    {
      TASE: "2",
      KOOD: "855",
      NIMETUS: "Valga vald",
      NIMETUS_LIIGIGA: "Valga vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "81",
      ADS_OID: "OV03658659",
    },
    {
      TASE: "2",
      KOOD: "824",
      NIMETUS: "Tõrva vald",
      NIMETUS_LIIGIGA: "Tõrva vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "81",
      ADS_OID: "OV03658675",
    },
    {
      TASE: "2",
      KOOD: "283",
      NIMETUS: "Kambja vald",
      NIMETUS_LIIGIGA: "Kambja vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "79",
      ADS_OID: "OV03658126",
    },
    {
      TASE: "2",
      KOOD: "796",
      NIMETUS: "Tartu vald",
      NIMETUS_LIIGIGA: "Tartu vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "79",
      ADS_OID: "OV03658132",
    },
    {
      TASE: "2",
      KOOD: "624",
      NIMETUS: "Pärnu linn",
      NIMETUS_LIIGIGA: "Pärnu linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "68",
      ADS_OID: "OV03658876",
    },
    {
      TASE: "2",
      KOOD: "793",
      NIMETUS: "Tartu linn",
      NIMETUS_LIIGIGA: "Tartu linn",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "79",
      ADS_OID: "OV03658880",
    },
    {
      TASE: "2",
      KOOD: "291",
      NIMETUS: "Kastre vald",
      NIMETUS_LIIGIGA: "Kastre vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "79",
      ADS_OID: "OV03659301",
    },
    {
      TASE: "2",
      KOOD: "205",
      NIMETUS: "Hiiumaa vald",
      NIMETUS_LIIGIGA: "Hiiumaa vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "39",
      ADS_OID: "OV03658644",
    },
    {
      TASE: "2",
      KOOD: "901",
      NIMETUS: "Vinni vald",
      NIMETUS_LIIGIGA: "Vinni vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "60",
      ADS_OID: "OV03658124",
    },
    {
      TASE: "2",
      KOOD: "293",
      NIMETUS: "Kehtna vald",
      NIMETUS_LIIGIGA: "Kehtna vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "71",
      ADS_OID: "OV03659249",
    },
    {
      TASE: "2",
      KOOD: "668",
      NIMETUS: "Rapla vald",
      NIMETUS_LIIGIGA: "Rapla vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "71",
      ADS_OID: "OV03659281",
    },
    {
      TASE: "2",
      KOOD: "441",
      NIMETUS: "Lääne-Nigula vald",
      NIMETUS_LIIGIGA: "Lääne-Nigula vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "56",
      ADS_OID: "OV03658549",
    },
    {
      TASE: "2",
      KOOD: "557",
      NIMETUS: "Otepää vald",
      NIMETUS_LIIGIGA: "Otepää vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "81",
      ADS_OID: "OV03658717",
    },
    {
      TASE: "2",
      KOOD: "712",
      NIMETUS: "Saarde vald",
      NIMETUS_LIIGIGA: "Saarde vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "68",
      ADS_OID: "OV03658463",
    },
    {
      TASE: "2",
      KOOD: "214",
      NIMETUS: "Häädemeeste vald",
      NIMETUS_LIIGIGA: "Häädemeeste vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "68",
      ADS_OID: "OV03658465",
    },
    {
      TASE: "2",
      KOOD: "430",
      NIMETUS: "Lääneranna vald",
      NIMETUS_LIIGIGA: "Lääneranna vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "68",
      ADS_OID: "OV03658486",
    },
    {
      TASE: "2",
      KOOD: "638",
      NIMETUS: "Põhja-Pärnumaa vald",
      NIMETUS_LIIGIGA: "Põhja-Pärnumaa vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "68",
      ADS_OID: "OV03658473",
    },
    {
      TASE: "2",
      KOOD: "171",
      NIMETUS: "Elva vald",
      NIMETUS_LIIGIGA: "Elva vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "79",
      ADS_OID: "OV03658704",
    },
    {
      TASE: "2",
      KOOD: "586",
      NIMETUS: "Peipsiääre vald",
      NIMETUS_LIIGIGA: "Peipsiääre vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "79",
      ADS_OID: "OV03658150",
    },
    {
      TASE: "2",
      KOOD: "708",
      NIMETUS: "Räpina vald",
      NIMETUS_LIIGIGA: "Räpina vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "64",
      ADS_OID: "OV03659307",
    },
    {
      TASE: "2",
      KOOD: "618",
      NIMETUS: "Põltsamaa vald",
      NIMETUS_LIIGIGA: "Põltsamaa vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "50",
      ADS_OID: "OV03659979",
    },
    {
      TASE: "2",
      KOOD: "698",
      NIMETUS: "Rõuge vald",
      NIMETUS_LIIGIGA: "Rõuge vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "87",
      ADS_OID: "OV03660053",
    },
    {
      TASE: "2",
      KOOD: "622",
      NIMETUS: "Põlva vald",
      NIMETUS_LIIGIGA: "Põlva vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "64",
      ADS_OID: "OV03659305",
    },
    {
      TASE: "2",
      KOOD: "732",
      NIMETUS: "Setomaa vald",
      NIMETUS_LIIGIGA: "Setomaa vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "87",
      ADS_OID: "OV03660019",
    },
    {
      TASE: "2",
      KOOD: "247",
      NIMETUS: "Jõgeva vald",
      NIMETUS_LIIGIGA: "Jõgeva vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "50",
      ADS_OID: "OV03659973",
    },
    {
      TASE: "2",
      KOOD: "809",
      NIMETUS: "Tori vald",
      NIMETUS_LIIGIGA: "Tori vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "68",
      ADS_OID: "OV03660897",
    },
    {
      TASE: "2",
      KOOD: "305",
      NIMETUS: "Kiili vald",
      NIMETUS_LIIGIGA: "Kiili vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV03779929",
    },
    {
      TASE: "2",
      KOOD: "719",
      NIMETUS: "Saku vald",
      NIMETUS_LIIGIGA: "Saku vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV03779930",
    },
    {
      TASE: "2",
      KOOD: "502",
      NIMETUS: "Märjamaa vald",
      NIMETUS_LIIGIGA: "Märjamaa vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "71",
      ADS_OID: "OV03888643",
    },
    {
      TASE: "2",
      KOOD: "725",
      NIMETUS: "Saue vald",
      NIMETUS_LIIGIGA: "Saue vald",
      YLEMKOMP_TASE: "1",
      YLEMKOMP_KOOD: "37",
      ADS_OID: "OV03888644",
    },
  ],
  districts: [
    {
      TASE: "3",
      KOOD: "EE3_52827",
      NIMETUS: "Kantreküla",
      NIMETUS_LIIGIGA: "Kantreküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "897",
      ADS_OID: "EE3_52827",
    },
    {
      TASE: "3",
      KOOD: "EE3_52828",
      NIMETUS: "Kesklinn",
      NIMETUS_LIIGIGA: "Kesklinn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "897",
      ADS_OID: "EE3_52828",
    },
    {
      TASE: "3",
      KOOD: "EE3_52829",
      NIMETUS: "Männimäe",
      NIMETUS_LIIGIGA: "Männimäe",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "897",
      ADS_OID: "EE3_52829",
    },
    {
      TASE: "3",
      KOOD: "EE3_52830",
      NIMETUS: "Paalalinn",
      NIMETUS_LIIGIGA: "Paalalinn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "897",
      ADS_OID: "EE3_52830",
    },
    {
      TASE: "3",
      KOOD: "EE3_52831",
      NIMETUS: "Peetrimõisa",
      NIMETUS_LIIGIGA: "Peetrimõisa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "897",
      ADS_OID: "EE3_52831",
    },
    {
      TASE: "3",
      KOOD: "EE3_52832",
      NIMETUS: "Uueveski",
      NIMETUS_LIIGIGA: "Uueveski",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "897",
      ADS_OID: "EE3_52832",
    },
    {
      TASE: "3",
      KOOD: "EE3_52833",
      NIMETUS: "Vanalinn",
      NIMETUS_LIIGIGA: "Vanalinn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "897",
      ADS_OID: "EE3_52833",
    },
    {
      TASE: "3",
      KOOD: "EE3_52834",
      NIMETUS: "Järveotsa",
      NIMETUS_LIIGIGA: "Järveotsa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "897",
      ADS_OID: "EE3_52834",
    },
    {
      TASE: "3",
      KOOD: "EE3_52795",
      NIMETUS: "Parussinka",
      NIMETUS_LIIGIGA: "Parussinka",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52795",
    },
    {
      TASE: "3",
      KOOD: "EE3_52796",
      NIMETUS: "Veekulgu",
      NIMETUS_LIIGIGA: "Veekulgu",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52796",
    },
    {
      TASE: "3",
      KOOD: "EE3_52782",
      NIMETUS: "Elektrijaama",
      NIMETUS_LIIGIGA: "Elektrijaama",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52782",
    },
    {
      TASE: "3",
      KOOD: "EE3_52783",
      NIMETUS: "Joaorg",
      NIMETUS_LIIGIGA: "Joaorg",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52783",
    },
    {
      TASE: "3",
      KOOD: "EE3_52784",
      NIMETUS: "Kalevi",
      NIMETUS_LIIGIGA: "Kalevi",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52784",
    },
    {
      TASE: "3",
      KOOD: "EE3_52785",
      NIMETUS: "Kerese",
      NIMETUS_LIIGIGA: "Kerese",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52785",
    },
    {
      TASE: "3",
      KOOD: "EE3_52786",
      NIMETUS: "Kesklinn",
      NIMETUS_LIIGIGA: "Kesklinn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52786",
    },
    {
      TASE: "3",
      KOOD: "EE3_52787",
      NIMETUS: "Kreenholmi",
      NIMETUS_LIIGIGA: "Kreenholmi",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52787",
    },
    {
      TASE: "3",
      KOOD: "EE3_52788",
      NIMETUS: "Kulgu",
      NIMETUS_LIIGIGA: "Kulgu",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52788",
    },
    {
      TASE: "3",
      KOOD: "EE3_52789",
      NIMETUS: "Paemurru",
      NIMETUS_LIIGIGA: "Paemurru",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52789",
    },
    {
      TASE: "3",
      KOOD: "EE3_52790",
      NIMETUS: "Pähklimäe",
      NIMETUS_LIIGIGA: "Pähklimäe",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52790",
    },
    {
      TASE: "3",
      KOOD: "EE3_52791",
      NIMETUS: "Siivertsi",
      NIMETUS_LIIGIGA: "Siivertsi",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52791",
    },
    {
      TASE: "3",
      KOOD: "EE3_52792",
      NIMETUS: "Soldino",
      NIMETUS_LIIGIGA: "Soldino",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52792",
    },
    {
      TASE: "3",
      KOOD: "EE3_52793",
      NIMETUS: "Sutthoffi",
      NIMETUS_LIIGIGA: "Sutthoffi",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52793",
    },
    {
      TASE: "3",
      KOOD: "EE3_52794",
      NIMETUS: "Vanalinn",
      NIMETUS_LIIGIGA: "Vanalinn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "511",
      ADS_OID: "EE3_52794",
    },
    {
      TASE: "3",
      KOOD: "EE3_52808",
      NIMETUS: "Papiniidu",
      NIMETUS_LIIGIGA: "Papiniidu",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "EE3_52808",
    },
    {
      TASE: "3",
      KOOD: "EE3_52797",
      NIMETUS: "Eeslinn",
      NIMETUS_LIIGIGA: "Eeslinn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "EE3_52797",
    },
    {
      TASE: "3",
      KOOD: "EE3_52798",
      NIMETUS: "Kesklinn",
      NIMETUS_LIIGIGA: "Kesklinn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "EE3_52798",
    },
    {
      TASE: "3",
      KOOD: "EE3_52799",
      NIMETUS: "Lodja",
      NIMETUS_LIIGIGA: "Lodja",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "EE3_52799",
    },
    {
      TASE: "3",
      KOOD: "EE3_52800",
      NIMETUS: "Mai",
      NIMETUS_LIIGIGA: "Mai",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "EE3_52800",
    },
    {
      TASE: "3",
      KOOD: "EE3_52801",
      NIMETUS: "Rääma",
      NIMETUS_LIIGIGA: "Rääma",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "EE3_52801",
    },
    {
      TASE: "3",
      KOOD: "EE3_52802",
      NIMETUS: "Raeküla",
      NIMETUS_LIIGIGA: "Raeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "EE3_52802",
    },
    {
      TASE: "3",
      KOOD: "EE3_52803",
      NIMETUS: "Rannarajoon",
      NIMETUS_LIIGIGA: "Rannarajoon",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "EE3_52803",
    },
    {
      TASE: "3",
      KOOD: "EE3_52804",
      NIMETUS: "Tammiste",
      NIMETUS_LIIGIGA: "Tammiste",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "EE3_52804",
    },
    {
      TASE: "3",
      KOOD: "EE3_52805",
      NIMETUS: "Ülejõe",
      NIMETUS_LIIGIGA: "Ülejõe",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "EE3_52805",
    },
    {
      TASE: "3",
      KOOD: "EE3_52806",
      NIMETUS: "Vana-Pärnu",
      NIMETUS_LIIGIGA: "Vana-Pärnu",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "EE3_52806",
    },
    {
      TASE: "3",
      KOOD: "EE3_52807",
      NIMETUS: "Niidu",
      NIMETUS_LIIGIGA: "Niidu",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "EE3_52807",
    },

    {
      TASE: "3",
      KOOD: "3600",
      NIMETUS: "Kullimaa küla",
      NIMETUS_LIIGIGA: "Kullimaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00003600",
    },
    {
      TASE: "3",
      KOOD: "3601",
      NIMETUS: "Lümanda-Kulli küla",
      NIMETUS_LIIGIGA: "Lümanda-Kulli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003601",
    },
    {
      TASE: "3",
      KOOD: "3603",
      NIMETUS: "Kulna küla",
      NIMETUS_LIIGIGA: "Kulna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00003603",
    },
    {
      TASE: "3",
      KOOD: "3605",
      NIMETUS: "Kuluse küla",
      NIMETUS_LIIGIGA: "Kuluse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00003605",
    },
    {
      TASE: "3",
      KOOD: "3606",
      NIMETUS: "Kumma küla",
      NIMETUS_LIIGIGA: "Kumma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00003606",
    },
    {
      TASE: "3",
      KOOD: "3608",
      NIMETUS: "Kumna küla",
      NIMETUS_LIIGIGA: "Kumna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00003608",
    },
    {
      TASE: "3",
      KOOD: "3609",
      NIMETUS: "Kundruse küla",
      NIMETUS_LIIGIGA: "Kundruse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003609",
    },
    {
      TASE: "3",
      KOOD: "3610",
      NIMETUS: "Kunda küla",
      NIMETUS_LIIGIGA: "Kunda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00003610",
    },
    {
      TASE: "3",
      KOOD: "3611",
      NIMETUS: "Kungi küla",
      NIMETUS_LIIGIGA: "Kungi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00003611",
    },
    {
      TASE: "3",
      KOOD: "3614",
      NIMETUS: "Kungla küla",
      NIMETUS_LIIGIGA: "Kungla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003614",
    },
    {
      TASE: "3",
      KOOD: "3616",
      NIMETUS: "Kunila küla",
      NIMETUS_LIIGIGA: "Kunila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003616",
    },
    {
      TASE: "3",
      KOOD: "3617",
      NIMETUS: "Kuninga küla",
      NIMETUS_LIIGIGA: "Kuninga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00003617",
    },
    {
      TASE: "3",
      KOOD: "3619",
      NIMETUS: "Kuninga küla",
      NIMETUS_LIIGIGA: "Kuninga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00003619",
    },
    {
      TASE: "3",
      KOOD: "3621",
      NIMETUS: "Kuningaküla",
      NIMETUS_LIIGIGA: "Kuningaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00003621",
    },
    {
      TASE: "3",
      KOOD: "3624",
      NIMETUS: "Kuningamäe küla",
      NIMETUS_LIIGIGA: "Kuningamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00003624",
    },
    {
      TASE: "3",
      KOOD: "3625",
      NIMETUS: "Kuninguste küla",
      NIMETUS_LIIGIGA: "Kuninguste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003625",
    },
    {
      TASE: "3",
      KOOD: "3626",
      NIMETUS: "Kuningvere küla",
      NIMETUS_LIIGIGA: "Kuningvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00003626",
    },
    {
      TASE: "3",
      KOOD: "3627",
      NIMETUS: "Kunsu küla",
      NIMETUS_LIIGIGA: "Kunsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003627",
    },
    {
      TASE: "3",
      KOOD: "3629",
      NIMETUS: "Kunksilla küla",
      NIMETUS_LIIGIGA: "Kunksilla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00003629",
    },
    {
      TASE: "3",
      KOOD: "3630",
      NIMETUS: "Kupu küla",
      NIMETUS_LIIGIGA: "Kupu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003630",
    },
    {
      TASE: "3",
      KOOD: "3632",
      NIMETUS: "Kuralase küla",
      NIMETUS_LIIGIGA: "Kuralase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003632",
    },
    {
      TASE: "3",
      KOOD: "3635",
      NIMETUS: "Kurõ küla",
      NIMETUS_LIIGIGA: "Kurõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003635",
    },
    {
      TASE: "3",
      KOOD: "3637",
      NIMETUS: "Kureküla alevik",
      NIMETUS_LIIGIGA: "Kureküla alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00003637",
    },
    {
      TASE: "3",
      KOOD: "3639",
      NIMETUS: "Kurelaane küla",
      NIMETUS_LIIGIGA: "Kurelaane küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00003639",
    },
    {
      TASE: "3",
      KOOD: "3642",
      NIMETUS: "Kuremaa alevik",
      NIMETUS_LIIGIGA: "Kuremaa alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00003642",
    },
    {
      TASE: "3",
      KOOD: "3643",
      NIMETUS: "Kuremetsa küla",
      NIMETUS_LIIGIGA: "Kuremetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003643",
    },
    {
      TASE: "3",
      KOOD: "3644",
      NIMETUS: "Kuremäe küla",
      NIMETUS_LIIGIGA: "Kuremäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00003644",
    },
    {
      TASE: "3",
      KOOD: "3649",
      NIMETUS: "Kurenurme küla",
      NIMETUS_LIIGIGA: "Kurenurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003649",
    },
    {
      TASE: "3",
      KOOD: "3652",
      NIMETUS: "Kurepalu küla",
      NIMETUS_LIIGIGA: "Kurepalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00003652",
    },
    {
      TASE: "3",
      KOOD: "3654",
      NIMETUS: "Kurese küla",
      NIMETUS_LIIGIGA: "Kurese küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003654",
    },
    {
      TASE: "3",
      KOOD: "3658",
      NIMETUS: "Kuressaare küla",
      NIMETUS_LIIGIGA: "Kuressaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00003658",
    },
    {
      TASE: "3",
      KOOD: "3661",
      NIMETUS: "Kurevere küla",
      NIMETUS_LIIGIGA: "Kurevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003661",
    },
    {
      TASE: "3",
      KOOD: "3662",
      NIMETUS: "Kurevere küla",
      NIMETUS_LIIGIGA: "Kurevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003662",
    },
    {
      TASE: "3",
      KOOD: "3663",
      NIMETUS: "Kurevere küla",
      NIMETUS_LIIGIGA: "Kurevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00003663",
    },
    {
      TASE: "3",
      KOOD: "3664",
      NIMETUS: "Kurgjärve küla",
      NIMETUS_LIIGIGA: "Kurgjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003664",
    },
    {
      TASE: "3",
      KOOD: "3666",
      NIMETUS: "Kurgja küla",
      NIMETUS_LIIGIGA: "Kurgja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00003666",
    },
    {
      TASE: "3",
      KOOD: "3668",
      NIMETUS: "Kurgla küla",
      NIMETUS_LIIGIGA: "Kurgla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00003668",
    },
    {
      TASE: "3",
      KOOD: "3671",
      NIMETUS: "Kuri küla",
      NIMETUS_LIIGIGA: "Kuri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003671",
    },
    {
      TASE: "3",
      KOOD: "3673",
      NIMETUS: "Kurisoo küla",
      NIMETUS_LIIGIGA: "Kurisoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00003673",
    },
    {
      TASE: "3",
      KOOD: "3676",
      NIMETUS: "Kurista küla",
      NIMETUS_LIIGIGA: "Kurista küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00003676",
    },
    {
      TASE: "3",
      KOOD: "3677",
      NIMETUS: "Kurista küla",
      NIMETUS_LIIGIGA: "Kurista küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00003677",
    },
    {
      TASE: "3",
      KOOD: "3679",
      NIMETUS: "Kurisu küla",
      NIMETUS_LIIGIGA: "Kurisu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003679",
    },
    {
      TASE: "3",
      KOOD: "3680",
      NIMETUS: "Kuriste küla",
      NIMETUS_LIIGIGA: "Kuriste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003680",
    },
    {
      TASE: "3",
      KOOD: "3682",
      NIMETUS: "Kurkse küla",
      NIMETUS_LIIGIGA: "Kurkse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00003682",
    },
    {
      TASE: "3",
      KOOD: "3684",
      NIMETUS: "Kurla küla",
      NIMETUS_LIIGIGA: "Kurla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00003684",
    },
    {
      TASE: "3",
      KOOD: "3687",
      NIMETUS: "Kurna küla",
      NIMETUS_LIIGIGA: "Kurna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00003687",
    },
    {
      TASE: "3",
      KOOD: "3688",
      NIMETUS: "Kurna küla",
      NIMETUS_LIIGIGA: "Kurna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00003688",
    },
    {
      TASE: "3",
      KOOD: "3690",
      NIMETUS: "Kurnuvere küla",
      NIMETUS_LIIGIGA: "Kurnuvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00003690",
    },
    {
      TASE: "3",
      KOOD: "3691",
      NIMETUS: "Kursi küla",
      NIMETUS_LIIGIGA: "Kursi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003691",
    },
    {
      TASE: "3",
      KOOD: "3692",
      NIMETUS: "Kurtna küla",
      NIMETUS_LIIGIGA: "Kurtna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00003692",
    },
    {
      TASE: "3",
      KOOD: "3693",
      NIMETUS: "Kursi küla",
      NIMETUS_LIIGIGA: "Kursi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00003693",
    },
    {
      TASE: "3",
      KOOD: "3694",
      NIMETUS: "Kursi küla",
      NIMETUS_LIIGIGA: "Kursi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00003694",
    },
    {
      TASE: "3",
      KOOD: "3696",
      NIMETUS: "Kurtna küla",
      NIMETUS_LIIGIGA: "Kurtna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00003696",
    },
    {
      TASE: "3",
      KOOD: "3697",
      NIMETUS: "Kurtna küla",
      NIMETUS_LIIGIGA: "Kurtna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00003697",
    },
    {
      TASE: "3",
      KOOD: "3698",
      NIMETUS: "Kurvitsa küla",
      NIMETUS_LIIGIGA: "Kurvitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003698",
    },
    {
      TASE: "3",
      KOOD: "3700",
      NIMETUS: "Kuru küla",
      NIMETUS_LIIGIGA: "Kuru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00003700",
    },
    {
      TASE: "3",
      KOOD: "3701",
      NIMETUS: "Kusnetsova küla",
      NIMETUS_LIIGIGA: "Kusnetsova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003701",
    },
    {
      TASE: "3",
      KOOD: "3702",
      NIMETUS: "Kuru küla",
      NIMETUS_LIIGIGA: "Kuru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00003702",
    },
    {
      TASE: "3",
      KOOD: "3703",
      NIMETUS: "Kusma küla",
      NIMETUS_LIIGIGA: "Kusma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00003703",
    },
    {
      TASE: "3",
      KOOD: "3704",
      NIMETUS: "Kusma küla",
      NIMETUS_LIIGIGA: "Kusma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003704",
    },
    {
      TASE: "3",
      KOOD: "3705",
      NIMETUS: "Kustja küla",
      NIMETUS_LIIGIGA: "Kustja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00003705",
    },
    {
      TASE: "3",
      KOOD: "3706",
      NIMETUS: "Kuuda küla",
      NIMETUS_LIIGIGA: "Kuuda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003706",
    },
    {
      TASE: "3",
      KOOD: "3711",
      NIMETUS: "Kuudeküla",
      NIMETUS_LIIGIGA: "Kuudeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00003711",
    },
    {
      TASE: "3",
      KOOD: "3712",
      NIMETUS: "Kuumi küla",
      NIMETUS_LIIGIGA: "Kuumi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003712",
    },
    {
      TASE: "3",
      KOOD: "3713",
      NIMETUS: "Kuura küla",
      NIMETUS_LIIGIGA: "Kuura küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003713",
    },
    {
      TASE: "3",
      KOOD: "3714",
      NIMETUS: "Kuusalu alevik",
      NIMETUS_LIIGIGA: "Kuusalu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003714",
    },
    {
      TASE: "3",
      KOOD: "3715",
      NIMETUS: "Kuuse küla",
      NIMETUS_LIIGIGA: "Kuuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003715",
    },
    {
      TASE: "3",
      KOOD: "3716",
      NIMETUS: "Kuusemäe küla",
      NIMETUS_LIIGIGA: "Kuusemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00003716",
    },
    {
      TASE: "3",
      KOOD: "3717",
      NIMETUS: "Kuusiku küla",
      NIMETUS_LIIGIGA: "Kuusiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003717",
    },
    {
      TASE: "3",
      KOOD: "3718",
      NIMETUS: "Kuusalu küla",
      NIMETUS_LIIGIGA: "Kuusalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003718",
    },
    {
      TASE: "3",
      KOOD: "3719",
      NIMETUS: "Kuusiku küla",
      NIMETUS_LIIGIGA: "Kuusiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003719",
    },
    {
      TASE: "3",
      KOOD: "3720",
      NIMETUS: "Kuusiku alevik",
      NIMETUS_LIIGIGA: "Kuusiku alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00003720",
    },
    {
      TASE: "3",
      KOOD: "3721",
      NIMETUS: "Kuusiku küla",
      NIMETUS_LIIGIGA: "Kuusiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00003721",
    },
    {
      TASE: "3",
      KOOD: "3723",
      NIMETUS: "Kuusna küla",
      NIMETUS_LIIGIGA: "Kuusna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00003723",
    },
    {
      TASE: "3",
      KOOD: "3724",
      NIMETUS: "Kuusiku-Nõmme küla",
      NIMETUS_LIIGIGA: "Kuusiku-Nõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00003724",
    },
    {
      TASE: "3",
      KOOD: "3725",
      NIMETUS: "Kuura küla",
      NIMETUS_LIIGIGA: "Kuura küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00003725",
    },
    {
      TASE: "3",
      KOOD: "3726",
      NIMETUS: "Kuusnõmme küla",
      NIMETUS_LIIGIGA: "Kuusnõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003726",
    },
    {
      TASE: "3",
      KOOD: "3728",
      NIMETUS: "Kuutsi küla",
      NIMETUS_LIIGIGA: "Kuutsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003728",
    },
    {
      TASE: "3",
      KOOD: "3732",
      NIMETUS: "Kõdesi küla",
      NIMETUS_LIIGIGA: "Kõdesi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00003732",
    },
    {
      TASE: "3",
      KOOD: "3735",
      NIMETUS: "Kõdu küla",
      NIMETUS_LIIGIGA: "Kõdu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00003735",
    },
    {
      TASE: "3",
      KOOD: "3737",
      NIMETUS: "Kõduküla",
      NIMETUS_LIIGIGA: "Kõduküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00003737",
    },
    {
      TASE: "3",
      KOOD: "3738",
      NIMETUS: "Kõduküla",
      NIMETUS_LIIGIGA: "Kõduküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00003738",
    },
    {
      TASE: "3",
      KOOD: "3739",
      NIMETUS: "Kõera küla",
      NIMETUS_LIIGIGA: "Kõera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003739",
    },
    {
      TASE: "3",
      KOOD: "3741",
      NIMETUS: "Kõidama küla",
      NIMETUS_LIIGIGA: "Kõidama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00003741",
    },
    {
      TASE: "3",
      KOOD: "3744",
      NIMETUS: "Kõiguste küla",
      NIMETUS_LIIGIGA: "Kõiguste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003744",
    },
    {
      TASE: "3",
      KOOD: "3746",
      NIMETUS: "Kõima küla",
      NIMETUS_LIIGIGA: "Kõima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00003746",
    },
    {
      TASE: "3",
      KOOD: "3747",
      NIMETUS: "Kõinastu küla",
      NIMETUS_LIIGIGA: "Kõinastu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003747",
    },
    {
      TASE: "3",
      KOOD: "3748",
      NIMETUS: "Kõivuküla",
      NIMETUS_LIIGIGA: "Kõivuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00003748",
    },
    {
      TASE: "3",
      KOOD: "3749",
      NIMETUS: "Kõivu küla",
      NIMETUS_LIIGIGA: "Kõivu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00003749",
    },
    {
      TASE: "3",
      KOOD: "3750",
      NIMETUS: "Kõivsaare küla",
      NIMETUS_LIIGIGA: "Kõivsaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003750",
    },
    {
      TASE: "3",
      KOOD: "3752",
      NIMETUS: "Kõlbi küla",
      NIMETUS_LIIGIGA: "Kõlbi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00003752",
    },
    {
      TASE: "3",
      KOOD: "3754",
      NIMETUS: "Kõldu küla",
      NIMETUS_LIIGIGA: "Kõldu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00003754",
    },
    {
      TASE: "3",
      KOOD: "3755",
      NIMETUS: "Kõliküla",
      NIMETUS_LIIGIGA: "Kõliküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003755",
    },
    {
      TASE: "3",
      KOOD: "3757",
      NIMETUS: "Kõljala küla",
      NIMETUS_LIIGIGA: "Kõljala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003757",
    },
    {
      TASE: "3",
      KOOD: "3759",
      NIMETUS: "Kõlunõmme küla",
      NIMETUS_LIIGIGA: "Kõlunõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003759",
    },
    {
      TASE: "3",
      KOOD: "3760",
      NIMETUS: "Kõmmusselja küla",
      NIMETUS_LIIGIGA: "Kõmmusselja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003760",
    },
    {
      TASE: "3",
      KOOD: "3762",
      NIMETUS: "Kõmmaste küla",
      NIMETUS_LIIGIGA: "Kõmmaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00003762",
    },
    {
      TASE: "3",
      KOOD: "3765",
      NIMETUS: "Kõmsi küla",
      NIMETUS_LIIGIGA: "Kõmsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003765",
    },
    {
      TASE: "3",
      KOOD: "3768",
      NIMETUS: "Kõnnu küla",
      NIMETUS_LIIGIGA: "Kõnnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003768",
    },
    {
      TASE: "3",
      KOOD: "3769",
      NIMETUS: "Kõnnu küla",
      NIMETUS_LIIGIGA: "Kõnnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00003769",
    },
    {
      TASE: "3",
      KOOD: "3770",
      NIMETUS: "Kõnnu küla",
      NIMETUS_LIIGIGA: "Kõnnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00003770",
    },
    {
      TASE: "3",
      KOOD: "3771",
      NIMETUS: "Kõnnu küla",
      NIMETUS_LIIGIGA: "Kõnnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00003771",
    },
    {
      TASE: "3",
      KOOD: "3772",
      NIMETUS: "Kõnnu küla",
      NIMETUS_LIIGIGA: "Kõnnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00003772",
    },
    {
      TASE: "3",
      KOOD: "3774",
      NIMETUS: "Kõnnu küla",
      NIMETUS_LIIGIGA: "Kõnnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003774",
    },
    {
      TASE: "3",
      KOOD: "3775",
      NIMETUS: "Kõo küla",
      NIMETUS_LIIGIGA: "Kõo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00003775",
    },
    {
      TASE: "3",
      KOOD: "3776",
      NIMETUS: "Kõo küla",
      NIMETUS_LIIGIGA: "Kõo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003776",
    },
    {
      TASE: "3",
      KOOD: "3777",
      NIMETUS: "Kõnnujõe küla",
      NIMETUS_LIIGIGA: "Kõnnujõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00003777",
    },
    {
      TASE: "3",
      KOOD: "3778",
      NIMETUS: "Kõola küla",
      NIMETUS_LIIGIGA: "Kõola küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00003778",
    },
    {
      TASE: "3",
      KOOD: "3779",
      NIMETUS: "Kõpu küla",
      NIMETUS_LIIGIGA: "Kõpu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00003779",
    },
    {
      TASE: "3",
      KOOD: "3780",
      NIMETUS: "Kõomäe küla",
      NIMETUS_LIIGIGA: "Kõomäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003780",
    },
    {
      TASE: "3",
      KOOD: "3781",
      NIMETUS: "Kõpu küla",
      NIMETUS_LIIGIGA: "Kõpu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003781",
    },
    {
      TASE: "3",
      KOOD: "3782",
      NIMETUS: "Kõpu alevik",
      NIMETUS_LIIGIGA: "Kõpu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00003782",
    },
    {
      TASE: "3",
      KOOD: "3783",
      NIMETUS: "Kõpsta küla",
      NIMETUS_LIIGIGA: "Kõpsta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00003783",
    },
    {
      TASE: "3",
      KOOD: "3784",
      NIMETUS: "Kõpu küla",
      NIMETUS_LIIGIGA: "Kõpu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00003784",
    },
    {
      TASE: "3",
      KOOD: "3785",
      NIMETUS: "Kõrbja küla",
      NIMETUS_LIIGIGA: "Kõrbja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00003785",
    },
    {
      TASE: "3",
      KOOD: "3788",
      NIMETUS: "Kõrenduse küla",
      NIMETUS_LIIGIGA: "Kõrenduse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00003788",
    },
    {
      TASE: "3",
      KOOD: "3790",
      NIMETUS: "Kõrgemäe küla",
      NIMETUS_LIIGIGA: "Kõrgemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00003790",
    },
    {
      TASE: "3",
      KOOD: "3793",
      NIMETUS: "Kõrgepalu küla",
      NIMETUS_LIIGIGA: "Kõrgepalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003793",
    },
    {
      TASE: "3",
      KOOD: "3794",
      NIMETUS: "Korgõssaarõ küla",
      NIMETUS_LIIGIGA: "Korgõssaarõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003794",
    },
    {
      TASE: "3",
      KOOD: "3795",
      NIMETUS: "Kõrgessaare alevik",
      NIMETUS_LIIGIGA: "Kõrgessaare alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003795",
    },
    {
      TASE: "3",
      KOOD: "3796",
      NIMETUS: "Kõrgessaare küla",
      NIMETUS_LIIGIGA: "Kõrgessaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003796",
    },
    {
      TASE: "3",
      KOOD: "3799",
      NIMETUS: "Kõrgu küla",
      NIMETUS_LIIGIGA: "Kõrgu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00003799",
    },
    {
      TASE: "3",
      KOOD: "3800",
      NIMETUS: "Kõriska küla",
      NIMETUS_LIIGIGA: "Kõriska küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003800",
    },
    {
      TASE: "3",
      KOOD: "3801",
      NIMETUS: "Kõrkküla",
      NIMETUS_LIIGIGA: "Kõrkküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00003801",
    },
    {
      TASE: "3",
      KOOD: "1004",
      NIMETUS: "Aa küla",
      NIMETUS_LIIGIGA: "Aa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00001004",
    },
    {
      TASE: "3",
      KOOD: "1005",
      NIMETUS: "Aabra küla",
      NIMETUS_LIIGIGA: "Aabra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001005",
    },
    {
      TASE: "3",
      KOOD: "1007",
      NIMETUS: "Kolga-Aabla küla",
      NIMETUS_LIIGIGA: "Kolga-Aabla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00001007",
    },
    {
      TASE: "3",
      KOOD: "1010",
      NIMETUS: "Aadami küla",
      NIMETUS_LIIGIGA: "Aadami küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00001010",
    },
    {
      TASE: "3",
      KOOD: "1013",
      NIMETUS: "Aadma küla",
      NIMETUS_LIIGIGA: "Aadma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001013",
    },
    {
      TASE: "3",
      KOOD: "1016",
      NIMETUS: "Aakaru küla",
      NIMETUS_LIIGIGA: "Aakaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00001016",
    },
    {
      TASE: "3",
      KOOD: "1017",
      NIMETUS: "Aamse küla",
      NIMETUS_LIIGIGA: "Aamse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00001017",
    },
    {
      TASE: "3",
      KOOD: "1018",
      NIMETUS: "Aakre küla",
      NIMETUS_LIIGIGA: "Aakre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00001018",
    },
    {
      TASE: "3",
      KOOD: "1021",
      NIMETUS: "Aandu küla",
      NIMETUS_LIIGIGA: "Aandu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00001021",
    },
    {
      TASE: "3",
      KOOD: "1022",
      NIMETUS: "Aardlapalu küla",
      NIMETUS_LIIGIGA: "Aardlapalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00001022",
    },
    {
      TASE: "3",
      KOOD: "1024",
      NIMETUS: "Aardla küla",
      NIMETUS_LIIGIGA: "Aardla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00001024",
    },
    {
      TASE: "3",
      KOOD: "1027",
      NIMETUS: "Aarna küla",
      NIMETUS_LIIGIGA: "Aarna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00001027",
    },
    {
      TASE: "3",
      KOOD: "1030",
      NIMETUS: "Aasa küla",
      NIMETUS_LIIGIGA: "Aasa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00001030",
    },
    {
      TASE: "3",
      KOOD: "1032",
      NIMETUS: "Aaspere küla",
      NIMETUS_LIIGIGA: "Aaspere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001032",
    },
    {
      TASE: "3",
      KOOD: "1035",
      NIMETUS: "Aasu küla",
      NIMETUS_LIIGIGA: "Aasu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001035",
    },
    {
      TASE: "3",
      KOOD: "1037",
      NIMETUS: "Aasukalda küla",
      NIMETUS_LIIGIGA: "Aasukalda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00001037",
    },
    {
      TASE: "3",
      KOOD: "1040",
      NIMETUS: "Aasumetsa küla",
      NIMETUS_LIIGIGA: "Aasumetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001040",
    },
    {
      TASE: "3",
      KOOD: "1043",
      NIMETUS: "Aasuvälja küla",
      NIMETUS_LIIGIGA: "Aasuvälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00001043",
    },
    {
      TASE: "3",
      KOOD: "1046",
      NIMETUS: "Aavere küla",
      NIMETUS_LIIGIGA: "Aavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00001046",
    },
    {
      TASE: "3",
      KOOD: "1047",
      NIMETUS: "Aavere küla",
      NIMETUS_LIIGIGA: "Aavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00001047",
    },
    {
      TASE: "3",
      KOOD: "1048",
      NIMETUS: "Aavere küla",
      NIMETUS_LIIGIGA: "Aavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00001048",
    },
    {
      TASE: "3",
      KOOD: "1050",
      NIMETUS: "Aaviku küla",
      NIMETUS_LIIGIGA: "Aaviku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00001050",
    },
    {
      TASE: "3",
      KOOD: "1051",
      NIMETUS: "Aaviku küla",
      NIMETUS_LIIGIGA: "Aaviku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001051",
    },
    {
      TASE: "3",
      KOOD: "1052",
      NIMETUS: "Aaviku küla",
      NIMETUS_LIIGIGA: "Aaviku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001052",
    },
    {
      TASE: "3",
      KOOD: "1054",
      NIMETUS: "Abaja küla",
      NIMETUS_LIIGIGA: "Abaja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001054",
    },
    {
      TASE: "3",
      KOOD: "1055",
      NIMETUS: "Abaja küla",
      NIMETUS_LIIGIGA: "Abaja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00001055",
    },
    {
      TASE: "3",
      KOOD: "1058",
      NIMETUS: "Abissaare küla",
      NIMETUS_LIIGIGA: "Abissaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00001058",
    },
    {
      TASE: "3",
      KOOD: "1060",
      NIMETUS: "Abja-Paluoja linn",
      NIMETUS_LIIGIGA: "Abja-Paluoja linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00001060",
    },
    {
      TASE: "3",
      KOOD: "1061",
      NIMETUS: "Abjaku küla",
      NIMETUS_LIIGIGA: "Abjaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00001061",
    },
    {
      TASE: "3",
      KOOD: "1064",
      NIMETUS: "Abruka küla",
      NIMETUS_LIIGIGA: "Abruka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001064",
    },
    {
      TASE: "3",
      KOOD: "1067",
      NIMETUS: "Abula küla",
      NIMETUS_LIIGIGA: "Abula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001067",
    },
    {
      TASE: "3",
      KOOD: "1069",
      NIMETUS: "Aburi küla",
      NIMETUS_LIIGIGA: "Aburi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00001069",
    },
    {
      TASE: "3",
      KOOD: "1073",
      NIMETUS: "Adaka küla",
      NIMETUS_LIIGIGA: "Adaka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001073",
    },
    {
      TASE: "3",
      KOOD: "1076",
      NIMETUS: "Adavere alevik",
      NIMETUS_LIIGIGA: "Adavere alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00001076",
    },
    {
      TASE: "3",
      KOOD: "1079",
      NIMETUS: "Adila küla",
      NIMETUS_LIIGIGA: "Adila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00001079",
    },
    {
      TASE: "3",
      KOOD: "1081",
      NIMETUS: "Adiste küla",
      NIMETUS_LIIGIGA: "Adiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00001081",
    },
    {
      TASE: "3",
      KOOD: "1084",
      NIMETUS: "Adra küla",
      NIMETUS_LIIGIGA: "Adra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00001084",
    },
    {
      TASE: "3",
      KOOD: "1087",
      NIMETUS: "Adraku küla",
      NIMETUS_LIIGIGA: "Adraku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00001087",
    },
    {
      TASE: "3",
      KOOD: "1088",
      NIMETUS: "Aegviidu alev",
      NIMETUS_LIIGIGA: "Aegviidu alev",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00001088",
    },
    {
      TASE: "3",
      KOOD: "1089",
      NIMETUS: "Aela küla",
      NIMETUS_LIIGIGA: "Aela küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00001089",
    },
    {
      TASE: "3",
      KOOD: "1091",
      NIMETUS: "Aesoo küla",
      NIMETUS_LIIGIGA: "Aesoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00001091",
    },
    {
      TASE: "3",
      KOOD: "1097",
      NIMETUS: "Agali küla",
      NIMETUS_LIIGIGA: "Agali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00001097",
    },
    {
      TASE: "3",
      KOOD: "1100",
      NIMETUS: "Ageri küla",
      NIMETUS_LIIGIGA: "Ageri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00001100",
    },
    {
      TASE: "3",
      KOOD: "1103",
      NIMETUS: "Agusalu küla",
      NIMETUS_LIIGIGA: "Agusalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00001103",
    },
    {
      TASE: "3",
      KOOD: "1107",
      NIMETUS: "Ahaste küla",
      NIMETUS_LIIGIGA: "Ahaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00001107",
    },
    {
      TASE: "3",
      KOOD: "1110",
      NIMETUS: "Ahekõnnu küla",
      NIMETUS_LIIGIGA: "Ahekõnnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00001110",
    },
    {
      TASE: "3",
      KOOD: "1111",
      NIMETUS: "Ahitsa küla",
      NIMETUS_LIIGIGA: "Ahitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001111",
    },
    {
      TASE: "3",
      KOOD: "1113",
      NIMETUS: "Ahisilla küla",
      NIMETUS_LIIGIGA: "Ahisilla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00001113",
    },
    {
      TASE: "3",
      KOOD: "1116",
      NIMETUS: "Ahja alevik",
      NIMETUS_LIIGIGA: "Ahja alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00001116",
    },
    {
      TASE: "3",
      KOOD: "1119",
      NIMETUS: "Suure-Ahli küla",
      NIMETUS_LIIGIGA: "Suure-Ahli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00001119",
    },
    {
      TASE: "3",
      KOOD: "1122",
      NIMETUS: "Ahula küla",
      NIMETUS_LIIGIGA: "Ahula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00001122",
    },
    {
      TASE: "3",
      KOOD: "1125",
      NIMETUS: "Ahunapalu küla",
      NIMETUS_LIIGIGA: "Ahunapalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00001125",
    },
    {
      TASE: "3",
      KOOD: "1129",
      NIMETUS: "Aiamaa küla",
      NIMETUS_LIIGIGA: "Aiamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00001129",
    },
    {
      TASE: "3",
      KOOD: "1131",
      NIMETUS: "Aiaste küla",
      NIMETUS_LIIGIGA: "Aiaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00001131",
    },
    {
      TASE: "3",
      KOOD: "1132",
      NIMETUS: "Aidu küla",
      NIMETUS_LIIGIGA: "Aidu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00001132",
    },
    {
      TASE: "3",
      KOOD: "1133",
      NIMETUS: "Aidu-Nõmme küla",
      NIMETUS_LIIGIGA: "Aidu-Nõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00001133",
    },
    {
      TASE: "3",
      KOOD: "1134",
      NIMETUS: "Haidaku küla",
      NIMETUS_LIIGIGA: "Haidaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001134",
    },
    {
      TASE: "3",
      KOOD: "1137",
      NIMETUS: "Aidu küla",
      NIMETUS_LIIGIGA: "Aidu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00001137",
    },
    {
      TASE: "3",
      KOOD: "1138",
      NIMETUS: "Aidu küla",
      NIMETUS_LIIGIGA: "Aidu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00001138",
    },
    {
      TASE: "3",
      KOOD: "1139",
      NIMETUS: "Aidu-Liiva küla",
      NIMETUS_LIIGIGA: "Aidu-Liiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00001139",
    },
    {
      TASE: "3",
      KOOD: "1140",
      NIMETUS: "Aidu-Sooküla",
      NIMETUS_LIIGIGA: "Aidu-Sooküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00001140",
    },
    {
      TASE: "3",
      KOOD: "1141",
      NIMETUS: "Aila küla",
      NIMETUS_LIIGIGA: "Aila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00001141",
    },
    {
      TASE: "3",
      KOOD: "1144",
      NIMETUS: "Aimla küla",
      NIMETUS_LIIGIGA: "Aimla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00001144",
    },
    {
      TASE: "3",
      KOOD: "1147",
      NIMETUS: "Aindu küla",
      NIMETUS_LIIGIGA: "Aindu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00001147",
    },
    {
      TASE: "3",
      KOOD: "1149",
      NIMETUS: "Ainja küla",
      NIMETUS_LIIGIGA: "Ainja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00001149",
    },
    {
      TASE: "3",
      KOOD: "1152",
      NIMETUS: "Aitsra küla",
      NIMETUS_LIIGIGA: "Aitsra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00001152",
    },
    {
      TASE: "3",
      KOOD: "1156",
      NIMETUS: "Akste küla",
      NIMETUS_LIIGIGA: "Akste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00001156",
    },
    {
      TASE: "3",
      KOOD: "1157",
      NIMETUS: "Ala küla",
      NIMETUS_LIIGIGA: "Ala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001157",
    },
    {
      TASE: "3",
      KOOD: "1160",
      NIMETUS: "Ala küla",
      NIMETUS_LIIGIGA: "Ala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00001160",
    },
    {
      TASE: "3",
      KOOD: "1161",
      NIMETUS: "Ala-Palo küla",
      NIMETUS_LIIGIGA: "Ala-Palo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001161",
    },
    {
      TASE: "3",
      KOOD: "1162",
      NIMETUS: "Ala-Tilga küla",
      NIMETUS_LIIGIGA: "Ala-Tilga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001162",
    },
    {
      TASE: "3",
      KOOD: "1163",
      NIMETUS: "Alakülä küla",
      NIMETUS_LIIGIGA: "Alakülä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001163",
    },
    {
      TASE: "3",
      KOOD: "1164",
      NIMETUS: "Ala-Suhka küla",
      NIMETUS_LIIGIGA: "Ala-Suhka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001164",
    },
    {
      TASE: "3",
      KOOD: "1165",
      NIMETUS: "Alajõe küla",
      NIMETUS_LIIGIGA: "Alajõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00001165",
    },
    {
      TASE: "3",
      KOOD: "1166",
      NIMETUS: "Alajõe küla",
      NIMETUS_LIIGIGA: "Alajõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00001166",
    },
    {
      TASE: "3",
      KOOD: "1167",
      NIMETUS: "Alaküla",
      NIMETUS_LIIGIGA: "Alaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00001167",
    },
    {
      TASE: "3",
      KOOD: "1168",
      NIMETUS: "Alaküla",
      NIMETUS_LIIGIGA: "Alaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00001168",
    },
    {
      TASE: "3",
      KOOD: "1169",
      NIMETUS: "Alaküla",
      NIMETUS_LIIGIGA: "Alaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00001169",
    },
    {
      TASE: "3",
      KOOD: "1170",
      NIMETUS: "Ala-Tsumba küla",
      NIMETUS_LIIGIGA: "Ala-Tsumba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00001170",
    },
    {
      TASE: "3",
      KOOD: "1171",
      NIMETUS: "Alamõisa küla",
      NIMETUS_LIIGIGA: "Alamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00001171",
    },
    {
      TASE: "3",
      KOOD: "1172",
      NIMETUS: "Alapõdra küla",
      NIMETUS_LIIGIGA: "Alapõdra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001172",
    },
    {
      TASE: "3",
      KOOD: "1174",
      NIMETUS: "Alansi küla",
      NIMETUS_LIIGIGA: "Alansi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00001174",
    },
    {
      TASE: "3",
      KOOD: "1176",
      NIMETUS: "Alasoo küla",
      NIMETUS_LIIGIGA: "Alasoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00001176",
    },
    {
      TASE: "3",
      KOOD: "1179",
      NIMETUS: "Alastvere küla",
      NIMETUS_LIIGIGA: "Alastvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00001179",
    },
    {
      TASE: "3",
      KOOD: "1181",
      NIMETUS: "Alatskivi alevik",
      NIMETUS_LIIGIGA: "Alatskivi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00001181",
    },
    {
      TASE: "3",
      KOOD: "1182",
      NIMETUS: "Alavere küla",
      NIMETUS_LIIGIGA: "Alavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00001182",
    },
    {
      TASE: "3",
      KOOD: "1184",
      NIMETUS: "Alavere küla",
      NIMETUS_LIIGIGA: "Alavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00001184",
    },
    {
      TASE: "3",
      KOOD: "1185",
      NIMETUS: "Alavere küla",
      NIMETUS_LIIGIGA: "Alavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00001185",
    },
    {
      TASE: "3",
      KOOD: "1188",
      NIMETUS: "Albu küla",
      NIMETUS_LIIGIGA: "Albu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00001188",
    },
    {
      TASE: "3",
      KOOD: "1191",
      NIMETUS: "Alekere küla",
      NIMETUS_LIIGIGA: "Alekere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00001191",
    },
    {
      TASE: "3",
      KOOD: "1193",
      NIMETUS: "Alekvere küla",
      NIMETUS_LIIGIGA: "Alekvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00001193",
    },
    {
      TASE: "3",
      KOOD: "1196",
      NIMETUS: "Aljava küla",
      NIMETUS_LIIGIGA: "Aljava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00001196",
    },
    {
      TASE: "3",
      KOOD: "1199",
      NIMETUS: "Allaste küla",
      NIMETUS_LIIGIGA: "Allaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00001199",
    },
    {
      TASE: "3",
      KOOD: "1200",
      NIMETUS: "Allika küla",
      NIMETUS_LIIGIGA: "Allika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001200",
    },
    {
      TASE: "3",
      KOOD: "1201",
      NIMETUS: "Allika küla",
      NIMETUS_LIIGIGA: "Allika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00001201",
    },
    {
      TASE: "3",
      KOOD: "1202",
      NIMETUS: "Allika küla",
      NIMETUS_LIIGIGA: "Allika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00001202",
    },
    {
      TASE: "3",
      KOOD: "1203",
      NIMETUS: "Allika küla",
      NIMETUS_LIIGIGA: "Allika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00001203",
    },
    {
      TASE: "3",
      KOOD: "1204",
      NIMETUS: "Allika küla",
      NIMETUS_LIIGIGA: "Allika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00001204",
    },
    {
      TASE: "3",
      KOOD: "1205",
      NIMETUS: "Allikjärve küla",
      NIMETUS_LIIGIGA: "Allikjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00001205",
    },
    {
      TASE: "3",
      KOOD: "1206",
      NIMETUS: "Allika küla",
      NIMETUS_LIIGIGA: "Allika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00001206",
    },
    {
      TASE: "3",
      KOOD: "1208",
      NIMETUS: "Alliklepa küla",
      NIMETUS_LIIGIGA: "Alliklepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00001208",
    },
    {
      TASE: "3",
      KOOD: "1209",
      NIMETUS: "Allikotsa küla",
      NIMETUS_LIIGIGA: "Allikotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00001209",
    },
    {
      TASE: "3",
      KOOD: "1210",
      NIMETUS: "Allikmaa küla",
      NIMETUS_LIIGIGA: "Allikmaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00001210",
    },
    {
      TASE: "3",
      KOOD: "1211",
      NIMETUS: "Alliku küla",
      NIMETUS_LIIGIGA: "Alliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00001211",
    },
    {
      TASE: "3",
      KOOD: "1212",
      NIMETUS: "Altküla",
      NIMETUS_LIIGIGA: "Altküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00001212",
    },
    {
      TASE: "3",
      KOOD: "1214",
      NIMETUS: "Altküla",
      NIMETUS_LIIGIGA: "Altküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00001214",
    },
    {
      TASE: "3",
      KOOD: "1215",
      NIMETUS: "Allikõnnu küla",
      NIMETUS_LIIGIGA: "Allikõnnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00001215",
    },
    {
      TASE: "3",
      KOOD: "1218",
      NIMETUS: "Altja küla",
      NIMETUS_LIIGIGA: "Altja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001218",
    },
    {
      TASE: "3",
      KOOD: "1219",
      NIMETUS: "Altküla",
      NIMETUS_LIIGIGA: "Altküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00001219",
    },
    {
      TASE: "3",
      KOOD: "1221",
      NIMETUS: "Altküla",
      NIMETUS_LIIGIGA: "Altküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00001221",
    },
    {
      TASE: "3",
      KOOD: "1223",
      NIMETUS: "Altmäe küla",
      NIMETUS_LIIGIGA: "Altmäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00001223",
    },
    {
      TASE: "3",
      KOOD: "1226",
      NIMETUS: "Altnurga küla",
      NIMETUS_LIIGIGA: "Altnurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00001226",
    },
    {
      TASE: "3",
      KOOD: "1229",
      NIMETUS: "Alu küla",
      NIMETUS_LIIGIGA: "Alu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00001229",
    },
    {
      TASE: "3",
      KOOD: "1230",
      NIMETUS: "Alu alevik",
      NIMETUS_LIIGIGA: "Alu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00001230",
    },
    {
      TASE: "3",
      KOOD: "1232",
      NIMETUS: "Alu-Metsküla",
      NIMETUS_LIIGIGA: "Alu-Metsküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00001232",
    },
    {
      TASE: "3",
      KOOD: "1235",
      NIMETUS: "Alupere küla",
      NIMETUS_LIIGIGA: "Alupere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00001235",
    },
    {
      TASE: "3",
      KOOD: "1237",
      NIMETUS: "Aluste küla",
      NIMETUS_LIIGIGA: "Aluste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00001237",
    },
    {
      TASE: "3",
      KOOD: "1240",
      NIMETUS: "Alustre küla",
      NIMETUS_LIIGIGA: "Alustre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00001240",
    },
    {
      TASE: "3",
      KOOD: "1242",
      NIMETUS: "Aluvere küla",
      NIMETUS_LIIGIGA: "Aluvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00001242",
    },
    {
      TASE: "3",
      KOOD: "1250",
      NIMETUS: "Ambla alevik",
      NIMETUS_LIIGIGA: "Ambla alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00001250",
    },
    {
      TASE: "3",
      KOOD: "1251",
      NIMETUS: "Ammuta küla",
      NIMETUS_LIIGIGA: "Ammuta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00001251",
    },
    {
      TASE: "3",
      KOOD: "1252",
      NIMETUS: "Ammuta küla",
      NIMETUS_LIIGIGA: "Ammuta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00001252",
    },
    {
      TASE: "3",
      KOOD: "1253",
      NIMETUS: "Amula küla",
      NIMETUS_LIIGIGA: "Amula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00001253",
    },
    {
      TASE: "3",
      KOOD: "1255",
      NIMETUS: "Andi küla",
      NIMETUS_LIIGIGA: "Andi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001255",
    },
    {
      TASE: "3",
      KOOD: "1256",
      NIMETUS: "Andineeme küla",
      NIMETUS_LIIGIGA: "Andineeme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00001256",
    },
    {
      TASE: "3",
      KOOD: "1259",
      NIMETUS: "Andja küla",
      NIMETUS_LIIGIGA: "Andja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00001259",
    },
    {
      TASE: "3",
      KOOD: "1261",
      NIMETUS: "Andre küla",
      NIMETUS_LIIGIGA: "Andre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00001261",
    },
    {
      TASE: "3",
      KOOD: "1262",
      NIMETUS: "Andsumäe küla",
      NIMETUS_LIIGIGA: "Andsumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001262",
    },
    {
      TASE: "3",
      KOOD: "1264",
      NIMETUS: "Antkruva küla",
      NIMETUS_LIIGIGA: "Antkruva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00001264",
    },
    {
      TASE: "3",
      KOOD: "1267",
      NIMETUS: "Anelema küla",
      NIMETUS_LIIGIGA: "Anelema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00001267",
    },
    {
      TASE: "3",
      KOOD: "1268",
      NIMETUS: "Anepesa küla",
      NIMETUS_LIIGIGA: "Anepesa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001268",
    },
    {
      TASE: "3",
      KOOD: "1270",
      NIMETUS: "Angerja küla",
      NIMETUS_LIIGIGA: "Angerja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00001270",
    },
    {
      TASE: "3",
      KOOD: "1272",
      NIMETUS: "Angla küla",
      NIMETUS_LIIGIGA: "Angla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001272",
    },
    {
      TASE: "3",
      KOOD: "1275",
      NIMETUS: "Anguse küla",
      NIMETUS_LIIGIGA: "Anguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00001275",
    },
    {
      TASE: "3",
      KOOD: "1278",
      NIMETUS: "Anija küla",
      NIMETUS_LIIGIGA: "Anija küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00001278",
    },
    {
      TASE: "3",
      KOOD: "1280",
      NIMETUS: "Anijala küla",
      NIMETUS_LIIGIGA: "Anijala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001280",
    },
    {
      TASE: "3",
      KOOD: "1283",
      NIMETUS: "Anikatsi küla",
      NIMETUS_LIIGIGA: "Anikatsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00001283",
    },
    {
      TASE: "3",
      KOOD: "1286",
      NIMETUS: "Anna küla",
      NIMETUS_LIIGIGA: "Anna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00001286",
    },
    {
      TASE: "3",
      KOOD: "1288",
      NIMETUS: "Anne küla",
      NIMETUS_LIIGIGA: "Anne küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00001288",
    },
    {
      TASE: "3",
      KOOD: "1291",
      NIMETUS: "Annikoru küla",
      NIMETUS_LIIGIGA: "Annikoru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00001291",
    },
    {
      TASE: "3",
      KOOD: "1293",
      NIMETUS: "Annikvere küla",
      NIMETUS_LIIGIGA: "Annikvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00001293",
    },
    {
      TASE: "3",
      KOOD: "1294",
      NIMETUS: "Annikvere küla",
      NIMETUS_LIIGIGA: "Annikvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001294",
    },
    {
      TASE: "3",
      KOOD: "1297",
      NIMETUS: "Anseküla",
      NIMETUS_LIIGIGA: "Anseküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001297",
    },
    {
      TASE: "3",
      KOOD: "1300",
      NIMETUS: "Ansi küla",
      NIMETUS_LIIGIGA: "Ansi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001300",
    },
    {
      TASE: "3",
      KOOD: "1301",
      NIMETUS: "Antsla linn",
      NIMETUS_LIIGIGA: "Antsla linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00001301",
    },
    {
      TASE: "3",
      KOOD: "1303",
      NIMETUS: "Antsu küla",
      NIMETUS_LIIGIGA: "Antsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00001303",
    },
    {
      TASE: "3",
      KOOD: "1305",
      NIMETUS: "Andsumäe küla",
      NIMETUS_LIIGIGA: "Andsumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001305",
    },
    {
      TASE: "3",
      KOOD: "1309",
      NIMETUS: "Ao küla",
      NIMETUS_LIIGIGA: "Ao küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00001309",
    },
    {
      TASE: "3",
      KOOD: "1310",
      NIMETUS: "Apandiku küla",
      NIMETUS_LIIGIGA: "Apandiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00001310",
    },
    {
      TASE: "3",
      KOOD: "1312",
      NIMETUS: "Aovere küla",
      NIMETUS_LIIGIGA: "Aovere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00001312",
    },
    {
      TASE: "3",
      KOOD: "1313",
      NIMETUS: "Arandi küla",
      NIMETUS_LIIGIGA: "Arandi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001313",
    },
    {
      TASE: "3",
      KOOD: "1314",
      NIMETUS: "Arase küla",
      NIMETUS_LIIGIGA: "Arase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00001314",
    },
    {
      TASE: "3",
      KOOD: "1315",
      NIMETUS: "Araski küla",
      NIMETUS_LIIGIGA: "Araski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00001315",
    },
    {
      TASE: "3",
      KOOD: "1316",
      NIMETUS: "Aranküla",
      NIMETUS_LIIGIGA: "Aranküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00001316",
    },
    {
      TASE: "3",
      KOOD: "1319",
      NIMETUS: "Araste küla",
      NIMETUS_LIIGIGA: "Araste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00001319",
    },
    {
      TASE: "3",
      KOOD: "1321",
      NIMETUS: "Arava küla",
      NIMETUS_LIIGIGA: "Arava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00001321",
    },
    {
      TASE: "3",
      KOOD: "1324",
      NIMETUS: "Aravere küla",
      NIMETUS_LIIGIGA: "Aravere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00001324",
    },
    {
      TASE: "3",
      KOOD: "1326",
      NIMETUS: "Aravete alevik",
      NIMETUS_LIIGIGA: "Aravete alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00001326",
    },
    {
      TASE: "3",
      KOOD: "1329",
      NIMETUS: "Aravu küla",
      NIMETUS_LIIGIGA: "Aravu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00001329",
    },
    {
      TASE: "3",
      KOOD: "1331",
      NIMETUS: "Aravuse küla",
      NIMETUS_LIIGIGA: "Aravuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00001331",
    },
    {
      TASE: "3",
      KOOD: "1334",
      NIMETUS: "Arbavere küla",
      NIMETUS_LIIGIGA: "Arbavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00001334",
    },
    {
      TASE: "3",
      KOOD: "1337",
      NIMETUS: "Ardla küla",
      NIMETUS_LIIGIGA: "Ardla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001337",
    },
    {
      TASE: "3",
      KOOD: "1340",
      NIMETUS: "Ardu alevik",
      NIMETUS_LIIGIGA: "Ardu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00001340",
    },
    {
      TASE: "3",
      KOOD: "1345",
      NIMETUS: "Aresi küla",
      NIMETUS_LIIGIGA: "Aresi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00001345",
    },
    {
      TASE: "3",
      KOOD: "1346",
      NIMETUS: "Are küla",
      NIMETUS_LIIGIGA: "Are küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001346",
    },
    {
      TASE: "3",
      KOOD: "1348",
      NIMETUS: "Ariste küla",
      NIMETUS_LIIGIGA: "Ariste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001348",
    },
    {
      TASE: "3",
      KOOD: "1351",
      NIMETUS: "Arisvere küla",
      NIMETUS_LIIGIGA: "Arisvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00001351",
    },
    {
      TASE: "3",
      KOOD: "1354",
      NIMETUS: "Arjadi küla",
      NIMETUS_LIIGIGA: "Arjadi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00001354",
    },
    {
      TASE: "3",
      KOOD: "1356",
      NIMETUS: "Arjassaare küla",
      NIMETUS_LIIGIGA: "Arjassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00001356",
    },
    {
      TASE: "3",
      KOOD: "1357",
      NIMETUS: "Arju küla",
      NIMETUS_LIIGIGA: "Arju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001357",
    },
    {
      TASE: "3",
      KOOD: "1359",
      NIMETUS: "Arkma küla",
      NIMETUS_LIIGIGA: "Arkma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00001359",
    },
    {
      TASE: "3",
      KOOD: "1362",
      NIMETUS: "Arkna küla",
      NIMETUS_LIIGIGA: "Arkna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00001362",
    },
    {
      TASE: "3",
      KOOD: "1363",
      NIMETUS: "Aru küla",
      NIMETUS_LIIGIGA: "Aru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00001363",
    },
    {
      TASE: "3",
      KOOD: "1364",
      NIMETUS: "Aru küla",
      NIMETUS_LIIGIGA: "Aru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001364",
    },
    {
      TASE: "3",
      KOOD: "1365",
      NIMETUS: "Aruaia küla",
      NIMETUS_LIIGIGA: "Aruaia küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00001365",
    },
    {
      TASE: "3",
      KOOD: "1366",
      NIMETUS: "Aruküla",
      NIMETUS_LIIGIGA: "Aruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00001366",
    },
    {
      TASE: "3",
      KOOD: "1367",
      NIMETUS: "Aruaru küla",
      NIMETUS_LIIGIGA: "Aruaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00001367",
    },
    {
      TASE: "3",
      KOOD: "1368",
      NIMETUS: "Aruküla",
      NIMETUS_LIIGIGA: "Aruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00001368",
    },
    {
      TASE: "3",
      KOOD: "1370",
      NIMETUS: "Arukse küla",
      NIMETUS_LIIGIGA: "Arukse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00001370",
    },
    {
      TASE: "3",
      KOOD: "1371",
      NIMETUS: "Aruküla",
      NIMETUS_LIIGIGA: "Aruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00001371",
    },
    {
      TASE: "3",
      KOOD: "1372",
      NIMETUS: "Aruküla",
      NIMETUS_LIIGIGA: "Aruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00001372",
    },
    {
      TASE: "3",
      KOOD: "1373",
      NIMETUS: "Aruküla alevik",
      NIMETUS_LIIGIGA: "Aruküla alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00001373",
    },
    {
      TASE: "3",
      KOOD: "1374",
      NIMETUS: "Aruküla",
      NIMETUS_LIIGIGA: "Aruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001374",
    },
    {
      TASE: "3",
      KOOD: "1375",
      NIMETUS: "Aruküla",
      NIMETUS_LIIGIGA: "Aruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00001375",
    },
    {
      TASE: "3",
      KOOD: "1376",
      NIMETUS: "Arula küla",
      NIMETUS_LIIGIGA: "Arula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00001376",
    },
    {
      TASE: "3",
      KOOD: "1377",
      NIMETUS: "Aruküla",
      NIMETUS_LIIGIGA: "Aruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00001377",
    },
    {
      TASE: "3",
      KOOD: "1378",
      NIMETUS: "Arumetsa küla",
      NIMETUS_LIIGIGA: "Arumetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00001378",
    },
    {
      TASE: "3",
      KOOD: "1381",
      NIMETUS: "Arumäe küla",
      NIMETUS_LIIGIGA: "Arumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00001381",
    },
    {
      TASE: "3",
      KOOD: "1382",
      NIMETUS: "Arupäälse küla",
      NIMETUS_LIIGIGA: "Arupäälse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00001382",
    },
    {
      TASE: "3",
      KOOD: "1383",
      NIMETUS: "Arupää küla",
      NIMETUS_LIIGIGA: "Arupää küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00001383",
    },
    {
      TASE: "3",
      KOOD: "1386",
      NIMETUS: "Arussaare küla",
      NIMETUS_LIIGIGA: "Arussaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00001386",
    },
    {
      TASE: "3",
      KOOD: "1388",
      NIMETUS: "Arusta küla",
      NIMETUS_LIIGIGA: "Arusta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY00001388",
    },
    {
      TASE: "3",
      KOOD: "1389",
      NIMETUS: "Aruste küla",
      NIMETUS_LIIGIGA: "Aruste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001389",
    },
    {
      TASE: "3",
      KOOD: "1391",
      NIMETUS: "Aruvalla küla",
      NIMETUS_LIIGIGA: "Aruvalla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00001391",
    },
    {
      TASE: "3",
      KOOD: "1393",
      NIMETUS: "Aruvälja küla",
      NIMETUS_LIIGIGA: "Aruvälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00001393",
    },
    {
      TASE: "3",
      KOOD: "1394",
      NIMETUS: "Aruvälja küla",
      NIMETUS_LIIGIGA: "Aruvälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00001394",
    },
    {
      TASE: "3",
      KOOD: "1395",
      NIMETUS: "Aruvälja küla",
      NIMETUS_LIIGIGA: "Aruvälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00001395",
    },
    {
      TASE: "3",
      KOOD: "1398",
      NIMETUS: "Arvila küla",
      NIMETUS_LIIGIGA: "Arvila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00001398",
    },
    {
      TASE: "3",
      KOOD: "1402",
      NIMETUS: "Aseri alevik",
      NIMETUS_LIIGIGA: "Aseri alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00001402",
    },
    {
      TASE: "3",
      KOOD: "1405",
      NIMETUS: "Aseriaru küla",
      NIMETUS_LIIGIGA: "Aseriaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00001405",
    },
    {
      TASE: "3",
      KOOD: "1408",
      NIMETUS: "Assaku alevik",
      NIMETUS_LIIGIGA: "Assaku alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00001408",
    },
    {
      TASE: "3",
      KOOD: "1410",
      NIMETUS: "Assamalla küla",
      NIMETUS_LIIGIGA: "Assamalla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00001410",
    },
    {
      TASE: "3",
      KOOD: "1413",
      NIMETUS: "Assikvere küla",
      NIMETUS_LIIGIGA: "Assikvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00001413",
    },
    {
      TASE: "3",
      KOOD: "1416",
      NIMETUS: "Aste alevik",
      NIMETUS_LIIGIGA: "Aste alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001416",
    },
    {
      TASE: "3",
      KOOD: "1417",
      NIMETUS: "Aste küla",
      NIMETUS_LIIGIGA: "Aste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001417",
    },
    {
      TASE: "3",
      KOOD: "1418",
      NIMETUS: "Astuvere küla",
      NIMETUS_LIIGIGA: "Astuvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00001418",
    },
    {
      TASE: "3",
      KOOD: "1421",
      NIMETUS: "Laiksaare küla",
      NIMETUS_LIIGIGA: "Laiksaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00001421",
    },
    {
      TASE: "3",
      KOOD: "1424",
      NIMETUS: "Asuka küla",
      NIMETUS_LIIGIGA: "Asuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001424",
    },
    {
      TASE: "3",
      KOOD: "1426",
      NIMETUS: "Asuküla",
      NIMETUS_LIIGIGA: "Asuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001426",
    },
    {
      TASE: "3",
      KOOD: "1429",
      NIMETUS: "Asva küla",
      NIMETUS_LIIGIGA: "Asva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001429",
    },
    {
      TASE: "3",
      KOOD: "1433",
      NIMETUS: "Atika küla",
      NIMETUS_LIIGIGA: "Atika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00001433",
    },
    {
      TASE: "3",
      KOOD: "1436",
      NIMETUS: "Atla küla",
      NIMETUS_LIIGIGA: "Atla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001436",
    },
    {
      TASE: "3",
      KOOD: "1437",
      NIMETUS: "Atla küla",
      NIMETUS_LIIGIGA: "Atla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00001437",
    },
    {
      TASE: "3",
      KOOD: "1440",
      NIMETUS: "Atra küla",
      NIMETUS_LIIGIGA: "Atra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00001440",
    },
    {
      TASE: "3",
      KOOD: "1441",
      NIMETUS: "Ataste küla",
      NIMETUS_LIIGIGA: "Ataste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00001441",
    },
    {
      TASE: "3",
      KOOD: "1443",
      NIMETUS: "Atsalama küla",
      NIMETUS_LIIGIGA: "Atsalama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00001443",
    },
    {
      TASE: "3",
      KOOD: "1447",
      NIMETUS: "Auaste küla",
      NIMETUS_LIIGIGA: "Auaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00001447",
    },
    {
      TASE: "3",
      KOOD: "1449",
      NIMETUS: "Aude küla",
      NIMETUS_LIIGIGA: "Aude küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00001449",
    },
    {
      TASE: "3",
      KOOD: "1450",
      NIMETUS: "Audevälja küla",
      NIMETUS_LIIGIGA: "Audevälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00001450",
    },
    {
      TASE: "3",
      KOOD: "1453",
      NIMETUS: "Audjassaare küla",
      NIMETUS_LIIGIGA: "Audjassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00001453",
    },
    {
      TASE: "3",
      KOOD: "1455",
      NIMETUS: "Audla küla",
      NIMETUS_LIIGIGA: "Audla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001455",
    },
    {
      TASE: "3",
      KOOD: "1456",
      NIMETUS: "Augli küla",
      NIMETUS_LIIGIGA: "Augli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001456",
    },
    {
      TASE: "3",
      KOOD: "1458",
      NIMETUS: "Audru alevik",
      NIMETUS_LIIGIGA: "Audru alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00001458",
    },
    {
      TASE: "3",
      KOOD: "1461",
      NIMETUS: "Auksi küla",
      NIMETUS_LIIGIGA: "Auksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00001461",
    },
    {
      TASE: "3",
      KOOD: "1463",
      NIMETUS: "Auküla",
      NIMETUS_LIIGIGA: "Auküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001463",
    },
    {
      TASE: "3",
      KOOD: "1466",
      NIMETUS: "Aula-Vintri küla",
      NIMETUS_LIIGIGA: "Aula-Vintri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001466",
    },
    {
      TASE: "3",
      KOOD: "1469",
      NIMETUS: "Aulepa küla / Dirslätt",
      NIMETUS_LIIGIGA: "Aulepa küla / Dirslätt",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00001469",
    },
    {
      TASE: "3",
      KOOD: "1470",
      NIMETUS: "Austla küla",
      NIMETUS_LIIGIGA: "Austla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001470",
    },
    {
      TASE: "3",
      KOOD: "1472",
      NIMETUS: "Auvere küla",
      NIMETUS_LIIGIGA: "Auvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00001472",
    },
    {
      TASE: "3",
      KOOD: "1476",
      NIMETUS: "Avanduse küla",
      NIMETUS_LIIGIGA: "Avanduse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00001476",
    },
    {
      TASE: "3",
      KOOD: "1478",
      NIMETUS: "Avaste küla",
      NIMETUS_LIIGIGA: "Avaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00001478",
    },
    {
      TASE: "3",
      KOOD: "1481",
      NIMETUS: "Avinurme alevik",
      NIMETUS_LIIGIGA: "Avinurme alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00001481",
    },
    {
      TASE: "3",
      KOOD: "1484",
      NIMETUS: "Avispea küla",
      NIMETUS_LIIGIGA: "Avispea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00001484",
    },
    {
      TASE: "3",
      KOOD: "1489",
      NIMETUS: "Beresje küla",
      NIMETUS_LIIGIGA: "Beresje küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00001489",
    },
    {
      TASE: "3",
      KOOD: "1493",
      NIMETUS: "Borrby küla",
      NIMETUS_LIIGIGA: "Borrby küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00001493",
    },
    {
      TASE: "3",
      KOOD: "1498",
      NIMETUS: "Karujärve küla",
      NIMETUS_LIIGIGA: "Karujärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001498",
    },
    {
      TASE: "3",
      KOOD: "1502",
      NIMETUS: "Diby küla",
      NIMETUS_LIIGIGA: "Diby küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00001502",
    },
    {
      TASE: "3",
      KOOD: "1505",
      NIMETUS: "Dirhami küla / Derhamn",
      NIMETUS_LIIGIGA: "Dirhami küla / Derhamn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00001505",
    },
    {
      TASE: "3",
      KOOD: "1506",
      NIMETUS: "Eametsa küla",
      NIMETUS_LIIGIGA: "Eametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00001506",
    },
    {
      TASE: "3",
      KOOD: "1510",
      NIMETUS: "Eametsa küla",
      NIMETUS_LIIGIGA: "Eametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00001510",
    },
    {
      TASE: "3",
      KOOD: "1513",
      NIMETUS: "Eassalu küla",
      NIMETUS_LIIGIGA: "Eassalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00001513",
    },
    {
      TASE: "3",
      KOOD: "1514",
      NIMETUS: "Easte küla",
      NIMETUS_LIIGIGA: "Easte küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001514",
    },
    {
      TASE: "3",
      KOOD: "1516",
      NIMETUS: "Eavere küla",
      NIMETUS_LIIGIGA: "Eavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00001516",
    },
    {
      TASE: "3",
      KOOD: "1524",
      NIMETUS: "Edise küla",
      NIMETUS_LIIGIGA: "Edise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "251",
      ADS_OID: "AY00001524",
    },
    {
      TASE: "3",
      KOOD: "1526",
      NIMETUS: "Edivere küla",
      NIMETUS_LIIGIGA: "Edivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00001526",
    },
    {
      TASE: "3",
      KOOD: "1527",
      NIMETUS: "Eense küla",
      NIMETUS_LIIGIGA: "Eense küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00001527",
    },
    {
      TASE: "3",
      KOOD: "1529",
      NIMETUS: "Edru küla",
      NIMETUS_LIIGIGA: "Edru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00001529",
    },
    {
      TASE: "3",
      KOOD: "1530",
      NIMETUS: "Eeriksaare küla",
      NIMETUS_LIIGIGA: "Eeriksaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001530",
    },
    {
      TASE: "3",
      KOOD: "1531",
      NIMETUS: "Eerma küla",
      NIMETUS_LIIGIGA: "Eerma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00001531",
    },
    {
      TASE: "3",
      KOOD: "1533",
      NIMETUS: "Eerikvere küla",
      NIMETUS_LIIGIGA: "Eerikvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00001533",
    },
    {
      TASE: "3",
      KOOD: "1534",
      NIMETUS: "Taga-Roostoja küla",
      NIMETUS_LIIGIGA: "Taga-Roostoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00001534",
    },
    {
      TASE: "3",
      KOOD: "1536",
      NIMETUS: "Eesküla",
      NIMETUS_LIIGIGA: "Eesküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00001536",
    },
    {
      TASE: "3",
      KOOD: "1539",
      NIMETUS: "Eesnurga küla",
      NIMETUS_LIIGIGA: "Eesnurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00001539",
    },
    {
      TASE: "3",
      KOOD: "1543",
      NIMETUS: "Ehavere küla",
      NIMETUS_LIIGIGA: "Ehavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00001543",
    },
    {
      TASE: "3",
      KOOD: "1546",
      NIMETUS: "Ehmja küla",
      NIMETUS_LIIGIGA: "Ehmja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00001546",
    },
    {
      TASE: "3",
      KOOD: "1550",
      NIMETUS: "Eidapere alevik",
      NIMETUS_LIIGIGA: "Eidapere alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00001550",
    },
    {
      TASE: "3",
      KOOD: "1553",
      NIMETUS: "Eikla küla",
      NIMETUS_LIIGIGA: "Eikla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001553",
    },
    {
      TASE: "3",
      KOOD: "1556",
      NIMETUS: "Einbi küla / Enby",
      NIMETUS_LIIGIGA: "Einbi küla / Enby",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00001556",
    },
    {
      TASE: "3",
      KOOD: "1559",
      NIMETUS: "Eipri küla",
      NIMETUS_LIIGIGA: "Eipri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00001559",
    },
    {
      TASE: "3",
      KOOD: "1562",
      NIMETUS: "Eisma küla",
      NIMETUS_LIIGIGA: "Eisma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001562",
    },
    {
      TASE: "3",
      KOOD: "1564",
      NIMETUS: "Eiste küla",
      NIMETUS_LIIGIGA: "Eiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001564",
    },
    {
      TASE: "3",
      KOOD: "1567",
      NIMETUS: "Eistvere küla",
      NIMETUS_LIIGIGA: "Eistvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00001567",
    },
    {
      TASE: "3",
      KOOD: "1570",
      NIMETUS: "Eivere küla",
      NIMETUS_LIIGIGA: "Eivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00001570",
    },
    {
      TASE: "3",
      KOOD: "1571",
      NIMETUS: "Elbiku küla / Ölbäck",
      NIMETUS_LIIGIGA: "Elbiku küla / Ölbäck",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00001571",
    },
    {
      TASE: "3",
      KOOD: "1574",
      NIMETUS: "Elbi küla",
      NIMETUS_LIIGIGA: "Elbi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00001574",
    },
    {
      TASE: "3",
      KOOD: "1576",
      NIMETUS: "Elbu küla",
      NIMETUS_LIIGIGA: "Elbu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00001576",
    },
    {
      TASE: "3",
      KOOD: "1579",
      NIMETUS: "Elistvere küla",
      NIMETUS_LIIGIGA: "Elistvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00001579",
    },
    {
      TASE: "3",
      KOOD: "1582",
      NIMETUS: "Ellakvere küla",
      NIMETUS_LIIGIGA: "Ellakvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00001582",
    },
    {
      TASE: "3",
      KOOD: "1583",
      NIMETUS: "Ellamaa küla",
      NIMETUS_LIIGIGA: "Ellamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00001583",
    },
    {
      TASE: "3",
      KOOD: "1585",
      NIMETUS: "Ellamaa küla",
      NIMETUS_LIIGIGA: "Ellamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00001585",
    },
    {
      TASE: "3",
      KOOD: "1589",
      NIMETUS: "Emmaste küla",
      NIMETUS_LIIGIGA: "Emmaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001589",
    },
    {
      TASE: "3",
      KOOD: "1591",
      NIMETUS: "Emmu küla",
      NIMETUS_LIIGIGA: "Emmu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00001591",
    },
    {
      TASE: "3",
      KOOD: "1592",
      NIMETUS: "Emmuvere küla",
      NIMETUS_LIIGIGA: "Emmuvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00001592",
    },
    {
      TASE: "3",
      KOOD: "1594",
      NIMETUS: "Emumäe küla",
      NIMETUS_LIIGIGA: "Emumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00001594",
    },
    {
      TASE: "3",
      KOOD: "1598",
      NIMETUS: "Endla küla",
      NIMETUS_LIIGIGA: "Endla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00001598",
    },
    {
      TASE: "3",
      KOOD: "1599",
      NIMETUS: "Endla küla",
      NIMETUS_LIIGIGA: "Endla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001599",
    },
    {
      TASE: "3",
      KOOD: "1600",
      NIMETUS: "Enivere küla",
      NIMETUS_LIIGIGA: "Enivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00001600",
    },
    {
      TASE: "3",
      KOOD: "1602",
      NIMETUS: "Enge küla",
      NIMETUS_LIIGIGA: "Enge küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00001602",
    },
    {
      TASE: "3",
      KOOD: "1603",
      NIMETUS: "Ännikse küla",
      NIMETUS_LIIGIGA: "Ännikse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00001603",
    },
    {
      TASE: "3",
      KOOD: "1605",
      NIMETUS: "Enno küla",
      NIMETUS_LIIGIGA: "Enno küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00001605",
    },
    {
      TASE: "3",
      KOOD: "1606",
      NIMETUS: "Ennu küla",
      NIMETUS_LIIGIGA: "Ennu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001606",
    },
    {
      TASE: "3",
      KOOD: "1609",
      NIMETUS: "Eoste küla",
      NIMETUS_LIIGIGA: "Eoste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00001609",
    },
    {
      TASE: "3",
      KOOD: "1613",
      NIMETUS: "Epra küla",
      NIMETUS_LIIGIGA: "Epra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00001613",
    },
    {
      TASE: "3",
      KOOD: "1617",
      NIMETUS: "Erala küla",
      NIMETUS_LIIGIGA: "Erala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00001617",
    },
    {
      TASE: "3",
      KOOD: "1623",
      NIMETUS: "Ereda küla",
      NIMETUS_LIIGIGA: "Ereda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00001623",
    },
    {
      TASE: "3",
      KOOD: "1624",
      NIMETUS: "Erja küla",
      NIMETUS_LIIGIGA: "Erja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00001624",
    },
    {
      TASE: "3",
      KOOD: "1625",
      NIMETUS: "Ereste küla",
      NIMETUS_LIIGIGA: "Ereste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00001625",
    },
    {
      TASE: "3",
      KOOD: "1626",
      NIMETUS: "Ermakova küla",
      NIMETUS_LIIGIGA: "Ermakova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00001626",
    },
    {
      TASE: "3",
      KOOD: "1628",
      NIMETUS: "Ermistu küla",
      NIMETUS_LIIGIGA: "Ermistu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00001628",
    },
    {
      TASE: "3",
      KOOD: "1629",
      NIMETUS: "Erra-Liiva küla",
      NIMETUS_LIIGIGA: "Erra-Liiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00001629",
    },
    {
      TASE: "3",
      KOOD: "1631",
      NIMETUS: "Erra alevik",
      NIMETUS_LIIGIGA: "Erra alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00001631",
    },
    {
      TASE: "3",
      KOOD: "1634",
      NIMETUS: "Ertsma küla",
      NIMETUS_LIIGIGA: "Ertsma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00001634",
    },
    {
      TASE: "3",
      KOOD: "1637",
      NIMETUS: "Eru küla",
      NIMETUS_LIIGIGA: "Eru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001637",
    },
    {
      TASE: "3",
      KOOD: "1640",
      NIMETUS: "Ervita küla",
      NIMETUS_LIIGIGA: "Ervita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00001640",
    },
    {
      TASE: "3",
      KOOD: "1643",
      NIMETUS: "Ervu küla",
      NIMETUS_LIIGIGA: "Ervu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00001643",
    },
    {
      TASE: "3",
      KOOD: "1647",
      NIMETUS: "Esiküla",
      NIMETUS_LIIGIGA: "Esiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001647",
    },
    {
      TASE: "3",
      KOOD: "1649",
      NIMETUS: "Esivere küla",
      NIMETUS_LIIGIGA: "Esivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00001649",
    },
    {
      TASE: "3",
      KOOD: "1652",
      NIMETUS: "Esku küla",
      NIMETUS_LIIGIGA: "Esku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00001652",
    },
    {
      TASE: "3",
      KOOD: "1653",
      NIMETUS: "Esna küla",
      NIMETUS_LIIGIGA: "Esna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00001653",
    },
    {
      TASE: "3",
      KOOD: "1655",
      NIMETUS: "Esna küla",
      NIMETUS_LIIGIGA: "Esna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00001655",
    },
    {
      TASE: "3",
      KOOD: "1658",
      NIMETUS: "Espre küla",
      NIMETUS_LIIGIGA: "Espre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00001658",
    },
    {
      TASE: "3",
      KOOD: "1661",
      NIMETUS: "Essu küla",
      NIMETUS_LIIGIGA: "Essu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001661",
    },
    {
      TASE: "3",
      KOOD: "1662",
      NIMETUS: "Fällarna küla",
      NIMETUS_LIIGIGA: "Fällarna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00001662",
    },
    {
      TASE: "3",
      KOOD: "1665",
      NIMETUS: "Etsaste küla",
      NIMETUS_LIIGIGA: "Etsaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00001665",
    },
    {
      TASE: "3",
      KOOD: "1670",
      NIMETUS: "Förby küla",
      NIMETUS_LIIGIGA: "Förby küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00001670",
    },
    {
      TASE: "3",
      KOOD: "1675",
      NIMETUS: "Haabneeme alevik",
      NIMETUS_LIIGIGA: "Haabneeme alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00001675",
    },
    {
      TASE: "3",
      KOOD: "1676",
      NIMETUS: "Haabsilla küla",
      NIMETUS_LIIGIGA: "Haabsilla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001676",
    },
    {
      TASE: "3",
      KOOD: "1678",
      NIMETUS: "Haabsaare küla",
      NIMETUS_LIIGIGA: "Haabsaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00001678",
    },
    {
        TASE: "3",
        KOOD: "EE3_52821",
        NIMETUS: "Tammelinn",
        NIMETUS_LIIGIGA: "Tammelinn",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52822",
        NIMETUS: "Ülejõe",
        NIMETUS_LIIGIGA: "Ülejõe",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52823",
        NIMETUS: "Vaksali",
        NIMETUS_LIIGIGA: "Vaksali",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52824",
        NIMETUS: "Vanalinn",
        NIMETUS_LIIGIGA: "Vanalinn",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52825",
        NIMETUS: "Variku",
        NIMETUS_LIIGIGA: "Variku",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52826",
        NIMETUS: "Veeriku",
        NIMETUS_LIIGIGA: "Veeriku",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52809",
        NIMETUS: "Annelinn",
        NIMETUS_LIIGIGA: "Annelinn",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52810",
        NIMETUS: "Ihaste",
        NIMETUS_LIIGIGA: "Ihaste",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },

    {
        TASE: "3",
        KOOD: "EE3_52811",
        NIMETUS: "Jaamamõisa",
        NIMETUS_LIIGIGA: "Jaamamõisa",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52812",
        NIMETUS: "Karlova",
        NIMETUS_LIIGIGA: "Karlova",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52813",
        NIMETUS: "Kesklinn",
        NIMETUS_LIIGIGA: "Kesklinn",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52814",
        NIMETUS: "Maarjamõisa",
        NIMETUS_LIIGIGA: "Maarjamõisa",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52815",
        NIMETUS: "Raadi-Kruusamäe",
        NIMETUS_LIIGIGA: "Raadi-Kruusamäe",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52816",
        NIMETUS: "Ränilinn",
        NIMETUS_LIIGIGA: "Ränilinn",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52817",
        NIMETUS: "Ropka",
        NIMETUS_LIIGIGA: "Ropka",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52818",
        NIMETUS: "Ropka tööstuse",
        NIMETUS_LIIGIGA: "Ropka tööstuse",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52819",
        NIMETUS: "Supilinn",
        NIMETUS_LIIGIGA: "Supilinn",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
        TASE: "3",
        KOOD: "EE3_52820",
        NIMETUS: "Tähtvere",
        NIMETUS_LIIGIGA: "Tähtvere",
        YLEMKOMP_TASE: "2",
        YLEMKOMP_KOOD: "793",
        ADS_OID: "AY03658881",
    },
    {
      TASE: "3",
      KOOD: "1681",
      NIMETUS: "Haage küla",
      NIMETUS_LIIGIGA: "Haage küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "793",
      ADS_OID: "AY00001681",
    },
    {
      TASE: "3",
      KOOD: "1684",
      NIMETUS: "Haakla küla",
      NIMETUS_LIIGIGA: "Haakla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00001684",
    },
    {
      TASE: "3",
      KOOD: "1686",
      NIMETUS: "Haamse küla",
      NIMETUS_LIIGIGA: "Haamse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001686",
    },
    {
      TASE: "3",
      KOOD: "1689",
      NIMETUS: "Haanja küla",
      NIMETUS_LIIGIGA: "Haanja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001689",
    },
    {
      TASE: "3",
      KOOD: "1690",
      NIMETUS: "Haapsi küla",
      NIMETUS_LIIGIGA: "Haapsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00001690",
    },
    {
      TASE: "3",
      KOOD: "1691",
      NIMETUS: "Haapse küla",
      NIMETUS_LIIGIGA: "Haapse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00001691",
    },
    {
      TASE: "3",
      KOOD: "1694",
      NIMETUS: "Haapsipea küla",
      NIMETUS_LIIGIGA: "Haapsipea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00001694",
    },
    {
      TASE: "3",
      KOOD: "1695",
      NIMETUS: "Haapsu küla",
      NIMETUS_LIIGIGA: "Haapsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001695",
    },
    {
      TASE: "3",
      KOOD: "1696",
      NIMETUS: "Haaslava küla",
      NIMETUS_LIIGIGA: "Haaslava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00001696",
    },
    {
      TASE: "3",
      KOOD: "1697",
      NIMETUS: "Haava küla",
      NIMETUS_LIIGIGA: "Haava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001697",
    },
    {
      TASE: "3",
      KOOD: "1699",
      NIMETUS: "Haava küla",
      NIMETUS_LIIGIGA: "Haava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00001699",
    },
    {
      TASE: "3",
      KOOD: "1701",
      NIMETUS: "Haavakannu küla",
      NIMETUS_LIIGIGA: "Haavakannu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00001701",
    },
    {
      TASE: "3",
      KOOD: "1702",
      NIMETUS: "Haavakivi küla",
      NIMETUS_LIIGIGA: "Haavakivi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00001702",
    },
    {
      TASE: "3",
      KOOD: "1703",
      NIMETUS: "Haavistu küla",
      NIMETUS_LIIGIGA: "Haavistu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001703",
    },
    {
      TASE: "3",
      KOOD: "1705",
      NIMETUS: "Haavametsa küla",
      NIMETUS_LIIGIGA: "Haavametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00001705",
    },
    {
      TASE: "3",
      KOOD: "1706",
      NIMETUS: "Haavapää küla",
      NIMETUS_LIIGIGA: "Haavapää küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00001706",
    },
    {
      TASE: "3",
      KOOD: "1708",
      NIMETUS: "Habaja alevik",
      NIMETUS_LIIGIGA: "Habaja alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00001708",
    },
    {
      TASE: "3",
      KOOD: "1711",
      NIMETUS: "Haeska küla",
      NIMETUS_LIIGIGA: "Haeska küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00001711",
    },
    {
      TASE: "3",
      KOOD: "1712",
      NIMETUS: "Haeska küla",
      NIMETUS_LIIGIGA: "Haeska küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001712",
    },
    {
      TASE: "3",
      KOOD: "1713",
      NIMETUS: "Hagaste küla",
      NIMETUS_LIIGIGA: "Hagaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001713",
    },
    {
      TASE: "3",
      KOOD: "1714",
      NIMETUS: "Hageri alevik",
      NIMETUS_LIIGIGA: "Hageri alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00001714",
    },
    {
      TASE: "3",
      KOOD: "1715",
      NIMETUS: "Hageri küla",
      NIMETUS_LIIGIGA: "Hageri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00001715",
    },
    {
      TASE: "3",
      KOOD: "1716",
      NIMETUS: "Hagudi alevik",
      NIMETUS_LIIGIGA: "Hagudi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00001716",
    },
    {
      TASE: "3",
      KOOD: "1717",
      NIMETUS: "Hagudi küla",
      NIMETUS_LIIGIGA: "Hagudi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00001717",
    },
    {
      TASE: "3",
      KOOD: "1720",
      NIMETUS: "Haiba küla",
      NIMETUS_LIIGIGA: "Haiba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00001720",
    },
    {
      TASE: "3",
      KOOD: "1723",
      NIMETUS: "Haili küla",
      NIMETUS_LIIGIGA: "Haili küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001723",
    },
    {
      TASE: "3",
      KOOD: "1725",
      NIMETUS: "Haimre küla",
      NIMETUS_LIIGIGA: "Haimre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00001725",
    },
    {
      TASE: "3",
      KOOD: "1728",
      NIMETUS: "Haki küla",
      NIMETUS_LIIGIGA: "Haki küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001728",
    },
    {
      TASE: "3",
      KOOD: "1731",
      NIMETUS: "Hakjala küla",
      NIMETUS_LIIGIGA: "Hakjala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001731",
    },
    {
      TASE: "3",
      KOOD: "1732",
      NIMETUS: "Haldreka küla",
      NIMETUS_LIIGIGA: "Haldreka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001732",
    },
    {
      TASE: "3",
      KOOD: "1734",
      NIMETUS: "Haldi küla",
      NIMETUS_LIIGIGA: "Haldi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001734",
    },
    {
      TASE: "3",
      KOOD: "1736",
      NIMETUS: "Halinga küla",
      NIMETUS_LIIGIGA: "Halinga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00001736",
    },
    {
      TASE: "3",
      KOOD: "1739",
      NIMETUS: "Haljala alevik",
      NIMETUS_LIIGIGA: "Haljala alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001739",
    },
    {
      TASE: "3",
      KOOD: "1741",
      NIMETUS: "Haljava küla",
      NIMETUS_LIIGIGA: "Haljava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00001741",
    },
    {
      TASE: "3",
      KOOD: "1744",
      NIMETUS: "Halla küla",
      NIMETUS_LIIGIGA: "Halla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001744",
    },
    {
      TASE: "3",
      KOOD: "1745",
      NIMETUS: "Hallimäe küla",
      NIMETUS_LIIGIGA: "Hallimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001745",
    },
    {
      TASE: "3",
      KOOD: "1746",
      NIMETUS: "Halliku küla",
      NIMETUS_LIIGIGA: "Halliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00001746",
    },
    {
      TASE: "3",
      KOOD: "1749",
      NIMETUS: "Halliste alevik",
      NIMETUS_LIIGIGA: "Halliste alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00001749",
    },
    {
      TASE: "3",
      KOOD: "1750",
      NIMETUS: "Hanija küla",
      NIMETUS_LIIGIGA: "Hanija küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001750",
    },
    {
      TASE: "3",
      KOOD: "1751",
      NIMETUS: "Haamaste küla",
      NIMETUS_LIIGIGA: "Haamaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001751",
    },
    {
      TASE: "3",
      KOOD: "1752",
      NIMETUS: "Hammaste küla",
      NIMETUS_LIIGIGA: "Hammaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00001752",
    },
    {
      TASE: "3",
      KOOD: "1753",
      NIMETUS: "Handimiku küla",
      NIMETUS_LIIGIGA: "Handimiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001753",
    },
    {
      TASE: "3",
      KOOD: "1755",
      NIMETUS: "Hanikase küla",
      NIMETUS_LIIGIGA: "Hanikase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001755",
    },
    {
      TASE: "3",
      KOOD: "1756",
      NIMETUS: "Hapsu küla",
      NIMETUS_LIIGIGA: "Hapsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001756",
    },
    {
      TASE: "3",
      KOOD: "1757",
      NIMETUS: "Hanila küla",
      NIMETUS_LIIGIGA: "Hanila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00001757",
    },
    {
      TASE: "3",
      KOOD: "1758",
      NIMETUS: "Hansi küla",
      NIMETUS_LIIGIGA: "Hansi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001758",
    },
    {
      TASE: "3",
      KOOD: "1759",
      NIMETUS: "Hannuste küla",
      NIMETUS_LIIGIGA: "Hannuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001759",
    },
    {
      TASE: "3",
      KOOD: "1760",
      NIMETUS: "Hara küla / Harga",
      NIMETUS_LIIGIGA: "Hara küla / Harga",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00001760",
    },
    {
      TASE: "3",
      KOOD: "1761",
      NIMETUS: "Hara küla",
      NIMETUS_LIIGIGA: "Hara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00001761",
    },
    {
      TASE: "3",
      KOOD: "1764",
      NIMETUS: "Hargi küla",
      NIMETUS_LIIGIGA: "Hargi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001764",
    },
    {
      TASE: "3",
      KOOD: "1766",
      NIMETUS: "Hargla küla",
      NIMETUS_LIIGIGA: "Hargla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00001766",
    },
    {
      TASE: "3",
      KOOD: "1769",
      NIMETUS: "Harju küla",
      NIMETUS_LIIGIGA: "Harju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001769",
    },
    {
      TASE: "3",
      KOOD: "1771",
      NIMETUS: "Harju-Risti küla",
      NIMETUS_LIIGIGA: "Harju-Risti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00001771",
    },
    {
      TASE: "3",
      KOOD: "1774",
      NIMETUS: "Harku alevik",
      NIMETUS_LIIGIGA: "Harku alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00001774",
    },
    {
      TASE: "3",
      KOOD: "1776",
      NIMETUS: "Harkujärve küla",
      NIMETUS_LIIGIGA: "Harkujärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00001776",
    },
    {
      TASE: "3",
      KOOD: "1779",
      NIMETUS: "Harmi küla",
      NIMETUS_LIIGIGA: "Harmi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00001779",
    },
    {
      TASE: "3",
      KOOD: "1782",
      NIMETUS: "Hatu küla",
      NIMETUS_LIIGIGA: "Hatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00001782",
    },
    {
      TASE: "3",
      KOOD: "1785",
      NIMETUS: "Hauka küla",
      NIMETUS_LIIGIGA: "Hauka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00001785",
    },
    {
      TASE: "3",
      KOOD: "1786",
      NIMETUS: "Heeska küla",
      NIMETUS_LIIGIGA: "Heeska küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001786",
    },
    {
      TASE: "3",
      KOOD: "1787",
      NIMETUS: "Hausma küla",
      NIMETUS_LIIGIGA: "Hausma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001787",
    },
    {
      TASE: "3",
      KOOD: "1788",
      NIMETUS: "Heigi küla",
      NIMETUS_LIIGIGA: "Heigi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001788",
    },
    {
      TASE: "3",
      KOOD: "1789",
      NIMETUS: "Heedu küla",
      NIMETUS_LIIGIGA: "Heedu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001789",
    },
    {
      TASE: "3",
      KOOD: "1791",
      NIMETUS: "Heibri küla",
      NIMETUS_LIIGIGA: "Heibri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001791",
    },
    {
      TASE: "3",
      KOOD: "1794",
      NIMETUS: "Heimtali küla",
      NIMETUS_LIIGIGA: "Heimtali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00001794",
    },
    {
      TASE: "3",
      KOOD: "1796",
      NIMETUS: "Heinasoo küla",
      NIMETUS_LIIGIGA: "Heinasoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001796",
    },
    {
      TASE: "3",
      KOOD: "1799",
      NIMETUS: "Heisri küla",
      NIMETUS_LIIGIGA: "Heisri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00001799",
    },
    {
      TASE: "3",
      KOOD: "1800",
      NIMETUS: "Heistesoo küla",
      NIMETUS_LIIGIGA: "Heistesoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001800",
    },
    {
      TASE: "3",
      KOOD: "1801",
      NIMETUS: "Heiste küla",
      NIMETUS_LIIGIGA: "Heiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001801",
    },
    {
      TASE: "3",
      KOOD: "1802",
      NIMETUS: "Helenurme küla",
      NIMETUS_LIIGIGA: "Helenurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00001802",
    },
    {
      TASE: "3",
      KOOD: "1804",
      NIMETUS: "Helbi küla",
      NIMETUS_LIIGIGA: "Helbi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00001804",
    },
    {
      TASE: "3",
      KOOD: "1807",
      NIMETUS: "Hellamaa küla",
      NIMETUS_LIIGIGA: "Hellamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001807",
    },
    {
      TASE: "3",
      KOOD: "1808",
      NIMETUS: "Hellamaa küla",
      NIMETUS_LIIGIGA: "Hellamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00001808",
    },
    {
      TASE: "3",
      KOOD: "1810",
      NIMETUS: "Hellekunnu küla",
      NIMETUS_LIIGIGA: "Hellekunnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001810",
    },
    {
      TASE: "3",
      KOOD: "1813",
      NIMETUS: "Hellenurme küla",
      NIMETUS_LIIGIGA: "Hellenurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00001813",
    },
    {
      TASE: "3",
      KOOD: "1814",
      NIMETUS: "Helmküla",
      NIMETUS_LIIGIGA: "Helmküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00001814",
    },
    {
      TASE: "3",
      KOOD: "1815",
      NIMETUS: "Helme alevik",
      NIMETUS_LIIGIGA: "Helme alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00001815",
    },
    {
      TASE: "3",
      KOOD: "1818",
      NIMETUS: "Heltermaa küla",
      NIMETUS_LIIGIGA: "Heltermaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001818",
    },
    {
      TASE: "3",
      KOOD: "1821",
      NIMETUS: "Hendrikumõisa küla",
      NIMETUS_LIIGIGA: "Hendrikumõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00001821",
    },
    {
      TASE: "3",
      KOOD: "1824",
      NIMETUS: "Herjava küla",
      NIMETUS_LIIGIGA: "Herjava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00001824",
    },
    {
      TASE: "3",
      KOOD: "1826",
      NIMETUS: "Hertu küla",
      NIMETUS_LIIGIGA: "Hertu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00001826",
    },
    {
      TASE: "3",
      KOOD: "1830",
      NIMETUS: "Hiie küla",
      NIMETUS_LIIGIGA: "Hiie küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00001830",
    },
    {
      TASE: "3",
      KOOD: "1833",
      NIMETUS: "Hiiemetsa küla",
      NIMETUS_LIIGIGA: "Hiiemetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00001833",
    },
    {
      TASE: "3",
      KOOD: "1834",
      NIMETUS: "Hiietse küla",
      NIMETUS_LIIGIGA: "Hiietse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00001834",
    },
    {
      TASE: "3",
      KOOD: "1835",
      NIMETUS: "Hiiessaare küla",
      NIMETUS_LIIGIGA: "Hiiessaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001835",
    },
    {
      TASE: "3",
      KOOD: "1836",
      NIMETUS: "Hiievälja küla",
      NIMETUS_LIIGIGA: "Hiievälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001836",
    },
    {
      TASE: "3",
      KOOD: "1838",
      NIMETUS: "Hilana küla",
      NIMETUS_LIIGIGA: "Hilana küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00001838",
    },
    {
      TASE: "3",
      KOOD: "1839",
      NIMETUS: "Hilläkeste küla",
      NIMETUS_LIIGIGA: "Hilläkeste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00001839",
    },
    {
      TASE: "3",
      KOOD: "1841",
      NIMETUS: "Hilleste küla",
      NIMETUS_LIIGIGA: "Hilleste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001841",
    },
    {
      TASE: "3",
      KOOD: "1844",
      NIMETUS: "Himma küla",
      NIMETUS_LIIGIGA: "Himma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00001844",
    },
    {
      TASE: "3",
      KOOD: "1846",
      NIMETUS: "Himmaste küla",
      NIMETUS_LIIGIGA: "Himmaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00001846",
    },
    {
      TASE: "3",
      KOOD: "1849",
      NIMETUS: "Himmiste küla",
      NIMETUS_LIIGIGA: "Himmiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00001849",
    },
    {
      TASE: "3",
      KOOD: "1850",
      NIMETUS: "Himmiste küla",
      NIMETUS_LIIGIGA: "Himmiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001850",
    },
    {
      TASE: "3",
      KOOD: "1851",
      NIMETUS: "Hindu küla",
      NIMETUS_LIIGIGA: "Hindu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001851",
    },
    {
      TASE: "3",
      KOOD: "1852",
      NIMETUS: "Hindaste küla",
      NIMETUS_LIIGIGA: "Hindaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00001852",
    },
    {
      TASE: "3",
      KOOD: "1853",
      NIMETUS: "Hindu küla",
      NIMETUS_LIIGIGA: "Hindu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001853",
    },
    {
      TASE: "3",
      KOOD: "1854",
      NIMETUS: "Hingu küla",
      NIMETUS_LIIGIGA: "Hingu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00001854",
    },
    {
      TASE: "3",
      KOOD: "1856",
      NIMETUS: "Hinniala küla",
      NIMETUS_LIIGIGA: "Hinniala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001856",
    },
    {
      TASE: "3",
      KOOD: "1857",
      NIMETUS: "Hino küla",
      NIMETUS_LIIGIGA: "Hino küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00001857",
    },
    {
      TASE: "3",
      KOOD: "1858",
      NIMETUS: "Hino küla",
      NIMETUS_LIIGIGA: "Hino küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001858",
    },
    {
      TASE: "3",
      KOOD: "1859",
      NIMETUS: "Hindsa küla",
      NIMETUS_LIIGIGA: "Hindsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00001859",
    },
    {
      TASE: "3",
      KOOD: "1860",
      NIMETUS: "Hinsa küla",
      NIMETUS_LIIGIGA: "Hinsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001860",
    },
    {
      TASE: "3",
      KOOD: "1861",
      NIMETUS: "Hinu küla",
      NIMETUS_LIIGIGA: "Hinu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001861",
    },
    {
      TASE: "3",
      KOOD: "1863",
      NIMETUS: "Hintsiko küla",
      NIMETUS_LIIGIGA: "Hintsiko küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001863",
    },
    {
      TASE: "3",
      KOOD: "1866",
      NIMETUS: "Hirla küla",
      NIMETUS_LIIGIGA: "Hirla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00001866",
    },
    {
      TASE: "3",
      KOOD: "1868",
      NIMETUS: "Hirmuküla",
      NIMETUS_LIIGIGA: "Hirmuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00001868",
    },
    {
      TASE: "3",
      KOOD: "1871",
      NIMETUS: "Hirmuse küla",
      NIMETUS_LIIGIGA: "Hirmuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00001871",
    },
    {
      TASE: "3",
      KOOD: "1873",
      NIMETUS: "Hirmuste küla",
      NIMETUS_LIIGIGA: "Hirmuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001873",
    },
    {
      TASE: "3",
      KOOD: "1874",
      NIMETUS: "Hirmuste küla",
      NIMETUS_LIIGIGA: "Hirmuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001874",
    },
    {
      TASE: "3",
      KOOD: "1875",
      NIMETUS: "Hobulaiu küla",
      NIMETUS_LIIGIGA: "Hobulaiu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00001875",
    },
    {
      TASE: "3",
      KOOD: "1876",
      NIMETUS: "Holdi küla",
      NIMETUS_LIIGIGA: "Holdi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00001876",
    },
    {
      TASE: "3",
      KOOD: "1877",
      NIMETUS: "Hirvli küla",
      NIMETUS_LIIGIGA: "Hirvli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00001877",
    },
    {
      TASE: "3",
      KOOD: "1881",
      NIMETUS: "Holdi küla",
      NIMETUS_LIIGIGA: "Holdi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001881",
    },
    {
      TASE: "3",
      KOOD: "1883",
      NIMETUS: "Holdre küla",
      NIMETUS_LIIGIGA: "Holdre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00001883",
    },
    {
      TASE: "3",
      KOOD: "1886",
      NIMETUS: "Holsta küla",
      NIMETUS_LIIGIGA: "Holsta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001886",
    },
    {
      TASE: "3",
      KOOD: "1888",
      NIMETUS: "Holstre küla",
      NIMETUS_LIIGIGA: "Holstre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00001888",
    },
    {
      TASE: "3",
      KOOD: "1889",
      NIMETUS: "Hosby küla",
      NIMETUS_LIIGIGA: "Hosby küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00001889",
    },
    {
      TASE: "3",
      KOOD: "1890",
      NIMETUS: "Horoski küla",
      NIMETUS_LIIGIGA: "Horoski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001890",
    },
    {
      TASE: "3",
      KOOD: "1892",
      NIMETUS: "Hosby küla",
      NIMETUS_LIIGIGA: "Hosby küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00001892",
    },
    {
      TASE: "3",
      KOOD: "1893",
      NIMETUS: "Horosuu küla",
      NIMETUS_LIIGIGA: "Horosuu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001893",
    },
    {
      TASE: "3",
      KOOD: "1894",
      NIMETUS: "Horma küla",
      NIMETUS_LIIGIGA: "Horma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001894",
    },
    {
      TASE: "3",
      KOOD: "1895",
      NIMETUS: "Hulaku küla",
      NIMETUS_LIIGIGA: "Hulaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001895",
    },
    {
      TASE: "3",
      KOOD: "1896",
      NIMETUS: "Hotõmäe küla",
      NIMETUS_LIIGIGA: "Hotõmäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001896",
    },
    {
      TASE: "3",
      KOOD: "1897",
      NIMETUS: "Hulja alevik",
      NIMETUS_LIIGIGA: "Hulja alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00001897",
    },
    {
      TASE: "3",
      KOOD: "1898",
      NIMETUS: "Horsa küla",
      NIMETUS_LIIGIGA: "Horsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001898",
    },
    {
      TASE: "3",
      KOOD: "1900",
      NIMETUS: "Hullo küla",
      NIMETUS_LIIGIGA: "Hullo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00001900",
    },
    {
      TASE: "3",
      KOOD: "1903",
      NIMETUS: "Humala küla",
      NIMETUS_LIIGIGA: "Humala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00001903",
    },
    {
      TASE: "3",
      KOOD: "1905",
      NIMETUS: "Hummuli alevik",
      NIMETUS_LIIGIGA: "Hummuli alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00001905",
    },
    {
      TASE: "3",
      KOOD: "1908",
      NIMETUS: "Hundinurga küla",
      NIMETUS_LIIGIGA: "Hundinurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00001908",
    },
    {
      TASE: "3",
      KOOD: "1911",
      NIMETUS: "Hurda küla",
      NIMETUS_LIIGIGA: "Hurda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001911",
    },
    {
      TASE: "3",
      KOOD: "1914",
      NIMETUS: "Hurmi küla",
      NIMETUS_LIIGIGA: "Hurmi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00001914",
    },
    {
      TASE: "3",
      KOOD: "1917",
      NIMETUS: "Husari küla",
      NIMETUS_LIIGIGA: "Husari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001917",
    },
    {
      TASE: "3",
      KOOD: "1920",
      NIMETUS: "Huuksi küla",
      NIMETUS_LIIGIGA: "Huuksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00001920",
    },
    {
      TASE: "3",
      KOOD: "1921",
      NIMETUS: "Hõbeda küla",
      NIMETUS_LIIGIGA: "Hõbeda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00001921",
    },
    {
      TASE: "3",
      KOOD: "1924",
      NIMETUS: "Hõbeda küla",
      NIMETUS_LIIGIGA: "Hõbeda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00001924",
    },
    {
      TASE: "3",
      KOOD: "1926",
      NIMETUS: "Hõbemäe küla",
      NIMETUS_LIIGIGA: "Hõbemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00001926",
    },
    {
      TASE: "3",
      KOOD: "1929",
      NIMETUS: "Hõbesalu küla",
      NIMETUS_LIIGIGA: "Hõbesalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00001929",
    },
    {
      TASE: "3",
      KOOD: "1933",
      NIMETUS: "Hämkoti küla",
      NIMETUS_LIIGIGA: "Hämkoti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001933",
    },
    {
      TASE: "3",
      KOOD: "1936",
      NIMETUS: "Hälvati küla",
      NIMETUS_LIIGIGA: "Hälvati küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00001936",
    },
    {
      TASE: "3",
      KOOD: "1939",
      NIMETUS: "Hämmelepa küla",
      NIMETUS_LIIGIGA: "Hämmelepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001939",
    },
    {
      TASE: "3",
      KOOD: "1940",
      NIMETUS: "Hänga küla",
      NIMETUS_LIIGIGA: "Hänga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001940",
    },
    {
      TASE: "3",
      KOOD: "1942",
      NIMETUS: "Hänike küla",
      NIMETUS_LIIGIGA: "Hänike küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00001942",
    },
    {
      TASE: "3",
      KOOD: "1947",
      NIMETUS: "Härjadi küla",
      NIMETUS_LIIGIGA: "Härjadi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00001947",
    },
    {
      TASE: "3",
      KOOD: "1950",
      NIMETUS: "Härjanurme küla",
      NIMETUS_LIIGIGA: "Härjanurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00001950",
    },
    {
      TASE: "3",
      KOOD: "1951",
      NIMETUS: "Härjanurme küla",
      NIMETUS_LIIGIGA: "Härjanurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00001951",
    },
    {
      TASE: "3",
      KOOD: "1952",
      NIMETUS: "Härma küla",
      NIMETUS_LIIGIGA: "Härma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001952",
    },
    {
      TASE: "3",
      KOOD: "1953",
      NIMETUS: "Härmä küla",
      NIMETUS_LIIGIGA: "Härmä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00001953",
    },
    {
      TASE: "3",
      KOOD: "1954",
      NIMETUS: "Härma küla",
      NIMETUS_LIIGIGA: "Härma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00001954",
    },
    {
      TASE: "3",
      KOOD: "1956",
      NIMETUS: "Härämäe küla",
      NIMETUS_LIIGIGA: "Härämäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001956",
    },
    {
      TASE: "3",
      KOOD: "1957",
      NIMETUS: "Häädemeeste alevik",
      NIMETUS_LIIGIGA: "Häädemeeste alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00001957",
    },
    {
      TASE: "3",
      KOOD: "1960",
      NIMETUS: "Häätaru küla",
      NIMETUS_LIIGIGA: "Häätaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00001960",
    },
    {
      TASE: "3",
      KOOD: "1961",
      NIMETUS: "Härmakosu küla",
      NIMETUS_LIIGIGA: "Härmakosu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00001961",
    },
    {
      TASE: "3",
      KOOD: "1964",
      NIMETUS: "Höbringi küla / Höbring",
      NIMETUS_LIIGIGA: "Höbringi küla / Höbring",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00001964",
    },
    {
      TASE: "3",
      KOOD: "1965",
      NIMETUS: "Hübja küla",
      NIMETUS_LIIGIGA: "Hübja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00001965",
    },
    {
      TASE: "3",
      KOOD: "1966",
      NIMETUS: "Hürsi küla",
      NIMETUS_LIIGIGA: "Hürsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001966",
    },
    {
      TASE: "3",
      KOOD: "1968",
      NIMETUS: "Hürova küla",
      NIMETUS_LIIGIGA: "Hürova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001968",
    },
    {
      TASE: "3",
      KOOD: "1971",
      NIMETUS: "Hüti küla",
      NIMETUS_LIIGIGA: "Hüti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00001971",
    },
    {
      TASE: "3",
      KOOD: "1972",
      NIMETUS: "Hüti küla",
      NIMETUS_LIIGIGA: "Hüti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00001972",
    },
    {
      TASE: "3",
      KOOD: "1975",
      NIMETUS: "Hüüru küla",
      NIMETUS_LIIGIGA: "Hüüru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00001975",
    },
    {
      TASE: "3",
      KOOD: "1980",
      NIMETUS: "Ibaste küla",
      NIMETUS_LIIGIGA: "Ibaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00001980",
    },
    {
      TASE: "3",
      KOOD: "1984",
      NIMETUS: "Idaotsa küla",
      NIMETUS_LIIGIGA: "Idaotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00001984",
    },
    {
      TASE: "3",
      KOOD: "1986",
      NIMETUS: "Idavere küla",
      NIMETUS_LIIGIGA: "Idavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00001986",
    },
    {
      TASE: "3",
      KOOD: "1990",
      NIMETUS: "Igaküla",
      NIMETUS_LIIGIGA: "Igaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00001990",
    },
    {
      TASE: "3",
      KOOD: "1993",
      NIMETUS: "Igavere küla",
      NIMETUS_LIIGIGA: "Igavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00001993",
    },
    {
      TASE: "3",
      KOOD: "1994",
      NIMETUS: "Igavere küla",
      NIMETUS_LIIGIGA: "Igavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00001994",
    },
    {
      TASE: "3",
      KOOD: "1997",
      NIMETUS: "Igevere küla",
      NIMETUS_LIIGIGA: "Igevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00001997",
    },
    {
      TASE: "3",
      KOOD: "1998",
      NIMETUS: "Ignasõ küla",
      NIMETUS_LIIGIGA: "Ignasõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00001998",
    },
    {
      TASE: "3",
      KOOD: "2000",
      NIMETUS: "Ignase küla",
      NIMETUS_LIIGIGA: "Ignase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00002000",
    },
    {
      TASE: "3",
      KOOD: "2003",
      NIMETUS: "Igrise küla",
      NIMETUS_LIIGIGA: "Igrise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00002003",
    },
    {
      TASE: "3",
      KOOD: "2007",
      NIMETUS: "Ihamaru küla",
      NIMETUS_LIIGIGA: "Ihamaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00002007",
    },
    {
      TASE: "3",
      KOOD: "2008",
      NIMETUS: "Ihatsi küla",
      NIMETUS_LIIGIGA: "Ihatsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002008",
    },
    {
      TASE: "3",
      KOOD: "2009",
      NIMETUS: "Ihasalu küla",
      NIMETUS_LIIGIGA: "Ihasalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00002009",
    },
    {
      TASE: "3",
      KOOD: "2013",
      NIMETUS: "Iia küla",
      NIMETUS_LIIGIGA: "Iia küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00002013",
    },
    {
      TASE: "3",
      KOOD: "2014",
      NIMETUS: "Iide küla",
      NIMETUS_LIIGIGA: "Iide küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002014",
    },
    {
      TASE: "3",
      KOOD: "2016",
      NIMETUS: "Iigaste küla",
      NIMETUS_LIIGIGA: "Iigaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00002016",
    },
    {
      TASE: "3",
      KOOD: "2019",
      NIMETUS: "Iila küla",
      NIMETUS_LIIGIGA: "Iila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00002019",
    },
    {
      TASE: "3",
      KOOD: "2020",
      NIMETUS: "Iira küla",
      NIMETUS_LIIGIGA: "Iira küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00002020",
    },
    {
      TASE: "3",
      KOOD: "2022",
      NIMETUS: "Iilaste küla",
      NIMETUS_LIIGIGA: "Iilaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002022",
    },
    {
      TASE: "3",
      KOOD: "2025",
      NIMETUS: "Iisaku alevik",
      NIMETUS_LIIGIGA: "Iisaku alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002025",
    },
    {
      TASE: "3",
      KOOD: "2029",
      NIMETUS: "Ikla küla",
      NIMETUS_LIIGIGA: "Ikla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00002029",
    },
    {
      TASE: "3",
      KOOD: "2033",
      NIMETUS: "Ilbaku küla",
      NIMETUS_LIIGIGA: "Ilbaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00002033",
    },
    {
      TASE: "3",
      KOOD: "2036",
      NIMETUS: "Ilistvere küla",
      NIMETUS_LIIGIGA: "Ilistvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00002036",
    },
    {
      TASE: "3",
      KOOD: "2039",
      NIMETUS: "Illi küla",
      NIMETUS_LIIGIGA: "Illi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00002039",
    },
    {
      TASE: "3",
      KOOD: "2040",
      NIMETUS: "Illi küla",
      NIMETUS_LIIGIGA: "Illi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002040",
    },
    {
      TASE: "3",
      KOOD: "2042",
      NIMETUS: "Illuka küla",
      NIMETUS_LIIGIGA: "Illuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002042",
    },
    {
      TASE: "3",
      KOOD: "2045",
      NIMETUS: "Illurma küla",
      NIMETUS_LIIGIGA: "Illurma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00002045",
    },
    {
      TASE: "3",
      KOOD: "2046",
      NIMETUS: "Ilmastalu küla",
      NIMETUS_LIIGIGA: "Ilmastalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00002046",
    },
    {
      TASE: "3",
      KOOD: "2049",
      NIMETUS: "Ilmatsalu küla",
      NIMETUS_LIIGIGA: "Ilmatsalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "793",
      ADS_OID: "AY00002049",
    },
    {
      TASE: "3",
      KOOD: "2050",
      NIMETUS: "Ilmatsalu alevik",
      NIMETUS_LIIGIGA: "Ilmatsalu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "793",
      ADS_OID: "AY00002050",
    },
    {
      TASE: "3",
      KOOD: "2051",
      NIMETUS: "Ilmaste küla",
      NIMETUS_LIIGIGA: "Ilmaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00002051",
    },
    {
      TASE: "3",
      KOOD: "2053",
      NIMETUS: "Ilmjärve küla",
      NIMETUS_LIIGIGA: "Ilmjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00002053",
    },
    {
      TASE: "3",
      KOOD: "2056",
      NIMETUS: "Ilpla küla",
      NIMETUS_LIIGIGA: "Ilpla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002056",
    },
    {
      TASE: "3",
      KOOD: "2059",
      NIMETUS: "Ilumäe küla",
      NIMETUS_LIIGIGA: "Ilumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00002059",
    },
    {
      TASE: "3",
      KOOD: "2062",
      NIMETUS: "Ilvese küla",
      NIMETUS_LIIGIGA: "Ilvese küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00002062",
    },
    {
      TASE: "3",
      KOOD: "2066",
      NIMETUS: "Imara küla",
      NIMETUS_LIIGIGA: "Imara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002066",
    },
    {
      TASE: "3",
      KOOD: "2068",
      NIMETUS: "Imastu küla",
      NIMETUS_LIIGIGA: "Imastu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00002068",
    },
    {
      TASE: "3",
      KOOD: "2071",
      NIMETUS: "Imatu küla",
      NIMETUS_LIIGIGA: "Imatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002071",
    },
    {
      TASE: "3",
      KOOD: "2073",
      NIMETUS: "Imavere küla",
      NIMETUS_LIIGIGA: "Imavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002073",
    },
    {
      TASE: "3",
      KOOD: "2074",
      NIMETUS: "Imavere küla",
      NIMETUS_LIIGIGA: "Imavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002074",
    },
    {
      TASE: "3",
      KOOD: "2076",
      NIMETUS: "Imste küla",
      NIMETUS_LIIGIGA: "Imste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00002076",
    },
    {
      TASE: "3",
      KOOD: "2079",
      NIMETUS: "Imukvere küla",
      NIMETUS_LIIGIGA: "Imukvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00002079",
    },
    {
      TASE: "3",
      KOOD: "2080",
      NIMETUS: "Imukvere küla",
      NIMETUS_LIIGIGA: "Imukvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00002080",
    },
    {
      TASE: "3",
      KOOD: "2081",
      NIMETUS: "Inda küla",
      NIMETUS_LIIGIGA: "Inda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00002081",
    },
    {
      TASE: "3",
      KOOD: "2082",
      NIMETUS: "Indra küla",
      NIMETUS_LIIGIGA: "Indra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002082",
    },
    {
      TASE: "3",
      KOOD: "2084",
      NIMETUS: "Ingküla",
      NIMETUS_LIIGIGA: "Ingküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002084",
    },
    {
      TASE: "3",
      KOOD: "2087",
      NIMETUS: "Ingliste küla",
      NIMETUS_LIIGIGA: "Ingliste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00002087",
    },
    {
      TASE: "3",
      KOOD: "2090",
      NIMETUS: "Inju küla",
      NIMETUS_LIIGIGA: "Inju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00002090",
    },
    {
      TASE: "3",
      KOOD: "2093",
      NIMETUS: "Intsu küla",
      NIMETUS_LIIGIGA: "Intsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00002093",
    },
    {
      TASE: "3",
      KOOD: "2097",
      NIMETUS: "Irase küla",
      NIMETUS_LIIGIGA: "Irase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002097",
    },
    {
      TASE: "3",
      KOOD: "2099",
      NIMETUS: "Iravere küla",
      NIMETUS_LIIGIGA: "Iravere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00002099",
    },
    {
      TASE: "3",
      KOOD: "2100",
      NIMETUS: "Iru küla",
      NIMETUS_LIIGIGA: "Iru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00002100",
    },
    {
      TASE: "3",
      KOOD: "2102",
      NIMETUS: "Irta küla",
      NIMETUS_LIIGIGA: "Irta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002102",
    },
    {
      TASE: "3",
      KOOD: "2103",
      NIMETUS: "Iruste küla",
      NIMETUS_LIIGIGA: "Iruste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002103",
    },
    {
      TASE: "3",
      KOOD: "2105",
      NIMETUS: "Irvala küla",
      NIMETUS_LIIGIGA: "Irvala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00002105",
    },
    {
      TASE: "3",
      KOOD: "2106",
      NIMETUS: "Iska küla",
      NIMETUS_LIIGIGA: "Iska küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002106",
    },
    {
      TASE: "3",
      KOOD: "2109",
      NIMETUS: "Isabella küla",
      NIMETUS_LIIGIGA: "Isabella küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002109",
    },
    {
      TASE: "3",
      KOOD: "2112",
      NIMETUS: "Issaku küla",
      NIMETUS_LIIGIGA: "Issaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00002112",
    },
    {
      TASE: "3",
      KOOD: "2116",
      NIMETUS: "Ivaski küla",
      NIMETUS_LIIGIGA: "Ivaski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00002116",
    },
    {
      TASE: "3",
      KOOD: "2119",
      NIMETUS: "Ivaste küla",
      NIMETUS_LIIGIGA: "Ivaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00002119",
    },
    {
      TASE: "3",
      KOOD: "2124",
      NIMETUS: "Jaagupi küla",
      NIMETUS_LIIGIGA: "Jaagupi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00002124",
    },
    {
      TASE: "3",
      KOOD: "2126",
      NIMETUS: "Jaakna küla",
      NIMETUS_LIIGIGA: "Jaakna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002126",
    },
    {
      TASE: "3",
      KOOD: "2129",
      NIMETUS: "Jaama küla",
      NIMETUS_LIIGIGA: "Jaama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00002129",
    },
    {
      TASE: "3",
      KOOD: "2130",
      NIMETUS: "Jaama küla",
      NIMETUS_LIIGIGA: "Jaama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002130",
    },
    {
      TASE: "3",
      KOOD: "2132",
      NIMETUS: "Jaamaküla",
      NIMETUS_LIIGIGA: "Jaamaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00002132",
    },
    {
      TASE: "3",
      KOOD: "2135",
      NIMETUS: "Jaanika küla",
      NIMETUS_LIIGIGA: "Jaanika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00002135",
    },
    {
      TASE: "3",
      KOOD: "2137",
      NIMETUS: "Jaanikese küla",
      NIMETUS_LIIGIGA: "Jaanikese küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00002137",
    },
    {
      TASE: "3",
      KOOD: "2140",
      NIMETUS: "Jaanikeste küla",
      NIMETUS_LIIGIGA: "Jaanikeste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00002140",
    },
    {
      TASE: "3",
      KOOD: "2142",
      NIMETUS: "Jaanimõisa küla",
      NIMETUS_LIIGIGA: "Jaanimõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00002142",
    },
    {
      TASE: "3",
      KOOD: "2143",
      NIMETUS: "Jaanimäe küla",
      NIMETUS_LIIGIGA: "Jaanimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00002143",
    },
    {
      TASE: "3",
      KOOD: "2145",
      NIMETUS: "Jaanimäe küla",
      NIMETUS_LIIGIGA: "Jaanimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002145",
    },
    {
      TASE: "3",
      KOOD: "2146",
      NIMETUS: "Jaanipeebu küla",
      NIMETUS_LIIGIGA: "Jaanipeebu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002146",
    },
    {
      TASE: "3",
      KOOD: "2147",
      NIMETUS: "Jaaniveski küla",
      NIMETUS_LIIGIGA: "Jaaniveski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00002147",
    },
    {
      TASE: "3",
      KOOD: "2148",
      NIMETUS: "Jaani küla",
      NIMETUS_LIIGIGA: "Jaani küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002148",
    },
    {
      TASE: "3",
      KOOD: "2150",
      NIMETUS: "Jabara küla",
      NIMETUS_LIIGIGA: "Jabara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00002150",
    },
    {
      TASE: "3",
      KOOD: "2153",
      NIMETUS: "Jakobimõisa küla",
      NIMETUS_LIIGIGA: "Jakobimõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00002153",
    },
    {
      TASE: "3",
      KOOD: "2156",
      NIMETUS: "Jalalõpe küla",
      NIMETUS_LIIGIGA: "Jalalõpe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002156",
    },
    {
      TASE: "3",
      KOOD: "2159",
      NIMETUS: "Jalametsa küla",
      NIMETUS_LIIGIGA: "Jalametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002159",
    },
    {
      TASE: "3",
      KOOD: "2161",
      NIMETUS: "Jalase küla",
      NIMETUS_LIIGIGA: "Jalase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00002161",
    },
    {
      TASE: "3",
      KOOD: "2164",
      NIMETUS: "Jalgsema küla",
      NIMETUS_LIIGIGA: "Jalgsema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002164",
    },
    {
      TASE: "3",
      KOOD: "2166",
      NIMETUS: "Jalukse küla",
      NIMETUS_LIIGIGA: "Jalukse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002166",
    },
    {
      TASE: "3",
      KOOD: "2169",
      NIMETUS: "Jaluse küla",
      NIMETUS_LIIGIGA: "Jaluse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00002169",
    },
    {
      TASE: "3",
      KOOD: "2172",
      NIMETUS: "Jantra küla",
      NIMETUS_LIIGIGA: "Jantra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002172",
    },
    {
      TASE: "3",
      KOOD: "2175",
      NIMETUS: "Jaska küla",
      NIMETUS_LIIGIGA: "Jaska küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00002175",
    },
    {
      TASE: "3",
      KOOD: "2178",
      NIMETUS: "Jauni küla",
      NIMETUS_LIIGIGA: "Jauni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002178",
    },
    {
      TASE: "3",
      KOOD: "2180",
      NIMETUS: "Jausa küla",
      NIMETUS_LIIGIGA: "Jausa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002180",
    },
    {
      TASE: "3",
      KOOD: "2184",
      NIMETUS: "Jeedasküla",
      NIMETUS_LIIGIGA: "Jeedasküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002184",
    },
    {
      TASE: "3",
      KOOD: "2187",
      NIMETUS: "Jeti küla",
      NIMETUS_LIIGIGA: "Jeti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00002187",
    },
    {
      TASE: "3",
      KOOD: "2191",
      NIMETUS: "Joandu küla",
      NIMETUS_LIIGIGA: "Joandu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00002191",
    },
    {
      TASE: "3",
      KOOD: "2192",
      NIMETUS: "Joonuse küla",
      NIMETUS_LIIGIGA: "Joonuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002192",
    },
    {
      TASE: "3",
      KOOD: "2194",
      NIMETUS: "Joaveski küla",
      NIMETUS_LIIGIGA: "Joaveski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00002194",
    },
    {
      TASE: "3",
      KOOD: "2197",
      NIMETUS: "Joosu küla",
      NIMETUS_LIIGIGA: "Joosu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00002197",
    },
    {
      TASE: "3",
      KOOD: "2199",
      NIMETUS: "Jootme küla",
      NIMETUS_LIIGIGA: "Jootme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00002199",
    },
    {
      TASE: "3",
      KOOD: "2200",
      NIMETUS: "Jootme küla",
      NIMETUS_LIIGIGA: "Jootme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002200",
    },
    {
      TASE: "3",
      KOOD: "2203",
      NIMETUS: "Juba küla",
      NIMETUS_LIIGIGA: "Juba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002203",
    },
    {
      TASE: "3",
      KOOD: "2206",
      NIMETUS: "Jugu küla",
      NIMETUS_LIIGIGA: "Jugu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002206",
    },
    {
      TASE: "3",
      KOOD: "2209",
      NIMETUS: "Juminda küla",
      NIMETUS_LIIGIGA: "Juminda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00002209",
    },
    {
      TASE: "3",
      KOOD: "2212",
      NIMETUS: "Juraski küla",
      NIMETUS_LIIGIGA: "Juraski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002212",
    },
    {
      TASE: "3",
      KOOD: "2215",
      NIMETUS: "Jursi küla",
      NIMETUS_LIIGIGA: "Jursi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002215",
    },
    {
      TASE: "3",
      KOOD: "2216",
      NIMETUS: "Juula küla",
      NIMETUS_LIIGIGA: "Juula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00002216",
    },
    {
      TASE: "3",
      KOOD: "2218",
      NIMETUS: "Juula küla",
      NIMETUS_LIIGIGA: "Juula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00002218",
    },
    {
      TASE: "3",
      KOOD: "2220",
      NIMETUS: "Juuliku küla",
      NIMETUS_LIIGIGA: "Juuliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00002220",
    },
    {
      TASE: "3",
      KOOD: "2221",
      NIMETUS: "Juusa küla",
      NIMETUS_LIIGIGA: "Juusa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00002221",
    },
    {
      TASE: "3",
      KOOD: "2223",
      NIMETUS: "Juuru alevik",
      NIMETUS_LIIGIGA: "Juuru alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00002223",
    },
    {
      TASE: "3",
      KOOD: "2225",
      NIMETUS: "Jõe küla",
      NIMETUS_LIIGIGA: "Jõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002225",
    },
    {
      TASE: "3",
      KOOD: "2227",
      NIMETUS: "Jõeküla",
      NIMETUS_LIIGIGA: "Jõeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002227",
    },
    {
      TASE: "3",
      KOOD: "2228",
      NIMETUS: "Jõeküla",
      NIMETUS_LIIGIGA: "Jõeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00002228",
    },
    {
      TASE: "3",
      KOOD: "2229",
      NIMETUS: "Jõeküla",
      NIMETUS_LIIGIGA: "Jõeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00002229",
    },
    {
      TASE: "3",
      KOOD: "2230",
      NIMETUS: "Jõeküla",
      NIMETUS_LIIGIGA: "Jõeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002230",
    },
    {
      TASE: "3",
      KOOD: "2231",
      NIMETUS: "Jõelepa küla",
      NIMETUS_LIIGIGA: "Jõelepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002231",
    },
    {
      TASE: "3",
      KOOD: "2234",
      NIMETUS: "Jõelähtme küla",
      NIMETUS_LIIGIGA: "Jõelähtme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00002234",
    },
    {
      TASE: "3",
      KOOD: "2236",
      NIMETUS: "Jõemetsa küla",
      NIMETUS_LIIGIGA: "Jõemetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00002236",
    },
    {
      TASE: "3",
      KOOD: "2239",
      NIMETUS: "Jõempa küla",
      NIMETUS_LIIGIGA: "Jõempa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002239",
    },
    {
      TASE: "3",
      KOOD: "2241",
      NIMETUS: "Jõepera küla",
      NIMETUS_LIIGIGA: "Jõepera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00002241",
    },
    {
      TASE: "3",
      KOOD: "2242",
      NIMETUS: "Jõepera küla",
      NIMETUS_LIIGIGA: "Jõepera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00002242",
    },
    {
      TASE: "3",
      KOOD: "2245",
      NIMETUS: "Jõepere küla",
      NIMETUS_LIIGIGA: "Jõepere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00002245",
    },
    {
      TASE: "3",
      KOOD: "2246",
      NIMETUS: "Jõesse küla",
      NIMETUS_LIIGIGA: "Jõesse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002246",
    },
    {
      TASE: "3",
      KOOD: "2247",
      NIMETUS: "Jõeranna küla",
      NIMETUS_LIIGIGA: "Jõeranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002247",
    },
    {
      TASE: "3",
      KOOD: "2248",
      NIMETUS: "Jõesuu küla",
      NIMETUS_LIIGIGA: "Jõesuu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00002248",
    },
    {
      TASE: "3",
      KOOD: "2249",
      NIMETUS: "Jõetaguse küla",
      NIMETUS_LIIGIGA: "Jõetaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002249",
    },
    {
      TASE: "3",
      KOOD: "2250",
      NIMETUS: "Jõesuu küla",
      NIMETUS_LIIGIGA: "Jõesuu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002250",
    },
    {
      TASE: "3",
      KOOD: "2251",
      NIMETUS: "Jõesuu küla",
      NIMETUS_LIIGIGA: "Jõesuu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00002251",
    },
    {
      TASE: "3",
      KOOD: "2252",
      NIMETUS: "Jõevaara küla",
      NIMETUS_LIIGIGA: "Jõevaara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00002252",
    },
    {
      TASE: "3",
      KOOD: "2253",
      NIMETUS: "Jõetaguse küla",
      NIMETUS_LIIGIGA: "Jõetaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00002253",
    },
    {
      TASE: "3",
      KOOD: "2254",
      NIMETUS: "Jõeääre küla",
      NIMETUS_LIIGIGA: "Jõeääre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00002254",
    },
    {
      TASE: "3",
      KOOD: "2256",
      NIMETUS: "Jõeveere küla",
      NIMETUS_LIIGIGA: "Jõeveere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00002256",
    },
    {
      TASE: "3",
      KOOD: "2259",
      NIMETUS: "Jõgehara küla",
      NIMETUS_LIIGIGA: "Jõgehara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00002259",
    },
    {
      TASE: "3",
      KOOD: "2260",
      NIMETUS: "Jõgela küla",
      NIMETUS_LIIGIGA: "Jõgela küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002260",
    },
    {
      TASE: "3",
      KOOD: "2261",
      NIMETUS: "Jõgeva alevik",
      NIMETUS_LIIGIGA: "Jõgeva alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00002261",
    },
    {
      TASE: "3",
      KOOD: "2264",
      NIMETUS: "Jõgeveste küla",
      NIMETUS_LIIGIGA: "Jõgeveste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00002264",
    },
    {
      TASE: "3",
      KOOD: "2266",
      NIMETUS: "Jõgisoo küla",
      NIMETUS_LIIGIGA: "Jõgisoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002266",
    },
    {
      TASE: "3",
      KOOD: "2267",
      NIMETUS: "Jõgisoo küla",
      NIMETUS_LIIGIGA: "Jõgisoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00002267",
    },
    {
      TASE: "3",
      KOOD: "2268",
      NIMETUS: "Jõgisoo küla",
      NIMETUS_LIIGIGA: "Jõgisoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002268",
    },
    {
      TASE: "3",
      KOOD: "2270",
      NIMETUS: "Jõhvi linn",
      NIMETUS_LIIGIGA: "Jõhvi linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "251",
      ADS_OID: "AY00002270",
    },
    {
      TASE: "3",
      KOOD: "2271",
      NIMETUS: "Jõhvi küla",
      NIMETUS_LIIGIGA: "Jõhvi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "251",
      ADS_OID: "AY00002271",
    },
    {
      TASE: "3",
      KOOD: "2274",
      NIMETUS: "Jõiste küla",
      NIMETUS_LIIGIGA: "Jõiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002274",
    },
    {
      TASE: "3",
      KOOD: "2277",
      NIMETUS: "Jõksi küla",
      NIMETUS_LIIGIGA: "Jõksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00002277",
    },
    {
      TASE: "3",
      KOOD: "2278",
      NIMETUS: "Jõksi küla",
      NIMETUS_LIIGIGA: "Jõksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00002278",
    },
    {
      TASE: "3",
      KOOD: "2281",
      NIMETUS: "Jõuga küla",
      NIMETUS_LIIGIGA: "Jõuga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002281",
    },
    {
      TASE: "3",
      KOOD: "2284",
      NIMETUS: "Jõune küla",
      NIMETUS_LIIGIGA: "Jõune küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00002284",
    },
    {
      TASE: "3",
      KOOD: "2285",
      NIMETUS: "Jõõdre küla",
      NIMETUS_LIIGIGA: "Jõõdre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00002285",
    },
    {
      TASE: "3",
      KOOD: "2286",
      NIMETUS: "Jõusa küla",
      NIMETUS_LIIGIGA: "Jõusa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00002286",
    },
    {
      TASE: "3",
      KOOD: "2289",
      NIMETUS: "Jõõpre küla",
      NIMETUS_LIIGIGA: "Jõõpre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00002289",
    },
    {
      TASE: "3",
      KOOD: "2292",
      NIMETUS: "Jööri küla",
      NIMETUS_LIIGIGA: "Jööri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002292",
    },
    {
      TASE: "3",
      KOOD: "2296",
      NIMETUS: "Jädivere küla",
      NIMETUS_LIIGIGA: "Jädivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00002296",
    },
    {
      TASE: "3",
      KOOD: "2299",
      NIMETUS: "Jägala küla",
      NIMETUS_LIIGIGA: "Jägala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00002299",
    },
    {
      TASE: "3",
      KOOD: "2301",
      NIMETUS: "Jägala-Joa küla",
      NIMETUS_LIIGIGA: "Jägala-Joa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00002301",
    },
    {
      TASE: "3",
      KOOD: "2304",
      NIMETUS: "Jälevere küla",
      NIMETUS_LIIGIGA: "Jälevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00002304",
    },
    {
      TASE: "3",
      KOOD: "2307",
      NIMETUS: "Jälgimäe küla",
      NIMETUS_LIIGIGA: "Jälgimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00002307",
    },
    {
      TASE: "3",
      KOOD: "2310",
      NIMETUS: "Jämaja küla",
      NIMETUS_LIIGIGA: "Jämaja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002310",
    },
    {
      TASE: "3",
      KOOD: "2312",
      NIMETUS: "Jämejala küla",
      NIMETUS_LIIGIGA: "Jämejala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00002312",
    },
    {
      TASE: "3",
      KOOD: "2315",
      NIMETUS: "Jändja küla",
      NIMETUS_LIIGIGA: "Jändja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00002315",
    },
    {
      TASE: "3",
      KOOD: "2316",
      NIMETUS: "Jänistvere küla",
      NIMETUS_LIIGIGA: "Jänistvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002316",
    },
    {
      TASE: "3",
      KOOD: "2318",
      NIMETUS: "Jäneda küla",
      NIMETUS_LIIGIGA: "Jäneda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00002318",
    },
    {
      TASE: "3",
      KOOD: "2321",
      NIMETUS: "Järavere küla",
      NIMETUS_LIIGIGA: "Järavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002321",
    },
    {
      TASE: "3",
      KOOD: "2323",
      NIMETUS: "Järise küla",
      NIMETUS_LIIGIGA: "Järise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002323",
    },
    {
      TASE: "3",
      KOOD: "2324",
      NIMETUS: "Järise küla",
      NIMETUS_LIIGIGA: "Järise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002324",
    },
    {
      TASE: "3",
      KOOD: "2327",
      NIMETUS: "Järiste küla",
      NIMETUS_LIIGIGA: "Järiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00002327",
    },
    {
      TASE: "3",
      KOOD: "2332",
      NIMETUS: "Järni küla",
      NIMETUS_LIIGIGA: "Järni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00002332",
    },
    {
      TASE: "3",
      KOOD: "2334",
      NIMETUS: "Järsi küla",
      NIMETUS_LIIGIGA: "Järsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00002334",
    },
    {
      TASE: "3",
      KOOD: "2335",
      NIMETUS: "Järsi küla",
      NIMETUS_LIIGIGA: "Järsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00002335",
    },
    {
      TASE: "3",
      KOOD: "2338",
      NIMETUS: "Järtsaare küla",
      NIMETUS_LIIGIGA: "Järtsaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00002338",
    },
    {
      TASE: "3",
      KOOD: "2339",
      NIMETUS: "Kahala küla",
      NIMETUS_LIIGIGA: "Kahala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002339",
    },
    {
      TASE: "3",
      KOOD: "2341",
      NIMETUS: "Järva-Jaani alev",
      NIMETUS_LIIGIGA: "Järva-Jaani alev",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002341",
    },
    {
      TASE: "3",
      KOOD: "2343",
      NIMETUS: "Järva-Madise küla",
      NIMETUS_LIIGIGA: "Järva-Madise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002343",
    },
    {
      TASE: "3",
      KOOD: "2345",
      NIMETUS: "Järvajõe küla",
      NIMETUS_LIIGIGA: "Järvajõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00002345",
    },
    {
      TASE: "3",
      KOOD: "2346",
      NIMETUS: "Järvakandi alev",
      NIMETUS_LIIGIGA: "Järvakandi alev",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00002346",
    },
    {
      TASE: "3",
      KOOD: "2348",
      NIMETUS: "Järvaküla",
      NIMETUS_LIIGIGA: "Järvaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00002348",
    },
    {
      TASE: "3",
      KOOD: "2349",
      NIMETUS: "Järve küla",
      NIMETUS_LIIGIGA: "Järve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002349",
    },
    {
      TASE: "3",
      KOOD: "2350",
      NIMETUS: "Järve küla",
      NIMETUS_LIIGIGA: "Järve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00002350",
    },
    {
      TASE: "3",
      KOOD: "2351",
      NIMETUS: "Järveküla",
      NIMETUS_LIIGIGA: "Järveküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00002351",
    },
    {
      TASE: "3",
      KOOD: "2355",
      NIMETUS: "Järveküla",
      NIMETUS_LIIGIGA: "Järveküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00002355",
    },
    {
      TASE: "3",
      KOOD: "2356",
      NIMETUS: "Järveküla",
      NIMETUS_LIIGIGA: "Järveküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002356",
    },
    {
      TASE: "3",
      KOOD: "2357",
      NIMETUS: "Järvepalu küla",
      NIMETUS_LIIGIGA: "Järvepalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002357",
    },
    {
      TASE: "3",
      KOOD: "2358",
      NIMETUS: "Järvekülä küla",
      NIMETUS_LIIGIGA: "Järvekülä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002358",
    },
    {
      TASE: "3",
      KOOD: "2360",
      NIMETUS: "Järvepera küla",
      NIMETUS_LIIGIGA: "Järvepera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00002360",
    },
    {
      TASE: "3",
      KOOD: "2362",
      NIMETUS: "Järvepää küla",
      NIMETUS_LIIGIGA: "Järvepää küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00002362",
    },
    {
      TASE: "3",
      KOOD: "2365",
      NIMETUS: "Järvere küla",
      NIMETUS_LIIGIGA: "Järvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002365",
    },
    {
      TASE: "3",
      KOOD: "2366",
      NIMETUS: "Järve küla",
      NIMETUS_LIIGIGA: "Järve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002366",
    },
    {
      TASE: "3",
      KOOD: "2367",
      NIMETUS: "Järvselja küla",
      NIMETUS_LIIGIGA: "Järvselja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00002367",
    },
    {
      TASE: "3",
      KOOD: "2370",
      NIMETUS: "Jäärja küla",
      NIMETUS_LIIGIGA: "Jäärja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00002370",
    },
    {
      TASE: "3",
      KOOD: "2371",
      NIMETUS: "Jäätma küla",
      NIMETUS_LIIGIGA: "Jäätma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00002371",
    },
    {
      TASE: "3",
      KOOD: "2373",
      NIMETUS: "Jäätma küla",
      NIMETUS_LIIGIGA: "Jäätma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00002373",
    },
    {
      TASE: "3",
      KOOD: "2377",
      NIMETUS: "Jüri alevik",
      NIMETUS_LIIGIGA: "Jüri alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00002377",
    },
    {
      TASE: "3",
      KOOD: "2379",
      NIMETUS: "Jüriküla",
      NIMETUS_LIIGIGA: "Jüriküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00002379",
    },
    {
      TASE: "3",
      KOOD: "2384",
      NIMETUS: "Kaagjärve küla",
      NIMETUS_LIIGIGA: "Kaagjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00002384",
    },
    {
      TASE: "3",
      KOOD: "2387",
      NIMETUS: "Kaagna küla",
      NIMETUS_LIIGIGA: "Kaagna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00002387",
    },
    {
      TASE: "3",
      KOOD: "2389",
      NIMETUS: "Kaagu küla",
      NIMETUS_LIIGIGA: "Kaagu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002389",
    },
    {
      TASE: "3",
      KOOD: "2392",
      NIMETUS: "Kaagvere küla",
      NIMETUS_LIIGIGA: "Kaagvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00002392",
    },
    {
      TASE: "3",
      KOOD: "2393",
      NIMETUS: "Kaagvere küla",
      NIMETUS_LIIGIGA: "Kaagvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00002393",
    },
    {
      TASE: "3",
      KOOD: "2395",
      NIMETUS: "Kaalepi küla",
      NIMETUS_LIIGIGA: "Kaalepi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002395",
    },
    {
      TASE: "3",
      KOOD: "2398",
      NIMETUS: "Kaali küla",
      NIMETUS_LIIGIGA: "Kaali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002398",
    },
    {
      TASE: "3",
      KOOD: "2399",
      NIMETUS: "Kaare küla",
      NIMETUS_LIIGIGA: "Kaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002399",
    },
    {
      TASE: "3",
      KOOD: "2400",
      NIMETUS: "Kaansoo küla",
      NIMETUS_LIIGIGA: "Kaansoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00002400",
    },
    {
      TASE: "3",
      KOOD: "2401",
      NIMETUS: "Kaaratautsa küla",
      NIMETUS_LIIGIGA: "Kaaratautsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002401",
    },
    {
      TASE: "3",
      KOOD: "2403",
      NIMETUS: "Kaarepere küla",
      NIMETUS_LIIGIGA: "Kaarepere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00002403",
    },
    {
      TASE: "3",
      KOOD: "2405",
      NIMETUS: "Kaarli küla",
      NIMETUS_LIIGIGA: "Kaarli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00002405",
    },
    {
      TASE: "3",
      KOOD: "2406",
      NIMETUS: "Kaarli küla",
      NIMETUS_LIIGIGA: "Kaarli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00002406",
    },
    {
      TASE: "3",
      KOOD: "2409",
      NIMETUS: "Kaarlijärve küla",
      NIMETUS_LIIGIGA: "Kaarlijärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00002409",
    },
    {
      TASE: "3",
      KOOD: "2411",
      NIMETUS: "Kaarlimõisa küla",
      NIMETUS_LIIGIGA: "Kaarlimõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00002411",
    },
    {
      TASE: "3",
      KOOD: "2414",
      NIMETUS: "Kaarma küla",
      NIMETUS_LIIGIGA: "Kaarma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002414",
    },
    {
      TASE: "3",
      KOOD: "2416",
      NIMETUS: "Kaarmise küla",
      NIMETUS_LIIGIGA: "Kaarmise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002416",
    },
    {
      TASE: "3",
      KOOD: "2419",
      NIMETUS: "Kaaru küla",
      NIMETUS_LIIGIGA: "Kaaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00002419",
    },
    {
      TASE: "3",
      KOOD: "2420",
      NIMETUS: "Kaasikaia küla",
      NIMETUS_LIIGIGA: "Kaasikaia küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00002420",
    },
    {
      TASE: "3",
      KOOD: "2421",
      NIMETUS: "Kaaruka küla",
      NIMETUS_LIIGIGA: "Kaaruka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00002421",
    },
    {
      TASE: "3",
      KOOD: "2424",
      NIMETUS: "Kaasiksaare küla",
      NIMETUS_LIIGIGA: "Kaasiksaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00002424",
    },
    {
      TASE: "3",
      KOOD: "2426",
      NIMETUS: "Kaasiku küla",
      NIMETUS_LIIGIGA: "Kaasiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002426",
    },
    {
      TASE: "3",
      KOOD: "2427",
      NIMETUS: "Kaasiku küla",
      NIMETUS_LIIGIGA: "Kaasiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00002427",
    },
    {
      TASE: "3",
      KOOD: "2428",
      NIMETUS: "Kaasiku küla",
      NIMETUS_LIIGIGA: "Kaasiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002428",
    },
    {
      TASE: "3",
      KOOD: "2429",
      NIMETUS: "Kaasikvälja küla",
      NIMETUS_LIIGIGA: "Kaasikvälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00002429",
    },
    {
      TASE: "3",
      KOOD: "2430",
      NIMETUS: "Kaasiku küla",
      NIMETUS_LIIGIGA: "Kaasiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00002430",
    },
    {
      TASE: "3",
      KOOD: "2431",
      NIMETUS: "Kaatermu küla",
      NIMETUS_LIIGIGA: "Kaatermu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002431",
    },
    {
      TASE: "3",
      KOOD: "2433",
      NIMETUS: "Kaatsi küla",
      NIMETUS_LIIGIGA: "Kaatsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00002433",
    },
    {
      TASE: "3",
      KOOD: "2436",
      NIMETUS: "Kaave küla",
      NIMETUS_LIIGIGA: "Kaave küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00002436",
    },
    {
      TASE: "3",
      KOOD: "2437",
      NIMETUS: "Kaavere küla",
      NIMETUS_LIIGIGA: "Kaavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00002437",
    },
    {
      TASE: "3",
      KOOD: "2438",
      NIMETUS: "Kaavere küla",
      NIMETUS_LIIGIGA: "Kaavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00002438",
    },
    {
      TASE: "3",
      KOOD: "2439",
      NIMETUS: "Kaavere küla",
      NIMETUS_LIIGIGA: "Kaavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00002439",
    },
    {
      TASE: "3",
      KOOD: "2442",
      NIMETUS: "Kaavi küla",
      NIMETUS_LIIGIGA: "Kaavi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002442",
    },
    {
      TASE: "3",
      KOOD: "2445",
      NIMETUS: "Kabala küla",
      NIMETUS_LIIGIGA: "Kabala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00002445",
    },
    {
      TASE: "3",
      KOOD: "2446",
      NIMETUS: "Kabeli küla",
      NIMETUS_LIIGIGA: "Kabeli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002446",
    },
    {
      TASE: "3",
      KOOD: "2447",
      NIMETUS: "Kabeli küla",
      NIMETUS_LIIGIGA: "Kabeli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00002447",
    },
    {
      TASE: "3",
      KOOD: "2448",
      NIMETUS: "Kabelimetsa küla",
      NIMETUS_LIIGIGA: "Kabelimetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00002448",
    },
    {
      TASE: "3",
      KOOD: "2450",
      NIMETUS: "Kaberla küla",
      NIMETUS_LIIGIGA: "Kaberla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00002450",
    },
    {
      TASE: "3",
      KOOD: "2452",
      NIMETUS: "Kaberneeme küla",
      NIMETUS_LIIGIGA: "Kaberneeme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00002452",
    },
    {
      TASE: "3",
      KOOD: "2455",
      NIMETUS: "Kabila küla",
      NIMETUS_LIIGIGA: "Kabila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00002455",
    },
    {
      TASE: "3",
      KOOD: "2456",
      NIMETUS: "Kabila küla",
      NIMETUS_LIIGIGA: "Kabila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00002456",
    },
    {
      TASE: "3",
      KOOD: "2461",
      NIMETUS: "Kablaküla",
      NIMETUS_LIIGIGA: "Kablaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00002461",
    },
    {
      TASE: "3",
      KOOD: "2462",
      NIMETUS: "Kablima küla",
      NIMETUS_LIIGIGA: "Kablima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00002462",
    },
    {
      TASE: "3",
      KOOD: "2463",
      NIMETUS: "Kabli küla",
      NIMETUS_LIIGIGA: "Kabli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00002463",
    },
    {
      TASE: "3",
      KOOD: "2466",
      NIMETUS: "Kabrametsa küla",
      NIMETUS_LIIGIGA: "Kabrametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00002466",
    },
    {
      TASE: "3",
      KOOD: "2467",
      NIMETUS: "Kabuna küla",
      NIMETUS_LIIGIGA: "Kabuna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002467",
    },
    {
      TASE: "3",
      KOOD: "2468",
      NIMETUS: "Kabriste küla",
      NIMETUS_LIIGIGA: "Kabriste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00002468",
    },
    {
      TASE: "3",
      KOOD: "2471",
      NIMETUS: "Kadaja küla",
      NIMETUS_LIIGIGA: "Kadaja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00002471",
    },
    {
      TASE: "3",
      KOOD: "2472",
      NIMETUS: "Kadaka küla",
      NIMETUS_LIIGIGA: "Kadaka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00002472",
    },
    {
      TASE: "3",
      KOOD: "2473",
      NIMETUS: "Kadaka küla",
      NIMETUS_LIIGIGA: "Kadaka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002473",
    },
    {
      TASE: "3",
      KOOD: "2474",
      NIMETUS: "Kadaka küla",
      NIMETUS_LIIGIGA: "Kadaka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00002474",
    },
    {
      TASE: "3",
      KOOD: "2475",
      NIMETUS: "Kadaka küla",
      NIMETUS_LIIGIGA: "Kadaka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00002475",
    },
    {
      TASE: "3",
      KOOD: "2476",
      NIMETUS: "Kadapiku küla",
      NIMETUS_LIIGIGA: "Kadapiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00002476",
    },
    {
      TASE: "3",
      KOOD: "2479",
      NIMETUS: "Kadarpiku küla",
      NIMETUS_LIIGIGA: "Kadarpiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002479",
    },
    {
      TASE: "3",
      KOOD: "2480",
      NIMETUS: "Kadiküla",
      NIMETUS_LIIGIGA: "Kadiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00002480",
    },
    {
      TASE: "3",
      KOOD: "2481",
      NIMETUS: "Kaderna küla",
      NIMETUS_LIIGIGA: "Kaderna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002481",
    },
    {
      TASE: "3",
      KOOD: "2482",
      NIMETUS: "Kadapiku küla",
      NIMETUS_LIIGIGA: "Kadapiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00002482",
    },
    {
      TASE: "3",
      KOOD: "2484",
      NIMETUS: "Kadila küla",
      NIMETUS_LIIGIGA: "Kadila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00002484",
    },
    {
      TASE: "3",
      KOOD: "2485",
      NIMETUS: "Kadja küla",
      NIMETUS_LIIGIGA: "Kadja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00002485",
    },
    {
      TASE: "3",
      KOOD: "2486",
      NIMETUS: "Kadjaste küla",
      NIMETUS_LIIGIGA: "Kadjaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00002486",
    },
    {
      TASE: "3",
      KOOD: "2489",
      NIMETUS: "Kadrina küla",
      NIMETUS_LIIGIGA: "Kadrina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00002489",
    },
    {
      TASE: "3",
      KOOD: "2490",
      NIMETUS: "Kadrina alevik",
      NIMETUS_LIIGIGA: "Kadrina alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00002490",
    },
    {
      TASE: "3",
      KOOD: "2491",
      NIMETUS: "Kadõni küla",
      NIMETUS_LIIGIGA: "Kadõni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002491",
    },
    {
      TASE: "3",
      KOOD: "2493",
      NIMETUS: "Kaelase küla",
      NIMETUS_LIIGIGA: "Kaelase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00002493",
    },
    {
      TASE: "3",
      KOOD: "2495",
      NIMETUS: "Kaera küla",
      NIMETUS_LIIGIGA: "Kaera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00002495",
    },
    {
      TASE: "3",
      KOOD: "2496",
      NIMETUS: "Kaerepere alevik",
      NIMETUS_LIIGIGA: "Kaerepere alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00002496",
    },
    {
      TASE: "3",
      KOOD: "2497",
      NIMETUS: "Kaevere küla",
      NIMETUS_LIIGIGA: "Kaevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00002497",
    },
    {
      TASE: "3",
      KOOD: "2498",
      NIMETUS: "Kaerepere küla",
      NIMETUS_LIIGIGA: "Kaerepere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00002498",
    },
    {
      TASE: "3",
      KOOD: "2500",
      NIMETUS: "Kaeva küla",
      NIMETUS_LIIGIGA: "Kaeva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00002500",
    },
    {
      TASE: "3",
      KOOD: "2503",
      NIMETUS: "Kaevussaare küla",
      NIMETUS_LIIGIGA: "Kaevussaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00002503",
    },
    {
      TASE: "3",
      KOOD: "2504",
      NIMETUS: "Kaguvere küla",
      NIMETUS_LIIGIGA: "Kaguvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00002504",
    },
    {
      TASE: "3",
      KOOD: "2506",
      NIMETUS: "Kagavere küla",
      NIMETUS_LIIGIGA: "Kagavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002506",
    },
    {
      TASE: "3",
      KOOD: "2509",
      NIMETUS: "Kahala küla",
      NIMETUS_LIIGIGA: "Kahala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00002509",
    },
    {
      TASE: "3",
      KOOD: "2510",
      NIMETUS: "Kahala küla",
      NIMETUS_LIIGIGA: "Kahala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00002510",
    },
    {
      TASE: "3",
      KOOD: "2512",
      NIMETUS: "Kahkva küla",
      NIMETUS_LIIGIGA: "Kahkva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00002512",
    },
    {
      TASE: "3",
      KOOD: "2513",
      NIMETUS: "Kahru küla",
      NIMETUS_LIIGIGA: "Kahru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002513",
    },
    {
      TASE: "3",
      KOOD: "2515",
      NIMETUS: "Kahro küla",
      NIMETUS_LIIGIGA: "Kahro küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002515",
    },
    {
      TASE: "3",
      KOOD: "2517",
      NIMETUS: "Kahtla küla",
      NIMETUS_LIIGIGA: "Kahtla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002517",
    },
    {
      TASE: "3",
      KOOD: "2520",
      NIMETUS: "Kahula küla",
      NIMETUS_LIIGIGA: "Kahula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "251",
      ADS_OID: "AY00002520",
    },
    {
      TASE: "3",
      KOOD: "2522",
      NIMETUS: "Kahutsi küla",
      NIMETUS_LIIGIGA: "Kahutsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002522",
    },
    {
      TASE: "3",
      KOOD: "2523",
      NIMETUS: "Kaiavere küla",
      NIMETUS_LIIGIGA: "Kaiavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00002523",
    },
    {
      TASE: "3",
      KOOD: "2525",
      NIMETUS: "Kaiavere küla",
      NIMETUS_LIIGIGA: "Kaiavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00002525",
    },
    {
      TASE: "3",
      KOOD: "2528",
      NIMETUS: "Kaidma küla",
      NIMETUS_LIIGIGA: "Kaidma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002528",
    },
    {
      TASE: "3",
      KOOD: "2530",
      NIMETUS: "Kaigepere küla",
      NIMETUS_LIIGIGA: "Kaigepere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00002530",
    },
    {
      TASE: "3",
      KOOD: "2533",
      NIMETUS: "Kaigutsi küla",
      NIMETUS_LIIGIGA: "Kaigutsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002533",
    },
    {
      TASE: "3",
      KOOD: "2535",
      NIMETUS: "Kaika küla",
      NIMETUS_LIIGIGA: "Kaika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00002535",
    },
    {
      TASE: "3",
      KOOD: "2538",
      NIMETUS: "Kailuka küla",
      NIMETUS_LIIGIGA: "Kailuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002538",
    },
    {
      TASE: "3",
      KOOD: "2540",
      NIMETUS: "Kaimi küla",
      NIMETUS_LIIGIGA: "Kaimi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00002540",
    },
    {
      TASE: "3",
      KOOD: "2541",
      NIMETUS: "Kaimri küla",
      NIMETUS_LIIGIGA: "Kaimri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002541",
    },
    {
      TASE: "3",
      KOOD: "2543",
      NIMETUS: "Kaisa küla",
      NIMETUS_LIIGIGA: "Kaisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002543",
    },
    {
      TASE: "3",
      KOOD: "2545",
      NIMETUS: "Kaisma küla",
      NIMETUS_LIIGIGA: "Kaisma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00002545",
    },
    {
      TASE: "3",
      KOOD: "2548",
      NIMETUS: "Kaisvere küla",
      NIMETUS_LIIGIGA: "Kaisvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002548",
    },
    {
      TASE: "3",
      KOOD: "2550",
      NIMETUS: "Kaitsemõisa küla",
      NIMETUS_LIIGIGA: "Kaitsemõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00002550",
    },
    {
      TASE: "3",
      KOOD: "2551",
      NIMETUS: "Kaku küla",
      NIMETUS_LIIGIGA: "Kaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002551",
    },
    {
      TASE: "3",
      KOOD: "2552",
      NIMETUS: "Kajamaa küla",
      NIMETUS_LIIGIGA: "Kajamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00002552",
    },
    {
      TASE: "3",
      KOOD: "2553",
      NIMETUS: "Kaiu alevik",
      NIMETUS_LIIGIGA: "Kaiu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00002553",
    },
    {
      TASE: "3",
      KOOD: "2554",
      NIMETUS: "Kaku küla",
      NIMETUS_LIIGIGA: "Kaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002554",
    },
    {
      TASE: "3",
      KOOD: "2555",
      NIMETUS: "Kakumäe küla",
      NIMETUS_LIIGIGA: "Kakumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00002555",
    },
    {
      TASE: "3",
      KOOD: "2556",
      NIMETUS: "Kakumetsa küla",
      NIMETUS_LIIGIGA: "Kakumetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00002556",
    },
    {
      TASE: "3",
      KOOD: "2557",
      NIMETUS: "Kakuna küla",
      NIMETUS_LIIGIGA: "Kakuna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002557",
    },
    {
      TASE: "3",
      KOOD: "2558",
      NIMETUS: "Kakuvälja küla",
      NIMETUS_LIIGIGA: "Kakuvälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00002558",
    },
    {
      TASE: "3",
      KOOD: "2559",
      NIMETUS: "Kakusuu küla",
      NIMETUS_LIIGIGA: "Kakusuu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002559",
    },
    {
      TASE: "3",
      KOOD: "2561",
      NIMETUS: "Kalana küla",
      NIMETUS_LIIGIGA: "Kalana küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002561",
    },
    {
      TASE: "3",
      KOOD: "2562",
      NIMETUS: "Kalana küla",
      NIMETUS_LIIGIGA: "Kalana küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00002562",
    },
    {
      TASE: "3",
      KOOD: "2565",
      NIMETUS: "Kalatsova küla",
      NIMETUS_LIIGIGA: "Kalatsova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00002565",
    },
    {
      TASE: "3",
      KOOD: "2567",
      NIMETUS: "Kalbu küla",
      NIMETUS_LIIGIGA: "Kalbu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00002567",
    },
    {
      TASE: "3",
      KOOD: "2570",
      NIMETUS: "Kalbuse küla",
      NIMETUS_LIIGIGA: "Kalbuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00002570",
    },
    {
      TASE: "3",
      KOOD: "2571",
      NIMETUS: "Kaldemäe küla",
      NIMETUS_LIIGIGA: "Kaldemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002571",
    },
    {
      TASE: "3",
      KOOD: "2572",
      NIMETUS: "Kalda küla",
      NIMETUS_LIIGIGA: "Kalda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00002572",
    },
    {
      TASE: "3",
      KOOD: "2575",
      NIMETUS: "Kalesi küla",
      NIMETUS_LIIGIGA: "Kalesi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00002575",
    },
    {
      TASE: "3",
      KOOD: "2577",
      NIMETUS: "Kaleste küla",
      NIMETUS_LIIGIGA: "Kaleste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002577",
    },
    {
      TASE: "3",
      KOOD: "2578",
      NIMETUS: "Kalgi küla",
      NIMETUS_LIIGIGA: "Kalgi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002578",
    },
    {
      TASE: "3",
      KOOD: "2580",
      NIMETUS: "Kalevi küla",
      NIMETUS_LIIGIGA: "Kalevi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00002580",
    },
    {
      TASE: "3",
      KOOD: "2582",
      NIMETUS: "Kaliküla",
      NIMETUS_LIIGIGA: "Kaliküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00002582",
    },
    {
      TASE: "3",
      KOOD: "2583",
      NIMETUS: "Kaliküla",
      NIMETUS_LIIGIGA: "Kaliküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00002583",
    },
    {
      TASE: "3",
      KOOD: "2586",
      NIMETUS: "Kalina küla",
      NIMETUS_LIIGIGA: "Kalina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002586",
    },
    {
      TASE: "3",
      KOOD: "2588",
      NIMETUS: "Kalita küla",
      NIMETUS_LIIGIGA: "Kalita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00002588",
    },
    {
      TASE: "3",
      KOOD: "2591",
      NIMETUS: "Kalitsa küla",
      NIMETUS_LIIGIGA: "Kalitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002591",
    },
    {
      TASE: "3",
      KOOD: "2593",
      NIMETUS: "Kalju küla",
      NIMETUS_LIIGIGA: "Kalju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002593",
    },
    {
      TASE: "3",
      KOOD: "2594",
      NIMETUS: "Kalju küla",
      NIMETUS_LIIGIGA: "Kalju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002594",
    },
    {
      TASE: "3",
      KOOD: "2597",
      NIMETUS: "Kallaste küla",
      NIMETUS_LIIGIGA: "Kallaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00002597",
    },
    {
      TASE: "3",
      KOOD: "2598",
      NIMETUS: "Kallaste küla",
      NIMETUS_LIIGIGA: "Kallaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002598",
    },
    {
      TASE: "3",
      KOOD: "2599",
      NIMETUS: "Kallaste küla",
      NIMETUS_LIIGIGA: "Kallaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002599",
    },
    {
      TASE: "3",
      KOOD: "2603",
      NIMETUS: "Kallemäe küla",
      NIMETUS_LIIGIGA: "Kallemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002603",
    },
    {
      TASE: "3",
      KOOD: "2605",
      NIMETUS: "Kalli küla",
      NIMETUS_LIIGIGA: "Kalli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002605",
    },
    {
      TASE: "3",
      KOOD: "2606",
      NIMETUS: "Kalli küla",
      NIMETUS_LIIGIGA: "Kalli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002606",
    },
    {
      TASE: "3",
      KOOD: "2609",
      NIMETUS: "Kalliküla",
      NIMETUS_LIIGIGA: "Kalliküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00002609",
    },
    {
      TASE: "3",
      KOOD: "2611",
      NIMETUS: "Kallivere küla",
      NIMETUS_LIIGIGA: "Kallivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00002611",
    },
    {
      TASE: "3",
      KOOD: "2614",
      NIMETUS: "Kallukse küla",
      NIMETUS_LIIGIGA: "Kallukse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00002614",
    },
    {
      TASE: "3",
      KOOD: "2616",
      NIMETUS: "Kalmaküla",
      NIMETUS_LIIGIGA: "Kalmaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00002616",
    },
    {
      TASE: "3",
      KOOD: "2619",
      NIMETUS: "Kalmaru küla",
      NIMETUS_LIIGIGA: "Kalmaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00002619",
    },
    {
      TASE: "3",
      KOOD: "2620",
      NIMETUS: "Kalma küla",
      NIMETUS_LIIGIGA: "Kalma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002620",
    },
    {
      TASE: "3",
      KOOD: "2621",
      NIMETUS: "Kalme küla",
      NIMETUS_LIIGIGA: "Kalme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00002621",
    },
    {
      TASE: "3",
      KOOD: "2622",
      NIMETUS: "Kalme küla",
      NIMETUS_LIIGIGA: "Kalme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00002622",
    },
    {
      TASE: "3",
      KOOD: "2623",
      NIMETUS: "Kalme küla",
      NIMETUS_LIIGIGA: "Kalme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00002623",
    },
    {
      TASE: "3",
      KOOD: "2624",
      NIMETUS: "Kalmu küla",
      NIMETUS_LIIGIGA: "Kalmu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002624",
    },
    {
      TASE: "3",
      KOOD: "2625",
      NIMETUS: "Kaloga küla",
      NIMETUS_LIIGIGA: "Kaloga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002625",
    },
    {
      TASE: "3",
      KOOD: "2626",
      NIMETUS: "Kalvi küla",
      NIMETUS_LIIGIGA: "Kalvi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00002626",
    },
    {
      TASE: "3",
      KOOD: "2627",
      NIMETUS: "Kaluka küla",
      NIMETUS_LIIGIGA: "Kaluka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002627",
    },
    {
      TASE: "3",
      KOOD: "2628",
      NIMETUS: "Kalvre küla",
      NIMETUS_LIIGIGA: "Kalvre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00002628",
    },
    {
      TASE: "3",
      KOOD: "2629",
      NIMETUS: "Kalme küla",
      NIMETUS_LIIGIGA: "Kalme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00002629",
    },
    {
      TASE: "3",
      KOOD: "2631",
      NIMETUS: "Kamali küla",
      NIMETUS_LIIGIGA: "Kamali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00002631",
    },
    {
      TASE: "3",
      KOOD: "2634",
      NIMETUS: "Kamara küla",
      NIMETUS_LIIGIGA: "Kamara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00002634",
    },
    {
      TASE: "3",
      KOOD: "2635",
      NIMETUS: "Kamariku küla",
      NIMETUS_LIIGIGA: "Kamariku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00002635",
    },
    {
      TASE: "3",
      KOOD: "2636",
      NIMETUS: "Kamari alevik",
      NIMETUS_LIIGIGA: "Kamari alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00002636",
    },
    {
      TASE: "3",
      KOOD: "2639",
      NIMETUS: "Kamarna küla",
      NIMETUS_LIIGIGA: "Kamarna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002639",
    },
    {
      TASE: "3",
      KOOD: "2641",
      NIMETUS: "Kambja alevik",
      NIMETUS_LIIGIGA: "Kambja alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00002641",
    },
    {
      TASE: "3",
      KOOD: "2644",
      NIMETUS: "Kammeri küla",
      NIMETUS_LIIGIGA: "Kammeri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00002644",
    },
    {
      TASE: "3",
      KOOD: "2646",
      NIMETUS: "Kamnitsa küla",
      NIMETUS_LIIGIGA: "Kamnitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002646",
    },
    {
      TASE: "3",
      KOOD: "2650",
      NIMETUS: "Kanapeeksi küla",
      NIMETUS_LIIGIGA: "Kanapeeksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002650",
    },
    {
      TASE: "3",
      KOOD: "2651",
      NIMETUS: "Kanamardi küla",
      NIMETUS_LIIGIGA: "Kanamardi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002651",
    },
    {
      TASE: "3",
      KOOD: "2652",
      NIMETUS: "Saue küla",
      NIMETUS_LIIGIGA: "Saue küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00002652",
    },
    {
      TASE: "3",
      KOOD: "2654",
      NIMETUS: "Kanassaare küla",
      NIMETUS_LIIGIGA: "Kanassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00002654",
    },
    {
      TASE: "3",
      KOOD: "2657",
      NIMETUS: "Kanavere küla",
      NIMETUS_LIIGIGA: "Kanavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00002657",
    },
    {
      TASE: "3",
      KOOD: "2659",
      NIMETUS: "Kandiküla",
      NIMETUS_LIIGIGA: "Kandiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "793",
      ADS_OID: "AY00002659",
    },
    {
      TASE: "3",
      KOOD: "2662",
      NIMETUS: "Kandla küla",
      NIMETUS_LIIGIGA: "Kandla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002662",
    },
    {
      TASE: "3",
      KOOD: "2664",
      NIMETUS: "Kandle küla",
      NIMETUS_LIIGIGA: "Kandle küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00002664",
    },
    {
      TASE: "3",
      KOOD: "2665",
      NIMETUS: "Kangavitsa küla",
      NIMETUS_LIIGIGA: "Kangavitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00002665",
    },
    {
      TASE: "3",
      KOOD: "2667",
      NIMETUS: "Kanepi alevik",
      NIMETUS_LIIGIGA: "Kanepi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00002667",
    },
    {
      TASE: "3",
      KOOD: "2668",
      NIMETUS: "Kangru küla",
      NIMETUS_LIIGIGA: "Kangru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00002668",
    },
    {
      TASE: "3",
      KOOD: "2669",
      NIMETUS: "Kangru küla",
      NIMETUS_LIIGIGA: "Kangru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00002669",
    },
    {
      TASE: "3",
      KOOD: "2672",
      NIMETUS: "Kangrusselja küla",
      NIMETUS_LIIGIGA: "Kangrusselja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002672",
    },
    {
      TASE: "3",
      KOOD: "2674",
      NIMETUS: "Kangrussaare küla",
      NIMETUS_LIIGIGA: "Kangrussaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00002674",
    },
    {
      TASE: "3",
      KOOD: "2675",
      NIMETUS: "Kanguristi küla",
      NIMETUS_LIIGIGA: "Kanguristi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00002675",
    },
    {
      TASE: "3",
      KOOD: "2677",
      NIMETUS: "Kangsti küla",
      NIMETUS_LIIGIGA: "Kangsti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002677",
    },
    {
      TASE: "3",
      KOOD: "2679",
      NIMETUS: "Kannastiku küla",
      NIMETUS_LIIGIGA: "Kannastiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00002679",
    },
    {
      TASE: "3",
      KOOD: "2682",
      NIMETUS: "Kannu küla",
      NIMETUS_LIIGIGA: "Kannu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00002682",
    },
    {
      TASE: "3",
      KOOD: "2683",
      NIMETUS: "Kannu küla",
      NIMETUS_LIIGIGA: "Kannu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002683",
    },
    {
      TASE: "3",
      KOOD: "2685",
      NIMETUS: "Kannuküla",
      NIMETUS_LIIGIGA: "Kannuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00002685",
    },
    {
      TASE: "3",
      KOOD: "2688",
      NIMETUS: "Kantküla",
      NIMETUS_LIIGIGA: "Kantküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00002688",
    },
    {
      TASE: "3",
      KOOD: "2689",
      NIMETUS: "Kantküla",
      NIMETUS_LIIGIGA: "Kantküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00002689",
    },
    {
      TASE: "3",
      KOOD: "2690",
      NIMETUS: "Kantküla",
      NIMETUS_LIIGIGA: "Kantküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00002690",
    },
    {
      TASE: "3",
      KOOD: "2691",
      NIMETUS: "Kantsi küla",
      NIMETUS_LIIGIGA: "Kantsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00002691",
    },
    {
      TASE: "3",
      KOOD: "2694",
      NIMETUS: "Kapera küla",
      NIMETUS_LIIGIGA: "Kapera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002694",
    },
    {
      TASE: "3",
      KOOD: "2695",
      NIMETUS: "Kapi küla",
      NIMETUS_LIIGIGA: "Kapi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00002695",
    },
    {
      TASE: "3",
      KOOD: "2697",
      NIMETUS: "Kapra küla",
      NIMETUS_LIIGIGA: "Kapra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002697",
    },
    {
      TASE: "3",
      KOOD: "2699",
      NIMETUS: "Kapsta küla",
      NIMETUS_LIIGIGA: "Kapsta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00002699",
    },
    {
      TASE: "3",
      KOOD: "2702",
      NIMETUS: "Kapu küla",
      NIMETUS_LIIGIGA: "Kapu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002702",
    },
    {
      TASE: "3",
      KOOD: "2703",
      NIMETUS: "Karamsina küla",
      NIMETUS_LIIGIGA: "Karamsina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00002703",
    },
    {
      TASE: "3",
      KOOD: "2704",
      NIMETUS: "Karaski küla",
      NIMETUS_LIIGIGA: "Karaski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002704",
    },
    {
      TASE: "3",
      KOOD: "2705",
      NIMETUS: "Karala küla",
      NIMETUS_LIIGIGA: "Karala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002705",
    },
    {
      TASE: "3",
      KOOD: "2707",
      NIMETUS: "Karaski küla",
      NIMETUS_LIIGIGA: "Karaski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00002707",
    },
    {
      TASE: "3",
      KOOD: "2708",
      NIMETUS: "Karba küla",
      NIMETUS_LIIGIGA: "Karba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002708",
    },
    {
      TASE: "3",
      KOOD: "2710",
      NIMETUS: "Kardla küla",
      NIMETUS_LIIGIGA: "Kardla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "793",
      ADS_OID: "AY00002710",
    },
    {
      TASE: "3",
      KOOD: "2712",
      NIMETUS: "Kareda küla",
      NIMETUS_LIIGIGA: "Kareda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002712",
    },
    {
      TASE: "3",
      KOOD: "2713",
      NIMETUS: "Kareda küla",
      NIMETUS_LIIGIGA: "Kareda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002713",
    },
    {
      TASE: "3",
      KOOD: "2715",
      NIMETUS: "Karepa küla",
      NIMETUS_LIIGIGA: "Karepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00002715",
    },
    {
      TASE: "3",
      KOOD: "2717",
      NIMETUS: "Kargaja küla",
      NIMETUS_LIIGIGA: "Kargaja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00002717",
    },
    {
      TASE: "3",
      KOOD: "2720",
      NIMETUS: "Kargi küla",
      NIMETUS_LIIGIGA: "Kargi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002720",
    },
    {
      TASE: "3",
      KOOD: "2722",
      NIMETUS: "Karida küla",
      NIMETUS_LIIGIGA: "Karida küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002722",
    },
    {
      TASE: "3",
      KOOD: "2725",
      NIMETUS: "Karijärve küla",
      NIMETUS_LIIGIGA: "Karijärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00002725",
    },
    {
      TASE: "3",
      KOOD: "2727",
      NIMETUS: "Karilatsi küla",
      NIMETUS_LIIGIGA: "Karilatsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00002727",
    },
    {
      TASE: "3",
      KOOD: "2728",
      NIMETUS: "Karilatsi küla",
      NIMETUS_LIIGIGA: "Karilatsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00002728",
    },
    {
      TASE: "3",
      KOOD: "2731",
      NIMETUS: "Karilepa küla",
      NIMETUS_LIIGIGA: "Karilepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00002731",
    },
    {
      TASE: "3",
      KOOD: "2732",
      NIMETUS: "Karinõmme küla",
      NIMETUS_LIIGIGA: "Karinõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002732",
    },
    {
      TASE: "3",
      KOOD: "2733",
      NIMETUS: "Karinu küla",
      NIMETUS_LIIGIGA: "Karinu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002733",
    },
    {
      TASE: "3",
      KOOD: "2736",
      NIMETUS: "Karisilla küla",
      NIMETUS_LIIGIGA: "Karisilla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00002736",
    },
    {
      TASE: "3",
      KOOD: "2738",
      NIMETUS: "Karisöödi küla",
      NIMETUS_LIIGIGA: "Karisöödi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002738",
    },
    {
      TASE: "3",
      KOOD: "2741",
      NIMETUS: "Karitsa küla",
      NIMETUS_LIIGIGA: "Karitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00002741",
    },
    {
      TASE: "3",
      KOOD: "2742",
      NIMETUS: "Karitsa küla",
      NIMETUS_LIIGIGA: "Karitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00002742",
    },
    {
      TASE: "3",
      KOOD: "2744",
      NIMETUS: "Karivärava küla",
      NIMETUS_LIIGIGA: "Karivärava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00002744",
    },
    {
      TASE: "3",
      KOOD: "2747",
      NIMETUS: "Karja küla",
      NIMETUS_LIIGIGA: "Karja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002747",
    },
    {
      TASE: "3",
      KOOD: "2749",
      NIMETUS: "Karjaküla alevik",
      NIMETUS_LIIGIGA: "Karjaküla alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00002749",
    },
    {
      TASE: "3",
      KOOD: "2752",
      NIMETUS: "Karjamaa küla",
      NIMETUS_LIIGIGA: "Karjamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002752",
    },
    {
      TASE: "3",
      KOOD: "2754",
      NIMETUS: "Karjasoo küla",
      NIMETUS_LIIGIGA: "Karjasoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00002754",
    },
    {
      TASE: "3",
      KOOD: "2757",
      NIMETUS: "Karjatnurme küla",
      NIMETUS_LIIGIGA: "Karjatnurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00002757",
    },
    {
      TASE: "3",
      KOOD: "2759",
      NIMETUS: "Karksi küla",
      NIMETUS_LIIGIGA: "Karksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00002759",
    },
    {
      TASE: "3",
      KOOD: "2760",
      NIMETUS: "Karkuse küla",
      NIMETUS_LIIGIGA: "Karkuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00002760",
    },
    {
      TASE: "3",
      KOOD: "2761",
      NIMETUS: "Karksi-Nuia linn",
      NIMETUS_LIIGIGA: "Karksi-Nuia linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00002761",
    },
    {
      TASE: "3",
      KOOD: "2762",
      NIMETUS: "Karkuse küla",
      NIMETUS_LIIGIGA: "Karkuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00002762",
    },
    {
      TASE: "3",
      KOOD: "2763",
      NIMETUS: "Karla küla",
      NIMETUS_LIIGIGA: "Karla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00002763",
    },
    {
      TASE: "3",
      KOOD: "2764",
      NIMETUS: "Karla küla",
      NIMETUS_LIIGIGA: "Karla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00002764",
    },
    {
      TASE: "3",
      KOOD: "2767",
      NIMETUS: "Karoli küla",
      NIMETUS_LIIGIGA: "Karoli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002767",
    },
    {
      TASE: "3",
      KOOD: "2769",
      NIMETUS: "Karste küla",
      NIMETUS_LIIGIGA: "Karste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00002769",
    },
    {
      TASE: "3",
      KOOD: "2772",
      NIMETUS: "Karu küla",
      NIMETUS_LIIGIGA: "Karu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00002772",
    },
    {
      TASE: "3",
      KOOD: "2774",
      NIMETUS: "Karula küla",
      NIMETUS_LIIGIGA: "Karula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00002774",
    },
    {
      TASE: "3",
      KOOD: "2775",
      NIMETUS: "Karula küla",
      NIMETUS_LIIGIGA: "Karula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00002775",
    },
    {
      TASE: "3",
      KOOD: "2776",
      NIMETUS: "Karula küla",
      NIMETUS_LIIGIGA: "Karula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00002776",
    },
    {
      TASE: "3",
      KOOD: "2777",
      NIMETUS: "Karuba küla",
      NIMETUS_LIIGIGA: "Karuba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002777",
    },
    {
      TASE: "3",
      KOOD: "2779",
      NIMETUS: "Varangu küla",
      NIMETUS_LIIGIGA: "Varangu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00002779",
    },
    {
      TASE: "3",
      KOOD: "2781",
      NIMETUS: "Karunga küla",
      NIMETUS_LIIGIGA: "Karunga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00002781",
    },
    {
      TASE: "3",
      KOOD: "2784",
      NIMETUS: "Karuse küla",
      NIMETUS_LIIGIGA: "Karuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002784",
    },
    {
      TASE: "3",
      KOOD: "2785",
      NIMETUS: "Karuste küla",
      NIMETUS_LIIGIGA: "Karuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002785",
    },
    {
      TASE: "3",
      KOOD: "2786",
      NIMETUS: "Kasakova küla",
      NIMETUS_LIIGIGA: "Kasakova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00002786",
    },
    {
      TASE: "3",
      KOOD: "2787",
      NIMETUS: "Kasari küla",
      NIMETUS_LIIGIGA: "Kasari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002787",
    },
    {
      TASE: "3",
      KOOD: "2789",
      NIMETUS: "Kasaritsa küla",
      NIMETUS_LIIGIGA: "Kasaritsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002789",
    },
    {
      TASE: "3",
      KOOD: "2792",
      NIMETUS: "Kaseküla",
      NIMETUS_LIIGIGA: "Kaseküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002792",
    },
    {
      TASE: "3",
      KOOD: "2794",
      NIMETUS: "Kasemetsa küla",
      NIMETUS_LIIGIGA: "Kasemetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00002794",
    },
    {
      TASE: "3",
      KOOD: "2797",
      NIMETUS: "Kasepere küla",
      NIMETUS_LIIGIGA: "Kasepere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00002797",
    },
    {
      TASE: "3",
      KOOD: "2799",
      NIMETUS: "Kasepää alevik",
      NIMETUS_LIIGIGA: "Kasepää alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00002799",
    },
    {
      TASE: "3",
      KOOD: "2800",
      NIMETUS: "Kasepää küla",
      NIMETUS_LIIGIGA: "Kasepää küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00002800",
    },
    {
      TASE: "3",
      KOOD: "2802",
      NIMETUS: "Kasevälja küla",
      NIMETUS_LIIGIGA: "Kasevälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002802",
    },
    {
      TASE: "3",
      KOOD: "2804",
      NIMETUS: "Kasispea küla",
      NIMETUS_LIIGIGA: "Kasispea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00002804",
    },
    {
      TASE: "3",
      KOOD: "2807",
      NIMETUS: "Kassari küla",
      NIMETUS_LIIGIGA: "Kassari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002807",
    },
    {
      TASE: "3",
      KOOD: "2809",
      NIMETUS: "Kassema küla",
      NIMETUS_LIIGIGA: "Kassema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00002809",
    },
    {
      TASE: "3",
      KOOD: "2812",
      NIMETUS: "Kassi küla",
      NIMETUS_LIIGIGA: "Kassi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00002812",
    },
    {
      TASE: "3",
      KOOD: "2813",
      NIMETUS: "Kassi küla",
      NIMETUS_LIIGIGA: "Kassi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00002813",
    },
    {
      TASE: "3",
      KOOD: "2815",
      NIMETUS: "Kassilaane küla",
      NIMETUS_LIIGIGA: "Kassilaane küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00002815",
    },
    {
      TASE: "3",
      KOOD: "2818",
      NIMETUS: "Kassinurme küla",
      NIMETUS_LIIGIGA: "Kassinurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00002818",
    },
    {
      TASE: "3",
      KOOD: "2819",
      NIMETUS: "Kassivere küla",
      NIMETUS_LIIGIGA: "Kassivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00002819",
    },
    {
      TASE: "3",
      KOOD: "2820",
      NIMETUS: "Kassiratta küla",
      NIMETUS_LIIGIGA: "Kassiratta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00002820",
    },
    {
      TASE: "3",
      KOOD: "2821",
      NIMETUS: "Kastamara küla",
      NIMETUS_LIIGIGA: "Kastamara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00002821",
    },
    {
      TASE: "3",
      KOOD: "2823",
      NIMETUS: "Kasti küla",
      NIMETUS_LIIGIGA: "Kasti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002823",
    },
    {
      TASE: "3",
      KOOD: "2824",
      NIMETUS: "Kasti küla",
      NIMETUS_LIIGIGA: "Kasti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00002824",
    },
    {
      TASE: "3",
      KOOD: "2826",
      NIMETUS: "Kastja küla",
      NIMETUS_LIIGIGA: "Kastja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002826",
    },
    {
      TASE: "3",
      KOOD: "2829",
      NIMETUS: "Kastli küla",
      NIMETUS_LIIGIGA: "Kastli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00002829",
    },
    {
      TASE: "3",
      KOOD: "2831",
      NIMETUS: "Kastmekoja küla",
      NIMETUS_LIIGIGA: "Kastmekoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00002831",
    },
    {
      TASE: "3",
      KOOD: "2834",
      NIMETUS: "Kastna küla",
      NIMETUS_LIIGIGA: "Kastna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00002834",
    },
    {
      TASE: "3",
      KOOD: "2835",
      NIMETUS: "Kastna küla",
      NIMETUS_LIIGIGA: "Kastna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00002835",
    },
    {
      TASE: "3",
      KOOD: "2837",
      NIMETUS: "Kastolatsi küla",
      NIMETUS_LIIGIGA: "Kastolatsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00002837",
    },
    {
      TASE: "3",
      KOOD: "2840",
      NIMETUS: "Kastre küla",
      NIMETUS_LIIGIGA: "Kastre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00002840",
    },
    {
      TASE: "3",
      KOOD: "2842",
      NIMETUS: "Võllinge küla",
      NIMETUS_LIIGIGA: "Võllinge küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00002842",
    },
    {
      TASE: "3",
      KOOD: "2845",
      NIMETUS: "Kasvandu küla",
      NIMETUS_LIIGIGA: "Kasvandu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00002845",
    },
    {
      TASE: "3",
      KOOD: "2848",
      NIMETUS: "Kata küla",
      NIMETUS_LIIGIGA: "Kata küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00002848",
    },
    {
      TASE: "3",
      KOOD: "2850",
      NIMETUS: "Katase küla",
      NIMETUS_LIIGIGA: "Katase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002850",
    },
    {
      TASE: "3",
      KOOD: "2851",
      NIMETUS: "Katku küla",
      NIMETUS_LIIGIGA: "Katku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00002851",
    },
    {
      TASE: "3",
      KOOD: "2852",
      NIMETUS: "Katsina küla",
      NIMETUS_LIIGIGA: "Katsina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00002852",
    },
    {
      TASE: "3",
      KOOD: "2853",
      NIMETUS: "Katela küla",
      NIMETUS_LIIGIGA: "Katela küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00002853",
    },
    {
      TASE: "3",
      KOOD: "2854",
      NIMETUS: "Kaubi küla",
      NIMETUS_LIIGIGA: "Kaubi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002854",
    },
    {
      TASE: "3",
      KOOD: "2856",
      NIMETUS: "Kaubi küla",
      NIMETUS_LIIGIGA: "Kaubi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00002856",
    },
    {
      TASE: "3",
      KOOD: "2857",
      NIMETUS: "Kaubi küla",
      NIMETUS_LIIGIGA: "Kaubi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002857",
    },
    {
      TASE: "3",
      KOOD: "2858",
      NIMETUS: "Kauda küla",
      NIMETUS_LIIGIGA: "Kauda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00002858",
    },
    {
      TASE: "3",
      KOOD: "2861",
      NIMETUS: "Kaude küla",
      NIMETUS_LIIGIGA: "Kaude küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00002861",
    },
    {
      TASE: "3",
      KOOD: "2866",
      NIMETUS: "Kaugu küla",
      NIMETUS_LIIGIGA: "Kaugu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002866",
    },
    {
      TASE: "3",
      KOOD: "2868",
      NIMETUS: "Kauksi küla",
      NIMETUS_LIIGIGA: "Kauksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002868",
    },
    {
      TASE: "3",
      KOOD: "2869",
      NIMETUS: "Kauksi küla",
      NIMETUS_LIIGIGA: "Kauksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00002869",
    },
    {
      TASE: "3",
      KOOD: "2872",
      NIMETUS: "Kaukvere küla",
      NIMETUS_LIIGIGA: "Kaukvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00002872",
    },
    {
      TASE: "3",
      KOOD: "2874",
      NIMETUS: "Kaunispe küla",
      NIMETUS_LIIGIGA: "Kaunispe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002874",
    },
    {
      TASE: "3",
      KOOD: "2875",
      NIMETUS: "Kauru küla",
      NIMETUS_LIIGIGA: "Kauru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00002875",
    },
    {
      TASE: "3",
      KOOD: "2877",
      NIMETUS: "Kaunissaare küla",
      NIMETUS_LIIGIGA: "Kaunissaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00002877",
    },
    {
      TASE: "3",
      KOOD: "2878",
      NIMETUS: "Kanissaare küla",
      NIMETUS_LIIGIGA: "Kanissaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002878",
    },
    {
      TASE: "3",
      KOOD: "2879",
      NIMETUS: "Kause küla",
      NIMETUS_LIIGIGA: "Kause küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002879",
    },
    {
      TASE: "3",
      KOOD: "2880",
      NIMETUS: "Kaurutootsi küla",
      NIMETUS_LIIGIGA: "Kaurutootsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00002880",
    },
    {
      TASE: "3",
      KOOD: "2881",
      NIMETUS: "Kauste küla",
      NIMETUS_LIIGIGA: "Kauste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002881",
    },
    {
      TASE: "3",
      KOOD: "2883",
      NIMETUS: "Kausi küla",
      NIMETUS_LIIGIGA: "Kausi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00002883",
    },
    {
      TASE: "3",
      KOOD: "2885",
      NIMETUS: "Kautjala küla",
      NIMETUS_LIIGIGA: "Kautjala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00002885",
    },
    {
      TASE: "3",
      KOOD: "2888",
      NIMETUS: "Kavandi küla",
      NIMETUS_LIIGIGA: "Kavandi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002888",
    },
    {
      TASE: "3",
      KOOD: "2891",
      NIMETUS: "Kavandu küla",
      NIMETUS_LIIGIGA: "Kavandu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00002891",
    },
    {
      TASE: "3",
      KOOD: "2893",
      NIMETUS: "Kavaru küla",
      NIMETUS_LIIGIGA: "Kavaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00002893",
    },
    {
      TASE: "3",
      KOOD: "2896",
      NIMETUS: "Kavastu küla",
      NIMETUS_LIIGIGA: "Kavastu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00002896",
    },
    {
      TASE: "3",
      KOOD: "2897",
      NIMETUS: "Kavastu küla",
      NIMETUS_LIIGIGA: "Kavastu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00002897",
    },
    {
      TASE: "3",
      KOOD: "2899",
      NIMETUS: "Kavõldi küla",
      NIMETUS_LIIGIGA: "Kavõldi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002899",
    },
    {
      TASE: "3",
      KOOD: "2900",
      NIMETUS: "Kehtna-Nurme küla",
      NIMETUS_LIIGIGA: "Kehtna-Nurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00002900",
    },
    {
      TASE: "3",
      KOOD: "2903",
      NIMETUS: "Keava alevik",
      NIMETUS_LIIGIGA: "Keava alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00002903",
    },
    {
      TASE: "3",
      KOOD: "2904",
      NIMETUS: "Kedre küla",
      NIMETUS_LIIGIGA: "Kedre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002904",
    },
    {
      TASE: "3",
      KOOD: "2906",
      NIMETUS: "Keedika küla",
      NIMETUS_LIIGIGA: "Keedika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002906",
    },
    {
      TASE: "3",
      KOOD: "2909",
      NIMETUS: "Keelva küla",
      NIMETUS_LIIGIGA: "Keelva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00002909",
    },
    {
      TASE: "3",
      KOOD: "2911",
      NIMETUS: "Keema küla",
      NIMETUS_LIIGIGA: "Keema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002911",
    },
    {
      TASE: "3",
      KOOD: "2914",
      NIMETUS: "Keeni küla",
      NIMETUS_LIIGIGA: "Keeni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00002914",
    },
    {
      TASE: "3",
      KOOD: "2916",
      NIMETUS: "Keeri küla",
      NIMETUS_LIIGIGA: "Keeri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00002916",
    },
    {
      TASE: "3",
      KOOD: "2919",
      NIMETUS: "Kehala küla",
      NIMETUS_LIIGIGA: "Kehala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00002919",
    },
    {
      TASE: "3",
      KOOD: "2922",
      NIMETUS: "Kehila küla",
      NIMETUS_LIIGIGA: "Kehila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002922",
    },
    {
      TASE: "3",
      KOOD: "2923",
      NIMETUS: "Lellapere-Nurme küla",
      NIMETUS_LIIGIGA: "Lellapere-Nurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00002923",
    },
    {
      TASE: "3",
      KOOD: "2924",
      NIMETUS: "Kehtna alevik",
      NIMETUS_LIIGIGA: "Kehtna alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00002924",
    },
    {
      TASE: "3",
      KOOD: "2925",
      NIMETUS: "Kehra küla",
      NIMETUS_LIIGIGA: "Kehra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00002925",
    },
    {
      TASE: "3",
      KOOD: "2927",
      NIMETUS: "Keibu küla",
      NIMETUS_LIIGIGA: "Keibu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00002927",
    },
    {
      TASE: "3",
      KOOD: "2928",
      NIMETUS: "Kehra linn",
      NIMETUS_LIIGIGA: "Kehra linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00002928",
    },
    {
      TASE: "3",
      KOOD: "2933",
      NIMETUS: "Kelba küla",
      NIMETUS_LIIGIGA: "Kelba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00002933",
    },
    {
      TASE: "3",
      KOOD: "2934",
      NIMETUS: "Kellamäe küla",
      NIMETUS_LIIGIGA: "Kellamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00002934",
    },
    {
      TASE: "3",
      KOOD: "2935",
      NIMETUS: "Kellamäe küla",
      NIMETUS_LIIGIGA: "Kellamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002935",
    },
    {
      TASE: "3",
      KOOD: "2936",
      NIMETUS: "Kellämäe küla",
      NIMETUS_LIIGIGA: "Kellämäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002936",
    },
    {
      TASE: "3",
      KOOD: "2939",
      NIMETUS: "Kellassaare küla",
      NIMETUS_LIIGIGA: "Kellassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00002939",
    },
    {
      TASE: "3",
      KOOD: "2941",
      NIMETUS: "Kellavere küla",
      NIMETUS_LIIGIGA: "Kellavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00002941",
    },
    {
      TASE: "3",
      KOOD: "2944",
      NIMETUS: "Kelnase küla",
      NIMETUS_LIIGIGA: "Kelnase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00002944",
    },
    {
      TASE: "3",
      KOOD: "2945",
      NIMETUS: "Kelvingi küla",
      NIMETUS_LIIGIGA: "Kelvingi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00002945",
    },
    {
      TASE: "3",
      KOOD: "2946",
      NIMETUS: "Kelu küla",
      NIMETUS_LIIGIGA: "Kelu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002946",
    },
    {
      TASE: "3",
      KOOD: "2949",
      NIMETUS: "Kemba küla",
      NIMETUS_LIIGIGA: "Kemba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00002949",
    },
    {
      TASE: "3",
      KOOD: "2952",
      NIMETUS: "Kenni küla",
      NIMETUS_LIIGIGA: "Kenni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00002952",
    },
    {
      TASE: "3",
      KOOD: "2955",
      NIMETUS: "Keo küla",
      NIMETUS_LIIGIGA: "Keo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00002955",
    },
    {
      TASE: "3",
      KOOD: "2958",
      NIMETUS: "Keravere küla",
      NIMETUS_LIIGIGA: "Keravere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002958",
    },
    {
      TASE: "3",
      KOOD: "2959",
      NIMETUS: "Kerema küla",
      NIMETUS_LIIGIGA: "Kerema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00002959",
    },
    {
      TASE: "3",
      KOOD: "2961",
      NIMETUS: "Keerba küla",
      NIMETUS_LIIGIGA: "Keerba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00002961",
    },
    {
      TASE: "3",
      KOOD: "2963",
      NIMETUS: "Kerepäälse küla",
      NIMETUS_LIIGIGA: "Kerepäälse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00002963",
    },
    {
      TASE: "3",
      KOOD: "2966",
      NIMETUS: "Keressaare küla",
      NIMETUS_LIIGIGA: "Keressaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00002966",
    },
    {
      TASE: "3",
      KOOD: "2968",
      NIMETUS: "Kergatsi küla",
      NIMETUS_LIIGIGA: "Kergatsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00002968",
    },
    {
      TASE: "3",
      KOOD: "2969",
      NIMETUS: "Kergu küla",
      NIMETUS_LIIGIGA: "Kergu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00002969",
    },
    {
      TASE: "3",
      KOOD: "2970",
      NIMETUS: "Kerguta küla",
      NIMETUS_LIIGIGA: "Kerguta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00002970",
    },
    {
      TASE: "3",
      KOOD: "2971",
      NIMETUS: "Keri küla",
      NIMETUS_LIIGIGA: "Keri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00002971",
    },
    {
      TASE: "3",
      KOOD: "2973",
      NIMETUS: "Kerita küla",
      NIMETUS_LIIGIGA: "Kerita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00002973",
    },
    {
      TASE: "3",
      KOOD: "2976",
      NIMETUS: "Kernu küla",
      NIMETUS_LIIGIGA: "Kernu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00002976",
    },
    {
      TASE: "3",
      KOOD: "2978",
      NIMETUS: "Kersalu küla",
      NIMETUS_LIIGIGA: "Kersalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00002978",
    },
    {
      TASE: "3",
      KOOD: "2979",
      NIMETUS: "Kesklahe küla",
      NIMETUS_LIIGIGA: "Kesklahe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00002979",
    },
    {
      TASE: "3",
      KOOD: "2980",
      NIMETUS: "Keskküla",
      NIMETUS_LIIGIGA: "Keskküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00002980",
    },
    {
      TASE: "3",
      KOOD: "2981",
      NIMETUS: "Kersleti küla",
      NIMETUS_LIIGIGA: "Kersleti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00002981",
    },
    {
      TASE: "3",
      KOOD: "2982",
      NIMETUS: "Keskranna küla",
      NIMETUS_LIIGIGA: "Keskranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002982",
    },
    {
      TASE: "3",
      KOOD: "2983",
      NIMETUS: "Keskküla",
      NIMETUS_LIIGIGA: "Keskküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002983",
    },
    {
      TASE: "3",
      KOOD: "2985",
      NIMETUS: "Keskvere küla",
      NIMETUS_LIIGIGA: "Keskvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00002985",
    },
    {
      TASE: "3",
      KOOD: "2986",
      NIMETUS: "Kestla küla",
      NIMETUS_LIIGIGA: "Kestla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00002986",
    },
    {
      TASE: "3",
      KOOD: "2987",
      NIMETUS: "Kesse küla",
      NIMETUS_LIIGIGA: "Kesse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00002987",
    },
    {
      TASE: "3",
      KOOD: "2989",
      NIMETUS: "Ketneri küla",
      NIMETUS_LIIGIGA: "Ketneri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00002989",
    },
    {
      TASE: "3",
      KOOD: "2990",
      NIMETUS: "Kesu küla",
      NIMETUS_LIIGIGA: "Kesu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00002990",
    },
    {
      TASE: "3",
      KOOD: "2991",
      NIMETUS: "Kesu küla",
      NIMETUS_LIIGIGA: "Kesu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00002991",
    },
    {
      TASE: "3",
      KOOD: "2993",
      NIMETUS: "Kibaru küla",
      NIMETUS_LIIGIGA: "Kibaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00002993",
    },
    {
      TASE: "3",
      KOOD: "2996",
      NIMETUS: "Kibeküla",
      NIMETUS_LIIGIGA: "Kibeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00002996",
    },
    {
      TASE: "3",
      KOOD: "2998",
      NIMETUS: "Kibena küla",
      NIMETUS_LIIGIGA: "Kibena küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00002998",
    },
    {
      TASE: "3",
      KOOD: "2999",
      NIMETUS: "Kibura küla",
      NIMETUS_LIIGIGA: "Kibura küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00002999",
    },
    {
      TASE: "3",
      KOOD: "3001",
      NIMETUS: "Kibuna küla",
      NIMETUS_LIIGIGA: "Kibuna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00003001",
    },
    {
      TASE: "3",
      KOOD: "3004",
      NIMETUS: "Kidaste küla",
      NIMETUS_LIIGIGA: "Kidaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003004",
    },
    {
      TASE: "3",
      KOOD: "3006",
      NIMETUS: "Kidise küla",
      NIMETUS_LIIGIGA: "Kidise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003006",
    },
    {
      TASE: "3",
      KOOD: "3009",
      NIMETUS: "Kiduspe küla",
      NIMETUS_LIIGIGA: "Kiduspe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003009",
    },
    {
      TASE: "3",
      KOOD: "3012",
      NIMETUS: "Kihelkonna alevik",
      NIMETUS_LIIGIGA: "Kihelkonna alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003012",
    },
    {
      TASE: "3",
      KOOD: "3014",
      NIMETUS: "Kihlepa küla",
      NIMETUS_LIIGIGA: "Kihlepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00003014",
    },
    {
      TASE: "3",
      KOOD: "3017",
      NIMETUS: "Kihlevere küla",
      NIMETUS_LIIGIGA: "Kihlevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00003017",
    },
    {
      TASE: "3",
      KOOD: "3019",
      NIMETUS: "Kihme küla",
      NIMETUS_LIIGIGA: "Kihme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00003019",
    },
    {
      TASE: "3",
      KOOD: "3022",
      NIMETUS: "Kihmla küla",
      NIMETUS_LIIGIGA: "Kihmla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00003022",
    },
    {
      TASE: "3",
      KOOD: "3025",
      NIMETUS: "Kiia küla",
      NIMETUS_LIIGIGA: "Kiia küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00003025",
    },
    {
      TASE: "3",
      KOOD: "3026",
      NIMETUS: "Kiidi küla",
      NIMETUS_LIIGIGA: "Kiidi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003026",
    },
    {
      TASE: "3",
      KOOD: "3027",
      NIMETUS: "Kiideva küla",
      NIMETUS_LIIGIGA: "Kiideva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00003027",
    },
    {
      TASE: "3",
      KOOD: "3030",
      NIMETUS: "Kiidjärve küla",
      NIMETUS_LIIGIGA: "Kiidjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00003030",
    },
    {
      TASE: "3",
      KOOD: "3032",
      NIMETUS: "Kiigevere küla",
      NIMETUS_LIIGIGA: "Kiigevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00003032",
    },
    {
      TASE: "3",
      KOOD: "3035",
      NIMETUS: "Kiikla küla",
      NIMETUS_LIIGIGA: "Kiikla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00003035",
    },
    {
      TASE: "3",
      KOOD: "3037",
      NIMETUS: "Kiilaspere küla",
      NIMETUS_LIIGIGA: "Kiilaspere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003037",
    },
    {
      TASE: "3",
      KOOD: "3041",
      NIMETUS: "Kiiova küla",
      NIMETUS_LIIGIGA: "Kiiova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003041",
    },
    {
      TASE: "3",
      KOOD: "3042",
      NIMETUS: "Kiini küla",
      NIMETUS_LIIGIGA: "Kiini küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00003042",
    },
    {
      TASE: "3",
      KOOD: "3045",
      NIMETUS: "Kiirassaare küla",
      NIMETUS_LIIGIGA: "Kiirassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003045",
    },
    {
      TASE: "3",
      KOOD: "3046",
      NIMETUS: "Kiisa küla",
      NIMETUS_LIIGIGA: "Kiisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00003046",
    },
    {
      TASE: "3",
      KOOD: "3047",
      NIMETUS: "Kiisa küla",
      NIMETUS_LIIGIGA: "Kiisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00003047",
    },
    {
      TASE: "3",
      KOOD: "3048",
      NIMETUS: "Kiisa alevik",
      NIMETUS_LIIGIGA: "Kiisa alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00003048",
    },
    {
      TASE: "3",
      KOOD: "3049",
      NIMETUS: "Kiisamaa küla",
      NIMETUS_LIIGIGA: "Kiisamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003049",
    },
    {
      TASE: "3",
      KOOD: "3051",
      NIMETUS: "Kiisli küla",
      NIMETUS_LIIGIGA: "Kiisli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00003051",
    },
    {
      TASE: "3",
      KOOD: "3052",
      NIMETUS: "Kiissa küla",
      NIMETUS_LIIGIGA: "Kiissa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00003052",
    },
    {
      TASE: "3",
      KOOD: "3053",
      NIMETUS: "Kiislova küla",
      NIMETUS_LIIGIGA: "Kiislova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003053",
    },
    {
      TASE: "3",
      KOOD: "3054",
      NIMETUS: "Kiivera küla",
      NIMETUS_LIIGIGA: "Kiivera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003054",
    },
    {
      TASE: "3",
      KOOD: "3055",
      NIMETUS: "Kiiu-Aabla küla",
      NIMETUS_LIIGIGA: "Kiiu-Aabla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003055",
    },
    {
      TASE: "3",
      KOOD: "3056",
      NIMETUS: "Kiiu alevik",
      NIMETUS_LIIGIGA: "Kiiu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003056",
    },
    {
      TASE: "3",
      KOOD: "3059",
      NIMETUS: "Kikaste küla",
      NIMETUS_LIIGIGA: "Kikaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00003059",
    },
    {
      TASE: "3",
      KOOD: "3061",
      NIMETUS: "Kikepera küla",
      NIMETUS_LIIGIGA: "Kikepera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00003061",
    },
    {
      TASE: "3",
      KOOD: "3064",
      NIMETUS: "Kikivere küla",
      NIMETUS_LIIGIGA: "Kikivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00003064",
    },
    {
      TASE: "3",
      KOOD: "3066",
      NIMETUS: "Kikka küla",
      NIMETUS_LIIGIGA: "Kikka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00003066",
    },
    {
      TASE: "3",
      KOOD: "3069",
      NIMETUS: "Kikkaoja küla",
      NIMETUS_LIIGIGA: "Kikkaoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00003069",
    },
    {
      TASE: "3",
      KOOD: "3071",
      NIMETUS: "Kiksova küla",
      NIMETUS_LIIGIGA: "Kiksova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003071",
    },
    {
      TASE: "3",
      KOOD: "3074",
      NIMETUS: "Kiku küla",
      NIMETUS_LIIGIGA: "Kiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00003074",
    },
    {
      TASE: "3",
      KOOD: "3077",
      NIMETUS: "Kildemaa küla",
      NIMETUS_LIIGIGA: "Kildemaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00003077",
    },
    {
      TASE: "3",
      KOOD: "3079",
      NIMETUS: "Kildu küla",
      NIMETUS_LIIGIGA: "Kildu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00003079",
    },
    {
      TASE: "3",
      KOOD: "3080",
      NIMETUS: "Kilgi küla",
      NIMETUS_LIIGIGA: "Kilgi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003080",
    },
    {
      TASE: "3",
      KOOD: "3082",
      NIMETUS: "Kilgi küla",
      NIMETUS_LIIGIGA: "Kilgi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003082",
    },
    {
      TASE: "3",
      KOOD: "3083",
      NIMETUS: "Kilingi-Nõmme linn",
      NIMETUS_LIIGIGA: "Kilingi-Nõmme linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00003083",
    },
    {
      TASE: "3",
      KOOD: "3084",
      NIMETUS: "Kilksama küla",
      NIMETUS_LIIGIGA: "Kilksama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00003084",
    },
    {
      TASE: "3",
      KOOD: "3087",
      NIMETUS: "Killinge küla",
      NIMETUS_LIIGIGA: "Killinge küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00003087",
    },
    {
      TASE: "3",
      KOOD: "3089",
      NIMETUS: "Kilomani küla",
      NIMETUS_LIIGIGA: "Kilomani küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003089",
    },
    {
      TASE: "3",
      KOOD: "3090",
      NIMETUS: "Kiltsi küla",
      NIMETUS_LIIGIGA: "Kiltsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00003090",
    },
    {
      TASE: "3",
      KOOD: "3092",
      NIMETUS: "Kiltsi alevik",
      NIMETUS_LIIGIGA: "Kiltsi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00003092",
    },
    {
      TASE: "3",
      KOOD: "3095",
      NIMETUS: "Kimalasõ küla",
      NIMETUS_LIIGIGA: "Kimalasõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003095",
    },
    {
      TASE: "3",
      KOOD: "3098",
      NIMETUS: "Kingli küla",
      NIMETUS_LIIGIGA: "Kingli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003098",
    },
    {
      TASE: "3",
      KOOD: "3099",
      NIMETUS: "Kinksi küla",
      NIMETUS_LIIGIGA: "Kinksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003099",
    },
    {
      TASE: "3",
      KOOD: "3100",
      NIMETUS: "Kingu küla",
      NIMETUS_LIIGIGA: "Kingu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00003100",
    },
    {
      TASE: "3",
      KOOD: "3103",
      NIMETUS: "Kipastu küla",
      NIMETUS_LIIGIGA: "Kipastu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00003103",
    },
    {
      TASE: "3",
      KOOD: "3106",
      NIMETUS: "Kipi küla",
      NIMETUS_LIIGIGA: "Kipi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003106",
    },
    {
      TASE: "3",
      KOOD: "3109",
      NIMETUS: "Kiraste küla",
      NIMETUS_LIIGIGA: "Kiraste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00003109",
    },
    {
      TASE: "3",
      KOOD: "3111",
      NIMETUS: "Kiratsi küla",
      NIMETUS_LIIGIGA: "Kiratsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003111",
    },
    {
      TASE: "3",
      KOOD: "3115",
      NIMETUS: "Kirbu küla",
      NIMETUS_LIIGIGA: "Kirbu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003115",
    },
    {
      TASE: "3",
      KOOD: "3116",
      NIMETUS: "Kirbu küla",
      NIMETUS_LIIGIGA: "Kirbu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00003116",
    },
    {
      TASE: "3",
      KOOD: "3119",
      NIMETUS: "Kirdalu küla",
      NIMETUS_LIIGIGA: "Kirdalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00003119",
    },
    {
      TASE: "3",
      KOOD: "3120",
      NIMETUS: "Kirikla küla",
      NIMETUS_LIIGIGA: "Kirikla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00003120",
    },
    {
      TASE: "3",
      KOOD: "3121",
      NIMETUS: "Kirepi küla",
      NIMETUS_LIIGIGA: "Kirepi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00003121",
    },
    {
      TASE: "3",
      KOOD: "3122",
      NIMETUS: "Kaarma-Kirikuküla",
      NIMETUS_LIIGIGA: "Kaarma-Kirikuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003122",
    },
    {
      TASE: "3",
      KOOD: "3123",
      NIMETUS: "Kärla-Kirikuküla",
      NIMETUS_LIIGIGA: "Kärla-Kirikuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003123",
    },
    {
      TASE: "3",
      KOOD: "3124",
      NIMETUS: "Kirikuküla",
      NIMETUS_LIIGIGA: "Kirikuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00003124",
    },
    {
      TASE: "3",
      KOOD: "3125",
      NIMETUS: "Kirikuküla",
      NIMETUS_LIIGIGA: "Kirikuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00003125",
    },
    {
      TASE: "3",
      KOOD: "3127",
      NIMETUS: "Kirikumõisa küla",
      NIMETUS_LIIGIGA: "Kirikumõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00003127",
    },
    {
      TASE: "3",
      KOOD: "3130",
      NIMETUS: "Kirikumäe küla",
      NIMETUS_LIIGIGA: "Kirikumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003130",
    },
    {
      TASE: "3",
      KOOD: "3131",
      NIMETUS: "Kirila küla",
      NIMETUS_LIIGIGA: "Kirila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00003131",
    },
    {
      TASE: "3",
      KOOD: "3132",
      NIMETUS: "Kirikuvalla küla",
      NIMETUS_LIIGIGA: "Kirikuvalla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00003132",
    },
    {
      TASE: "3",
      KOOD: "3135",
      NIMETUS: "Kirimäe küla",
      NIMETUS_LIIGIGA: "Kirimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00003135",
    },
    {
      TASE: "3",
      KOOD: "3137",
      NIMETUS: "Kirisaare küla",
      NIMETUS_LIIGIGA: "Kirisaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00003137",
    },
    {
      TASE: "3",
      KOOD: "3138",
      NIMETUS: "Kiritu küla",
      NIMETUS_LIIGIGA: "Kiritu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003138",
    },
    {
      TASE: "3",
      KOOD: "3140",
      NIMETUS: "Kirivalla küla",
      NIMETUS_LIIGIGA: "Kirivalla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00003140",
    },
    {
      TASE: "3",
      KOOD: "3142",
      NIMETUS: "Kirivere küla",
      NIMETUS_LIIGIGA: "Kirivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00003142",
    },
    {
      TASE: "3",
      KOOD: "3145",
      NIMETUS: "Kirmsi küla",
      NIMETUS_LIIGIGA: "Kirmsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00003145",
    },
    {
      TASE: "3",
      KOOD: "3146",
      NIMETUS: "Kirna küla",
      NIMETUS_LIIGIGA: "Kirna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003146",
    },
    {
      TASE: "3",
      KOOD: "3147",
      NIMETUS: "Kirna küla",
      NIMETUS_LIIGIGA: "Kirna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00003147",
    },
    {
      TASE: "3",
      KOOD: "3148",
      NIMETUS: "Kirna küla",
      NIMETUS_LIIGIGA: "Kirna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00003148",
    },
    {
      TASE: "3",
      KOOD: "3151",
      NIMETUS: "Kirtsi küla",
      NIMETUS_LIIGIGA: "Kirtsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00003151",
    },
    {
      TASE: "3",
      KOOD: "3152",
      NIMETUS: "Kiruma küla",
      NIMETUS_LIIGIGA: "Kiruma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003152",
    },
    {
      TASE: "3",
      KOOD: "3153",
      NIMETUS: "Kirumpää küla",
      NIMETUS_LIIGIGA: "Kirumpää küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003153",
    },
    {
      TASE: "3",
      KOOD: "3156",
      NIMETUS: "Kiruvere küla",
      NIMETUS_LIIGIGA: "Kiruvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00003156",
    },
    {
      TASE: "3",
      KOOD: "3159",
      NIMETUS: "Kiska küla",
      NIMETUS_LIIGIGA: "Kiska küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003159",
    },
    {
      TASE: "3",
      KOOD: "3160",
      NIMETUS: "Kitsa küla",
      NIMETUS_LIIGIGA: "Kitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003160",
    },
    {
      TASE: "3",
      KOOD: "3161",
      NIMETUS: "Kisuvere küla",
      NIMETUS_LIIGIGA: "Kisuvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00003161",
    },
    {
      TASE: "3",
      KOOD: "3162",
      NIMETUS: "Kitsemetsa küla",
      NIMETUS_LIIGIGA: "Kitsemetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00003162",
    },
    {
      TASE: "3",
      KOOD: "3164",
      NIMETUS: "Kitsõ küla",
      NIMETUS_LIIGIGA: "Kitsõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003164",
    },
    {
      TASE: "3",
      KOOD: "3167",
      NIMETUS: "Kitseküla",
      NIMETUS_LIIGIGA: "Kitseküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00003167",
    },
    {
      TASE: "3",
      KOOD: "3170",
      NIMETUS: "Kiuma küla",
      NIMETUS_LIIGIGA: "Kiuma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00003170",
    },
    {
      TASE: "3",
      KOOD: "3171",
      NIMETUS: "Kiviküla",
      NIMETUS_LIIGIGA: "Kiviküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00003171",
    },
    {
      TASE: "3",
      KOOD: "3173",
      NIMETUS: "Kiva küla",
      NIMETUS_LIIGIGA: "Kiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00003173",
    },
    {
      TASE: "3",
      KOOD: "3175",
      NIMETUS: "Kivi-Vigala küla",
      NIMETUS_LIIGIGA: "Kivi-Vigala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003175",
    },
    {
      TASE: "3",
      KOOD: "3178",
      NIMETUS: "Kivijärve küla",
      NIMETUS_LIIGIGA: "Kivijärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00003178",
    },
    {
      TASE: "3",
      KOOD: "3179",
      NIMETUS: "Kiviküla",
      NIMETUS_LIIGIGA: "Kiviküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00003179",
    },
    {
      TASE: "3",
      KOOD: "3180",
      NIMETUS: "Kiviküla",
      NIMETUS_LIIGIGA: "Kiviküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00003180",
    },
    {
      TASE: "3",
      KOOD: "3183",
      NIMETUS: "Kiviloo küla",
      NIMETUS_LIIGIGA: "Kiviloo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00003183",
    },
    {
      TASE: "3",
      KOOD: "3185",
      NIMETUS: "Kivilõppe küla",
      NIMETUS_LIIGIGA: "Kivilõppe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00003185",
    },
    {
      TASE: "3",
      KOOD: "3188",
      NIMETUS: "Kivimäe küla",
      NIMETUS_LIIGIGA: "Kivimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00003188",
    },
    {
      TASE: "3",
      KOOD: "3190",
      NIMETUS: "Kivinõmme küla",
      NIMETUS_LIIGIGA: "Kivinõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00003190",
    },
    {
      TASE: "3",
      KOOD: "3193",
      NIMETUS: "Kiviora küla",
      NIMETUS_LIIGIGA: "Kiviora küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003193",
    },
    {
      TASE: "3",
      KOOD: "3195",
      NIMETUS: "Kivitammi küla",
      NIMETUS_LIIGIGA: "Kivitammi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00003195",
    },
    {
      TASE: "3",
      KOOD: "3196",
      NIMETUS: "Kleemu küla",
      NIMETUS_LIIGIGA: "Kleemu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003196",
    },
    {
      TASE: "3",
      KOOD: "3197",
      NIMETUS: "Klistina küla",
      NIMETUS_LIIGIGA: "Klistina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003197",
    },
    {
      TASE: "3",
      KOOD: "3199",
      NIMETUS: "Kliima küla",
      NIMETUS_LIIGIGA: "Kliima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003199",
    },
    {
      TASE: "3",
      KOOD: "3202",
      NIMETUS: "Kloodi küla",
      NIMETUS_LIIGIGA: "Kloodi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00003202",
    },
    {
      TASE: "3",
      KOOD: "3205",
      NIMETUS: "Klooga alevik",
      NIMETUS_LIIGIGA: "Klooga alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00003205",
    },
    {
      TASE: "3",
      KOOD: "3207",
      NIMETUS: "Kloogaranna küla",
      NIMETUS_LIIGIGA: "Kloogaranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00003207",
    },
    {
      TASE: "3",
      KOOD: "3210",
      NIMETUS: "Kloostri küla",
      NIMETUS_LIIGIGA: "Kloostri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003210",
    },
    {
      TASE: "3",
      KOOD: "3214",
      NIMETUS: "Kobela alevik",
      NIMETUS_LIIGIGA: "Kobela alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00003214",
    },
    {
      TASE: "3",
      KOOD: "3216",
      NIMETUS: "Kobilu küla",
      NIMETUS_LIIGIGA: "Kobilu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00003216",
    },
    {
      TASE: "3",
      KOOD: "3219",
      NIMETUS: "Kobra küla",
      NIMETUS_LIIGIGA: "Kobra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00003219",
    },
    {
      TASE: "3",
      KOOD: "3221",
      NIMETUS: "Kobratu küla",
      NIMETUS_LIIGIGA: "Kobratu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00003221",
    },
    {
      TASE: "3",
      KOOD: "3224",
      NIMETUS: "Kobru küla",
      NIMETUS_LIIGIGA: "Kobru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00003224",
    },
    {
      TASE: "3",
      KOOD: "3226",
      NIMETUS: "Kobruvere küla",
      NIMETUS_LIIGIGA: "Kobruvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00003226",
    },
    {
      TASE: "3",
      KOOD: "3229",
      NIMETUS: "Kodasema küla",
      NIMETUS_LIIGIGA: "Kodasema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00003229",
    },
    {
      TASE: "3",
      KOOD: "3232",
      NIMETUS: "Kodasoo küla",
      NIMETUS_LIIGIGA: "Kodasoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003232",
    },
    {
      TASE: "3",
      KOOD: "3235",
      NIMETUS: "Kodeste küla",
      NIMETUS_LIIGIGA: "Kodeste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003235",
    },
    {
      TASE: "3",
      KOOD: "3237",
      NIMETUS: "Kodesmaa küla",
      NIMETUS_LIIGIGA: "Kodesmaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00003237",
    },
    {
      TASE: "3",
      KOOD: "3239",
      NIMETUS: "Kodijärve küla",
      NIMETUS_LIIGIGA: "Kodijärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00003239",
    },
    {
      TASE: "3",
      KOOD: "3240",
      NIMETUS: "Kodila-Metsküla",
      NIMETUS_LIIGIGA: "Kodila-Metsküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00003240",
    },
    {
      TASE: "3",
      KOOD: "3242",
      NIMETUS: "Kodila küla",
      NIMETUS_LIIGIGA: "Kodila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00003242",
    },
    {
      TASE: "3",
      KOOD: "3244",
      NIMETUS: "Kodismaa küla",
      NIMETUS_LIIGIGA: "Kodismaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00003244",
    },
    {
      TASE: "3",
      KOOD: "3247",
      NIMETUS: "Koela küla",
      NIMETUS_LIIGIGA: "Koela küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00003247",
    },
    {
      TASE: "3",
      KOOD: "3250",
      NIMETUS: "Koemetsa küla",
      NIMETUS_LIIGIGA: "Koemetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003250",
    },
    {
      TASE: "3",
      KOOD: "3251",
      NIMETUS: "Koeri küla",
      NIMETUS_LIIGIGA: "Koeri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003251",
    },
    {
      TASE: "3",
      KOOD: "3252",
      NIMETUS: "Koeravere küla",
      NIMETUS_LIIGIGA: "Koeravere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00003252",
    },
    {
      TASE: "3",
      KOOD: "3253",
      NIMETUS: "Kogri küla",
      NIMETUS_LIIGIGA: "Kogri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003253",
    },
    {
      TASE: "3",
      KOOD: "3255",
      NIMETUS: "Koeru alevik",
      NIMETUS_LIIGIGA: "Koeru alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00003255",
    },
    {
      TASE: "3",
      KOOD: "3256",
      NIMETUS: "Kogrõ küla",
      NIMETUS_LIIGIGA: "Kogrõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003256",
    },
    {
      TASE: "3",
      KOOD: "3258",
      NIMETUS: "Kogula küla",
      NIMETUS_LIIGIGA: "Kogula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003258",
    },
    {
      TASE: "3",
      KOOD: "3260",
      NIMETUS: "Koguva küla",
      NIMETUS_LIIGIGA: "Koguva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00003260",
    },
    {
      TASE: "3",
      KOOD: "3261",
      NIMETUS: "Kohala-Eesküla",
      NIMETUS_LIIGIGA: "Kohala-Eesküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00003261",
    },
    {
      TASE: "3",
      KOOD: "3263",
      NIMETUS: "Kohala küla",
      NIMETUS_LIIGIGA: "Kohala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00003263",
    },
    {
      TASE: "3",
      KOOD: "3266",
      NIMETUS: "Kohatu küla",
      NIMETUS_LIIGIGA: "Kohatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00003266",
    },
    {
      TASE: "3",
      KOOD: "3268",
      NIMETUS: "Kohila alev",
      NIMETUS_LIIGIGA: "Kohila alev",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00003268",
    },
    {
      TASE: "3",
      KOOD: "3269",
      NIMETUS: "Kohtla küla",
      NIMETUS_LIIGIGA: "Kohtla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00003269",
    },
    {
      TASE: "3",
      KOOD: "3270",
      NIMETUS: "Kohtla-Nõmme alev",
      NIMETUS_LIIGIGA: "Kohtla-Nõmme alev",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00003270",
    },
    {
      TASE: "3",
      KOOD: "3271",
      NIMETUS: "Koidma küla",
      NIMETUS_LIIGIGA: "Koidma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003271",
    },
    {
      TASE: "3",
      KOOD: "3272",
      NIMETUS: "Kohtru küla",
      NIMETUS_LIIGIGA: "Kohtru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003272",
    },
    {
      TASE: "3",
      KOOD: "3273",
      NIMETUS: "Koidu-Ellavere küla",
      NIMETUS_LIIGIGA: "Koidu-Ellavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00003273",
    },
    {
      TASE: "3",
      KOOD: "3275",
      NIMETUS: "Koidu küla",
      NIMETUS_LIIGIGA: "Koidu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00003275",
    },
    {
      TASE: "3",
      KOOD: "3276",
      NIMETUS: "Koheri küla",
      NIMETUS_LIIGIGA: "Koheri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00003276",
    },
    {
      TASE: "3",
      KOOD: "3277",
      NIMETUS: "Koidula küla",
      NIMETUS_LIIGIGA: "Koidula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003277",
    },
    {
      TASE: "3",
      KOOD: "3278",
      NIMETUS: "Koidula küla",
      NIMETUS_LIIGIGA: "Koidula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003278",
    },
    {
      TASE: "3",
      KOOD: "3279",
      NIMETUS: "Koiduvälja küla",
      NIMETUS_LIIGIGA: "Koiduvälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003279",
    },
    {
      TASE: "3",
      KOOD: "3280",
      NIMETUS: "Koigera küla",
      NIMETUS_LIIGIGA: "Koigera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00003280",
    },
    {
      TASE: "3",
      KOOD: "3281",
      NIMETUS: "Koidu küla",
      NIMETUS_LIIGIGA: "Koidu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00003281",
    },
    {
      TASE: "3",
      KOOD: "3282",
      NIMETUS: "Koigi küla",
      NIMETUS_LIIGIGA: "Koigi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00003282",
    },
    {
      TASE: "3",
      KOOD: "3283",
      NIMETUS: "Koigi küla",
      NIMETUS_LIIGIGA: "Koigi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00003283",
    },
    {
      TASE: "3",
      KOOD: "3284",
      NIMETUS: "Koigi küla",
      NIMETUS_LIIGIGA: "Koigi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003284",
    },
    {
      TASE: "3",
      KOOD: "3286",
      NIMETUS: "Koigu küla",
      NIMETUS_LIIGIGA: "Koigu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00003286",
    },
    {
      TASE: "3",
      KOOD: "3287",
      NIMETUS: "Koigu küla",
      NIMETUS_LIIGIGA: "Koigu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00003287",
    },
    {
      TASE: "3",
      KOOD: "3288",
      NIMETUS: "Koiduküla",
      NIMETUS_LIIGIGA: "Koiduküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00003288",
    },
    {
      TASE: "3",
      KOOD: "3289",
      NIMETUS: "Koikküla",
      NIMETUS_LIIGIGA: "Koikküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00003289",
    },
    {
      TASE: "3",
      KOOD: "3292",
      NIMETUS: "Koikla küla",
      NIMETUS_LIIGIGA: "Koikla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003292",
    },
    {
      TASE: "3",
      KOOD: "3294",
      NIMETUS: "Koikse küla",
      NIMETUS_LIIGIGA: "Koikse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00003294",
    },
    {
      TASE: "3",
      KOOD: "3295",
      NIMETUS: "Koila küla",
      NIMETUS_LIIGIGA: "Koila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00003295",
    },
    {
      TASE: "3",
      KOOD: "3296",
      NIMETUS: "Koila küla",
      NIMETUS_LIIGIGA: "Koila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00003296",
    },
    {
      TASE: "3",
      KOOD: "3297",
      NIMETUS: "Koila küla",
      NIMETUS_LIIGIGA: "Koila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00003297",
    },
    {
      TASE: "3",
      KOOD: "3300",
      NIMETUS: "Koitjärve küla",
      NIMETUS_LIIGIGA: "Koitjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003300",
    },
    {
      TASE: "3",
      KOOD: "3301",
      NIMETUS: "Koipsi küla",
      NIMETUS_LIIGIGA: "Koipsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00003301",
    },
    {
      TASE: "3",
      KOOD: "3302",
      NIMETUS: "Koimula küla",
      NIMETUS_LIIGIGA: "Koimula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00003302",
    },
    {
      TASE: "3",
      KOOD: "3304",
      NIMETUS: "Koiva küla",
      NIMETUS_LIIGIGA: "Koiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00003304",
    },
    {
      TASE: "3",
      KOOD: "3307",
      NIMETUS: "Kojastu küla",
      NIMETUS_LIIGIGA: "Kojastu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003307",
    },
    {
      TASE: "3",
      KOOD: "3310",
      NIMETUS: "Kokanurga küla",
      NIMETUS_LIIGIGA: "Kokanurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00003310",
    },
    {
      TASE: "3",
      KOOD: "3313",
      NIMETUS: "Kokaviidika küla",
      NIMETUS_LIIGIGA: "Kokaviidika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00003313",
    },
    {
      TASE: "3",
      KOOD: "3315",
      NIMETUS: "Koke küla",
      NIMETUS_LIIGIGA: "Koke küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00003315",
    },
    {
      TASE: "3",
      KOOD: "3318",
      NIMETUS: "Kokõmäe küla",
      NIMETUS_LIIGIGA: "Kokõmäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003318",
    },
    {
      TASE: "3",
      KOOD: "3320",
      NIMETUS: "Koki küla",
      NIMETUS_LIIGIGA: "Koki küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003320",
    },
    {
      TASE: "3",
      KOOD: "3321",
      NIMETUS: "Kokre küla",
      NIMETUS_LIIGIGA: "Kokre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00003321",
    },
    {
      TASE: "3",
      KOOD: "3323",
      NIMETUS: "Kokora küla",
      NIMETUS_LIIGIGA: "Kokora küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00003323",
    },
    {
      TASE: "3",
      KOOD: "3325",
      NIMETUS: "Koksi küla",
      NIMETUS_LIIGIGA: "Koksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003325",
    },
    {
      TASE: "3",
      KOOD: "3326",
      NIMETUS: "Kokuta küla",
      NIMETUS_LIIGIGA: "Kokuta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003326",
    },
    {
      TASE: "3",
      KOOD: "3327",
      NIMETUS: "Kokõjüri küla",
      NIMETUS_LIIGIGA: "Kokõjüri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003327",
    },
    {
      TASE: "3",
      KOOD: "3328",
      NIMETUS: "Koksvere küla",
      NIMETUS_LIIGIGA: "Koksvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00003328",
    },
    {
      TASE: "3",
      KOOD: "3329",
      NIMETUS: "Kokõ küla",
      NIMETUS_LIIGIGA: "Kokõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003329",
    },
    {
      TASE: "3",
      KOOD: "3331",
      NIMETUS: "Koldamäe küla",
      NIMETUS_LIIGIGA: "Koldamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00003331",
    },
    {
      TASE: "3",
      KOOD: "3333",
      NIMETUS: "Kolepi küla",
      NIMETUS_LIIGIGA: "Kolepi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003333",
    },
    {
      TASE: "3",
      KOOD: "3334",
      NIMETUS: "Kolga küla",
      NIMETUS_LIIGIGA: "Kolga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003334",
    },
    {
      TASE: "3",
      KOOD: "3336",
      NIMETUS: "Kolga alevik",
      NIMETUS_LIIGIGA: "Kolga alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003336",
    },
    {
      TASE: "3",
      KOOD: "3337",
      NIMETUS: "Kolga küla",
      NIMETUS_LIIGIGA: "Kolga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003337",
    },
    {
      TASE: "3",
      KOOD: "3338",
      NIMETUS: "Kolga küla",
      NIMETUS_LIIGIGA: "Kolga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00003338",
    },
    {
      TASE: "3",
      KOOD: "3340",
      NIMETUS: "Kolga-Jaani alevik",
      NIMETUS_LIIGIGA: "Kolga-Jaani alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00003340",
    },
    {
      TASE: "3",
      KOOD: "3341",
      NIMETUS: "Kolila küla",
      NIMETUS_LIIGIGA: "Kolila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00003341",
    },
    {
      TASE: "3",
      KOOD: "3342",
      NIMETUS: "Kolgu küla",
      NIMETUS_LIIGIGA: "Kolgu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003342",
    },
    {
      TASE: "3",
      KOOD: "3343",
      NIMETUS: "Kolgaküla",
      NIMETUS_LIIGIGA: "Kolgaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003343",
    },
    {
      TASE: "3",
      KOOD: "3345",
      NIMETUS: "Koljaku küla",
      NIMETUS_LIIGIGA: "Koljaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00003345",
    },
    {
      TASE: "3",
      KOOD: "3348",
      NIMETUS: "Koljala küla",
      NIMETUS_LIIGIGA: "Koljala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00003348",
    },
    {
      TASE: "3",
      KOOD: "3350",
      NIMETUS: "Kolkja alevik",
      NIMETUS_LIIGIGA: "Kolkja alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00003350",
    },
    {
      TASE: "3",
      KOOD: "3353",
      NIMETUS: "Kolli küla",
      NIMETUS_LIIGIGA: "Kolli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00003353",
    },
    {
      TASE: "3",
      KOOD: "3355",
      NIMETUS: "Kollino küla",
      NIMETUS_LIIGIGA: "Kollino küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00003355",
    },
    {
      TASE: "3",
      KOOD: "3358",
      NIMETUS: "Kolodavitsa küla",
      NIMETUS_LIIGIGA: "Kolodavitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003358",
    },
    {
      TASE: "3",
      KOOD: "3359",
      NIMETUS: "Kolossova küla",
      NIMETUS_LIIGIGA: "Kolossova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003359",
    },
    {
      TASE: "3",
      KOOD: "3360",
      NIMETUS: "Koloreino küla",
      NIMETUS_LIIGIGA: "Koloreino küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003360",
    },
    {
      TASE: "3",
      KOOD: "3361",
      NIMETUS: "Kolu küla",
      NIMETUS_LIIGIGA: "Kolu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00003361",
    },
    {
      TASE: "3",
      KOOD: "3363",
      NIMETUS: "Kolu küla",
      NIMETUS_LIIGIGA: "Kolu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00003363",
    },
    {
      TASE: "3",
      KOOD: "3364",
      NIMETUS: "Kolu küla",
      NIMETUS_LIIGIGA: "Kolu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00003364",
    },
    {
      TASE: "3",
      KOOD: "3365",
      NIMETUS: "Koluta küla",
      NIMETUS_LIIGIGA: "Koluta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003365",
    },
    {
      TASE: "3",
      KOOD: "3366",
      NIMETUS: "Koluvere küla",
      NIMETUS_LIIGIGA: "Koluvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00003366",
    },
    {
      TASE: "3",
      KOOD: "3367",
      NIMETUS: "Koluvere küla",
      NIMETUS_LIIGIGA: "Koluvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00003367",
    },
    {
      TASE: "3",
      KOOD: "3369",
      NIMETUS: "Komsi küla",
      NIMETUS_LIIGIGA: "Komsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00003369",
    },
    {
      TASE: "3",
      KOOD: "3372",
      NIMETUS: "Konguta küla",
      NIMETUS_LIIGIGA: "Konguta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00003372",
    },
    {
      TASE: "3",
      KOOD: "3373",
      NIMETUS: "Konnapere küla",
      NIMETUS_LIIGIGA: "Konnapere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003373",
    },
    {
      TASE: "3",
      KOOD: "3374",
      NIMETUS: "Kundsa küla",
      NIMETUS_LIIGIGA: "Kundsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003374",
    },
    {
      TASE: "3",
      KOOD: "3375",
      NIMETUS: "Konju küla",
      NIMETUS_LIIGIGA: "Konju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00003375",
    },
    {
      TASE: "3",
      KOOD: "3377",
      NIMETUS: "Konsu küla",
      NIMETUS_LIIGIGA: "Konsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00003377",
    },
    {
      TASE: "3",
      KOOD: "3380",
      NIMETUS: "Konuvere küla",
      NIMETUS_LIIGIGA: "Konuvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003380",
    },
    {
      TASE: "3",
      KOOD: "3383",
      NIMETUS: "Koobassaare küla",
      NIMETUS_LIIGIGA: "Koobassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00003383",
    },
    {
      TASE: "3",
      KOOD: "3385",
      NIMETUS: "Koogi küla",
      NIMETUS_LIIGIGA: "Koogi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00003385",
    },
    {
      TASE: "3",
      KOOD: "3386",
      NIMETUS: "Koogi küla",
      NIMETUS_LIIGIGA: "Koogi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00003386",
    },
    {
      TASE: "3",
      KOOD: "3389",
      NIMETUS: "Koogimäe küla",
      NIMETUS_LIIGIGA: "Koogimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00003389",
    },
    {
      TASE: "3",
      KOOD: "3391",
      NIMETUS: "Koogiste küla",
      NIMETUS_LIIGIGA: "Koogiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00003391",
    },
    {
      TASE: "3",
      KOOD: "3394",
      NIMETUS: "Koogu küla",
      NIMETUS_LIIGIGA: "Koogu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00003394",
    },
    {
      TASE: "3",
      KOOD: "3396",
      NIMETUS: "Kookla küla",
      NIMETUS_LIIGIGA: "Kookla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00003396",
    },
    {
      TASE: "3",
      KOOD: "3399",
      NIMETUS: "Kooli küla",
      NIMETUS_LIIGIGA: "Kooli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00003399",
    },
    {
      TASE: "3",
      KOOD: "3401",
      NIMETUS: "Koolimäe küla",
      NIMETUS_LIIGIGA: "Koolimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00003401",
    },
    {
      TASE: "3",
      KOOD: "3404",
      NIMETUS: "Koolma küla",
      NIMETUS_LIIGIGA: "Koolma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00003404",
    },
    {
      TASE: "3",
      KOOD: "3405",
      NIMETUS: "Koolma küla",
      NIMETUS_LIIGIGA: "Koolma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00003405",
    },
    {
      TASE: "3",
      KOOD: "3406",
      NIMETUS: "Koolmajärve küla",
      NIMETUS_LIIGIGA: "Koolmajärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00003406",
    },
    {
      TASE: "3",
      KOOD: "3407",
      NIMETUS: "Koonga küla",
      NIMETUS_LIIGIGA: "Koonga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003407",
    },
    {
      TASE: "3",
      KOOD: "3410",
      NIMETUS: "Koonu küla",
      NIMETUS_LIIGIGA: "Koonu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00003410",
    },
    {
      TASE: "3",
      KOOD: "3412",
      NIMETUS: "Koopsi küla",
      NIMETUS_LIIGIGA: "Koopsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00003412",
    },
    {
      TASE: "3",
      KOOD: "3415",
      NIMETUS: "Kooraste küla",
      NIMETUS_LIIGIGA: "Kooraste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00003415",
    },
    {
      TASE: "3",
      KOOD: "3417",
      NIMETUS: "Koordi küla",
      NIMETUS_LIIGIGA: "Koordi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00003417",
    },
    {
      TASE: "3",
      KOOD: "3420",
      NIMETUS: "Koorküla",
      NIMETUS_LIIGIGA: "Koorküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00003420",
    },
    {
      TASE: "3",
      KOOD: "3422",
      NIMETUS: "Koorvere küla",
      NIMETUS_LIIGIGA: "Koorvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00003422",
    },
    {
      TASE: "3",
      KOOD: "3425",
      NIMETUS: "Koosa küla",
      NIMETUS_LIIGIGA: "Koosa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00003425",
    },
    {
      TASE: "3",
      KOOD: "3427",
      NIMETUS: "Koosalaane küla",
      NIMETUS_LIIGIGA: "Koosalaane küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00003427",
    },
    {
      TASE: "3",
      KOOD: "3430",
      NIMETUS: "Kootsi küla",
      NIMETUS_LIIGIGA: "Kootsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00003430",
    },
    {
      TASE: "3",
      KOOD: "3431",
      NIMETUS: "Koovi küla",
      NIMETUS_LIIGIGA: "Koovi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003431",
    },
    {
      TASE: "3",
      KOOD: "3432",
      NIMETUS: "Koovälja küla",
      NIMETUS_LIIGIGA: "Koovälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00003432",
    },
    {
      TASE: "3",
      KOOD: "3433",
      NIMETUS: "Kopa küla",
      NIMETUS_LIIGIGA: "Kopa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003433",
    },
    {
      TASE: "3",
      KOOD: "3435",
      NIMETUS: "Kopli küla",
      NIMETUS_LIIGIGA: "Kopli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00003435",
    },
    {
      TASE: "3",
      KOOD: "3436",
      NIMETUS: "Kopli küla",
      NIMETUS_LIIGIGA: "Kopli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00003436",
    },
    {
      TASE: "3",
      KOOD: "3437",
      NIMETUS: "Kopli küla",
      NIMETUS_LIIGIGA: "Kopli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003437",
    },
    {
      TASE: "3",
      KOOD: "3438",
      NIMETUS: "Koplitaguse küla",
      NIMETUS_LIIGIGA: "Koplitaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00003438",
    },
    {
      TASE: "3",
      KOOD: "3439",
      NIMETUS: "Koppelmaa küla",
      NIMETUS_LIIGIGA: "Koppelmaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00003439",
    },
    {
      TASE: "3",
      KOOD: "3442",
      NIMETUS: "Korba küla",
      NIMETUS_LIIGIGA: "Korba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00003442",
    },
    {
      TASE: "3",
      KOOD: "3444",
      NIMETUS: "Korela küla",
      NIMETUS_LIIGIGA: "Korela küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003444",
    },
    {
      TASE: "3",
      KOOD: "3445",
      NIMETUS: "Korju küla",
      NIMETUS_LIIGIGA: "Korju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003445",
    },
    {
      TASE: "3",
      KOOD: "3446",
      NIMETUS: "Korgõmõisa küla",
      NIMETUS_LIIGIGA: "Korgõmõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003446",
    },
    {
      TASE: "3",
      KOOD: "3447",
      NIMETUS: "Korijärve küla",
      NIMETUS_LIIGIGA: "Korijärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00003447",
    },
    {
      TASE: "3",
      KOOD: "3449",
      NIMETUS: "Korjuse küla",
      NIMETUS_LIIGIGA: "Korjuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00003449",
    },
    {
      TASE: "3",
      KOOD: "3450",
      NIMETUS: "Kornitsa küla",
      NIMETUS_LIIGIGA: "Kornitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003450",
    },
    {
      TASE: "3",
      KOOD: "3451",
      NIMETUS: "Koorla küla",
      NIMETUS_LIIGIGA: "Koorla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003451",
    },
    {
      TASE: "3",
      KOOD: "3452",
      NIMETUS: "Korkuna küla",
      NIMETUS_LIIGIGA: "Korkuna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00003452",
    },
    {
      TASE: "3",
      KOOD: "3454",
      NIMETUS: "Korski küla",
      NIMETUS_LIIGIGA: "Korski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003454",
    },
    {
      TASE: "3",
      KOOD: "3457",
      NIMETUS: "Koruste küla",
      NIMETUS_LIIGIGA: "Koruste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00003457",
    },
    {
      TASE: "3",
      KOOD: "3458",
      NIMETUS: "Kose küla",
      NIMETUS_LIIGIGA: "Kose küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00003458",
    },
    {
      TASE: "3",
      KOOD: "3459",
      NIMETUS: "Kose küla",
      NIMETUS_LIIGIGA: "Kose küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00003459",
    },
    {
      TASE: "3",
      KOOD: "3460",
      NIMETUS: "Kose alevik",
      NIMETUS_LIIGIGA: "Kose alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00003460",
    },
    {
      TASE: "3",
      KOOD: "3461",
      NIMETUS: "Kose küla",
      NIMETUS_LIIGIGA: "Kose küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "251",
      ADS_OID: "AY00003461",
    },
    {
      TASE: "3",
      KOOD: "3462",
      NIMETUS: "Kose alevik",
      NIMETUS_LIIGIGA: "Kose alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003462",
    },
    {
      TASE: "3",
      KOOD: "3464",
      NIMETUS: "Kose-Uuemõisa alevik",
      NIMETUS_LIIGIGA: "Kose-Uuemõisa alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00003464",
    },
    {
      TASE: "3",
      KOOD: "3467",
      NIMETUS: "Koseveski küla",
      NIMETUS_LIIGIGA: "Koseveski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00003467",
    },
    {
      TASE: "3",
      KOOD: "3468",
      NIMETUS: "Kossa küla",
      NIMETUS_LIIGIGA: "Kossa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003468",
    },
    {
      TASE: "3",
      KOOD: "3469",
      NIMETUS: "Kosova küla",
      NIMETUS_LIIGIGA: "Kosova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00003469",
    },
    {
      TASE: "3",
      KOOD: "3470",
      NIMETUS: "Kostkova küla",
      NIMETUS_LIIGIGA: "Kostkova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003470",
    },
    {
      TASE: "3",
      KOOD: "3471",
      NIMETUS: "Kostiranna küla",
      NIMETUS_LIIGIGA: "Kostiranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00003471",
    },
    {
      TASE: "3",
      KOOD: "3472",
      NIMETUS: "Kostivere alevik",
      NIMETUS_LIIGIGA: "Kostivere alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00003472",
    },
    {
      TASE: "3",
      KOOD: "3473",
      NIMETUS: "Kosta küla",
      NIMETUS_LIIGIGA: "Kosta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00003473",
    },
    {
      TASE: "3",
      KOOD: "3474",
      NIMETUS: "Kosu küla",
      NIMETUS_LIIGIGA: "Kosu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003474",
    },
    {
      TASE: "3",
      KOOD: "3477",
      NIMETUS: "Kotinuka küla",
      NIMETUS_LIIGIGA: "Kotinuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "251",
      ADS_OID: "AY00003477",
    },
    {
      TASE: "3",
      KOOD: "3478",
      NIMETUS: "Kotka küla",
      NIMETUS_LIIGIGA: "Kotka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003478",
    },
    {
      TASE: "3",
      KOOD: "3480",
      NIMETUS: "Kotka küla",
      NIMETUS_LIIGIGA: "Kotka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003480",
    },
    {
      TASE: "3",
      KOOD: "3482",
      NIMETUS: "Kotlandi küla",
      NIMETUS_LIIGIGA: "Kotlandi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003482",
    },
    {
      TASE: "3",
      KOOD: "3483",
      NIMETUS: "Kotsma küla",
      NIMETUS_LIIGIGA: "Kotsma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003483",
    },
    {
      TASE: "3",
      KOOD: "3486",
      NIMETUS: "Kraavi küla",
      NIMETUS_LIIGIGA: "Kraavi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00003486",
    },
    {
      TASE: "3",
      KOOD: "3489",
      NIMETUS: "Krabi küla",
      NIMETUS_LIIGIGA: "Krabi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003489",
    },
    {
      TASE: "3",
      KOOD: "3492",
      NIMETUS: "Krei küla",
      NIMETUS_LIIGIGA: "Krei küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00003492",
    },
    {
      TASE: "3",
      KOOD: "3493",
      NIMETUS: "Kriguli küla",
      NIMETUS_LIIGIGA: "Kriguli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003493",
    },
    {
      TASE: "3",
      KOOD: "3494",
      NIMETUS: "Kremessova küla",
      NIMETUS_LIIGIGA: "Kremessova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003494",
    },
    {
      TASE: "3",
      KOOD: "3497",
      NIMETUS: "Kriilevälja küla",
      NIMETUS_LIIGIGA: "Kriilevälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00003497",
    },
    {
      TASE: "3",
      KOOD: "3500",
      NIMETUS: "Kriimani küla",
      NIMETUS_LIIGIGA: "Kriimani küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00003500",
    },
    {
      TASE: "3",
      KOOD: "3502",
      NIMETUS: "Kriiva küla",
      NIMETUS_LIIGIGA: "Kriiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003502",
    },
    {
      TASE: "3",
      KOOD: "3505",
      NIMETUS: "Krootuse küla",
      NIMETUS_LIIGIGA: "Krootuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00003505",
    },
    {
      TASE: "3",
      KOOD: "3508",
      NIMETUS: "Krundiküla",
      NIMETUS_LIIGIGA: "Krundiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00003508",
    },
    {
      TASE: "3",
      KOOD: "3511",
      NIMETUS: "Krüüdneri küla",
      NIMETUS_LIIGIGA: "Krüüdneri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00003511",
    },
    {
      TASE: "3",
      KOOD: "3518",
      NIMETUS: "Kudina küla",
      NIMETUS_LIIGIGA: "Kudina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00003518",
    },
    {
      TASE: "3",
      KOOD: "3519",
      NIMETUS: "Kudjape alevik",
      NIMETUS_LIIGIGA: "Kudjape alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003519",
    },
    {
      TASE: "3",
      KOOD: "3520",
      NIMETUS: "Kudruküla",
      NIMETUS_LIIGIGA: "Kudruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00003520",
    },
    {
      TASE: "3",
      KOOD: "3521",
      NIMETUS: "Kugalepa küla",
      NIMETUS_LIIGIGA: "Kugalepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003521",
    },
    {
      TASE: "3",
      KOOD: "3522",
      NIMETUS: "Kuiandi küla",
      NIMETUS_LIIGIGA: "Kuiandi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003522",
    },
    {
      TASE: "3",
      KOOD: "3523",
      NIMETUS: "Kuhjavere küla",
      NIMETUS_LIIGIGA: "Kuhjavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00003523",
    },
    {
      TASE: "3",
      KOOD: "3524",
      NIMETUS: "Kuhu küla",
      NIMETUS_LIIGIGA: "Kuhu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003524",
    },
    {
      TASE: "3",
      KOOD: "3526",
      NIMETUS: "Kuiaru küla",
      NIMETUS_LIIGIGA: "Kuiaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00003526",
    },
    {
      TASE: "3",
      KOOD: "3529",
      NIMETUS: "Kuiavere küla",
      NIMETUS_LIIGIGA: "Kuiavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00003529",
    },
    {
      TASE: "3",
      KOOD: "3531",
      NIMETUS: "Kuie küla",
      NIMETUS_LIIGIGA: "Kuie küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00003531",
    },
    {
      TASE: "3",
      KOOD: "3534",
      NIMETUS: "Kuigatsi küla",
      NIMETUS_LIIGIGA: "Kuigatsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00003534",
    },
    {
      TASE: "3",
      KOOD: "3536",
      NIMETUS: "Kuigõ küla",
      NIMETUS_LIIGIGA: "Kuigõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003536",
    },
    {
      TASE: "3",
      KOOD: "3539",
      NIMETUS: "Kuijõe küla",
      NIMETUS_LIIGIGA: "Kuijõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00003539",
    },
    {
      TASE: "3",
      KOOD: "3541",
      NIMETUS: "Kuimetsa küla",
      NIMETUS_LIIGIGA: "Kuimetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00003541",
    },
    {
      TASE: "3",
      KOOD: "3544",
      NIMETUS: "Kuiste küla",
      NIMETUS_LIIGIGA: "Kuiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003544",
    },
    {
      TASE: "3",
      KOOD: "3546",
      NIMETUS: "Kuivajõe küla",
      NIMETUS_LIIGIGA: "Kuivajõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00003546",
    },
    {
      TASE: "3",
      KOOD: "3549",
      NIMETUS: "Kuivastu küla",
      NIMETUS_LIIGIGA: "Kuivastu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00003549",
    },
    {
      TASE: "3",
      KOOD: "3550",
      NIMETUS: "Kuke küla",
      NIMETUS_LIIGIGA: "Kuke küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003550",
    },
    {
      TASE: "3",
      KOOD: "3552",
      NIMETUS: "Kuke küla",
      NIMETUS_LIIGIGA: "Kuke küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003552",
    },
    {
      TASE: "3",
      KOOD: "3553",
      NIMETUS: "Kukepala küla",
      NIMETUS_LIIGIGA: "Kukepala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00003553",
    },
    {
      TASE: "3",
      KOOD: "3554",
      NIMETUS: "Kukevere küla",
      NIMETUS_LIIGIGA: "Kukevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00003554",
    },
    {
      TASE: "3",
      KOOD: "3557",
      NIMETUS: "Kukka küla",
      NIMETUS_LIIGIGA: "Kukka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003557",
    },
    {
      TASE: "3",
      KOOD: "3558",
      NIMETUS: "Kuklasõ küla",
      NIMETUS_LIIGIGA: "Kuklasõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003558",
    },
    {
      TASE: "3",
      KOOD: "3559",
      NIMETUS: "Kuklase küla",
      NIMETUS_LIIGIGA: "Kuklase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003559",
    },
    {
      TASE: "3",
      KOOD: "3562",
      NIMETUS: "Kukruse küla",
      NIMETUS_LIIGIGA: "Kukruse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00003562",
    },
    {
      TASE: "3",
      KOOD: "3564",
      NIMETUS: "Kuksema küla",
      NIMETUS_LIIGIGA: "Kuksema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00003564",
    },
    {
      TASE: "3",
      KOOD: "3567",
      NIMETUS: "Kuksina küla",
      NIMETUS_LIIGIGA: "Kuksina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003567",
    },
    {
      TASE: "3",
      KOOD: "3569",
      NIMETUS: "Kuku küla",
      NIMETUS_LIIGIGA: "Kuku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00003569",
    },
    {
      TASE: "3",
      KOOD: "3572",
      NIMETUS: "Kukulinna küla",
      NIMETUS_LIIGIGA: "Kukulinna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00003572",
    },
    {
      TASE: "3",
      KOOD: "3575",
      NIMETUS: "Kuldre küla",
      NIMETUS_LIIGIGA: "Kuldre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00003575",
    },
    {
      TASE: "3",
      KOOD: "3576",
      NIMETUS: "Kulja küla",
      NIMETUS_LIIGIGA: "Kulja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00003576",
    },
    {
      TASE: "3",
      KOOD: "3577",
      NIMETUS: "Kulina küla",
      NIMETUS_LIIGIGA: "Kulina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00003577",
    },
    {
      TASE: "3",
      KOOD: "3580",
      NIMETUS: "Kulla küla",
      NIMETUS_LIIGIGA: "Kulla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00003580",
    },
    {
      TASE: "3",
      KOOD: "3582",
      NIMETUS: "Kullaaru küla",
      NIMETUS_LIIGIGA: "Kullaaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00003582",
    },
    {
      TASE: "3",
      KOOD: "3585",
      NIMETUS: "Kullaga küla",
      NIMETUS_LIIGIGA: "Kullaga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00003585",
    },
    {
      TASE: "3",
      KOOD: "3587",
      NIMETUS: "Kullamaa küla",
      NIMETUS_LIIGIGA: "Kullamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00003587",
    },
    {
      TASE: "3",
      KOOD: "3588",
      NIMETUS: "Kullamäe küla",
      NIMETUS_LIIGIGA: "Kullamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00003588",
    },
    {
      TASE: "3",
      KOOD: "3589",
      NIMETUS: "Kullamäe küla",
      NIMETUS_LIIGIGA: "Kullamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00003589",
    },
    {
      TASE: "3",
      KOOD: "3590",
      NIMETUS: "Kullametsa küla",
      NIMETUS_LIIGIGA: "Kullametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00003590",
    },
    {
      TASE: "3",
      KOOD: "3592",
      NIMETUS: "Kullenga küla",
      NIMETUS_LIIGIGA: "Kullenga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00003592",
    },
    {
      TASE: "3",
      KOOD: "3593",
      NIMETUS: "Kulli küla",
      NIMETUS_LIIGIGA: "Kulli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003593",
    },
    {
      TASE: "3",
      KOOD: "3595",
      NIMETUS: "Kulli küla",
      NIMETUS_LIIGIGA: "Kulli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00003595",
    },
    {
      TASE: "3",
      KOOD: "3596",
      NIMETUS: "Kulli küla",
      NIMETUS_LIIGIGA: "Kulli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00003596",
    },
    {
      TASE: "3",
      KOOD: "3597",
      NIMETUS: "Kulli küla",
      NIMETUS_LIIGIGA: "Kulli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00003597",
    },
    {
      TASE: "3",
      KOOD: "3598",
      NIMETUS: "Kärla-Kulli küla",
      NIMETUS_LIIGIGA: "Kärla-Kulli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003598",
    },
    {
      TASE: "3",
      KOOD: "3599",
      NIMETUS: "Kullimaa küla",
      NIMETUS_LIIGIGA: "Kullimaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00003599",
    },
    {
      TASE: "3",
      KOOD: "4568",
      NIMETUS: "Lutika küla",
      NIMETUS_LIIGIGA: "Lutika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004568",
    },
    {
      TASE: "3",
      KOOD: "4570",
      NIMETUS: "Lutepää küla",
      NIMETUS_LIIGIGA: "Lutepää küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004570",
    },
    {
      TASE: "3",
      KOOD: "4571",
      NIMETUS: "Lutja küla",
      NIMETUS_LIIGIGA: "Lutja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004571",
    },
    {
      TASE: "3",
      KOOD: "4573",
      NIMETUS: "Lutike küla",
      NIMETUS_LIIGIGA: "Lutike küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00004573",
    },
    {
      TASE: "3",
      KOOD: "4575",
      NIMETUS: "Lutsu küla",
      NIMETUS_LIIGIGA: "Lutsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00004575",
    },
    {
      TASE: "3",
      KOOD: "4576",
      NIMETUS: "Lutsu küla",
      NIMETUS_LIIGIGA: "Lutsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00004576",
    },
    {
      TASE: "3",
      KOOD: "4577",
      NIMETUS: "Lutsu küla",
      NIMETUS_LIIGIGA: "Lutsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00004577",
    },
    {
      TASE: "3",
      KOOD: "4579",
      NIMETUS: "Luua küla",
      NIMETUS_LIIGIGA: "Luua küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00004579",
    },
    {
      TASE: "3",
      KOOD: "4581",
      NIMETUS: "Luulupe küla",
      NIMETUS_LIIGIGA: "Luulupe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004581",
    },
    {
      TASE: "3",
      KOOD: "4586",
      NIMETUS: "Luuri küla",
      NIMETUS_LIIGIGA: "Luuri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00004586",
    },
    {
      TASE: "3",
      KOOD: "4589",
      NIMETUS: "Luusika küla",
      NIMETUS_LIIGIGA: "Luusika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00004589",
    },
    {
      TASE: "3",
      KOOD: "4590",
      NIMETUS: "Lõbembe küla",
      NIMETUS_LIIGIGA: "Lõbembe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004590",
    },
    {
      TASE: "3",
      KOOD: "4591",
      NIMETUS: "Luutsniku küla",
      NIMETUS_LIIGIGA: "Luutsniku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004591",
    },
    {
      TASE: "3",
      KOOD: "4592",
      NIMETUS: "Lõbe küla",
      NIMETUS_LIIGIGA: "Lõbe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00004592",
    },
    {
      TASE: "3",
      KOOD: "4594",
      NIMETUS: "Luuska küla",
      NIMETUS_LIIGIGA: "Luuska küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004594",
    },
    {
      TASE: "3",
      KOOD: "4595",
      NIMETUS: "Lõetsa küla",
      NIMETUS_LIIGIGA: "Lõetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00004595",
    },
    {
      TASE: "3",
      KOOD: "4604",
      NIMETUS: "Lõmala küla",
      NIMETUS_LIIGIGA: "Lõmala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004604",
    },
    {
      TASE: "3",
      KOOD: "4607",
      NIMETUS: "Lõo küla",
      NIMETUS_LIIGIGA: "Lõo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00004607",
    },
    {
      TASE: "3",
      KOOD: "4608",
      NIMETUS: "Lõu küla",
      NIMETUS_LIIGIGA: "Lõu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004608",
    },
    {
      TASE: "3",
      KOOD: "4609",
      NIMETUS: "Lõupõllu küla",
      NIMETUS_LIIGIGA: "Lõupõllu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004609",
    },
    {
      TASE: "3",
      KOOD: "4610",
      NIMETUS: "Lõpe küla",
      NIMETUS_LIIGIGA: "Lõpe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00004610",
    },
    {
      TASE: "3",
      KOOD: "4611",
      NIMETUS: "Lõpe küla",
      NIMETUS_LIIGIGA: "Lõpe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00004611",
    },
    {
      TASE: "3",
      KOOD: "4612",
      NIMETUS: "Lõpe küla",
      NIMETUS_LIIGIGA: "Lõpe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004612",
    },
    {
      TASE: "3",
      KOOD: "4613",
      NIMETUS: "Lõpe küla",
      NIMETUS_LIIGIGA: "Lõpe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00004613",
    },
    {
      TASE: "3",
      KOOD: "4614",
      NIMETUS: "Lõpemetsa küla",
      NIMETUS_LIIGIGA: "Lõpemetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00004614",
    },
    {
      TASE: "3",
      KOOD: "4615",
      NIMETUS: "Lõpi küla",
      NIMETUS_LIIGIGA: "Lõpi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004615",
    },
    {
      TASE: "3",
      KOOD: "4617",
      NIMETUS: "Lõuka küla",
      NIMETUS_LIIGIGA: "Lõuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00004617",
    },
    {
      TASE: "3",
      KOOD: "4620",
      NIMETUS: "Lõve küla",
      NIMETUS_LIIGIGA: "Lõve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00004620",
    },
    {
      TASE: "3",
      KOOD: "4623",
      NIMETUS: "Lõõla küla",
      NIMETUS_LIIGIGA: "Lõõla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00004623",
    },
    {
      TASE: "3",
      KOOD: "4628",
      NIMETUS: "Lähkma küla",
      NIMETUS_LIIGIGA: "Lähkma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00004628",
    },
    {
      TASE: "3",
      KOOD: "4629",
      NIMETUS: "Lähte alevik",
      NIMETUS_LIIGIGA: "Lähte alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00004629",
    },
    {
      TASE: "3",
      KOOD: "4632",
      NIMETUS: "Väike-Lähtru küla",
      NIMETUS_LIIGIGA: "Väike-Lähtru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00004632",
    },
    {
      TASE: "3",
      KOOD: "4634",
      NIMETUS: "Lähtse küla",
      NIMETUS_LIIGIGA: "Lähtse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00004634",
    },
    {
      TASE: "3",
      KOOD: "4636",
      NIMETUS: "Länga küla",
      NIMETUS_LIIGIGA: "Länga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004636",
    },
    {
      TASE: "3",
      KOOD: "4638",
      NIMETUS: "Läpi küla",
      NIMETUS_LIIGIGA: "Läpi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00004638",
    },
    {
      TASE: "3",
      KOOD: "4641",
      NIMETUS: "Läsna küla",
      NIMETUS_LIIGIGA: "Läsna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00004641",
    },
    {
      TASE: "3",
      KOOD: "4644",
      NIMETUS: "Läste küla",
      NIMETUS_LIIGIGA: "Läste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00004644",
    },
    {
      TASE: "3",
      KOOD: "4647",
      NIMETUS: "Läti küla",
      NIMETUS_LIIGIGA: "Läti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00004647",
    },
    {
      TASE: "3",
      KOOD: "4648",
      NIMETUS: "Läti küla",
      NIMETUS_LIIGIGA: "Läti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00004648",
    },
    {
      TASE: "3",
      KOOD: "4649",
      NIMETUS: "Lätiniidi küla",
      NIMETUS_LIIGIGA: "Lätiniidi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004649",
    },
    {
      TASE: "3",
      KOOD: "4650",
      NIMETUS: "Lätkalu küla",
      NIMETUS_LIIGIGA: "Lätkalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004650",
    },
    {
      TASE: "3",
      KOOD: "4653",
      NIMETUS: "Läägi küla",
      NIMETUS_LIIGIGA: "Läägi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004653",
    },
    {
      TASE: "3",
      KOOD: "4656",
      NIMETUS: "Lääneotsa küla",
      NIMETUS_LIIGIGA: "Lääneotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00004656",
    },
    {
      TASE: "3",
      KOOD: "4658",
      NIMETUS: "Lääniste küla",
      NIMETUS_LIIGIGA: "Lääniste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00004658",
    },
    {
      TASE: "3",
      KOOD: "4661",
      NIMETUS: "Läätsa küla",
      NIMETUS_LIIGIGA: "Läätsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004661",
    },
    {
      TASE: "3",
      KOOD: "4665",
      NIMETUS: "Lööne küla",
      NIMETUS_LIIGIGA: "Lööne küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004665",
    },
    {
      TASE: "3",
      KOOD: "4666",
      NIMETUS: "Läbara küla",
      NIMETUS_LIIGIGA: "Läbara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004666",
    },
    {
      TASE: "3",
      KOOD: "4667",
      NIMETUS: "Lööra küla",
      NIMETUS_LIIGIGA: "Lööra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00004667",
    },
    {
      TASE: "3",
      KOOD: "4669",
      NIMETUS: "Lüganuse alevik",
      NIMETUS_LIIGIGA: "Lüganuse alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00004669",
    },
    {
      TASE: "3",
      KOOD: "4670",
      NIMETUS: "Lükkä küla",
      NIMETUS_LIIGIGA: "Lükkä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004670",
    },
    {
      TASE: "3",
      KOOD: "4672",
      NIMETUS: "Lükati küla",
      NIMETUS_LIIGIGA: "Lükati küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00004672",
    },
    {
      TASE: "3",
      KOOD: "4675",
      NIMETUS: "Lülle küla",
      NIMETUS_LIIGIGA: "Lülle küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004675",
    },
    {
      TASE: "3",
      KOOD: "4677",
      NIMETUS: "Lüllemäe küla",
      NIMETUS_LIIGIGA: "Lüllemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00004677",
    },
    {
      TASE: "3",
      KOOD: "4681",
      NIMETUS: "Lümandu küla",
      NIMETUS_LIIGIGA: "Lümandu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00004681",
    },
    {
      TASE: "3",
      KOOD: "4683",
      NIMETUS: "Lümandu küla",
      NIMETUS_LIIGIGA: "Lümandu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00004683",
    },
    {
      TASE: "3",
      KOOD: "4685",
      NIMETUS: "Lümati küla",
      NIMETUS_LIIGIGA: "Lümati küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00004685",
    },
    {
      TASE: "3",
      KOOD: "4688",
      NIMETUS: "Lümatu küla",
      NIMETUS_LIIGIGA: "Lümatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00004688",
    },
    {
      TASE: "3",
      KOOD: "4689",
      NIMETUS: "Lümatu küla",
      NIMETUS_LIIGIGA: "Lümatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00004689",
    },
    {
      TASE: "3",
      KOOD: "4692",
      NIMETUS: "Lüübnitsa küla",
      NIMETUS_LIIGIGA: "Lüübnitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004692",
    },
    {
      TASE: "3",
      KOOD: "4694",
      NIMETUS: "Lüütsepa küla",
      NIMETUS_LIIGIGA: "Lüütsepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004694",
    },
    {
      TASE: "3",
      KOOD: "4695",
      NIMETUS: "Maade küla",
      NIMETUS_LIIGIGA: "Maade küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00004695",
    },
    {
      TASE: "3",
      KOOD: "4696",
      NIMETUS: "Lüüste küla",
      NIMETUS_LIIGIGA: "Lüüste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00004696",
    },
    {
      TASE: "3",
      KOOD: "4699",
      NIMETUS: "Maalasti küla",
      NIMETUS_LIIGIGA: "Maalasti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00004699",
    },
    {
      TASE: "3",
      KOOD: "4700",
      NIMETUS: "Maantee küla",
      NIMETUS_LIIGIGA: "Maantee küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004700",
    },
    {
      TASE: "3",
      KOOD: "4702",
      NIMETUS: "Maardla küla",
      NIMETUS_LIIGIGA: "Maardla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00004702",
    },
    {
      TASE: "3",
      KOOD: "4704",
      NIMETUS: "Maardu küla",
      NIMETUS_LIIGIGA: "Maardu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00004704",
    },
    {
      TASE: "3",
      KOOD: "4707",
      NIMETUS: "Maaritsa küla",
      NIMETUS_LIIGIGA: "Maaritsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00004707",
    },
    {
      TASE: "3",
      KOOD: "4709",
      NIMETUS: "Maarja-Magdaleena küla",
      NIMETUS_LIIGIGA: "Maarja-Magdaleena küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00004709",
    },
    {
      TASE: "3",
      KOOD: "4710",
      NIMETUS: "Maaslova küla",
      NIMETUS_LIIGIGA: "Maaslova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004710",
    },
    {
      TASE: "3",
      KOOD: "4715",
      NIMETUS: "Madala küla",
      NIMETUS_LIIGIGA: "Madala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004715",
    },
    {
      TASE: "3",
      KOOD: "4716",
      NIMETUS: "Madi küla",
      NIMETUS_LIIGIGA: "Madi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004716",
    },
    {
      TASE: "3",
      KOOD: "4717",
      NIMETUS: "Madila küla",
      NIMETUS_LIIGIGA: "Madila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00004717",
    },
    {
      TASE: "3",
      KOOD: "4720",
      NIMETUS: "Madise küla",
      NIMETUS_LIIGIGA: "Madise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00004720",
    },
    {
      TASE: "3",
      KOOD: "4721",
      NIMETUS: "Madise küla",
      NIMETUS_LIIGIGA: "Madise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00004721",
    },
    {
      TASE: "3",
      KOOD: "4722",
      NIMETUS: "Madise küla",
      NIMETUS_LIIGIGA: "Madise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00004722",
    },
    {
      TASE: "3",
      KOOD: "4725",
      NIMETUS: "Maeru küla",
      NIMETUS_LIIGIGA: "Maeru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00004725",
    },
    {
      TASE: "3",
      KOOD: "4727",
      NIMETUS: "Maetsma küla",
      NIMETUS_LIIGIGA: "Maetsma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00004727",
    },
    {
      TASE: "3",
      KOOD: "4728",
      NIMETUS: "Mahlamäe küla",
      NIMETUS_LIIGIGA: "Mahlamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00004728",
    },
    {
      TASE: "3",
      KOOD: "4730",
      NIMETUS: "Magari küla",
      NIMETUS_LIIGIGA: "Magari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00004730",
    },
    {
      TASE: "3",
      KOOD: "4731",
      NIMETUS: "Mahtja küla",
      NIMETUS_LIIGIGA: "Mahtja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004731",
    },
    {
      TASE: "3",
      KOOD: "4733",
      NIMETUS: "Mahtra küla",
      NIMETUS_LIIGIGA: "Mahtra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00004733",
    },
    {
      TASE: "3",
      KOOD: "4736",
      NIMETUS: "Mahu küla",
      NIMETUS_LIIGIGA: "Mahu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00004736",
    },
    {
      TASE: "3",
      KOOD: "4739",
      NIMETUS: "Maidla küla",
      NIMETUS_LIIGIGA: "Maidla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00004739",
    },
    {
      TASE: "3",
      KOOD: "4740",
      NIMETUS: "Maidla küla",
      NIMETUS_LIIGIGA: "Maidla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00004740",
    },
    {
      TASE: "3",
      KOOD: "4741",
      NIMETUS: "Maidla küla",
      NIMETUS_LIIGIGA: "Maidla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00004741",
    },
    {
      TASE: "3",
      KOOD: "4742",
      NIMETUS: "Maikse küla",
      NIMETUS_LIIGIGA: "Maikse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00004742",
    },
    {
      TASE: "3",
      KOOD: "4743",
      NIMETUS: "Maima küla",
      NIMETUS_LIIGIGA: "Maima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00004743",
    },
    {
      TASE: "3",
      KOOD: "4746",
      NIMETUS: "Majaka küla",
      NIMETUS_LIIGIGA: "Majaka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00004746",
    },
    {
      TASE: "3",
      KOOD: "4749",
      NIMETUS: "Majala küla",
      NIMETUS_LIIGIGA: "Majala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00004749",
    },
    {
      TASE: "3",
      KOOD: "4750",
      NIMETUS: "Majala küla",
      NIMETUS_LIIGIGA: "Majala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004750",
    },
    {
      TASE: "3",
      KOOD: "4751",
      NIMETUS: "Makita küla",
      NIMETUS_LIIGIGA: "Makita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00004751",
    },
    {
      TASE: "3",
      KOOD: "4753",
      NIMETUS: "Malda küla",
      NIMETUS_LIIGIGA: "Malda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00004753",
    },
    {
      TASE: "3",
      KOOD: "4754",
      NIMETUS: "Maleva küla",
      NIMETUS_LIIGIGA: "Maleva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004754",
    },
    {
      TASE: "3",
      KOOD: "4755",
      NIMETUS: "Malla küla",
      NIMETUS_LIIGIGA: "Malla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00004755",
    },
    {
      TASE: "3",
      KOOD: "4758",
      NIMETUS: "Mallavere küla",
      NIMETUS_LIIGIGA: "Mallavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00004758",
    },
    {
      TASE: "3",
      KOOD: "4760",
      NIMETUS: "Mallika küla",
      NIMETUS_LIIGIGA: "Mallika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004760",
    },
    {
      TASE: "3",
      KOOD: "4763",
      NIMETUS: "Maltsa küla",
      NIMETUS_LIIGIGA: "Maltsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004763",
    },
    {
      TASE: "3",
      KOOD: "4765",
      NIMETUS: "Malvaste küla",
      NIMETUS_LIIGIGA: "Malvaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004765",
    },
    {
      TASE: "3",
      KOOD: "4766",
      NIMETUS: "Mangu küla",
      NIMETUS_LIIGIGA: "Mangu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004766",
    },
    {
      TASE: "3",
      KOOD: "4771",
      NIMETUS: "Manija küla",
      NIMETUS_LIIGIGA: "Manija küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00004771",
    },
    {
      TASE: "3",
      KOOD: "4774",
      NIMETUS: "Mannare küla",
      NIMETUS_LIIGIGA: "Mannare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00004774",
    },
    {
      TASE: "3",
      KOOD: "4775",
      NIMETUS: "Manni küla",
      NIMETUS_LIIGIGA: "Manni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00004775",
    },
    {
      TASE: "3",
      KOOD: "4776",
      NIMETUS: "Manniva küla",
      NIMETUS_LIIGIGA: "Manniva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00004776",
    },
    {
      TASE: "3",
      KOOD: "4779",
      NIMETUS: "Maramaa küla",
      NIMETUS_LIIGIGA: "Maramaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00004779",
    },
    {
      TASE: "3",
      KOOD: "4780",
      NIMETUS: "Mardihansu küla",
      NIMETUS_LIIGIGA: "Mardihansu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004780",
    },
    {
      TASE: "3",
      KOOD: "4782",
      NIMETUS: "Marana küla",
      NIMETUS_LIIGIGA: "Marana küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00004782",
    },
    {
      TASE: "3",
      KOOD: "4784",
      NIMETUS: "Marga küla",
      NIMETUS_LIIGIGA: "Marga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004784",
    },
    {
      TASE: "3",
      KOOD: "4785",
      NIMETUS: "Marinova küla",
      NIMETUS_LIIGIGA: "Marinova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004785",
    },
    {
      TASE: "3",
      KOOD: "4786",
      NIMETUS: "Marinu küla",
      NIMETUS_LIIGIGA: "Marinu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00004786",
    },
    {
      TASE: "3",
      KOOD: "4787",
      NIMETUS: "Marina küla",
      NIMETUS_LIIGIGA: "Marina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00004787",
    },
    {
      TASE: "3",
      KOOD: "4788",
      NIMETUS: "Marguse küla",
      NIMETUS_LIIGIGA: "Marguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00004788",
    },
    {
      TASE: "3",
      KOOD: "4789",
      NIMETUS: "Marjamäe küla",
      NIMETUS_LIIGIGA: "Marjamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004789",
    },
    {
      TASE: "3",
      KOOD: "4792",
      NIMETUS: "Marksa küla",
      NIMETUS_LIIGIGA: "Marksa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00004792",
    },
    {
      TASE: "3",
      KOOD: "4794",
      NIMETUS: "Marna küla",
      NIMETUS_LIIGIGA: "Marna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004794",
    },
    {
      TASE: "3",
      KOOD: "4796",
      NIMETUS: "Martna küla",
      NIMETUS_LIIGIGA: "Martna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00004796",
    },
    {
      TASE: "3",
      KOOD: "4798",
      NIMETUS: "Martsina küla",
      NIMETUS_LIIGIGA: "Martsina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004798",
    },
    {
      TASE: "3",
      KOOD: "4799",
      NIMETUS: "Martsa küla",
      NIMETUS_LIIGIGA: "Martsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00004799",
    },
    {
      TASE: "3",
      KOOD: "4802",
      NIMETUS: "Maru küla",
      NIMETUS_LIIGIGA: "Maru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00004802",
    },
    {
      TASE: "3",
      KOOD: "4803",
      NIMETUS: "Masa küla",
      NIMETUS_LIIGIGA: "Masa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004803",
    },
    {
      TASE: "3",
      KOOD: "4804",
      NIMETUS: "Masluva küla",
      NIMETUS_LIIGIGA: "Masluva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004804",
    },
    {
      TASE: "3",
      KOOD: "4805",
      NIMETUS: "Massiaru küla",
      NIMETUS_LIIGIGA: "Massiaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00004805",
    },
    {
      TASE: "3",
      KOOD: "4807",
      NIMETUS: "Massu küla",
      NIMETUS_LIIGIGA: "Massu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00004807",
    },
    {
      TASE: "3",
      KOOD: "4808",
      NIMETUS: "Massu küla",
      NIMETUS_LIIGIGA: "Massu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00004808",
    },
    {
      TASE: "3",
      KOOD: "4811",
      NIMETUS: "Masti küla",
      NIMETUS_LIIGIGA: "Masti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00004811",
    },
    {
      TASE: "3",
      KOOD: "4814",
      NIMETUS: "Matapera küla",
      NIMETUS_LIIGIGA: "Matapera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004814",
    },
    {
      TASE: "3",
      KOOD: "4816",
      NIMETUS: "Matjama küla",
      NIMETUS_LIIGIGA: "Matjama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00004816",
    },
    {
      TASE: "3",
      KOOD: "4819",
      NIMETUS: "Matka küla",
      NIMETUS_LIIGIGA: "Matka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00004819",
    },
    {
      TASE: "3",
      KOOD: "4824",
      NIMETUS: "Matsi küla",
      NIMETUS_LIIGIGA: "Matsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00004824",
    },
    {
      TASE: "3",
      KOOD: "4825",
      NIMETUS: "Matsi küla",
      NIMETUS_LIIGIGA: "Matsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004825",
    },
    {
      TASE: "3",
      KOOD: "4826",
      NIMETUS: "Matsiranna küla",
      NIMETUS_LIIGIGA: "Matsiranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004826",
    },
    {
      TASE: "3",
      KOOD: "4827",
      NIMETUS: "Matsuri küla",
      NIMETUS_LIIGIGA: "Matsuri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004827",
    },
    {
      TASE: "3",
      KOOD: "4830",
      NIMETUS: "Mauri küla",
      NIMETUS_LIIGIGA: "Mauri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004830",
    },
    {
      TASE: "3",
      KOOD: "4834",
      NIMETUS: "Meedla küla",
      NIMETUS_LIIGIGA: "Meedla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004834",
    },
    {
      TASE: "3",
      KOOD: "4837",
      NIMETUS: "Meegaste küla",
      NIMETUS_LIIGIGA: "Meegaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00004837",
    },
    {
      TASE: "3",
      KOOD: "4839",
      NIMETUS: "Meegomäe küla",
      NIMETUS_LIIGIGA: "Meegomäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004839",
    },
    {
      TASE: "3",
      KOOD: "4842",
      NIMETUS: "Meeksi küla",
      NIMETUS_LIIGIGA: "Meeksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00004842",
    },
    {
      TASE: "3",
      KOOD: "4843",
      NIMETUS: "Miikse küla",
      NIMETUS_LIIGIGA: "Miikse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004843",
    },
    {
      TASE: "3",
      KOOD: "4844",
      NIMETUS: "Meelste küla",
      NIMETUS_LIIGIGA: "Meelste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004844",
    },
    {
      TASE: "3",
      KOOD: "4845",
      NIMETUS: "Meeliku küla",
      NIMETUS_LIIGIGA: "Meeliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004845",
    },
    {
      TASE: "3",
      KOOD: "4846",
      NIMETUS: "Meelaku küla",
      NIMETUS_LIIGIGA: "Meelaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004846",
    },
    {
      TASE: "3",
      KOOD: "4849",
      NIMETUS: "Meelva küla",
      NIMETUS_LIIGIGA: "Meelva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00004849",
    },
    {
      TASE: "3",
      KOOD: "4851",
      NIMETUS: "Meemaste küla",
      NIMETUS_LIIGIGA: "Meemaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00004851",
    },
    {
      TASE: "3",
      KOOD: "4855",
      NIMETUS: "Mehama küla",
      NIMETUS_LIIGIGA: "Mehama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004855",
    },
    {
      TASE: "3",
      KOOD: "4856",
      NIMETUS: "Meeri küla",
      NIMETUS_LIIGIGA: "Meeri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00004856",
    },
    {
      TASE: "3",
      KOOD: "4857",
      NIMETUS: "Mehide küla",
      NIMETUS_LIIGIGA: "Mehide küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00004857",
    },
    {
      TASE: "3",
      KOOD: "4859",
      NIMETUS: "Mehikoorma alevik",
      NIMETUS_LIIGIGA: "Mehikoorma alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00004859",
    },
    {
      TASE: "3",
      KOOD: "4862",
      NIMETUS: "Meiuste küla",
      NIMETUS_LIIGIGA: "Meiuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004862",
    },
    {
      TASE: "3",
      KOOD: "4865",
      NIMETUS: "Meleski küla",
      NIMETUS_LIIGIGA: "Meleski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004865",
    },
    {
      TASE: "3",
      KOOD: "4866",
      NIMETUS: "Melso küla",
      NIMETUS_LIIGIGA: "Melso küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004866",
    },
    {
      TASE: "3",
      KOOD: "4868",
      NIMETUS: "Melliste küla",
      NIMETUS_LIIGIGA: "Melliste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00004868",
    },
    {
      TASE: "3",
      KOOD: "4871",
      NIMETUS: "Meoma küla",
      NIMETUS_LIIGIGA: "Meoma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00004871",
    },
    {
      TASE: "3",
      KOOD: "4872",
      NIMETUS: "Merekülä küla",
      NIMETUS_LIIGIGA: "Merekülä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004872",
    },
    {
      TASE: "3",
      KOOD: "4873",
      NIMETUS: "Meossaare küla",
      NIMETUS_LIIGIGA: "Meossaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00004873",
    },
    {
      TASE: "3",
      KOOD: "4876",
      NIMETUS: "Meremõisa küla",
      NIMETUS_LIIGIGA: "Meremõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00004876",
    },
    {
      TASE: "3",
      KOOD: "4879",
      NIMETUS: "Meremäe küla",
      NIMETUS_LIIGIGA: "Meremäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004879",
    },
    {
      TASE: "3",
      KOOD: "4881",
      NIMETUS: "Mereäärse küla",
      NIMETUS_LIIGIGA: "Mereäärse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00004881",
    },
    {
      TASE: "3",
      KOOD: "4882",
      NIMETUS: "Merja küla",
      NIMETUS_LIIGIGA: "Merja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00004882",
    },
    {
      TASE: "3",
      KOOD: "4883",
      NIMETUS: "Metsaküla",
      NIMETUS_LIIGIGA: "Metsaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00004883",
    },
    {
      TASE: "3",
      KOOD: "4884",
      NIMETUS: "Meriküla",
      NIMETUS_LIIGIGA: "Meriküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00004884",
    },
    {
      TASE: "3",
      KOOD: "4887",
      NIMETUS: "Metsakasti küla",
      NIMETUS_LIIGIGA: "Metsakasti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00004887",
    },
    {
      TASE: "3",
      KOOD: "4888",
      NIMETUS: "Merise küla",
      NIMETUS_LIIGIGA: "Merise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004888",
    },
    {
      TASE: "3",
      KOOD: "4889",
      NIMETUS: "Metsakivi küla",
      NIMETUS_LIIGIGA: "Metsakivi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00004889",
    },
    {
      TASE: "3",
      KOOD: "4890",
      NIMETUS: "Metsaküla",
      NIMETUS_LIIGIGA: "Metsaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004890",
    },
    {
      TASE: "3",
      KOOD: "4891",
      NIMETUS: "Metsaküla",
      NIMETUS_LIIGIGA: "Metsaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00004891",
    },
    {
      TASE: "3",
      KOOD: "4892",
      NIMETUS: "Metsaküla",
      NIMETUS_LIIGIGA: "Metsaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00004892",
    },
    {
      TASE: "3",
      KOOD: "4893",
      NIMETUS: "Metsaküla",
      NIMETUS_LIIGIGA: "Metsaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00004893",
    },
    {
      TASE: "3",
      KOOD: "4894",
      NIMETUS: "Metsaküla",
      NIMETUS_LIIGIGA: "Metsaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004894",
    },
    {
      TASE: "3",
      KOOD: "4895",
      NIMETUS: "Metsalaane küla",
      NIMETUS_LIIGIGA: "Metsalaane küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00004895",
    },
    {
      TASE: "3",
      KOOD: "4896",
      NIMETUS: "Metsamägara küla",
      NIMETUS_LIIGIGA: "Metsamägara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00004896",
    },
    {
      TASE: "3",
      KOOD: "4897",
      NIMETUS: "Metsaküla",
      NIMETUS_LIIGIGA: "Metsaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00004897",
    },
    {
      TASE: "3",
      KOOD: "4898",
      NIMETUS: "Metsalauka küla",
      NIMETUS_LIIGIGA: "Metsalauka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004898",
    },
    {
      TASE: "3",
      KOOD: "4899",
      NIMETUS: "Metsalõuka küla",
      NIMETUS_LIIGIGA: "Metsalõuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004899",
    },
    {
      TASE: "3",
      KOOD: "4900",
      NIMETUS: "Metsanuka küla",
      NIMETUS_LIIGIGA: "Metsanuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00004900",
    },
    {
      TASE: "3",
      KOOD: "4903",
      NIMETUS: "Metsanurga küla",
      NIMETUS_LIIGIGA: "Metsanurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00004903",
    },
    {
      TASE: "3",
      KOOD: "4904",
      NIMETUS: "Metsanurga küla",
      NIMETUS_LIIGIGA: "Metsanurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00004904",
    },
    {
      TASE: "3",
      KOOD: "4905",
      NIMETUS: "Metsanurga küla",
      NIMETUS_LIIGIGA: "Metsanurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00004905",
    },
    {
      TASE: "3",
      KOOD: "4906",
      NIMETUS: "Metsanurga küla",
      NIMETUS_LIIGIGA: "Metsanurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00004906",
    },
    {
      TASE: "3",
      KOOD: "4907",
      NIMETUS: "Metsapere küla",
      NIMETUS_LIIGIGA: "Metsapere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004907",
    },
    {
      TASE: "3",
      KOOD: "4908",
      NIMETUS: "Metsapoole küla",
      NIMETUS_LIIGIGA: "Metsapoole küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00004908",
    },
    {
      TASE: "3",
      KOOD: "4909",
      NIMETUS: "Metsara küla",
      NIMETUS_LIIGIGA: "Metsara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004909",
    },
    {
      TASE: "3",
      KOOD: "4910",
      NIMETUS: "Metsapere küla",
      NIMETUS_LIIGIGA: "Metsapere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004910",
    },
    {
      TASE: "3",
      KOOD: "4911",
      NIMETUS: "Mõtsavaara küla",
      NIMETUS_LIIGIGA: "Mõtsavaara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00004911",
    },
    {
      TASE: "3",
      KOOD: "4912",
      NIMETUS: "Metsanurme küla",
      NIMETUS_LIIGIGA: "Metsanurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00004912",
    },
    {
      TASE: "3",
      KOOD: "4913",
      NIMETUS: "Metsavere küla",
      NIMETUS_LIIGIGA: "Metsavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00004913",
    },
    {
      TASE: "3",
      KOOD: "4914",
      NIMETUS: "Metsaääre küla",
      NIMETUS_LIIGIGA: "Metsaääre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00004914",
    },
    {
      TASE: "3",
      KOOD: "4915",
      NIMETUS: "Metsaääre küla",
      NIMETUS_LIIGIGA: "Metsaääre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00004915",
    },
    {
      TASE: "3",
      KOOD: "4916",
      NIMETUS: "Metsaääre küla",
      NIMETUS_LIIGIGA: "Metsaääre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00004916",
    },
    {
      TASE: "3",
      KOOD: "4917",
      NIMETUS: "Metsaääre küla",
      NIMETUS_LIIGIGA: "Metsaääre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004917",
    },
    {
      TASE: "3",
      KOOD: "4918",
      NIMETUS: "Metsiku küla",
      NIMETUS_LIIGIGA: "Metsiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00004918",
    },
    {
      TASE: "3",
      KOOD: "4919",
      NIMETUS: "Metsküla",
      NIMETUS_LIIGIGA: "Metsküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00004919",
    },
    {
      TASE: "3",
      KOOD: "4920",
      NIMETUS: "Metskaevu küla",
      NIMETUS_LIIGIGA: "Metskaevu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00004920",
    },
    {
      TASE: "3",
      KOOD: "4922",
      NIMETUS: "Metsküla",
      NIMETUS_LIIGIGA: "Metsküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004922",
    },
    {
      TASE: "3",
      KOOD: "4923",
      NIMETUS: "Metsküla",
      NIMETUS_LIIGIGA: "Metsküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00004923",
    },
    {
      TASE: "3",
      KOOD: "4924",
      NIMETUS: "Metsküla",
      NIMETUS_LIIGIGA: "Metsküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00004924",
    },
    {
      TASE: "3",
      KOOD: "4925",
      NIMETUS: "Metsküla",
      NIMETUS_LIIGIGA: "Metsküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00004925",
    },
    {
      TASE: "3",
      KOOD: "4926",
      NIMETUS: "Metsavälja küla",
      NIMETUS_LIIGIGA: "Metsavälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00004926",
    },
    {
      TASE: "3",
      KOOD: "4927",
      NIMETUS: "Metsla küla",
      NIMETUS_LIIGIGA: "Metsla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00004927",
    },
    {
      TASE: "3",
      KOOD: "4928",
      NIMETUS: "Metsla küla",
      NIMETUS_LIIGIGA: "Metsla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004928",
    },
    {
      TASE: "3",
      KOOD: "4931",
      NIMETUS: "Metslõugu küla",
      NIMETUS_LIIGIGA: "Metslõugu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00004931",
    },
    {
      TASE: "3",
      KOOD: "4933",
      NIMETUS: "Metstaga küla",
      NIMETUS_LIIGIGA: "Metstaga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004933",
    },
    {
      TASE: "3",
      KOOD: "4936",
      NIMETUS: "Metstaguse küla",
      NIMETUS_LIIGIGA: "Metstaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00004936",
    },
    {
      TASE: "3",
      KOOD: "4938",
      NIMETUS: "Metste küla",
      NIMETUS_LIIGIGA: "Metste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00004938",
    },
    {
      TASE: "3",
      KOOD: "4942",
      NIMETUS: "Mihkli küla",
      NIMETUS_LIIGIGA: "Mihkli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00004942",
    },
    {
      TASE: "3",
      KOOD: "4943",
      NIMETUS: "Miiduranna küla",
      NIMETUS_LIIGIGA: "Miiduranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00004943",
    },
    {
      TASE: "3",
      KOOD: "4945",
      NIMETUS: "Miiaste küla",
      NIMETUS_LIIGIGA: "Miiaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00004945",
    },
    {
      TASE: "3",
      KOOD: "4946",
      NIMETUS: "Miilimäe küla",
      NIMETUS_LIIGIGA: "Miilimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004946",
    },
    {
      TASE: "3",
      KOOD: "4947",
      NIMETUS: "Mikita küla",
      NIMETUS_LIIGIGA: "Mikita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004947",
    },
    {
      TASE: "3",
      KOOD: "4948",
      NIMETUS: "Miila küla",
      NIMETUS_LIIGIGA: "Miila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00004948",
    },
    {
      TASE: "3",
      KOOD: "4950",
      NIMETUS: "Miku küla",
      NIMETUS_LIIGIGA: "Miku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004950",
    },
    {
      TASE: "3",
      KOOD: "4951",
      NIMETUS: "Mikitamäe küla",
      NIMETUS_LIIGIGA: "Mikitamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004951",
    },
    {
      TASE: "3",
      KOOD: "4954",
      NIMETUS: "Misso alevik",
      NIMETUS_LIIGIGA: "Misso alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004954",
    },
    {
      TASE: "3",
      KOOD: "4956",
      NIMETUS: "Missokülä küla",
      NIMETUS_LIIGIGA: "Missokülä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004956",
    },
    {
      TASE: "3",
      KOOD: "4959",
      NIMETUS: "Miti küla",
      NIMETUS_LIIGIGA: "Miti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00004959",
    },
    {
      TASE: "3",
      KOOD: "4963",
      NIMETUS: "Moe küla",
      NIMETUS_LIIGIGA: "Moe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00004963",
    },
    {
      TASE: "3",
      KOOD: "4966",
      NIMETUS: "Moka küla",
      NIMETUS_LIIGIGA: "Moka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004966",
    },
    {
      TASE: "3",
      KOOD: "4967",
      NIMETUS: "Moka küla",
      NIMETUS_LIIGIGA: "Moka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00004967",
    },
    {
      TASE: "3",
      KOOD: "4970",
      NIMETUS: "Mokra küla",
      NIMETUS_LIIGIGA: "Mokra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004970",
    },
    {
      TASE: "3",
      KOOD: "4972",
      NIMETUS: "Moku küla",
      NIMETUS_LIIGIGA: "Moku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00004972",
    },
    {
      TASE: "3",
      KOOD: "4975",
      NIMETUS: "Moldova küla",
      NIMETUS_LIIGIGA: "Moldova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00004975",
    },
    {
      TASE: "3",
      KOOD: "4978",
      NIMETUS: "Moora küla",
      NIMETUS_LIIGIGA: "Moora küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00004978",
    },
    {
      TASE: "3",
      KOOD: "4981",
      NIMETUS: "Moori küla",
      NIMETUS_LIIGIGA: "Moori küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004981",
    },
    {
      TASE: "3",
      KOOD: "4983",
      NIMETUS: "Mooritsa küla",
      NIMETUS_LIIGIGA: "Mooritsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00004983",
    },
    {
      TASE: "3",
      KOOD: "4984",
      NIMETUS: "Moosi küla",
      NIMETUS_LIIGIGA: "Moosi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004984",
    },
    {
      TASE: "3",
      KOOD: "4986",
      NIMETUS: "Mooste alevik",
      NIMETUS_LIIGIGA: "Mooste alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00004986",
    },
    {
      TASE: "3",
      KOOD: "4989",
      NIMETUS: "Morna küla",
      NIMETUS_LIIGIGA: "Morna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00004989",
    },
    {
      TASE: "3",
      KOOD: "4993",
      NIMETUS: "Muda küla",
      NIMETUS_LIIGIGA: "Muda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004993",
    },
    {
      TASE: "3",
      KOOD: "4995",
      NIMETUS: "Mudaste küla",
      NIMETUS_LIIGIGA: "Mudaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004995",
    },
    {
      TASE: "3",
      KOOD: "4996",
      NIMETUS: "Muduri küla",
      NIMETUS_LIIGIGA: "Muduri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004996",
    },
    {
      TASE: "3",
      KOOD: "4998",
      NIMETUS: "Mudiste küla",
      NIMETUS_LIIGIGA: "Mudiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00004998",
    },
    {
      TASE: "3",
      KOOD: "5001",
      NIMETUS: "Muhkamõtsa küla",
      NIMETUS_LIIGIGA: "Muhkamõtsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005001",
    },
    {
      TASE: "3",
      KOOD: "5003",
      NIMETUS: "Muhkva küla",
      NIMETUS_LIIGIGA: "Muhkva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00005003",
    },
    {
      TASE: "3",
      KOOD: "5006",
      NIMETUS: "Mui küla",
      NIMETUS_LIIGIGA: "Mui küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005006",
    },
    {
      TASE: "3",
      KOOD: "5009",
      NIMETUS: "Muike küla",
      NIMETUS_LIIGIGA: "Muike küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00005009",
    },
    {
      TASE: "3",
      KOOD: "5012",
      NIMETUS: "Mujaste küla",
      NIMETUS_LIIGIGA: "Mujaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005012",
    },
    {
      TASE: "3",
      KOOD: "5015",
      NIMETUS: "Mukri küla",
      NIMETUS_LIIGIGA: "Mukri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00005015",
    },
    {
      TASE: "3",
      KOOD: "5017",
      NIMETUS: "Muksi küla",
      NIMETUS_LIIGIGA: "Muksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00005017",
    },
    {
      TASE: "3",
      KOOD: "5020",
      NIMETUS: "Mulgi küla",
      NIMETUS_LIIGIGA: "Mulgi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00005020",
    },
    {
      TASE: "3",
      KOOD: "5023",
      NIMETUS: "Mullavere küla",
      NIMETUS_LIIGIGA: "Mullavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00005023",
    },
    {
      TASE: "3",
      KOOD: "5024",
      NIMETUS: "Muna küla",
      NIMETUS_LIIGIGA: "Muna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005024",
    },
    {
      TASE: "3",
      KOOD: "5025",
      NIMETUS: "Mullutu küla",
      NIMETUS_LIIGIGA: "Mullutu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005025",
    },
    {
      TASE: "3",
      KOOD: "5028",
      NIMETUS: "Munalaskme küla",
      NIMETUS_LIIGIGA: "Munalaskme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00005028",
    },
    {
      TASE: "3",
      KOOD: "5031",
      NIMETUS: "Munsi küla",
      NIMETUS_LIIGIGA: "Munsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00005031",
    },
    {
      TASE: "3",
      KOOD: "5034",
      NIMETUS: "Muraja küla",
      NIMETUS_LIIGIGA: "Muraja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005034",
    },
    {
      TASE: "3",
      KOOD: "5035",
      NIMETUS: "Muraski küla",
      NIMETUS_LIIGIGA: "Muraski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005035",
    },
    {
      TASE: "3",
      KOOD: "5036",
      NIMETUS: "Muraka küla",
      NIMETUS_LIIGIGA: "Muraka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00005036",
    },
    {
      TASE: "3",
      KOOD: "5041",
      NIMETUS: "Murati küla",
      NIMETUS_LIIGIGA: "Murati küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005041",
    },
    {
      TASE: "3",
      KOOD: "5042",
      NIMETUS: "Murdõmäe küla",
      NIMETUS_LIIGIGA: "Murdõmäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005042",
    },
    {
      TASE: "3",
      KOOD: "5044",
      NIMETUS: "Muratsi küla",
      NIMETUS_LIIGIGA: "Muratsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005044",
    },
    {
      TASE: "3",
      KOOD: "5046",
      NIMETUS: "Muri küla",
      NIMETUS_LIIGIGA: "Muri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00005046",
    },
    {
      TASE: "3",
      KOOD: "5047",
      NIMETUS: "Muri küla",
      NIMETUS_LIIGIGA: "Muri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00005047",
    },
    {
      TASE: "3",
      KOOD: "5048",
      NIMETUS: "Murksi küla",
      NIMETUS_LIIGIGA: "Murksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00005048",
    },
    {
      TASE: "3",
      KOOD: "5049",
      NIMETUS: "Muriste küla",
      NIMETUS_LIIGIGA: "Muriste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005049",
    },
    {
      TASE: "3",
      KOOD: "5050",
      NIMETUS: "Murika küla",
      NIMETUS_LIIGIGA: "Murika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005050",
    },
    {
      TASE: "3",
      KOOD: "5052",
      NIMETUS: "Metsaküla",
      NIMETUS_LIIGIGA: "Metsaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00005052",
    },
    {
      TASE: "3",
      KOOD: "5053",
      NIMETUS: "Murru küla",
      NIMETUS_LIIGIGA: "Murru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00005053",
    },
    {
      TASE: "3",
      KOOD: "5054",
      NIMETUS: "Mustahamba küla",
      NIMETUS_LIIGIGA: "Mustahamba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005054",
    },
    {
      TASE: "3",
      KOOD: "5055",
      NIMETUS: "Muru küla",
      NIMETUS_LIIGIGA: "Muru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00005055",
    },
    {
      TASE: "3",
      KOOD: "5059",
      NIMETUS: "Mustajõe küla",
      NIMETUS_LIIGIGA: "Mustajõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00005059",
    },
    {
      TASE: "3",
      KOOD: "5061",
      NIMETUS: "Mustakurmu küla",
      NIMETUS_LIIGIGA: "Mustakurmu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00005061",
    },
    {
      TASE: "3",
      KOOD: "5064",
      NIMETUS: "Mustametsa küla",
      NIMETUS_LIIGIGA: "Mustametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00005064",
    },
    {
      TASE: "3",
      KOOD: "5065",
      NIMETUS: "Mustametsa küla",
      NIMETUS_LIIGIGA: "Mustametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00005065",
    },
    {
      TASE: "3",
      KOOD: "5067",
      NIMETUS: "Mustanina küla",
      NIMETUS_LIIGIGA: "Mustanina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00005067",
    },
    {
      TASE: "3",
      KOOD: "5070",
      NIMETUS: "Mustapali küla",
      NIMETUS_LIIGIGA: "Mustapali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00005070",
    },
    {
      TASE: "3",
      KOOD: "5071",
      NIMETUS: "Mustassaare küla",
      NIMETUS_LIIGIGA: "Mustassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005071",
    },
    {
      TASE: "3",
      KOOD: "5072",
      NIMETUS: "Mustaru küla",
      NIMETUS_LIIGIGA: "Mustaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00005072",
    },
    {
      TASE: "3",
      KOOD: "5075",
      NIMETUS: "Mustivere küla",
      NIMETUS_LIIGIGA: "Mustivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00005075",
    },
    {
      TASE: "3",
      KOOD: "5077",
      NIMETUS: "Mustja küla",
      NIMETUS_LIIGIGA: "Mustja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005077",
    },
    {
      TASE: "3",
      KOOD: "5080",
      NIMETUS: "Mustjala küla",
      NIMETUS_LIIGIGA: "Mustjala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005080",
    },
    {
      TASE: "3",
      KOOD: "5082",
      NIMETUS: "Mustjõe küla",
      NIMETUS_LIIGIGA: "Mustjõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00005082",
    },
    {
      TASE: "3",
      KOOD: "5083",
      NIMETUS: "Mustla küla",
      NIMETUS_LIIGIGA: "Mustla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00005083",
    },
    {
      TASE: "3",
      KOOD: "5085",
      NIMETUS: "Mustla küla",
      NIMETUS_LIIGIGA: "Mustla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00005085",
    },
    {
      TASE: "3",
      KOOD: "5087",
      NIMETUS: "Mustla küla",
      NIMETUS_LIIGIGA: "Mustla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005087",
    },
    {
      TASE: "3",
      KOOD: "5088",
      NIMETUS: "Mustmätta küla",
      NIMETUS_LIIGIGA: "Mustmätta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00005088",
    },
    {
      TASE: "3",
      KOOD: "5090",
      NIMETUS: "Mustla-Nõmme küla",
      NIMETUS_LIIGIGA: "Mustla-Nõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00005090",
    },
    {
      TASE: "3",
      KOOD: "5091",
      NIMETUS: "Mustoja küla",
      NIMETUS_LIIGIGA: "Mustoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00005091",
    },
    {
      TASE: "3",
      KOOD: "5093",
      NIMETUS: "Mustu küla",
      NIMETUS_LIIGIGA: "Mustu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00005093",
    },
    {
      TASE: "3",
      KOOD: "5096",
      NIMETUS: "Mustumetsa küla",
      NIMETUS_LIIGIGA: "Mustumetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00005096",
    },
    {
      TASE: "3",
      KOOD: "5099",
      NIMETUS: "Mutemetsa küla",
      NIMETUS_LIIGIGA: "Mutemetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005099",
    },
    {
      TASE: "3",
      KOOD: "5100",
      NIMETUS: "Mutsu küla",
      NIMETUS_LIIGIGA: "Mutsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005100",
    },
    {
      TASE: "3",
      KOOD: "5101",
      NIMETUS: "Muti küla",
      NIMETUS_LIIGIGA: "Muti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00005101",
    },
    {
      TASE: "3",
      KOOD: "5104",
      NIMETUS: "Muuga küla",
      NIMETUS_LIIGIGA: "Muuga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00005104",
    },
    {
      TASE: "3",
      KOOD: "5105",
      NIMETUS: "Muuga küla",
      NIMETUS_LIIGIGA: "Muuga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00005105",
    },
    {
      TASE: "3",
      KOOD: "5108",
      NIMETUS: "Muuksi küla",
      NIMETUS_LIIGIGA: "Muuksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00005108",
    },
    {
      TASE: "3",
      KOOD: "5110",
      NIMETUS: "Muusika küla",
      NIMETUS_LIIGIGA: "Muusika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00005110",
    },
    {
      TASE: "3",
      KOOD: "5114",
      NIMETUS: "Mõdriku küla",
      NIMETUS_LIIGIGA: "Mõdriku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00005114",
    },
    {
      TASE: "3",
      KOOD: "5117",
      NIMETUS: "Mõedaka küla",
      NIMETUS_LIIGIGA: "Mõedaka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00005117",
    },
    {
      TASE: "3",
      KOOD: "5120",
      NIMETUS: "Mõega küla",
      NIMETUS_LIIGIGA: "Mõega küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00005120",
    },
    {
      TASE: "3",
      KOOD: "5123",
      NIMETUS: "Mõhküla",
      NIMETUS_LIIGIGA: "Mõhküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00005123",
    },
    {
      TASE: "3",
      KOOD: "5126",
      NIMETUS: "Mõisaaseme küla",
      NIMETUS_LIIGIGA: "Mõisaaseme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00005126",
    },
    {
      TASE: "3",
      KOOD: "5127",
      NIMETUS: "Mõisaküla",
      NIMETUS_LIIGIGA: "Mõisaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005127",
    },
    {
      TASE: "3",
      KOOD: "5130",
      NIMETUS: "Mõisaküla",
      NIMETUS_LIIGIGA: "Mõisaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00005130",
    },
    {
      TASE: "3",
      KOOD: "5131",
      NIMETUS: "Mõisamaa küla",
      NIMETUS_LIIGIGA: "Mõisamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00005131",
    },
    {
      TASE: "3",
      KOOD: "5132",
      NIMETUS: "Mõisamaa küla",
      NIMETUS_LIIGIGA: "Mõisamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00005132",
    },
    {
      TASE: "3",
      KOOD: "5133",
      NIMETUS: "Mõisamaa küla",
      NIMETUS_LIIGIGA: "Mõisamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005133",
    },
    {
      TASE: "3",
      KOOD: "5135",
      NIMETUS: "Mõisamäe küla",
      NIMETUS_LIIGIGA: "Mõisamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005135",
    },
    {
      TASE: "3",
      KOOD: "5136",
      NIMETUS: "Mõisaküla",
      NIMETUS_LIIGIGA: "Mõisaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00005136",
    },
    {
      TASE: "3",
      KOOD: "5137",
      NIMETUS: "Mõisaküla",
      NIMETUS_LIIGIGA: "Mõisaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00005137",
    },
    {
      TASE: "3",
      KOOD: "5138",
      NIMETUS: "Mõisanurme küla",
      NIMETUS_LIIGIGA: "Mõisanurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00005138",
    },
    {
      TASE: "3",
      KOOD: "5139",
      NIMETUS: "Mõisaküla",
      NIMETUS_LIIGIGA: "Mõisaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005139",
    },
    {
      TASE: "3",
      KOOD: "5141",
      NIMETUS: "Mõksi küla",
      NIMETUS_LIIGIGA: "Mõksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005141",
    },
    {
      TASE: "3",
      KOOD: "5142",
      NIMETUS: "Mõisamaa küla",
      NIMETUS_LIIGIGA: "Mõisamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00005142",
    },
    {
      TASE: "3",
      KOOD: "5143",
      NIMETUS: "Mõisaküla",
      NIMETUS_LIIGIGA: "Mõisaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005143",
    },
    {
      TASE: "3",
      KOOD: "5144",
      NIMETUS: "Karjaküla",
      NIMETUS_LIIGIGA: "Karjaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00005144",
    },
    {
      TASE: "3",
      KOOD: "5147",
      NIMETUS: "Mõndavere küla",
      NIMETUS_LIIGIGA: "Mõndavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00005147",
    },
    {
      TASE: "3",
      KOOD: "5149",
      NIMETUS: "Mõniste küla",
      NIMETUS_LIIGIGA: "Mõniste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005149",
    },
    {
      TASE: "3",
      KOOD: "5152",
      NIMETUS: "Mõnnaste küla",
      NIMETUS_LIIGIGA: "Mõnnaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00005152",
    },
    {
      TASE: "3",
      KOOD: "5154",
      NIMETUS: "Mõnnuste küla",
      NIMETUS_LIIGIGA: "Mõnnuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005154",
    },
    {
      TASE: "3",
      KOOD: "5155",
      NIMETUS: "Mõntu küla",
      NIMETUS_LIIGIGA: "Mõntu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005155",
    },
    {
      TASE: "3",
      KOOD: "5156",
      NIMETUS: "Mõnuvere küla",
      NIMETUS_LIIGIGA: "Mõnuvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00005156",
    },
    {
      TASE: "3",
      KOOD: "5157",
      NIMETUS: "Mõnuste küla",
      NIMETUS_LIIGIGA: "Mõnuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00005157",
    },
    {
      TASE: "3",
      KOOD: "5160",
      NIMETUS: "Mõra küla",
      NIMETUS_LIIGIGA: "Mõra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00005160",
    },
    {
      TASE: "3",
      KOOD: "5162",
      NIMETUS: "Mõraste küla",
      NIMETUS_LIIGIGA: "Mõraste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005162",
    },
    {
      TASE: "3",
      KOOD: "5165",
      NIMETUS: "Mõrdu küla",
      NIMETUS_LIIGIGA: "Mõrdu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005165",
    },
    {
      TASE: "3",
      KOOD: "5166",
      NIMETUS: "Mõrtsi küla",
      NIMETUS_LIIGIGA: "Mõrtsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00005166",
    },
    {
      TASE: "3",
      KOOD: "5167",
      NIMETUS: "Mõrgi küla",
      NIMETUS_LIIGIGA: "Mõrgi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005167",
    },
    {
      TASE: "3",
      KOOD: "5170",
      NIMETUS: "Mõtsküla",
      NIMETUS_LIIGIGA: "Mõtsküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00005170",
    },
    {
      TASE: "3",
      KOOD: "5173",
      NIMETUS: "Mõtsu küla",
      NIMETUS_LIIGIGA: "Mõtsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005173",
    },
    {
      TASE: "3",
      KOOD: "5176",
      NIMETUS: "Mõõlu küla",
      NIMETUS_LIIGIGA: "Mõõlu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005176",
    },
    {
      TASE: "3",
      KOOD: "5178",
      NIMETUS: "Mõõnaste küla",
      NIMETUS_LIIGIGA: "Mõõnaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00005178",
    },
    {
      TASE: "3",
      KOOD: "5182",
      NIMETUS: "Mädapea küla",
      NIMETUS_LIIGIGA: "Mädapea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00005182",
    },
    {
      TASE: "3",
      KOOD: "5183",
      NIMETUS: "Mäeküla",
      NIMETUS_LIIGIGA: "Mäeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005183",
    },
    {
      TASE: "3",
      KOOD: "5185",
      NIMETUS: "Mädara küla",
      NIMETUS_LIIGIGA: "Mädara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00005185",
    },
    {
      TASE: "3",
      KOOD: "5186",
      NIMETUS: "Mäeküla",
      NIMETUS_LIIGIGA: "Mäeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00005186",
    },
    {
      TASE: "3",
      KOOD: "5187",
      NIMETUS: "Mäe-Palo küla",
      NIMETUS_LIIGIGA: "Mäe-Palo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005187",
    },
    {
      TASE: "3",
      KOOD: "5188",
      NIMETUS: "Mäekülä küla",
      NIMETUS_LIIGIGA: "Mäekülä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005188",
    },
    {
      TASE: "3",
      KOOD: "5189",
      NIMETUS: "Mäe-Tilga küla",
      NIMETUS_LIIGIGA: "Mäe-Tilga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005189",
    },
    {
      TASE: "3",
      KOOD: "5190",
      NIMETUS: "Mäebe küla",
      NIMETUS_LIIGIGA: "Mäebe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005190",
    },
    {
      TASE: "3",
      KOOD: "5191",
      NIMETUS: "Mäeküla",
      NIMETUS_LIIGIGA: "Mäeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00005191",
    },
    {
      TASE: "3",
      KOOD: "5192",
      NIMETUS: "Mäe-Kõoküla",
      NIMETUS_LIIGIGA: "Mäe-Kõoküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005192",
    },
    {
      TASE: "3",
      KOOD: "5193",
      NIMETUS: "Mäeküla",
      NIMETUS_LIIGIGA: "Mäeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00005193",
    },
    {
      TASE: "3",
      KOOD: "5194",
      NIMETUS: "Mäeküla",
      NIMETUS_LIIGIGA: "Mäeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00005194",
    },
    {
      TASE: "3",
      KOOD: "5195",
      NIMETUS: "Mäeküla",
      NIMETUS_LIIGIGA: "Mäeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00005195",
    },
    {
      TASE: "3",
      KOOD: "5196",
      NIMETUS: "Mäeküla",
      NIMETUS_LIIGIGA: "Mäeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00005196",
    },
    {
      TASE: "3",
      KOOD: "5197",
      NIMETUS: "Mäeküla",
      NIMETUS_LIIGIGA: "Mäeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00005197",
    },
    {
      TASE: "3",
      KOOD: "5198",
      NIMETUS: "Mäelooga küla",
      NIMETUS_LIIGIGA: "Mäelooga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00005198",
    },
    {
      TASE: "3",
      KOOD: "5201",
      NIMETUS: "Mäeltküla",
      NIMETUS_LIIGIGA: "Mäeltküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00005201",
    },
    {
      TASE: "3",
      KOOD: "5202",
      NIMETUS: "Mäense küla",
      NIMETUS_LIIGIGA: "Mäense küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005202",
    },
    {
      TASE: "3",
      KOOD: "5203",
      NIMETUS: "Mäeltse küla",
      NIMETUS_LIIGIGA: "Mäeltse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005203",
    },
    {
      TASE: "3",
      KOOD: "5206",
      NIMETUS: "Mäeotsa küla",
      NIMETUS_LIIGIGA: "Mäeotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00005206",
    },
    {
      TASE: "3",
      KOOD: "5208",
      NIMETUS: "Mäepea küla",
      NIMETUS_LIIGIGA: "Mäepea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00005208",
    },
    {
      TASE: "3",
      KOOD: "5210",
      NIMETUS: "Mäessaare küla",
      NIMETUS_LIIGIGA: "Mäessaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005210",
    },
    {
      TASE: "3",
      KOOD: "5211",
      NIMETUS: "Mäeselja küla",
      NIMETUS_LIIGIGA: "Mäeselja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00005211",
    },
    {
      TASE: "3",
      KOOD: "5212",
      NIMETUS: "Mäetaguse küla",
      NIMETUS_LIIGIGA: "Mäetaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00005212",
    },
    {
      TASE: "3",
      KOOD: "5213",
      NIMETUS: "Mäetaguse alevik",
      NIMETUS_LIIGIGA: "Mäetaguse alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00005213",
    },
    {
      TASE: "3",
      KOOD: "5214",
      NIMETUS: "Mäeküla",
      NIMETUS_LIIGIGA: "Mäeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005214",
    },
    {
      TASE: "3",
      KOOD: "5215",
      NIMETUS: "Mägede küla",
      NIMETUS_LIIGIGA: "Mägede küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00005215",
    },
    {
      TASE: "3",
      KOOD: "5216",
      NIMETUS: "Mägari küla",
      NIMETUS_LIIGIGA: "Mägari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00005216",
    },
    {
      TASE: "3",
      KOOD: "5217",
      NIMETUS: "Mäetaguse küla",
      NIMETUS_LIIGIGA: "Mäetaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00005217",
    },
    {
      TASE: "3",
      KOOD: "5219",
      NIMETUS: "Mägestiku küla",
      NIMETUS_LIIGIGA: "Mägestiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00005219",
    },
    {
      TASE: "3",
      KOOD: "5220",
      NIMETUS: "Mägi-Kurdla küla",
      NIMETUS_LIIGIGA: "Mägi-Kurdla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005220",
    },
    {
      TASE: "3",
      KOOD: "5221",
      NIMETUS: "Mägiotsa küla",
      NIMETUS_LIIGIGA: "Mägiotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00005221",
    },
    {
      TASE: "3",
      KOOD: "5224",
      NIMETUS: "Mägipe küla",
      NIMETUS_LIIGIGA: "Mägipe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005224",
    },
    {
      TASE: "3",
      KOOD: "5226",
      NIMETUS: "Mägise küla",
      NIMETUS_LIIGIGA: "Mägise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00005226",
    },
    {
      TASE: "3",
      KOOD: "5229",
      NIMETUS: "Mägiste küla",
      NIMETUS_LIIGIGA: "Mägiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00005229",
    },
    {
      TASE: "3",
      KOOD: "5232",
      NIMETUS: "Mäha küla",
      NIMETUS_LIIGIGA: "Mäha küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00005232",
    },
    {
      TASE: "3",
      KOOD: "5234",
      NIMETUS: "Mähkli küla",
      NIMETUS_LIIGIGA: "Mähkli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00005234",
    },
    {
      TASE: "3",
      KOOD: "5235",
      NIMETUS: "Mäiste küla",
      NIMETUS_LIIGIGA: "Mäiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00005235",
    },
    {
      TASE: "3",
      KOOD: "5237",
      NIMETUS: "Mähma küla",
      NIMETUS_LIIGIGA: "Mähma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00005237",
    },
    {
      TASE: "3",
      KOOD: "5240",
      NIMETUS: "Mäksa küla",
      NIMETUS_LIIGIGA: "Mäksa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00005240",
    },
    {
      TASE: "3",
      KOOD: "5243",
      NIMETUS: "Mäla küla",
      NIMETUS_LIIGIGA: "Mäla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00005243",
    },
    {
      TASE: "3",
      KOOD: "5245",
      NIMETUS: "Mäletjärve küla",
      NIMETUS_LIIGIGA: "Mäletjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00005245",
    },
    {
      TASE: "3",
      KOOD: "5246",
      NIMETUS: "Mäliste küla",
      NIMETUS_LIIGIGA: "Mäliste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005246",
    },
    {
      TASE: "3",
      KOOD: "5247",
      NIMETUS: "Mäliküla",
      NIMETUS_LIIGIGA: "Mäliküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005247",
    },
    {
      TASE: "3",
      KOOD: "5248",
      NIMETUS: "Mälgi küla",
      NIMETUS_LIIGIGA: "Mälgi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00005248",
    },
    {
      TASE: "3",
      KOOD: "5250",
      NIMETUS: "Mälivere küla",
      NIMETUS_LIIGIGA: "Mälivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00005250",
    },
    {
      TASE: "3",
      KOOD: "5253",
      NIMETUS: "Mällikvere küla",
      NIMETUS_LIIGIGA: "Mällikvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00005253",
    },
    {
      TASE: "3",
      KOOD: "5254",
      NIMETUS: "Mällu küla",
      NIMETUS_LIIGIGA: "Mällu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00005254",
    },
    {
      TASE: "3",
      KOOD: "5258",
      NIMETUS: "Männamaa küla",
      NIMETUS_LIIGIGA: "Männamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005258",
    },
    {
      TASE: "3",
      KOOD: "5259",
      NIMETUS: "Männiku küla",
      NIMETUS_LIIGIGA: "Männiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005259",
    },
    {
      TASE: "3",
      KOOD: "5262",
      NIMETUS: "Männiku küla",
      NIMETUS_LIIGIGA: "Männiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005262",
    },
    {
      TASE: "3",
      KOOD: "5264",
      NIMETUS: "Männikuste küla",
      NIMETUS_LIIGIGA: "Männikuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00005264",
    },
    {
      TASE: "3",
      KOOD: "5265",
      NIMETUS: "Männiku küla",
      NIMETUS_LIIGIGA: "Männiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005265",
    },
    {
      TASE: "3",
      KOOD: "5267",
      NIMETUS: "Männikvälja küla",
      NIMETUS_LIIGIGA: "Männikvälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00005267",
    },
    {
      TASE: "3",
      KOOD: "5269",
      NIMETUS: "Männisalu küla",
      NIMETUS_LIIGIGA: "Männisalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00005269",
    },
    {
      TASE: "3",
      KOOD: "5272",
      NIMETUS: "Mänspe küla",
      NIMETUS_LIIGIGA: "Mänspe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005272",
    },
    {
      TASE: "3",
      KOOD: "5275",
      NIMETUS: "Mäo küla",
      NIMETUS_LIIGIGA: "Mäo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00005275",
    },
    {
      TASE: "3",
      KOOD: "5276",
      NIMETUS: "Mäo küla",
      NIMETUS_LIIGIGA: "Mäo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00005276",
    },
    {
      TASE: "3",
      KOOD: "5277",
      NIMETUS: "Märja alevik",
      NIMETUS_LIIGIGA: "Märja alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "793",
      ADS_OID: "AY00005277",
    },
    {
      TASE: "3",
      KOOD: "5278",
      NIMETUS: "Märdimiku küla",
      NIMETUS_LIIGIGA: "Märdimiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005278",
    },
    {
      TASE: "3",
      KOOD: "5279",
      NIMETUS: "Märdi küla",
      NIMETUS_LIIGIGA: "Märdi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00005279",
    },
    {
      TASE: "3",
      KOOD: "5280",
      NIMETUS: "Märjamaa alev",
      NIMETUS_LIIGIGA: "Märjamaa alev",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005280",
    },
    {
      TASE: "3",
      KOOD: "5281",
      NIMETUS: "Märjandi küla",
      NIMETUS_LIIGIGA: "Märjandi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00005281",
    },
    {
      TASE: "3",
      KOOD: "5282",
      NIMETUS: "Mässa küla",
      NIMETUS_LIIGIGA: "Mässa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005282",
    },
    {
      TASE: "3",
      KOOD: "5283",
      NIMETUS: "Märdi küla",
      NIMETUS_LIIGIGA: "Märdi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005283",
    },
    {
      TASE: "3",
      KOOD: "5284",
      NIMETUS: "Mätasselja küla",
      NIMETUS_LIIGIGA: "Mätasselja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005284",
    },
    {
      TASE: "3",
      KOOD: "5287",
      NIMETUS: "Mätja küla",
      NIMETUS_LIIGIGA: "Mätja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005287",
    },
    {
      TASE: "3",
      KOOD: "5290",
      NIMETUS: "Määra küla",
      NIMETUS_LIIGIGA: "Määra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00005290",
    },
    {
      TASE: "3",
      KOOD: "5292",
      NIMETUS: "Rätsepa küla",
      NIMETUS_LIIGIGA: "Rätsepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00005292",
    },
    {
      TASE: "3",
      KOOD: "5295",
      NIMETUS: "Määri küla",
      NIMETUS_LIIGIGA: "Määri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00005295",
    },
    {
      TASE: "3",
      KOOD: "5297",
      NIMETUS: "Määsi küla",
      NIMETUS_LIIGIGA: "Määsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00005297",
    },
    {
      TASE: "3",
      KOOD: "5298",
      NIMETUS: "Määvli küla",
      NIMETUS_LIIGIGA: "Määvli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005298",
    },
    {
      TASE: "3",
      KOOD: "5300",
      NIMETUS: "Määsovitsa küla",
      NIMETUS_LIIGIGA: "Määsovitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00005300",
    },
    {
      TASE: "3",
      KOOD: "5304",
      NIMETUS: "Möldre küla",
      NIMETUS_LIIGIGA: "Möldre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00005304",
    },
    {
      TASE: "3",
      KOOD: "5305",
      NIMETUS: "Möldre küla",
      NIMETUS_LIIGIGA: "Möldre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005305",
    },
    {
      TASE: "3",
      KOOD: "5306",
      NIMETUS: "Möldri küla",
      NIMETUS_LIIGIGA: "Möldri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005306",
    },
    {
      TASE: "3",
      KOOD: "5307",
      NIMETUS: "Möldri küla",
      NIMETUS_LIIGIGA: "Möldri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005307",
    },
    {
      TASE: "3",
      KOOD: "5308",
      NIMETUS: "Möldri küla",
      NIMETUS_LIIGIGA: "Möldri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005308",
    },
    {
      TASE: "3",
      KOOD: "5310",
      NIMETUS: "Möllatsi küla",
      NIMETUS_LIIGIGA: "Möllatsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00005310",
    },
    {
      TASE: "3",
      KOOD: "5314",
      NIMETUS: "Mügra küla",
      NIMETUS_LIIGIGA: "Mügra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00005314",
    },
    {
      TASE: "3",
      KOOD: "5317",
      NIMETUS: "Mündi küla",
      NIMETUS_LIIGIGA: "Mündi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00005317",
    },
    {
      TASE: "3",
      KOOD: "5320",
      NIMETUS: "Müüriku küla",
      NIMETUS_LIIGIGA: "Müüriku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00005320",
    },
    {
      TASE: "3",
      KOOD: "5322",
      NIMETUS: "Müüsleri küla",
      NIMETUS_LIIGIGA: "Müüsleri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00005322",
    },
    {
      TASE: "3",
      KOOD: "5323",
      NIMETUS: "Naapka küla",
      NIMETUS_LIIGIGA: "Naapka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005323",
    },
    {
      TASE: "3",
      KOOD: "5327",
      NIMETUS: "Naage küla",
      NIMETUS_LIIGIGA: "Naage küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00005327",
    },
    {
      TASE: "3",
      KOOD: "5328",
      NIMETUS: "Naartse küla",
      NIMETUS_LIIGIGA: "Naartse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00005328",
    },
    {
      TASE: "3",
      KOOD: "5333",
      NIMETUS: "Nadalama küla",
      NIMETUS_LIIGIGA: "Nadalama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00005333",
    },
    {
      TASE: "3",
      KOOD: "5334",
      NIMETUS: "Nadalama küla",
      NIMETUS_LIIGIGA: "Nadalama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00005334",
    },
    {
      TASE: "3",
      KOOD: "5337",
      NIMETUS: "Naelavere küla",
      NIMETUS_LIIGIGA: "Naelavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00005337",
    },
    {
      TASE: "3",
      KOOD: "5340",
      NIMETUS: "Naha küla",
      NIMETUS_LIIGIGA: "Naha küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00005340",
    },
    {
      TASE: "3",
      KOOD: "5343",
      NIMETUS: "Nahkjala küla",
      NIMETUS_LIIGIGA: "Nahkjala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00005343",
    },
    {
      TASE: "3",
      KOOD: "5344",
      NIMETUS: "Naissoo küla",
      NIMETUS_LIIGIGA: "Naissoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005344",
    },
    {
      TASE: "3",
      KOOD: "5348",
      NIMETUS: "Naistevalla küla",
      NIMETUS_LIIGIGA: "Naistevalla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005348",
    },
    {
      TASE: "3",
      KOOD: "5349",
      NIMETUS: "Naistevalla küla",
      NIMETUS_LIIGIGA: "Naistevalla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00005349",
    },
    {
      TASE: "3",
      KOOD: "5350",
      NIMETUS: "Napi küla",
      NIMETUS_LIIGIGA: "Napi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005350",
    },
    {
      TASE: "3",
      KOOD: "5351",
      NIMETUS: "Napanurga küla",
      NIMETUS_LIIGIGA: "Napanurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005351",
    },
    {
      TASE: "3",
      KOOD: "5352",
      NIMETUS: "Naistevälja küla",
      NIMETUS_LIIGIGA: "Naistevälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00005352",
    },
    {
      TASE: "3",
      KOOD: "5353",
      NIMETUS: "Naravere küla",
      NIMETUS_LIIGIGA: "Naravere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005353",
    },
    {
      TASE: "3",
      KOOD: "5354",
      NIMETUS: "Napi küla",
      NIMETUS_LIIGIGA: "Napi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00005354",
    },
    {
      TASE: "3",
      KOOD: "5355",
      NIMETUS: "Naruski küla",
      NIMETUS_LIIGIGA: "Naruski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00005355",
    },
    {
      TASE: "3",
      KOOD: "5358",
      NIMETUS: "Nasja küla",
      NIMETUS_LIIGIGA: "Nasja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00005358",
    },
    {
      TASE: "3",
      KOOD: "5360",
      NIMETUS: "Nasva küla",
      NIMETUS_LIIGIGA: "Nasva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005360",
    },
    {
      TASE: "3",
      KOOD: "5361",
      NIMETUS: "Nasva alevik",
      NIMETUS_LIIGIGA: "Nasva alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005361",
    },
    {
      TASE: "3",
      KOOD: "5364",
      NIMETUS: "Natturi küla",
      NIMETUS_LIIGIGA: "Natturi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00005364",
    },
    {
      TASE: "3",
      KOOD: "5367",
      NIMETUS: "Nautrasi küla",
      NIMETUS_LIIGIGA: "Nautrasi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00005367",
    },
    {
      TASE: "3",
      KOOD: "5370",
      NIMETUS: "Nautse küla",
      NIMETUS_LIIGIGA: "Nautse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00005370",
    },
    {
      TASE: "3",
      KOOD: "5373",
      NIMETUS: "Nava küla",
      NIMETUS_LIIGIGA: "Nava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00005373",
    },
    {
      TASE: "3",
      KOOD: "5374",
      NIMETUS: "Nava küla",
      NIMETUS_LIIGIGA: "Nava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005374",
    },
    {
      TASE: "3",
      KOOD: "5375",
      NIMETUS: "Navesti küla",
      NIMETUS_LIIGIGA: "Navesti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00005375",
    },
    {
      TASE: "3",
      KOOD: "5376",
      NIMETUS: "Navikõ küla",
      NIMETUS_LIIGIGA: "Navikõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00005376",
    },
    {
      TASE: "3",
      KOOD: "5378",
      NIMETUS: "Navi küla",
      NIMETUS_LIIGIGA: "Navi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005378",
    },
    {
      TASE: "3",
      KOOD: "5382",
      NIMETUS: "Neanurme küla",
      NIMETUS_LIIGIGA: "Neanurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00005382",
    },
    {
      TASE: "3",
      KOOD: "5385",
      NIMETUS: "Nedrema küla",
      NIMETUS_LIIGIGA: "Nedrema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005385",
    },
    {
      TASE: "3",
      KOOD: "5387",
      NIMETUS: "Nedsaja küla",
      NIMETUS_LIIGIGA: "Nedsaja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00005387",
    },
    {
      TASE: "3",
      KOOD: "5388",
      NIMETUS: "Neeme küla",
      NIMETUS_LIIGIGA: "Neeme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005388",
    },
    {
      TASE: "3",
      KOOD: "5389",
      NIMETUS: "Neeme küla",
      NIMETUS_LIIGIGA: "Neeme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00005389",
    },
    {
      TASE: "3",
      KOOD: "5390",
      NIMETUS: "Neemi küla",
      NIMETUS_LIIGIGA: "Neemi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005390",
    },
    {
      TASE: "3",
      KOOD: "5393",
      NIMETUS: "Neemisküla",
      NIMETUS_LIIGIGA: "Neemisküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00005393",
    },
    {
      TASE: "3",
      KOOD: "5395",
      NIMETUS: "Neeruti küla",
      NIMETUS_LIIGIGA: "Neeruti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00005395",
    },
    {
      TASE: "3",
      KOOD: "5396",
      NIMETUS: "Neeruti küla",
      NIMETUS_LIIGIGA: "Neeruti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00005396",
    },
    {
      TASE: "3",
      KOOD: "5399",
      NIMETUS: "Nehatu küla",
      NIMETUS_LIIGIGA: "Nehatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005399",
    },
    {
      TASE: "3",
      KOOD: "5400",
      NIMETUS: "Nehatu küla",
      NIMETUS_LIIGIGA: "Nehatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00005400",
    },
    {
      TASE: "3",
      KOOD: "5401",
      NIMETUS: "Nenu küla",
      NIMETUS_LIIGIGA: "Nenu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005401",
    },
    {
      TASE: "3",
      KOOD: "5402",
      NIMETUS: "Neitla küla",
      NIMETUS_LIIGIGA: "Neitla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00005402",
    },
    {
      TASE: "3",
      KOOD: "5403",
      NIMETUS: "Nepste küla",
      NIMETUS_LIIGIGA: "Nepste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00005403",
    },
    {
      TASE: "3",
      KOOD: "5407",
      NIMETUS: "Nigula küla",
      NIMETUS_LIIGIGA: "Nigula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005407",
    },
    {
      TASE: "3",
      KOOD: "5408",
      NIMETUS: "Nigula küla",
      NIMETUS_LIIGIGA: "Nigula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00005408",
    },
    {
      TASE: "3",
      KOOD: "5411",
      NIMETUS: "Niguli küla",
      NIMETUS_LIIGIGA: "Niguli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00005411",
    },
    {
      TASE: "3",
      KOOD: "5412",
      NIMETUS: "Niidiküla",
      NIMETUS_LIIGIGA: "Niidiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005412",
    },
    {
      TASE: "3",
      KOOD: "5413",
      NIMETUS: "Nihka küla",
      NIMETUS_LIIGIGA: "Nihka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005413",
    },
    {
      TASE: "3",
      KOOD: "5414",
      NIMETUS: "Nihatu küla",
      NIMETUS_LIIGIGA: "Nihatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005414",
    },
    {
      TASE: "3",
      KOOD: "5415",
      NIMETUS: "Niibi küla",
      NIMETUS_LIIGIGA: "Niibi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005415",
    },
    {
      TASE: "3",
      KOOD: "5417",
      NIMETUS: "Niidu küla",
      NIMETUS_LIIGIGA: "Niidu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00005417",
    },
    {
      TASE: "3",
      KOOD: "5419",
      NIMETUS: "Niinja küla",
      NIMETUS_LIIGIGA: "Niinja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005419",
    },
    {
      TASE: "3",
      KOOD: "5422",
      NIMETUS: "Niitsiku küla",
      NIMETUS_LIIGIGA: "Niitsiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00005422",
    },
    {
      TASE: "3",
      KOOD: "5423",
      NIMETUS: "Nilbõ küla",
      NIMETUS_LIIGIGA: "Nilbõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005423",
    },
    {
      TASE: "3",
      KOOD: "5424",
      NIMETUS: "Niitvälja küla",
      NIMETUS_LIIGIGA: "Niitvälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00005424",
    },
    {
      TASE: "3",
      KOOD: "5427",
      NIMETUS: "Nina küla",
      NIMETUS_LIIGIGA: "Nina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00005427",
    },
    {
      TASE: "3",
      KOOD: "5428",
      NIMETUS: "Ninase küla",
      NIMETUS_LIIGIGA: "Ninase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005428",
    },
    {
      TASE: "3",
      KOOD: "5430",
      NIMETUS: "Ninasi küla",
      NIMETUS_LIIGIGA: "Ninasi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00005430",
    },
    {
      TASE: "3",
      KOOD: "5434",
      NIMETUS: "Nogu küla",
      NIMETUS_LIIGIGA: "Nogu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005434",
    },
    {
      TASE: "3",
      KOOD: "5437",
      NIMETUS: "Nohipalo küla",
      NIMETUS_LIIGIGA: "Nohipalo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00005437",
    },
    {
      TASE: "3",
      KOOD: "5440",
      NIMETUS: "Noodasküla",
      NIMETUS_LIIGIGA: "Noodasküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005440",
    },
    {
      TASE: "3",
      KOOD: "5442",
      NIMETUS: "Noonu küla",
      NIMETUS_LIIGIGA: "Noonu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00005442",
    },
    {
      TASE: "3",
      KOOD: "5445",
      NIMETUS: "Nooritsmetsa küla",
      NIMETUS_LIIGIGA: "Nooritsmetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00005445",
    },
    {
      TASE: "3",
      KOOD: "5447",
      NIMETUS: "Noorma küla",
      NIMETUS_LIIGIGA: "Noorma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00005447",
    },
    {
      TASE: "3",
      KOOD: "5450",
      NIMETUS: "Nooska küla",
      NIMETUS_LIIGIGA: "Nooska küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005450",
    },
    {
      TASE: "3",
      KOOD: "5453",
      NIMETUS: "Norrby küla",
      NIMETUS_LIIGIGA: "Norrby küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00005453",
    },
    {
      TASE: "3",
      KOOD: "5455",
      NIMETUS: "Norra küla",
      NIMETUS_LIIGIGA: "Norra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00005455",
    },
    {
      TASE: "3",
      KOOD: "5456",
      NIMETUS: "Nugeri küla",
      NIMETUS_LIIGIGA: "Nugeri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00005456",
    },
    {
      TASE: "3",
      KOOD: "5459",
      NIMETUS: "Nulga küla",
      NIMETUS_LIIGIGA: "Nulga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00005459",
    },
    {
      TASE: "3",
      KOOD: "5462",
      NIMETUS: "Nurga küla",
      NIMETUS_LIIGIGA: "Nurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00005462",
    },
    {
      TASE: "3",
      KOOD: "5465",
      NIMETUS: "Nurkse küla",
      NIMETUS_LIIGIGA: "Nurkse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00005465",
    },
    {
      TASE: "3",
      KOOD: "5466",
      NIMETUS: "Nurme küla",
      NIMETUS_LIIGIGA: "Nurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00005466",
    },
    {
      TASE: "3",
      KOOD: "5467",
      NIMETUS: "Nurme küla",
      NIMETUS_LIIGIGA: "Nurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00005467",
    },
    {
      TASE: "3",
      KOOD: "5468",
      NIMETUS: "Nurme küla",
      NIMETUS_LIIGIGA: "Nurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00005468",
    },
    {
      TASE: "3",
      KOOD: "5469",
      NIMETUS: "Nurme küla",
      NIMETUS_LIIGIGA: "Nurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005469",
    },
    {
      TASE: "3",
      KOOD: "5470",
      NIMETUS: "Seli-Nurme küla",
      NIMETUS_LIIGIGA: "Seli-Nurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00005470",
    },
    {
      TASE: "3",
      KOOD: "5471",
      NIMETUS: "Nurmetu küla",
      NIMETUS_LIIGIGA: "Nurmetu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00005471",
    },
    {
      TASE: "3",
      KOOD: "5472",
      NIMETUS: "Nurme küla",
      NIMETUS_LIIGIGA: "Nurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005472",
    },
    {
      TASE: "3",
      KOOD: "5473",
      NIMETUS: "Nurmsi küla",
      NIMETUS_LIIGIGA: "Nurmsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005473",
    },
    {
      TASE: "3",
      KOOD: "5474",
      NIMETUS: "Nurmsi küla",
      NIMETUS_LIIGIGA: "Nurmsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00005474",
    },
    {
      TASE: "3",
      KOOD: "5475",
      NIMETUS: "Nurme küla",
      NIMETUS_LIIGIGA: "Nurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00005475",
    },
    {
      TASE: "3",
      KOOD: "5477",
      NIMETUS: "Nursi küla",
      NIMETUS_LIIGIGA: "Nursi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005477",
    },
    {
      TASE: "3",
      KOOD: "5478",
      NIMETUS: "Nurme küla",
      NIMETUS_LIIGIGA: "Nurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00005478",
    },
    {
      TASE: "3",
      KOOD: "5479",
      NIMETUS: "Nurste küla",
      NIMETUS_LIIGIGA: "Nurste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005479",
    },
    {
      TASE: "3",
      KOOD: "5482",
      NIMETUS: "Nurtu-Nõlva küla",
      NIMETUS_LIIGIGA: "Nurtu-Nõlva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005482",
    },
    {
      TASE: "3",
      KOOD: "5485",
      NIMETUS: "Nutu küla",
      NIMETUS_LIIGIGA: "Nutu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00005485",
    },
    {
      TASE: "3",
      KOOD: "5492",
      NIMETUS: "Nõela küla",
      NIMETUS_LIIGIGA: "Nõela küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00005492",
    },
    {
      TASE: "3",
      KOOD: "5495",
      NIMETUS: "Nõgiaru küla",
      NIMETUS_LIIGIGA: "Nõgiaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00005495",
    },
    {
      TASE: "3",
      KOOD: "5498",
      NIMETUS: "Nõlva küla",
      NIMETUS_LIIGIGA: "Nõlva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00005498",
    },
    {
      TASE: "3",
      KOOD: "5501",
      NIMETUS: "Nõmavere küla",
      NIMETUS_LIIGIGA: "Nõmavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00005501",
    },
    {
      TASE: "3",
      KOOD: "5503",
      NIMETUS: "Nõmba küla",
      NIMETUS_LIIGIGA: "Nõmba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005503",
    },
    {
      TASE: "3",
      KOOD: "5506",
      NIMETUS: "Nõmbra küla",
      NIMETUS_LIIGIGA: "Nõmbra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00005506",
    },
    {
      TASE: "3",
      KOOD: "5508",
      NIMETUS: "Nõmme küla",
      NIMETUS_LIIGIGA: "Nõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00005508",
    },
    {
      TASE: "3",
      KOOD: "5512",
      NIMETUS: "Nõmme küla",
      NIMETUS_LIIGIGA: "Nõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005512",
    },
    {
      TASE: "3",
      KOOD: "5513",
      NIMETUS: "Nõmmemaa küla",
      NIMETUS_LIIGIGA: "Nõmmemaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005513",
    },
    {
      TASE: "3",
      KOOD: "5514",
      NIMETUS: "Nõmmerga küla",
      NIMETUS_LIIGIGA: "Nõmmerga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005514",
    },
    {
      TASE: "3",
      KOOD: "3803",
      NIMETUS: "Kõrkküla",
      NIMETUS_LIIGIGA: "Kõrkküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00003803",
    },
    {
      TASE: "3",
      KOOD: "3804",
      NIMETUS: "Kõrkküla",
      NIMETUS_LIIGIGA: "Kõrkküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00003804",
    },
    {
      TASE: "3",
      KOOD: "3807",
      NIMETUS: "Kõrkvere küla",
      NIMETUS_LIIGIGA: "Kõrkvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003807",
    },
    {
      TASE: "3",
      KOOD: "3809",
      NIMETUS: "Kõrma küla",
      NIMETUS_LIIGIGA: "Kõrma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00003809",
    },
    {
      TASE: "3",
      KOOD: "3812",
      NIMETUS: "Kõrsa küla",
      NIMETUS_LIIGIGA: "Kõrsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00003812",
    },
    {
      TASE: "3",
      KOOD: "3813",
      NIMETUS: "Kõrtsuotsa küla",
      NIMETUS_LIIGIGA: "Kõrtsuotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003813",
    },
    {
      TASE: "3",
      KOOD: "3814",
      NIMETUS: "Kõrtsialuse küla",
      NIMETUS_LIIGIGA: "Kõrtsialuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00003814",
    },
    {
      TASE: "3",
      KOOD: "3817",
      NIMETUS: "Kõruse küla",
      NIMETUS_LIIGIGA: "Kõruse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003817",
    },
    {
      TASE: "3",
      KOOD: "3818",
      NIMETUS: "Kõrve küla",
      NIMETUS_LIIGIGA: "Kõrve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003818",
    },
    {
      TASE: "3",
      KOOD: "3819",
      NIMETUS: "Kõrve küla",
      NIMETUS_LIIGIGA: "Kõrve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00003819",
    },
    {
      TASE: "3",
      KOOD: "3822",
      NIMETUS: "Kõrveküla",
      NIMETUS_LIIGIGA: "Kõrveküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00003822",
    },
    {
      TASE: "3",
      KOOD: "3823",
      NIMETUS: "Kõrveküla",
      NIMETUS_LIIGIGA: "Kõrveküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00003823",
    },
    {
      TASE: "3",
      KOOD: "3824",
      NIMETUS: "Kõrveküla alevik",
      NIMETUS_LIIGIGA: "Kõrveküla alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00003824",
    },
    {
      TASE: "3",
      KOOD: "3826",
      NIMETUS: "Kõrvemetsa küla",
      NIMETUS_LIIGIGA: "Kõrvemetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00003826",
    },
    {
      TASE: "3",
      KOOD: "3829",
      NIMETUS: "Kõrvenurga küla",
      NIMETUS_LIIGIGA: "Kõrvenurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00003829",
    },
    {
      TASE: "3",
      KOOD: "3831",
      NIMETUS: "Kõrvetaguse küla",
      NIMETUS_LIIGIGA: "Kõrvetaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003831",
    },
    {
      TASE: "3",
      KOOD: "3834",
      NIMETUS: "Kõue küla",
      NIMETUS_LIIGIGA: "Kõue küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00003834",
    },
    {
      TASE: "3",
      KOOD: "3837",
      NIMETUS: "Kõvaküla",
      NIMETUS_LIIGIGA: "Kõvaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00003837",
    },
    {
      TASE: "3",
      KOOD: "3838",
      NIMETUS: "Kõvera küla",
      NIMETUS_LIIGIGA: "Kõvera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003838",
    },
    {
      TASE: "3",
      KOOD: "3840",
      NIMETUS: "Kõveri küla",
      NIMETUS_LIIGIGA: "Kõveri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00003840",
    },
    {
      TASE: "3",
      KOOD: "3842",
      NIMETUS: "Kõveriku küla",
      NIMETUS_LIIGIGA: "Kõveriku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00003842",
    },
    {
      TASE: "3",
      KOOD: "3843",
      NIMETUS: "Kõõru küla",
      NIMETUS_LIIGIGA: "Kõõru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003843",
    },
    {
      TASE: "3",
      KOOD: "3844",
      NIMETUS: "Käbiküla",
      NIMETUS_LIIGIGA: "Käbiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003844",
    },
    {
      TASE: "3",
      KOOD: "3845",
      NIMETUS: "Kõõru küla",
      NIMETUS_LIIGIGA: "Kõõru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003845",
    },
    {
      TASE: "3",
      KOOD: "3846",
      NIMETUS: "Käbiküla",
      NIMETUS_LIIGIGA: "Käbiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00003846",
    },
    {
      TASE: "3",
      KOOD: "3849",
      NIMETUS: "Käpla küla",
      NIMETUS_LIIGIGA: "Käpla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00003849",
    },
    {
      TASE: "3",
      KOOD: "3851",
      NIMETUS: "Käbli küla",
      NIMETUS_LIIGIGA: "Käbli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003851",
    },
    {
      TASE: "3",
      KOOD: "3854",
      NIMETUS: "Kädva küla",
      NIMETUS_LIIGIGA: "Kädva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00003854",
    },
    {
      TASE: "3",
      KOOD: "3857",
      NIMETUS: "Käesalu küla",
      NIMETUS_LIIGIGA: "Käesalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00003857",
    },
    {
      TASE: "3",
      KOOD: "3860",
      NIMETUS: "Käesla küla",
      NIMETUS_LIIGIGA: "Käesla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003860",
    },
    {
      TASE: "3",
      KOOD: "3861",
      NIMETUS: "Kähri küla",
      NIMETUS_LIIGIGA: "Kähri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003861",
    },
    {
      TASE: "3",
      KOOD: "3863",
      NIMETUS: "Kähri küla",
      NIMETUS_LIIGIGA: "Kähri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00003863",
    },
    {
      TASE: "3",
      KOOD: "3864",
      NIMETUS: "Kähri küla",
      NIMETUS_LIIGIGA: "Kähri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00003864",
    },
    {
      TASE: "3",
      KOOD: "3866",
      NIMETUS: "Kähu küla",
      NIMETUS_LIIGIGA: "Kähu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00003866",
    },
    {
      TASE: "3",
      KOOD: "3869",
      NIMETUS: "Käina alevik",
      NIMETUS_LIIGIGA: "Käina alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003869",
    },
    {
      TASE: "3",
      KOOD: "3870",
      NIMETUS: "Käku küla",
      NIMETUS_LIIGIGA: "Käku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003870",
    },
    {
      TASE: "3",
      KOOD: "3872",
      NIMETUS: "Kämara küla",
      NIMETUS_LIIGIGA: "Kämara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00003872",
    },
    {
      TASE: "3",
      KOOD: "3873",
      NIMETUS: "Kängsepä küla",
      NIMETUS_LIIGIGA: "Kängsepä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003873",
    },
    {
      TASE: "3",
      KOOD: "3878",
      NIMETUS: "Kännuküla",
      NIMETUS_LIIGIGA: "Kännuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00003878",
    },
    {
      TASE: "3",
      KOOD: "3881",
      NIMETUS: "Käo küla",
      NIMETUS_LIIGIGA: "Käo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00003881",
    },
    {
      TASE: "3",
      KOOD: "3882",
      NIMETUS: "Käo küla",
      NIMETUS_LIIGIGA: "Käo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003882",
    },
    {
      TASE: "3",
      KOOD: "3883",
      NIMETUS: "Käpa küla",
      NIMETUS_LIIGIGA: "Käpa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003883",
    },
    {
      TASE: "3",
      KOOD: "3884",
      NIMETUS: "Kärasi küla",
      NIMETUS_LIIGIGA: "Kärasi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00003884",
    },
    {
      TASE: "3",
      KOOD: "3886",
      NIMETUS: "Käravete alevik",
      NIMETUS_LIIGIGA: "Käravete alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00003886",
    },
    {
      TASE: "3",
      KOOD: "3889",
      NIMETUS: "Kärbla küla",
      NIMETUS_LIIGIGA: "Kärbla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00003889",
    },
    {
      TASE: "3",
      KOOD: "3891",
      NIMETUS: "Kärbu küla",
      NIMETUS_LIIGIGA: "Kärbu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00003891",
    },
    {
      TASE: "3",
      KOOD: "3894",
      NIMETUS: "Kärde küla",
      NIMETUS_LIIGIGA: "Kärde küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00003894",
    },
    {
      TASE: "3",
      KOOD: "3896",
      NIMETUS: "Kärdu küla",
      NIMETUS_LIIGIGA: "Kärdu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003896",
    },
    {
      TASE: "3",
      KOOD: "3899",
      NIMETUS: "Kärevere küla",
      NIMETUS_LIIGIGA: "Kärevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00003899",
    },
    {
      TASE: "3",
      KOOD: "3900",
      NIMETUS: "Kärevere küla",
      NIMETUS_LIIGIGA: "Kärevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00003900",
    },
    {
      TASE: "3",
      KOOD: "3901",
      NIMETUS: "Kärevere küla",
      NIMETUS_LIIGIGA: "Kärevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00003901",
    },
    {
      TASE: "3",
      KOOD: "3903",
      NIMETUS: "Kärgula küla",
      NIMETUS_LIIGIGA: "Kärgula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003903",
    },
    {
      TASE: "3",
      KOOD: "3906",
      NIMETUS: "Kärinä küla",
      NIMETUS_LIIGIGA: "Kärinä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003906",
    },
    {
      TASE: "3",
      KOOD: "3908",
      NIMETUS: "Käriselja küla",
      NIMETUS_LIIGIGA: "Käriselja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00003908",
    },
    {
      TASE: "3",
      KOOD: "3911",
      NIMETUS: "Kärkna küla",
      NIMETUS_LIIGIGA: "Kärkna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00003911",
    },
    {
      TASE: "3",
      KOOD: "3913",
      NIMETUS: "Kärksi küla",
      NIMETUS_LIIGIGA: "Kärksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00003913",
    },
    {
      TASE: "3",
      KOOD: "3916",
      NIMETUS: "Kärla alevik",
      NIMETUS_LIIGIGA: "Kärla alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003916",
    },
    {
      TASE: "3",
      KOOD: "3918",
      NIMETUS: "Kärmu küla",
      NIMETUS_LIIGIGA: "Kärmu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00003918",
    },
    {
      TASE: "3",
      KOOD: "3919",
      NIMETUS: "Kärpla küla",
      NIMETUS_LIIGIGA: "Kärpla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00003919",
    },
    {
      TASE: "3",
      KOOD: "3921",
      NIMETUS: "Kärnamäe küla",
      NIMETUS_LIIGIGA: "Kärnamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003921",
    },
    {
      TASE: "3",
      KOOD: "3922",
      NIMETUS: "Kärneri küla",
      NIMETUS_LIIGIGA: "Kärneri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003922",
    },
    {
      TASE: "3",
      KOOD: "3923",
      NIMETUS: "Kärsa küla",
      NIMETUS_LIIGIGA: "Kärsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00003923",
    },
    {
      TASE: "3",
      KOOD: "3924",
      NIMETUS: "Kärsa küla",
      NIMETUS_LIIGIGA: "Kärsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00003924",
    },
    {
      TASE: "3",
      KOOD: "3927",
      NIMETUS: "Kärstna küla",
      NIMETUS_LIIGIGA: "Kärstna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00003927",
    },
    {
      TASE: "3",
      KOOD: "3930",
      NIMETUS: "Käru küla",
      NIMETUS_LIIGIGA: "Käru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00003930",
    },
    {
      TASE: "3",
      KOOD: "3932",
      NIMETUS: "Käru küla",
      NIMETUS_LIIGIGA: "Käru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00003932",
    },
    {
      TASE: "3",
      KOOD: "3934",
      NIMETUS: "Käsmu küla",
      NIMETUS_LIIGIGA: "Käsmu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00003934",
    },
    {
      TASE: "3",
      KOOD: "3939",
      NIMETUS: "Kääni küla",
      NIMETUS_LIIGIGA: "Kääni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00003939",
    },
    {
      TASE: "3",
      KOOD: "3941",
      NIMETUS: "Käänu küla",
      NIMETUS_LIIGIGA: "Käänu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003941",
    },
    {
      TASE: "3",
      KOOD: "3944",
      NIMETUS: "Kääpa küla",
      NIMETUS_LIIGIGA: "Kääpa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003944",
    },
    {
      TASE: "3",
      KOOD: "3946",
      NIMETUS: "Kääraku küla",
      NIMETUS_LIIGIGA: "Kääraku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003946",
    },
    {
      TASE: "3",
      KOOD: "3949",
      NIMETUS: "Käärdi alevik",
      NIMETUS_LIIGIGA: "Käärdi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00003949",
    },
    {
      TASE: "3",
      KOOD: "3951",
      NIMETUS: "Käärikmäe küla",
      NIMETUS_LIIGIGA: "Käärikmäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00003951",
    },
    {
      TASE: "3",
      KOOD: "3954",
      NIMETUS: "Kääriku küla",
      NIMETUS_LIIGIGA: "Kääriku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00003954",
    },
    {
      TASE: "3",
      KOOD: "3956",
      NIMETUS: "Käätso küla",
      NIMETUS_LIIGIGA: "Käätso küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003956",
    },
    {
      TASE: "3",
      KOOD: "3960",
      NIMETUS: "Köisi küla",
      NIMETUS_LIIGIGA: "Köisi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00003960",
    },
    {
      TASE: "3",
      KOOD: "3963",
      NIMETUS: "Köstrimäe küla",
      NIMETUS_LIIGIGA: "Köstrimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00003963",
    },
    {
      TASE: "3",
      KOOD: "3964",
      NIMETUS: "Kübassaare küla",
      NIMETUS_LIIGIGA: "Kübassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003964",
    },
    {
      TASE: "3",
      KOOD: "3965",
      NIMETUS: "Kühmamäe küla",
      NIMETUS_LIIGIGA: "Kühmamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003965",
    },
    {
      TASE: "3",
      KOOD: "3967",
      NIMETUS: "Küdema küla",
      NIMETUS_LIIGIGA: "Küdema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003967",
    },
    {
      TASE: "3",
      KOOD: "3968",
      NIMETUS: "Kükita küla",
      NIMETUS_LIIGIGA: "Kükita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00003968",
    },
    {
      TASE: "3",
      KOOD: "3970",
      NIMETUS: "Kükitaja küla",
      NIMETUS_LIIGIGA: "Kükitaja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00003970",
    },
    {
      TASE: "3",
      KOOD: "3973",
      NIMETUS: "Külaaseme küla",
      NIMETUS_LIIGIGA: "Külaaseme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00003973",
    },
    {
      TASE: "3",
      KOOD: "3976",
      NIMETUS: "Külaküla",
      NIMETUS_LIIGIGA: "Külaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003976",
    },
    {
      TASE: "3",
      KOOD: "3978",
      NIMETUS: "Külama küla",
      NIMETUS_LIIGIGA: "Külama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00003978",
    },
    {
      TASE: "3",
      KOOD: "3981",
      NIMETUS: "Külaoru küla",
      NIMETUS_LIIGIGA: "Külaoru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003981",
    },
    {
      TASE: "3",
      KOOD: "3983",
      NIMETUS: "Külasema küla",
      NIMETUS_LIIGIGA: "Külasema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00003983",
    },
    {
      TASE: "3",
      KOOD: "3986",
      NIMETUS: "Külitse alevik",
      NIMETUS_LIIGIGA: "Külitse alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00003986",
    },
    {
      TASE: "3",
      KOOD: "3988",
      NIMETUS: "Küllätüvä küla",
      NIMETUS_LIIGIGA: "Küllätüvä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00003988",
    },
    {
      TASE: "3",
      KOOD: "3989",
      NIMETUS: "Külma küla",
      NIMETUS_LIIGIGA: "Külma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00003989",
    },
    {
      TASE: "3",
      KOOD: "3990",
      NIMETUS: "Külma küla",
      NIMETUS_LIIGIGA: "Külma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00003990",
    },
    {
      TASE: "3",
      KOOD: "3991",
      NIMETUS: "Kündja küla",
      NIMETUS_LIIGIGA: "Kündja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00003991",
    },
    {
      TASE: "3",
      KOOD: "3992",
      NIMETUS: "Küti küla",
      NIMETUS_LIIGIGA: "Küti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00003992",
    },
    {
      TASE: "3",
      KOOD: "3993",
      NIMETUS: "Külmaallika küla",
      NIMETUS_LIIGIGA: "Külmaallika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00003993",
    },
    {
      TASE: "3",
      KOOD: "3994",
      NIMETUS: "Küti küla",
      NIMETUS_LIIGIGA: "Küti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00003994",
    },
    {
      TASE: "3",
      KOOD: "3997",
      NIMETUS: "Kütke küla",
      NIMETUS_LIIGIGA: "Kütke küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00003997",
    },
    {
      TASE: "3",
      KOOD: "4002",
      NIMETUS: "Laabi küla",
      NIMETUS_LIIGIGA: "Laabi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00004002",
    },
    {
      TASE: "3",
      KOOD: "4004",
      NIMETUS: "Laadi küla",
      NIMETUS_LIIGIGA: "Laadi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00004004",
    },
    {
      TASE: "3",
      KOOD: "4007",
      NIMETUS: "Laadjala küla",
      NIMETUS_LIIGIGA: "Laadjala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004007",
    },
    {
      TASE: "3",
      KOOD: "4009",
      NIMETUS: "Laadla küla",
      NIMETUS_LIIGIGA: "Laadla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004009",
    },
    {
      TASE: "3",
      KOOD: "4012",
      NIMETUS: "Laagna küla",
      NIMETUS_LIIGIGA: "Laagna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00004012",
    },
    {
      TASE: "3",
      KOOD: "4014",
      NIMETUS: "Laagri alevik",
      NIMETUS_LIIGIGA: "Laagri alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00004014",
    },
    {
      TASE: "3",
      KOOD: "4017",
      NIMETUS: "Laane küla",
      NIMETUS_LIIGIGA: "Laane küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00004017",
    },
    {
      TASE: "3",
      KOOD: "4018",
      NIMETUS: "Laane küla",
      NIMETUS_LIIGIGA: "Laane küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00004018",
    },
    {
      TASE: "3",
      KOOD: "4019",
      NIMETUS: "Laane küla",
      NIMETUS_LIIGIGA: "Laane küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00004019",
    },
    {
      TASE: "3",
      KOOD: "4020",
      NIMETUS: "Laane küla",
      NIMETUS_LIIGIGA: "Laane küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00004020",
    },
    {
      TASE: "3",
      KOOD: "4021",
      NIMETUS: "Laanekuru küla",
      NIMETUS_LIIGIGA: "Laanekuru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004021",
    },
    {
      TASE: "3",
      KOOD: "4022",
      NIMETUS: "Laaneotsa küla",
      NIMETUS_LIIGIGA: "Laaneotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00004022",
    },
    {
      TASE: "3",
      KOOD: "4023",
      NIMETUS: "Laasi küla",
      NIMETUS_LIIGIGA: "Laasi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004023",
    },
    {
      TASE: "3",
      KOOD: "4024",
      NIMETUS: "Laanemetsa küla",
      NIMETUS_LIIGIGA: "Laanemetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00004024",
    },
    {
      TASE: "3",
      KOOD: "4025",
      NIMETUS: "Laartsa küla",
      NIMETUS_LIIGIGA: "Laartsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004025",
    },
    {
      TASE: "3",
      KOOD: "4026",
      NIMETUS: "Laasme küla",
      NIMETUS_LIIGIGA: "Laasme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00004026",
    },
    {
      TASE: "3",
      KOOD: "4029",
      NIMETUS: "Laatre alevik",
      NIMETUS_LIIGIGA: "Laatre alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00004029",
    },
    {
      TASE: "3",
      KOOD: "4030",
      NIMETUS: "Laatre küla",
      NIMETUS_LIIGIGA: "Laatre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00004030",
    },
    {
      TASE: "3",
      KOOD: "4033",
      NIMETUS: "Laekannu küla",
      NIMETUS_LIIGIGA: "Laekannu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00004033",
    },
    {
      TASE: "3",
      KOOD: "4035",
      NIMETUS: "Laekvere alevik",
      NIMETUS_LIIGIGA: "Laekvere alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00004035",
    },
    {
      TASE: "3",
      KOOD: "4038",
      NIMETUS: "Laeste küla",
      NIMETUS_LIIGIGA: "Laeste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00004038",
    },
    {
      TASE: "3",
      KOOD: "4040",
      NIMETUS: "Laeva küla",
      NIMETUS_LIIGIGA: "Laeva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00004040",
    },
    {
      TASE: "3",
      KOOD: "4043",
      NIMETUS: "Lagedi alevik",
      NIMETUS_LIIGIGA: "Lagedi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00004043",
    },
    {
      TASE: "3",
      KOOD: "4046",
      NIMETUS: "Laguja küla",
      NIMETUS_LIIGIGA: "Laguja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00004046",
    },
    {
      TASE: "3",
      KOOD: "4049",
      NIMETUS: "Lahavere küla",
      NIMETUS_LIIGIGA: "Lahavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00004049",
    },
    {
      TASE: "3",
      KOOD: "4050",
      NIMETUS: "Lahe küla",
      NIMETUS_LIIGIGA: "Lahe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00004050",
    },
    {
      TASE: "3",
      KOOD: "4051",
      NIMETUS: "Lahe küla",
      NIMETUS_LIIGIGA: "Lahe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00004051",
    },
    {
      TASE: "3",
      KOOD: "4052",
      NIMETUS: "Lahe küla",
      NIMETUS_LIIGIGA: "Lahe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00004052",
    },
    {
      TASE: "3",
      KOOD: "4053",
      NIMETUS: "Laheküla",
      NIMETUS_LIIGIGA: "Laheküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004053",
    },
    {
      TASE: "3",
      KOOD: "4055",
      NIMETUS: "Lahepera küla",
      NIMETUS_LIIGIGA: "Lahepera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00004055",
    },
    {
      TASE: "3",
      KOOD: "4056",
      NIMETUS: "Laheküla",
      NIMETUS_LIIGIGA: "Laheküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004056",
    },
    {
      TASE: "3",
      KOOD: "4057",
      NIMETUS: "Lahetaguse küla",
      NIMETUS_LIIGIGA: "Lahetaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004057",
    },
    {
      TASE: "3",
      KOOD: "4058",
      NIMETUS: "Laheküla",
      NIMETUS_LIIGIGA: "Laheküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00004058",
    },
    {
      TASE: "3",
      KOOD: "4060",
      NIMETUS: "Lahmuse küla",
      NIMETUS_LIIGIGA: "Lahmuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00004060",
    },
    {
      TASE: "3",
      KOOD: "4062",
      NIMETUS: "Laho küla",
      NIMETUS_LIIGIGA: "Laho küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00004062",
    },
    {
      TASE: "3",
      KOOD: "4063",
      NIMETUS: "Laheva küla",
      NIMETUS_LIIGIGA: "Laheva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00004063",
    },
    {
      TASE: "3",
      KOOD: "4064",
      NIMETUS: "Laiaküla",
      NIMETUS_LIIGIGA: "Laiaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00004064",
    },
    {
      TASE: "3",
      KOOD: "4065",
      NIMETUS: "Lahu küla",
      NIMETUS_LIIGIGA: "Lahu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00004065",
    },
    {
      TASE: "3",
      KOOD: "4066",
      NIMETUS: "Laho küla",
      NIMETUS_LIIGIGA: "Laho küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00004066",
    },
    {
      TASE: "3",
      KOOD: "4068",
      NIMETUS: "Laiküla",
      NIMETUS_LIIGIGA: "Laiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00004068",
    },
    {
      TASE: "3",
      KOOD: "4070",
      NIMETUS: "Laimetsa küla",
      NIMETUS_LIIGIGA: "Laimetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00004070",
    },
    {
      TASE: "3",
      KOOD: "4071",
      NIMETUS: "Laisi küla",
      NIMETUS_LIIGIGA: "Laisi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004071",
    },
    {
      TASE: "3",
      KOOD: "4073",
      NIMETUS: "Laimjala küla",
      NIMETUS_LIIGIGA: "Laimjala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004073",
    },
    {
      TASE: "3",
      KOOD: "4075",
      NIMETUS: "Laitse küla",
      NIMETUS_LIIGIGA: "Laitse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00004075",
    },
    {
      TASE: "3",
      KOOD: "4078",
      NIMETUS: "Laiuse alevik",
      NIMETUS_LIIGIGA: "Laiuse alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00004078",
    },
    {
      TASE: "3",
      KOOD: "4079",
      NIMETUS: "Lakovitsa küla",
      NIMETUS_LIIGIGA: "Lakovitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004079",
    },
    {
      TASE: "3",
      KOOD: "4080",
      NIMETUS: "Laiusevälja küla",
      NIMETUS_LIIGIGA: "Laiusevälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00004080",
    },
    {
      TASE: "3",
      KOOD: "4083",
      NIMETUS: "Lalli küla",
      NIMETUS_LIIGIGA: "Lalli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00004083",
    },
    {
      TASE: "3",
      KOOD: "4084",
      NIMETUS: "Lalli küla",
      NIMETUS_LIIGIGA: "Lalli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00004084",
    },
    {
      TASE: "3",
      KOOD: "4085",
      NIMETUS: "Lalli küla",
      NIMETUS_LIIGIGA: "Lalli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00004085",
    },
    {
      TASE: "3",
      KOOD: "4088",
      NIMETUS: "Lalsi küla",
      NIMETUS_LIIGIGA: "Lalsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004088",
    },
    {
      TASE: "3",
      KOOD: "4091",
      NIMETUS: "Lammasküla",
      NIMETUS_LIIGIGA: "Lammasküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00004091",
    },
    {
      TASE: "3",
      KOOD: "4093",
      NIMETUS: "Lammiku küla",
      NIMETUS_LIIGIGA: "Lammiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00004093",
    },
    {
      TASE: "3",
      KOOD: "4096",
      NIMETUS: "Langa küla",
      NIMETUS_LIIGIGA: "Langa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00004096",
    },
    {
      TASE: "3",
      KOOD: "4099",
      NIMETUS: "Lange küla",
      NIMETUS_LIIGIGA: "Lange küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00004099",
    },
    {
      TASE: "3",
      KOOD: "4101",
      NIMETUS: "Langerma küla",
      NIMETUS_LIIGIGA: "Langerma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00004101",
    },
    {
      TASE: "3",
      KOOD: "4102",
      NIMETUS: "Lannuste küla",
      NIMETUS_LIIGIGA: "Lannuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00004102",
    },
    {
      TASE: "3",
      KOOD: "4104",
      NIMETUS: "Lanksaare küla",
      NIMETUS_LIIGIGA: "Lanksaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00004104",
    },
    {
      TASE: "3",
      KOOD: "4106",
      NIMETUS: "Lante küla",
      NIMETUS_LIIGIGA: "Lante küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00004106",
    },
    {
      TASE: "3",
      KOOD: "4109",
      NIMETUS: "Lao küla",
      NIMETUS_LIIGIGA: "Lao küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00004109",
    },
    {
      TASE: "3",
      KOOD: "4110",
      NIMETUS: "Laoküla",
      NIMETUS_LIIGIGA: "Laoküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004110",
    },
    {
      TASE: "3",
      KOOD: "4111",
      NIMETUS: "Laossaarõ küla",
      NIMETUS_LIIGIGA: "Laossaarõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004111",
    },
    {
      TASE: "3",
      KOOD: "4112",
      NIMETUS: "Laoküla",
      NIMETUS_LIIGIGA: "Laoküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00004112",
    },
    {
      TASE: "3",
      KOOD: "4114",
      NIMETUS: "Laossina küla",
      NIMETUS_LIIGIGA: "Laossina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004114",
    },
    {
      TASE: "3",
      KOOD: "4117",
      NIMETUS: "Lapetukme küla",
      NIMETUS_LIIGIGA: "Lapetukme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00004117",
    },
    {
      TASE: "3",
      KOOD: "4118",
      NIMETUS: "Lapi küla",
      NIMETUS_LIIGIGA: "Lapi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004118",
    },
    {
      TASE: "3",
      KOOD: "4120",
      NIMETUS: "Lasari küla",
      NIMETUS_LIIGIGA: "Lasari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00004120",
    },
    {
      TASE: "3",
      KOOD: "4123",
      NIMETUS: "Lasila küla",
      NIMETUS_LIIGIGA: "Lasila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00004123",
    },
    {
      TASE: "3",
      KOOD: "4125",
      NIMETUS: "Lasinurme küla",
      NIMETUS_LIIGIGA: "Lasinurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00004125",
    },
    {
      TASE: "3",
      KOOD: "4128",
      NIMETUS: "Lassi küla",
      NIMETUS_LIIGIGA: "Lassi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004128",
    },
    {
      TASE: "3",
      KOOD: "4129",
      NIMETUS: "Lassi küla",
      NIMETUS_LIIGIGA: "Lassi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004129",
    },
    {
      TASE: "3",
      KOOD: "4130",
      NIMETUS: "Lasva küla",
      NIMETUS_LIIGIGA: "Lasva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004130",
    },
    {
      TASE: "3",
      KOOD: "4133",
      NIMETUS: "Lau küla",
      NIMETUS_LIIGIGA: "Lau küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00004133",
    },
    {
      TASE: "3",
      KOOD: "4136",
      NIMETUS: "Lauga küla",
      NIMETUS_LIIGIGA: "Lauga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004136",
    },
    {
      TASE: "3",
      KOOD: "4138",
      NIMETUS: "Laugu küla",
      NIMETUS_LIIGIGA: "Laugu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004138",
    },
    {
      TASE: "3",
      KOOD: "4141",
      NIMETUS: "Lauka küla",
      NIMETUS_LIIGIGA: "Lauka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004141",
    },
    {
      TASE: "3",
      KOOD: "4143",
      NIMETUS: "Laukna küla",
      NIMETUS_LIIGIGA: "Laukna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00004143",
    },
    {
      TASE: "3",
      KOOD: "4146",
      NIMETUS: "Lauküla",
      NIMETUS_LIIGIGA: "Lauküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00004146",
    },
    {
      TASE: "3",
      KOOD: "4148",
      NIMETUS: "Laulasmaa küla",
      NIMETUS_LIIGIGA: "Laulasmaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00004148",
    },
    {
      TASE: "3",
      KOOD: "4151",
      NIMETUS: "Lauli küla",
      NIMETUS_LIIGIGA: "Lauli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00004151",
    },
    {
      TASE: "3",
      KOOD: "4153",
      NIMETUS: "Laupa küla",
      NIMETUS_LIIGIGA: "Laupa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00004153",
    },
    {
      TASE: "3",
      KOOD: "4156",
      NIMETUS: "Lauri küla",
      NIMETUS_LIIGIGA: "Lauri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00004156",
    },
    {
      TASE: "3",
      KOOD: "4158",
      NIMETUS: "Lauri küla",
      NIMETUS_LIIGIGA: "Lauri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004158",
    },
    {
      TASE: "3",
      KOOD: "4160",
      NIMETUS: "Laurimäe küla",
      NIMETUS_LIIGIGA: "Laurimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004160",
    },
    {
      TASE: "3",
      KOOD: "4163",
      NIMETUS: "Lautna küla",
      NIMETUS_LIIGIGA: "Lautna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00004163",
    },
    {
      TASE: "3",
      KOOD: "4165",
      NIMETUS: "Lavassaare alev",
      NIMETUS_LIIGIGA: "Lavassaare alev",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00004165",
    },
    {
      TASE: "3",
      KOOD: "4166",
      NIMETUS: "Lavi küla",
      NIMETUS_LIIGIGA: "Lavi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00004166",
    },
    {
      TASE: "3",
      KOOD: "4170",
      NIMETUS: "Lebavere küla",
      NIMETUS_LIIGIGA: "Lebavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00004170",
    },
    {
      TASE: "3",
      KOOD: "4173",
      NIMETUS: "Leebiku küla",
      NIMETUS_LIIGIGA: "Leebiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00004173",
    },
    {
      TASE: "3",
      KOOD: "4175",
      NIMETUS: "Leedi küla",
      NIMETUS_LIIGIGA: "Leedi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00004175",
    },
    {
      TASE: "3",
      KOOD: "4178",
      NIMETUS: "Leediküla",
      NIMETUS_LIIGIGA: "Leediküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00004178",
    },
    {
      TASE: "3",
      KOOD: "4180",
      NIMETUS: "Leedri küla",
      NIMETUS_LIIGIGA: "Leedri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004180",
    },
    {
      TASE: "3",
      KOOD: "4183",
      NIMETUS: "Leeli küla",
      NIMETUS_LIIGIGA: "Leeli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00004183",
    },
    {
      TASE: "3",
      KOOD: "4184",
      NIMETUS: "Leerimetsa küla",
      NIMETUS_LIIGIGA: "Leerimetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004184",
    },
    {
      TASE: "3",
      KOOD: "4185",
      NIMETUS: "Leemeti küla",
      NIMETUS_LIIGIGA: "Leemeti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004185",
    },
    {
      TASE: "3",
      KOOD: "4188",
      NIMETUS: "Leesi küla",
      NIMETUS_LIIGIGA: "Leesi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00004188",
    },
    {
      TASE: "3",
      KOOD: "4189",
      NIMETUS: "Leeskopa küla",
      NIMETUS_LIIGIGA: "Leeskopa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00004189",
    },
    {
      TASE: "3",
      KOOD: "4190",
      NIMETUS: "Leetva küla",
      NIMETUS_LIIGIGA: "Leetva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00004190",
    },
    {
      TASE: "3",
      KOOD: "4193",
      NIMETUS: "Leevaku küla",
      NIMETUS_LIIGIGA: "Leevaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00004193",
    },
    {
      TASE: "3",
      KOOD: "4195",
      NIMETUS: "Leevi küla",
      NIMETUS_LIIGIGA: "Leevi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00004195",
    },
    {
      TASE: "3",
      KOOD: "4198",
      NIMETUS: "Leevijõe küla",
      NIMETUS_LIIGIGA: "Leevijõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00004198",
    },
    {
      TASE: "3",
      KOOD: "4200",
      NIMETUS: "Leevre küla",
      NIMETUS_LIIGIGA: "Leevre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00004200",
    },
    {
      TASE: "3",
      KOOD: "4203",
      NIMETUS: "Lehemetsa küla",
      NIMETUS_LIIGIGA: "Lehemetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004203",
    },
    {
      TASE: "3",
      KOOD: "4208",
      NIMETUS: "Lehmja küla",
      NIMETUS_LIIGIGA: "Lehmja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00004208",
    },
    {
      TASE: "3",
      KOOD: "4209",
      NIMETUS: "Lehtma küla",
      NIMETUS_LIIGIGA: "Lehtma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004209",
    },
    {
      TASE: "3",
      KOOD: "4211",
      NIMETUS: "Lehola küla",
      NIMETUS_LIIGIGA: "Lehola küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00004211",
    },
    {
      TASE: "3",
      KOOD: "4213",
      NIMETUS: "Lehtmetsa küla",
      NIMETUS_LIIGIGA: "Lehtmetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00004213",
    },
    {
      TASE: "3",
      KOOD: "4214",
      NIMETUS: "Lehtmetsa küla",
      NIMETUS_LIIGIGA: "Lehtmetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00004214",
    },
    {
      TASE: "3",
      KOOD: "4215",
      NIMETUS: "Lehtmetsa küla",
      NIMETUS_LIIGIGA: "Lehtmetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00004215",
    },
    {
      TASE: "3",
      KOOD: "4216",
      NIMETUS: "Lehtmetsa küla",
      NIMETUS_LIIGIGA: "Lehtmetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00004216",
    },
    {
      TASE: "3",
      KOOD: "4217",
      NIMETUS: "Lehtse alevik",
      NIMETUS_LIIGIGA: "Lehtse alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00004217",
    },
    {
      TASE: "3",
      KOOD: "4219",
      NIMETUS: "Lehu küla",
      NIMETUS_LIIGIGA: "Lehu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00004219",
    },
    {
      TASE: "3",
      KOOD: "4223",
      NIMETUS: "Leigri küla",
      NIMETUS_LIIGIGA: "Leigri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004223",
    },
    {
      TASE: "3",
      KOOD: "4225",
      NIMETUS: "Leie küla",
      NIMETUS_LIIGIGA: "Leie küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004225",
    },
    {
      TASE: "3",
      KOOD: "4228",
      NIMETUS: "Leimani küla",
      NIMETUS_LIIGIGA: "Leimani küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004228",
    },
    {
      TASE: "3",
      KOOD: "4230",
      NIMETUS: "Leila küla",
      NIMETUS_LIIGIGA: "Leila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00004230",
    },
    {
      TASE: "3",
      KOOD: "4232",
      NIMETUS: "Leina küla",
      NIMETUS_LIIGIGA: "Leina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00004232",
    },
    {
      TASE: "3",
      KOOD: "4233",
      NIMETUS: "Leina küla",
      NIMETUS_LIIGIGA: "Leina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004233",
    },
    {
      TASE: "3",
      KOOD: "4235",
      NIMETUS: "Leipste küla",
      NIMETUS_LIIGIGA: "Leipste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00004235",
    },
    {
      TASE: "3",
      KOOD: "4237",
      NIMETUS: "Leisi alevik",
      NIMETUS_LIIGIGA: "Leisi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004237",
    },
    {
      TASE: "3",
      KOOD: "4238",
      NIMETUS: "Leisi küla",
      NIMETUS_LIIGIGA: "Leisi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004238",
    },
    {
      TASE: "3",
      KOOD: "4240",
      NIMETUS: "Leiso küla",
      NIMETUS_LIIGIGA: "Leiso küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004240",
    },
    {
      TASE: "3",
      KOOD: "4242",
      NIMETUS: "Leistu küla",
      NIMETUS_LIIGIGA: "Leistu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00004242",
    },
    {
      TASE: "3",
      KOOD: "4245",
      NIMETUS: "Leisu küla",
      NIMETUS_LIIGIGA: "Leisu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004245",
    },
    {
      TASE: "3",
      KOOD: "4248",
      NIMETUS: "Lellapere küla",
      NIMETUS_LIIGIGA: "Lellapere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00004248",
    },
    {
      TASE: "3",
      KOOD: "4250",
      NIMETUS: "Lelle alevik",
      NIMETUS_LIIGIGA: "Lelle alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00004250",
    },
    {
      TASE: "3",
      KOOD: "4253",
      NIMETUS: "Lelu küla",
      NIMETUS_LIIGIGA: "Lelu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004253",
    },
    {
      TASE: "3",
      KOOD: "4256",
      NIMETUS: "Lembevere küla",
      NIMETUS_LIIGIGA: "Lembevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00004256",
    },
    {
      TASE: "3",
      KOOD: "4258",
      NIMETUS: "Lemmaku küla",
      NIMETUS_LIIGIGA: "Lemmaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00004258",
    },
    {
      TASE: "3",
      KOOD: "4261",
      NIMETUS: "Lemmakõnnu küla",
      NIMETUS_LIIGIGA: "Lemmakõnnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00004261",
    },
    {
      TASE: "3",
      KOOD: "4263",
      NIMETUS: "Lemmaru küla",
      NIMETUS_LIIGIGA: "Lemmaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00004263",
    },
    {
      TASE: "3",
      KOOD: "4266",
      NIMETUS: "Lemmatsi küla",
      NIMETUS_LIIGIGA: "Lemmatsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00004266",
    },
    {
      TASE: "3",
      KOOD: "4268",
      NIMETUS: "Lemmetsa küla",
      NIMETUS_LIIGIGA: "Lemmetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00004268",
    },
    {
      TASE: "3",
      KOOD: "4271",
      NIMETUS: "Lemmikküla",
      NIMETUS_LIIGIGA: "Lemmikküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00004271",
    },
    {
      TASE: "3",
      KOOD: "4273",
      NIMETUS: "Lemmküla",
      NIMETUS_LIIGIGA: "Lemmküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00004273",
    },
    {
      TASE: "3",
      KOOD: "4276",
      NIMETUS: "Lemsi küla",
      NIMETUS_LIIGIGA: "Lemsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "303",
      ADS_OID: "AY00004276",
    },
    {
      TASE: "3",
      KOOD: "4277",
      NIMETUS: "Leoski küla",
      NIMETUS_LIIGIGA: "Leoski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004277",
    },
    {
      TASE: "3",
      KOOD: "4278",
      NIMETUS: "Lemuvere küla",
      NIMETUS_LIIGIGA: "Lemuvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00004278",
    },
    {
      TASE: "3",
      KOOD: "4281",
      NIMETUS: "Lepa küla",
      NIMETUS_LIIGIGA: "Lepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00004281",
    },
    {
      TASE: "3",
      KOOD: "4284",
      NIMETUS: "Lepaküla",
      NIMETUS_LIIGIGA: "Lepaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00004284",
    },
    {
      TASE: "3",
      KOOD: "4286",
      NIMETUS: "Lepassaare küla",
      NIMETUS_LIIGIGA: "Lepassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004286",
    },
    {
      TASE: "3",
      KOOD: "4287",
      NIMETUS: "Lepiksaare küla",
      NIMETUS_LIIGIGA: "Lepiksaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00004287",
    },
    {
      TASE: "3",
      KOOD: "4289",
      NIMETUS: "Lepaste küla",
      NIMETUS_LIIGIGA: "Lepaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00004289",
    },
    {
      TASE: "3",
      KOOD: "4290",
      NIMETUS: "Lepiku küla",
      NIMETUS_LIIGIGA: "Lepiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004290",
    },
    {
      TASE: "3",
      KOOD: "4291",
      NIMETUS: "Lepiku küla",
      NIMETUS_LIIGIGA: "Lepiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00004291",
    },
    {
      TASE: "3",
      KOOD: "4292",
      NIMETUS: "Lepiku küla",
      NIMETUS_LIIGIGA: "Lepiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00004292",
    },
    {
      TASE: "3",
      KOOD: "4293",
      NIMETUS: "Lepiku küla",
      NIMETUS_LIIGIGA: "Lepiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00004293",
    },
    {
      TASE: "3",
      KOOD: "4294",
      NIMETUS: "Lepna alevik",
      NIMETUS_LIIGIGA: "Lepna alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00004294",
    },
    {
      TASE: "3",
      KOOD: "4296",
      NIMETUS: "Lepplaane küla",
      NIMETUS_LIIGIGA: "Lepplaane küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00004296",
    },
    {
      TASE: "3",
      KOOD: "4297",
      NIMETUS: "Lepä küla",
      NIMETUS_LIIGIGA: "Lepä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004297",
    },
    {
      TASE: "3",
      KOOD: "4299",
      NIMETUS: "Leppneeme küla",
      NIMETUS_LIIGIGA: "Leppneeme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00004299",
    },
    {
      TASE: "3",
      KOOD: "4302",
      NIMETUS: "Lestima küla",
      NIMETUS_LIIGIGA: "Lestima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00004302",
    },
    {
      TASE: "3",
      KOOD: "4305",
      NIMETUS: "Letipea küla",
      NIMETUS_LIIGIGA: "Letipea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00004305",
    },
    {
      TASE: "3",
      KOOD: "4308",
      NIMETUS: "Levala küla",
      NIMETUS_LIIGIGA: "Levala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00004308",
    },
    {
      TASE: "3",
      KOOD: "4309",
      NIMETUS: "Levala küla",
      NIMETUS_LIIGIGA: "Levala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00004309",
    },
    {
      TASE: "3",
      KOOD: "4310",
      NIMETUS: "Levala küla",
      NIMETUS_LIIGIGA: "Levala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004310",
    },
    {
      TASE: "3",
      KOOD: "4311",
      NIMETUS: "Levalõpme küla",
      NIMETUS_LIIGIGA: "Levalõpme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00004311",
    },
    {
      TASE: "3",
      KOOD: "4314",
      NIMETUS: "Levi küla",
      NIMETUS_LIIGIGA: "Levi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00004314",
    },
    {
      TASE: "3",
      KOOD: "4318",
      NIMETUS: "Väljataguse küla",
      NIMETUS_LIIGIGA: "Väljataguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00004318",
    },
    {
      TASE: "3",
      KOOD: "4319",
      NIMETUS: "Ligema küla",
      NIMETUS_LIIGIGA: "Ligema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004319",
    },
    {
      TASE: "3",
      KOOD: "4320",
      NIMETUS: "Libatse küla",
      NIMETUS_LIIGIGA: "Libatse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00004320",
    },
    {
      TASE: "3",
      KOOD: "4326",
      NIMETUS: "Lihtensteini küla",
      NIMETUS_LIIGIGA: "Lihtensteini küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00004326",
    },
    {
      TASE: "3",
      KOOD: "4330",
      NIMETUS: "Lihula linn",
      NIMETUS_LIIGIGA: "Lihula linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00004330",
    },
    {
      TASE: "3",
      KOOD: "4331",
      NIMETUS: "Lihulõpe küla",
      NIMETUS_LIIGIGA: "Lihulõpe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00004331",
    },
    {
      TASE: "3",
      KOOD: "4334",
      NIMETUS: "Liiapeksi küla",
      NIMETUS_LIIGIGA: "Liiapeksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00004334",
    },
    {
      TASE: "3",
      KOOD: "4335",
      NIMETUS: "Liigalaskma küla",
      NIMETUS_LIIGIGA: "Liigalaskma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004335",
    },
    {
      TASE: "3",
      KOOD: "4337",
      NIMETUS: "Liiguste küla",
      NIMETUS_LIIGIGA: "Liiguste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00004337",
    },
    {
      TASE: "3",
      KOOD: "4339",
      NIMETUS: "Liigvalla küla",
      NIMETUS_LIIGIGA: "Liigvalla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00004339",
    },
    {
      TASE: "3",
      KOOD: "4342",
      NIMETUS: "Liikatku küla",
      NIMETUS_LIIGIGA: "Liikatku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00004342",
    },
    {
      TASE: "3",
      KOOD: "4344",
      NIMETUS: "Liikva küla",
      NIMETUS_LIIGIGA: "Liikva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00004344",
    },
    {
      TASE: "3",
      KOOD: "4345",
      NIMETUS: "Liiküla",
      NIMETUS_LIIGIGA: "Liiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004345",
    },
    {
      TASE: "3",
      KOOD: "4347",
      NIMETUS: "Liimala küla",
      NIMETUS_LIIGIGA: "Liimala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00004347",
    },
    {
      TASE: "3",
      KOOD: "4348",
      NIMETUS: "Liinamäe küla",
      NIMETUS_LIIGIGA: "Liinamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004348",
    },
    {
      TASE: "3",
      KOOD: "4349",
      NIMETUS: "Liispõllu küla",
      NIMETUS_LIIGIGA: "Liispõllu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00004349",
    },
    {
      TASE: "3",
      KOOD: "4350",
      NIMETUS: "Liivaküla",
      NIMETUS_LIIGIGA: "Liivaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00004350",
    },
    {
      TASE: "3",
      KOOD: "4351",
      NIMETUS: "Liivaküla",
      NIMETUS_LIIGIGA: "Liivaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00004351",
    },
    {
      TASE: "3",
      KOOD: "4352",
      NIMETUS: "Liiva küla",
      NIMETUS_LIIGIGA: "Liiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00004352",
    },
    {
      TASE: "3",
      KOOD: "4353",
      NIMETUS: "Liiva küla",
      NIMETUS_LIIGIGA: "Liiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00004353",
    },
    {
      TASE: "3",
      KOOD: "4354",
      NIMETUS: "Liiva küla",
      NIMETUS_LIIGIGA: "Liiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00004354",
    },
    {
      TASE: "3",
      KOOD: "4356",
      NIMETUS: "Liivaküla",
      NIMETUS_LIIGIGA: "Liivaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00004356",
    },
    {
      TASE: "3",
      KOOD: "4357",
      NIMETUS: "Liiva küla",
      NIMETUS_LIIGIGA: "Liiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004357",
    },
    {
      TASE: "3",
      KOOD: "4358",
      NIMETUS: "Liivakupalu küla",
      NIMETUS_LIIGIGA: "Liivakupalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004358",
    },
    {
      TASE: "3",
      KOOD: "4359",
      NIMETUS: "Liivamäe küla",
      NIMETUS_LIIGIGA: "Liivamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00004359",
    },
    {
      TASE: "3",
      KOOD: "4361",
      NIMETUS: "Liivi küla",
      NIMETUS_LIIGIGA: "Liivi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00004361",
    },
    {
      TASE: "3",
      KOOD: "4363",
      NIMETUS: "Liiva küla",
      NIMETUS_LIIGIGA: "Liiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004363",
    },
    {
      TASE: "3",
      KOOD: "4364",
      NIMETUS: "Liivoja küla",
      NIMETUS_LIIGIGA: "Liivoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00004364",
    },
    {
      TASE: "3",
      KOOD: "4366",
      NIMETUS: "Liiva-Putla küla",
      NIMETUS_LIIGIGA: "Liiva-Putla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004366",
    },
    {
      TASE: "3",
      KOOD: "4367",
      NIMETUS: "Lilastvere küla",
      NIMETUS_LIIGIGA: "Lilastvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00004367",
    },
    {
      TASE: "3",
      KOOD: "4368",
      NIMETUS: "Lilbi küla",
      NIMETUS_LIIGIGA: "Lilbi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004368",
    },
    {
      TASE: "3",
      KOOD: "4369",
      NIMETUS: "Lilli küla",
      NIMETUS_LIIGIGA: "Lilli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00004369",
    },
    {
      TASE: "3",
      KOOD: "4370",
      NIMETUS: "Lilli küla",
      NIMETUS_LIIGIGA: "Lilli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00004370",
    },
    {
      TASE: "3",
      KOOD: "4371",
      NIMETUS: "Lilbi küla",
      NIMETUS_LIIGIGA: "Lilbi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004371",
    },
    {
      TASE: "3",
      KOOD: "4372",
      NIMETUS: "Lillimõisa küla",
      NIMETUS_LIIGIGA: "Lillimõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004372",
    },
    {
      TASE: "3",
      KOOD: "4373",
      NIMETUS: "Lilli-Anne küla",
      NIMETUS_LIIGIGA: "Lilli-Anne küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004373",
    },
    {
      TASE: "3",
      KOOD: "4374",
      NIMETUS: "Liivakünka küla",
      NIMETUS_LIIGIGA: "Liivakünka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00004374",
    },
    {
      TASE: "3",
      KOOD: "4375",
      NIMETUS: "Lilu küla",
      NIMETUS_LIIGIGA: "Lilu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00004375",
    },
    {
      TASE: "3",
      KOOD: "4378",
      NIMETUS: "Limu küla",
      NIMETUS_LIIGIGA: "Limu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00004378",
    },
    {
      TASE: "3",
      KOOD: "4379",
      NIMETUS: "Linaleo küla",
      NIMETUS_LIIGIGA: "Linaleo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00004379",
    },
    {
      TASE: "3",
      KOOD: "4381",
      NIMETUS: "Linaküla",
      NIMETUS_LIIGIGA: "Linaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "303",
      ADS_OID: "AY00004381",
    },
    {
      TASE: "3",
      KOOD: "4384",
      NIMETUS: "Lindi küla",
      NIMETUS_LIIGIGA: "Lindi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00004384",
    },
    {
      TASE: "3",
      KOOD: "4385",
      NIMETUS: "Lindmetsa küla",
      NIMETUS_LIIGIGA: "Lindmetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004385",
    },
    {
      TASE: "3",
      KOOD: "4386",
      NIMETUS: "Lindora küla",
      NIMETUS_LIIGIGA: "Lindora küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004386",
    },
    {
      TASE: "3",
      KOOD: "4387",
      NIMETUS: "Lindsi küla",
      NIMETUS_LIIGIGA: "Lindsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004387",
    },
    {
      TASE: "3",
      KOOD: "4389",
      NIMETUS: "Linna küla",
      NIMETUS_LIIGIGA: "Linna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "251",
      ADS_OID: "AY00004389",
    },
    {
      TASE: "3",
      KOOD: "4390",
      NIMETUS: "Linna küla",
      NIMETUS_LIIGIGA: "Linna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00004390",
    },
    {
      TASE: "3",
      KOOD: "4392",
      NIMETUS: "Linnaaluste küla",
      NIMETUS_LIIGIGA: "Linnaaluste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00004392",
    },
    {
      TASE: "3",
      KOOD: "4395",
      NIMETUS: "Linnaka küla",
      NIMETUS_LIIGIGA: "Linnaka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004395",
    },
    {
      TASE: "3",
      KOOD: "4397",
      NIMETUS: "Linnakse küla",
      NIMETUS_LIIGIGA: "Linnakse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00004397",
    },
    {
      TASE: "3",
      KOOD: "4400",
      NIMETUS: "Linnamäe küla",
      NIMETUS_LIIGIGA: "Linnamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00004400",
    },
    {
      TASE: "3",
      KOOD: "4401",
      NIMETUS: "Linnamäe küla",
      NIMETUS_LIIGIGA: "Linnamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004401",
    },
    {
      TASE: "3",
      KOOD: "4402",
      NIMETUS: "Linnumäe küla",
      NIMETUS_LIIGIGA: "Linnumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004402",
    },
    {
      TASE: "3",
      KOOD: "4403",
      NIMETUS: "Linnape küla",
      NIMETUS_LIIGIGA: "Linnape küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00004403",
    },
    {
      TASE: "3",
      KOOD: "4406",
      NIMETUS: "Linnuse küla",
      NIMETUS_LIIGIGA: "Linnuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00004406",
    },
    {
      TASE: "3",
      KOOD: "4407",
      NIMETUS: "Linnuse küla",
      NIMETUS_LIIGIGA: "Linnuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00004407",
    },
    {
      TASE: "3",
      KOOD: "4408",
      NIMETUS: "Linnuse küla",
      NIMETUS_LIIGIGA: "Linnuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00004408",
    },
    {
      TASE: "3",
      KOOD: "4409",
      NIMETUS: "Linnuse küla",
      NIMETUS_LIIGIGA: "Linnuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004409",
    },
    {
      TASE: "3",
      KOOD: "4410",
      NIMETUS: "Linte küla",
      NIMETUS_LIIGIGA: "Linte küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00004410",
    },
    {
      TASE: "3",
      KOOD: "4413",
      NIMETUS: "Lipa küla",
      NIMETUS_LIIGIGA: "Lipa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00004413",
    },
    {
      TASE: "3",
      KOOD: "4416",
      NIMETUS: "Lipametsa küla",
      NIMETUS_LIIGIGA: "Lipametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00004416",
    },
    {
      TASE: "3",
      KOOD: "4418",
      NIMETUS: "Lipniku küla",
      NIMETUS_LIIGIGA: "Lipniku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00004418",
    },
    {
      TASE: "3",
      KOOD: "4419",
      NIMETUS: "Lipu küla",
      NIMETUS_LIIGIGA: "Lipu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00004419",
    },
    {
      TASE: "3",
      KOOD: "4421",
      NIMETUS: "Lipstu küla",
      NIMETUS_LIIGIGA: "Lipstu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00004421",
    },
    {
      TASE: "3",
      KOOD: "4422",
      NIMETUS: "Listaku küla",
      NIMETUS_LIIGIGA: "Listaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004422",
    },
    {
      TASE: "3",
      KOOD: "4424",
      NIMETUS: "Listaku küla",
      NIMETUS_LIIGIGA: "Listaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004424",
    },
    {
      TASE: "3",
      KOOD: "4425",
      NIMETUS: "Litvina küla",
      NIMETUS_LIIGIGA: "Litvina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004425",
    },
    {
      TASE: "3",
      KOOD: "4426",
      NIMETUS: "Litu küla",
      NIMETUS_LIIGIGA: "Litu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00004426",
    },
    {
      TASE: "3",
      KOOD: "4427",
      NIMETUS: "Litsmetsa küla",
      NIMETUS_LIIGIGA: "Litsmetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00004427",
    },
    {
      TASE: "3",
      KOOD: "4428",
      NIMETUS: "Liusvere küla",
      NIMETUS_LIIGIGA: "Liusvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00004428",
    },
    {
      TASE: "3",
      KOOD: "4430",
      NIMETUS: "Liu küla",
      NIMETUS_LIIGIGA: "Liu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00004430",
    },
    {
      TASE: "3",
      KOOD: "4433",
      NIMETUS: "Liva küla",
      NIMETUS_LIIGIGA: "Liva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00004433",
    },
    {
      TASE: "3",
      KOOD: "4434",
      NIMETUS: "Lobotka küla",
      NIMETUS_LIIGIGA: "Lobotka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004434",
    },
    {
      TASE: "3",
      KOOD: "4437",
      NIMETUS: "Lobi küla",
      NIMETUS_LIIGIGA: "Lobi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00004437",
    },
    {
      TASE: "3",
      KOOD: "4440",
      NIMETUS: "Lodja küla",
      NIMETUS_LIIGIGA: "Lodja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00004440",
    },
    {
      TASE: "3",
      KOOD: "4443",
      NIMETUS: "Loe küla",
      NIMETUS_LIIGIGA: "Loe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00004443",
    },
    {
      TASE: "3",
      KOOD: "4446",
      NIMETUS: "Logina küla",
      NIMETUS_LIIGIGA: "Logina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00004446",
    },
    {
      TASE: "3",
      KOOD: "4449",
      NIMETUS: "Lohkuse küla",
      NIMETUS_LIIGIGA: "Lohkuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00004449",
    },
    {
      TASE: "3",
      KOOD: "4451",
      NIMETUS: "Lohkva küla",
      NIMETUS_LIIGIGA: "Lohkva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00004451",
    },
    {
      TASE: "3",
      KOOD: "4456",
      NIMETUS: "Lohusalu küla",
      NIMETUS_LIIGIGA: "Lohusalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00004456",
    },
    {
      TASE: "3",
      KOOD: "4459",
      NIMETUS: "Lohusuu alevik",
      NIMETUS_LIIGIGA: "Lohusuu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00004459",
    },
    {
      TASE: "3",
      KOOD: "4462",
      NIMETUS: "Loime küla",
      NIMETUS_LIIGIGA: "Loime küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004462",
    },
    {
      TASE: "3",
      KOOD: "4465",
      NIMETUS: "Loja küla",
      NIMETUS_LIIGIGA: "Loja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004465",
    },
    {
      TASE: "3",
      KOOD: "4468",
      NIMETUS: "Loko küla",
      NIMETUS_LIIGIGA: "Loko küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00004468",
    },
    {
      TASE: "3",
      KOOD: "4470",
      NIMETUS: "Loksa küla",
      NIMETUS_LIIGIGA: "Loksa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00004470",
    },
    {
      TASE: "3",
      KOOD: "4471",
      NIMETUS: "Loksa küla",
      NIMETUS_LIIGIGA: "Loksa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00004471",
    },
    {
      TASE: "3",
      KOOD: "4473",
      NIMETUS: "Loksu küla",
      NIMETUS_LIIGIGA: "Loksu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00004473",
    },
    {
      TASE: "3",
      KOOD: "4474",
      NIMETUS: "Lokuta küla",
      NIMETUS_LIIGIGA: "Lokuta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00004474",
    },
    {
      TASE: "3",
      KOOD: "4476",
      NIMETUS: "Lokuta küla",
      NIMETUS_LIIGIGA: "Lokuta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00004476",
    },
    {
      TASE: "3",
      KOOD: "4479",
      NIMETUS: "Lokuta küla",
      NIMETUS_LIIGIGA: "Lokuta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00004479",
    },
    {
      TASE: "3",
      KOOD: "4481",
      NIMETUS: "Lokuti küla",
      NIMETUS_LIIGIGA: "Lokuti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00004481",
    },
    {
      TASE: "3",
      KOOD: "4484",
      NIMETUS: "Lolu küla",
      NIMETUS_LIIGIGA: "Lolu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004484",
    },
    {
      TASE: "3",
      KOOD: "4487",
      NIMETUS: "Lombi küla",
      NIMETUS_LIIGIGA: "Lombi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00004487",
    },
    {
      TASE: "3",
      KOOD: "4490",
      NIMETUS: "Lompka küla",
      NIMETUS_LIIGIGA: "Lompka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004490",
    },
    {
      TASE: "3",
      KOOD: "4493",
      NIMETUS: "Londi küla",
      NIMETUS_LIIGIGA: "Londi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00004493",
    },
    {
      TASE: "3",
      KOOD: "4494",
      NIMETUS: "Loo küla",
      NIMETUS_LIIGIGA: "Loo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00004494",
    },
    {
      TASE: "3",
      KOOD: "4496",
      NIMETUS: "Loo alevik",
      NIMETUS_LIIGIGA: "Loo alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00004496",
    },
    {
      TASE: "3",
      KOOD: "4498",
      NIMETUS: "Loobu küla",
      NIMETUS_LIIGIGA: "Loobu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00004498",
    },
    {
      TASE: "3",
      KOOD: "4501",
      NIMETUS: "Loodi küla",
      NIMETUS_LIIGIGA: "Loodi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004501",
    },
    {
      TASE: "3",
      KOOD: "4502",
      NIMETUS: "Loogamäe küla",
      NIMETUS_LIIGIGA: "Loogamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00004502",
    },
    {
      TASE: "3",
      KOOD: "4503",
      NIMETUS: "Loodna küla",
      NIMETUS_LIIGIGA: "Loodna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00004503",
    },
    {
      TASE: "3",
      KOOD: "4504",
      NIMETUS: "Loomse küla",
      NIMETUS_LIIGIGA: "Loomse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00004504",
    },
    {
      TASE: "3",
      KOOD: "4506",
      NIMETUS: "Looküla",
      NIMETUS_LIIGIGA: "Looküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00004506",
    },
    {
      TASE: "3",
      KOOD: "4509",
      NIMETUS: "Loona küla",
      NIMETUS_LIIGIGA: "Loona küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00004509",
    },
    {
      TASE: "3",
      KOOD: "4511",
      NIMETUS: "Loone küla",
      NIMETUS_LIIGIGA: "Loone küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00004511",
    },
    {
      TASE: "3",
      KOOD: "4513",
      NIMETUS: "Loopre küla",
      NIMETUS_LIIGIGA: "Loopre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00004513",
    },
    {
      TASE: "3",
      KOOD: "4514",
      NIMETUS: "Loopre küla",
      NIMETUS_LIIGIGA: "Loopre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00004514",
    },
    {
      TASE: "3",
      KOOD: "4517",
      NIMETUS: "Loosi küla",
      NIMETUS_LIIGIGA: "Loosi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004517",
    },
    {
      TASE: "3",
      KOOD: "4519",
      NIMETUS: "Loosu küla",
      NIMETUS_LIIGIGA: "Loosu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004519",
    },
    {
      TASE: "3",
      KOOD: "4522",
      NIMETUS: "Lootvina küla",
      NIMETUS_LIIGIGA: "Lootvina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00004522",
    },
    {
      TASE: "3",
      KOOD: "4525",
      NIMETUS: "Lossiküla",
      NIMETUS_LIIGIGA: "Lossiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00004525",
    },
    {
      TASE: "3",
      KOOD: "4527",
      NIMETUS: "Lossimäe küla",
      NIMETUS_LIIGIGA: "Lossimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00004527",
    },
    {
      TASE: "3",
      KOOD: "4530",
      NIMETUS: "Lota küla",
      NIMETUS_LIIGIGA: "Lota küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00004530",
    },
    {
      TASE: "3",
      KOOD: "4534",
      NIMETUS: "Lubja küla",
      NIMETUS_LIIGIGA: "Lubja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00004534",
    },
    {
      TASE: "3",
      KOOD: "4537",
      NIMETUS: "Luguse küla",
      NIMETUS_LIIGIGA: "Luguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004537",
    },
    {
      TASE: "3",
      KOOD: "4540",
      NIMETUS: "Lütä küla",
      NIMETUS_LIIGIGA: "Lütä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00004540",
    },
    {
      TASE: "3",
      KOOD: "4544",
      NIMETUS: "Luhte küla",
      NIMETUS_LIIGIGA: "Luhte küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00004544",
    },
    {
      TASE: "3",
      KOOD: "4546",
      NIMETUS: "Luidja küla",
      NIMETUS_LIIGIGA: "Luidja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00004546",
    },
    {
      TASE: "3",
      KOOD: "4548",
      NIMETUS: "Luiga küla",
      NIMETUS_LIIGIGA: "Luiga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00004548",
    },
    {
      TASE: "3",
      KOOD: "4551",
      NIMETUS: "Luige küla",
      NIMETUS_LIIGIGA: "Luige küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00004551",
    },
    {
      TASE: "3",
      KOOD: "4553",
      NIMETUS: "Luigu küla",
      NIMETUS_LIIGIGA: "Luigu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00004553",
    },
    {
      TASE: "3",
      KOOD: "4554",
      NIMETUS: "Luiste küla",
      NIMETUS_LIIGIGA: "Luiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00004554",
    },
    {
      TASE: "3",
      KOOD: "4557",
      NIMETUS: "Luke küla",
      NIMETUS_LIIGIGA: "Luke küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00004557",
    },
    {
      TASE: "3",
      KOOD: "4560",
      NIMETUS: "Lungu küla",
      NIMETUS_LIIGIGA: "Lungu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00004560",
    },
    {
      TASE: "3",
      KOOD: "4563",
      NIMETUS: "Lusti küla",
      NIMETUS_LIIGIGA: "Lusti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00004563",
    },
    {
      TASE: "3",
      KOOD: "4564",
      NIMETUS: "Lusti küla",
      NIMETUS_LIIGIGA: "Lusti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00004564",
    },
    {
      TASE: "3",
      KOOD: "4567",
      NIMETUS: "Lustivere küla",
      NIMETUS_LIIGIGA: "Lustivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00004567",
    },
    {
      TASE: "3",
      KOOD: "6342",
      NIMETUS: "Praaga küla",
      NIMETUS_LIIGIGA: "Praaga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006342",
    },
    {
      TASE: "3",
      KOOD: "6343",
      NIMETUS: "Praakli küla",
      NIMETUS_LIIGIGA: "Praakli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006343",
    },
    {
      TASE: "3",
      KOOD: "6344",
      NIMETUS: "Praaklima küla",
      NIMETUS_LIIGIGA: "Praaklima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00006344",
    },
    {
      TASE: "3",
      KOOD: "6345",
      NIMETUS: "Praakmani küla",
      NIMETUS_LIIGIGA: "Praakmani küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006345",
    },
    {
      TASE: "3",
      KOOD: "6347",
      NIMETUS: "Pragi küla",
      NIMETUS_LIIGIGA: "Pragi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00006347",
    },
    {
      TASE: "3",
      KOOD: "6349",
      NIMETUS: "Prandi küla",
      NIMETUS_LIIGIGA: "Prandi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00006349",
    },
    {
      TASE: "3",
      KOOD: "6352",
      NIMETUS: "Prange küla",
      NIMETUS_LIIGIGA: "Prange küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00006352",
    },
    {
      TASE: "3",
      KOOD: "6354",
      NIMETUS: "Prangli küla",
      NIMETUS_LIIGIGA: "Prangli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00006354",
    },
    {
      TASE: "3",
      KOOD: "6357",
      NIMETUS: "Prassi küla",
      NIMETUS_LIIGIGA: "Prassi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006357",
    },
    {
      TASE: "3",
      KOOD: "6358",
      NIMETUS: "Preeksa küla",
      NIMETUS_LIIGIGA: "Preeksa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006358",
    },
    {
      TASE: "3",
      KOOD: "6360",
      NIMETUS: "Preedi küla",
      NIMETUS_LIIGIGA: "Preedi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00006360",
    },
    {
      TASE: "3",
      KOOD: "6362",
      NIMETUS: "Pressi küla",
      NIMETUS_LIIGIGA: "Pressi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006362",
    },
    {
      TASE: "3",
      KOOD: "6365",
      NIMETUS: "Priipalu küla",
      NIMETUS_LIIGIGA: "Priipalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00006365",
    },
    {
      TASE: "3",
      KOOD: "6368",
      NIMETUS: "Prillimäe alevik",
      NIMETUS_LIIGIGA: "Prillimäe alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00006368",
    },
    {
      TASE: "3",
      KOOD: "6370",
      NIMETUS: "Pringi küla",
      NIMETUS_LIIGIGA: "Pringi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00006370",
    },
    {
      TASE: "3",
      KOOD: "6371",
      NIMETUS: "Pringi küla",
      NIMETUS_LIIGIGA: "Pringi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00006371",
    },
    {
      TASE: "3",
      KOOD: "6372",
      NIMETUS: "Prähnu küla",
      NIMETUS_LIIGIGA: "Prähnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006372",
    },
    {
      TASE: "3",
      KOOD: "6373",
      NIMETUS: "Prählamäe küla",
      NIMETUS_LIIGIGA: "Prählamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006373",
    },
    {
      TASE: "3",
      KOOD: "6374",
      NIMETUS: "Pruuna küla",
      NIMETUS_LIIGIGA: "Pruuna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00006374",
    },
    {
      TASE: "3",
      KOOD: "6375",
      NIMETUS: "Pruntova küla",
      NIMETUS_LIIGIGA: "Pruntova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00006375",
    },
    {
      TASE: "3",
      KOOD: "6376",
      NIMETUS: "Pritsi küla",
      NIMETUS_LIIGIGA: "Pritsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006376",
    },
    {
      TASE: "3",
      KOOD: "6377",
      NIMETUS: "Prääma küla",
      NIMETUS_LIIGIGA: "Prääma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00006377",
    },
    {
      TASE: "3",
      KOOD: "6378",
      NIMETUS: "Pudisoo küla",
      NIMETUS_LIIGIGA: "Pudisoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00006378",
    },
    {
      TASE: "3",
      KOOD: "6381",
      NIMETUS: "Pudivere küla",
      NIMETUS_LIIGIGA: "Pudivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00006381",
    },
    {
      TASE: "3",
      KOOD: "6382",
      NIMETUS: "Pudivere küla",
      NIMETUS_LIIGIGA: "Pudivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00006382",
    },
    {
      TASE: "3",
      KOOD: "6385",
      NIMETUS: "Puduküla",
      NIMETUS_LIIGIGA: "Puduküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00006385",
    },
    {
      TASE: "3",
      KOOD: "6386",
      NIMETUS: "Pugõstu küla",
      NIMETUS_LIIGIGA: "Pugõstu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006386",
    },
    {
      TASE: "3",
      KOOD: "6388",
      NIMETUS: "Pugritsa küla",
      NIMETUS_LIIGIGA: "Pugritsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00006388",
    },
    {
      TASE: "3",
      KOOD: "6391",
      NIMETUS: "Puhatu küla",
      NIMETUS_LIIGIGA: "Puhatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00006391",
    },
    {
      TASE: "3",
      KOOD: "6393",
      NIMETUS: "Puhja alevik",
      NIMETUS_LIIGIGA: "Puhja alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006393",
    },
    {
      TASE: "3",
      KOOD: "6396",
      NIMETUS: "Puhkova küla",
      NIMETUS_LIIGIGA: "Puhkova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00006396",
    },
    {
      TASE: "3",
      KOOD: "6398",
      NIMETUS: "Puhmu küla",
      NIMETUS_LIIGIGA: "Puhmu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00006398",
    },
    {
      TASE: "3",
      KOOD: "6401",
      NIMETUS: "Puhtaleiva küla",
      NIMETUS_LIIGIGA: "Puhtaleiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00006401",
    },
    {
      TASE: "3",
      KOOD: "6402",
      NIMETUS: "Puiatu küla",
      NIMETUS_LIIGIGA: "Puiatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00006402",
    },
    {
      TASE: "3",
      KOOD: "6403",
      NIMETUS: "Puiatu küla",
      NIMETUS_LIIGIGA: "Puiatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00006403",
    },
    {
      TASE: "3",
      KOOD: "6404",
      NIMETUS: "Puiatu küla",
      NIMETUS_LIIGIGA: "Puiatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00006404",
    },
    {
      TASE: "3",
      KOOD: "6405",
      NIMETUS: "Puiatu küla",
      NIMETUS_LIIGIGA: "Puiatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00006405",
    },
    {
      TASE: "3",
      KOOD: "6406",
      NIMETUS: "Puiatu küla",
      NIMETUS_LIIGIGA: "Puiatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006406",
    },
    {
      TASE: "3",
      KOOD: "6408",
      NIMETUS: "Puide küla",
      NIMETUS_LIIGIGA: "Puide küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00006408",
    },
    {
      TASE: "3",
      KOOD: "6411",
      NIMETUS: "Puiga küla",
      NIMETUS_LIIGIGA: "Puiga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006411",
    },
    {
      TASE: "3",
      KOOD: "6412",
      NIMETUS: "Puista küla",
      NIMETUS_LIIGIGA: "Puista küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00006412",
    },
    {
      TASE: "3",
      KOOD: "6413",
      NIMETUS: "Puise küla",
      NIMETUS_LIIGIGA: "Puise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00006413",
    },
    {
      TASE: "3",
      KOOD: "6416",
      NIMETUS: "Puka küla",
      NIMETUS_LIIGIGA: "Puka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00006416",
    },
    {
      TASE: "3",
      KOOD: "6417",
      NIMETUS: "Puka alevik",
      NIMETUS_LIIGIGA: "Puka alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00006417",
    },
    {
      TASE: "3",
      KOOD: "6418",
      NIMETUS: "Puka küla",
      NIMETUS_LIIGIGA: "Puka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006418",
    },
    {
      TASE: "3",
      KOOD: "6419",
      NIMETUS: "Puliste küla",
      NIMETUS_LIIGIGA: "Puliste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006419",
    },
    {
      TASE: "3",
      KOOD: "6420",
      NIMETUS: "Pulgoja küla",
      NIMETUS_LIIGIGA: "Pulgoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00006420",
    },
    {
      TASE: "3",
      KOOD: "6422",
      NIMETUS: "Pullevere küla",
      NIMETUS_LIIGIGA: "Pullevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00006422",
    },
    {
      TASE: "3",
      KOOD: "6423",
      NIMETUS: "Pulleritsu küla",
      NIMETUS_LIIGIGA: "Pulleritsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006423",
    },
    {
      TASE: "3",
      KOOD: "6425",
      NIMETUS: "Pulli küla",
      NIMETUS_LIIGIGA: "Pulli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00006425",
    },
    {
      TASE: "3",
      KOOD: "6426",
      NIMETUS: "Pulli küla",
      NIMETUS_LIIGIGA: "Pulli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00006426",
    },
    {
      TASE: "3",
      KOOD: "6427",
      NIMETUS: "Pulli küla",
      NIMETUS_LIIGIGA: "Pulli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006427",
    },
    {
      TASE: "3",
      KOOD: "6428",
      NIMETUS: "Pundi küla",
      NIMETUS_LIIGIGA: "Pundi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006428",
    },
    {
      TASE: "3",
      KOOD: "6429",
      NIMETUS: "Punaküla",
      NIMETUS_LIIGIGA: "Punaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00006429",
    },
    {
      TASE: "3",
      KOOD: "6430",
      NIMETUS: "Pulli küla",
      NIMETUS_LIIGIGA: "Pulli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006430",
    },
    {
      TASE: "3",
      KOOD: "6431",
      NIMETUS: "Punakülä küla",
      NIMETUS_LIIGIGA: "Punakülä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006431",
    },
    {
      TASE: "3",
      KOOD: "6432",
      NIMETUS: "Punikvere küla",
      NIMETUS_LIIGIGA: "Punikvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006432",
    },
    {
      TASE: "3",
      KOOD: "6433",
      NIMETUS: "Pulli küla",
      NIMETUS_LIIGIGA: "Pulli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006433",
    },
    {
      TASE: "3",
      KOOD: "6434",
      NIMETUS: "Punsa küla",
      NIMETUS_LIIGIGA: "Punsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006434",
    },
    {
      TASE: "3",
      KOOD: "6435",
      NIMETUS: "Pupli küla",
      NIMETUS_LIIGIGA: "Pupli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006435",
    },
    {
      TASE: "3",
      KOOD: "6437",
      NIMETUS: "Pupastvere küla",
      NIMETUS_LIIGIGA: "Pupastvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00006437",
    },
    {
      TASE: "3",
      KOOD: "6438",
      NIMETUS: "Purga küla",
      NIMETUS_LIIGIGA: "Purga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00006438",
    },
    {
      TASE: "3",
      KOOD: "6439",
      NIMETUS: "Purka küla",
      NIMETUS_LIIGIGA: "Purka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006439",
    },
    {
      TASE: "3",
      KOOD: "6440",
      NIMETUS: "Purdi küla",
      NIMETUS_LIIGIGA: "Purdi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00006440",
    },
    {
      TASE: "3",
      KOOD: "6443",
      NIMETUS: "Purila küla",
      NIMETUS_LIIGIGA: "Purila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00006443",
    },
    {
      TASE: "3",
      KOOD: "6445",
      NIMETUS: "Purku küla",
      NIMETUS_LIIGIGA: "Purku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00006445",
    },
    {
      TASE: "3",
      KOOD: "6448",
      NIMETUS: "Purtsa küla",
      NIMETUS_LIIGIGA: "Purtsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006448",
    },
    {
      TASE: "3",
      KOOD: "6450",
      NIMETUS: "Purtse küla",
      NIMETUS_LIIGIGA: "Purtse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00006450",
    },
    {
      TASE: "3",
      KOOD: "6453",
      NIMETUS: "Purtsi küla",
      NIMETUS_LIIGIGA: "Purtsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006453",
    },
    {
      TASE: "3",
      KOOD: "6455",
      NIMETUS: "Puru küla",
      NIMETUS_LIIGIGA: "Puru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "251",
      ADS_OID: "AY00006455",
    },
    {
      TASE: "3",
      KOOD: "6458",
      NIMETUS: "Pusi küla",
      NIMETUS_LIIGIGA: "Pusi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006458",
    },
    {
      TASE: "3",
      KOOD: "6459",
      NIMETUS: "Puski küla",
      NIMETUS_LIIGIGA: "Puski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006459",
    },
    {
      TASE: "3",
      KOOD: "6460",
      NIMETUS: "Putkaste küla",
      NIMETUS_LIIGIGA: "Putkaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006460",
    },
    {
      TASE: "3",
      KOOD: "6461",
      NIMETUS: "Puskaru küla",
      NIMETUS_LIIGIGA: "Puskaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00006461",
    },
    {
      TASE: "3",
      KOOD: "6462",
      NIMETUS: "Pusku küla",
      NIMETUS_LIIGIGA: "Pusku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00006462",
    },
    {
      TASE: "3",
      KOOD: "6463",
      NIMETUS: "Puspuri küla",
      NIMETUS_LIIGIGA: "Puspuri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006463",
    },
    {
      TASE: "3",
      KOOD: "6464",
      NIMETUS: "Putkaste küla",
      NIMETUS_LIIGIGA: "Putkaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00006464",
    },
    {
      TASE: "3",
      KOOD: "6469",
      NIMETUS: "Putu küla",
      NIMETUS_LIIGIGA: "Putu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00006469",
    },
    {
      TASE: "3",
      KOOD: "6472",
      NIMETUS: "Puugi küla",
      NIMETUS_LIIGIGA: "Puugi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00006472",
    },
    {
      TASE: "3",
      KOOD: "6474",
      NIMETUS: "Puugnitsa küla",
      NIMETUS_LIIGIGA: "Puugnitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00006474",
    },
    {
      TASE: "3",
      KOOD: "6477",
      NIMETUS: "Puuri küla",
      NIMETUS_LIIGIGA: "Puuri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00006477",
    },
    {
      TASE: "3",
      KOOD: "6479",
      NIMETUS: "Puurmani alevik",
      NIMETUS_LIIGIGA: "Puurmani alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00006479",
    },
    {
      TASE: "3",
      KOOD: "6482",
      NIMETUS: "Puusepa küla",
      NIMETUS_LIIGIGA: "Puusepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006482",
    },
    {
      TASE: "3",
      KOOD: "6483",
      NIMETUS: "Puusepa küla",
      NIMETUS_LIIGIGA: "Puusepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00006483",
    },
    {
      TASE: "3",
      KOOD: "6484",
      NIMETUS: "Puutli küla",
      NIMETUS_LIIGIGA: "Puutli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006484",
    },
    {
      TASE: "3",
      KOOD: "6485",
      NIMETUS: "Põdra küla",
      NIMETUS_LIIGIGA: "Põdra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006485",
    },
    {
      TASE: "3",
      KOOD: "6488",
      NIMETUS: "Põdra küla",
      NIMETUS_LIIGIGA: "Põdra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006488",
    },
    {
      TASE: "3",
      KOOD: "6491",
      NIMETUS: "Põdrangu küla",
      NIMETUS_LIIGIGA: "Põdrangu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00006491",
    },
    {
      TASE: "3",
      KOOD: "6493",
      NIMETUS: "Põdruse küla",
      NIMETUS_LIIGIGA: "Põdruse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00006493",
    },
    {
      TASE: "3",
      KOOD: "6496",
      NIMETUS: "Põgari-Sassi küla",
      NIMETUS_LIIGIGA: "Põgari-Sassi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00006496",
    },
    {
      TASE: "3",
      KOOD: "6497",
      NIMETUS: "Põhja küla",
      NIMETUS_LIIGIGA: "Põhja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00006497",
    },
    {
      TASE: "3",
      KOOD: "6499",
      NIMETUS: "Põhara küla",
      NIMETUS_LIIGIGA: "Põhara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00006499",
    },
    {
      TASE: "3",
      KOOD: "6502",
      NIMETUS: "Põhjaka küla",
      NIMETUS_LIIGIGA: "Põhjaka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00006502",
    },
    {
      TASE: "3",
      KOOD: "6503",
      NIMETUS: "Põikma küla",
      NIMETUS_LIIGIGA: "Põikma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00006503",
    },
    {
      TASE: "3",
      KOOD: "6505",
      NIMETUS: "Põikva küla",
      NIMETUS_LIIGIGA: "Põikva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00006505",
    },
    {
      TASE: "3",
      KOOD: "6507",
      NIMETUS: "Põima küla",
      NIMETUS_LIIGIGA: "Põima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00006507",
    },
    {
      TASE: "3",
      KOOD: "6510",
      NIMETUS: "Põlde küla",
      NIMETUS_LIIGIGA: "Põlde küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00006510",
    },
    {
      TASE: "3",
      KOOD: "6513",
      NIMETUS: "Põldeotsa küla",
      NIMETUS_LIIGIGA: "Põldeotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00006513",
    },
    {
      TASE: "3",
      KOOD: "6515",
      NIMETUS: "Põldmaa küla",
      NIMETUS_LIIGIGA: "Põldmaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006515",
    },
    {
      TASE: "3",
      KOOD: "6518",
      NIMETUS: "Põlendmaa küla",
      NIMETUS_LIIGIGA: "Põlendmaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00006518",
    },
    {
      TASE: "3",
      KOOD: "6520",
      NIMETUS: "Põlgaste küla",
      NIMETUS_LIIGIGA: "Põlgaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00006520",
    },
    {
      TASE: "3",
      KOOD: "6523",
      NIMETUS: "Põlli küla",
      NIMETUS_LIIGIGA: "Põlli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00006523",
    },
    {
      TASE: "3",
      KOOD: "6528",
      NIMETUS: "Põllküla",
      NIMETUS_LIIGIGA: "Põllküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00006528",
    },
    {
      TASE: "3",
      KOOD: "6530",
      NIMETUS: "Põllu küla",
      NIMETUS_LIIGIGA: "Põllu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00006530",
    },
    {
      TASE: "3",
      KOOD: "6533",
      NIMETUS: "Põlma küla",
      NIMETUS_LIIGIGA: "Põlma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00006533",
    },
    {
      TASE: "3",
      KOOD: "6534",
      NIMETUS: "Põlluküla",
      NIMETUS_LIIGIGA: "Põlluküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006534",
    },
    {
      TASE: "3",
      KOOD: "6535",
      NIMETUS: "Põlula küla",
      NIMETUS_LIIGIGA: "Põlula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00006535",
    },
    {
      TASE: "3",
      KOOD: "6538",
      NIMETUS: "Põnni küla",
      NIMETUS_LIIGIGA: "Põnni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006538",
    },
    {
      TASE: "3",
      KOOD: "6541",
      NIMETUS: "Põrga küla",
      NIMETUS_LIIGIGA: "Põrga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006541",
    },
    {
      TASE: "3",
      KOOD: "6542",
      NIMETUS: "Põripõllu küla",
      NIMETUS_LIIGIGA: "Põripõllu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006542",
    },
    {
      TASE: "3",
      KOOD: "6543",
      NIMETUS: "Põrstõ küla",
      NIMETUS_LIIGIGA: "Põrstõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00006543",
    },
    {
      TASE: "3",
      KOOD: "6544",
      NIMETUS: "Põrgu küla",
      NIMETUS_LIIGIGA: "Põrgu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006544",
    },
    {
      TASE: "3",
      KOOD: "6545",
      NIMETUS: "Põru küla",
      NIMETUS_LIIGIGA: "Põru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006545",
    },
    {
      TASE: "3",
      KOOD: "6546",
      NIMETUS: "Põrsaku küla",
      NIMETUS_LIIGIGA: "Põrsaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00006546",
    },
    {
      TASE: "3",
      KOOD: "6549",
      NIMETUS: "Põru küla",
      NIMETUS_LIIGIGA: "Põru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00006549",
    },
    {
      TASE: "3",
      KOOD: "6552",
      NIMETUS: "Põvvatu küla",
      NIMETUS_LIIGIGA: "Põvvatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00006552",
    },
    {
      TASE: "3",
      KOOD: "6556",
      NIMETUS: "Pädaste küla",
      NIMETUS_LIIGIGA: "Pädaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00006556",
    },
    {
      TASE: "3",
      KOOD: "6559",
      NIMETUS: "Päelda küla",
      NIMETUS_LIIGIGA: "Päelda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00006559",
    },
    {
      TASE: "3",
      KOOD: "6562",
      NIMETUS: "Pähkla küla",
      NIMETUS_LIIGIGA: "Pähkla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006562",
    },
    {
      TASE: "3",
      KOOD: "6564",
      NIMETUS: "Pähni küla",
      NIMETUS_LIIGIGA: "Pähni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006564",
    },
    {
      TASE: "3",
      KOOD: "6567",
      NIMETUS: "Päide küla",
      NIMETUS_LIIGIGA: "Päide küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00006567",
    },
    {
      TASE: "3",
      KOOD: "6570",
      NIMETUS: "Päidla küla",
      NIMETUS_LIIGIGA: "Päidla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00006570",
    },
    {
      TASE: "3",
      KOOD: "6572",
      NIMETUS: "Päidre küla",
      NIMETUS_LIIGIGA: "Päidre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00006572",
    },
    {
      TASE: "3",
      KOOD: "6575",
      NIMETUS: "Päigiste küla",
      NIMETUS_LIIGIGA: "Päigiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00006575",
    },
    {
      TASE: "3",
      KOOD: "6577",
      NIMETUS: "Päiksi küla",
      NIMETUS_LIIGIGA: "Päiksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006577",
    },
    {
      TASE: "3",
      KOOD: "6580",
      NIMETUS: "Päinurme küla",
      NIMETUS_LIIGIGA: "Päinurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00006580",
    },
    {
      TASE: "3",
      KOOD: "6582",
      NIMETUS: "Päite küla",
      NIMETUS_LIIGIGA: "Päite küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00006582",
    },
    {
      TASE: "3",
      KOOD: "6583",
      NIMETUS: "Pällastvere küla",
      NIMETUS_LIIGIGA: "Pällastvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00006583",
    },
    {
      TASE: "3",
      KOOD: "6584",
      NIMETUS: "Päkste küla",
      NIMETUS_LIIGIGA: "Päkste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00006584",
    },
    {
      TASE: "3",
      KOOD: "6585",
      NIMETUS: "Päka küla",
      NIMETUS_LIIGIGA: "Päka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006585",
    },
    {
      TASE: "3",
      KOOD: "6586",
      NIMETUS: "Pälli küla",
      NIMETUS_LIIGIGA: "Pälli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00006586",
    },
    {
      TASE: "3",
      KOOD: "6588",
      NIMETUS: "Pällu küla",
      NIMETUS_LIIGIGA: "Pällu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00006588",
    },
    {
      TASE: "3",
      KOOD: "6589",
      NIMETUS: "Pällu küla",
      NIMETUS_LIIGIGA: "Pällu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00006589",
    },
    {
      TASE: "3",
      KOOD: "6592",
      NIMETUS: "Pältre küla",
      NIMETUS_LIIGIGA: "Pältre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006592",
    },
    {
      TASE: "3",
      KOOD: "6595",
      NIMETUS: "Päraküla",
      NIMETUS_LIIGIGA: "Päraküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00006595",
    },
    {
      TASE: "3",
      KOOD: "6598",
      NIMETUS: "Pärase küla",
      NIMETUS_LIIGIGA: "Pärase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00006598",
    },
    {
      TASE: "3",
      KOOD: "6599",
      NIMETUS: "Päri küla",
      NIMETUS_LIIGIGA: "Päri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00006599",
    },
    {
      TASE: "3",
      KOOD: "6601",
      NIMETUS: "Päri küla",
      NIMETUS_LIIGIGA: "Päri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006601",
    },
    {
      TASE: "3",
      KOOD: "6603",
      NIMETUS: "Pärinurme küla",
      NIMETUS_LIIGIGA: "Pärinurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00006603",
    },
    {
      TASE: "3",
      KOOD: "6606",
      NIMETUS: "Pärispea küla",
      NIMETUS_LIIGIGA: "Pärispea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00006606",
    },
    {
      TASE: "3",
      KOOD: "6608",
      NIMETUS: "Pärivere küla",
      NIMETUS_LIIGIGA: "Pärivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00006608",
    },
    {
      TASE: "3",
      KOOD: "6609",
      NIMETUS: "Pärna küla",
      NIMETUS_LIIGIGA: "Pärna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006609",
    },
    {
      TASE: "3",
      KOOD: "6611",
      NIMETUS: "Pärlijõe küla",
      NIMETUS_LIIGIGA: "Pärlijõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006611",
    },
    {
      TASE: "3",
      KOOD: "6612",
      NIMETUS: "Pärna küla",
      NIMETUS_LIIGIGA: "Pärna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00006612",
    },
    {
      TASE: "3",
      KOOD: "6613",
      NIMETUS: "Pärnamäe küla",
      NIMETUS_LIIGIGA: "Pärnamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00006613",
    },
    {
      TASE: "3",
      KOOD: "6614",
      NIMETUS: "Pärni küla",
      NIMETUS_LIIGIGA: "Pärni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006614",
    },
    {
      TASE: "3",
      KOOD: "6615",
      NIMETUS: "Pärnselja küla",
      NIMETUS_LIIGIGA: "Pärnselja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006615",
    },
    {
      TASE: "3",
      KOOD: "6616",
      NIMETUS: "Pärnjõe küla",
      NIMETUS_LIIGIGA: "Pärnjõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00006616",
    },
    {
      TASE: "3",
      KOOD: "6617",
      NIMETUS: "Pärnu-Jaagupi alev",
      NIMETUS_LIIGIGA: "Pärnu-Jaagupi alev",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00006617",
    },
    {
      TASE: "3",
      KOOD: "6618",
      NIMETUS: "Pärsama küla",
      NIMETUS_LIIGIGA: "Pärsama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006618",
    },
    {
      TASE: "3",
      KOOD: "6621",
      NIMETUS: "Pärsi küla",
      NIMETUS_LIIGIGA: "Pärsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00006621",
    },
    {
      TASE: "3",
      KOOD: "6624",
      NIMETUS: "Päädeva küla",
      NIMETUS_LIIGIGA: "Päädeva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00006624",
    },
    {
      TASE: "3",
      KOOD: "6625",
      NIMETUS: "Pässä küla",
      NIMETUS_LIIGIGA: "Pässä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006625",
    },
    {
      TASE: "3",
      KOOD: "6626",
      NIMETUS: "Pärsti küla",
      NIMETUS_LIIGIGA: "Pärsti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006626",
    },
    {
      TASE: "3",
      KOOD: "6629",
      NIMETUS: "Päärdu küla",
      NIMETUS_LIIGIGA: "Päärdu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00006629",
    },
    {
      TASE: "3",
      KOOD: "6631",
      NIMETUS: "Pääsna küla",
      NIMETUS_LIIGIGA: "Pääsna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00006631",
    },
    {
      TASE: "3",
      KOOD: "6635",
      NIMETUS: "Pöide küla",
      NIMETUS_LIIGIGA: "Pöide küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006635",
    },
    {
      TASE: "3",
      KOOD: "6638",
      NIMETUS: "Põitse küla",
      NIMETUS_LIIGIGA: "Põitse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00006638",
    },
    {
      TASE: "3",
      KOOD: "6639",
      NIMETUS: "Pöitse küla",
      NIMETUS_LIIGIGA: "Pöitse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006639",
    },
    {
      TASE: "3",
      KOOD: "6641",
      NIMETUS: "Pöögle küla",
      NIMETUS_LIIGIGA: "Pöögle küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00006641",
    },
    {
      TASE: "3",
      KOOD: "6643",
      NIMETUS: "Pööra küla",
      NIMETUS_LIIGIGA: "Pööra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00006643",
    },
    {
      TASE: "3",
      KOOD: "6646",
      NIMETUS: "Pööravere küla",
      NIMETUS_LIIGIGA: "Pööravere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00006646",
    },
    {
      TASE: "3",
      KOOD: "6648",
      NIMETUS: "Pööritsa küla",
      NIMETUS_LIIGIGA: "Pööritsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006648",
    },
    {
      TASE: "3",
      KOOD: "6652",
      NIMETUS: "Püha küla",
      NIMETUS_LIIGIGA: "Püha küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00006652",
    },
    {
      TASE: "3",
      KOOD: "6653",
      NIMETUS: "Püha küla",
      NIMETUS_LIIGIGA: "Püha küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006653",
    },
    {
      TASE: "3",
      KOOD: "6656",
      NIMETUS: "Pühajõe küla",
      NIMETUS_LIIGIGA: "Pühajõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00006656",
    },
    {
      TASE: "3",
      KOOD: "6661",
      NIMETUS: "Pühalepa küla",
      NIMETUS_LIIGIGA: "Pühalepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006661",
    },
    {
      TASE: "3",
      KOOD: "6662",
      NIMETUS: "Pühatu küla",
      NIMETUS_LIIGIGA: "Pühatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00006662",
    },
    {
      TASE: "3",
      KOOD: "6663",
      NIMETUS: "Pühaste küla",
      NIMETUS_LIIGIGA: "Pühaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006663",
    },
    {
      TASE: "3",
      KOOD: "6666",
      NIMETUS: "Pühi küla",
      NIMETUS_LIIGIGA: "Pühi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00006666",
    },
    {
      TASE: "3",
      KOOD: "6669",
      NIMETUS: "Pürksi küla / Birkas",
      NIMETUS_LIIGIGA: "Pürksi küla / Birkas",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00006669",
    },
    {
      TASE: "3",
      KOOD: "6670",
      NIMETUS: "Püssä küla",
      NIMETUS_LIIGIGA: "Püssä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006670",
    },
    {
      TASE: "3",
      KOOD: "6672",
      NIMETUS: "Püünsi küla",
      NIMETUS_LIIGIGA: "Püünsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00006672",
    },
    {
      TASE: "3",
      KOOD: "6673",
      NIMETUS: "Raadi küla",
      NIMETUS_LIIGIGA: "Raadi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006673",
    },
    {
      TASE: "3",
      KOOD: "6677",
      NIMETUS: "Raadama küla",
      NIMETUS_LIIGIGA: "Raadama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00006677",
    },
    {
      TASE: "3",
      KOOD: "6679",
      NIMETUS: "Raadivere küla",
      NIMETUS_LIIGIGA: "Raadivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00006679",
    },
    {
      TASE: "3",
      KOOD: "6682",
      NIMETUS: "Raadna küla",
      NIMETUS_LIIGIGA: "Raadna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00006682",
    },
    {
      TASE: "3",
      KOOD: "6684",
      NIMETUS: "Raaduvere küla",
      NIMETUS_LIIGIGA: "Raaduvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00006684",
    },
    {
      TASE: "3",
      KOOD: "6687",
      NIMETUS: "Raagi küla",
      NIMETUS_LIIGIGA: "Raagi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006687",
    },
    {
      TASE: "3",
      KOOD: "6689",
      NIMETUS: "Raamatu küla",
      NIMETUS_LIIGIGA: "Raamatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00006689",
    },
    {
      TASE: "3",
      KOOD: "6692",
      NIMETUS: "Raanitsa küla",
      NIMETUS_LIIGIGA: "Raanitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00006692",
    },
    {
      TASE: "3",
      KOOD: "6694",
      NIMETUS: "Raasiku alevik",
      NIMETUS_LIIGIGA: "Raasiku alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00006694",
    },
    {
      TASE: "3",
      KOOD: "6697",
      NIMETUS: "Raassilla küla",
      NIMETUS_LIIGIGA: "Raassilla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006697",
    },
    {
      TASE: "3",
      KOOD: "6699",
      NIMETUS: "Raatvere küla",
      NIMETUS_LIIGIGA: "Raatvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006699",
    },
    {
      TASE: "3",
      KOOD: "6702",
      NIMETUS: "Raavitsa küla",
      NIMETUS_LIIGIGA: "Raavitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00006702",
    },
    {
      TASE: "3",
      KOOD: "6703",
      NIMETUS: "Rabasaare küla",
      NIMETUS_LIIGIGA: "Rabasaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00006703",
    },
    {
      TASE: "3",
      KOOD: "6705",
      NIMETUS: "Rabaküla",
      NIMETUS_LIIGIGA: "Rabaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00006705",
    },
    {
      TASE: "3",
      KOOD: "6707",
      NIMETUS: "Rabavere küla",
      NIMETUS_LIIGIGA: "Rabavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006707",
    },
    {
      TASE: "3",
      KOOD: "6710",
      NIMETUS: "Rabivere küla",
      NIMETUS_LIIGIGA: "Rabivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00006710",
    },
    {
      TASE: "3",
      KOOD: "6713",
      NIMETUS: "Rae küla",
      NIMETUS_LIIGIGA: "Rae küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00006713",
    },
    {
      TASE: "3",
      KOOD: "6714",
      NIMETUS: "Rae küla",
      NIMETUS_LIIGIGA: "Rae küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00006714",
    },
    {
      TASE: "3",
      KOOD: "6715",
      NIMETUS: "Raegma küla",
      NIMETUS_LIIGIGA: "Raegma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00006715",
    },
    {
      TASE: "3",
      KOOD: "6716",
      NIMETUS: "Raeküla",
      NIMETUS_LIIGIGA: "Raeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00006716",
    },
    {
      TASE: "3",
      KOOD: "6717",
      NIMETUS: "Raespa küla",
      NIMETUS_LIIGIGA: "Raespa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006717",
    },
    {
      TASE: "3",
      KOOD: "6719",
      NIMETUS: "Raela küla",
      NIMETUS_LIIGIGA: "Raela küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00006719",
    },
    {
      TASE: "3",
      KOOD: "6722",
      NIMETUS: "Raheste küla",
      NIMETUS_LIIGIGA: "Raheste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006722",
    },
    {
      TASE: "3",
      KOOD: "6724",
      NIMETUS: "Rahinge küla",
      NIMETUS_LIIGIGA: "Rahinge küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "793",
      ADS_OID: "AY00006724",
    },
    {
      TASE: "3",
      KOOD: "6725",
      NIMETUS: "Rahkama küla",
      NIMETUS_LIIGIGA: "Rahkama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00006725",
    },
    {
      TASE: "3",
      KOOD: "6726",
      NIMETUS: "Rahkla küla",
      NIMETUS_LIIGIGA: "Rahkla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00006726",
    },
    {
      TASE: "3",
      KOOD: "6727",
      NIMETUS: "Rahivere küla",
      NIMETUS_LIIGIGA: "Rahivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00006727",
    },
    {
      TASE: "3",
      KOOD: "6729",
      NIMETUS: "Rahkla küla",
      NIMETUS_LIIGIGA: "Rahkla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00006729",
    },
    {
      TASE: "3",
      KOOD: "6730",
      NIMETUS: "Rahniku küla",
      NIMETUS_LIIGIGA: "Rahniku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006730",
    },
    {
      TASE: "3",
      KOOD: "6732",
      NIMETUS: "Rahnoja küla",
      NIMETUS_LIIGIGA: "Rahnoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00006732",
    },
    {
      TASE: "3",
      KOOD: "6734",
      NIMETUS: "Rahtla küla",
      NIMETUS_LIIGIGA: "Rahtla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006734",
    },
    {
      TASE: "3",
      KOOD: "6737",
      NIMETUS: "Rahu küla",
      NIMETUS_LIIGIGA: "Rahu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006737",
    },
    {
      TASE: "3",
      KOOD: "6739",
      NIMETUS: "Rahula küla",
      NIMETUS_LIIGIGA: "Rahula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00006739",
    },
    {
      TASE: "3",
      KOOD: "6743",
      NIMETUS: "Rahumäe küla",
      NIMETUS_LIIGIGA: "Rahumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00006743",
    },
    {
      TASE: "3",
      KOOD: "6745",
      NIMETUS: "Rahuste küla",
      NIMETUS_LIIGIGA: "Rahuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006745",
    },
    {
      TASE: "3",
      KOOD: "6748",
      NIMETUS: "Raigaste küla",
      NIMETUS_LIIGIGA: "Raigaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006748",
    },
    {
      TASE: "3",
      KOOD: "6751",
      NIMETUS: "Raigastvere küla",
      NIMETUS_LIIGIGA: "Raigastvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00006751",
    },
    {
      TASE: "3",
      KOOD: "6753",
      NIMETUS: "Raigla küla",
      NIMETUS_LIIGIGA: "Raigla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00006753",
    },
    {
      TASE: "3",
      KOOD: "6756",
      NIMETUS: "Raigu küla",
      NIMETUS_LIIGIGA: "Raigu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00006756",
    },
    {
      TASE: "3",
      KOOD: "6758",
      NIMETUS: "Raikküla",
      NIMETUS_LIIGIGA: "Raikküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00006758",
    },
    {
      TASE: "3",
      KOOD: "6764",
      NIMETUS: "Raja küla",
      NIMETUS_LIIGIGA: "Raja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00006764",
    },
    {
      TASE: "3",
      KOOD: "6765",
      NIMETUS: "Raja küla",
      NIMETUS_LIIGIGA: "Raja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00006765",
    },
    {
      TASE: "3",
      KOOD: "6767",
      NIMETUS: "Rajaküla",
      NIMETUS_LIIGIGA: "Rajaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00006767",
    },
    {
      TASE: "3",
      KOOD: "6768",
      NIMETUS: "Rajaküla",
      NIMETUS_LIIGIGA: "Rajaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00006768",
    },
    {
      TASE: "3",
      KOOD: "6771",
      NIMETUS: "Raka küla",
      NIMETUS_LIIGIGA: "Raka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00006771",
    },
    {
      TASE: "3",
      KOOD: "6772",
      NIMETUS: "Raka küla",
      NIMETUS_LIIGIGA: "Raka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00006772",
    },
    {
      TASE: "3",
      KOOD: "6775",
      NIMETUS: "Rakke alevik",
      NIMETUS_LIIGIGA: "Rakke alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00006775",
    },
    {
      TASE: "3",
      KOOD: "6778",
      NIMETUS: "Rame küla",
      NIMETUS_LIIGIGA: "Rame küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006778",
    },
    {
      TASE: "3",
      KOOD: "6780",
      NIMETUS: "Ramma küla",
      NIMETUS_LIIGIGA: "Ramma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00006780",
    },
    {
      TASE: "3",
      KOOD: "6783",
      NIMETUS: "Rammuka küla",
      NIMETUS_LIIGIGA: "Rammuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00006783",
    },
    {
      TASE: "3",
      KOOD: "6784",
      NIMETUS: "Rammuka küla",
      NIMETUS_LIIGIGA: "Rammuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006784",
    },
    {
      TASE: "3",
      KOOD: "6785",
      NIMETUS: "Rammu küla",
      NIMETUS_LIIGIGA: "Rammu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00006785",
    },
    {
      TASE: "3",
      KOOD: "6786",
      NIMETUS: "Rampe küla",
      NIMETUS_LIIGIGA: "Rampe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00006786",
    },
    {
      TASE: "3",
      KOOD: "6789",
      NIMETUS: "Ramsi alevik",
      NIMETUS_LIIGIGA: "Ramsi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006789",
    },
    {
      TASE: "3",
      KOOD: "6792",
      NIMETUS: "Randivälja küla",
      NIMETUS_LIIGIGA: "Randivälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00006792",
    },
    {
      TASE: "3",
      KOOD: "6794",
      NIMETUS: "Randküla",
      NIMETUS_LIIGIGA: "Randküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006794",
    },
    {
      TASE: "3",
      KOOD: "6797",
      NIMETUS: "Randvere küla",
      NIMETUS_LIIGIGA: "Randvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00006797",
    },
    {
      TASE: "3",
      KOOD: "6799",
      NIMETUS: "Randvere küla",
      NIMETUS_LIIGIGA: "Randvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006799",
    },
    {
      TASE: "3",
      KOOD: "6800",
      NIMETUS: "Rangu küla",
      NIMETUS_LIIGIGA: "Rangu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00006800",
    },
    {
      TASE: "3",
      KOOD: "6801",
      NIMETUS: "Rannaküla",
      NIMETUS_LIIGIGA: "Rannaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006801",
    },
    {
      TASE: "3",
      KOOD: "6802",
      NIMETUS: "Rannaküla",
      NIMETUS_LIIGIGA: "Rannaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006802",
    },
    {
      TASE: "3",
      KOOD: "6803",
      NIMETUS: "Ranna küla",
      NIMETUS_LIIGIGA: "Ranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006803",
    },
    {
      TASE: "3",
      KOOD: "6805",
      NIMETUS: "Rannajõe küla",
      NIMETUS_LIIGIGA: "Rannajõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00006805",
    },
    {
      TASE: "3",
      KOOD: "6808",
      NIMETUS: "Rannaküla",
      NIMETUS_LIIGIGA: "Rannaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00006808",
    },
    {
      TASE: "3",
      KOOD: "6809",
      NIMETUS: "Rannaküla",
      NIMETUS_LIIGIGA: "Rannaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006809",
    },
    {
      TASE: "3",
      KOOD: "6811",
      NIMETUS: "Rannametsa küla",
      NIMETUS_LIIGIGA: "Rannametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00006811",
    },
    {
      TASE: "3",
      KOOD: "6814",
      NIMETUS: "Rannamõisa küla",
      NIMETUS_LIIGIGA: "Rannamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00006814",
    },
    {
      TASE: "3",
      KOOD: "6816",
      NIMETUS: "Rannapungerja küla",
      NIMETUS_LIIGIGA: "Rannapungerja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00006816",
    },
    {
      TASE: "3",
      KOOD: "6817",
      NIMETUS: "Rannaküla",
      NIMETUS_LIIGIGA: "Rannaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00006817",
    },
    {
      TASE: "3",
      KOOD: "6818",
      NIMETUS: "Rannaküla",
      NIMETUS_LIIGIGA: "Rannaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006818",
    },
    {
      TASE: "3",
      KOOD: "6819",
      NIMETUS: "Ranniku küla",
      NIMETUS_LIIGIGA: "Ranniku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00006819",
    },
    {
      TASE: "3",
      KOOD: "6821",
      NIMETUS: "Rannu küla",
      NIMETUS_LIIGIGA: "Rannu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00006821",
    },
    {
      TASE: "3",
      KOOD: "6822",
      NIMETUS: "Rannu alevik",
      NIMETUS_LIIGIGA: "Rannu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006822",
    },
    {
      TASE: "3",
      KOOD: "6823",
      NIMETUS: "Raotu küla",
      NIMETUS_LIIGIGA: "Raotu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00006823",
    },
    {
      TASE: "3",
      KOOD: "6824",
      NIMETUS: "Ransi küla",
      NIMETUS_LIIGIGA: "Ransi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00006824",
    },
    {
      TASE: "3",
      KOOD: "6825",
      NIMETUS: "Rasina küla",
      NIMETUS_LIIGIGA: "Rasina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00006825",
    },
    {
      TASE: "3",
      KOOD: "6826",
      NIMETUS: "Rapla linn",
      NIMETUS_LIIGIGA: "Rapla linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00006826",
    },
    {
      TASE: "3",
      KOOD: "6828",
      NIMETUS: "Rasivere küla",
      NIMETUS_LIIGIGA: "Rasivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00006828",
    },
    {
      TASE: "3",
      KOOD: "6829",
      NIMETUS: "Rasivere küla",
      NIMETUS_LIIGIGA: "Rasivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00006829",
    },
    {
      TASE: "3",
      KOOD: "6831",
      NIMETUS: "Rassi küla",
      NIMETUS_LIIGIGA: "Rassi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00006831",
    },
    {
      TASE: "3",
      KOOD: "6832",
      NIMETUS: "Rassiotsa küla",
      NIMETUS_LIIGIGA: "Rassiotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00006832",
    },
    {
      TASE: "3",
      KOOD: "6834",
      NIMETUS: "Rassiku küla",
      NIMETUS_LIIGIGA: "Rassiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00006834",
    },
    {
      TASE: "3",
      KOOD: "6836",
      NIMETUS: "Rastla küla",
      NIMETUS_LIIGIGA: "Rastla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00006836",
    },
    {
      TASE: "3",
      KOOD: "6839",
      NIMETUS: "Rasva küla",
      NIMETUS_LIIGIGA: "Rasva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006839",
    },
    {
      TASE: "3",
      KOOD: "6842",
      NIMETUS: "Ratla küla",
      NIMETUS_LIIGIGA: "Ratla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006842",
    },
    {
      TASE: "3",
      KOOD: "6844",
      NIMETUS: "Ratva küla",
      NIMETUS_LIIGIGA: "Ratva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00006844",
    },
    {
      TASE: "3",
      KOOD: "6847",
      NIMETUS: "Raudla küla",
      NIMETUS_LIIGIGA: "Raudla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00006847",
    },
    {
      TASE: "3",
      KOOD: "6850",
      NIMETUS: "Raudlepa küla",
      NIMETUS_LIIGIGA: "Raudlepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00006850",
    },
    {
      TASE: "3",
      KOOD: "6852",
      NIMETUS: "Raudna küla",
      NIMETUS_LIIGIGA: "Raudna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006852",
    },
    {
      TASE: "3",
      KOOD: "6855",
      NIMETUS: "Raudoja küla",
      NIMETUS_LIIGIGA: "Raudoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00006855",
    },
    {
      TASE: "3",
      KOOD: "6856",
      NIMETUS: "Raudsepa küla",
      NIMETUS_LIIGIGA: "Raudsepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006856",
    },
    {
      TASE: "3",
      KOOD: "6857",
      NIMETUS: "Raudsepa küla",
      NIMETUS_LIIGIGA: "Raudsepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00006857",
    },
    {
      TASE: "3",
      KOOD: "6858",
      NIMETUS: "Raudsepa küla",
      NIMETUS_LIIGIGA: "Raudsepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006858",
    },
    {
      TASE: "3",
      KOOD: "6859",
      NIMETUS: "Raudsepä küla",
      NIMETUS_LIIGIGA: "Raudsepä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006859",
    },
    {
      TASE: "3",
      KOOD: "6860",
      NIMETUS: "Raudvere küla",
      NIMETUS_LIIGIGA: "Raudvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00006860",
    },
    {
      TASE: "3",
      KOOD: "6861",
      NIMETUS: "Raugi küla",
      NIMETUS_LIIGIGA: "Raugi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00006861",
    },
    {
      TASE: "3",
      KOOD: "6862",
      NIMETUS: "Raugu küla",
      NIMETUS_LIIGIGA: "Raugu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006862",
    },
    {
      TASE: "3",
      KOOD: "6863",
      NIMETUS: "Raukla küla",
      NIMETUS_LIIGIGA: "Raukla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00006863",
    },
    {
      TASE: "3",
      KOOD: "6864",
      NIMETUS: "Rauskapalu küla",
      NIMETUS_LIIGIGA: "Rauskapalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006864",
    },
    {
      TASE: "3",
      KOOD: "6865",
      NIMETUS: "Rauksi küla",
      NIMETUS_LIIGIGA: "Rauksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006865",
    },
    {
      TASE: "3",
      KOOD: "6866",
      NIMETUS: "Rausvere küla",
      NIMETUS_LIIGIGA: "Rausvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00006866",
    },
    {
      TASE: "3",
      KOOD: "6869",
      NIMETUS: "Rava küla",
      NIMETUS_LIIGIGA: "Rava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00006869",
    },
    {
      TASE: "3",
      KOOD: "6870",
      NIMETUS: "Rava küla",
      NIMETUS_LIIGIGA: "Rava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00006870",
    },
    {
      TASE: "3",
      KOOD: "6872",
      NIMETUS: "Raveliku küla",
      NIMETUS_LIIGIGA: "Raveliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00006872",
    },
    {
      TASE: "3",
      KOOD: "6875",
      NIMETUS: "Ravila alevik",
      NIMETUS_LIIGIGA: "Ravila alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00006875",
    },
    {
      TASE: "3",
      KOOD: "6879",
      NIMETUS: "Reastvere küla",
      NIMETUS_LIIGIGA: "Reastvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00006879",
    },
    {
      TASE: "3",
      KOOD: "6882",
      NIMETUS: "Rebala küla",
      NIMETUS_LIIGIGA: "Rebala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00006882",
    },
    {
      TASE: "3",
      KOOD: "6884",
      NIMETUS: "Rebase küla",
      NIMETUS_LIIGIGA: "Rebase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00006884",
    },
    {
      TASE: "3",
      KOOD: "6885",
      NIMETUS: "Rebase küla",
      NIMETUS_LIIGIGA: "Rebase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006885",
    },
    {
      TASE: "3",
      KOOD: "6887",
      NIMETUS: "Rebasemõisa küla",
      NIMETUS_LIIGIGA: "Rebasemõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00006887",
    },
    {
      TASE: "3",
      KOOD: "6888",
      NIMETUS: "Rebaski küla",
      NIMETUS_LIIGIGA: "Rebaski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00006888",
    },
    {
      TASE: "3",
      KOOD: "6889",
      NIMETUS: "Rebaste küla",
      NIMETUS_LIIGIGA: "Rebaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00006889",
    },
    {
      TASE: "3",
      KOOD: "6890",
      NIMETUS: "Rebaste küla",
      NIMETUS_LIIGIGA: "Rebaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006890",
    },
    {
      TASE: "3",
      KOOD: "6891",
      NIMETUS: "Rebaste küla",
      NIMETUS_LIIGIGA: "Rebaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006891",
    },
    {
      TASE: "3",
      KOOD: "6892",
      NIMETUS: "Rebäse küla",
      NIMETUS_LIIGIGA: "Rebäse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006892",
    },
    {
      TASE: "3",
      KOOD: "6894",
      NIMETUS: "Rebu küla",
      NIMETUS_LIIGIGA: "Rebu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00006894",
    },
    {
      TASE: "3",
      KOOD: "6895",
      NIMETUS: "Rebäsemõisa küla",
      NIMETUS_LIIGIGA: "Rebäsemõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006895",
    },
    {
      TASE: "3",
      KOOD: "6896",
      NIMETUS: "Rebasmäe küla",
      NIMETUS_LIIGIGA: "Rebasmäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006896",
    },
    {
      TASE: "3",
      KOOD: "6897",
      NIMETUS: "Reegoldi küla",
      NIMETUS_LIIGIGA: "Reegoldi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00006897",
    },
    {
      TASE: "3",
      KOOD: "6898",
      NIMETUS: "Rehatse küla",
      NIMETUS_LIIGIGA: "Rehatse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00006898",
    },
    {
      TASE: "3",
      KOOD: "6899",
      NIMETUS: "Reeküla",
      NIMETUS_LIIGIGA: "Reeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006899",
    },
    {
      TASE: "3",
      KOOD: "6902",
      NIMETUS: "Rehemetsa küla",
      NIMETUS_LIIGIGA: "Rehemetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006902",
    },
    {
      TASE: "3",
      KOOD: "6903",
      NIMETUS: "Reheselja küla",
      NIMETUS_LIIGIGA: "Reheselja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006903",
    },
    {
      TASE: "3",
      KOOD: "6908",
      NIMETUS: "Reigi küla",
      NIMETUS_LIIGIGA: "Reigi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006908",
    },
    {
      TASE: "3",
      KOOD: "6910",
      NIMETUS: "Reikama küla",
      NIMETUS_LIIGIGA: "Reikama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006910",
    },
    {
      TASE: "3",
      KOOD: "6913",
      NIMETUS: "Reina küla",
      NIMETUS_LIIGIGA: "Reina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006913",
    },
    {
      TASE: "3",
      KOOD: "6915",
      NIMETUS: "Reinevere küla",
      NIMETUS_LIIGIGA: "Reinevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00006915",
    },
    {
      TASE: "3",
      KOOD: "6918",
      NIMETUS: "Reinu küla",
      NIMETUS_LIIGIGA: "Reinu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00006918",
    },
    {
      TASE: "3",
      KOOD: "6919",
      NIMETUS: "Reinu küla",
      NIMETUS_LIIGIGA: "Reinu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00006919",
    },
    {
      TASE: "3",
      KOOD: "6921",
      NIMETUS: "Reinumurru küla",
      NIMETUS_LIIGIGA: "Reinumurru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00006921",
    },
    {
      TASE: "3",
      KOOD: "6927",
      NIMETUS: "Remniku küla",
      NIMETUS_LIIGIGA: "Remniku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00006927",
    },
    {
      TASE: "3",
      KOOD: "6930",
      NIMETUS: "Reo küla",
      NIMETUS_LIIGIGA: "Reo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006930",
    },
    {
      TASE: "3",
      KOOD: "6932",
      NIMETUS: "Reola küla",
      NIMETUS_LIIGIGA: "Reola küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00006932",
    },
    {
      TASE: "3",
      KOOD: "6935",
      NIMETUS: "Reolasoo küla",
      NIMETUS_LIIGIGA: "Reolasoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00006935",
    },
    {
      TASE: "3",
      KOOD: "6937",
      NIMETUS: "Reonda küla",
      NIMETUS_LIIGIGA: "Reonda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00006937",
    },
    {
      TASE: "3",
      KOOD: "6938",
      NIMETUS: "Resto küla",
      NIMETUS_LIIGIGA: "Resto küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006938",
    },
    {
      TASE: "3",
      KOOD: "6940",
      NIMETUS: "Reopalu küla",
      NIMETUS_LIIGIGA: "Reopalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00006940",
    },
    {
      TASE: "3",
      KOOD: "6943",
      NIMETUS: "Restu küla",
      NIMETUS_LIIGIGA: "Restu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00006943",
    },
    {
      TASE: "3",
      KOOD: "6946",
      NIMETUS: "Reti küla",
      NIMETUS_LIIGIGA: "Reti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00006946",
    },
    {
      TASE: "3",
      KOOD: "6948",
      NIMETUS: "Retla küla",
      NIMETUS_LIIGIGA: "Retla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00006948",
    },
    {
      TASE: "3",
      KOOD: "6953",
      NIMETUS: "Ridaküla",
      NIMETUS_LIIGIGA: "Ridaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00006953",
    },
    {
      TASE: "3",
      KOOD: "6954",
      NIMETUS: "Ridaküla",
      NIMETUS_LIIGIGA: "Ridaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00006954",
    },
    {
      TASE: "3",
      KOOD: "6955",
      NIMETUS: "Ridaküla",
      NIMETUS_LIIGIGA: "Ridaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006955",
    },
    {
      TASE: "3",
      KOOD: "6956",
      NIMETUS: "Ridaküla",
      NIMETUS_LIIGIGA: "Ridaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006956",
    },
    {
      TASE: "3",
      KOOD: "6959",
      NIMETUS: "Parila küla",
      NIMETUS_LIIGIGA: "Parila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00006959",
    },
    {
      TASE: "3",
      KOOD: "6960",
      NIMETUS: "Ridala küla",
      NIMETUS_LIIGIGA: "Ridala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006960",
    },
    {
      TASE: "3",
      KOOD: "6961",
      NIMETUS: "Ridase küla",
      NIMETUS_LIIGIGA: "Ridase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006961",
    },
    {
      TASE: "3",
      KOOD: "6962",
      NIMETUS: "Ridalepa küla",
      NIMETUS_LIIGIGA: "Ridalepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00006962",
    },
    {
      TASE: "3",
      KOOD: "6965",
      NIMETUS: "Ridasi küla",
      NIMETUS_LIIGIGA: "Ridasi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00006965",
    },
    {
      TASE: "3",
      KOOD: "6968",
      NIMETUS: "Riguldi küla / Rickul",
      NIMETUS_LIIGIGA: "Riguldi küla / Rickul",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00006968",
    },
    {
      TASE: "3",
      KOOD: "6971",
      NIMETUS: "Rihkama küla",
      NIMETUS_LIIGIGA: "Rihkama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006971",
    },
    {
      TASE: "3",
      KOOD: "6972",
      NIMETUS: "Riidaküla",
      NIMETUS_LIIGIGA: "Riidaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006972",
    },
    {
      TASE: "3",
      KOOD: "6974",
      NIMETUS: "Riiassaare küla",
      NIMETUS_LIIGIGA: "Riiassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00006974",
    },
    {
      TASE: "3",
      KOOD: "6976",
      NIMETUS: "Riidaja küla",
      NIMETUS_LIIGIGA: "Riidaja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00006976",
    },
    {
      TASE: "3",
      KOOD: "6979",
      NIMETUS: "Riidaku küla",
      NIMETUS_LIIGIGA: "Riidaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00006979",
    },
    {
      TASE: "3",
      KOOD: "6981",
      NIMETUS: "Riidamäe küla",
      NIMETUS_LIIGIGA: "Riidamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00006981",
    },
    {
      TASE: "3",
      KOOD: "6984",
      NIMETUS: "Riidma küla",
      NIMETUS_LIIGIGA: "Riidma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006984",
    },
    {
      TASE: "3",
      KOOD: "6985",
      NIMETUS: "Riihora küla",
      NIMETUS_LIIGIGA: "Riihora küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006985",
    },
    {
      TASE: "3",
      KOOD: "6986",
      NIMETUS: "Riisa küla",
      NIMETUS_LIIGIGA: "Riisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00006986",
    },
    {
      TASE: "3",
      KOOD: "6989",
      NIMETUS: "Riisipere alevik",
      NIMETUS_LIIGIGA: "Riisipere alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00006989",
    },
    {
      TASE: "3",
      KOOD: "6991",
      NIMETUS: "Riitsilla küla",
      NIMETUS_LIIGIGA: "Riitsilla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006991",
    },
    {
      TASE: "3",
      KOOD: "6994",
      NIMETUS: "Riiviku küla",
      NIMETUS_LIIGIGA: "Riiviku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00006994",
    },
    {
      TASE: "3",
      KOOD: "6995",
      NIMETUS: "Rikassaare küla",
      NIMETUS_LIIGIGA: "Rikassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00006995",
    },
    {
      TASE: "3",
      KOOD: "6997",
      NIMETUS: "Riksu küla",
      NIMETUS_LIIGIGA: "Riksu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006997",
    },
    {
      TASE: "3",
      KOOD: "7000",
      NIMETUS: "Rimmi küla",
      NIMETUS_LIIGIGA: "Rimmi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00007000",
    },
    {
      TASE: "3",
      KOOD: "7002",
      NIMETUS: "Rimmu küla",
      NIMETUS_LIIGIGA: "Rimmu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00007002",
    },
    {
      TASE: "3",
      KOOD: "7003",
      NIMETUS: "Ringuta küla",
      NIMETUS_LIIGIGA: "Ringuta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00007003",
    },
    {
      TASE: "3",
      KOOD: "7005",
      NIMETUS: "Ringiste küla",
      NIMETUS_LIIGIGA: "Ringiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00007005",
    },
    {
      TASE: "3",
      KOOD: "7006",
      NIMETUS: "Rinsi küla",
      NIMETUS_LIIGIGA: "Rinsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00007006",
    },
    {
      TASE: "3",
      KOOD: "7008",
      NIMETUS: "Ristemäe küla",
      NIMETUS_LIIGIGA: "Ristemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007008",
    },
    {
      TASE: "3",
      KOOD: "7009",
      NIMETUS: "Risti küla",
      NIMETUS_LIIGIGA: "Risti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007009",
    },
    {
      TASE: "3",
      KOOD: "7011",
      NIMETUS: "Risti alevik",
      NIMETUS_LIIGIGA: "Risti alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007011",
    },
    {
      TASE: "3",
      KOOD: "7012",
      NIMETUS: "Ristiküla",
      NIMETUS_LIIGIGA: "Ristiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00007012",
    },
    {
      TASE: "3",
      KOOD: "7013",
      NIMETUS: "Ristiküla",
      NIMETUS_LIIGIGA: "Ristiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00007013",
    },
    {
      TASE: "3",
      KOOD: "7014",
      NIMETUS: "Ristivälja küla",
      NIMETUS_LIIGIGA: "Ristivälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007014",
    },
    {
      TASE: "3",
      KOOD: "7015",
      NIMETUS: "Risu-Suurküla",
      NIMETUS_LIIGIGA: "Risu-Suurküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00007015",
    },
    {
      TASE: "3",
      KOOD: "7016",
      NIMETUS: "Ristipalo küla",
      NIMETUS_LIIGIGA: "Ristipalo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00007016",
    },
    {
      TASE: "3",
      KOOD: "7018",
      NIMETUS: "Risttee küla",
      NIMETUS_LIIGIGA: "Risttee küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00007018",
    },
    {
      TASE: "3",
      KOOD: "7021",
      NIMETUS: "Ritsiko küla",
      NIMETUS_LIIGIGA: "Ritsiko küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007021",
    },
    {
      TASE: "3",
      KOOD: "7024",
      NIMETUS: "Riuma küla",
      NIMETUS_LIIGIGA: "Riuma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00007024",
    },
    {
      TASE: "3",
      KOOD: "7028",
      NIMETUS: "Roela alevik",
      NIMETUS_LIIGIGA: "Roela alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00007028",
    },
    {
      TASE: "3",
      KOOD: "7029",
      NIMETUS: "Rohense küla",
      NIMETUS_LIIGIGA: "Rohense küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00007029",
    },
    {
      TASE: "3",
      KOOD: "7031",
      NIMETUS: "Rohe küla",
      NIMETUS_LIIGIGA: "Rohe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00007031",
    },
    {
      TASE: "3",
      KOOD: "7034",
      NIMETUS: "Rohu küla",
      NIMETUS_LIIGIGA: "Rohu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00007034",
    },
    {
      TASE: "3",
      KOOD: "7036",
      NIMETUS: "Rohuküla",
      NIMETUS_LIIGIGA: "Rohuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00007036",
    },
    {
      TASE: "3",
      KOOD: "7037",
      NIMETUS: "Rohusi küla",
      NIMETUS_LIIGIGA: "Rohusi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00007037",
    },
    {
      TASE: "3",
      KOOD: "7039",
      NIMETUS: "Rohuneeme küla",
      NIMETUS_LIIGIGA: "Rohuneeme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00007039",
    },
    {
      TASE: "3",
      KOOD: "7042",
      NIMETUS: "Roiu alevik",
      NIMETUS_LIIGIGA: "Roiu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00007042",
    },
    {
      TASE: "3",
      KOOD: "7043",
      NIMETUS: "Ronisoo küla",
      NIMETUS_LIIGIGA: "Ronisoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007043",
    },
    {
      TASE: "3",
      KOOD: "7045",
      NIMETUS: "Rokina küla",
      NIMETUS_LIIGIGA: "Rokina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007045",
    },
    {
      TASE: "3",
      KOOD: "7048",
      NIMETUS: "Ronivere küla",
      NIMETUS_LIIGIGA: "Ronivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00007048",
    },
    {
      TASE: "3",
      KOOD: "7051",
      NIMETUS: "Roobaka küla",
      NIMETUS_LIIGIGA: "Roobaka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007051",
    },
    {
      TASE: "3",
      KOOD: "7052",
      NIMETUS: "Roobi küla",
      NIMETUS_LIIGIGA: "Roobi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007052",
    },
    {
      TASE: "3",
      KOOD: "7053",
      NIMETUS: "Roobe küla",
      NIMETUS_LIIGIGA: "Roobe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00007053",
    },
    {
      TASE: "3",
      KOOD: "7056",
      NIMETUS: "Roobuka küla",
      NIMETUS_LIIGIGA: "Roobuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00007056",
    },
    {
      TASE: "3",
      KOOD: "7058",
      NIMETUS: "Roodevälja küla",
      NIMETUS_LIIGIGA: "Roodevälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00007058",
    },
    {
      TASE: "3",
      KOOD: "7061",
      NIMETUS: "Roodi küla",
      NIMETUS_LIIGIGA: "Roodi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00007061",
    },
    {
      TASE: "3",
      KOOD: "7063",
      NIMETUS: "Roodu küla",
      NIMETUS_LIIGIGA: "Roodu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00007063",
    },
    {
      TASE: "3",
      KOOD: "7066",
      NIMETUS: "Rookse küla",
      NIMETUS_LIIGIGA: "Rookse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00007066",
    },
    {
      TASE: "3",
      KOOD: "7068",
      NIMETUS: "Rooküla",
      NIMETUS_LIIGIGA: "Rooküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00007068",
    },
    {
      TASE: "3",
      KOOD: "7071",
      NIMETUS: "Roosi küla",
      NIMETUS_LIIGIGA: "Roosi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00007071",
    },
    {
      TASE: "3",
      KOOD: "7076",
      NIMETUS: "Roosilla küla",
      NIMETUS_LIIGIGA: "Roosilla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00007076",
    },
    {
      TASE: "3",
      KOOD: "7077",
      NIMETUS: "Rooslepa küla / Roslep",
      NIMETUS_LIIGIGA: "Rooslepa küla / Roslep",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007077",
    },
    {
      TASE: "3",
      KOOD: "7078",
      NIMETUS: "Roosisaare küla",
      NIMETUS_LIIGIGA: "Roosisaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007078",
    },
    {
      TASE: "3",
      KOOD: "7081",
      NIMETUS: "Roosna küla",
      NIMETUS_LIIGIGA: "Roosna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00007081",
    },
    {
      TASE: "3",
      KOOD: "7083",
      NIMETUS: "Roosna-Alliku alevik",
      NIMETUS_LIIGIGA: "Roosna-Alliku alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00007083",
    },
    {
      TASE: "3",
      KOOD: "7084",
      NIMETUS: "Rootsi küla",
      NIMETUS_LIIGIGA: "Rootsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007084",
    },
    {
      TASE: "3",
      KOOD: "7085",
      NIMETUS: "Rootsi küla",
      NIMETUS_LIIGIGA: "Rootsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00007085",
    },
    {
      TASE: "3",
      KOOD: "7086",
      NIMETUS: "Roostoja küla",
      NIMETUS_LIIGIGA: "Roostoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00007086",
    },
    {
      TASE: "3",
      KOOD: "7088",
      NIMETUS: "Rootsiküla",
      NIMETUS_LIIGIGA: "Rootsiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007088",
    },
    {
      TASE: "3",
      KOOD: "7089",
      NIMETUS: "Rootsiküla",
      NIMETUS_LIIGIGA: "Rootsiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "303",
      ADS_OID: "AY00007089",
    },
    {
      TASE: "3",
      KOOD: "7090",
      NIMETUS: "Rootsiküla",
      NIMETUS_LIIGIGA: "Rootsiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007090",
    },
    {
      TASE: "3",
      KOOD: "7093",
      NIMETUS: "Rootsivere küla",
      NIMETUS_LIIGIGA: "Rootsivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00007093",
    },
    {
      TASE: "3",
      KOOD: "7095",
      NIMETUS: "Roovere küla",
      NIMETUS_LIIGIGA: "Roovere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00007095",
    },
    {
      TASE: "3",
      KOOD: "7098",
      NIMETUS: "Rosma küla",
      NIMETUS_LIIGIGA: "Rosma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00007098",
    },
    {
      TASE: "3",
      KOOD: "7102",
      NIMETUS: "Ruhingu küla",
      NIMETUS_LIIGIGA: "Ruhingu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00007102",
    },
    {
      TASE: "3",
      KOOD: "7105",
      NIMETUS: "Ruhnu küla",
      NIMETUS_LIIGIGA: "Ruhnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "689",
      ADS_OID: "AY00007105",
    },
    {
      TASE: "3",
      KOOD: "7107",
      NIMETUS: "Ruhve küla",
      NIMETUS_LIIGIGA: "Ruhve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007107",
    },
    {
      TASE: "3",
      KOOD: "7108",
      NIMETUS: "Rukkiküla",
      NIMETUS_LIIGIGA: "Rukkiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00007108",
    },
    {
      TASE: "3",
      KOOD: "7110",
      NIMETUS: "Ruila küla",
      NIMETUS_LIIGIGA: "Ruila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00007110",
    },
    {
      TASE: "3",
      KOOD: "7113",
      NIMETUS: "Rulli küla",
      NIMETUS_LIIGIGA: "Rulli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00007113",
    },
    {
      TASE: "3",
      KOOD: "7119",
      NIMETUS: "Rummi küla",
      NIMETUS_LIIGIGA: "Rummi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007119",
    },
    {
      TASE: "3",
      KOOD: "7120",
      NIMETUS: "Rummu küla",
      NIMETUS_LIIGIGA: "Rummu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00007120",
    },
    {
      TASE: "3",
      KOOD: "7121",
      NIMETUS: "Rummu alevik",
      NIMETUS_LIIGIGA: "Rummu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00007121",
    },
    {
      TASE: "3",
      KOOD: "7122",
      NIMETUS: "Rummu küla",
      NIMETUS_LIIGIGA: "Rummu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00007122",
    },
    {
      TASE: "3",
      KOOD: "7124",
      NIMETUS: "Rumpo küla",
      NIMETUS_LIIGIGA: "Rumpo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00007124",
    },
    {
      TASE: "3",
      KOOD: "7125",
      NIMETUS: "Rusa küla",
      NIMETUS_LIIGIGA: "Rusa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007125",
    },
    {
      TASE: "3",
      KOOD: "7127",
      NIMETUS: "Rupsi küla",
      NIMETUS_LIIGIGA: "Rupsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007127",
    },
    {
      TASE: "3",
      KOOD: "7128",
      NIMETUS: "Rusima küla",
      NIMETUS_LIIGIGA: "Rusima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007128",
    },
    {
      TASE: "3",
      KOOD: "7130",
      NIMETUS: "Ruskavere küla",
      NIMETUS_LIIGIGA: "Ruskavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00007130",
    },
    {
      TASE: "3",
      KOOD: "7132",
      NIMETUS: "Russalu küla",
      NIMETUS_LIIGIGA: "Russalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00007132",
    },
    {
      TASE: "3",
      KOOD: "7135",
      NIMETUS: "Rutikvere küla",
      NIMETUS_LIIGIGA: "Rutikvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00007135",
    },
    {
      TASE: "3",
      KOOD: "7138",
      NIMETUS: "Rutja küla",
      NIMETUS_LIIGIGA: "Rutja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00007138",
    },
    {
      TASE: "3",
      KOOD: "7141",
      NIMETUS: "Ruu küla",
      NIMETUS_LIIGIGA: "Ruu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00007141",
    },
    {
      TASE: "3",
      KOOD: "7143",
      NIMETUS: "Ruudiküla",
      NIMETUS_LIIGIGA: "Ruudiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00007143",
    },
    {
      TASE: "3",
      KOOD: "7144",
      NIMETUS: "Ruuksu küla",
      NIMETUS_LIIGIGA: "Ruuksu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007144",
    },
    {
      TASE: "3",
      KOOD: "7146",
      NIMETUS: "Suurõ-Ruuga küla",
      NIMETUS_LIIGIGA: "Suurõ-Ruuga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007146",
    },
    {
      TASE: "3",
      KOOD: "7148",
      NIMETUS: "Ruuna küla",
      NIMETUS_LIIGIGA: "Ruuna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00007148",
    },
    {
      TASE: "3",
      KOOD: "7151",
      NIMETUS: "Ruusa küla",
      NIMETUS_LIIGIGA: "Ruusa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00007151",
    },
    {
      TASE: "3",
      KOOD: "7152",
      NIMETUS: "Ruutsi küla",
      NIMETUS_LIIGIGA: "Ruutsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007152",
    },
    {
      TASE: "3",
      KOOD: "7153",
      NIMETUS: "Ruusmäe küla",
      NIMETUS_LIIGIGA: "Ruusmäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007153",
    },
    {
      TASE: "3",
      KOOD: "7157",
      NIMETUS: "Rõhu küla",
      NIMETUS_LIIGIGA: "Rõhu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00007157",
    },
    {
      TASE: "3",
      KOOD: "7158",
      NIMETUS: "Rõhu küla",
      NIMETUS_LIIGIGA: "Rõhu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "793",
      ADS_OID: "AY00007158",
    },
    {
      TASE: "3",
      KOOD: "7161",
      NIMETUS: "Rõka küla",
      NIMETUS_LIIGIGA: "Rõka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00007161",
    },
    {
      TASE: "3",
      KOOD: "7164",
      NIMETUS: "Rõmeda küla",
      NIMETUS_LIIGIGA: "Rõmeda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00007164",
    },
    {
      TASE: "3",
      KOOD: "7167",
      NIMETUS: "Rõngu alevik",
      NIMETUS_LIIGIGA: "Rõngu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00007167",
    },
    {
      TASE: "3",
      KOOD: "7170",
      NIMETUS: "Rõsna küla",
      NIMETUS_LIIGIGA: "Rõsna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007170",
    },
    {
      TASE: "3",
      KOOD: "7175",
      NIMETUS: "Rõstla küla",
      NIMETUS_LIIGIGA: "Rõstla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00007175",
    },
    {
      TASE: "3",
      KOOD: "7176",
      NIMETUS: "Rõue küla",
      NIMETUS_LIIGIGA: "Rõue küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00007176",
    },
    {
      TASE: "3",
      KOOD: "7178",
      NIMETUS: "Rõude küla",
      NIMETUS_LIIGIGA: "Rõude küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007178",
    },
    {
      TASE: "3",
      KOOD: "7181",
      NIMETUS: "Rõuge alevik",
      NIMETUS_LIIGIGA: "Rõuge alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007181",
    },
    {
      TASE: "3",
      KOOD: "7183",
      NIMETUS: "Rõuma küla",
      NIMETUS_LIIGIGA: "Rõuma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007183",
    },
    {
      TASE: "3",
      KOOD: "7186",
      NIMETUS: "Rõusa küla",
      NIMETUS_LIIGIGA: "Rõusa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00007186",
    },
    {
      TASE: "3",
      KOOD: "7189",
      NIMETUS: "Rõõmu küla",
      NIMETUS_LIIGIGA: "Rõõmu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00007189",
    },
    {
      TASE: "3",
      KOOD: "7191",
      NIMETUS: "Rõõsa küla",
      NIMETUS_LIIGIGA: "Rõõsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00007191",
    },
    {
      TASE: "3",
      KOOD: "7195",
      NIMETUS: "Räbi küla",
      NIMETUS_LIIGIGA: "Räbi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00007195",
    },
    {
      TASE: "3",
      KOOD: "7198",
      NIMETUS: "Rägavere küla",
      NIMETUS_LIIGIGA: "Rägavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00007198",
    },
    {
      TASE: "3",
      KOOD: "7199",
      NIMETUS: "Rägavere küla",
      NIMETUS_LIIGIGA: "Rägavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00007199",
    },
    {
      TASE: "3",
      KOOD: "7200",
      NIMETUS: "Räimaste küla",
      NIMETUS_LIIGIGA: "Räimaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007200",
    },
    {
      TASE: "3",
      KOOD: "7202",
      NIMETUS: "Räitsvere küla",
      NIMETUS_LIIGIGA: "Räitsvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00007202",
    },
    {
      TASE: "3",
      KOOD: "7205",
      NIMETUS: "Rälby küla",
      NIMETUS_LIIGIGA: "Rälby küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00007205",
    },
    {
      TASE: "3",
      KOOD: "7208",
      NIMETUS: "Rämsi küla",
      NIMETUS_LIIGIGA: "Rämsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00007208",
    },
    {
      TASE: "3",
      KOOD: "7211",
      NIMETUS: "Mäeküla",
      NIMETUS_LIIGIGA: "Mäeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00007211",
    },
    {
      TASE: "3",
      KOOD: "7214",
      NIMETUS: "Räni alevik",
      NIMETUS_LIIGIGA: "Räni alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00007214",
    },
    {
      TASE: "3",
      KOOD: "7216",
      NIMETUS: "Räpina linn",
      NIMETUS_LIIGIGA: "Räpina linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00007216",
    },
    {
      TASE: "3",
      KOOD: "7217",
      NIMETUS: "Räpo küla",
      NIMETUS_LIIGIGA: "Räpo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007217",
    },
    {
      TASE: "3",
      KOOD: "7220",
      NIMETUS: "Räsna küla",
      NIMETUS_LIIGIGA: "Räsna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00007220",
    },
    {
      TASE: "3",
      KOOD: "7221",
      NIMETUS: "Räsna küla",
      NIMETUS_LIIGIGA: "Räsna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00007221",
    },
    {
      TASE: "3",
      KOOD: "7223",
      NIMETUS: "Rässa küla",
      NIMETUS_LIIGIGA: "Rässa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00007223",
    },
    {
      TASE: "3",
      KOOD: "7226",
      NIMETUS: "Rätla küla",
      NIMETUS_LIIGIGA: "Rätla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00007226",
    },
    {
      TASE: "3",
      KOOD: "7229",
      NIMETUS: "Rätsepa küla",
      NIMETUS_LIIGIGA: "Rätsepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00007229",
    },
    {
      TASE: "3",
      KOOD: "7232",
      NIMETUS: "Rääbise küla",
      NIMETUS_LIIGIGA: "Rääbise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00007232",
    },
    {
      TASE: "3",
      KOOD: "7234",
      NIMETUS: "Räägi küla",
      NIMETUS_LIIGIGA: "Räägi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007234",
    },
    {
      TASE: "3",
      KOOD: "7237",
      NIMETUS: "Räägu küla",
      NIMETUS_LIIGIGA: "Räägu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00007237",
    },
    {
      TASE: "3",
      KOOD: "7238",
      NIMETUS: "Räägu küla",
      NIMETUS_LIIGIGA: "Räägu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00007238",
    },
    {
      TASE: "3",
      KOOD: "7240",
      NIMETUS: "Rääka küla",
      NIMETUS_LIIGIGA: "Rääka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00007240",
    },
    {
      TASE: "3",
      KOOD: "7243",
      NIMETUS: "Rääptsova küla",
      NIMETUS_LIIGIGA: "Rääptsova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007243",
    },
    {
      TASE: "3",
      KOOD: "7245",
      NIMETUS: "Rääsa küla",
      NIMETUS_LIIGIGA: "Rääsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00007245",
    },
    {
      TASE: "3",
      KOOD: "7248",
      NIMETUS: "Rääski küla",
      NIMETUS_LIIGIGA: "Rääski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00007248",
    },
    {
      TASE: "3",
      KOOD: "7250",
      NIMETUS: "Rääsolaane küla",
      NIMETUS_LIIGIGA: "Rääsolaane küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007250",
    },
    {
      TASE: "3",
      KOOD: "7254",
      NIMETUS: "Röa küla",
      NIMETUS_LIIGIGA: "Röa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00007254",
    },
    {
      TASE: "3",
      KOOD: "7255",
      NIMETUS: "Röa küla",
      NIMETUS_LIIGIGA: "Röa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00007255",
    },
    {
      TASE: "3",
      KOOD: "7258",
      NIMETUS: "Röösa küla",
      NIMETUS_LIIGIGA: "Röösa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007258",
    },
    {
      TASE: "3",
      KOOD: "7259",
      NIMETUS: "Rädi küla",
      NIMETUS_LIIGIGA: "Rädi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00007259",
    },
    {
      TASE: "3",
      KOOD: "7262",
      NIMETUS: "Rünga küla",
      NIMETUS_LIIGIGA: "Rünga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00007262",
    },
    {
      TASE: "3",
      KOOD: "7265",
      NIMETUS: "Rütavere küla",
      NIMETUS_LIIGIGA: "Rütavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00007265",
    },
    {
      TASE: "3",
      KOOD: "7270",
      NIMETUS: "Saabolda küla",
      NIMETUS_LIIGIGA: "Saabolda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007270",
    },
    {
      TASE: "3",
      KOOD: "7271",
      NIMETUS: "Saagri küla",
      NIMETUS_LIIGIGA: "Saagri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007271",
    },
    {
      TASE: "3",
      KOOD: "7272",
      NIMETUS: "Saagrimäe küla",
      NIMETUS_LIIGIGA: "Saagrimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007272",
    },
    {
      TASE: "3",
      KOOD: "7273",
      NIMETUS: "Saadjärve küla",
      NIMETUS_LIIGIGA: "Saadjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00007273",
    },
    {
      TASE: "3",
      KOOD: "7274",
      NIMETUS: "Saagri küla",
      NIMETUS_LIIGIGA: "Saagri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007274",
    },
    {
      TASE: "3",
      KOOD: "7275",
      NIMETUS: "Saanika küla",
      NIMETUS_LIIGIGA: "Saanika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00007275",
    },
    {
      TASE: "3",
      KOOD: "7278",
      NIMETUS: "Saara küla",
      NIMETUS_LIIGIGA: "Saara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00007278",
    },
    {
      TASE: "3",
      KOOD: "7280",
      NIMETUS: "Saarde küla",
      NIMETUS_LIIGIGA: "Saarde küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00007280",
    },
    {
      TASE: "3",
      KOOD: "7281",
      NIMETUS: "Saarde küla",
      NIMETUS_LIIGIGA: "Saarde küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007281",
    },
    {
      TASE: "3",
      KOOD: "7282",
      NIMETUS: "Saare küla",
      NIMETUS_LIIGIGA: "Saare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00007282",
    },
    {
      TASE: "3",
      KOOD: "7283",
      NIMETUS: "Saardu küla",
      NIMETUS_LIIGIGA: "Saardu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00007283",
    },
    {
      TASE: "3",
      KOOD: "7284",
      NIMETUS: "Saare küla / Lyckholm",
      NIMETUS_LIIGIGA: "Saare küla / Lyckholm",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007284",
    },
    {
      TASE: "3",
      KOOD: "7285",
      NIMETUS: "Kääpa küla",
      NIMETUS_LIIGIGA: "Kääpa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00007285",
    },
    {
      TASE: "3",
      KOOD: "7286",
      NIMETUS: "Saare küla",
      NIMETUS_LIIGIGA: "Saare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00007286",
    },
    {
      TASE: "3",
      KOOD: "7287",
      NIMETUS: "Saare küla",
      NIMETUS_LIIGIGA: "Saare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00007287",
    },
    {
      TASE: "3",
      KOOD: "7289",
      NIMETUS: "Saareküla",
      NIMETUS_LIIGIGA: "Saareküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00007289",
    },
    {
      TASE: "3",
      KOOD: "7290",
      NIMETUS: "Saareküla",
      NIMETUS_LIIGIGA: "Saareküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00007290",
    },
    {
      TASE: "3",
      KOOD: "7291",
      NIMETUS: "Saaremetsa küla",
      NIMETUS_LIIGIGA: "Saaremetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007291",
    },
    {
      TASE: "3",
      KOOD: "7292",
      NIMETUS: "Saaremaa küla",
      NIMETUS_LIIGIGA: "Saaremaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007292",
    },
    {
      TASE: "3",
      KOOD: "7293",
      NIMETUS: "Saareotsa küla",
      NIMETUS_LIIGIGA: "Saareotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00007293",
    },
    {
      TASE: "3",
      KOOD: "7295",
      NIMETUS: "Saarepeedi küla",
      NIMETUS_LIIGIGA: "Saarepeedi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00007295",
    },
    {
      TASE: "3",
      KOOD: "7298",
      NIMETUS: "Saarepõllu küla",
      NIMETUS_LIIGIGA: "Saarepõllu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00007298",
    },
    {
      TASE: "3",
      KOOD: "7300",
      NIMETUS: "Saari küla",
      NIMETUS_LIIGIGA: "Saari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00007300",
    },
    {
      TASE: "3",
      KOOD: "7303",
      NIMETUS: "Saarjärve küla",
      NIMETUS_LIIGIGA: "Saarjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00007303",
    },
    {
      TASE: "3",
      KOOD: "7305",
      NIMETUS: "Saarlasõ küla",
      NIMETUS_LIIGIGA: "Saarlasõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007305",
    },
    {
      TASE: "3",
      KOOD: "7308",
      NIMETUS: "Saarnakõrve küla",
      NIMETUS_LIIGIGA: "Saarnakõrve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00007308",
    },
    {
      TASE: "3",
      KOOD: "7310",
      NIMETUS: "Saastna küla",
      NIMETUS_LIIGIGA: "Saastna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00007310",
    },
    {
      TASE: "3",
      KOOD: "7313",
      NIMETUS: "Saate küla",
      NIMETUS_LIIGIGA: "Saate küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00007313",
    },
    {
      TASE: "3",
      KOOD: "7314",
      NIMETUS: "Saburi küla",
      NIMETUS_LIIGIGA: "Saburi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007314",
    },
    {
      TASE: "3",
      KOOD: "7315",
      NIMETUS: "Saatse küla",
      NIMETUS_LIIGIGA: "Saatse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007315",
    },
    {
      TASE: "3",
      KOOD: "7318",
      NIMETUS: "Sadala alevik",
      NIMETUS_LIIGIGA: "Sadala alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00007318",
    },
    {
      TASE: "3",
      KOOD: "7321",
      NIMETUS: "Sadramõtsa küla",
      NIMETUS_LIIGIGA: "Sadramõtsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007321",
    },
    {
      TASE: "3",
      KOOD: "7323",
      NIMETUS: "Saduküla",
      NIMETUS_LIIGIGA: "Saduküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00007323",
    },
    {
      TASE: "3",
      KOOD: "7324",
      NIMETUS: "Sae küla",
      NIMETUS_LIIGIGA: "Sae küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00007324",
    },
    {
      TASE: "3",
      KOOD: "7325",
      NIMETUS: "Sae küla",
      NIMETUS_LIIGIGA: "Sae küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00007325",
    },
    {
      TASE: "3",
      KOOD: "7326",
      NIMETUS: "Tamme küla",
      NIMETUS_LIIGIGA: "Tamme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007326",
    },
    {
      TASE: "3",
      KOOD: "7329",
      NIMETUS: "Sagadi küla",
      NIMETUS_LIIGIGA: "Sagadi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00007329",
    },
    {
      TASE: "3",
      KOOD: "7330",
      NIMETUS: "Sagariste küla",
      NIMETUS_LIIGIGA: "Sagariste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007330",
    },
    {
      TASE: "3",
      KOOD: "7332",
      NIMETUS: "Sagevere küla",
      NIMETUS_LIIGIGA: "Sagevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00007332",
    },
    {
      TASE: "3",
      KOOD: "7335",
      NIMETUS: "Saha küla",
      NIMETUS_LIIGIGA: "Saha küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00007335",
    },
    {
      TASE: "3",
      KOOD: "7337",
      NIMETUS: "Sahargu küla",
      NIMETUS_LIIGIGA: "Sahargu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00007337",
    },
    {
      TASE: "3",
      KOOD: "7340",
      NIMETUS: "Saia küla",
      NIMETUS_LIIGIGA: "Saia küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007340",
    },
    {
      TASE: "3",
      KOOD: "7341",
      NIMETUS: "Saika küla",
      NIMETUS_LIIGIGA: "Saika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007341",
    },
    {
      TASE: "3",
      KOOD: "7343",
      NIMETUS: "Saiakopli küla",
      NIMETUS_LIIGIGA: "Saiakopli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00007343",
    },
    {
      TASE: "3",
      KOOD: "7345",
      NIMETUS: "Saikla küla",
      NIMETUS_LIIGIGA: "Saikla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007345",
    },
    {
      TASE: "3",
      KOOD: "7346",
      NIMETUS: "Saki küla",
      NIMETUS_LIIGIGA: "Saki küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007346",
    },
    {
      TASE: "3",
      KOOD: "7348",
      NIMETUS: "Saka küla",
      NIMETUS_LIIGIGA: "Saka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00007348",
    },
    {
      TASE: "3",
      KOOD: "7349",
      NIMETUS: "Sakla küla",
      NIMETUS_LIIGIGA: "Sakla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007349",
    },
    {
      TASE: "3",
      KOOD: "7351",
      NIMETUS: "Sakla küla",
      NIMETUS_LIIGIGA: "Sakla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007351",
    },
    {
      TASE: "3",
      KOOD: "7353",
      NIMETUS: "Saksa küla",
      NIMETUS_LIIGIGA: "Saksa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00007353",
    },
    {
      TASE: "3",
      KOOD: "7356",
      NIMETUS: "Saksaküla",
      NIMETUS_LIIGIGA: "Saksaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00007356",
    },
    {
      TASE: "3",
      KOOD: "7358",
      NIMETUS: "Saksi küla",
      NIMETUS_LIIGIGA: "Saksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00007358",
    },
    {
      TASE: "3",
      KOOD: "7361",
      NIMETUS: "Saku alevik",
      NIMETUS_LIIGIGA: "Saku alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00007361",
    },
    {
      TASE: "3",
      KOOD: "7363",
      NIMETUS: "Sakurgi küla",
      NIMETUS_LIIGIGA: "Sakurgi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007363",
    },
    {
      TASE: "3",
      KOOD: "7364",
      NIMETUS: "Sakudi küla",
      NIMETUS_LIIGIGA: "Sakudi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007364",
    },
    {
      TASE: "3",
      KOOD: "7366",
      NIMETUS: "Sakussaare küla",
      NIMETUS_LIIGIGA: "Sakussaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00007366",
    },
    {
      TASE: "3",
      KOOD: "7369",
      NIMETUS: "Salajõe küla",
      NIMETUS_LIIGIGA: "Salajõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007369",
    },
    {
      TASE: "3",
      KOOD: "7371",
      NIMETUS: "Salaküla",
      NIMETUS_LIIGIGA: "Salaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00007371",
    },
    {
      TASE: "3",
      KOOD: "7374",
      NIMETUS: "Salatse küla",
      NIMETUS_LIIGIGA: "Salatse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00007374",
    },
    {
      TASE: "3",
      KOOD: "7376",
      NIMETUS: "Salda küla",
      NIMETUS_LIIGIGA: "Salda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00007376",
    },
    {
      TASE: "3",
      KOOD: "7379",
      NIMETUS: "Salevere küla",
      NIMETUS_LIIGIGA: "Salevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00007379",
    },
    {
      TASE: "3",
      KOOD: "7382",
      NIMETUS: "Salinõmme küla",
      NIMETUS_LIIGIGA: "Salinõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007382",
    },
    {
      TASE: "3",
      KOOD: "7385",
      NIMETUS: "Salla küla",
      NIMETUS_LIIGIGA: "Salla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00007385",
    },
    {
      TASE: "3",
      KOOD: "7387",
      NIMETUS: "Salme alevik",
      NIMETUS_LIIGIGA: "Salme alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007387",
    },
    {
      TASE: "3",
      KOOD: "7390",
      NIMETUS: "Salmistu küla",
      NIMETUS_LIIGIGA: "Salmistu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00007390",
    },
    {
      TASE: "3",
      KOOD: "7391",
      NIMETUS: "Salu küla",
      NIMETUS_LIIGIGA: "Salu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00007391",
    },
    {
      TASE: "3",
      KOOD: "7392",
      NIMETUS: "Salu küla",
      NIMETUS_LIIGIGA: "Salu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00007392",
    },
    {
      TASE: "3",
      KOOD: "7393",
      NIMETUS: "Salu küla",
      NIMETUS_LIIGIGA: "Salu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00007393",
    },
    {
      TASE: "3",
      KOOD: "7396",
      NIMETUS: "Salumetsa küla",
      NIMETUS_LIIGIGA: "Salumetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00007396",
    },
    {
      TASE: "3",
      KOOD: "7397",
      NIMETUS: "Saluora küla",
      NIMETUS_LIIGIGA: "Saluora küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007397",
    },
    {
      TASE: "3",
      KOOD: "7398",
      NIMETUS: "Salumäe küla",
      NIMETUS_LIIGIGA: "Salumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00007398",
    },
    {
      TASE: "3",
      KOOD: "7399",
      NIMETUS: "Salutaguse küla",
      NIMETUS_LIIGIGA: "Salutaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00007399",
    },
    {
      TASE: "3",
      KOOD: "7401",
      NIMETUS: "Salutaguse küla",
      NIMETUS_LIIGIGA: "Salutaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00007401",
    },
    {
      TASE: "3",
      KOOD: "7402",
      NIMETUS: "Salutaguse küla",
      NIMETUS_LIIGIGA: "Salutaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00007402",
    },
    {
      TASE: "3",
      KOOD: "7403",
      NIMETUS: "Salu küla",
      NIMETUS_LIIGIGA: "Salu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007403",
    },
    {
      TASE: "3",
      KOOD: "7404",
      NIMETUS: "Samarina küla",
      NIMETUS_LIIGIGA: "Samarina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007404",
    },
    {
      TASE: "3",
      KOOD: "7405",
      NIMETUS: "Sambu küla",
      NIMETUS_LIIGIGA: "Sambu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00007405",
    },
    {
      TASE: "3",
      KOOD: "7406",
      NIMETUS: "Samliku küla",
      NIMETUS_LIIGIGA: "Samliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00007406",
    },
    {
      TASE: "3",
      KOOD: "7407",
      NIMETUS: "Samma küla",
      NIMETUS_LIIGIGA: "Samma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00007407",
    },
    {
      TASE: "3",
      KOOD: "7408",
      NIMETUS: "Santovi küla",
      NIMETUS_LIIGIGA: "Santovi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00007408",
    },
    {
      TASE: "3",
      KOOD: "7409",
      NIMETUS: "Sandisuu küla",
      NIMETUS_LIIGIGA: "Sandisuu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007409",
    },
    {
      TASE: "3",
      KOOD: "7410",
      NIMETUS: "Sammaste küla",
      NIMETUS_LIIGIGA: "Sammaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00007410",
    },
    {
      TASE: "3",
      KOOD: "7411",
      NIMETUS: "Sandi küla",
      NIMETUS_LIIGIGA: "Sandi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007411",
    },
    {
      TASE: "3",
      KOOD: "7415",
      NIMETUS: "Sandra küla",
      NIMETUS_LIIGIGA: "Sandra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00007415",
    },
    {
      TASE: "3",
      KOOD: "7418",
      NIMETUS: "Sangaste alevik",
      NIMETUS_LIIGIGA: "Sangaste alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00007418",
    },
    {
      TASE: "3",
      KOOD: "7419",
      NIMETUS: "Sapi küla",
      NIMETUS_LIIGIGA: "Sapi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007419",
    },
    {
      TASE: "3",
      KOOD: "7420",
      NIMETUS: "Sangla küla",
      NIMETUS_LIIGIGA: "Sangla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00007420",
    },
    {
      TASE: "3",
      KOOD: "7423",
      NIMETUS: "Sarakuste küla",
      NIMETUS_LIIGIGA: "Sarakuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00007423",
    },
    {
      TASE: "3",
      KOOD: "7426",
      NIMETUS: "Sarapuu küla",
      NIMETUS_LIIGIGA: "Sarapuu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00007426",
    },
    {
      TASE: "3",
      KOOD: "7428",
      NIMETUS: "Sargvere küla",
      NIMETUS_LIIGIGA: "Sargvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00007428",
    },
    {
      TASE: "3",
      KOOD: "7431",
      NIMETUS: "Sarise küla",
      NIMETUS_LIIGIGA: "Sarise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007431",
    },
    {
      TASE: "3",
      KOOD: "7433",
      NIMETUS: "Sarja küla",
      NIMETUS_LIIGIGA: "Sarja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00007433",
    },
    {
      TASE: "3",
      KOOD: "7436",
      NIMETUS: "Saru küla",
      NIMETUS_LIIGIGA: "Saru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007436",
    },
    {
      TASE: "3",
      KOOD: "7438",
      NIMETUS: "Sarve küla",
      NIMETUS_LIIGIGA: "Sarve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007438",
    },
    {
      TASE: "3",
      KOOD: "7439",
      NIMETUS: "Sarvemäe küla",
      NIMETUS_LIIGIGA: "Sarvemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00007439",
    },
    {
      TASE: "3",
      KOOD: "7441",
      NIMETUS: "Sassi küla",
      NIMETUS_LIIGIGA: "Sassi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00007441",
    },
    {
      TASE: "3",
      KOOD: "7444",
      NIMETUS: "Sassukvere küla",
      NIMETUS_LIIGIGA: "Sassukvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007444",
    },
    {
      TASE: "3",
      KOOD: "7447",
      NIMETUS: "Satsu küla",
      NIMETUS_LIIGIGA: "Satsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00007447",
    },
    {
      TASE: "3",
      KOOD: "7450",
      NIMETUS: "Sauaru küla",
      NIMETUS_LIIGIGA: "Sauaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007450",
    },
    {
      TASE: "3",
      KOOD: "7451",
      NIMETUS: "Saue-Putla küla",
      NIMETUS_LIIGIGA: "Saue-Putla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007451",
    },
    {
      TASE: "3",
      KOOD: "7452",
      NIMETUS: "Saueaugu küla",
      NIMETUS_LIIGIGA: "Saueaugu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00007452",
    },
    {
      TASE: "3",
      KOOD: "7455",
      NIMETUS: "Sauga alevik",
      NIMETUS_LIIGIGA: "Sauga alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00007455",
    },
    {
      TASE: "3",
      KOOD: "7456",
      NIMETUS: "Saukse küla",
      NIMETUS_LIIGIGA: "Saukse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00007456",
    },
    {
      TASE: "3",
      KOOD: "7457",
      NIMETUS: "Saula küla",
      NIMETUS_LIIGIGA: "Saula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00007457",
    },
    {
      TASE: "3",
      KOOD: "7460",
      NIMETUS: "Saulepa küla",
      NIMETUS_LIIGIGA: "Saulepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00007460",
    },
    {
      TASE: "3",
      KOOD: "7461",
      NIMETUS: "Saunaküla",
      NIMETUS_LIIGIGA: "Saunaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00007461",
    },
    {
      TASE: "3",
      KOOD: "7462",
      NIMETUS: "Saulepi küla",
      NIMETUS_LIIGIGA: "Saulepi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00007462",
    },
    {
      TASE: "3",
      KOOD: "7463",
      NIMETUS: "Saunametsa küla",
      NIMETUS_LIIGIGA: "Saunametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00007463",
    },
    {
      TASE: "3",
      KOOD: "7465",
      NIMETUS: "Saunja küla",
      NIMETUS_LIIGIGA: "Saunja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007465",
    },
    {
      TASE: "3",
      KOOD: "7466",
      NIMETUS: "Saunja küla",
      NIMETUS_LIIGIGA: "Saunja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00007466",
    },
    {
      TASE: "3",
      KOOD: "7468",
      NIMETUS: "Sauste küla",
      NIMETUS_LIIGIGA: "Sauste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00007468",
    },
    {
      TASE: "3",
      KOOD: "7473",
      NIMETUS: "Sauvere küla",
      NIMETUS_LIIGIGA: "Sauvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007473",
    },
    {
      TASE: "3",
      KOOD: "7476",
      NIMETUS: "Sauvälja küla",
      NIMETUS_LIIGIGA: "Sauvälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00007476",
    },
    {
      TASE: "3",
      KOOD: "7477",
      NIMETUS: "Savala küla",
      NIMETUS_LIIGIGA: "Savala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00007477",
    },
    {
      TASE: "3",
      KOOD: "7479",
      NIMETUS: "Sava küla",
      NIMETUS_LIIGIGA: "Sava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00007479",
    },
    {
      TASE: "3",
      KOOD: "7481",
      NIMETUS: "Savalduma küla",
      NIMETUS_LIIGIGA: "Savalduma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00007481",
    },
    {
      TASE: "3",
      KOOD: "7484",
      NIMETUS: "Savastvere küla",
      NIMETUS_LIIGIGA: "Savastvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007484",
    },
    {
      TASE: "3",
      KOOD: "7486",
      NIMETUS: "Saverna küla",
      NIMETUS_LIIGIGA: "Saverna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00007486",
    },
    {
      TASE: "3",
      KOOD: "7489",
      NIMETUS: "Saviaugu küla",
      NIMETUS_LIIGIGA: "Saviaugu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00007489",
    },
    {
      TASE: "3",
      KOOD: "7491",
      NIMETUS: "Savikoja küla",
      NIMETUS_LIIGIGA: "Savikoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00007491",
    },
    {
      TASE: "3",
      KOOD: "7494",
      NIMETUS: "Savikoti küla",
      NIMETUS_LIIGIGA: "Savikoti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00007494",
    },
    {
      TASE: "3",
      KOOD: "7496",
      NIMETUS: "Savilöövi küla",
      NIMETUS_LIIGIGA: "Savilöövi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00007496",
    },
    {
      TASE: "3",
      KOOD: "7499",
      NIMETUS: "Savimetsa küla",
      NIMETUS_LIIGIGA: "Savimetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007499",
    },
    {
      TASE: "3",
      KOOD: "7500",
      NIMETUS: "Savka küla",
      NIMETUS_LIIGIGA: "Savka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007500",
    },
    {
      TASE: "3",
      KOOD: "7501",
      NIMETUS: "Savimäe küla",
      NIMETUS_LIIGIGA: "Savimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00007501",
    },
    {
      TASE: "3",
      KOOD: "7502",
      NIMETUS: "Saxby küla",
      NIMETUS_LIIGIGA: "Saxby küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00007502",
    },
    {
      TASE: "3",
      KOOD: "7503",
      NIMETUS: "Savioja küla",
      NIMETUS_LIIGIGA: "Savioja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007503",
    },
    {
      TASE: "3",
      KOOD: "7504",
      NIMETUS: "Savioja küla",
      NIMETUS_LIIGIGA: "Savioja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007504",
    },
    {
      TASE: "3",
      KOOD: "7505",
      NIMETUS: "Seidla küla",
      NIMETUS_LIIGIGA: "Seidla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00007505",
    },
    {
      TASE: "3",
      KOOD: "7506",
      NIMETUS: "Savimäe küla",
      NIMETUS_LIIGIGA: "Savimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007506",
    },
    {
      TASE: "3",
      KOOD: "7507",
      NIMETUS: "Savioru küla",
      NIMETUS_LIIGIGA: "Savioru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007507",
    },
    {
      TASE: "3",
      KOOD: "7508",
      NIMETUS: "Seinapalu küla",
      NIMETUS_LIIGIGA: "Seinapalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00007508",
    },
    {
      TASE: "3",
      KOOD: "7513",
      NIMETUS: "Selgase küla",
      NIMETUS_LIIGIGA: "Selgase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007513",
    },
    {
      TASE: "3",
      KOOD: "7516",
      NIMETUS: "Selgise küla",
      NIMETUS_LIIGIGA: "Selgise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007516",
    },
    {
      TASE: "3",
      KOOD: "7517",
      NIMETUS: "Seli küla",
      NIMETUS_LIIGIGA: "Seli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00007517",
    },
    {
      TASE: "3",
      KOOD: "7518",
      NIMETUS: "Seli küla",
      NIMETUS_LIIGIGA: "Seli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00007518",
    },
    {
      TASE: "3",
      KOOD: "7521",
      NIMETUS: "Seliküla",
      NIMETUS_LIIGIGA: "Seliküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00007521",
    },
    {
      TASE: "3",
      KOOD: "7523",
      NIMETUS: "Selise küla",
      NIMETUS_LIIGIGA: "Selise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007523",
    },
    {
      TASE: "3",
      KOOD: "7526",
      NIMETUS: "Seliste küla",
      NIMETUS_LIIGIGA: "Seliste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00007526",
    },
    {
      TASE: "3",
      KOOD: "7528",
      NIMETUS: "Selja küla",
      NIMETUS_LIIGIGA: "Selja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007528",
    },
    {
      TASE: "3",
      KOOD: "7529",
      NIMETUS: "Selja küla",
      NIMETUS_LIIGIGA: "Selja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00007529",
    },
    {
      TASE: "3",
      KOOD: "7530",
      NIMETUS: "Selja küla",
      NIMETUS_LIIGIGA: "Selja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00007530",
    },
    {
      TASE: "3",
      KOOD: "7531",
      NIMETUS: "Selja küla",
      NIMETUS_LIIGIGA: "Selja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00007531",
    },
    {
      TASE: "3",
      KOOD: "7532",
      NIMETUS: "Selja küla",
      NIMETUS_LIIGIGA: "Selja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00007532",
    },
    {
      TASE: "3",
      KOOD: "7534",
      NIMETUS: "Seljaküla",
      NIMETUS_LIIGIGA: "Seljaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007534",
    },
    {
      TASE: "3",
      KOOD: "7536",
      NIMETUS: "Seljametsa küla",
      NIMETUS_LIIGIGA: "Seljametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00007536",
    },
    {
      TASE: "3",
      KOOD: "7537",
      NIMETUS: "Selja küla",
      NIMETUS_LIIGIGA: "Selja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007537",
    },
    {
      TASE: "3",
      KOOD: "7539",
      NIMETUS: "Selli küla",
      NIMETUS_LIIGIGA: "Selli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00007539",
    },
    {
      TASE: "3",
      KOOD: "7540",
      NIMETUS: "Sepaküla",
      NIMETUS_LIIGIGA: "Sepaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00007540",
    },
    {
      TASE: "3",
      KOOD: "7541",
      NIMETUS: "Sepaküla",
      NIMETUS_LIIGIGA: "Sepaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00007541",
    },
    {
      TASE: "3",
      KOOD: "7542",
      NIMETUS: "Sepa küla",
      NIMETUS_LIIGIGA: "Sepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00007542",
    },
    {
      TASE: "3",
      KOOD: "7543",
      NIMETUS: "Sepa küla",
      NIMETUS_LIIGIGA: "Sepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007543",
    },
    {
      TASE: "3",
      KOOD: "7544",
      NIMETUS: "Separa küla",
      NIMETUS_LIIGIGA: "Separa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00007544",
    },
    {
      TASE: "3",
      KOOD: "7545",
      NIMETUS: "Sepise küla",
      NIMETUS_LIIGIGA: "Sepise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007545",
    },
    {
      TASE: "3",
      KOOD: "7547",
      NIMETUS: "Serga küla",
      NIMETUS_LIIGIGA: "Serga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007547",
    },
    {
      TASE: "3",
      KOOD: "7548",
      NIMETUS: "Sepaste küla",
      NIMETUS_LIIGIGA: "Sepaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007548",
    },
    {
      TASE: "3",
      KOOD: "7549",
      NIMETUS: "Seretsüvä küla",
      NIMETUS_LIIGIGA: "Seretsüvä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007549",
    },
    {
      TASE: "3",
      KOOD: "7550",
      NIMETUS: "Seruküla",
      NIMETUS_LIIGIGA: "Seruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00007550",
    },
    {
      TASE: "3",
      KOOD: "7551",
      NIMETUS: "Servaääre küla",
      NIMETUS_LIIGIGA: "Servaääre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00007551",
    },
    {
      TASE: "3",
      KOOD: "7553",
      NIMETUS: "Sesniki küla",
      NIMETUS_LIIGIGA: "Sesniki küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007553",
    },
    {
      TASE: "3",
      KOOD: "7554",
      NIMETUS: "Sigala küla",
      NIMETUS_LIIGIGA: "Sigala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007554",
    },
    {
      TASE: "3",
      KOOD: "7557",
      NIMETUS: "Siberi küla",
      NIMETUS_LIIGIGA: "Siberi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00007557",
    },
    {
      TASE: "3",
      KOOD: "7560",
      NIMETUS: "Sigaste küla",
      NIMETUS_LIIGIGA: "Sigaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00007560",
    },
    {
      TASE: "3",
      KOOD: "7562",
      NIMETUS: "Sigula küla",
      NIMETUS_LIIGIGA: "Sigula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00007562",
    },
    {
      TASE: "3",
      KOOD: "7565",
      NIMETUS: "Sihva küla",
      NIMETUS_LIIGIGA: "Sihva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00007565",
    },
    {
      TASE: "3",
      KOOD: "7568",
      NIMETUS: "Siiksaare küla",
      NIMETUS_LIIGIGA: "Siiksaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007568",
    },
    {
      TASE: "3",
      KOOD: "7571",
      NIMETUS: "Siimika küla",
      NIMETUS_LIIGIGA: "Siimika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00007571",
    },
    {
      TASE: "3",
      KOOD: "7573",
      NIMETUS: "Siimusti alevik",
      NIMETUS_LIIGIGA: "Siimusti alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00007573",
    },
    {
      TASE: "3",
      KOOD: "7574",
      NIMETUS: "Sika küla",
      NIMETUS_LIIGIGA: "Sika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007574",
    },
    {
      TASE: "3",
      KOOD: "7575",
      NIMETUS: "Sikalaanõ küla",
      NIMETUS_LIIGIGA: "Sikalaanõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007575",
    },
    {
      TASE: "3",
      KOOD: "7576",
      NIMETUS: "Sika küla",
      NIMETUS_LIIGIGA: "Sika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007576",
    },
    {
      TASE: "3",
      KOOD: "7579",
      NIMETUS: "Sikakurmu küla",
      NIMETUS_LIIGIGA: "Sikakurmu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00007579",
    },
    {
      TASE: "3",
      KOOD: "7581",
      NIMETUS: "Sikana küla",
      NIMETUS_LIIGIGA: "Sikana küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00007581",
    },
    {
      TASE: "3",
      KOOD: "7584",
      NIMETUS: "Sikassaare küla",
      NIMETUS_LIIGIGA: "Sikassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007584",
    },
    {
      TASE: "3",
      KOOD: "7585",
      NIMETUS: "Siksälä küla",
      NIMETUS_LIIGIGA: "Siksälä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007585",
    },
    {
      TASE: "3",
      KOOD: "7586",
      NIMETUS: "Sikeldi küla",
      NIMETUS_LIIGIGA: "Sikeldi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00007586",
    },
    {
      TASE: "3",
      KOOD: "7589",
      NIMETUS: "Silla küla",
      NIMETUS_LIIGIGA: "Silla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007589",
    },
    {
      TASE: "3",
      KOOD: "7593",
      NIMETUS: "Sillaotsa küla",
      NIMETUS_LIIGIGA: "Sillaotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00007593",
    },
    {
      TASE: "3",
      KOOD: "7595",
      NIMETUS: "Sillapää küla",
      NIMETUS_LIIGIGA: "Sillapää küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00007595",
    },
    {
      TASE: "3",
      KOOD: "5516",
      NIMETUS: "Nõmmemetsa küla",
      NIMETUS_LIIGIGA: "Nõmmemetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00005516",
    },
    {
      TASE: "3",
      KOOD: "5517",
      NIMETUS: "Nõmmeotsa küla",
      NIMETUS_LIIGIGA: "Nõmmeotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005517",
    },
    {
      TASE: "3",
      KOOD: "5518",
      NIMETUS: "Nõmmeri küla",
      NIMETUS_LIIGIGA: "Nõmmeri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00005518",
    },
    {
      TASE: "3",
      KOOD: "5519",
      NIMETUS: "Nõmme küla",
      NIMETUS_LIIGIGA: "Nõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005519",
    },
    {
      TASE: "3",
      KOOD: "5520",
      NIMETUS: "Nõmme küla",
      NIMETUS_LIIGIGA: "Nõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00005520",
    },
    {
      TASE: "3",
      KOOD: "5521",
      NIMETUS: "Nõmmise küla",
      NIMETUS_LIIGIGA: "Nõmmise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00005521",
    },
    {
      TASE: "3",
      KOOD: "5522",
      NIMETUS: "Nõmmküla",
      NIMETUS_LIIGIGA: "Nõmmküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00005522",
    },
    {
      TASE: "3",
      KOOD: "5523",
      NIMETUS: "Väike-Nõmmküla / Persåker",
      NIMETUS_LIIGIGA: "Väike-Nõmmküla / Persåker",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005523",
    },
    {
      TASE: "3",
      KOOD: "5524",
      NIMETUS: "Nõmmküla",
      NIMETUS_LIIGIGA: "Nõmmküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00005524",
    },
    {
      TASE: "3",
      KOOD: "5525",
      NIMETUS: "Nõmmküla",
      NIMETUS_LIIGIGA: "Nõmmküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00005525",
    },
    {
      TASE: "3",
      KOOD: "5526",
      NIMETUS: "Nõmme küla",
      NIMETUS_LIIGIGA: "Nõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005526",
    },
    {
      TASE: "3",
      KOOD: "5527",
      NIMETUS: "Nõmme küla",
      NIMETUS_LIIGIGA: "Nõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00005527",
    },
    {
      TASE: "3",
      KOOD: "5528",
      NIMETUS: "Nõmpa küla",
      NIMETUS_LIIGIGA: "Nõmpa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005528",
    },
    {
      TASE: "3",
      KOOD: "5529",
      NIMETUS: "Nõmmküla",
      NIMETUS_LIIGIGA: "Nõmmküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00005529",
    },
    {
      TASE: "3",
      KOOD: "5530",
      NIMETUS: "Nõmme küla",
      NIMETUS_LIIGIGA: "Nõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005530",
    },
    {
      TASE: "3",
      KOOD: "5531",
      NIMETUS: "Nõnova küla",
      NIMETUS_LIIGIGA: "Nõnova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005531",
    },
    {
      TASE: "3",
      KOOD: "5532",
      NIMETUS: "Nõmme küla",
      NIMETUS_LIIGIGA: "Nõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00005532",
    },
    {
      TASE: "3",
      KOOD: "5533",
      NIMETUS: "Nõmmeveski küla",
      NIMETUS_LIIGIGA: "Nõmmeveski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00005533",
    },
    {
      TASE: "3",
      KOOD: "5534",
      NIMETUS: "Nõo alevik",
      NIMETUS_LIIGIGA: "Nõo alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00005534",
    },
    {
      TASE: "3",
      KOOD: "5537",
      NIMETUS: "Nõrava küla",
      NIMETUS_LIIGIGA: "Nõrava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00005537",
    },
    {
      TASE: "3",
      KOOD: "5540",
      NIMETUS: "Nõuni küla",
      NIMETUS_LIIGIGA: "Nõuni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00005540",
    },
    {
      TASE: "3",
      KOOD: "5543",
      NIMETUS: "Nõva küla",
      NIMETUS_LIIGIGA: "Nõva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005543",
    },
    {
      TASE: "3",
      KOOD: "5544",
      NIMETUS: "Nõva küla",
      NIMETUS_LIIGIGA: "Nõva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00005544",
    },
    {
      TASE: "3",
      KOOD: "5548",
      NIMETUS: "Näduvere küla",
      NIMETUS_LIIGIGA: "Näduvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00005548",
    },
    {
      TASE: "3",
      KOOD: "5551",
      NIMETUS: "Näo küla",
      NIMETUS_LIIGIGA: "Näo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00005551",
    },
    {
      TASE: "3",
      KOOD: "5554",
      NIMETUS: "Näpi alevik",
      NIMETUS_LIIGIGA: "Näpi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00005554",
    },
    {
      TASE: "3",
      KOOD: "5557",
      NIMETUS: "Närapää küla",
      NIMETUS_LIIGIGA: "Närapää küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00005557",
    },
    {
      TASE: "3",
      KOOD: "5560",
      NIMETUS: "Nässuma küla",
      NIMETUS_LIIGIGA: "Nässuma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005560",
    },
    {
      TASE: "3",
      KOOD: "5561",
      NIMETUS: "Nääri küla",
      NIMETUS_LIIGIGA: "Nääri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005561",
    },
    {
      TASE: "3",
      KOOD: "5562",
      NIMETUS: "Näsuvere küla",
      NIMETUS_LIIGIGA: "Näsuvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00005562",
    },
    {
      TASE: "3",
      KOOD: "5563",
      NIMETUS: "Nätsi küla",
      NIMETUS_LIIGIGA: "Nätsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005563",
    },
    {
      TASE: "3",
      KOOD: "5569",
      NIMETUS: "Nüri küla",
      NIMETUS_LIIGIGA: "Nüri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00005569",
    },
    {
      TASE: "3",
      KOOD: "5574",
      NIMETUS: "Oandu küla",
      NIMETUS_LIIGIGA: "Oandu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00005574",
    },
    {
      TASE: "3",
      KOOD: "5575",
      NIMETUS: "Oandu küla",
      NIMETUS_LIIGIGA: "Oandu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00005575",
    },
    {
      TASE: "3",
      KOOD: "5578",
      NIMETUS: "Oara küla",
      NIMETUS_LIIGIGA: "Oara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00005578",
    },
    {
      TASE: "3",
      KOOD: "5582",
      NIMETUS: "Obinitsa küla",
      NIMETUS_LIIGIGA: "Obinitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00005582",
    },
    {
      TASE: "3",
      KOOD: "5585",
      NIMETUS: "Obja küla",
      NIMETUS_LIIGIGA: "Obja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00005585",
    },
    {
      TASE: "3",
      KOOD: "5588",
      NIMETUS: "Oblu küla",
      NIMETUS_LIIGIGA: "Oblu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00005588",
    },
    {
      TASE: "3",
      KOOD: "5592",
      NIMETUS: "Odalätsi küla",
      NIMETUS_LIIGIGA: "Odalätsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005592",
    },
    {
      TASE: "3",
      KOOD: "5595",
      NIMETUS: "Odiste küla",
      NIMETUS_LIIGIGA: "Odiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00005595",
    },
    {
      TASE: "3",
      KOOD: "5598",
      NIMETUS: "Odivere küla",
      NIMETUS_LIIGIGA: "Odivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00005598",
    },
    {
      TASE: "3",
      KOOD: "5601",
      NIMETUS: "Odulemma küla",
      NIMETUS_LIIGIGA: "Odulemma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00005601",
    },
    {
      TASE: "3",
      KOOD: "5605",
      NIMETUS: "Oe küla",
      NIMETUS_LIIGIGA: "Oe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00005605",
    },
    {
      TASE: "3",
      KOOD: "5608",
      NIMETUS: "Oela küla",
      NIMETUS_LIIGIGA: "Oela küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00005608",
    },
    {
      TASE: "3",
      KOOD: "5609",
      NIMETUS: "Oeti küla",
      NIMETUS_LIIGIGA: "Oeti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00005609",
    },
    {
      TASE: "3",
      KOOD: "5610",
      NIMETUS: "Oese küla",
      NIMETUS_LIIGIGA: "Oese küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00005610",
    },
    {
      TASE: "3",
      KOOD: "5611",
      NIMETUS: "Oese küla",
      NIMETUS_LIIGIGA: "Oese küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005611",
    },
    {
      TASE: "3",
      KOOD: "5612",
      NIMETUS: "Oessaare küla",
      NIMETUS_LIIGIGA: "Oessaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005612",
    },
    {
      TASE: "3",
      KOOD: "5613",
      NIMETUS: "Ogandi küla",
      NIMETUS_LIIGIGA: "Ogandi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005613",
    },
    {
      TASE: "3",
      KOOD: "5615",
      NIMETUS: "Ohakvere küla",
      NIMETUS_LIIGIGA: "Ohakvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00005615",
    },
    {
      TASE: "3",
      KOOD: "5618",
      NIMETUS: "Ohekatku küla",
      NIMETUS_LIIGIGA: "Ohekatku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00005618",
    },
    {
      TASE: "3",
      KOOD: "5620",
      NIMETUS: "Ohepalu küla",
      NIMETUS_LIIGIGA: "Ohepalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00005620",
    },
    {
      TASE: "3",
      KOOD: "5621",
      NIMETUS: "Ohessaare küla",
      NIMETUS_LIIGIGA: "Ohessaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005621",
    },
    {
      TASE: "3",
      KOOD: "5623",
      NIMETUS: "Ohtja küla",
      NIMETUS_LIIGIGA: "Ohtja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005623",
    },
    {
      TASE: "3",
      KOOD: "5626",
      NIMETUS: "Ohtla küla",
      NIMETUS_LIIGIGA: "Ohtla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005626",
    },
    {
      TASE: "3",
      KOOD: "5628",
      NIMETUS: "Ohtu küla",
      NIMETUS_LIIGIGA: "Ohtu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00005628",
    },
    {
      TASE: "3",
      KOOD: "5631",
      NIMETUS: "Ohukotsu küla",
      NIMETUS_LIIGIGA: "Ohukotsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005631",
    },
    {
      TASE: "3",
      KOOD: "5634",
      NIMETUS: "Ohulepa küla",
      NIMETUS_LIIGIGA: "Ohulepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00005634",
    },
    {
      TASE: "3",
      KOOD: "5638",
      NIMETUS: "Oidrema küla",
      NIMETUS_LIIGIGA: "Oidrema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005638",
    },
    {
      TASE: "3",
      KOOD: "5639",
      NIMETUS: "Oina küla",
      NIMETUS_LIIGIGA: "Oina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00005639",
    },
    {
      TASE: "3",
      KOOD: "5640",
      NIMETUS: "Oissaare küla",
      NIMETUS_LIIGIGA: "Oissaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00005640",
    },
    {
      TASE: "3",
      KOOD: "5641",
      NIMETUS: "Oisu alevik",
      NIMETUS_LIIGIGA: "Oisu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00005641",
    },
    {
      TASE: "3",
      KOOD: "5644",
      NIMETUS: "Oitme küla",
      NIMETUS_LIIGIGA: "Oitme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005644",
    },
    {
      TASE: "3",
      KOOD: "5647",
      NIMETUS: "Oiu küla",
      NIMETUS_LIIGIGA: "Oiu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00005647",
    },
    {
      TASE: "3",
      KOOD: "5648",
      NIMETUS: "Ojaküla",
      NIMETUS_LIIGIGA: "Ojaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00005648",
    },
    {
      TASE: "3",
      KOOD: "5649",
      NIMETUS: "Ojaküla",
      NIMETUS_LIIGIGA: "Ojaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005649",
    },
    {
      TASE: "3",
      KOOD: "5651",
      NIMETUS: "Ojaküla",
      NIMETUS_LIIGIGA: "Ojaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00005651",
    },
    {
      TASE: "3",
      KOOD: "5652",
      NIMETUS: "Ojamaa küla",
      NIMETUS_LIIGIGA: "Ojamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00005652",
    },
    {
      TASE: "3",
      KOOD: "5653",
      NIMETUS: "Ojapere küla",
      NIMETUS_LIIGIGA: "Ojapere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005653",
    },
    {
      TASE: "3",
      KOOD: "5654",
      NIMETUS: "Ole küla",
      NIMETUS_LIIGIGA: "Ole küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005654",
    },
    {
      TASE: "3",
      KOOD: "5655",
      NIMETUS: "Ojaäärse küla",
      NIMETUS_LIIGIGA: "Ojaäärse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005655",
    },
    {
      TASE: "3",
      KOOD: "5656",
      NIMETUS: "Ojasoo küla",
      NIMETUS_LIIGIGA: "Ojasoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00005656",
    },
    {
      TASE: "3",
      KOOD: "5657",
      NIMETUS: "Oju küla",
      NIMETUS_LIIGIGA: "Oju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005657",
    },
    {
      TASE: "3",
      KOOD: "5658",
      NIMETUS: "Olehkova küla",
      NIMETUS_LIIGIGA: "Olehkova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00005658",
    },
    {
      TASE: "3",
      KOOD: "5660",
      NIMETUS: "Oleski küla",
      NIMETUS_LIIGIGA: "Oleski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005660",
    },
    {
      TASE: "3",
      KOOD: "5661",
      NIMETUS: "Olju küla",
      NIMETUS_LIIGIGA: "Olju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00005661",
    },
    {
      TASE: "3",
      KOOD: "5663",
      NIMETUS: "Olgina alevik",
      NIMETUS_LIIGIGA: "Olgina alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00005663",
    },
    {
      TASE: "3",
      KOOD: "5666",
      NIMETUS: "Ollepa küla",
      NIMETUS_LIIGIGA: "Ollepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00005666",
    },
    {
      TASE: "3",
      KOOD: "5669",
      NIMETUS: "Olustvere alevik",
      NIMETUS_LIIGIGA: "Olustvere alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00005669",
    },
    {
      TASE: "3",
      KOOD: "5673",
      NIMETUS: "Omedu küla",
      NIMETUS_LIIGIGA: "Omedu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00005673",
    },
    {
      TASE: "3",
      KOOD: "5677",
      NIMETUS: "Ongassaare küla",
      NIMETUS_LIIGIGA: "Ongassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00005677",
    },
    {
      TASE: "3",
      KOOD: "5680",
      NIMETUS: "Ontika küla",
      NIMETUS_LIIGIGA: "Ontika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00005680",
    },
    {
      TASE: "3",
      KOOD: "5684",
      NIMETUS: "Ookatku küla",
      NIMETUS_LIIGIGA: "Ookatku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00005684",
    },
    {
      TASE: "3",
      KOOD: "5687",
      NIMETUS: "Oola küla",
      NIMETUS_LIIGIGA: "Oola küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00005687",
    },
    {
      TASE: "3",
      KOOD: "5690",
      NIMETUS: "Oomiste küla",
      NIMETUS_LIIGIGA: "Oomiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00005690",
    },
    {
      TASE: "3",
      KOOD: "5693",
      NIMETUS: "Oonga küla",
      NIMETUS_LIIGIGA: "Oonga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005693",
    },
    {
      TASE: "3",
      KOOD: "5695",
      NIMETUS: "Oonurme küla",
      NIMETUS_LIIGIGA: "Oonurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00005695",
    },
    {
      TASE: "3",
      KOOD: "5698",
      NIMETUS: "Oore küla",
      NIMETUS_LIIGIGA: "Oore küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00005698",
    },
    {
      TASE: "3",
      KOOD: "5701",
      NIMETUS: "Oorgu küla",
      NIMETUS_LIIGIGA: "Oorgu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00005701",
    },
    {
      TASE: "3",
      KOOD: "5705",
      NIMETUS: "Orajõe küla",
      NIMETUS_LIIGIGA: "Orajõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00005705",
    },
    {
      TASE: "3",
      KOOD: "5706",
      NIMETUS: "Orajõe küla",
      NIMETUS_LIIGIGA: "Orajõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00005706",
    },
    {
      TASE: "3",
      KOOD: "5708",
      NIMETUS: "Orava küla",
      NIMETUS_LIIGIGA: "Orava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005708",
    },
    {
      TASE: "3",
      KOOD: "5709",
      NIMETUS: "Orgemäe küla",
      NIMETUS_LIIGIGA: "Orgemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00005709",
    },
    {
      TASE: "3",
      KOOD: "5711",
      NIMETUS: "Orgita küla",
      NIMETUS_LIIGIGA: "Orgita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005711",
    },
    {
      TASE: "3",
      KOOD: "5714",
      NIMETUS: "Orgmetsa küla",
      NIMETUS_LIIGIGA: "Orgmetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00005714",
    },
    {
      TASE: "3",
      KOOD: "5716",
      NIMETUS: "Orguse küla",
      NIMETUS_LIIGIGA: "Orguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00005716",
    },
    {
      TASE: "3",
      KOOD: "5717",
      NIMETUS: "Orguse küla",
      NIMETUS_LIIGIGA: "Orguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00005717",
    },
    {
      TASE: "3",
      KOOD: "5720",
      NIMETUS: "Oriküla",
      NIMETUS_LIIGIGA: "Oriküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00005720",
    },
    {
      TASE: "3",
      KOOD: "5723",
      NIMETUS: "Orinõmme küla",
      NIMETUS_LIIGIGA: "Orinõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005723",
    },
    {
      TASE: "3",
      KOOD: "5725",
      NIMETUS: "Orissaare alevik",
      NIMETUS_LIIGIGA: "Orissaare alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005725",
    },
    {
      TASE: "3",
      KOOD: "5728",
      NIMETUS: "Orjaku küla",
      NIMETUS_LIIGIGA: "Orjaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005728",
    },
    {
      TASE: "3",
      KOOD: "5731",
      NIMETUS: "Oro küla",
      NIMETUS_LIIGIGA: "Oro küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005731",
    },
    {
      TASE: "3",
      KOOD: "5732",
      NIMETUS: "Ortumäe küla",
      NIMETUS_LIIGIGA: "Ortumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005732",
    },
    {
      TASE: "3",
      KOOD: "5734",
      NIMETUS: "Ortuma küla",
      NIMETUS_LIIGIGA: "Ortuma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005734",
    },
    {
      TASE: "3",
      KOOD: "5737",
      NIMETUS: "Oru küla",
      NIMETUS_LIIGIGA: "Oru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005737",
    },
    {
      TASE: "3",
      KOOD: "5738",
      NIMETUS: "Oru küla",
      NIMETUS_LIIGIGA: "Oru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00005738",
    },
    {
      TASE: "3",
      KOOD: "5739",
      NIMETUS: "Oru küla",
      NIMETUS_LIIGIGA: "Oru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00005739",
    },
    {
      TASE: "3",
      KOOD: "5741",
      NIMETUS: "Orutaguse küla",
      NIMETUS_LIIGIGA: "Orutaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00005741",
    },
    {
      TASE: "3",
      KOOD: "5743",
      NIMETUS: "Osmussaare küla / Odensholm",
      NIMETUS_LIIGIGA: "Osmussaare küla / Odensholm",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005743",
    },
    {
      TASE: "3",
      KOOD: "5746",
      NIMETUS: "Ostrova küla",
      NIMETUS_LIIGIGA: "Ostrova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00005746",
    },
    {
      TASE: "3",
      KOOD: "5749",
      NIMETUS: "Osula küla",
      NIMETUS_LIIGIGA: "Osula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005749",
    },
    {
      TASE: "3",
      KOOD: "5756",
      NIMETUS: "Oti küla",
      NIMETUS_LIIGIGA: "Oti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00005756",
    },
    {
      TASE: "3",
      KOOD: "5757",
      NIMETUS: "Oti küla",
      NIMETUS_LIIGIGA: "Oti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005757",
    },
    {
      TASE: "3",
      KOOD: "5758",
      NIMETUS: "Oti küla",
      NIMETUS_LIIGIGA: "Oti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00005758",
    },
    {
      TASE: "3",
      KOOD: "5761",
      NIMETUS: "Otiku küla",
      NIMETUS_LIIGIGA: "Otiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00005761",
    },
    {
      TASE: "3",
      KOOD: "5763",
      NIMETUS: "Otiküla",
      NIMETUS_LIIGIGA: "Otiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00005763",
    },
    {
      TASE: "3",
      KOOD: "5766",
      NIMETUS: "Otsa küla",
      NIMETUS_LIIGIGA: "Otsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005766",
    },
    {
      TASE: "3",
      KOOD: "5767",
      NIMETUS: "Otste küla",
      NIMETUS_LIIGIGA: "Otste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005767",
    },
    {
      TASE: "3",
      KOOD: "5769",
      NIMETUS: "Otslava küla",
      NIMETUS_LIIGIGA: "Otslava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00005769",
    },
    {
      TASE: "3",
      KOOD: "5770",
      NIMETUS: "Paaburissa küla",
      NIMETUS_LIIGIGA: "Paaburissa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005770",
    },
    {
      TASE: "3",
      KOOD: "5774",
      NIMETUS: "Paadenurme küla",
      NIMETUS_LIIGIGA: "Paadenurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00005774",
    },
    {
      TASE: "3",
      KOOD: "5776",
      NIMETUS: "Paadrema küla",
      NIMETUS_LIIGIGA: "Paadrema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005776",
    },
    {
      TASE: "3",
      KOOD: "5779",
      NIMETUS: "Paaduotsa küla",
      NIMETUS_LIIGIGA: "Paaduotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005779",
    },
    {
      TASE: "3",
      KOOD: "5781",
      NIMETUS: "Paaksima küla",
      NIMETUS_LIIGIGA: "Paaksima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00005781",
    },
    {
      TASE: "3",
      KOOD: "5784",
      NIMETUS: "Paali küla",
      NIMETUS_LIIGIGA: "Paali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00005784",
    },
    {
      TASE: "3",
      KOOD: "5786",
      NIMETUS: "Paasi küla",
      NIMETUS_LIIGIGA: "Paasi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00005786",
    },
    {
      TASE: "3",
      KOOD: "5789",
      NIMETUS: "Paasiku küla",
      NIMETUS_LIIGIGA: "Paasiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00005789",
    },
    {
      TASE: "3",
      KOOD: "5791",
      NIMETUS: "Paasküla",
      NIMETUS_LIIGIGA: "Paasküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00005791",
    },
    {
      TASE: "3",
      KOOD: "5792",
      NIMETUS: "Paaste küla",
      NIMETUS_LIIGIGA: "Paaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005792",
    },
    {
      TASE: "3",
      KOOD: "5793",
      NIMETUS: "Paate küla",
      NIMETUS_LIIGIGA: "Paate küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00005793",
    },
    {
      TASE: "3",
      KOOD: "5794",
      NIMETUS: "Paasvere küla",
      NIMETUS_LIIGIGA: "Paasvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00005794",
    },
    {
      TASE: "3",
      KOOD: "5796",
      NIMETUS: "Paatna küla",
      NIMETUS_LIIGIGA: "Paatna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00005796",
    },
    {
      TASE: "3",
      KOOD: "5799",
      NIMETUS: "Paatsa küla",
      NIMETUS_LIIGIGA: "Paatsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005799",
    },
    {
      TASE: "3",
      KOOD: "5801",
      NIMETUS: "Paatsalu küla",
      NIMETUS_LIIGIGA: "Paatsalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005801",
    },
    {
      TASE: "3",
      KOOD: "5802",
      NIMETUS: "Pada-Aruküla",
      NIMETUS_LIIGIGA: "Pada-Aruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00005802",
    },
    {
      TASE: "3",
      KOOD: "5804",
      NIMETUS: "Pada küla",
      NIMETUS_LIIGIGA: "Pada küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00005804",
    },
    {
      TASE: "3",
      KOOD: "5805",
      NIMETUS: "Padaküla",
      NIMETUS_LIIGIGA: "Padaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00005805",
    },
    {
      TASE: "3",
      KOOD: "5807",
      NIMETUS: "Padakõrve küla",
      NIMETUS_LIIGIGA: "Padakõrve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00005807",
    },
    {
      TASE: "3",
      KOOD: "5809",
      NIMETUS: "Padari küla",
      NIMETUS_LIIGIGA: "Padari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00005809",
    },
    {
      TASE: "3",
      KOOD: "5812",
      NIMETUS: "Padise küla",
      NIMETUS_LIIGIGA: "Padise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00005812",
    },
    {
      TASE: "3",
      KOOD: "5814",
      NIMETUS: "Padu küla",
      NIMETUS_LIIGIGA: "Padu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00005814",
    },
    {
      TASE: "3",
      KOOD: "5817",
      NIMETUS: "Paduvere küla",
      NIMETUS_LIIGIGA: "Paduvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00005817",
    },
    {
      TASE: "3",
      KOOD: "5820",
      NIMETUS: "Pae küla",
      NIMETUS_LIIGIGA: "Pae küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00005820",
    },
    {
      TASE: "3",
      KOOD: "5821",
      NIMETUS: "Pae küla",
      NIMETUS_LIIGIGA: "Pae küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00005821",
    },
    {
      TASE: "3",
      KOOD: "5822",
      NIMETUS: "Paeboja küla",
      NIMETUS_LIIGIGA: "Paeboja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005822",
    },
    {
      TASE: "3",
      KOOD: "5826",
      NIMETUS: "Paeküla",
      NIMETUS_LIIGIGA: "Paeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005826",
    },
    {
      TASE: "3",
      KOOD: "5828",
      NIMETUS: "Paelama küla",
      NIMETUS_LIIGIGA: "Paelama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00005828",
    },
    {
      TASE: "3",
      KOOD: "5831",
      NIMETUS: "Paenase küla",
      NIMETUS_LIIGIGA: "Paenase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00005831",
    },
    {
      TASE: "3",
      KOOD: "5833",
      NIMETUS: "Paenasti küla",
      NIMETUS_LIIGIGA: "Paenasti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00005833",
    },
    {
      TASE: "3",
      KOOD: "5836",
      NIMETUS: "Paevere küla",
      NIMETUS_LIIGIGA: "Paevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005836",
    },
    {
      TASE: "3",
      KOOD: "5839",
      NIMETUS: "Paganamaa küla",
      NIMETUS_LIIGIGA: "Paganamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005839",
    },
    {
      TASE: "3",
      KOOD: "5841",
      NIMETUS: "Pagari küla",
      NIMETUS_LIIGIGA: "Pagari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00005841",
    },
    {
      TASE: "3",
      KOOD: "5847",
      NIMETUS: "Pahapilli küla",
      NIMETUS_LIIGIGA: "Pahapilli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005847",
    },
    {
      TASE: "3",
      KOOD: "5849",
      NIMETUS: "Pahavalla küla",
      NIMETUS_LIIGIGA: "Pahavalla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005849",
    },
    {
      TASE: "3",
      KOOD: "5854",
      NIMETUS: "Pahkla küla",
      NIMETUS_LIIGIGA: "Pahkla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00005854",
    },
    {
      TASE: "3",
      KOOD: "5857",
      NIMETUS: "Pahtpää küla",
      NIMETUS_LIIGIGA: "Pahtpää küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00005857",
    },
    {
      TASE: "3",
      KOOD: "5859",
      NIMETUS: "Pahuvere küla",
      NIMETUS_LIIGIGA: "Pahuvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00005859",
    },
    {
      TASE: "3",
      KOOD: "5862",
      NIMETUS: "Paidra küla",
      NIMETUS_LIIGIGA: "Paidra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005862",
    },
    {
      TASE: "3",
      KOOD: "5867",
      NIMETUS: "Paiküla",
      NIMETUS_LIIGIGA: "Paiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005867",
    },
    {
      TASE: "3",
      KOOD: "5868",
      NIMETUS: "Paimala küla",
      NIMETUS_LIIGIGA: "Paimala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005868",
    },
    {
      TASE: "3",
      KOOD: "5869",
      NIMETUS: "Paimvere küla",
      NIMETUS_LIIGIGA: "Paimvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005869",
    },
    {
      TASE: "3",
      KOOD: "5870",
      NIMETUS: "Painküla",
      NIMETUS_LIIGIGA: "Painküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00005870",
    },
    {
      TASE: "3",
      KOOD: "5872",
      NIMETUS: "Paistu küla",
      NIMETUS_LIIGIGA: "Paistu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00005872",
    },
    {
      TASE: "3",
      KOOD: "5875",
      NIMETUS: "Paisumaa küla",
      NIMETUS_LIIGIGA: "Paisumaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005875",
    },
    {
      TASE: "3",
      KOOD: "5878",
      NIMETUS: "Pajaka küla",
      NIMETUS_LIIGIGA: "Pajaka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005878",
    },
    {
      TASE: "3",
      KOOD: "5880",
      NIMETUS: "Paju küla",
      NIMETUS_LIIGIGA: "Paju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00005880",
    },
    {
      TASE: "3",
      KOOD: "5881",
      NIMETUS: "Paju küla",
      NIMETUS_LIIGIGA: "Paju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00005881",
    },
    {
      TASE: "3",
      KOOD: "5882",
      NIMETUS: "Paju-Kurdla küla",
      NIMETUS_LIIGIGA: "Paju-Kurdla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005882",
    },
    {
      TASE: "3",
      KOOD: "5884",
      NIMETUS: "Pajualuse küla",
      NIMETUS_LIIGIGA: "Pajualuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "251",
      ADS_OID: "AY00005884",
    },
    {
      TASE: "3",
      KOOD: "5886",
      NIMETUS: "Pajukurmu küla",
      NIMETUS_LIIGIGA: "Pajukurmu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00005886",
    },
    {
      TASE: "3",
      KOOD: "5889",
      NIMETUS: "Pajumaa küla",
      NIMETUS_LIIGIGA: "Pajumaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005889",
    },
    {
      TASE: "3",
      KOOD: "5890",
      NIMETUS: "Pajumõisa küla",
      NIMETUS_LIIGIGA: "Pajumõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005890",
    },
    {
      TASE: "3",
      KOOD: "5891",
      NIMETUS: "Pajupea küla",
      NIMETUS_LIIGIGA: "Pajupea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00005891",
    },
    {
      TASE: "3",
      KOOD: "5894",
      NIMETUS: "Pajusi küla",
      NIMETUS_LIIGIGA: "Pajusi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00005894",
    },
    {
      TASE: "3",
      KOOD: "5896",
      NIMETUS: "Pajusti alevik",
      NIMETUS_LIIGIGA: "Pajusti alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00005896",
    },
    {
      TASE: "3",
      KOOD: "5899",
      NIMETUS: "Pajuveski küla",
      NIMETUS_LIIGIGA: "Pajuveski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00005899",
    },
    {
      TASE: "3",
      KOOD: "5900",
      NIMETUS: "Paklova küla",
      NIMETUS_LIIGIGA: "Paklova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00005900",
    },
    {
      TASE: "3",
      KOOD: "5901",
      NIMETUS: "Pala küla",
      NIMETUS_LIIGIGA: "Pala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00005901",
    },
    {
      TASE: "3",
      KOOD: "5902",
      NIMETUS: "Pakaste küla",
      NIMETUS_LIIGIGA: "Pakaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00005902",
    },
    {
      TASE: "3",
      KOOD: "5905",
      NIMETUS: "Pala küla",
      NIMETUS_LIIGIGA: "Pala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00005905",
    },
    {
      TASE: "3",
      KOOD: "5906",
      NIMETUS: "Pala küla",
      NIMETUS_LIIGIGA: "Pala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00005906",
    },
    {
      TASE: "3",
      KOOD: "5907",
      NIMETUS: "Pala küla",
      NIMETUS_LIIGIGA: "Pala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00005907",
    },
    {
      TASE: "3",
      KOOD: "5908",
      NIMETUS: "Palade küla",
      NIMETUS_LIIGIGA: "Palade küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005908",
    },
    {
      TASE: "3",
      KOOD: "5911",
      NIMETUS: "Palamulla küla",
      NIMETUS_LIIGIGA: "Palamulla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00005911",
    },
    {
      TASE: "3",
      KOOD: "5913",
      NIMETUS: "Palamuse alevik",
      NIMETUS_LIIGIGA: "Palamuse alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00005913",
    },
    {
      TASE: "3",
      KOOD: "5914",
      NIMETUS: "Palandõ küla",
      NIMETUS_LIIGIGA: "Palandõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00005914",
    },
    {
      TASE: "3",
      KOOD: "5916",
      NIMETUS: "Palamuste küla",
      NIMETUS_LIIGIGA: "Palamuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00005916",
    },
    {
      TASE: "3",
      KOOD: "5918",
      NIMETUS: "Palanumäe küla",
      NIMETUS_LIIGIGA: "Palanumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005918",
    },
    {
      TASE: "3",
      KOOD: "5921",
      NIMETUS: "Palase küla",
      NIMETUS_LIIGIGA: "Palase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005921",
    },
    {
      TASE: "3",
      KOOD: "5922",
      NIMETUS: "Palasi küla",
      NIMETUS_LIIGIGA: "Palasi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00005922",
    },
    {
      TASE: "3",
      KOOD: "5923",
      NIMETUS: "Palasi küla",
      NIMETUS_LIIGIGA: "Palasi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00005923",
    },
    {
      TASE: "3",
      KOOD: "5924",
      NIMETUS: "Palatu küla",
      NIMETUS_LIIGIGA: "Palatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005924",
    },
    {
      TASE: "3",
      KOOD: "5926",
      NIMETUS: "Palivere alevik",
      NIMETUS_LIIGIGA: "Palivere alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00005926",
    },
    {
      TASE: "3",
      KOOD: "5928",
      NIMETUS: "Paljasmaa küla",
      NIMETUS_LIIGIGA: "Paljasmaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00005928",
    },
    {
      TASE: "3",
      KOOD: "5931",
      NIMETUS: "Pallasmaa küla",
      NIMETUS_LIIGIGA: "Pallasmaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00005931",
    },
    {
      TASE: "3",
      KOOD: "5932",
      NIMETUS: "Palli küla",
      NIMETUS_LIIGIGA: "Palli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005932",
    },
    {
      TASE: "3",
      KOOD: "5933",
      NIMETUS: "Palli küla",
      NIMETUS_LIIGIGA: "Palli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005933",
    },
    {
      TASE: "3",
      KOOD: "5935",
      NIMETUS: "Pallika küla",
      NIMETUS_LIIGIGA: "Pallika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00005935",
    },
    {
      TASE: "3",
      KOOD: "5936",
      NIMETUS: "Palmse küla",
      NIMETUS_LIIGIGA: "Palmse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00005936",
    },
    {
      TASE: "3",
      KOOD: "5938",
      NIMETUS: "Palo küla",
      NIMETUS_LIIGIGA: "Palo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00005938",
    },
    {
      TASE: "3",
      KOOD: "5941",
      NIMETUS: "Palometsa küla",
      NIMETUS_LIIGIGA: "Palometsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005941",
    },
    {
      TASE: "3",
      KOOD: "5942",
      NIMETUS: "Palujüri küla",
      NIMETUS_LIIGIGA: "Palujüri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00005942",
    },
    {
      TASE: "3",
      KOOD: "5943",
      NIMETUS: "Paloveere küla",
      NIMETUS_LIIGIGA: "Paloveere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00005943",
    },
    {
      TASE: "3",
      KOOD: "5944",
      NIMETUS: "Paluküla",
      NIMETUS_LIIGIGA: "Paluküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00005944",
    },
    {
      TASE: "3",
      KOOD: "5945",
      NIMETUS: "Paloveere küla",
      NIMETUS_LIIGIGA: "Paloveere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00005945",
    },
    {
      TASE: "3",
      KOOD: "5946",
      NIMETUS: "Paluküla",
      NIMETUS_LIIGIGA: "Paluküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005946",
    },
    {
      TASE: "3",
      KOOD: "5947",
      NIMETUS: "Paluküla",
      NIMETUS_LIIGIGA: "Paluküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00005947",
    },
    {
      TASE: "3",
      KOOD: "5949",
      NIMETUS: "Palumäe küla",
      NIMETUS_LIIGIGA: "Palumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00005949",
    },
    {
      TASE: "3",
      KOOD: "5952",
      NIMETUS: "Palupera küla",
      NIMETUS_LIIGIGA: "Palupera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00005952",
    },
    {
      TASE: "3",
      KOOD: "5954",
      NIMETUS: "Palupere küla",
      NIMETUS_LIIGIGA: "Palupere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00005954",
    },
    {
      TASE: "3",
      KOOD: "5957",
      NIMETUS: "Palupõhja küla",
      NIMETUS_LIIGIGA: "Palupõhja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00005957",
    },
    {
      TASE: "3",
      KOOD: "5959",
      NIMETUS: "Palutaja küla",
      NIMETUS_LIIGIGA: "Palutaja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00005959",
    },
    {
      TASE: "3",
      KOOD: "5962",
      NIMETUS: "Palvere küla",
      NIMETUS_LIIGIGA: "Palvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00005962",
    },
    {
      TASE: "3",
      KOOD: "5965",
      NIMETUS: "Pamma küla",
      NIMETUS_LIIGIGA: "Pamma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005965",
    },
    {
      TASE: "3",
      KOOD: "5967",
      NIMETUS: "Pammana küla",
      NIMETUS_LIIGIGA: "Pammana küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005967",
    },
    {
      TASE: "3",
      KOOD: "5970",
      NIMETUS: "Pandivere küla",
      NIMETUS_LIIGIGA: "Pandivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00005970",
    },
    {
      TASE: "3",
      KOOD: "5971",
      NIMETUS: "Panga küla",
      NIMETUS_LIIGIGA: "Panga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00005971",
    },
    {
      TASE: "3",
      KOOD: "5973",
      NIMETUS: "Panga küla",
      NIMETUS_LIIGIGA: "Panga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005973",
    },
    {
      TASE: "3",
      KOOD: "5975",
      NIMETUS: "Pangodi küla",
      NIMETUS_LIIGIGA: "Pangodi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00005975",
    },
    {
      TASE: "3",
      KOOD: "5978",
      NIMETUS: "Paope küla",
      NIMETUS_LIIGIGA: "Paope küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00005978",
    },
    {
      TASE: "3",
      KOOD: "5979",
      NIMETUS: "Papiaru küla",
      NIMETUS_LIIGIGA: "Papiaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00005979",
    },
    {
      TASE: "3",
      KOOD: "5981",
      NIMETUS: "Papiaru küla",
      NIMETUS_LIIGIGA: "Papiaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00005981",
    },
    {
      TASE: "3",
      KOOD: "5984",
      NIMETUS: "Papisilla küla",
      NIMETUS_LIIGIGA: "Papisilla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00005984",
    },
    {
      TASE: "3",
      KOOD: "5986",
      NIMETUS: "Papsaare küla",
      NIMETUS_LIIGIGA: "Papsaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00005986",
    },
    {
      TASE: "3",
      KOOD: "5989",
      NIMETUS: "Paralepa alevik",
      NIMETUS_LIIGIGA: "Paralepa alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00005989",
    },
    {
      TASE: "3",
      KOOD: "5990",
      NIMETUS: "Parasmaa küla",
      NIMETUS_LIIGIGA: "Parasmaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00005990",
    },
    {
      TASE: "3",
      KOOD: "5992",
      NIMETUS: "Parapalu küla",
      NIMETUS_LIIGIGA: "Parapalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00005992",
    },
    {
      TASE: "3",
      KOOD: "5994",
      NIMETUS: "Parasmetsa küla",
      NIMETUS_LIIGIGA: "Parasmetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00005994",
    },
    {
      TASE: "3",
      KOOD: "5997",
      NIMETUS: "Parasmäe küla",
      NIMETUS_LIIGIGA: "Parasmäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00005997",
    },
    {
      TASE: "3",
      KOOD: "5999",
      NIMETUS: "Pargitaguse küla",
      NIMETUS_LIIGIGA: "Pargitaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "251",
      ADS_OID: "AY00005999",
    },
    {
      TASE: "3",
      KOOD: "6002",
      NIMETUS: "Pari küla",
      NIMETUS_LIIGIGA: "Pari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006002",
    },
    {
      TASE: "3",
      KOOD: "6004",
      NIMETUS: "Pariisi küla",
      NIMETUS_LIIGIGA: "Pariisi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00006004",
    },
    {
      TASE: "3",
      KOOD: "6007",
      NIMETUS: "Parika küla",
      NIMETUS_LIIGIGA: "Parika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006007",
    },
    {
      TASE: "3",
      KOOD: "6009",
      NIMETUS: "Parila küla",
      NIMETUS_LIIGIGA: "Parila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00006009",
    },
    {
      TASE: "3",
      KOOD: "6010",
      NIMETUS: "Parila küla",
      NIMETUS_LIIGIGA: "Parila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006010",
    },
    {
      TASE: "3",
      KOOD: "6012",
      NIMETUS: "Parisselja küla",
      NIMETUS_LIIGIGA: "Parisselja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00006012",
    },
    {
      TASE: "3",
      KOOD: "6015",
      NIMETUS: "Parmu küla",
      NIMETUS_LIIGIGA: "Parmu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006015",
    },
    {
      TASE: "3",
      KOOD: "6016",
      NIMETUS: "Parksi küla",
      NIMETUS_LIIGIGA: "Parksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00006016",
    },
    {
      TASE: "3",
      KOOD: "6017",
      NIMETUS: "Parksepa alevik",
      NIMETUS_LIIGIGA: "Parksepa alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006017",
    },
    {
      TASE: "3",
      KOOD: "6019",
      NIMETUS: "Parmupalu küla",
      NIMETUS_LIIGIGA: "Parmupalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006019",
    },
    {
      TASE: "3",
      KOOD: "6022",
      NIMETUS: "Partsaare küla",
      NIMETUS_LIIGIGA: "Partsaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00006022",
    },
    {
      TASE: "3",
      KOOD: "6024",
      NIMETUS: "Partsi küla",
      NIMETUS_LIIGIGA: "Partsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006024",
    },
    {
      TASE: "3",
      KOOD: "6025",
      NIMETUS: "Partsi küla",
      NIMETUS_LIIGIGA: "Partsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00006025",
    },
    {
      TASE: "3",
      KOOD: "6026",
      NIMETUS: "Passi küla",
      NIMETUS_LIIGIGA: "Passi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006026",
    },
    {
      TASE: "3",
      KOOD: "6031",
      NIMETUS: "Pastaku küla",
      NIMETUS_LIIGIGA: "Pastaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006031",
    },
    {
      TASE: "3",
      KOOD: "6034",
      NIMETUS: "Pataste küla",
      NIMETUS_LIIGIGA: "Pataste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00006034",
    },
    {
      TASE: "3",
      KOOD: "6036",
      NIMETUS: "Patika küla",
      NIMETUS_LIIGIGA: "Patika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00006036",
    },
    {
      TASE: "3",
      KOOD: "6037",
      NIMETUS: "Patika küla",
      NIMETUS_LIIGIGA: "Patika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00006037",
    },
    {
      TASE: "3",
      KOOD: "6040",
      NIMETUS: "Patjala küla",
      NIMETUS_LIIGIGA: "Patjala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00006040",
    },
    {
      TASE: "3",
      KOOD: "6042",
      NIMETUS: "Patküla",
      NIMETUS_LIIGIGA: "Patküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00006042",
    },
    {
      TASE: "3",
      KOOD: "6045",
      NIMETUS: "Pattina küla",
      NIMETUS_LIIGIGA: "Pattina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00006045",
    },
    {
      TASE: "3",
      KOOD: "6048",
      NIMETUS: "Pauastvere küla",
      NIMETUS_LIIGIGA: "Pauastvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00006048",
    },
    {
      TASE: "3",
      KOOD: "6050",
      NIMETUS: "Pauliku küla",
      NIMETUS_LIIGIGA: "Pauliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "251",
      ADS_OID: "AY00006050",
    },
    {
      TASE: "3",
      KOOD: "6051",
      NIMETUS: "Pausakunnu küla",
      NIMETUS_LIIGIGA: "Pausakunnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006051",
    },
    {
      TASE: "3",
      KOOD: "6052",
      NIMETUS: "Paunaste küla",
      NIMETUS_LIIGIGA: "Paunaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00006052",
    },
    {
      TASE: "3",
      KOOD: "6053",
      NIMETUS: "Paunküla",
      NIMETUS_LIIGIGA: "Paunküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00006053",
    },
    {
      TASE: "3",
      KOOD: "6054",
      NIMETUS: "Peantse küla",
      NIMETUS_LIIGIGA: "Peantse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006054",
    },
    {
      TASE: "3",
      KOOD: "6057",
      NIMETUS: "Peatskivi küla",
      NIMETUS_LIIGIGA: "Peatskivi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006057",
    },
    {
      TASE: "3",
      KOOD: "6060",
      NIMETUS: "Pedajamäe küla",
      NIMETUS_LIIGIGA: "Pedajamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00006060",
    },
    {
      TASE: "3",
      KOOD: "6062",
      NIMETUS: "Pedase küla",
      NIMETUS_LIIGIGA: "Pedase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00006062",
    },
    {
      TASE: "3",
      KOOD: "6065",
      NIMETUS: "Pedaspea küla",
      NIMETUS_LIIGIGA: "Pedaspea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00006065",
    },
    {
      TASE: "3",
      KOOD: "6067",
      NIMETUS: "Pedassaare küla",
      NIMETUS_LIIGIGA: "Pedassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00006067",
    },
    {
      TASE: "3",
      KOOD: "6068",
      NIMETUS: "Pedassaare küla",
      NIMETUS_LIIGIGA: "Pedassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00006068",
    },
    {
      TASE: "3",
      KOOD: "6069",
      NIMETUS: "Pedejä küla",
      NIMETUS_LIIGIGA: "Pedejä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006069",
    },
    {
      TASE: "3",
      KOOD: "6071",
      NIMETUS: "Pedaste küla",
      NIMETUS_LIIGIGA: "Pedaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006071",
    },
    {
      TASE: "3",
      KOOD: "6073",
      NIMETUS: "Pedja küla",
      NIMETUS_LIIGIGA: "Pedja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00006073",
    },
    {
      TASE: "3",
      KOOD: "6076",
      NIMETUS: "Peebu küla",
      NIMETUS_LIIGIGA: "Peebu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006076",
    },
    {
      TASE: "3",
      KOOD: "6077",
      NIMETUS: "Peederga küla",
      NIMETUS_LIIGIGA: "Peederga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006077",
    },
    {
      TASE: "3",
      KOOD: "6078",
      NIMETUS: "Peedo küla",
      NIMETUS_LIIGIGA: "Peedo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006078",
    },
    {
      TASE: "3",
      KOOD: "6079",
      NIMETUS: "Peedu küla",
      NIMETUS_LIIGIGA: "Peedu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00006079",
    },
    {
      TASE: "3",
      KOOD: "6081",
      NIMETUS: "Peeri küla",
      NIMETUS_LIIGIGA: "Peeri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00006081",
    },
    {
      TASE: "3",
      KOOD: "6082",
      NIMETUS: "Peerni küla",
      NIMETUS_LIIGIGA: "Peerni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00006082",
    },
    {
      TASE: "3",
      KOOD: "6084",
      NIMETUS: "Peeterristi küla",
      NIMETUS_LIIGIGA: "Peeterristi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00006084",
    },
    {
      TASE: "3",
      KOOD: "6085",
      NIMETUS: "Peetri alevik",
      NIMETUS_LIIGIGA: "Peetri alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00006085",
    },
    {
      TASE: "3",
      KOOD: "6086",
      NIMETUS: "Peetri alevik",
      NIMETUS_LIIGIGA: "Peetri alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00006086",
    },
    {
      TASE: "3",
      KOOD: "6089",
      NIMETUS: "Peetrimõisa küla",
      NIMETUS_LIIGIGA: "Peetrimõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00006089",
    },
    {
      TASE: "3",
      KOOD: "6090",
      NIMETUS: "Peetrimõisa küla",
      NIMETUS_LIIGIGA: "Peetrimõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006090",
    },
    {
      TASE: "3",
      KOOD: "6093",
      NIMETUS: "Pehka küla",
      NIMETUS_LIIGIGA: "Pehka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00006093",
    },
    {
      TASE: "3",
      KOOD: "6094",
      NIMETUS: "Pelsi küla",
      NIMETUS_LIIGIGA: "Pelsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00006094",
    },
    {
      TASE: "3",
      KOOD: "6096",
      NIMETUS: "Penijõe küla",
      NIMETUS_LIIGIGA: "Penijõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006096",
    },
    {
      TASE: "3",
      KOOD: "6098",
      NIMETUS: "Peningi küla",
      NIMETUS_LIIGIGA: "Peningi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00006098",
    },
    {
      TASE: "3",
      KOOD: "6103",
      NIMETUS: "Penu küla",
      NIMETUS_LIIGIGA: "Penu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00006103",
    },
    {
      TASE: "3",
      KOOD: "6106",
      NIMETUS: "Penuja küla",
      NIMETUS_LIIGIGA: "Penuja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00006106",
    },
    {
      TASE: "3",
      KOOD: "6107",
      NIMETUS: "Peraküla",
      NIMETUS_LIIGIGA: "Peraküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006107",
    },
    {
      TASE: "3",
      KOOD: "6109",
      NIMETUS: "Peraküla",
      NIMETUS_LIIGIGA: "Peraküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00006109",
    },
    {
      TASE: "3",
      KOOD: "6111",
      NIMETUS: "Perametsa küla",
      NIMETUS_LIIGIGA: "Perametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006111",
    },
    {
      TASE: "3",
      KOOD: "6112",
      NIMETUS: "Perametsa küla",
      NIMETUS_LIIGIGA: "Perametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006112",
    },
    {
      TASE: "3",
      KOOD: "6113",
      NIMETUS: "Pereküla",
      NIMETUS_LIIGIGA: "Pereküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00006113",
    },
    {
      TASE: "3",
      KOOD: "6115",
      NIMETUS: "Perdaku küla",
      NIMETUS_LIIGIGA: "Perdaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00006115",
    },
    {
      TASE: "3",
      KOOD: "6117",
      NIMETUS: "Peressaare küla",
      NIMETUS_LIIGIGA: "Peressaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00006117",
    },
    {
      TASE: "3",
      KOOD: "6120",
      NIMETUS: "Peri küla",
      NIMETUS_LIIGIGA: "Peri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00006120",
    },
    {
      TASE: "3",
      KOOD: "6122",
      NIMETUS: "Perila küla",
      NIMETUS_LIIGIGA: "Perila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00006122",
    },
    {
      TASE: "3",
      KOOD: "6125",
      NIMETUS: "Perjatsi küla",
      NIMETUS_LIIGIGA: "Perjatsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00006125",
    },
    {
      TASE: "3",
      KOOD: "6127",
      NIMETUS: "Permisküla",
      NIMETUS_LIIGIGA: "Permisküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00006127",
    },
    {
      TASE: "3",
      KOOD: "6128",
      NIMETUS: "Petrakuudi küla",
      NIMETUS_LIIGIGA: "Petrakuudi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006128",
    },
    {
      TASE: "3",
      KOOD: "6134",
      NIMETUS: "Pibari küla",
      NIMETUS_LIIGIGA: "Pibari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00006134",
    },
    {
      TASE: "3",
      KOOD: "6135",
      NIMETUS: "Piha küla",
      NIMETUS_LIIGIGA: "Piha küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006135",
    },
    {
      TASE: "3",
      KOOD: "6137",
      NIMETUS: "Pidula küla",
      NIMETUS_LIIGIGA: "Pidula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006137",
    },
    {
      TASE: "3",
      KOOD: "6140",
      NIMETUS: "Pihali küla",
      NIMETUS_LIIGIGA: "Pihali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00006140",
    },
    {
      TASE: "3",
      KOOD: "6143",
      NIMETUS: "Pihke küla",
      NIMETUS_LIIGIGA: "Pihke küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00006143",
    },
    {
      TASE: "3",
      KOOD: "6145",
      NIMETUS: "Pihla küla",
      NIMETUS_LIIGIGA: "Pihla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006145",
    },
    {
      TASE: "3",
      KOOD: "6148",
      NIMETUS: "Pihlaspea küla",
      NIMETUS_LIIGIGA: "Pihlaspea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00006148",
    },
    {
      TASE: "3",
      KOOD: "6150",
      NIMETUS: "Pihleni küla",
      NIMETUS_LIIGIGA: "Pihleni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00006150",
    },
    {
      TASE: "3",
      KOOD: "6153",
      NIMETUS: "Pihtla küla",
      NIMETUS_LIIGIGA: "Pihtla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006153",
    },
    {
      TASE: "3",
      KOOD: "6155",
      NIMETUS: "Pihva küla",
      NIMETUS_LIIGIGA: "Pihva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "793",
      ADS_OID: "AY00006155",
    },
    {
      TASE: "3",
      KOOD: "6158",
      NIMETUS: "Piibe küla",
      NIMETUS_LIIGIGA: "Piibe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00006158",
    },
    {
      TASE: "3",
      KOOD: "6161",
      NIMETUS: "Piibumäe küla",
      NIMETUS_LIIGIGA: "Piibumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006161",
    },
    {
      TASE: "3",
      KOOD: "6163",
      NIMETUS: "Piigandi küla",
      NIMETUS_LIIGIGA: "Piigandi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00006163",
    },
    {
      TASE: "3",
      KOOD: "6164",
      NIMETUS: "Piigandi küla",
      NIMETUS_LIIGIGA: "Piigandi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006164",
    },
    {
      TASE: "3",
      KOOD: "6167",
      NIMETUS: "Piigaste küla",
      NIMETUS_LIIGIGA: "Piigaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00006167",
    },
    {
      TASE: "3",
      KOOD: "6169",
      NIMETUS: "Piila küla",
      NIMETUS_LIIGIGA: "Piila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006169",
    },
    {
      TASE: "3",
      KOOD: "6172",
      NIMETUS: "Piilse küla",
      NIMETUS_LIIGIGA: "Piilse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00006172",
    },
    {
      TASE: "3",
      KOOD: "6174",
      NIMETUS: "Piilsi küla",
      NIMETUS_LIIGIGA: "Piilsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00006174",
    },
    {
      TASE: "3",
      KOOD: "6177",
      NIMETUS: "Piilu küla",
      NIMETUS_LIIGIGA: "Piilu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00006177",
    },
    {
      TASE: "3",
      KOOD: "6179",
      NIMETUS: "Piipsemäe küla",
      NIMETUS_LIIGIGA: "Piipsemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006179",
    },
    {
      TASE: "3",
      KOOD: "6182",
      NIMETUS: "Piira küla",
      NIMETUS_LIIGIGA: "Piira küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00006182",
    },
    {
      TASE: "3",
      KOOD: "6184",
      NIMETUS: "Piiri küla",
      NIMETUS_LIIGIGA: "Piiri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00006184",
    },
    {
      TASE: "3",
      KOOD: "6185",
      NIMETUS: "Piiri küla",
      NIMETUS_LIIGIGA: "Piiri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00006185",
    },
    {
      TASE: "3",
      KOOD: "6186",
      NIMETUS: "Piiri küla",
      NIMETUS_LIIGIGA: "Piiri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00006186",
    },
    {
      TASE: "3",
      KOOD: "6189",
      NIMETUS: "Piirivarbe küla",
      NIMETUS_LIIGIGA: "Piirivarbe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006189",
    },
    {
      TASE: "3",
      KOOD: "6191",
      NIMETUS: "Piirsalu küla",
      NIMETUS_LIIGIGA: "Piirsalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00006191",
    },
    {
      TASE: "3",
      KOOD: "6194",
      NIMETUS: "Piirumi küla",
      NIMETUS_LIIGIGA: "Piirumi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00006194",
    },
    {
      TASE: "3",
      KOOD: "6196",
      NIMETUS: "Piisi küla",
      NIMETUS_LIIGIGA: "Piisi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00006196",
    },
    {
      TASE: "3",
      KOOD: "6200",
      NIMETUS: "Piisu küla",
      NIMETUS_LIIGIGA: "Piisu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006200",
    },
    {
      TASE: "3",
      KOOD: "6201",
      NIMETUS: "Piistaoja küla",
      NIMETUS_LIIGIGA: "Piistaoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00006201",
    },
    {
      TASE: "3",
      KOOD: "6204",
      NIMETUS: "Piisupi küla",
      NIMETUS_LIIGIGA: "Piisupi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00006204",
    },
    {
      TASE: "3",
      KOOD: "6206",
      NIMETUS: "Piiumetsa küla",
      NIMETUS_LIIGIGA: "Piiumetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00006206",
    },
    {
      TASE: "3",
      KOOD: "6209",
      NIMETUS: "Pikajärve küla",
      NIMETUS_LIIGIGA: "Pikajärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00006209",
    },
    {
      TASE: "3",
      KOOD: "6212",
      NIMETUS: "Pikakannu küla",
      NIMETUS_LIIGIGA: "Pikakannu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006212",
    },
    {
      TASE: "3",
      KOOD: "6214",
      NIMETUS: "Pikaküla",
      NIMETUS_LIIGIGA: "Pikaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00006214",
    },
    {
      TASE: "3",
      KOOD: "6217",
      NIMETUS: "Pikareinu küla",
      NIMETUS_LIIGIGA: "Pikareinu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00006217",
    },
    {
      TASE: "3",
      KOOD: "6219",
      NIMETUS: "Pikaristi küla",
      NIMETUS_LIIGIGA: "Pikaristi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00006219",
    },
    {
      TASE: "3",
      KOOD: "6220",
      NIMETUS: "Pikasilla küla",
      NIMETUS_LIIGIGA: "Pikasilla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006220",
    },
    {
      TASE: "3",
      KOOD: "6222",
      NIMETUS: "Pikasilla küla",
      NIMETUS_LIIGIGA: "Pikasilla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00006222",
    },
    {
      TASE: "3",
      KOOD: "6224",
      NIMETUS: "Pikati küla",
      NIMETUS_LIIGIGA: "Pikati küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00006224",
    },
    {
      TASE: "3",
      KOOD: "6227",
      NIMETUS: "Pikavere küla",
      NIMETUS_LIIGIGA: "Pikavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006227",
    },
    {
      TASE: "3",
      KOOD: "6228",
      NIMETUS: "Pikavere küla",
      NIMETUS_LIIGIGA: "Pikavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00006228",
    },
    {
      TASE: "3",
      KOOD: "6230",
      NIMETUS: "Pikevere küla",
      NIMETUS_LIIGIGA: "Pikevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00006230",
    },
    {
      TASE: "3",
      KOOD: "6233",
      NIMETUS: "Pikkjärve küla",
      NIMETUS_LIIGIGA: "Pikkjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00006233",
    },
    {
      TASE: "3",
      KOOD: "6234",
      NIMETUS: "Pikkjärve küla",
      NIMETUS_LIIGIGA: "Pikkjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00006234",
    },
    {
      TASE: "3",
      KOOD: "6236",
      NIMETUS: "Pikknurme küla",
      NIMETUS_LIIGIGA: "Pikknurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00006236",
    },
    {
      TASE: "3",
      KOOD: "6239",
      NIMETUS: "Pikru küla",
      NIMETUS_LIIGIGA: "Pikru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006239",
    },
    {
      TASE: "3",
      KOOD: "6240",
      NIMETUS: "Pildiküla",
      NIMETUS_LIIGIGA: "Pildiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00006240",
    },
    {
      TASE: "3",
      KOOD: "6241",
      NIMETUS: "Pikva küla",
      NIMETUS_LIIGIGA: "Pikva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00006241",
    },
    {
      TASE: "3",
      KOOD: "6247",
      NIMETUS: "Pilistvere küla",
      NIMETUS_LIIGIGA: "Pilistvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00006247",
    },
    {
      TASE: "3",
      KOOD: "6249",
      NIMETUS: "Pilka küla",
      NIMETUS_LIIGIGA: "Pilka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00006249",
    },
    {
      TASE: "3",
      KOOD: "6252",
      NIMETUS: "Pilkuse küla",
      NIMETUS_LIIGIGA: "Pilkuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00006252",
    },
    {
      TASE: "3",
      KOOD: "6253",
      NIMETUS: "Pillardi küla",
      NIMETUS_LIIGIGA: "Pillardi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006253",
    },
    {
      TASE: "3",
      KOOD: "6254",
      NIMETUS: "Pillapalu küla",
      NIMETUS_LIIGIGA: "Pillapalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00006254",
    },
    {
      TASE: "3",
      KOOD: "6255",
      NIMETUS: "Pille küla",
      NIMETUS_LIIGIGA: "Pille küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006255",
    },
    {
      TASE: "3",
      KOOD: "6257",
      NIMETUS: "Pilpa küla",
      NIMETUS_LIIGIGA: "Pilpa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00006257",
    },
    {
      TASE: "3",
      KOOD: "6258",
      NIMETUS: "Pilpaküla",
      NIMETUS_LIIGIGA: "Pilpaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006258",
    },
    {
      TASE: "3",
      KOOD: "6259",
      NIMETUS: "Pilpaküla",
      NIMETUS_LIIGIGA: "Pilpaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00006259",
    },
    {
      TASE: "3",
      KOOD: "6262",
      NIMETUS: "Pilu küla",
      NIMETUS_LIIGIGA: "Pilu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00006262",
    },
    {
      TASE: "3",
      KOOD: "6265",
      NIMETUS: "Pimestiku küla",
      NIMETUS_LIIGIGA: "Pimestiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00006265",
    },
    {
      TASE: "3",
      KOOD: "6268",
      NIMETUS: "Pindi küla",
      NIMETUS_LIIGIGA: "Pindi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00006268",
    },
    {
      TASE: "3",
      KOOD: "6269",
      NIMETUS: "Pindi küla",
      NIMETUS_LIIGIGA: "Pindi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006269",
    },
    {
      TASE: "3",
      KOOD: "6271",
      NIMETUS: "Pinska küla",
      NIMETUS_LIIGIGA: "Pinska küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006271",
    },
    {
      TASE: "3",
      KOOD: "6274",
      NIMETUS: "Pirgu küla",
      NIMETUS_LIIGIGA: "Pirgu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00006274",
    },
    {
      TASE: "3",
      KOOD: "6276",
      NIMETUS: "Pirmastu küla",
      NIMETUS_LIIGIGA: "Pirmastu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006276",
    },
    {
      TASE: "3",
      KOOD: "6278",
      NIMETUS: "Pitsalu küla",
      NIMETUS_LIIGIGA: "Pitsalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00006278",
    },
    {
      TASE: "3",
      KOOD: "6280",
      NIMETUS: "Pisisaare küla",
      NIMETUS_LIIGIGA: "Pisisaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00006280",
    },
    {
      TASE: "3",
      KOOD: "6283",
      NIMETUS: "Piusa küla",
      NIMETUS_LIIGIGA: "Piusa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006283",
    },
    {
      TASE: "3",
      KOOD: "6286",
      NIMETUS: "Pivarootsi küla",
      NIMETUS_LIIGIGA: "Pivarootsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006286",
    },
    {
      TASE: "3",
      KOOD: "6287",
      NIMETUS: "Plaksi küla",
      NIMETUS_LIIGIGA: "Plaksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006287",
    },
    {
      TASE: "3",
      KOOD: "6288",
      NIMETUS: "Pliia küla",
      NIMETUS_LIIGIGA: "Pliia küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00006288",
    },
    {
      TASE: "3",
      KOOD: "6290",
      NIMETUS: "Plaani küla",
      NIMETUS_LIIGIGA: "Plaani küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006290",
    },
    {
      TASE: "3",
      KOOD: "6293",
      NIMETUS: "Plessi küla",
      NIMETUS_LIIGIGA: "Plessi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00006293",
    },
    {
      TASE: "3",
      KOOD: "6296",
      NIMETUS: "Plika küla",
      NIMETUS_LIIGIGA: "Plika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00006296",
    },
    {
      TASE: "3",
      KOOD: "6297",
      NIMETUS: "Poama küla",
      NIMETUS_LIIGIGA: "Poama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00006297",
    },
    {
      TASE: "3",
      KOOD: "6300",
      NIMETUS: "Poaka küla",
      NIMETUS_LIIGIGA: "Poaka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00006300",
    },
    {
      TASE: "3",
      KOOD: "6302",
      NIMETUS: "Poanse küla",
      NIMETUS_LIIGIGA: "Poanse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00006302",
    },
    {
      TASE: "3",
      KOOD: "6305",
      NIMETUS: "Podmotsa küla",
      NIMETUS_LIIGIGA: "Podmotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00006305",
    },
    {
      TASE: "3",
      KOOD: "6308",
      NIMETUS: "Pohla küla",
      NIMETUS_LIIGIGA: "Pohla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00006308",
    },
    {
      TASE: "3",
      KOOD: "6311",
      NIMETUS: "Poka küla",
      NIMETUS_LIIGIGA: "Poka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00006311",
    },
    {
      TASE: "3",
      KOOD: "6312",
      NIMETUS: "Poka küla",
      NIMETUS_LIIGIGA: "Poka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00006312",
    },
    {
      TASE: "3",
      KOOD: "6313",
      NIMETUS: "Poksa küla",
      NIMETUS_LIIGIGA: "Poksa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00006313",
    },
    {
      TASE: "3",
      KOOD: "6314",
      NIMETUS: "Poksi küla",
      NIMETUS_LIIGIGA: "Poksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00006314",
    },
    {
      TASE: "3",
      KOOD: "6317",
      NIMETUS: "Polli küla",
      NIMETUS_LIIGIGA: "Polli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00006317",
    },
    {
      TASE: "3",
      KOOD: "6319",
      NIMETUS: "Polovina küla",
      NIMETUS_LIIGIGA: "Polovina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00006319",
    },
    {
      TASE: "3",
      KOOD: "6322",
      NIMETUS: "Poole küla",
      NIMETUS_LIIGIGA: "Poole küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006322",
    },
    {
      TASE: "3",
      KOOD: "6325",
      NIMETUS: "Pootsi küla",
      NIMETUS_LIIGIGA: "Pootsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00006325",
    },
    {
      TASE: "3",
      KOOD: "6326",
      NIMETUS: "Popovitsa küla",
      NIMETUS_LIIGIGA: "Popovitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00006326",
    },
    {
      TASE: "3",
      KOOD: "6327",
      NIMETUS: "Pootsiku küla",
      NIMETUS_LIIGIGA: "Pootsiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00006327",
    },
    {
      TASE: "3",
      KOOD: "6328",
      NIMETUS: "Poriküla",
      NIMETUS_LIIGIGA: "Poriküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00006328",
    },
    {
      TASE: "3",
      KOOD: "6330",
      NIMETUS: "Pori küla",
      NIMETUS_LIIGIGA: "Pori küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00006330",
    },
    {
      TASE: "3",
      KOOD: "6333",
      NIMETUS: "Porkuni küla",
      NIMETUS_LIIGIGA: "Porkuni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00006333",
    },
    {
      TASE: "3",
      KOOD: "6335",
      NIMETUS: "Pornuse küla",
      NIMETUS_LIIGIGA: "Pornuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00006335",
    },
    {
      TASE: "3",
      KOOD: "6336",
      NIMETUS: "Posti küla",
      NIMETUS_LIIGIGA: "Posti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00006336",
    },
    {
      TASE: "3",
      KOOD: "6338",
      NIMETUS: "Porsa küla",
      NIMETUS_LIIGIGA: "Porsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00006338",
    },
    {
      TASE: "3",
      KOOD: "8357",
      NIMETUS: "Tsiiruli küla",
      NIMETUS_LIIGIGA: "Tsiiruli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008357",
    },
    {
      TASE: "3",
      KOOD: "8358",
      NIMETUS: "Tsilgutaja küla",
      NIMETUS_LIIGIGA: "Tsilgutaja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008358",
    },
    {
      TASE: "3",
      KOOD: "8359",
      NIMETUS: "Tserebi küla",
      NIMETUS_LIIGIGA: "Tserebi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008359",
    },
    {
      TASE: "3",
      KOOD: "8360",
      NIMETUS: "Tsiistre küla",
      NIMETUS_LIIGIGA: "Tsiistre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008360",
    },
    {
      TASE: "3",
      KOOD: "8363",
      NIMETUS: "Tsirgu küla",
      NIMETUS_LIIGIGA: "Tsirgu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008363",
    },
    {
      TASE: "3",
      KOOD: "8365",
      NIMETUS: "Tsirguliina alevik",
      NIMETUS_LIIGIGA: "Tsirguliina alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00008365",
    },
    {
      TASE: "3",
      KOOD: "8366",
      NIMETUS: "Tsirgupalu küla",
      NIMETUS_LIIGIGA: "Tsirgupalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008366",
    },
    {
      TASE: "3",
      KOOD: "8367",
      NIMETUS: "Tsitre küla",
      NIMETUS_LIIGIGA: "Tsitre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00008367",
    },
    {
      TASE: "3",
      KOOD: "8368",
      NIMETUS: "Tsirgumäe küla",
      NIMETUS_LIIGIGA: "Tsirgumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00008368",
    },
    {
      TASE: "3",
      KOOD: "8370",
      NIMETUS: "Tsirksi küla",
      NIMETUS_LIIGIGA: "Tsirksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00008370",
    },
    {
      TASE: "3",
      KOOD: "8372",
      NIMETUS: "Tsolgo küla",
      NIMETUS_LIIGIGA: "Tsolgo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008372",
    },
    {
      TASE: "3",
      KOOD: "8374",
      NIMETUS: "Tsolli küla",
      NIMETUS_LIIGIGA: "Tsolli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008374",
    },
    {
      TASE: "3",
      KOOD: "8375",
      NIMETUS: "Tsumba küla",
      NIMETUS_LIIGIGA: "Tsumba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008375",
    },
    {
      TASE: "3",
      KOOD: "8376",
      NIMETUS: "Tsolli küla",
      NIMETUS_LIIGIGA: "Tsolli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008376",
    },
    {
      TASE: "3",
      KOOD: "8378",
      NIMETUS: "Tsooru küla",
      NIMETUS_LIIGIGA: "Tsooru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00008378",
    },
    {
      TASE: "3",
      KOOD: "8379",
      NIMETUS: "Tsutsu küla",
      NIMETUS_LIIGIGA: "Tsutsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008379",
    },
    {
      TASE: "3",
      KOOD: "8382",
      NIMETUS: "Tubala küla",
      NIMETUS_LIIGIGA: "Tubala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008382",
    },
    {
      TASE: "3",
      KOOD: "8385",
      NIMETUS: "Tuderna küla",
      NIMETUS_LIIGIGA: "Tuderna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008385",
    },
    {
      TASE: "3",
      KOOD: "8388",
      NIMETUS: "Tudre küla",
      NIMETUS_LIIGIGA: "Tudre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00008388",
    },
    {
      TASE: "3",
      KOOD: "8390",
      NIMETUS: "Tudu alevik",
      NIMETUS_LIIGIGA: "Tudu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00008390",
    },
    {
      TASE: "3",
      KOOD: "8393",
      NIMETUS: "Tudulinna küla",
      NIMETUS_LIIGIGA: "Tudulinna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00008393",
    },
    {
      TASE: "3",
      KOOD: "8396",
      NIMETUS: "Tuhala küla",
      NIMETUS_LIIGIGA: "Tuhala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00008396",
    },
    {
      TASE: "3",
      KOOD: "8398",
      NIMETUS: "Tuhalaane küla",
      NIMETUS_LIIGIGA: "Tuhalaane küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00008398",
    },
    {
      TASE: "3",
      KOOD: "8401",
      NIMETUS: "Tuhu küla",
      NIMETUS_LIIGIGA: "Tuhu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008401",
    },
    {
      TASE: "3",
      KOOD: "8404",
      NIMETUS: "Tuimõisa küla",
      NIMETUS_LIIGIGA: "Tuimõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00008404",
    },
    {
      TASE: "3",
      KOOD: "8405",
      NIMETUS: "Tuka küla",
      NIMETUS_LIIGIGA: "Tuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00008405",
    },
    {
      TASE: "3",
      KOOD: "8406",
      NIMETUS: "Tuiu küla",
      NIMETUS_LIIGIGA: "Tuiu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008406",
    },
    {
      TASE: "3",
      KOOD: "8409",
      NIMETUS: "Tuksi küla / Bergsby",
      NIMETUS_LIIGIGA: "Tuksi küla / Bergsby",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00008409",
    },
    {
      TASE: "3",
      KOOD: "8410",
      NIMETUS: "Tummelka küla",
      NIMETUS_LIIGIGA: "Tummelka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008410",
    },
    {
      TASE: "3",
      KOOD: "8412",
      NIMETUS: "Tumala küla",
      NIMETUS_LIIGIGA: "Tumala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008412",
    },
    {
      TASE: "3",
      KOOD: "8415",
      NIMETUS: "Tundu küla",
      NIMETUS_LIIGIGA: "Tundu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008415",
    },
    {
      TASE: "3",
      KOOD: "8416",
      NIMETUS: "Tuplova küla",
      NIMETUS_LIIGIGA: "Tuplova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008416",
    },
    {
      TASE: "3",
      KOOD: "8418",
      NIMETUS: "Tupenurme küla",
      NIMETUS_LIIGIGA: "Tupenurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00008418",
    },
    {
      TASE: "3",
      KOOD: "8421",
      NIMETUS: "Turba alevik",
      NIMETUS_LIIGIGA: "Turba alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00008421",
    },
    {
      TASE: "3",
      KOOD: "8424",
      NIMETUS: "Turbuneeme küla",
      NIMETUS_LIIGIGA: "Turbuneeme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00008424",
    },
    {
      TASE: "3",
      KOOD: "8426",
      NIMETUS: "Turja küla",
      NIMETUS_LIIGIGA: "Turja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008426",
    },
    {
      TASE: "3",
      KOOD: "8429",
      NIMETUS: "Tursa küla",
      NIMETUS_LIIGIGA: "Tursa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008429",
    },
    {
      TASE: "3",
      KOOD: "8431",
      NIMETUS: "Turva küla",
      NIMETUS_LIIGIGA: "Turva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008431",
    },
    {
      TASE: "3",
      KOOD: "8434",
      NIMETUS: "Turvalepa küla",
      NIMETUS_LIIGIGA: "Turvalepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00008434",
    },
    {
      TASE: "3",
      KOOD: "8437",
      NIMETUS: "Tusari küla",
      NIMETUS_LIIGIGA: "Tusari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00008437",
    },
    {
      TASE: "3",
      KOOD: "8439",
      NIMETUS: "Tusti küla",
      NIMETUS_LIIGIGA: "Tusti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008439",
    },
    {
      TASE: "3",
      KOOD: "8440",
      NIMETUS: "Tusti küla",
      NIMETUS_LIIGIGA: "Tusti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00008440",
    },
    {
      TASE: "3",
      KOOD: "8441",
      NIMETUS: "Tuti küla",
      NIMETUS_LIIGIGA: "Tuti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00008441",
    },
    {
      TASE: "3",
      KOOD: "8442",
      NIMETUS: "Tutermaa küla",
      NIMETUS_LIIGIGA: "Tutermaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00008442",
    },
    {
      TASE: "3",
      KOOD: "8443",
      NIMETUS: "Tutku küla",
      NIMETUS_LIIGIGA: "Tutku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008443",
    },
    {
      TASE: "3",
      KOOD: "8448",
      NIMETUS: "Tuuka küla",
      NIMETUS_LIIGIGA: "Tuuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008448",
    },
    {
      TASE: "3",
      KOOD: "8450",
      NIMETUS: "Tuula küla",
      NIMETUS_LIIGIGA: "Tuula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00008450",
    },
    {
      TASE: "3",
      KOOD: "8453",
      NIMETUS: "Tuulavere küla",
      NIMETUS_LIIGIGA: "Tuulavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00008453",
    },
    {
      TASE: "3",
      KOOD: "8454",
      NIMETUS: "Tuulevälja küla",
      NIMETUS_LIIGIGA: "Tuulevälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00008454",
    },
    {
      TASE: "3",
      KOOD: "8455",
      NIMETUS: "Tuulemäe küla",
      NIMETUS_LIIGIGA: "Tuulemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00008455",
    },
    {
      TASE: "3",
      KOOD: "8458",
      NIMETUS: "Tuuliku küla",
      NIMETUS_LIIGIGA: "Tuuliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00008458",
    },
    {
      TASE: "3",
      KOOD: "8459",
      NIMETUS: "Tuulova küla",
      NIMETUS_LIIGIGA: "Tuulova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008459",
    },
    {
      TASE: "3",
      KOOD: "8460",
      NIMETUS: "Tuulna küla",
      NIMETUS_LIIGIGA: "Tuulna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00008460",
    },
    {
      TASE: "3",
      KOOD: "8463",
      NIMETUS: "Tuuraste küla",
      NIMETUS_LIIGIGA: "Tuuraste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00008463",
    },
    {
      TASE: "3",
      KOOD: "8465",
      NIMETUS: "Tuuru küla",
      NIMETUS_LIIGIGA: "Tuuru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00008465",
    },
    {
      TASE: "3",
      KOOD: "8469",
      NIMETUS: "Tõdu küla",
      NIMETUS_LIIGIGA: "Tõdu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00008469",
    },
    {
      TASE: "3",
      KOOD: "8472",
      NIMETUS: "Tõdva küla",
      NIMETUS_LIIGIGA: "Tõdva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00008472",
    },
    {
      TASE: "3",
      KOOD: "8475",
      NIMETUS: "Tõhela küla",
      NIMETUS_LIIGIGA: "Tõhela küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00008475",
    },
    {
      TASE: "3",
      KOOD: "8477",
      NIMETUS: "Tõhelgi küla",
      NIMETUS_LIIGIGA: "Tõhelgi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "651",
      ADS_OID: "AY00008477",
    },
    {
      TASE: "3",
      KOOD: "8478",
      NIMETUS: "Tõitse küla",
      NIMETUS_LIIGIGA: "Tõitse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008478",
    },
    {
      TASE: "3",
      KOOD: "8480",
      NIMETUS: "Tõikvere küla",
      NIMETUS_LIIGIGA: "Tõikvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00008480",
    },
    {
      TASE: "3",
      KOOD: "8483",
      NIMETUS: "Tõivere küla",
      NIMETUS_LIIGIGA: "Tõivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00008483",
    },
    {
      TASE: "3",
      KOOD: "8488",
      NIMETUS: "Tõlli küla",
      NIMETUS_LIIGIGA: "Tõlli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00008488",
    },
    {
      TASE: "3",
      KOOD: "8489",
      NIMETUS: "Tõlli küla",
      NIMETUS_LIIGIGA: "Tõlli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008489",
    },
    {
      TASE: "3",
      KOOD: "8491",
      NIMETUS: "Tõlliste küla",
      NIMETUS_LIIGIGA: "Tõlliste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00008491",
    },
    {
      TASE: "3",
      KOOD: "8493",
      NIMETUS: "Tõlluste küla",
      NIMETUS_LIIGIGA: "Tõlluste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008493",
    },
    {
      TASE: "3",
      KOOD: "8496",
      NIMETUS: "Tömbi küla",
      NIMETUS_LIIGIGA: "Tömbi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008496",
    },
    {
      TASE: "3",
      KOOD: "8499",
      NIMETUS: "Tõmmiku küla",
      NIMETUS_LIIGIGA: "Tõmmiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00008499",
    },
    {
      TASE: "3",
      KOOD: "8502",
      NIMETUS: "Tõnija küla",
      NIMETUS_LIIGIGA: "Tõnija küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008502",
    },
    {
      TASE: "3",
      KOOD: "8503",
      NIMETUS: "Tõnkova küla",
      NIMETUS_LIIGIGA: "Tõnkova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008503",
    },
    {
      TASE: "3",
      KOOD: "8504",
      NIMETUS: "Tõnissaare küla",
      NIMETUS_LIIGIGA: "Tõnissaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008504",
    },
    {
      TASE: "3",
      KOOD: "8507",
      NIMETUS: "Tõnuküla",
      NIMETUS_LIIGIGA: "Tõnuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008507",
    },
    {
      TASE: "3",
      KOOD: "8509",
      NIMETUS: "Tõnumaa küla",
      NIMETUS_LIIGIGA: "Tõnumaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00008509",
    },
    {
      TASE: "3",
      KOOD: "8510",
      NIMETUS: "Tõrdu küla",
      NIMETUS_LIIGIGA: "Tõrdu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00008510",
    },
    {
      TASE: "3",
      KOOD: "8512",
      NIMETUS: "Tõravere alevik",
      NIMETUS_LIIGIGA: "Tõravere alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00008512",
    },
    {
      TASE: "3",
      KOOD: "8515",
      NIMETUS: "Tõrenurme küla",
      NIMETUS_LIIGIGA: "Tõrenurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00008515",
    },
    {
      TASE: "3",
      KOOD: "8516",
      NIMETUS: "Tõre küla",
      NIMETUS_LIIGIGA: "Tõre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008516",
    },
    {
      TASE: "3",
      KOOD: "8517",
      NIMETUS: "Tõrise küla",
      NIMETUS_LIIGIGA: "Tõrise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008517",
    },
    {
      TASE: "3",
      KOOD: "8518",
      NIMETUS: "Tõrma küla",
      NIMETUS_LIIGIGA: "Tõrma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00008518",
    },
    {
      TASE: "3",
      KOOD: "8520",
      NIMETUS: "Tõrma küla",
      NIMETUS_LIIGIGA: "Tõrma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00008520",
    },
    {
      TASE: "3",
      KOOD: "8522",
      NIMETUS: "Tõrreküla",
      NIMETUS_LIIGIGA: "Tõrreküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008522",
    },
    {
      TASE: "3",
      KOOD: "8525",
      NIMETUS: "Tõrremäe küla",
      NIMETUS_LIIGIGA: "Tõrremäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00008525",
    },
    {
      TASE: "3",
      KOOD: "8526",
      NIMETUS: "Tõru küla",
      NIMETUS_LIIGIGA: "Tõru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008526",
    },
    {
      TASE: "3",
      KOOD: "8527",
      NIMETUS: "Tõruvere küla",
      NIMETUS_LIIGIGA: "Tõruvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00008527",
    },
    {
      TASE: "3",
      KOOD: "8530",
      NIMETUS: "Tõrvajõe küla",
      NIMETUS_LIIGIGA: "Tõrvajõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00008530",
    },
    {
      TASE: "3",
      KOOD: "8532",
      NIMETUS: "Tõrvandi alevik",
      NIMETUS_LIIGIGA: "Tõrvandi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00008532",
    },
    {
      TASE: "3",
      KOOD: "8535",
      NIMETUS: "Tõrvase küla",
      NIMETUS_LIIGIGA: "Tõrvase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00008535",
    },
    {
      TASE: "3",
      KOOD: "8537",
      NIMETUS: "Tõrve küla",
      NIMETUS_LIIGIGA: "Tõrve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00008537",
    },
    {
      TASE: "3",
      KOOD: "8540",
      NIMETUS: "Tõstamaa alevik",
      NIMETUS_LIIGIGA: "Tõstamaa alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00008540",
    },
    {
      TASE: "3",
      KOOD: "8541",
      NIMETUS: "Tõusi küla",
      NIMETUS_LIIGIGA: "Tõusi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008541",
    },
    {
      TASE: "3",
      KOOD: "8543",
      NIMETUS: "Tõugu küla",
      NIMETUS_LIIGIGA: "Tõugu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00008543",
    },
    {
      TASE: "3",
      KOOD: "8546",
      NIMETUS: "Tõutsi küla",
      NIMETUS_LIIGIGA: "Tõutsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00008546",
    },
    {
      TASE: "3",
      KOOD: "8549",
      NIMETUS: "Tõõrakõrve küla",
      NIMETUS_LIIGIGA: "Tõõrakõrve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00008549",
    },
    {
      TASE: "3",
      KOOD: "8551",
      NIMETUS: "Tõõraste küla",
      NIMETUS_LIIGIGA: "Tõõraste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00008551",
    },
    {
      TASE: "3",
      KOOD: "8555",
      NIMETUS: "Tähemaa küla",
      NIMETUS_LIIGIGA: "Tähemaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00008555",
    },
    {
      TASE: "3",
      KOOD: "8558",
      NIMETUS: "Tähkvere küla",
      NIMETUS_LIIGIGA: "Tähkvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00008558",
    },
    {
      TASE: "3",
      KOOD: "8560",
      NIMETUS: "Tähtvere küla",
      NIMETUS_LIIGIGA: "Tähtvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "793",
      ADS_OID: "AY00008560",
    },
    {
      TASE: "3",
      KOOD: "8563",
      NIMETUS: "Täkumetsa küla",
      NIMETUS_LIIGIGA: "Täkumetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00008563",
    },
    {
      TASE: "3",
      KOOD: "8566",
      NIMETUS: "Tällevere küla",
      NIMETUS_LIIGIGA: "Tällevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00008566",
    },
    {
      TASE: "3",
      KOOD: "8569",
      NIMETUS: "Tänassilma küla",
      NIMETUS_LIIGIGA: "Tänassilma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008569",
    },
    {
      TASE: "3",
      KOOD: "8570",
      NIMETUS: "Tännapere küla",
      NIMETUS_LIIGIGA: "Tännapere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00008570",
    },
    {
      TASE: "3",
      KOOD: "8572",
      NIMETUS: "Tänassilma küla",
      NIMETUS_LIIGIGA: "Tänassilma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00008572",
    },
    {
      TASE: "3",
      KOOD: "8573",
      NIMETUS: "Tännassilma küla",
      NIMETUS_LIIGIGA: "Tännassilma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00008573",
    },
    {
      TASE: "3",
      KOOD: "8574",
      NIMETUS: "Tännassilma küla",
      NIMETUS_LIIGIGA: "Tännassilma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00008574",
    },
    {
      TASE: "3",
      KOOD: "8575",
      NIMETUS: "Tännassilma küla",
      NIMETUS_LIIGIGA: "Tännassilma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00008575",
    },
    {
      TASE: "3",
      KOOD: "8576",
      NIMETUS: "Tärkma küla",
      NIMETUS_LIIGIGA: "Tärkma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008576",
    },
    {
      TASE: "3",
      KOOD: "8577",
      NIMETUS: "Täpsi küla",
      NIMETUS_LIIGIGA: "Täpsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008577",
    },
    {
      TASE: "3",
      KOOD: "8578",
      NIMETUS: "Tärivere küla",
      NIMETUS_LIIGIGA: "Tärivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00008578",
    },
    {
      TASE: "3",
      KOOD: "8581",
      NIMETUS: "Täsvere küla",
      NIMETUS_LIIGIGA: "Täsvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00008581",
    },
    {
      TASE: "3",
      KOOD: "8584",
      NIMETUS: "Tääglova küla",
      NIMETUS_LIIGIGA: "Tääglova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008584",
    },
    {
      TASE: "3",
      KOOD: "8586",
      NIMETUS: "Tääksi küla",
      NIMETUS_LIIGIGA: "Tääksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00008586",
    },
    {
      TASE: "3",
      KOOD: "8587",
      NIMETUS: "Täätsi küla",
      NIMETUS_LIIGIGA: "Täätsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008587",
    },
    {
      TASE: "3",
      KOOD: "8588",
      NIMETUS: "Tühjasma küla",
      NIMETUS_LIIGIGA: "Tühjasma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00008588",
    },
    {
      TASE: "3",
      KOOD: "8590",
      NIMETUS: "Tüki küla",
      NIMETUS_LIIGIGA: "Tüki küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "793",
      ADS_OID: "AY00008590",
    },
    {
      TASE: "3",
      KOOD: "8593",
      NIMETUS: "Abja-Vanamõisa küla",
      NIMETUS_LIIGIGA: "Abja-Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00008593",
    },
    {
      TASE: "3",
      KOOD: "8599",
      NIMETUS: "Türisalu küla",
      NIMETUS_LIIGIGA: "Türisalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00008599",
    },
    {
      TASE: "3",
      KOOD: "8600",
      NIMETUS: "Türju küla",
      NIMETUS_LIIGIGA: "Türju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008600",
    },
    {
      TASE: "3",
      KOOD: "8601",
      NIMETUS: "Tüütsmäe küla",
      NIMETUS_LIIGIGA: "Tüütsmäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008601",
    },
    {
      TASE: "3",
      KOOD: "8602",
      NIMETUS: "Tüükri küla",
      NIMETUS_LIIGIGA: "Tüükri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00008602",
    },
    {
      TASE: "3",
      KOOD: "8603",
      NIMETUS: "Tüütsi küla",
      NIMETUS_LIIGIGA: "Tüütsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008603",
    },
    {
      TASE: "3",
      KOOD: "8604",
      NIMETUS: "Türje küla",
      NIMETUS_LIIGIGA: "Türje küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00008604",
    },
    {
      TASE: "3",
      KOOD: "8607",
      NIMETUS: "Ubasalu küla",
      NIMETUS_LIIGIGA: "Ubasalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00008607",
    },
    {
      TASE: "3",
      KOOD: "8610",
      NIMETUS: "Ubja küla",
      NIMETUS_LIIGIGA: "Ubja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00008610",
    },
    {
      TASE: "3",
      KOOD: "8614",
      NIMETUS: "Uderna küla",
      NIMETUS_LIIGIGA: "Uderna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00008614",
    },
    {
      TASE: "3",
      KOOD: "8616",
      NIMETUS: "Udeva küla",
      NIMETUS_LIIGIGA: "Udeva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00008616",
    },
    {
      TASE: "3",
      KOOD: "8619",
      NIMETUS: "Udria küla",
      NIMETUS_LIIGIGA: "Udria küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00008619",
    },
    {
      TASE: "3",
      KOOD: "8620",
      NIMETUS: "Udsali küla",
      NIMETUS_LIIGIGA: "Udsali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008620",
    },
    {
      TASE: "3",
      KOOD: "8622",
      NIMETUS: "Udriku küla",
      NIMETUS_LIIGIGA: "Udriku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00008622",
    },
    {
      TASE: "3",
      KOOD: "8623",
      NIMETUS: "Udsali küla",
      NIMETUS_LIIGIGA: "Udsali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008623",
    },
    {
      TASE: "3",
      KOOD: "8624",
      NIMETUS: "Uhe küla",
      NIMETUS_LIIGIGA: "Uhe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00008624",
    },
    {
      TASE: "3",
      KOOD: "8625",
      NIMETUS: "Uduvere küla",
      NIMETUS_LIIGIGA: "Uduvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008625",
    },
    {
      TASE: "3",
      KOOD: "8629",
      NIMETUS: "Uhmardu küla",
      NIMETUS_LIIGIGA: "Uhmardu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00008629",
    },
    {
      TASE: "3",
      KOOD: "8632",
      NIMETUS: "Uhti küla",
      NIMETUS_LIIGIGA: "Uhti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00008632",
    },
    {
      TASE: "3",
      KOOD: "8634",
      NIMETUS: "Uhtjärve küla",
      NIMETUS_LIIGIGA: "Uhtjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00008634",
    },
    {
      TASE: "3",
      KOOD: "8637",
      NIMETUS: "Uhtna alevik",
      NIMETUS_LIIGIGA: "Uhtna alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00008637",
    },
    {
      TASE: "3",
      KOOD: "8641",
      NIMETUS: "Uia küla",
      NIMETUS_LIIGIGA: "Uia küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00008641",
    },
    {
      TASE: "3",
      KOOD: "8644",
      NIMETUS: "Uibujärve küla",
      NIMETUS_LIIGIGA: "Uibujärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00008644",
    },
    {
      TASE: "3",
      KOOD: "8647",
      NIMETUS: "Uikala küla",
      NIMETUS_LIIGIGA: "Uikala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00008647",
    },
    {
      TASE: "3",
      KOOD: "8648",
      NIMETUS: "Ulaskova küla",
      NIMETUS_LIIGIGA: "Ulaskova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008648",
    },
    {
      TASE: "3",
      KOOD: "8651",
      NIMETUS: "Uku küla",
      NIMETUS_LIIGIGA: "Uku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00008651",
    },
    {
      TASE: "3",
      KOOD: "8652",
      NIMETUS: "Ula küla",
      NIMETUS_LIIGIGA: "Ula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008652",
    },
    {
      TASE: "3",
      KOOD: "8655",
      NIMETUS: "Ulila alevik",
      NIMETUS_LIIGIGA: "Ulila alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00008655",
    },
    {
      TASE: "3",
      KOOD: "8656",
      NIMETUS: "Ulja küla",
      NIMETUS_LIIGIGA: "Ulja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008656",
    },
    {
      TASE: "3",
      KOOD: "8657",
      NIMETUS: "Ulitina küla",
      NIMETUS_LIIGIGA: "Ulitina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008657",
    },
    {
      TASE: "3",
      KOOD: "8660",
      NIMETUS: "Uljaste küla",
      NIMETUS_LIIGIGA: "Uljaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00008660",
    },
    {
      TASE: "3",
      KOOD: "8661",
      NIMETUS: "Uljaste küla",
      NIMETUS_LIIGIGA: "Uljaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00008661",
    },
    {
      TASE: "3",
      KOOD: "8662",
      NIMETUS: "Ulje küla",
      NIMETUS_LIIGIGA: "Ulje küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008662",
    },
    {
      TASE: "3",
      KOOD: "8663",
      NIMETUS: "Ullaste küla",
      NIMETUS_LIIGIGA: "Ullaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008663",
    },
    {
      TASE: "3",
      KOOD: "8664",
      NIMETUS: "Ulvi küla",
      NIMETUS_LIIGIGA: "Ulvi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00008664",
    },
    {
      TASE: "3",
      KOOD: "8665",
      NIMETUS: "Ulvi küla",
      NIMETUS_LIIGIGA: "Ulvi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00008665",
    },
    {
      TASE: "3",
      KOOD: "8669",
      NIMETUS: "Umbsaare küla",
      NIMETUS_LIIGIGA: "Umbsaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008669",
    },
    {
      TASE: "3",
      KOOD: "8672",
      NIMETUS: "Umbsoo küla",
      NIMETUS_LIIGIGA: "Umbsoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00008672",
    },
    {
      TASE: "3",
      KOOD: "8674",
      NIMETUS: "Umbusi küla",
      NIMETUS_LIIGIGA: "Umbusi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00008674",
    },
    {
      TASE: "3",
      KOOD: "8677",
      NIMETUS: "Ummaru küla",
      NIMETUS_LIIGIGA: "Ummaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00008677",
    },
    {
      TASE: "3",
      KOOD: "8681",
      NIMETUS: "Unakvere küla",
      NIMETUS_LIIGIGA: "Unakvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00008681",
    },
    {
      TASE: "3",
      KOOD: "8682",
      NIMETUS: "Undama küla",
      NIMETUS_LIIGIGA: "Undama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008682",
    },
    {
      TASE: "3",
      KOOD: "8683",
      NIMETUS: "Undimäe küla",
      NIMETUS_LIIGIGA: "Undimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008683",
    },
    {
      TASE: "3",
      KOOD: "8684",
      NIMETUS: "Unametsa küla",
      NIMETUS_LIIGIGA: "Unametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008684",
    },
    {
      TASE: "3",
      KOOD: "8687",
      NIMETUS: "Undi küla",
      NIMETUS_LIIGIGA: "Undi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00008687",
    },
    {
      TASE: "3",
      KOOD: "8690",
      NIMETUS: "Uneste küla",
      NIMETUS_LIIGIGA: "Uneste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00008690",
    },
    {
      TASE: "3",
      KOOD: "8691",
      NIMETUS: "Uniküla",
      NIMETUS_LIIGIGA: "Uniküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00008691",
    },
    {
      TASE: "3",
      KOOD: "8692",
      NIMETUS: "Undva küla",
      NIMETUS_LIIGIGA: "Undva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008692",
    },
    {
      TASE: "3",
      KOOD: "8693",
      NIMETUS: "Unguma küla",
      NIMETUS_LIIGIGA: "Unguma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008693",
    },
    {
      TASE: "3",
      KOOD: "8695",
      NIMETUS: "Uniküla",
      NIMETUS_LIIGIGA: "Uniküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00008695",
    },
    {
      TASE: "3",
      KOOD: "8696",
      NIMETUS: "Uniküla",
      NIMETUS_LIIGIGA: "Uniküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00008696",
    },
    {
      TASE: "3",
      KOOD: "8697",
      NIMETUS: "Unimäe küla",
      NIMETUS_LIIGIGA: "Unimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008697",
    },
    {
      TASE: "3",
      KOOD: "8698",
      NIMETUS: "Unipiha küla",
      NIMETUS_LIIGIGA: "Unipiha küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00008698",
    },
    {
      TASE: "3",
      KOOD: "8701",
      NIMETUS: "Univere küla",
      NIMETUS_LIIGIGA: "Univere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00008701",
    },
    {
      TASE: "3",
      KOOD: "8704",
      NIMETUS: "Unukse küla",
      NIMETUS_LIIGIGA: "Unukse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00008704",
    },
    {
      TASE: "3",
      KOOD: "8708",
      NIMETUS: "Upa küla",
      NIMETUS_LIIGIGA: "Upa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008708",
    },
    {
      TASE: "3",
      KOOD: "8712",
      NIMETUS: "Ura küla",
      NIMETUS_LIIGIGA: "Ura küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008712",
    },
    {
      TASE: "3",
      KOOD: "8714",
      NIMETUS: "Uralaane küla",
      NIMETUS_LIIGIGA: "Uralaane küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00008714",
    },
    {
      TASE: "3",
      KOOD: "8717",
      NIMETUS: "Urevere küla",
      NIMETUS_LIIGIGA: "Urevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00008717",
    },
    {
      TASE: "3",
      KOOD: "8720",
      NIMETUS: "Urge küla",
      NIMETUS_LIIGIGA: "Urge küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00008720",
    },
    {
      TASE: "3",
      KOOD: "8721",
      NIMETUS: "Urge küla",
      NIMETUS_LIIGIGA: "Urge küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00008721",
    },
    {
      TASE: "3",
      KOOD: "8722",
      NIMETUS: "Urita küla",
      NIMETUS_LIIGIGA: "Urita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008722",
    },
    {
      TASE: "3",
      KOOD: "8724",
      NIMETUS: "Urissaare küla",
      NIMETUS_LIIGIGA: "Urissaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00008724",
    },
    {
      TASE: "3",
      KOOD: "8727",
      NIMETUS: "Urmi küla",
      NIMETUS_LIIGIGA: "Urmi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00008727",
    },
    {
      TASE: "3",
      KOOD: "8730",
      NIMETUS: "Urumarja küla",
      NIMETUS_LIIGIGA: "Urumarja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00008730",
    },
    {
      TASE: "3",
      KOOD: "8731",
      NIMETUS: "Urvaste küla",
      NIMETUS_LIIGIGA: "Urvaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00008731",
    },
    {
      TASE: "3",
      KOOD: "8733",
      NIMETUS: "Urvaste küla",
      NIMETUS_LIIGIGA: "Urvaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00008733",
    },
    {
      TASE: "3",
      KOOD: "8737",
      NIMETUS: "Usinitsa küla",
      NIMETUS_LIIGIGA: "Usinitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008737",
    },
    {
      TASE: "3",
      KOOD: "8738",
      NIMETUS: "Utessuu küla",
      NIMETUS_LIIGIGA: "Utessuu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008738",
    },
    {
      TASE: "3",
      KOOD: "8740",
      NIMETUS: "Ussimäe küla",
      NIMETUS_LIIGIGA: "Ussimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00008740",
    },
    {
      TASE: "3",
      KOOD: "8744",
      NIMETUS: "Hutita küla",
      NIMETUS_LIIGIGA: "Hutita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008744",
    },
    {
      TASE: "3",
      KOOD: "8747",
      NIMETUS: "Utu küla",
      NIMETUS_LIIGIGA: "Utu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008747",
    },
    {
      TASE: "3",
      KOOD: "8750",
      NIMETUS: "Utukolga küla",
      NIMETUS_LIIGIGA: "Utukolga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00008750",
    },
    {
      TASE: "3",
      KOOD: "8754",
      NIMETUS: "Uudeküla",
      NIMETUS_LIIGIGA: "Uudeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00008754",
    },
    {
      TASE: "3",
      KOOD: "8757",
      NIMETUS: "Uue-Antsla küla",
      NIMETUS_LIIGIGA: "Uue-Antsla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00008757",
    },
    {
      TASE: "3",
      KOOD: "8759",
      NIMETUS: "Uue-Kariste küla",
      NIMETUS_LIIGIGA: "Uue-Kariste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00008759",
    },
    {
      TASE: "3",
      KOOD: "8762",
      NIMETUS: "Uue-Saaluse küla",
      NIMETUS_LIIGIGA: "Uue-Saaluse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008762",
    },
    {
      TASE: "3",
      KOOD: "8764",
      NIMETUS: "Uuearu küla",
      NIMETUS_LIIGIGA: "Uuearu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00008764",
    },
    {
      TASE: "3",
      KOOD: "8767",
      NIMETUS: "Uuemaa küla",
      NIMETUS_LIIGIGA: "Uuemaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00008767",
    },
    {
      TASE: "3",
      KOOD: "8768",
      NIMETUS: "Uuemõisa küla",
      NIMETUS_LIIGIGA: "Uuemõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00008768",
    },
    {
      TASE: "3",
      KOOD: "8769",
      NIMETUS: "Uuemõisa alevik",
      NIMETUS_LIIGIGA: "Uuemõisa alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00008769",
    },
    {
      TASE: "3",
      KOOD: "8770",
      NIMETUS: "Uuemõisa küla",
      NIMETUS_LIIGIGA: "Uuemõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00008770",
    },
    {
      TASE: "3",
      KOOD: "8771",
      NIMETUS: "Uuemõisa küla",
      NIMETUS_LIIGIGA: "Uuemõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008771",
    },
    {
      TASE: "3",
      KOOD: "8772",
      NIMETUS: "Uuevälja küla",
      NIMETUS_LIIGIGA: "Uuevälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00008772",
    },
    {
      TASE: "3",
      KOOD: "8773",
      NIMETUS: "Uueveski küla",
      NIMETUS_LIIGIGA: "Uueveski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00008773",
    },
    {
      TASE: "3",
      KOOD: "8776",
      NIMETUS: "Uugla küla",
      NIMETUS_LIIGIGA: "Uugla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00008776",
    },
    {
      TASE: "3",
      KOOD: "8779",
      NIMETUS: "Uulu küla",
      NIMETUS_LIIGIGA: "Uulu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00008779",
    },
    {
      TASE: "3",
      KOOD: "8782",
      NIMETUS: "Uuri küla",
      NIMETUS_LIIGIGA: "Uuri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00008782",
    },
    {
      TASE: "3",
      KOOD: "8783",
      NIMETUS: "Uusküla",
      NIMETUS_LIIGIGA: "Uusküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00008783",
    },
    {
      TASE: "3",
      KOOD: "8785",
      NIMETUS: "Uusküla",
      NIMETUS_LIIGIGA: "Uusküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00008785",
    },
    {
      TASE: "3",
      KOOD: "8786",
      NIMETUS: "Uusküla",
      NIMETUS_LIIGIGA: "Uusküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00008786",
    },
    {
      TASE: "3",
      KOOD: "8787",
      NIMETUS: "Uusküla",
      NIMETUS_LIIGIGA: "Uusküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00008787",
    },
    {
      TASE: "3",
      KOOD: "8788",
      NIMETUS: "Uusküla",
      NIMETUS_LIIGIGA: "Uusküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00008788",
    },
    {
      TASE: "3",
      KOOD: "8790",
      NIMETUS: "Uusna küla",
      NIMETUS_LIIGIGA: "Uusna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008790",
    },
    {
      TASE: "3",
      KOOD: "8793",
      NIMETUS: "Uusvada küla",
      NIMETUS_LIIGIGA: "Uusvada küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008793",
    },
    {
      TASE: "3",
      KOOD: "8796",
      NIMETUS: "Uuta küla",
      NIMETUS_LIIGIGA: "Uuta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00008796",
    },
    {
      TASE: "3",
      KOOD: "8797",
      NIMETUS: "Vaaksaarõ küla",
      NIMETUS_LIIGIGA: "Vaaksaarõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008797",
    },
    {
      TASE: "3",
      KOOD: "8801",
      NIMETUS: "Vaabina küla",
      NIMETUS_LIIGIGA: "Vaabina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00008801",
    },
    {
      TASE: "3",
      KOOD: "8802",
      NIMETUS: "Vaalimäe küla",
      NIMETUS_LIIGIGA: "Vaalimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008802",
    },
    {
      TASE: "3",
      KOOD: "8803",
      NIMETUS: "Vaali küla",
      NIMETUS_LIIGIGA: "Vaali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00008803",
    },
    {
      TASE: "3",
      KOOD: "8806",
      NIMETUS: "Vaalu küla",
      NIMETUS_LIIGIGA: "Vaalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00008806",
    },
    {
      TASE: "3",
      KOOD: "8808",
      NIMETUS: "Vaardi küla",
      NIMETUS_LIIGIGA: "Vaardi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00008808",
    },
    {
      TASE: "3",
      KOOD: "8809",
      NIMETUS: "Vaarkali küla",
      NIMETUS_LIIGIGA: "Vaarkali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008809",
    },
    {
      TASE: "3",
      KOOD: "8811",
      NIMETUS: "Vaarkali küla",
      NIMETUS_LIIGIGA: "Vaarkali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008811",
    },
    {
      TASE: "3",
      KOOD: "8813",
      NIMETUS: "Vaartsi küla",
      NIMETUS_LIIGIGA: "Vaartsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008813",
    },
    {
      TASE: "3",
      KOOD: "8816",
      NIMETUS: "Vabamatsi küla",
      NIMETUS_LIIGIGA: "Vabamatsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00008816",
    },
    {
      TASE: "3",
      KOOD: "8817",
      NIMETUS: "Vadsa küla",
      NIMETUS_LIIGIGA: "Vadsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008817",
    },
    {
      TASE: "3",
      KOOD: "8819",
      NIMETUS: "Vadi küla",
      NIMETUS_LIIGIGA: "Vadi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00008819",
    },
    {
      TASE: "3",
      KOOD: "8820",
      NIMETUS: "Vadiküla",
      NIMETUS_LIIGIGA: "Vadiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00008820",
    },
    {
      TASE: "3",
      KOOD: "8822",
      NIMETUS: "Vaeküla",
      NIMETUS_LIIGIGA: "Vaeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00008822",
    },
    {
      TASE: "3",
      KOOD: "8827",
      NIMETUS: "Vaemla küla",
      NIMETUS_LIIGIGA: "Vaemla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008827",
    },
    {
      TASE: "3",
      KOOD: "8830",
      NIMETUS: "Vagivere küla",
      NIMETUS_LIIGIGA: "Vagivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008830",
    },
    {
      TASE: "3",
      KOOD: "8833",
      NIMETUS: "Vagula küla",
      NIMETUS_LIIGIGA: "Vagula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008833",
    },
    {
      TASE: "3",
      KOOD: "8834",
      NIMETUS: "Vaguja küla",
      NIMETUS_LIIGIGA: "Vaguja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00008834",
    },
    {
      TASE: "3",
      KOOD: "8836",
      NIMETUS: "Vahakulmu küla",
      NIMETUS_LIIGIGA: "Vahakulmu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00008836",
    },
    {
      TASE: "3",
      KOOD: "8838",
      NIMETUS: "Vahakõnnu küla",
      NIMETUS_LIIGIGA: "Vahakõnnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00008838",
    },
    {
      TASE: "3",
      KOOD: "8839",
      NIMETUS: "Vahastu küla",
      NIMETUS_LIIGIGA: "Vahastu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00008839",
    },
    {
      TASE: "3",
      KOOD: "8843",
      NIMETUS: "Vahenurme küla",
      NIMETUS_LIIGIGA: "Vahenurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00008843",
    },
    {
      TASE: "3",
      KOOD: "8846",
      NIMETUS: "Vahessaare küla",
      NIMETUS_LIIGIGA: "Vahessaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00008846",
    },
    {
      TASE: "3",
      KOOD: "8847",
      NIMETUS: "Vahetüki küla",
      NIMETUS_LIIGIGA: "Vahetüki küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00008847",
    },
    {
      TASE: "3",
      KOOD: "8848",
      NIMETUS: "Vahi küla",
      NIMETUS_LIIGIGA: "Vahi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00008848",
    },
    {
      TASE: "3",
      KOOD: "8849",
      NIMETUS: "Vahi küla",
      NIMETUS_LIIGIGA: "Vahi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00008849",
    },
    {
      TASE: "3",
      KOOD: "8850",
      NIMETUS: "Vahi alevik",
      NIMETUS_LIIGIGA: "Vahi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00008850",
    },
    {
      TASE: "3",
      KOOD: "8851",
      NIMETUS: "Vahtraste küla",
      NIMETUS_LIIGIGA: "Vahtraste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00008851",
    },
    {
      TASE: "3",
      KOOD: "8853",
      NIMETUS: "Vahtrepa küla",
      NIMETUS_LIIGIGA: "Vahtrepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008853",
    },
    {
      TASE: "3",
      KOOD: "8855",
      NIMETUS: "Vana-Vastseliina küla",
      NIMETUS_LIIGIGA: "Vana-Vastseliina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008855",
    },
    {
      TASE: "3",
      KOOD: "8858",
      NIMETUS: "Vahuküla",
      NIMETUS_LIIGIGA: "Vahuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00008858",
    },
    {
      TASE: "3",
      KOOD: "8859",
      NIMETUS: "Vahva küla",
      NIMETUS_LIIGIGA: "Vahva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008859",
    },
    {
      TASE: "3",
      KOOD: "8861",
      NIMETUS: "Vaiatu küla",
      NIMETUS_LIIGIGA: "Vaiatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00008861",
    },
    {
      TASE: "3",
      KOOD: "8864",
      NIMETUS: "Vaibla küla",
      NIMETUS_LIIGIGA: "Vaibla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008864",
    },
    {
      TASE: "3",
      KOOD: "8867",
      NIMETUS: "Vaida alevik",
      NIMETUS_LIIGIGA: "Vaida alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00008867",
    },
    {
      TASE: "3",
      KOOD: "8869",
      NIMETUS: "Vaidasoo küla",
      NIMETUS_LIIGIGA: "Vaidasoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00008869",
    },
    {
      TASE: "3",
      KOOD: "8870",
      NIMETUS: "Vaigu küla",
      NIMETUS_LIIGIGA: "Vaigu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008870",
    },
    {
      TASE: "3",
      KOOD: "8872",
      NIMETUS: "Vaidavere küla",
      NIMETUS_LIIGIGA: "Vaidavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00008872",
    },
    {
      TASE: "3",
      KOOD: "8874",
      NIMETUS: "Vaikla küla",
      NIMETUS_LIIGIGA: "Vaikla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00008874",
    },
    {
      TASE: "3",
      KOOD: "8877",
      NIMETUS: "Vaila küla",
      NIMETUS_LIIGIGA: "Vaila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00008877",
    },
    {
      TASE: "3",
      KOOD: "8879",
      NIMETUS: "Vaimastvere küla",
      NIMETUS_LIIGIGA: "Vaimastvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00008879",
    },
    {
      TASE: "3",
      KOOD: "8882",
      NIMETUS: "Vaimõisa küla",
      NIMETUS_LIIGIGA: "Vaimõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00008882",
    },
    {
      TASE: "3",
      KOOD: "8884",
      NIMETUS: "Vainu küla",
      NIMETUS_LIIGIGA: "Vainu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00008884",
    },
    {
      TASE: "3",
      KOOD: "8885",
      NIMETUS: "Vainu küla",
      NIMETUS_LIIGIGA: "Vainu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00008885",
    },
    {
      TASE: "3",
      KOOD: "8888",
      NIMETUS: "Vainupea küla",
      NIMETUS_LIIGIGA: "Vainupea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00008888",
    },
    {
      TASE: "3",
      KOOD: "8890",
      NIMETUS: "Vaisi küla",
      NIMETUS_LIIGIGA: "Vaisi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00008890",
    },
    {
      TASE: "3",
      KOOD: "8893",
      NIMETUS: "Vaiste küla",
      NIMETUS_LIIGIGA: "Vaiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008893",
    },
    {
      TASE: "3",
      KOOD: "8895",
      NIMETUS: "Vaivara küla",
      NIMETUS_LIIGIGA: "Vaivara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00008895",
    },
    {
      TASE: "3",
      KOOD: "8898",
      NIMETUS: "Vaivere küla",
      NIMETUS_LIIGIGA: "Vaivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008898",
    },
    {
      TASE: "3",
      KOOD: "8900",
      NIMETUS: "Vaivina küla",
      NIMETUS_LIIGIGA: "Vaivina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00008900",
    },
    {
      TASE: "3",
      KOOD: "8901",
      NIMETUS: "Vakalepa küla",
      NIMETUS_LIIGIGA: "Vakalepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00008901",
    },
    {
      TASE: "3",
      KOOD: "8903",
      NIMETUS: "Vajangu küla",
      NIMETUS_LIIGIGA: "Vajangu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00008903",
    },
    {
      TASE: "3",
      KOOD: "8906",
      NIMETUS: "Vakari küla",
      NIMETUS_LIIGIGA: "Vakari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008906",
    },
    {
      TASE: "3",
      KOOD: "8909",
      NIMETUS: "Vaki küla",
      NIMETUS_LIIGIGA: "Vaki küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00008909",
    },
    {
      TASE: "3",
      KOOD: "8911",
      NIMETUS: "Villemi küla",
      NIMETUS_LIIGIGA: "Villemi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008911",
    },
    {
      TASE: "3",
      KOOD: "8914",
      NIMETUS: "Valaste küla",
      NIMETUS_LIIGIGA: "Valaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00008914",
    },
    {
      TASE: "3",
      KOOD: "8917",
      NIMETUS: "Valasti küla",
      NIMETUS_LIIGIGA: "Valasti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00008917",
    },
    {
      TASE: "3",
      KOOD: "8919",
      NIMETUS: "Valgejõe küla",
      NIMETUS_LIIGIGA: "Valgejõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00008919",
    },
    {
      TASE: "3",
      KOOD: "8922",
      NIMETUS: "Valgemetsa küla",
      NIMETUS_LIIGIGA: "Valgemetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00008922",
    },
    {
      TASE: "3",
      KOOD: "8924",
      NIMETUS: "Valgeranna küla",
      NIMETUS_LIIGIGA: "Valgeranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00008924",
    },
    {
      TASE: "3",
      KOOD: "8927",
      NIMETUS: "Valgesoo küla",
      NIMETUS_LIIGIGA: "Valgesoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00008927",
    },
    {
      TASE: "3",
      KOOD: "8929",
      NIMETUS: "Valgevälja küla",
      NIMETUS_LIIGIGA: "Valgevälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00008929",
    },
    {
      TASE: "3",
      KOOD: "8932",
      NIMETUS: "Valgjärve küla",
      NIMETUS_LIIGIGA: "Valgjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00008932",
    },
    {
      TASE: "3",
      KOOD: "8934",
      NIMETUS: "Valgma küla",
      NIMETUS_LIIGIGA: "Valgma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00008934",
    },
    {
      TASE: "3",
      KOOD: "8935",
      NIMETUS: "Valgma küla",
      NIMETUS_LIIGIGA: "Valgma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00008935",
    },
    {
      TASE: "3",
      KOOD: "8938",
      NIMETUS: "Valgu küla",
      NIMETUS_LIIGIGA: "Valgu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008938",
    },
    {
      TASE: "3",
      KOOD: "8939",
      NIMETUS: "Valgu küla",
      NIMETUS_LIIGIGA: "Valgu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00008939",
    },
    {
      TASE: "3",
      KOOD: "8941",
      NIMETUS: "Valguta küla",
      NIMETUS_LIIGIGA: "Valguta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00008941",
    },
    {
      TASE: "3",
      KOOD: "8944",
      NIMETUS: "Valila küla",
      NIMETUS_LIIGIGA: "Valila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00008944",
    },
    {
      TASE: "3",
      KOOD: "8946",
      NIMETUS: "Valingu küla",
      NIMETUS_LIIGIGA: "Valingu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00008946",
    },
    {
      TASE: "3",
      KOOD: "8947",
      NIMETUS: "Valistre küla",
      NIMETUS_LIIGIGA: "Valistre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00008947",
    },
    {
      TASE: "3",
      KOOD: "8949",
      NIMETUS: "Valipe küla",
      NIMETUS_LIIGIGA: "Valipe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008949",
    },
    {
      TASE: "3",
      KOOD: "8951",
      NIMETUS: "Valjala alevik",
      NIMETUS_LIIGIGA: "Valjala alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008951",
    },
    {
      TASE: "3",
      KOOD: "8954",
      NIMETUS: "Valkla küla",
      NIMETUS_LIIGIGA: "Valkla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00008954",
    },
    {
      TASE: "3",
      KOOD: "8956",
      NIMETUS: "Valkse küla",
      NIMETUS_LIIGIGA: "Valkse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00008956",
    },
    {
      TASE: "3",
      KOOD: "8959",
      NIMETUS: "Vallapalu küla",
      NIMETUS_LIIGIGA: "Vallapalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00008959",
    },
    {
      TASE: "3",
      KOOD: "8961",
      NIMETUS: "Valli küla",
      NIMETUS_LIIGIGA: "Valli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00008961",
    },
    {
      TASE: "3",
      KOOD: "8964",
      NIMETUS: "Valma küla",
      NIMETUS_LIIGIGA: "Valma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008964",
    },
    {
      TASE: "3",
      KOOD: "8966",
      NIMETUS: "Valmaotsa küla",
      NIMETUS_LIIGIGA: "Valmaotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00008966",
    },
    {
      TASE: "3",
      KOOD: "8969",
      NIMETUS: "Valtina küla",
      NIMETUS_LIIGIGA: "Valtina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00008969",
    },
    {
      TASE: "3",
      KOOD: "8970",
      NIMETUS: "Valtu-Nurme küla",
      NIMETUS_LIIGIGA: "Valtu-Nurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00008970",
    },
    {
      TASE: "3",
      KOOD: "8971",
      NIMETUS: "Valtu küla",
      NIMETUS_LIIGIGA: "Valtu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00008971",
    },
    {
      TASE: "3",
      KOOD: "8974",
      NIMETUS: "Valuste küla",
      NIMETUS_LIIGIGA: "Valuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008974",
    },
    {
      TASE: "3",
      KOOD: "8977",
      NIMETUS: "Vana-Antsla alevik",
      NIMETUS_LIIGIGA: "Vana-Antsla alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00008977",
    },
    {
      TASE: "3",
      KOOD: "8979",
      NIMETUS: "Vana-Jõgeva küla",
      NIMETUS_LIIGIGA: "Vana-Jõgeva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00008979",
    },
    {
      TASE: "3",
      KOOD: "8982",
      NIMETUS: "Vana-Kaiu küla",
      NIMETUS_LIIGIGA: "Vana-Kaiu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00008982",
    },
    {
      TASE: "3",
      KOOD: "8984",
      NIMETUS: "Vana-Kariste küla",
      NIMETUS_LIIGIGA: "Vana-Kariste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00008984",
    },
    {
      TASE: "3",
      KOOD: "8987",
      NIMETUS: "Vana-Kastre küla",
      NIMETUS_LIIGIGA: "Vana-Kastre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00008987",
    },
    {
      TASE: "3",
      KOOD: "8989",
      NIMETUS: "Vana-Koiola küla",
      NIMETUS_LIIGIGA: "Vana-Koiola küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00008989",
    },
    {
      TASE: "3",
      KOOD: "8990",
      NIMETUS: "Vana-Lahetaguse küla",
      NIMETUS_LIIGIGA: "Vana-Lahetaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008990",
    },
    {
      TASE: "3",
      KOOD: "8992",
      NIMETUS: "Vana-Kuuste küla",
      NIMETUS_LIIGIGA: "Vana-Kuuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00008992",
    },
    {
      TASE: "3",
      KOOD: "8994",
      NIMETUS: "Vana-Nursi küla",
      NIMETUS_LIIGIGA: "Vana-Nursi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008994",
    },
    {
      TASE: "3",
      KOOD: "8997",
      NIMETUS: "Vana-Nurtu küla",
      NIMETUS_LIIGIGA: "Vana-Nurtu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00008997",
    },
    {
      TASE: "3",
      KOOD: "8999",
      NIMETUS: "Vana-Otepää küla",
      NIMETUS_LIIGIGA: "Vana-Otepää küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00008999",
    },
    {
      TASE: "3",
      KOOD: "9002",
      NIMETUS: "Vana-Roosa küla",
      NIMETUS_LIIGIGA: "Vana-Roosa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009002",
    },
    {
      TASE: "3",
      KOOD: "9004",
      NIMETUS: "Vana-Saaluse küla",
      NIMETUS_LIIGIGA: "Vana-Saaluse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009004",
    },
    {
      TASE: "3",
      KOOD: "9007",
      NIMETUS: "Vana-Vigala küla",
      NIMETUS_LIIGIGA: "Vana-Vigala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00009007",
    },
    {
      TASE: "3",
      KOOD: "9009",
      NIMETUS: "Vana-Vinni küla",
      NIMETUS_LIIGIGA: "Vana-Vinni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00009009",
    },
    {
      TASE: "3",
      KOOD: "9011",
      NIMETUS: "Vanaküla / Gambyn",
      NIMETUS_LIIGIGA: "Vanaküla / Gambyn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00009011",
    },
    {
      TASE: "3",
      KOOD: "9012",
      NIMETUS: "Vana-Võidu küla",
      NIMETUS_LIIGIGA: "Vana-Võidu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009012",
    },
    {
      TASE: "3",
      KOOD: "9013",
      NIMETUS: "Vanakubja küla",
      NIMETUS_LIIGIGA: "Vanakubja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009013",
    },
    {
      TASE: "3",
      KOOD: "9014",
      NIMETUS: "Vanaküla",
      NIMETUS_LIIGIGA: "Vanaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00009014",
    },
    {
      TASE: "3",
      KOOD: "9017",
      NIMETUS: "Vanaküla",
      NIMETUS_LIIGIGA: "Vanaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00009017",
    },
    {
      TASE: "3",
      KOOD: "9018",
      NIMETUS: "Vanalõve küla",
      NIMETUS_LIIGIGA: "Vanalõve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009018",
    },
    {
      TASE: "3",
      KOOD: "9019",
      NIMETUS: "Vanamõisa küla",
      NIMETUS_LIIGIGA: "Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00009019",
    },
    {
      TASE: "3",
      KOOD: "9021",
      NIMETUS: "Vanamõisa küla",
      NIMETUS_LIIGIGA: "Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00009021",
    },
    {
      TASE: "3",
      KOOD: "9022",
      NIMETUS: "Vanamõisa küla",
      NIMETUS_LIIGIGA: "Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009022",
    },
    {
      TASE: "3",
      KOOD: "9023",
      NIMETUS: "Vanamõisa küla",
      NIMETUS_LIIGIGA: "Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00009023",
    },
    {
      TASE: "3",
      KOOD: "9024",
      NIMETUS: "Vanamõisa küla",
      NIMETUS_LIIGIGA: "Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00009024",
    },
    {
      TASE: "3",
      KOOD: "9025",
      NIMETUS: "Vanamõisa küla",
      NIMETUS_LIIGIGA: "Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00009025",
    },
    {
      TASE: "3",
      KOOD: "9026",
      NIMETUS: "Vanamõisa küla",
      NIMETUS_LIIGIGA: "Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009026",
    },
    {
      TASE: "3",
      KOOD: "9027",
      NIMETUS: "Vanamõisa küla",
      NIMETUS_LIIGIGA: "Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00009027",
    },
    {
      TASE: "3",
      KOOD: "9029",
      NIMETUS: "Vanassaare küla",
      NIMETUS_LIIGIGA: "Vanassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00009029",
    },
    {
      TASE: "3",
      KOOD: "9030",
      NIMETUS: "Vanamõisa küla",
      NIMETUS_LIIGIGA: "Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009030",
    },
    {
      TASE: "3",
      KOOD: "9031",
      NIMETUS: "Vanaussaia küla",
      NIMETUS_LIIGIGA: "Vanaussaia küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00009031",
    },
    {
      TASE: "3",
      KOOD: "9032",
      NIMETUS: "Vanamõisa küla",
      NIMETUS_LIIGIGA: "Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00009032",
    },
    {
      TASE: "3",
      KOOD: "9034",
      NIMETUS: "Vanausse küla",
      NIMETUS_LIIGIGA: "Vanausse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009034",
    },
    {
      TASE: "3",
      KOOD: "9036",
      NIMETUS: "Vanaveski küla",
      NIMETUS_LIIGIGA: "Vanaveski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00009036",
    },
    {
      TASE: "3",
      KOOD: "9037",
      NIMETUS: "Vanavälja küla",
      NIMETUS_LIIGIGA: "Vanavälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00009037",
    },
    {
      TASE: "3",
      KOOD: "9039",
      NIMETUS: "Vanavälja küla",
      NIMETUS_LIIGIGA: "Vanavälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009039",
    },
    {
      TASE: "3",
      KOOD: "9041",
      NIMETUS: "Vandjala küla",
      NIMETUS_LIIGIGA: "Vandjala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00009041",
    },
    {
      TASE: "3",
      KOOD: "9043",
      NIMETUS: "Vandu küla",
      NIMETUS_LIIGIGA: "Vandu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00009043",
    },
    {
      TASE: "3",
      KOOD: "9045",
      NIMETUS: "Vantri küla",
      NIMETUS_LIIGIGA: "Vantri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009045",
    },
    {
      TASE: "3",
      KOOD: "9046",
      NIMETUS: "Vankse küla",
      NIMETUS_LIIGIGA: "Vankse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00009046",
    },
    {
      TASE: "3",
      KOOD: "9047",
      NIMETUS: "Vao küla",
      NIMETUS_LIIGIGA: "Vao küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00009047",
    },
    {
      TASE: "3",
      KOOD: "9048",
      NIMETUS: "Vao küla",
      NIMETUS_LIIGIGA: "Vao küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00009048",
    },
    {
      TASE: "3",
      KOOD: "9049",
      NIMETUS: "Vansi küla",
      NIMETUS_LIIGIGA: "Vansi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00009049",
    },
    {
      TASE: "3",
      KOOD: "9050",
      NIMETUS: "Vaopere küla",
      NIMETUS_LIIGIGA: "Vaopere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00009050",
    },
    {
      TASE: "3",
      KOOD: "9053",
      NIMETUS: "Vara küla",
      NIMETUS_LIIGIGA: "Vara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00009053",
    },
    {
      TASE: "3",
      KOOD: "9056",
      NIMETUS: "Varangu küla",
      NIMETUS_LIIGIGA: "Varangu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00009056",
    },
    {
      TASE: "3",
      KOOD: "9058",
      NIMETUS: "Varbevere küla",
      NIMETUS_LIIGIGA: "Varbevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00009058",
    },
    {
      TASE: "3",
      KOOD: "9061",
      NIMETUS: "Varbla küla",
      NIMETUS_LIIGIGA: "Varbla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00009061",
    },
    {
      TASE: "3",
      KOOD: "9063",
      NIMETUS: "Varbola küla",
      NIMETUS_LIIGIGA: "Varbola küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00009063",
    },
    {
      TASE: "3",
      KOOD: "9066",
      NIMETUS: "Varbuse küla",
      NIMETUS_LIIGIGA: "Varbuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00009066",
    },
    {
      TASE: "3",
      KOOD: "9068",
      NIMETUS: "Vardi küla",
      NIMETUS_LIIGIGA: "Vardi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009068",
    },
    {
      TASE: "3",
      KOOD: "9071",
      NIMETUS: "Vardja küla",
      NIMETUS_LIIGIGA: "Vardja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00009071",
    },
    {
      TASE: "3",
      KOOD: "9072",
      NIMETUS: "Vardja küla",
      NIMETUS_LIIGIGA: "Vardja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00009072",
    },
    {
      TASE: "3",
      KOOD: "9073",
      NIMETUS: "Vardja küla",
      NIMETUS_LIIGIGA: "Vardja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009073",
    },
    {
      TASE: "3",
      KOOD: "9074",
      NIMETUS: "Varese küla",
      NIMETUS_LIIGIGA: "Varese küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009074",
    },
    {
      TASE: "3",
      KOOD: "9075",
      NIMETUS: "Varesmetsa küla",
      NIMETUS_LIIGIGA: "Varesmetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00009075",
    },
    {
      TASE: "3",
      KOOD: "9078",
      NIMETUS: "Varesmäe küla",
      NIMETUS_LIIGIGA: "Varesmäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009078",
    },
    {
      TASE: "3",
      KOOD: "9080",
      NIMETUS: "Vareste küla",
      NIMETUS_LIIGIGA: "Vareste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00009080",
    },
    {
      TASE: "3",
      KOOD: "9083",
      NIMETUS: "Variku küla",
      NIMETUS_LIIGIGA: "Variku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00009083",
    },
    {
      TASE: "3",
      KOOD: "9088",
      NIMETUS: "Varja küla",
      NIMETUS_LIIGIGA: "Varja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00009088",
    },
    {
      TASE: "3",
      KOOD: "9089",
      NIMETUS: "Varkja küla",
      NIMETUS_LIIGIGA: "Varkja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009089",
    },
    {
      TASE: "3",
      KOOD: "9090",
      NIMETUS: "Varnja alevik",
      NIMETUS_LIIGIGA: "Varnja alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00009090",
    },
    {
      TASE: "3",
      KOOD: "9091",
      NIMETUS: "Varni küla",
      NIMETUS_LIIGIGA: "Varni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00009091",
    },
    {
      TASE: "3",
      KOOD: "9093",
      NIMETUS: "Varpe küla",
      NIMETUS_LIIGIGA: "Varpe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009093",
    },
    {
      TASE: "3",
      KOOD: "9095",
      NIMETUS: "Varstu alevik",
      NIMETUS_LIIGIGA: "Varstu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009095",
    },
    {
      TASE: "3",
      KOOD: "9096",
      NIMETUS: "Varudi küla",
      NIMETUS_LIIGIGA: "Varudi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00009096",
    },
    {
      TASE: "3",
      KOOD: "9098",
      NIMETUS: "Varudi-Vanaküla",
      NIMETUS_LIIGIGA: "Varudi-Vanaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00009098",
    },
    {
      TASE: "3",
      KOOD: "9099",
      NIMETUS: "Varudi-Altküla",
      NIMETUS_LIIGIGA: "Varudi-Altküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00009099",
    },
    {
      TASE: "3",
      KOOD: "9101",
      NIMETUS: "Vasalemma alevik",
      NIMETUS_LIIGIGA: "Vasalemma alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00009101",
    },
    {
      TASE: "3",
      KOOD: "9103",
      NIMETUS: "Vasara küla",
      NIMETUS_LIIGIGA: "Vasara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009103",
    },
    {
      TASE: "3",
      KOOD: "9106",
      NIMETUS: "Vasavere küla",
      NIMETUS_LIIGIGA: "Vasavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00009106",
    },
    {
      TASE: "3",
      KOOD: "9108",
      NIMETUS: "Vaskjala küla",
      NIMETUS_LIIGIGA: "Vaskjala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00009108",
    },
    {
      TASE: "3",
      KOOD: "9111",
      NIMETUS: "Vasknarva küla",
      NIMETUS_LIIGIGA: "Vasknarva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00009111",
    },
    {
      TASE: "3",
      KOOD: "9112",
      NIMETUS: "Vasla küla",
      NIMETUS_LIIGIGA: "Vasla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009112",
    },
    {
      TASE: "3",
      KOOD: "9113",
      NIMETUS: "Vaskrääma küla",
      NIMETUS_LIIGIGA: "Vaskrääma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00009113",
    },
    {
      TASE: "3",
      KOOD: "9116",
      NIMETUS: "Vassevere küla",
      NIMETUS_LIIGIGA: "Vassevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00009116",
    },
    {
      TASE: "3",
      KOOD: "9118",
      NIMETUS: "Vassivere küla",
      NIMETUS_LIIGIGA: "Vassivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00009118",
    },
    {
      TASE: "3",
      KOOD: "9121",
      NIMETUS: "Vasta küla",
      NIMETUS_LIIGIGA: "Vasta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00009121",
    },
    {
      TASE: "3",
      KOOD: "9123",
      NIMETUS: "Vastemõisa küla",
      NIMETUS_LIIGIGA: "Vastemõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00009123",
    },
    {
      TASE: "3",
      KOOD: "9126",
      NIMETUS: "Vastja küla",
      NIMETUS_LIIGIGA: "Vastja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00009126",
    },
    {
      TASE: "3",
      KOOD: "9128",
      NIMETUS: "Vastse-Kuuste alevik",
      NIMETUS_LIIGIGA: "Vastse-Kuuste alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00009128",
    },
    {
      TASE: "3",
      KOOD: "9129",
      NIMETUS: "Vastsekivi küla",
      NIMETUS_LIIGIGA: "Vastsekivi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009129",
    },
    {
      TASE: "3",
      KOOD: "9131",
      NIMETUS: "Vastse-Roosa küla",
      NIMETUS_LIIGIGA: "Vastse-Roosa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009131",
    },
    {
      TASE: "3",
      KOOD: "9133",
      NIMETUS: "Vastseliina alevik",
      NIMETUS_LIIGIGA: "Vastseliina alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009133",
    },
    {
      TASE: "3",
      KOOD: "9134",
      NIMETUS: "Vastaba küla",
      NIMETUS_LIIGIGA: "Vastaba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00009134",
    },
    {
      TASE: "3",
      KOOD: "9136",
      NIMETUS: "Vasula alevik",
      NIMETUS_LIIGIGA: "Vasula alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00009136",
    },
    {
      TASE: "3",
      KOOD: "9139",
      NIMETUS: "Vatku küla",
      NIMETUS_LIIGIGA: "Vatku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00009139",
    },
    {
      TASE: "3",
      KOOD: "9141",
      NIMETUS: "Vatla küla",
      NIMETUS_LIIGIGA: "Vatla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00009141",
    },
    {
      TASE: "3",
      KOOD: "9144",
      NIMETUS: "Vatsa küla",
      NIMETUS_LIIGIGA: "Vatsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009144",
    },
    {
      TASE: "3",
      KOOD: "9145",
      NIMETUS: "Vatsküla",
      NIMETUS_LIIGIGA: "Vatsküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009145",
    },
    {
      TASE: "3",
      KOOD: "9146",
      NIMETUS: "Vatsla küla",
      NIMETUS_LIIGIGA: "Vatsla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00009146",
    },
    {
      TASE: "3",
      KOOD: "9150",
      NIMETUS: "Vea küla",
      NIMETUS_LIIGIGA: "Vea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00009150",
    },
    {
      TASE: "3",
      KOOD: "9151",
      NIMETUS: "Vedernika küla",
      NIMETUS_LIIGIGA: "Vedernika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009151",
    },
    {
      TASE: "3",
      KOOD: "9153",
      NIMETUS: "Veadla küla",
      NIMETUS_LIIGIGA: "Veadla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00009153",
    },
    {
      TASE: "3",
      KOOD: "9156",
      NIMETUS: "Vedra küla",
      NIMETUS_LIIGIGA: "Vedra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00009156",
    },
    {
      TASE: "3",
      KOOD: "9158",
      NIMETUS: "Vedruka küla",
      NIMETUS_LIIGIGA: "Vedruka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009158",
    },
    {
      TASE: "3",
      KOOD: "9161",
      NIMETUS: "Vedu küla",
      NIMETUS_LIIGIGA: "Vedu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00009161",
    },
    {
      TASE: "3",
      KOOD: "9164",
      NIMETUS: "Vee küla",
      NIMETUS_LIIGIGA: "Vee küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00009164",
    },
    {
      TASE: "3",
      KOOD: "9166",
      NIMETUS: "Veelikse küla",
      NIMETUS_LIIGIGA: "Veelikse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00009166",
    },
    {
      TASE: "3",
      KOOD: "9167",
      NIMETUS: "Veelikse küla",
      NIMETUS_LIIGIGA: "Veelikse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00009167",
    },
    {
      TASE: "3",
      KOOD: "9171",
      NIMETUS: "Veere küla",
      NIMETUS_LIIGIGA: "Veere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009171",
    },
    {
      TASE: "3",
      KOOD: "9172",
      NIMETUS: "Veeriku küla",
      NIMETUS_LIIGIGA: "Veeriku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009172",
    },
    {
      TASE: "3",
      KOOD: "9175",
      NIMETUS: "Väike-Veerksu küla",
      NIMETUS_LIIGIGA: "Väike-Veerksu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00009175",
    },
    {
      TASE: "3",
      KOOD: "9178",
      NIMETUS: "Vehendi küla",
      NIMETUS_LIIGIGA: "Vehendi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00009178",
    },
    {
      TASE: "3",
      KOOD: "9181",
      NIMETUS: "Veia küla",
      NIMETUS_LIIGIGA: "Veia küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00009181",
    },
    {
      TASE: "3",
      KOOD: "9183",
      NIMETUS: "Veibri küla",
      NIMETUS_LIIGIGA: "Veibri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00009183",
    },
    {
      TASE: "3",
      KOOD: "9186",
      NIMETUS: "Veisjärve küla",
      NIMETUS_LIIGIGA: "Veisjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009186",
    },
    {
      TASE: "3",
      KOOD: "9187",
      NIMETUS: "Velisemõisa küla",
      NIMETUS_LIIGIGA: "Velisemõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00009187",
    },
    {
      TASE: "3",
      KOOD: "9189",
      NIMETUS: "Velise küla",
      NIMETUS_LIIGIGA: "Velise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00009189",
    },
    {
      TASE: "3",
      KOOD: "9190",
      NIMETUS: "Velise-Nõlva küla",
      NIMETUS_LIIGIGA: "Velise-Nõlva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00009190",
    },
    {
      TASE: "3",
      KOOD: "9191",
      NIMETUS: "Vellavere küla",
      NIMETUS_LIIGIGA: "Vellavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00009191",
    },
    {
      TASE: "3",
      KOOD: "9192",
      NIMETUS: "Veltsa küla",
      NIMETUS_LIIGIGA: "Veltsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00009192",
    },
    {
      TASE: "3",
      KOOD: "9194",
      NIMETUS: "Velna küla",
      NIMETUS_LIIGIGA: "Velna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009194",
    },
    {
      TASE: "3",
      KOOD: "9196",
      NIMETUS: "Veltsi küla",
      NIMETUS_LIIGIGA: "Veltsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00009196",
    },
    {
      TASE: "3",
      KOOD: "9197",
      NIMETUS: "Vendise küla",
      NIMETUS_LIIGIGA: "Vendise küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009197",
    },
    {
      TASE: "3",
      KOOD: "9199",
      NIMETUS: "Venekuusiku küla",
      NIMETUS_LIIGIGA: "Venekuusiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00009199",
    },
    {
      TASE: "3",
      KOOD: "9200",
      NIMETUS: "Veneoja küla",
      NIMETUS_LIIGIGA: "Veneoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00009200",
    },
    {
      TASE: "3",
      KOOD: "9202",
      NIMETUS: "Veneküla",
      NIMETUS_LIIGIGA: "Veneküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00009202",
    },
    {
      TASE: "3",
      KOOD: "9204",
      NIMETUS: "Venevere küla",
      NIMETUS_LIIGIGA: "Venevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00009204",
    },
    {
      TASE: "3",
      KOOD: "9205",
      NIMETUS: "Venevere küla",
      NIMETUS_LIIGIGA: "Venevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00009205",
    },
    {
      TASE: "3",
      KOOD: "9206",
      NIMETUS: "Vennati küla",
      NIMETUS_LIIGIGA: "Vennati küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009206",
    },
    {
      TASE: "3",
      KOOD: "9208",
      NIMETUS: "Veretinä küla",
      NIMETUS_LIIGIGA: "Veretinä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009208",
    },
    {
      TASE: "3",
      KOOD: "9211",
      NIMETUS: "Verevi küla",
      NIMETUS_LIIGIGA: "Verevi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00009211",
    },
    {
      TASE: "3",
      KOOD: "9213",
      NIMETUS: "Vergi küla",
      NIMETUS_LIIGIGA: "Vergi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00009213",
    },
    {
      TASE: "3",
      KOOD: "9216",
      NIMETUS: "Verhulitsa küla",
      NIMETUS_LIIGIGA: "Verhulitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009216",
    },
    {
      TASE: "3",
      KOOD: "9218",
      NIMETUS: "Verijärve küla",
      NIMETUS_LIIGIGA: "Verijärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009218",
    },
    {
      TASE: "3",
      KOOD: "9221",
      NIMETUS: "Verilaske küla",
      NIMETUS_LIIGIGA: "Verilaske küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009221",
    },
    {
      TASE: "3",
      KOOD: "9223",
      NIMETUS: "Veriora alevik",
      NIMETUS_LIIGIGA: "Veriora alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00009223",
    },
    {
      TASE: "3",
      KOOD: "9224",
      NIMETUS: "Verioramõisa küla",
      NIMETUS_LIIGIGA: "Verioramõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00009224",
    },
    {
      TASE: "3",
      KOOD: "9226",
      NIMETUS: "Veske küla",
      NIMETUS_LIIGIGA: "Veske küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009226",
    },
    {
      TASE: "3",
      KOOD: "9227",
      NIMETUS: "Veskiaru küla",
      NIMETUS_LIIGIGA: "Veskiaru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00009227",
    },
    {
      TASE: "3",
      KOOD: "9228",
      NIMETUS: "Veski küla",
      NIMETUS_LIIGIGA: "Veski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00009228",
    },
    {
      TASE: "3",
      KOOD: "9229",
      NIMETUS: "Veski küla",
      NIMETUS_LIIGIGA: "Veski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00009229",
    },
    {
      TASE: "3",
      KOOD: "9231",
      NIMETUS: "Veskiküla",
      NIMETUS_LIIGIGA: "Veskiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00009231",
    },
    {
      TASE: "3",
      KOOD: "9234",
      NIMETUS: "Veskimäe küla",
      NIMETUS_LIIGIGA: "Veskimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00009234",
    },
    {
      TASE: "3",
      KOOD: "9235",
      NIMETUS: "Veskimäe küla",
      NIMETUS_LIIGIGA: "Veskimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00009235",
    },
    {
      TASE: "3",
      KOOD: "9236",
      NIMETUS: "Veskitaguse küla",
      NIMETUS_LIIGIGA: "Veskitaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00009236",
    },
    {
      TASE: "3",
      KOOD: "9237",
      NIMETUS: "Veskisoo küla",
      NIMETUS_LIIGIGA: "Veskisoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00009237",
    },
    {
      TASE: "3",
      KOOD: "9240",
      NIMETUS: "Vesneri küla",
      NIMETUS_LIIGIGA: "Vesneri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00009240",
    },
    {
      TASE: "3",
      KOOD: "9241",
      NIMETUS: "Vestla küla",
      NIMETUS_LIIGIGA: "Vestla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009241",
    },
    {
      TASE: "3",
      KOOD: "9243",
      NIMETUS: "Vetepere küla",
      NIMETUS_LIIGIGA: "Vetepere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00009243",
    },
    {
      TASE: "3",
      KOOD: "9245",
      NIMETUS: "Vetiku küla",
      NIMETUS_LIIGIGA: "Vetiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00009245",
    },
    {
      TASE: "3",
      KOOD: "9248",
      NIMETUS: "Vetla küla",
      NIMETUS_LIIGIGA: "Vetla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00009248",
    },
    {
      TASE: "3",
      KOOD: "9252",
      NIMETUS: "Vidrike küla",
      NIMETUS_LIIGIGA: "Vidrike küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00009252",
    },
    {
      TASE: "3",
      KOOD: "9254",
      NIMETUS: "Vidruka küla",
      NIMETUS_LIIGIGA: "Vidruka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00009254",
    },
    {
      TASE: "3",
      KOOD: "9257",
      NIMETUS: "Vihasoo küla",
      NIMETUS_LIIGIGA: "Vihasoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00009257",
    },
    {
      TASE: "3",
      KOOD: "9260",
      NIMETUS: "Vihavu küla",
      NIMETUS_LIIGIGA: "Vihavu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00009260",
    },
    {
      TASE: "3",
      KOOD: "9261",
      NIMETUS: "Vihkla küla",
      NIMETUS_LIIGIGA: "Vihkla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009261",
    },
    {
      TASE: "3",
      KOOD: "9262",
      NIMETUS: "Vihi küla",
      NIMETUS_LIIGIGA: "Vihi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00009262",
    },
    {
      TASE: "3",
      KOOD: "9265",
      NIMETUS: "Vihterpalu küla",
      NIMETUS_LIIGIGA: "Vihterpalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00009265",
    },
    {
      TASE: "3",
      KOOD: "9267",
      NIMETUS: "Vihtra küla",
      NIMETUS_LIIGIGA: "Vihtra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00009267",
    },
    {
      TASE: "3",
      KOOD: "9270",
      NIMETUS: "Vihula küla",
      NIMETUS_LIIGIGA: "Vihula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00009270",
    },
    {
      TASE: "3",
      KOOD: "9273",
      NIMETUS: "Viidike küla",
      NIMETUS_LIIGIGA: "Viidike küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00009273",
    },
    {
      TASE: "3",
      KOOD: "9275",
      NIMETUS: "Viidu küla",
      NIMETUS_LIIGIGA: "Viidu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009275",
    },
    {
      TASE: "3",
      KOOD: "9278",
      NIMETUS: "Viilupi küla",
      NIMETUS_LIIGIGA: "Viilupi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00009278",
    },
    {
      TASE: "3",
      KOOD: "9280",
      NIMETUS: "Viimsi alevik",
      NIMETUS_LIIGIGA: "Viimsi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00009280",
    },
    {
      TASE: "3",
      KOOD: "9283",
      NIMETUS: "Viinistu küla",
      NIMETUS_LIIGIGA: "Viinistu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00009283",
    },
    {
      TASE: "3",
      KOOD: "9285",
      NIMETUS: "Viira küla",
      NIMETUS_LIIGIGA: "Viira küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00009285",
    },
    {
      TASE: "3",
      KOOD: "9286",
      NIMETUS: "Viira küla",
      NIMETUS_LIIGIGA: "Viira küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00009286",
    },
    {
      TASE: "3",
      KOOD: "9290",
      NIMETUS: "Viirapalu küla",
      NIMETUS_LIIGIGA: "Viirapalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00009290",
    },
    {
      TASE: "3",
      KOOD: "9292",
      NIMETUS: "Viiratsi alevik",
      NIMETUS_LIIGIGA: "Viiratsi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009292",
    },
    {
      TASE: "3",
      KOOD: "9293",
      NIMETUS: "Viira küla",
      NIMETUS_LIIGIGA: "Viira küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009293",
    },
    {
      TASE: "3",
      KOOD: "9294",
      NIMETUS: "Viira küla",
      NIMETUS_LIIGIGA: "Viira küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00009294",
    },
    {
      TASE: "3",
      KOOD: "9295",
      NIMETUS: "Viiri küla",
      NIMETUS_LIIGIGA: "Viiri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00009295",
    },
    {
      TASE: "3",
      KOOD: "9297",
      NIMETUS: "Viisireiu küla",
      NIMETUS_LIIGIGA: "Viisireiu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00009297",
    },
    {
      TASE: "3",
      KOOD: "9300",
      NIMETUS: "Viisli küla",
      NIMETUS_LIIGIGA: "Viisli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00009300",
    },
    {
      TASE: "3",
      KOOD: "9302",
      NIMETUS: "Viisu küla",
      NIMETUS_LIIGIGA: "Viisu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00009302",
    },
    {
      TASE: "3",
      KOOD: "9303",
      NIMETUS: "Viita küla",
      NIMETUS_LIIGIGA: "Viita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00009303",
    },
    {
      TASE: "3",
      KOOD: "9305",
      NIMETUS: "Viisuküla",
      NIMETUS_LIIGIGA: "Viisuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009305",
    },
    {
      TASE: "3",
      KOOD: "9306",
      NIMETUS: "Viitasoo küla",
      NIMETUS_LIIGIGA: "Viitasoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00009306",
    },
    {
      TASE: "3",
      KOOD: "9307",
      NIMETUS: "Viitina küla",
      NIMETUS_LIIGIGA: "Viitina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009307",
    },
    {
      TASE: "3",
      KOOD: "9310",
      NIMETUS: "Viitka küla",
      NIMETUS_LIIGIGA: "Viitka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009310",
    },
    {
      TASE: "3",
      KOOD: "9315",
      NIMETUS: "Viki küla",
      NIMETUS_LIIGIGA: "Viki küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009315",
    },
    {
      TASE: "3",
      KOOD: "9318",
      NIMETUS: "Vikipalu küla",
      NIMETUS_LIIGIGA: "Vikipalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00009318",
    },
    {
      TASE: "3",
      KOOD: "9321",
      NIMETUS: "Vila küla",
      NIMETUS_LIIGIGA: "Vila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00009321",
    },
    {
      TASE: "3",
      KOOD: "9323",
      NIMETUS: "Vilama küla",
      NIMETUS_LIIGIGA: "Vilama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00009323",
    },
    {
      TASE: "3",
      KOOD: "9326",
      NIMETUS: "Vilaski küla",
      NIMETUS_LIIGIGA: "Vilaski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00009326",
    },
    {
      TASE: "3",
      KOOD: "9327",
      NIMETUS: "Vilima küla",
      NIMETUS_LIIGIGA: "Vilima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00009327",
    },
    {
      TASE: "3",
      KOOD: "9328",
      NIMETUS: "Vilidu küla",
      NIMETUS_LIIGIGA: "Vilidu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009328",
    },
    {
      TASE: "3",
      KOOD: "9329",
      NIMETUS: "Viliksaarõ küla",
      NIMETUS_LIIGIGA: "Viliksaarõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009329",
    },
    {
      TASE: "3",
      KOOD: "9331",
      NIMETUS: "Vilimeeste küla",
      NIMETUS_LIIGIGA: "Vilimeeste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009331",
    },
    {
      TASE: "3",
      KOOD: "9333",
      NIMETUS: "Vilina küla",
      NIMETUS_LIIGIGA: "Vilina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00009333",
    },
    {
      TASE: "3",
      KOOD: "9338",
      NIMETUS: "Vilivalla küla",
      NIMETUS_LIIGIGA: "Vilivalla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00009338",
    },
    {
      TASE: "3",
      KOOD: "9339",
      NIMETUS: "Vilivalla küla",
      NIMETUS_LIIGIGA: "Vilivalla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00009339",
    },
    {
      TASE: "3",
      KOOD: "9343",
      NIMETUS: "Vilkla küla",
      NIMETUS_LIIGIGA: "Vilkla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00009343",
    },
    {
      TASE: "3",
      KOOD: "9344",
      NIMETUS: "Villa küla",
      NIMETUS_LIIGIGA: "Villa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009344",
    },
    {
      TASE: "3",
      KOOD: "9345",
      NIMETUS: "Villa küla",
      NIMETUS_LIIGIGA: "Villa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009345",
    },
    {
      TASE: "3",
      KOOD: "9346",
      NIMETUS: "Villa küla",
      NIMETUS_LIIGIGA: "Villa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009346",
    },
    {
      TASE: "3",
      KOOD: "9347",
      NIMETUS: "Villakvere küla",
      NIMETUS_LIIGIGA: "Villakvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00009347",
    },
    {
      TASE: "3",
      KOOD: "9349",
      NIMETUS: "Villamaa küla",
      NIMETUS_LIIGIGA: "Villamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00009349",
    },
    {
      TASE: "3",
      KOOD: "9350",
      NIMETUS: "Villandi küla",
      NIMETUS_LIIGIGA: "Villandi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00009350",
    },
    {
      TASE: "3",
      KOOD: "9352",
      NIMETUS: "Villevere küla",
      NIMETUS_LIIGIGA: "Villevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00009352",
    },
    {
      TASE: "3",
      KOOD: "9354",
      NIMETUS: "Villike küla",
      NIMETUS_LIIGIGA: "Villike küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009354",
    },
    {
      TASE: "3",
      KOOD: "9357",
      NIMETUS: "Vilsandi küla",
      NIMETUS_LIIGIGA: "Vilsandi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009357",
    },
    {
      TASE: "3",
      KOOD: "9359",
      NIMETUS: "Vilta küla",
      NIMETUS_LIIGIGA: "Vilta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00009359",
    },
    {
      TASE: "3",
      KOOD: "9360",
      NIMETUS: "Viltina küla",
      NIMETUS_LIIGIGA: "Viltina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009360",
    },
    {
      TASE: "3",
      KOOD: "9362",
      NIMETUS: "Vilumäe küla",
      NIMETUS_LIIGIGA: "Vilumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00009362",
    },
    {
      TASE: "3",
      KOOD: "9364",
      NIMETUS: "Vilusi küla",
      NIMETUS_LIIGIGA: "Vilusi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00009364",
    },
    {
      TASE: "3",
      KOOD: "9367",
      NIMETUS: "Vilussaare küla",
      NIMETUS_LIIGIGA: "Vilussaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00009367",
    },
    {
      TASE: "3",
      KOOD: "9369",
      NIMETUS: "Viluste küla",
      NIMETUS_LIIGIGA: "Viluste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00009369",
    },
    {
      TASE: "3",
      KOOD: "9372",
      NIMETUS: "Viluvere küla",
      NIMETUS_LIIGIGA: "Viluvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00009372",
    },
    {
      TASE: "3",
      KOOD: "9373",
      NIMETUS: "Vinski küla",
      NIMETUS_LIIGIGA: "Vinski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009373",
    },
    {
      TASE: "3",
      KOOD: "9375",
      NIMETUS: "Vinni alevik",
      NIMETUS_LIIGIGA: "Vinni alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00009375",
    },
    {
      TASE: "3",
      KOOD: "9377",
      NIMETUS: "Vinso küla",
      NIMETUS_LIIGIGA: "Vinso küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00009377",
    },
    {
      TASE: "3",
      KOOD: "9378",
      NIMETUS: "Vintri küla",
      NIMETUS_LIIGIGA: "Vintri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009378",
    },
    {
      TASE: "3",
      KOOD: "9379",
      NIMETUS: "Viraksaare küla",
      NIMETUS_LIIGIGA: "Viraksaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00009379",
    },
    {
      TASE: "3",
      KOOD: "9380",
      NIMETUS: "Vintse küla",
      NIMETUS_LIIGIGA: "Vintse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00009380",
    },
    {
      TASE: "3",
      KOOD: "9383",
      NIMETUS: "Virita küla",
      NIMETUS_LIIGIGA: "Virita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009383",
    },
    {
      TASE: "3",
      KOOD: "9384",
      NIMETUS: "Viro küla",
      NIMETUS_LIIGIGA: "Viro küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009384",
    },
    {
      TASE: "3",
      KOOD: "9385",
      NIMETUS: "Virla küla",
      NIMETUS_LIIGIGA: "Virla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00009385",
    },
    {
      TASE: "3",
      KOOD: "9388",
      NIMETUS: "Virtsu alevik",
      NIMETUS_LIIGIGA: "Virtsu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00009388",
    },
    {
      TASE: "3",
      KOOD: "9389",
      NIMETUS: "Virtsu küla",
      NIMETUS_LIIGIGA: "Virtsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00009389",
    },
    {
      TASE: "3",
      KOOD: "9391",
      NIMETUS: "Viru küla",
      NIMETUS_LIIGIGA: "Viru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009391",
    },
    {
      TASE: "3",
      KOOD: "9394",
      NIMETUS: "Viru-Jaagupi alevik",
      NIMETUS_LIIGIGA: "Viru-Jaagupi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00009394",
    },
    {
      TASE: "3",
      KOOD: "9399",
      NIMETUS: "Viru-Nigula alevik",
      NIMETUS_LIIGIGA: "Viru-Nigula alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00009399",
    },
    {
      TASE: "3",
      KOOD: "9401",
      NIMETUS: "Viruküla",
      NIMETUS_LIIGIGA: "Viruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00009401",
    },
    {
      TASE: "3",
      KOOD: "9404",
      NIMETUS: "Virulase küla",
      NIMETUS_LIIGIGA: "Virulase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00009404",
    },
    {
      TASE: "3",
      KOOD: "9406",
      NIMETUS: "Virunurme küla",
      NIMETUS_LIIGIGA: "Virunurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00009406",
    },
    {
      TASE: "3",
      KOOD: "9409",
      NIMETUS: "Viruvere küla",
      NIMETUS_LIIGIGA: "Viruvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00009409",
    },
    {
      TASE: "3",
      KOOD: "9411",
      NIMETUS: "Virve küla",
      NIMETUS_LIIGIGA: "Virve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00009411",
    },
    {
      TASE: "3",
      KOOD: "7596",
      NIMETUS: "Silla küla",
      NIMETUS_LIIGIGA: "Silla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007596",
    },
    {
      TASE: "3",
      KOOD: "7597",
      NIMETUS: "Silmsi küla",
      NIMETUS_LIIGIGA: "Silmsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00007597",
    },
    {
      TASE: "3",
      KOOD: "7598",
      NIMETUS: "Silmsi küla",
      NIMETUS_LIIGIGA: "Silmsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00007598",
    },
    {
      TASE: "3",
      KOOD: "7599",
      NIMETUS: "Simula küla",
      NIMETUS_LIIGIGA: "Simula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007599",
    },
    {
      TASE: "3",
      KOOD: "7600",
      NIMETUS: "Simmuli küla",
      NIMETUS_LIIGIGA: "Simmuli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007600",
    },
    {
      TASE: "3",
      KOOD: "7601",
      NIMETUS: "Simiste küla",
      NIMETUS_LIIGIGA: "Simiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00007601",
    },
    {
      TASE: "3",
      KOOD: "7602",
      NIMETUS: "Simunamäe küla",
      NIMETUS_LIIGIGA: "Simunamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00007602",
    },
    {
      TASE: "3",
      KOOD: "7603",
      NIMETUS: "Simuna alevik",
      NIMETUS_LIIGIGA: "Simuna alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00007603",
    },
    {
      TASE: "3",
      KOOD: "7606",
      NIMETUS: "Sinalepa küla",
      NIMETUS_LIIGIGA: "Sinalepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00007606",
    },
    {
      TASE: "3",
      KOOD: "7609",
      NIMETUS: "Singa küla",
      NIMETUS_LIIGIGA: "Singa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007609",
    },
    {
      TASE: "3",
      KOOD: "7611",
      NIMETUS: "Sinialliku küla",
      NIMETUS_LIIGIGA: "Sinialliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00007611",
    },
    {
      TASE: "3",
      KOOD: "7614",
      NIMETUS: "Siniküla",
      NIMETUS_LIIGIGA: "Siniküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00007614",
    },
    {
      TASE: "3",
      KOOD: "7616",
      NIMETUS: "Sinima küla",
      NIMETUS_LIIGIGA: "Sinima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007616",
    },
    {
      TASE: "3",
      KOOD: "7619",
      NIMETUS: "Sinimäe alevik",
      NIMETUS_LIIGIGA: "Sinimäe alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00007619",
    },
    {
      TASE: "3",
      KOOD: "7622",
      NIMETUS: "Sipa küla",
      NIMETUS_LIIGIGA: "Sipa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00007622",
    },
    {
      TASE: "3",
      KOOD: "7624",
      NIMETUS: "Sipe küla",
      NIMETUS_LIIGIGA: "Sipe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00007624",
    },
    {
      TASE: "3",
      KOOD: "7627",
      NIMETUS: "Sipelga küla",
      NIMETUS_LIIGIGA: "Sipelga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007627",
    },
    {
      TASE: "3",
      KOOD: "7628",
      NIMETUS: "Sirgova küla",
      NIMETUS_LIIGIGA: "Sirgova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007628",
    },
    {
      TASE: "3",
      KOOD: "7630",
      NIMETUS: "Sirevere küla",
      NIMETUS_LIIGIGA: "Sirevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00007630",
    },
    {
      TASE: "3",
      KOOD: "7632",
      NIMETUS: "Sirgu küla",
      NIMETUS_LIIGIGA: "Sirgu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00007632",
    },
    {
      TASE: "3",
      KOOD: "7635",
      NIMETUS: "Sirgumetsa küla",
      NIMETUS_LIIGIGA: "Sirgumetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00007635",
    },
    {
      TASE: "3",
      KOOD: "7637",
      NIMETUS: "Sirguvere küla",
      NIMETUS_LIIGIGA: "Sirguvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00007637",
    },
    {
      TASE: "3",
      KOOD: "7640",
      NIMETUS: "Sirtsi küla",
      NIMETUS_LIIGIGA: "Sirtsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00007640",
    },
    {
      TASE: "3",
      KOOD: "7642",
      NIMETUS: "Sirvaku küla",
      NIMETUS_LIIGIGA: "Sirvaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00007642",
    },
    {
      TASE: "3",
      KOOD: "7645",
      NIMETUS: "Sirvaste küla",
      NIMETUS_LIIGIGA: "Sirvaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00007645",
    },
    {
      TASE: "3",
      KOOD: "7649",
      NIMETUS: "Smolnitsa küla",
      NIMETUS_LIIGIGA: "Smolnitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00007649",
    },
    {
      TASE: "3",
      KOOD: "7650",
      NIMETUS: "Soekõrdsi küla",
      NIMETUS_LIIGIGA: "Soekõrdsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007650",
    },
    {
      TASE: "3",
      KOOD: "7652",
      NIMETUS: "Soe küla",
      NIMETUS_LIIGIGA: "Soe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007652",
    },
    {
      TASE: "3",
      KOOD: "7653",
      NIMETUS: "Soe küla",
      NIMETUS_LIIGIGA: "Soe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00007653",
    },
    {
      TASE: "3",
      KOOD: "7654",
      NIMETUS: "Soe küla",
      NIMETUS_LIIGIGA: "Soe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00007654",
    },
    {
      TASE: "3",
      KOOD: "7655",
      NIMETUS: "Soeküla",
      NIMETUS_LIIGIGA: "Soeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00007655",
    },
    {
      TASE: "3",
      KOOD: "7656",
      NIMETUS: "Soemõisa küla",
      NIMETUS_LIIGIGA: "Soemõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007656",
    },
    {
      TASE: "3",
      KOOD: "7657",
      NIMETUS: "Soena küla",
      NIMETUS_LIIGIGA: "Soena küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007657",
    },
    {
      TASE: "3",
      KOOD: "7658",
      NIMETUS: "Soela küla",
      NIMETUS_LIIGIGA: "Soela küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007658",
    },
    {
      TASE: "3",
      KOOD: "7660",
      NIMETUS: "Soesaare küla",
      NIMETUS_LIIGIGA: "Soesaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00007660",
    },
    {
      TASE: "3",
      KOOD: "7661",
      NIMETUS: "Sohlu küla",
      NIMETUS_LIIGIGA: "Sohlu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00007661",
    },
    {
      TASE: "3",
      KOOD: "7663",
      NIMETUS: "Soeva küla",
      NIMETUS_LIIGIGA: "Soeva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00007663",
    },
    {
      TASE: "3",
      KOOD: "7666",
      NIMETUS: "Soinaste küla",
      NIMETUS_LIIGIGA: "Soinaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00007666",
    },
    {
      TASE: "3",
      KOOD: "7668",
      NIMETUS: "Soitsjärve küla",
      NIMETUS_LIIGIGA: "Soitsjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00007668",
    },
    {
      TASE: "3",
      KOOD: "7671",
      NIMETUS: "Sojamaa küla",
      NIMETUS_LIIGIGA: "Sojamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00007671",
    },
    {
      TASE: "3",
      KOOD: "7674",
      NIMETUS: "Soldina küla",
      NIMETUS_LIIGIGA: "Soldina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00007674",
    },
    {
      TASE: "3",
      KOOD: "7677",
      NIMETUS: "Sompa küla",
      NIMETUS_LIIGIGA: "Sompa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "251",
      ADS_OID: "AY00007677",
    },
    {
      TASE: "3",
      KOOD: "7680",
      NIMETUS: "Sonda alevik",
      NIMETUS_LIIGIGA: "Sonda alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00007680",
    },
    {
      TASE: "3",
      KOOD: "7681",
      NIMETUS: "Sooaluste küla",
      NIMETUS_LIIGIGA: "Sooaluste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "293",
      ADS_OID: "AY00007681",
    },
    {
      TASE: "3",
      KOOD: "7682",
      NIMETUS: "Soo-otsa küla",
      NIMETUS_LIIGIGA: "Soo-otsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007682",
    },
    {
      TASE: "3",
      KOOD: "7683",
      NIMETUS: "Sonni küla",
      NIMETUS_LIIGIGA: "Sonni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00007683",
    },
    {
      TASE: "3",
      KOOD: "7684",
      NIMETUS: "Sooaluse küla",
      NIMETUS_LIIGIGA: "Sooaluse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00007684",
    },
    {
      TASE: "3",
      KOOD: "7686",
      NIMETUS: "Sooblase küla",
      NIMETUS_LIIGIGA: "Sooblase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00007686",
    },
    {
      TASE: "3",
      KOOD: "7688",
      NIMETUS: "Soodevahe küla",
      NIMETUS_LIIGIGA: "Soodevahe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00007688",
    },
    {
      TASE: "3",
      KOOD: "7689",
      NIMETUS: "Soodevahe küla",
      NIMETUS_LIIGIGA: "Soodevahe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007689",
    },
    {
      TASE: "3",
      KOOD: "7691",
      NIMETUS: "Soodi küla",
      NIMETUS_LIIGIGA: "Soodi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007691",
    },
    {
      TASE: "3",
      KOOD: "7693",
      NIMETUS: "Soodla küla",
      NIMETUS_LIIGIGA: "Soodla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00007693",
    },
    {
      TASE: "3",
      KOOD: "7698",
      NIMETUS: "Soohara küla",
      NIMETUS_LIIGIGA: "Soohara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00007698",
    },
    {
      TASE: "3",
      KOOD: "7699",
      NIMETUS: "Sookalda küla",
      NIMETUS_LIIGIGA: "Sookalda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00007699",
    },
    {
      TASE: "3",
      KOOD: "7701",
      NIMETUS: "Sookaera küla",
      NIMETUS_LIIGIGA: "Sookaera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY00007701",
    },
    {
      TASE: "3",
      KOOD: "7702",
      NIMETUS: "Sookatse küla",
      NIMETUS_LIIGIGA: "Sookatse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00007702",
    },
    {
      TASE: "3",
      KOOD: "7703",
      NIMETUS: "Sookalduse küla",
      NIMETUS_LIIGIGA: "Sookalduse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007703",
    },
    {
      TASE: "3",
      KOOD: "7704",
      NIMETUS: "Sookaera-Metsanurga küla",
      NIMETUS_LIIGIGA: "Sookaera-Metsanurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00007704",
    },
    {
      TASE: "3",
      KOOD: "7706",
      NIMETUS: "Sooküla",
      NIMETUS_LIIGIGA: "Sooküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00007706",
    },
    {
      TASE: "3",
      KOOD: "7707",
      NIMETUS: "Sooküla",
      NIMETUS_LIIGIGA: "Sooküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007707",
    },
    {
      TASE: "3",
      KOOD: "7709",
      NIMETUS: "Soolu küla",
      NIMETUS_LIIGIGA: "Soolu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007709",
    },
    {
      TASE: "3",
      KOOD: "7712",
      NIMETUS: "Soolätte küla",
      NIMETUS_LIIGIGA: "Soolätte küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007712",
    },
    {
      TASE: "3",
      KOOD: "7714",
      NIMETUS: "Soome küla",
      NIMETUS_LIIGIGA: "Soome küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00007714",
    },
    {
      TASE: "3",
      KOOD: "7717",
      NIMETUS: "Soometsa küla",
      NIMETUS_LIIGIGA: "Soometsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00007717",
    },
    {
      TASE: "3",
      KOOD: "7719",
      NIMETUS: "Soomevere küla",
      NIMETUS_LIIGIGA: "Soomevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00007719",
    },
    {
      TASE: "3",
      KOOD: "7720",
      NIMETUS: "Soomevere küla",
      NIMETUS_LIIGIGA: "Soomevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00007720",
    },
    {
      TASE: "3",
      KOOD: "7721",
      NIMETUS: "Soomõoru küla",
      NIMETUS_LIIGIGA: "Soomõoru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007721",
    },
    {
      TASE: "3",
      KOOD: "7723",
      NIMETUS: "Soomra küla",
      NIMETUS_LIIGIGA: "Soomra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00007723",
    },
    {
      TASE: "3",
      KOOD: "7724",
      NIMETUS: "Soonda küla",
      NIMETUS_LIIGIGA: "Soonda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00007724",
    },
    {
      TASE: "3",
      KOOD: "7725",
      NIMETUS: "Sooniste küla",
      NIMETUS_LIIGIGA: "Sooniste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00007725",
    },
    {
      TASE: "3",
      KOOD: "7728",
      NIMETUS: "Soonlepa küla",
      NIMETUS_LIIGIGA: "Soonlepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007728",
    },
    {
      TASE: "3",
      KOOD: "7730",
      NIMETUS: "Soontaga küla",
      NIMETUS_LIIGIGA: "Soontaga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00007730",
    },
    {
      TASE: "3",
      KOOD: "7733",
      NIMETUS: "Soonuka küla",
      NIMETUS_LIIGIGA: "Soonuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00007733",
    },
    {
      TASE: "3",
      KOOD: "7734",
      NIMETUS: "Soorinna küla",
      NIMETUS_LIIGIGA: "Soorinna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00007734",
    },
    {
      TASE: "3",
      KOOD: "7735",
      NIMETUS: "Soonurme küla",
      NIMETUS_LIIGIGA: "Soonurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00007735",
    },
    {
      TASE: "3",
      KOOD: "7738",
      NIMETUS: "Sooru küla",
      NIMETUS_LIIGIGA: "Sooru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00007738",
    },
    {
      TASE: "3",
      KOOD: "7739",
      NIMETUS: "Soosalu küla",
      NIMETUS_LIIGIGA: "Soosalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00007739",
    },
    {
      TASE: "3",
      KOOD: "7740",
      NIMETUS: "Soosalu küla",
      NIMETUS_LIIGIGA: "Soosalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00007740",
    },
    {
      TASE: "3",
      KOOD: "7741",
      NIMETUS: "Soosalu küla",
      NIMETUS_LIIGIGA: "Soosalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00007741",
    },
    {
      TASE: "3",
      KOOD: "7743",
      NIMETUS: "Soosilla küla",
      NIMETUS_LIIGIGA: "Soosilla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00007743",
    },
    {
      TASE: "3",
      KOOD: "7745",
      NIMETUS: "Sootaga küla",
      NIMETUS_LIIGIGA: "Sootaga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00007745",
    },
    {
      TASE: "3",
      KOOD: "7746",
      NIMETUS: "Sootaguse küla",
      NIMETUS_LIIGIGA: "Sootaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00007746",
    },
    {
      TASE: "3",
      KOOD: "7748",
      NIMETUS: "Sootaguse küla",
      NIMETUS_LIIGIGA: "Sootaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00007748",
    },
    {
      TASE: "3",
      KOOD: "7750",
      NIMETUS: "Sooviku küla",
      NIMETUS_LIIGIGA: "Sooviku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00007750",
    },
    {
      TASE: "3",
      KOOD: "7751",
      NIMETUS: "Sormuli küla",
      NIMETUS_LIIGIGA: "Sormuli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007751",
    },
    {
      TASE: "3",
      KOOD: "7753",
      NIMETUS: "Sopimetsa küla",
      NIMETUS_LIIGIGA: "Sopimetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00007753",
    },
    {
      TASE: "3",
      KOOD: "7756",
      NIMETUS: "Sortsi küla",
      NIMETUS_LIIGIGA: "Sortsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00007756",
    },
    {
      TASE: "3",
      KOOD: "7760",
      NIMETUS: "Spithami küla / Spithamn",
      NIMETUS_LIIGIGA: "Spithami küla / Spithamn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007760",
    },
    {
      TASE: "3",
      KOOD: "7761",
      NIMETUS: "Sudemäe küla",
      NIMETUS_LIIGIGA: "Sudemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007761",
    },
    {
      TASE: "3",
      KOOD: "7762",
      NIMETUS: "Sudiste küla",
      NIMETUS_LIIGIGA: "Sudiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00007762",
    },
    {
      TASE: "3",
      KOOD: "7764",
      NIMETUS: "Sudaste küla",
      NIMETUS_LIIGIGA: "Sudaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00007764",
    },
    {
      TASE: "3",
      KOOD: "7767",
      NIMETUS: "Sudiste küla",
      NIMETUS_LIIGIGA: "Sudiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00007767",
    },
    {
      TASE: "3",
      KOOD: "7770",
      NIMETUS: "Sugalepa küla",
      NIMETUS_LIIGIGA: "Sugalepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00007770",
    },
    {
      TASE: "3",
      KOOD: "7773",
      NIMETUS: "Mäe-Suhka küla",
      NIMETUS_LIIGIGA: "Mäe-Suhka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007773",
    },
    {
      TASE: "3",
      KOOD: "7776",
      NIMETUS: "Suigu küla",
      NIMETUS_LIIGIGA: "Suigu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00007776",
    },
    {
      TASE: "3",
      KOOD: "7777",
      NIMETUS: "Suigu küla",
      NIMETUS_LIIGIGA: "Suigu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00007777",
    },
    {
      TASE: "3",
      KOOD: "7779",
      NIMETUS: "Suislepa küla",
      NIMETUS_LIIGIGA: "Suislepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00007779",
    },
    {
      TASE: "3",
      KOOD: "7782",
      NIMETUS: "Kirikuküla",
      NIMETUS_LIIGIGA: "Kirikuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00007782",
    },
    {
      TASE: "3",
      KOOD: "7785",
      NIMETUS: "Sulaoja küla",
      NIMETUS_LIIGIGA: "Sulaoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00007785",
    },
    {
      TASE: "3",
      KOOD: "7786",
      NIMETUS: "Sulbi küla",
      NIMETUS_LIIGIGA: "Sulbi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007786",
    },
    {
      TASE: "3",
      KOOD: "7787",
      NIMETUS: "Sulbi küla",
      NIMETUS_LIIGIGA: "Sulbi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007787",
    },
    {
      TASE: "3",
      KOOD: "7790",
      NIMETUS: "Sultsi küla",
      NIMETUS_LIIGIGA: "Sultsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00007790",
    },
    {
      TASE: "3",
      KOOD: "7792",
      NIMETUS: "Sulu küla",
      NIMETUS_LIIGIGA: "Sulu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00007792",
    },
    {
      TASE: "3",
      KOOD: "7793",
      NIMETUS: "Sulu küla",
      NIMETUS_LIIGIGA: "Sulu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00007793",
    },
    {
      TASE: "3",
      KOOD: "7796",
      NIMETUS: "Sulupere küla",
      NIMETUS_LIIGIGA: "Sulupere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00007796",
    },
    {
      TASE: "3",
      KOOD: "7798",
      NIMETUS: "Sulustvere küla",
      NIMETUS_LIIGIGA: "Sulustvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00007798",
    },
    {
      TASE: "3",
      KOOD: "7799",
      NIMETUS: "Sundimetsa küla",
      NIMETUS_LIIGIGA: "Sundimetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007799",
    },
    {
      TASE: "3",
      KOOD: "7801",
      NIMETUS: "Supa küla",
      NIMETUS_LIIGIGA: "Supa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00007801",
    },
    {
      TASE: "3",
      KOOD: "7804",
      NIMETUS: "Supsi küla",
      NIMETUS_LIIGIGA: "Supsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00007804",
    },
    {
      TASE: "3",
      KOOD: "7807",
      NIMETUS: "Surju küla",
      NIMETUS_LIIGIGA: "Surju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00007807",
    },
    {
      TASE: "3",
      KOOD: "7809",
      NIMETUS: "Suru küla",
      NIMETUS_LIIGIGA: "Suru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00007809",
    },
    {
      TASE: "3",
      KOOD: "7812",
      NIMETUS: "Surva küla",
      NIMETUS_LIIGIGA: "Surva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00007812",
    },
    {
      TASE: "3",
      KOOD: "7815",
      NIMETUS: "Sutlema küla",
      NIMETUS_LIIGIGA: "Sutlema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00007815",
    },
    {
      TASE: "3",
      KOOD: "7817",
      NIMETUS: "Sutlepa küla / Sutlep",
      NIMETUS_LIIGIGA: "Sutlepa küla / Sutlep",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007817",
    },
    {
      TASE: "3",
      KOOD: "7820",
      NIMETUS: "Sutte küla",
      NIMETUS_LIIGIGA: "Sutte küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007820",
    },
    {
      TASE: "3",
      KOOD: "7822",
      NIMETUS: "Sutu küla",
      NIMETUS_LIIGIGA: "Sutu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007822",
    },
    {
      TASE: "3",
      KOOD: "7825",
      NIMETUS: "Suuga küla",
      NIMETUS_LIIGIGA: "Suuga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00007825",
    },
    {
      TASE: "3",
      KOOD: "7826",
      NIMETUS: "Suur-Pahila küla",
      NIMETUS_LIIGIGA: "Suur-Pahila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007826",
    },
    {
      TASE: "3",
      KOOD: "7827",
      NIMETUS: "Suur-Rahula küla",
      NIMETUS_LIIGIGA: "Suur-Rahula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007827",
    },
    {
      TASE: "3",
      KOOD: "7828",
      NIMETUS: "Suure-Kambja küla",
      NIMETUS_LIIGIGA: "Suure-Kambja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00007828",
    },
    {
      TASE: "3",
      KOOD: "7829",
      NIMETUS: "Suur-Nõmmküla / Klottorp",
      NIMETUS_LIIGIGA: "Suur-Nõmmküla / Klottorp",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007829",
    },
    {
      TASE: "3",
      KOOD: "7831",
      NIMETUS: "Suure-Rakke küla",
      NIMETUS_LIIGIGA: "Suure-Rakke küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00007831",
    },
    {
      TASE: "3",
      KOOD: "7832",
      NIMETUS: "Suure-Lähtru küla",
      NIMETUS_LIIGIGA: "Suure-Lähtru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00007832",
    },
    {
      TASE: "3",
      KOOD: "7833",
      NIMETUS: "Suure-Rakke küla",
      NIMETUS_LIIGIGA: "Suure-Rakke küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00007833",
    },
    {
      TASE: "3",
      KOOD: "7834",
      NIMETUS: "Suure-Rootsi küla",
      NIMETUS_LIIGIGA: "Suure-Rootsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007834",
    },
    {
      TASE: "3",
      KOOD: "7835",
      NIMETUS: "Suure-Veerksu küla",
      NIMETUS_LIIGIGA: "Suure-Veerksu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00007835",
    },
    {
      TASE: "3",
      KOOD: "7838",
      NIMETUS: "Suurejõe küla",
      NIMETUS_LIIGIGA: "Suurejõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00007838",
    },
    {
      TASE: "3",
      KOOD: "7840",
      NIMETUS: "Suurekivi küla",
      NIMETUS_LIIGIGA: "Suurekivi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00007840",
    },
    {
      TASE: "3",
      KOOD: "7843",
      NIMETUS: "Suuremetsa küla",
      NIMETUS_LIIGIGA: "Suuremetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007843",
    },
    {
      TASE: "3",
      KOOD: "7845",
      NIMETUS: "Suuremõisa küla",
      NIMETUS_LIIGIGA: "Suuremõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00007845",
    },
    {
      TASE: "3",
      KOOD: "7846",
      NIMETUS: "Suuremõisa küla",
      NIMETUS_LIIGIGA: "Suuremõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007846",
    },
    {
      TASE: "3",
      KOOD: "7847",
      NIMETUS: "Suuremõisa küla",
      NIMETUS_LIIGIGA: "Suuremõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00007847",
    },
    {
      TASE: "3",
      KOOD: "7848",
      NIMETUS: "Suurepsi küla",
      NIMETUS_LIIGIGA: "Suurepsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007848",
    },
    {
      TASE: "3",
      KOOD: "7849",
      NIMETUS: "Suurõsuu küla",
      NIMETUS_LIIGIGA: "Suurõsuu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007849",
    },
    {
      TASE: "3",
      KOOD: "7850",
      NIMETUS: "Suureranna küla",
      NIMETUS_LIIGIGA: "Suureranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007850",
    },
    {
      TASE: "3",
      KOOD: "7852",
      NIMETUS: "Suuresta küla",
      NIMETUS_LIIGIGA: "Suuresta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00007852",
    },
    {
      TASE: "3",
      KOOD: "7853",
      NIMETUS: "Suurküla",
      NIMETUS_LIIGIGA: "Suurküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00007853",
    },
    {
      TASE: "3",
      KOOD: "7855",
      NIMETUS: "Suurküla",
      NIMETUS_LIIGIGA: "Suurküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00007855",
    },
    {
      TASE: "3",
      KOOD: "7856",
      NIMETUS: "Suurküla",
      NIMETUS_LIIGIGA: "Suurküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00007856",
    },
    {
      TASE: "3",
      KOOD: "7858",
      NIMETUS: "Suurmetsa küla",
      NIMETUS_LIIGIGA: "Suurmetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00007858",
    },
    {
      TASE: "3",
      KOOD: "7861",
      NIMETUS: "Suurna küla",
      NIMETUS_LIIGIGA: "Suurna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007861",
    },
    {
      TASE: "3",
      KOOD: "7863",
      NIMETUS: "Suurpalu küla",
      NIMETUS_LIIGIGA: "Suurpalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00007863",
    },
    {
      TASE: "3",
      KOOD: "7864",
      NIMETUS: "Suuresadama küla",
      NIMETUS_LIIGIGA: "Suuresadama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007864",
    },
    {
      TASE: "3",
      KOOD: "7866",
      NIMETUS: "Suurpea küla",
      NIMETUS_LIIGIGA: "Suurpea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00007866",
    },
    {
      TASE: "3",
      KOOD: "7868",
      NIMETUS: "Suursoo küla",
      NIMETUS_LIIGIGA: "Suursoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00007868",
    },
    {
      TASE: "3",
      KOOD: "7875",
      NIMETUS: "Sviby küla",
      NIMETUS_LIIGIGA: "Sviby küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00007875",
    },
    {
      TASE: "3",
      KOOD: "7882",
      NIMETUS: "Sõitme küla",
      NIMETUS_LIIGIGA: "Sõitme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00007882",
    },
    {
      TASE: "3",
      KOOD: "7885",
      NIMETUS: "Sõmera küla",
      NIMETUS_LIIGIGA: "Sõmera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007885",
    },
    {
      TASE: "3",
      KOOD: "7887",
      NIMETUS: "Sõmerpalu alevik",
      NIMETUS_LIIGIGA: "Sõmerpalu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007887",
    },
    {
      TASE: "3",
      KOOD: "7888",
      NIMETUS: "Sõmerpalu küla",
      NIMETUS_LIIGIGA: "Sõmerpalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00007888",
    },
    {
      TASE: "3",
      KOOD: "7889",
      NIMETUS: "Sõmeru küla",
      NIMETUS_LIIGIGA: "Sõmeru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00007889",
    },
    {
      TASE: "3",
      KOOD: "7890",
      NIMETUS: "Sõmeru küla",
      NIMETUS_LIIGIGA: "Sõmeru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00007890",
    },
    {
      TASE: "3",
      KOOD: "7891",
      NIMETUS: "Sõmeru küla",
      NIMETUS_LIIGIGA: "Sõmeru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00007891",
    },
    {
      TASE: "3",
      KOOD: "7892",
      NIMETUS: "Sõmeru alevik",
      NIMETUS_LIIGIGA: "Sõmeru alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00007892",
    },
    {
      TASE: "3",
      KOOD: "7895",
      NIMETUS: "Sõrandu küla",
      NIMETUS_LIIGIGA: "Sõrandu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00007895",
    },
    {
      TASE: "3",
      KOOD: "7897",
      NIMETUS: "Sõreste küla",
      NIMETUS_LIIGIGA: "Sõreste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00007897",
    },
    {
      TASE: "3",
      KOOD: "7900",
      NIMETUS: "Sõru küla",
      NIMETUS_LIIGIGA: "Sõru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007900",
    },
    {
      TASE: "3",
      KOOD: "7902",
      NIMETUS: "Sõrumäe küla",
      NIMETUS_LIIGIGA: "Sõrumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00007902",
    },
    {
      TASE: "3",
      KOOD: "7905",
      NIMETUS: "Sõrve küla",
      NIMETUS_LIIGIGA: "Sõrve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00007905",
    },
    {
      TASE: "3",
      KOOD: "7908",
      NIMETUS: "Sõtke küla",
      NIMETUS_LIIGIGA: "Sõtke küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00007908",
    },
    {
      TASE: "3",
      KOOD: "7909",
      NIMETUS: "Sõtke küla",
      NIMETUS_LIIGIGA: "Sõtke küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00007909",
    },
    {
      TASE: "3",
      KOOD: "7912",
      NIMETUS: "Sõõrike küla",
      NIMETUS_LIIGIGA: "Sõõrike küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00007912",
    },
    {
      TASE: "3",
      KOOD: "7913",
      NIMETUS: "Sõõru küla",
      NIMETUS_LIIGIGA: "Sõõru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007913",
    },
    {
      TASE: "3",
      KOOD: "7918",
      NIMETUS: "Säkna küla",
      NIMETUS_LIIGIGA: "Säkna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00007918",
    },
    {
      TASE: "3",
      KOOD: "7921",
      NIMETUS: "Sälliksaare küla",
      NIMETUS_LIIGIGA: "Sälliksaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00007921",
    },
    {
      TASE: "3",
      KOOD: "7924",
      NIMETUS: "Sälliku küla",
      NIMETUS_LIIGIGA: "Sälliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00007924",
    },
    {
      TASE: "3",
      KOOD: "7925",
      NIMETUS: "Sämi-Tagaküla",
      NIMETUS_LIIGIGA: "Sämi-Tagaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00007925",
    },
    {
      TASE: "3",
      KOOD: "7927",
      NIMETUS: "Sämi küla",
      NIMETUS_LIIGIGA: "Sämi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00007927",
    },
    {
      TASE: "3",
      KOOD: "7928",
      NIMETUS: "Säpina küla",
      NIMETUS_LIIGIGA: "Säpina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00007928",
    },
    {
      TASE: "3",
      KOOD: "7930",
      NIMETUS: "Sänna küla / Sännä",
      NIMETUS_LIIGIGA: "Sänna küla / Sännä",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007930",
    },
    {
      TASE: "3",
      KOOD: "7933",
      NIMETUS: "Säre küla",
      NIMETUS_LIIGIGA: "Säre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00007933",
    },
    {
      TASE: "3",
      KOOD: "7938",
      NIMETUS: "Särgla küla",
      NIMETUS_LIIGIGA: "Särgla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007938",
    },
    {
      TASE: "3",
      KOOD: "7943",
      NIMETUS: "Sätsuvere küla",
      NIMETUS_LIIGIGA: "Sätsuvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00007943",
    },
    {
      TASE: "3",
      KOOD: "7946",
      NIMETUS: "Sääla küla",
      NIMETUS_LIIGIGA: "Sääla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00007946",
    },
    {
      TASE: "3",
      KOOD: "7949",
      NIMETUS: "Sääre küla",
      NIMETUS_LIIGIGA: "Sääre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007949",
    },
    {
      TASE: "3",
      KOOD: "7950",
      NIMETUS: "Sääre küla",
      NIMETUS_LIIGIGA: "Sääre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007950",
    },
    {
      TASE: "3",
      KOOD: "7951",
      NIMETUS: "Sääre küla",
      NIMETUS_LIIGIGA: "Sääre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "303",
      ADS_OID: "AY00007951",
    },
    {
      TASE: "3",
      KOOD: "7953",
      NIMETUS: "Sääritsa küla",
      NIMETUS_LIIGIGA: "Sääritsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00007953",
    },
    {
      TASE: "3",
      KOOD: "7958",
      NIMETUS: "Sääsekõrva küla",
      NIMETUS_LIIGIGA: "Sääsekõrva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00007958",
    },
    {
      TASE: "3",
      KOOD: "7961",
      NIMETUS: "Sääsküla",
      NIMETUS_LIIGIGA: "Sääsküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00007961",
    },
    {
      TASE: "3",
      KOOD: "7962",
      NIMETUS: "Sääsküla",
      NIMETUS_LIIGIGA: "Sääsküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY00007962",
    },
    {
      TASE: "3",
      KOOD: "7963",
      NIMETUS: "Sääsküla",
      NIMETUS_LIIGIGA: "Sääsküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00007963",
    },
    {
      TASE: "3",
      KOOD: "7964",
      NIMETUS: "Säässaare küla",
      NIMETUS_LIIGIGA: "Säässaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00007964",
    },
    {
      TASE: "3",
      KOOD: "7967",
      NIMETUS: "Säästla küla",
      NIMETUS_LIIGIGA: "Säästla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00007967",
    },
    {
      TASE: "3",
      KOOD: "7968",
      NIMETUS: "Söödi küla",
      NIMETUS_LIIGIGA: "Söödi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00007968",
    },
    {
      TASE: "3",
      KOOD: "7971",
      NIMETUS: "Söderby küla",
      NIMETUS_LIIGIGA: "Söderby küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "907",
      ADS_OID: "AY00007971",
    },
    {
      TASE: "3",
      KOOD: "7972",
      NIMETUS: "Sülluste küla",
      NIMETUS_LIIGIGA: "Sülluste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00007972",
    },
    {
      TASE: "3",
      KOOD: "7975",
      NIMETUS: "Sülgoja küla",
      NIMETUS_LIIGIGA: "Sülgoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00007975",
    },
    {
      TASE: "3",
      KOOD: "7978",
      NIMETUS: "Sürgavere küla",
      NIMETUS_LIIGIGA: "Sürgavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00007978",
    },
    {
      TASE: "3",
      KOOD: "7981",
      NIMETUS: "Süvahavva küla",
      NIMETUS_LIIGIGA: "Süvahavva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00007981",
    },
    {
      TASE: "3",
      KOOD: "7983",
      NIMETUS: "Süvalepa küla",
      NIMETUS_LIIGIGA: "Süvalepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00007983",
    },
    {
      TASE: "3",
      KOOD: "7988",
      NIMETUS: "Taabri küla",
      NIMETUS_LIIGIGA: "Taabri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00007988",
    },
    {
      TASE: "3",
      KOOD: "7991",
      NIMETUS: "Taadikvere küla",
      NIMETUS_LIIGIGA: "Taadikvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00007991",
    },
    {
      TASE: "3",
      KOOD: "7993",
      NIMETUS: "Taagepera küla",
      NIMETUS_LIIGIGA: "Taagepera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00007993",
    },
    {
      TASE: "3",
      KOOD: "7996",
      NIMETUS: "Taali küla",
      NIMETUS_LIIGIGA: "Taali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00007996",
    },
    {
      TASE: "3",
      KOOD: "7998",
      NIMETUS: "Taaliku küla",
      NIMETUS_LIIGIGA: "Taaliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00007998",
    },
    {
      TASE: "3",
      KOOD: "8001",
      NIMETUS: "Taaravainu küla",
      NIMETUS_LIIGIGA: "Taaravainu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00008001",
    },
    {
      TASE: "3",
      KOOD: "8003",
      NIMETUS: "Taari küla",
      NIMETUS_LIIGIGA: "Taari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008003",
    },
    {
      TASE: "3",
      KOOD: "8006",
      NIMETUS: "Tabara küla",
      NIMETUS_LIIGIGA: "Tabara küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00008006",
    },
    {
      TASE: "3",
      KOOD: "8009",
      NIMETUS: "Tabasalu alevik",
      NIMETUS_LIIGIGA: "Tabasalu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00008009",
    },
    {
      TASE: "3",
      KOOD: "8011",
      NIMETUS: "Taberlaane küla",
      NIMETUS_LIIGIGA: "Taberlaane küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00008011",
    },
    {
      TASE: "3",
      KOOD: "8014",
      NIMETUS: "Tabina küla",
      NIMETUS_LIIGIGA: "Tabina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008014",
    },
    {
      TASE: "3",
      KOOD: "8016",
      NIMETUS: "Tabivere alevik",
      NIMETUS_LIIGIGA: "Tabivere alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00008016",
    },
    {
      TASE: "3",
      KOOD: "8019",
      NIMETUS: "Tabria küla",
      NIMETUS_LIIGIGA: "Tabria küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00008019",
    },
    {
      TASE: "3",
      KOOD: "8022",
      NIMETUS: "Tade küla",
      NIMETUS_LIIGIGA: "Tade küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00008022",
    },
    {
      TASE: "3",
      KOOD: "8025",
      NIMETUS: "Taebla alevik",
      NIMETUS_LIIGIGA: "Taebla alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00008025",
    },
    {
      TASE: "3",
      KOOD: "8030",
      NIMETUS: "Taevere küla",
      NIMETUS_LIIGIGA: "Taevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00008030",
    },
    {
      TASE: "3",
      KOOD: "8035",
      NIMETUS: "Tagajõe küla",
      NIMETUS_LIIGIGA: "Tagajõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00008035",
    },
    {
      TASE: "3",
      KOOD: "8038",
      NIMETUS: "Tagakolga küla",
      NIMETUS_LIIGIGA: "Tagakolga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008038",
    },
    {
      TASE: "3",
      KOOD: "8040",
      NIMETUS: "Tagaküla",
      NIMETUS_LIIGIGA: "Tagaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008040",
    },
    {
      TASE: "3",
      KOOD: "8045",
      NIMETUS: "Tagametsa küla",
      NIMETUS_LIIGIGA: "Tagametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00008045",
    },
    {
      TASE: "3",
      KOOD: "8048",
      NIMETUS: "Tagamõisa küla",
      NIMETUS_LIIGIGA: "Tagamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008048",
    },
    {
      TASE: "3",
      KOOD: "8049",
      NIMETUS: "Tagamõisa küla",
      NIMETUS_LIIGIGA: "Tagamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008049",
    },
    {
      TASE: "3",
      KOOD: "8050",
      NIMETUS: "Taganurga küla",
      NIMETUS_LIIGIGA: "Taganurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008050",
    },
    {
      TASE: "3",
      KOOD: "8053",
      NIMETUS: "Tagaranna küla",
      NIMETUS_LIIGIGA: "Tagaranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008053",
    },
    {
      TASE: "3",
      KOOD: "8055",
      NIMETUS: "Tagassaare küla",
      NIMETUS_LIIGIGA: "Tagassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00008055",
    },
    {
      TASE: "3",
      KOOD: "8058",
      NIMETUS: "Tagavere küla",
      NIMETUS_LIIGIGA: "Tagavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00008058",
    },
    {
      TASE: "3",
      KOOD: "8059",
      NIMETUS: "Tagavere küla",
      NIMETUS_LIIGIGA: "Tagavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008059",
    },
    {
      TASE: "3",
      KOOD: "8061",
      NIMETUS: "Taguküla",
      NIMETUS_LIIGIGA: "Taguküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008061",
    },
    {
      TASE: "3",
      KOOD: "8064",
      NIMETUS: "Tagula küla",
      NIMETUS_LIIGIGA: "Tagula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00008064",
    },
    {
      TASE: "3",
      KOOD: "8066",
      NIMETUS: "Tagumaa küla",
      NIMETUS_LIIGIGA: "Tagumaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00008066",
    },
    {
      TASE: "3",
      KOOD: "8067",
      NIMETUS: "Tahkuna küla",
      NIMETUS_LIIGIGA: "Tahkuna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008067",
    },
    {
      TASE: "3",
      KOOD: "8069",
      NIMETUS: "Taheva küla",
      NIMETUS_LIIGIGA: "Taheva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00008069",
    },
    {
      TASE: "3",
      KOOD: "8072",
      NIMETUS: "Tahkuranna küla",
      NIMETUS_LIIGIGA: "Tahkuranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00008072",
    },
    {
      TASE: "3",
      KOOD: "8074",
      NIMETUS: "Tahu küla / Skåtanäs",
      NIMETUS_LIIGIGA: "Tahu küla / Skåtanäs",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00008074",
    },
    {
      TASE: "3",
      KOOD: "8081",
      NIMETUS: "Tallikeste küla",
      NIMETUS_LIIGIGA: "Tallikeste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008081",
    },
    {
      TASE: "3",
      KOOD: "8082",
      NIMETUS: "Talka küla",
      NIMETUS_LIIGIGA: "Talka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008082",
    },
    {
      TASE: "3",
      KOOD: "8083",
      NIMETUS: "Tali küla",
      NIMETUS_LIIGIGA: "Tali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY00008083",
    },
    {
      TASE: "3",
      KOOD: "8084",
      NIMETUS: "Talila küla",
      NIMETUS_LIIGIGA: "Talila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008084",
    },
    {
      TASE: "3",
      KOOD: "8085",
      NIMETUS: "Talvikese küla",
      NIMETUS_LIIGIGA: "Talvikese küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00008085",
    },
    {
      TASE: "3",
      KOOD: "8086",
      NIMETUS: "Tallima küla",
      NIMETUS_LIIGIGA: "Tallima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008086",
    },
    {
      TASE: "3",
      KOOD: "8088",
      NIMETUS: "Tamba küla",
      NIMETUS_LIIGIGA: "Tamba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008088",
    },
    {
      TASE: "3",
      KOOD: "8091",
      NIMETUS: "Kõima küla",
      NIMETUS_LIIGIGA: "Kõima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008091",
    },
    {
      TASE: "3",
      KOOD: "8092",
      NIMETUS: "Tamme küla",
      NIMETUS_LIIGIGA: "Tamme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008092",
    },
    {
      TASE: "3",
      KOOD: "8094",
      NIMETUS: "Tamme küla",
      NIMETUS_LIIGIGA: "Tamme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00008094",
    },
    {
      TASE: "3",
      KOOD: "8095",
      NIMETUS: "Tammela küla",
      NIMETUS_LIIGIGA: "Tammela küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008095",
    },
    {
      TASE: "3",
      KOOD: "8097",
      NIMETUS: "Tammeküla",
      NIMETUS_LIIGIGA: "Tammeküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00008097",
    },
    {
      TASE: "3",
      KOOD: "8098",
      NIMETUS: "Tammemäe küla",
      NIMETUS_LIIGIGA: "Tammemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00008098",
    },
    {
      TASE: "3",
      KOOD: "8099",
      NIMETUS: "Tammese küla",
      NIMETUS_LIIGIGA: "Tammese küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008099",
    },
    {
      TASE: "3",
      KOOD: "8102",
      NIMETUS: "Tammessaare küla",
      NIMETUS_LIIGIGA: "Tammessaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00008102",
    },
    {
      TASE: "3",
      KOOD: "8104",
      NIMETUS: "Tammetaguse küla",
      NIMETUS_LIIGIGA: "Tammetaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00008104",
    },
    {
      TASE: "3",
      KOOD: "8107",
      NIMETUS: "Tammevaldma küla",
      NIMETUS_LIIGIGA: "Tammevaldma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00008107",
    },
    {
      TASE: "3",
      KOOD: "8108",
      NIMETUS: "Tammiku küla",
      NIMETUS_LIIGIGA: "Tammiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00008108",
    },
    {
      TASE: "3",
      KOOD: "8109",
      NIMETUS: "Voki-Tamme küla",
      NIMETUS_LIIGIGA: "Voki-Tamme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008109",
    },
    {
      TASE: "3",
      KOOD: "8110",
      NIMETUS: "Tammiku küla",
      NIMETUS_LIIGIGA: "Tammiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00008110",
    },
    {
      TASE: "3",
      KOOD: "8112",
      NIMETUS: "Tammiku küla",
      NIMETUS_LIIGIGA: "Tammiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00008112",
    },
    {
      TASE: "3",
      KOOD: "8113",
      NIMETUS: "Tammiku küla",
      NIMETUS_LIIGIGA: "Tammiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00008113",
    },
    {
      TASE: "3",
      KOOD: "8114",
      NIMETUS: "Tammiku alevik",
      NIMETUS_LIIGIGA: "Tammiku alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "251",
      ADS_OID: "AY00008114",
    },
    {
      TASE: "3",
      KOOD: "8115",
      NIMETUS: "Tammiku küla",
      NIMETUS_LIIGIGA: "Tammiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00008115",
    },
    {
      TASE: "3",
      KOOD: "8116",
      NIMETUS: "Tammiku küla",
      NIMETUS_LIIGIGA: "Tammiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00008116",
    },
    {
      TASE: "3",
      KOOD: "8117",
      NIMETUS: "Tammispää küla",
      NIMETUS_LIIGIGA: "Tammispää küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00008117",
    },
    {
      TASE: "3",
      KOOD: "8118",
      NIMETUS: "Tammispea küla",
      NIMETUS_LIIGIGA: "Tammispea küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00008118",
    },
    {
      TASE: "3",
      KOOD: "8120",
      NIMETUS: "Tammiste küla",
      NIMETUS_LIIGIGA: "Tammiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00008120",
    },
    {
      TASE: "3",
      KOOD: "8121",
      NIMETUS: "Tammiste küla",
      NIMETUS_LIIGIGA: "Tammiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00008121",
    },
    {
      TASE: "3",
      KOOD: "8122",
      NIMETUS: "Tammistu küla",
      NIMETUS_LIIGIGA: "Tammistu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008122",
    },
    {
      TASE: "3",
      KOOD: "8123",
      NIMETUS: "Tammistu küla",
      NIMETUS_LIIGIGA: "Tammistu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00008123",
    },
    {
      TASE: "3",
      KOOD: "8124",
      NIMETUS: "Tammsaare küla",
      NIMETUS_LIIGIGA: "Tammsaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008124",
    },
    {
      TASE: "3",
      KOOD: "8125",
      NIMETUS: "Tammistu küla",
      NIMETUS_LIIGIGA: "Tammistu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00008125",
    },
    {
      TASE: "3",
      KOOD: "8126",
      NIMETUS: "Tammneeme küla",
      NIMETUS_LIIGIGA: "Tammneeme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00008126",
    },
    {
      TASE: "3",
      KOOD: "8128",
      NIMETUS: "Tammuna küla",
      NIMETUS_LIIGIGA: "Tammuna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008128",
    },
    {
      TASE: "3",
      KOOD: "8131",
      NIMETUS: "Tammuru küla",
      NIMETUS_LIIGIGA: "Tammuru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00008131",
    },
    {
      TASE: "3",
      KOOD: "8133",
      NIMETUS: "Tamsa küla",
      NIMETUS_LIIGIGA: "Tamsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00008133",
    },
    {
      TASE: "3",
      KOOD: "8136",
      NIMETUS: "Tamse küla",
      NIMETUS_LIIGIGA: "Tamse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00008136",
    },
    {
      TASE: "3",
      KOOD: "8137",
      NIMETUS: "Tamsi küla",
      NIMETUS_LIIGIGA: "Tamsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00008137",
    },
    {
      TASE: "3",
      KOOD: "8138",
      NIMETUS: "Tamsi küla",
      NIMETUS_LIIGIGA: "Tamsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00008138",
    },
    {
      TASE: "3",
      KOOD: "8139",
      NIMETUS: "Tapupere küla",
      NIMETUS_LIIGIGA: "Tapupere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00008139",
    },
    {
      TASE: "3",
      KOOD: "8140",
      NIMETUS: "Tapa linn",
      NIMETUS_LIIGIGA: "Tapa linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00008140",
    },
    {
      TASE: "3",
      KOOD: "8141",
      NIMETUS: "Tapiku küla",
      NIMETUS_LIIGIGA: "Tapiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00008141",
    },
    {
      TASE: "3",
      KOOD: "8144",
      NIMETUS: "Tapurla küla",
      NIMETUS_LIIGIGA: "Tapurla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00008144",
    },
    {
      TASE: "3",
      KOOD: "8147",
      NIMETUS: "Tarakuse küla",
      NIMETUS_LIIGIGA: "Tarakuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00008147",
    },
    {
      TASE: "3",
      KOOD: "8149",
      NIMETUS: "Tarakvere küla",
      NIMETUS_LIIGIGA: "Tarakvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00008149",
    },
    {
      TASE: "3",
      KOOD: "8150",
      NIMETUS: "Tareste küla",
      NIMETUS_LIIGIGA: "Tareste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008150",
    },
    {
      TASE: "3",
      KOOD: "8152",
      NIMETUS: "Tarbja küla",
      NIMETUS_LIIGIGA: "Tarbja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00008152",
    },
    {
      TASE: "3",
      KOOD: "8153",
      NIMETUS: "Tareste küla",
      NIMETUS_LIIGIGA: "Tareste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008153",
    },
    {
      TASE: "3",
      KOOD: "8154",
      NIMETUS: "Tarumaa küla",
      NIMETUS_LIIGIGA: "Tarumaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00008154",
    },
    {
      TASE: "3",
      KOOD: "8156",
      NIMETUS: "Tarva küla",
      NIMETUS_LIIGIGA: "Tarva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00008156",
    },
    {
      TASE: "3",
      KOOD: "8157",
      NIMETUS: "Tarva küla",
      NIMETUS_LIIGIGA: "Tarva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008157",
    },
    {
      TASE: "3",
      KOOD: "8159",
      NIMETUS: "Tarvastu küla",
      NIMETUS_LIIGIGA: "Tarvastu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008159",
    },
    {
      TASE: "3",
      KOOD: "8162",
      NIMETUS: "Taterma küla",
      NIMETUS_LIIGIGA: "Taterma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008162",
    },
    {
      TASE: "3",
      KOOD: "8165",
      NIMETUS: "Tatra küla",
      NIMETUS_LIIGIGA: "Tatra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00008165",
    },
    {
      TASE: "3",
      KOOD: "8167",
      NIMETUS: "Tatruse küla",
      NIMETUS_LIIGIGA: "Tatruse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00008167",
    },
    {
      TASE: "3",
      KOOD: "8170",
      NIMETUS: "Taudsa küla",
      NIMETUS_LIIGIGA: "Taudsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008170",
    },
    {
      TASE: "3",
      KOOD: "8174",
      NIMETUS: "Tealama küla",
      NIMETUS_LIIGIGA: "Tealama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00008174",
    },
    {
      TASE: "3",
      KOOD: "8175",
      NIMETUS: "Tedre küla",
      NIMETUS_LIIGIGA: "Tedre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008175",
    },
    {
      TASE: "3",
      KOOD: "8177",
      NIMETUS: "Tiastõ küla",
      NIMETUS_LIIGIGA: "Tiastõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008177",
    },
    {
      TASE: "3",
      KOOD: "8178",
      NIMETUS: "Tidriku küla",
      NIMETUS_LIIGIGA: "Tidriku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00008178",
    },
    {
      TASE: "3",
      KOOD: "8180",
      NIMETUS: "Teedla küla",
      NIMETUS_LIIGIGA: "Teedla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00008180",
    },
    {
      TASE: "3",
      KOOD: "8182",
      NIMETUS: "Teenuse küla",
      NIMETUS_LIIGIGA: "Teenuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00008182",
    },
    {
      TASE: "3",
      KOOD: "8185",
      NIMETUS: "Tehumardi küla",
      NIMETUS_LIIGIGA: "Tehumardi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008185",
    },
    {
      TASE: "3",
      KOOD: "8188",
      NIMETUS: "Teilma küla",
      NIMETUS_LIIGIGA: "Teilma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00008188",
    },
    {
      TASE: "3",
      KOOD: "8189",
      NIMETUS: "Teilma küla",
      NIMETUS_LIIGIGA: "Teilma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00008189",
    },
    {
      TASE: "3",
      KOOD: "8190",
      NIMETUS: "Tempa küla",
      NIMETUS_LIIGIGA: "Tempa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008190",
    },
    {
      TASE: "3",
      KOOD: "8191",
      NIMETUS: "Tepia küla",
      NIMETUS_LIIGIGA: "Tepia küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008191",
    },
    {
      TASE: "3",
      KOOD: "8192",
      NIMETUS: "Tellaste küla",
      NIMETUS_LIIGIGA: "Tellaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008192",
    },
    {
      TASE: "3",
      KOOD: "8193",
      NIMETUS: "Tõreska küla",
      NIMETUS_LIIGIGA: "Tõreska küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "353",
      ADS_OID: "AY00008193",
    },
    {
      TASE: "3",
      KOOD: "8195",
      NIMETUS: "Tepelvälja küla",
      NIMETUS_LIIGIGA: "Tepelvälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00008195",
    },
    {
      TASE: "3",
      KOOD: "8198",
      NIMETUS: "Terepi küla",
      NIMETUS_LIIGIGA: "Terepi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00008198",
    },
    {
      TASE: "3",
      KOOD: "8199",
      NIMETUS: "Tessova küla",
      NIMETUS_LIIGIGA: "Tessova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008199",
    },
    {
      TASE: "3",
      KOOD: "8200",
      NIMETUS: "Tialasõ küla",
      NIMETUS_LIIGIGA: "Tialasõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008200",
    },
    {
      TASE: "3",
      KOOD: "8201",
      NIMETUS: "Terikeste küla",
      NIMETUS_LIIGIGA: "Terikeste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00008201",
    },
    {
      TASE: "3",
      KOOD: "8204",
      NIMETUS: "Teterüvä küla",
      NIMETUS_LIIGIGA: "Teterüvä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008204",
    },
    {
      TASE: "3",
      KOOD: "8208",
      NIMETUS: "Tiduvere küla",
      NIMETUS_LIIGIGA: "Tiduvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00008208",
    },
    {
      TASE: "3",
      KOOD: "8209",
      NIMETUS: "Tiharu küla",
      NIMETUS_LIIGIGA: "Tiharu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008209",
    },
    {
      TASE: "3",
      KOOD: "8210",
      NIMETUS: "Tiheda küla",
      NIMETUS_LIIGIGA: "Tiheda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00008210",
    },
    {
      TASE: "3",
      KOOD: "8211",
      NIMETUS: "Tigase küla",
      NIMETUS_LIIGIGA: "Tigase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00008211",
    },
    {
      TASE: "3",
      KOOD: "8217",
      NIMETUS: "Tiido küla",
      NIMETUS_LIIGIGA: "Tiido küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00008217",
    },
    {
      TASE: "3",
      KOOD: "8218",
      NIMETUS: "Tiidu küla",
      NIMETUS_LIIGIGA: "Tiidu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008218",
    },
    {
      TASE: "3",
      KOOD: "8219",
      NIMETUS: "Tiidu küla",
      NIMETUS_LIIGIGA: "Tiidu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00008219",
    },
    {
      TASE: "3",
      KOOD: "8220",
      NIMETUS: "Tiilima küla",
      NIMETUS_LIIGIGA: "Tiilima küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00008220",
    },
    {
      TASE: "3",
      KOOD: "8222",
      NIMETUS: "Tiigi küla",
      NIMETUS_LIIGIGA: "Tiigi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00008222",
    },
    {
      TASE: "3",
      KOOD: "8223",
      NIMETUS: "Tiirhanna küla",
      NIMETUS_LIIGIGA: "Tiirhanna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008223",
    },
    {
      TASE: "3",
      KOOD: "8224",
      NIMETUS: "Tiilige küla",
      NIMETUS_LIIGIGA: "Tiilige küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008224",
    },
    {
      TASE: "3",
      KOOD: "8227",
      NIMETUS: "Tiirimetsa küla",
      NIMETUS_LIIGIGA: "Tiirimetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008227",
    },
    {
      TASE: "3",
      KOOD: "8229",
      NIMETUS: "Tiitsa küla",
      NIMETUS_LIIGIGA: "Tiitsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008229",
    },
    {
      TASE: "3",
      KOOD: "8230",
      NIMETUS: "Tiitsuotsa küla",
      NIMETUS_LIIGIGA: "Tiitsuotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008230",
    },
    {
      TASE: "3",
      KOOD: "8231",
      NIMETUS: "Tiklasõ küla",
      NIMETUS_LIIGIGA: "Tiklasõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008231",
    },
    {
      TASE: "3",
      KOOD: "8232",
      NIMETUS: "Maidla küla",
      NIMETUS_LIIGIGA: "Maidla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00008232",
    },
    {
      TASE: "3",
      KOOD: "8233",
      NIMETUS: "Tika küla",
      NIMETUS_LIIGIGA: "Tika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008233",
    },
    {
      TASE: "3",
      KOOD: "8235",
      NIMETUS: "Tila küla",
      NIMETUS_LIIGIGA: "Tila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00008235",
    },
    {
      TASE: "3",
      KOOD: "8236",
      NIMETUS: "Tilga küla",
      NIMETUS_LIIGIGA: "Tilga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008236",
    },
    {
      TASE: "3",
      KOOD: "8237",
      NIMETUS: "Tilgu küla",
      NIMETUS_LIIGIGA: "Tilgu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008237",
    },
    {
      TASE: "3",
      KOOD: "8238",
      NIMETUS: "Tilga küla",
      NIMETUS_LIIGIGA: "Tilga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00008238",
    },
    {
      TASE: "3",
      KOOD: "8240",
      NIMETUS: "Tilla küla",
      NIMETUS_LIIGIGA: "Tilla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00008240",
    },
    {
      TASE: "3",
      KOOD: "8241",
      NIMETUS: "Tindi küla",
      NIMETUS_LIIGIGA: "Tindi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008241",
    },
    {
      TASE: "3",
      KOOD: "8243",
      NIMETUS: "Tilsi küla",
      NIMETUS_LIIGIGA: "Tilsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00008243",
    },
    {
      TASE: "3",
      KOOD: "8244",
      NIMETUS: "Timo küla",
      NIMETUS_LIIGIGA: "Timo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00008244",
    },
    {
      TASE: "3",
      KOOD: "8248",
      NIMETUS: "Tinu küla",
      NIMETUS_LIIGIGA: "Tinu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00008248",
    },
    {
      TASE: "3",
      KOOD: "8251",
      NIMETUS: "Tipu küla",
      NIMETUS_LIIGIGA: "Tipu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00008251",
    },
    {
      TASE: "3",
      KOOD: "8252",
      NIMETUS: "Tiri küla",
      NIMETUS_LIIGIGA: "Tiri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008252",
    },
    {
      TASE: "3",
      KOOD: "8254",
      NIMETUS: "Tirbiku küla",
      NIMETUS_LIIGIGA: "Tirbiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00008254",
    },
    {
      TASE: "3",
      KOOD: "8257",
      NIMETUS: "Tiskre küla",
      NIMETUS_LIIGIGA: "Tiskre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00008257",
    },
    {
      TASE: "3",
      KOOD: "8261",
      NIMETUS: "Tobia küla",
      NIMETUS_LIIGIGA: "Tobia küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00008261",
    },
    {
      TASE: "3",
      KOOD: "8264",
      NIMETUS: "Tobraselja küla",
      NIMETUS_LIIGIGA: "Tobraselja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008264",
    },
    {
      TASE: "3",
      KOOD: "8266",
      NIMETUS: "Tobrova küla",
      NIMETUS_LIIGIGA: "Tobrova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008266",
    },
    {
      TASE: "3",
      KOOD: "8267",
      NIMETUS: "Tohkri küla",
      NIMETUS_LIIGIGA: "Tohkri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008267",
    },
    {
      TASE: "3",
      KOOD: "8269",
      NIMETUS: "Tohera küla",
      NIMETUS_LIIGIGA: "Tohera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00008269",
    },
    {
      TASE: "3",
      KOOD: "8270",
      NIMETUS: "Tohku küla",
      NIMETUS_LIIGIGA: "Tohku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008270",
    },
    {
      TASE: "3",
      KOOD: "8271",
      NIMETUS: "Tohvri küla",
      NIMETUS_LIIGIGA: "Tohvri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00008271",
    },
    {
      TASE: "3",
      KOOD: "8272",
      NIMETUS: "Tohvri küla",
      NIMETUS_LIIGIGA: "Tohvri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00008272",
    },
    {
      TASE: "3",
      KOOD: "8275",
      NIMETUS: "Toila alevik",
      NIMETUS_LIIGIGA: "Toila alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00008275",
    },
    {
      TASE: "3",
      KOOD: "8278",
      NIMETUS: "Tokolopi küla",
      NIMETUS_LIIGIGA: "Tokolopi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00008278",
    },
    {
      TASE: "3",
      KOOD: "8279",
      NIMETUS: "Tolla küla",
      NIMETUS_LIIGIGA: "Tolla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00008279",
    },
    {
      TASE: "3",
      KOOD: "8280",
      NIMETUS: "Toku küla",
      NIMETUS_LIIGIGA: "Toku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00008280",
    },
    {
      TASE: "3",
      KOOD: "8283",
      NIMETUS: "Tolli küla",
      NIMETUS_LIIGIGA: "Tolli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00008283",
    },
    {
      TASE: "3",
      KOOD: "8284",
      NIMETUS: "Toodsi küla",
      NIMETUS_LIIGIGA: "Toodsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008284",
    },
    {
      TASE: "3",
      KOOD: "8286",
      NIMETUS: "Tonja küla",
      NIMETUS_LIIGIGA: "Tonja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008286",
    },
    {
      TASE: "3",
      KOOD: "8289",
      NIMETUS: "Toolamaa küla",
      NIMETUS_LIIGIGA: "Toolamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00008289",
    },
    {
      TASE: "3",
      KOOD: "8290",
      NIMETUS: "Toolamaa küla",
      NIMETUS_LIIGIGA: "Toolamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00008290",
    },
    {
      TASE: "3",
      KOOD: "8293",
      NIMETUS: "Toolse küla",
      NIMETUS_LIIGIGA: "Toolse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00008293",
    },
    {
      TASE: "3",
      KOOD: "8295",
      NIMETUS: "Tooma küla",
      NIMETUS_LIIGIGA: "Tooma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00008295",
    },
    {
      TASE: "3",
      KOOD: "8298",
      NIMETUS: "Toomalõuka küla",
      NIMETUS_LIIGIGA: "Toomalõuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008298",
    },
    {
      TASE: "3",
      KOOD: "8303",
      NIMETUS: "Toomika küla",
      NIMETUS_LIIGIGA: "Toomika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00008303",
    },
    {
      TASE: "3",
      KOOD: "8304",
      NIMETUS: "Toomla küla",
      NIMETUS_LIIGIGA: "Toomla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00008304",
    },
    {
      TASE: "3",
      KOOD: "8305",
      NIMETUS: "Toomja küla",
      NIMETUS_LIIGIGA: "Toomja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00008305",
    },
    {
      TASE: "3",
      KOOD: "8308",
      NIMETUS: "Tooni küla",
      NIMETUS_LIIGIGA: "Tooni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00008308",
    },
    {
      TASE: "3",
      KOOD: "8310",
      NIMETUS: "Toosi küla",
      NIMETUS_LIIGIGA: "Toosi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00008310",
    },
    {
      TASE: "3",
      KOOD: "8313",
      NIMETUS: "Tooste küla",
      NIMETUS_LIIGIGA: "Tooste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00008313",
    },
    {
      TASE: "3",
      KOOD: "8314",
      NIMETUS: "Toodsi küla",
      NIMETUS_LIIGIGA: "Toodsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008314",
    },
    {
      TASE: "3",
      KOOD: "8315",
      NIMETUS: "Tootsi küla",
      NIMETUS_LIIGIGA: "Tootsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00008315",
    },
    {
      TASE: "3",
      KOOD: "8316",
      NIMETUS: "Tootsi alev",
      NIMETUS_LIIGIGA: "Tootsi alev",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00008316",
    },
    {
      TASE: "3",
      KOOD: "8318",
      NIMETUS: "Toovere küla",
      NIMETUS_LIIGIGA: "Toovere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00008318",
    },
    {
      TASE: "3",
      KOOD: "8321",
      NIMETUS: "Tanska küla",
      NIMETUS_LIIGIGA: "Tanska küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00008321",
    },
    {
      TASE: "3",
      KOOD: "8325",
      NIMETUS: "Tori küla",
      NIMETUS_LIIGIGA: "Tori küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00008325",
    },
    {
      TASE: "3",
      KOOD: "8326",
      NIMETUS: "Tori alevik",
      NIMETUS_LIIGIGA: "Tori alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00008326",
    },
    {
      TASE: "3",
      KOOD: "8329",
      NIMETUS: "Torila küla",
      NIMETUS_LIIGIGA: "Torila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00008329",
    },
    {
      TASE: "3",
      KOOD: "8331",
      NIMETUS: "Torma alevik",
      NIMETUS_LIIGIGA: "Torma alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00008331",
    },
    {
      TASE: "3",
      KOOD: "8334",
      NIMETUS: "Tormi küla",
      NIMETUS_LIIGIGA: "Tormi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00008334",
    },
    {
      TASE: "3",
      KOOD: "8335",
      NIMETUS: "Toruküla",
      NIMETUS_LIIGIGA: "Toruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00008335",
    },
    {
      TASE: "3",
      KOOD: "8336",
      NIMETUS: "Tornimäe küla",
      NIMETUS_LIIGIGA: "Tornimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008336",
    },
    {
      TASE: "3",
      KOOD: "8337",
      NIMETUS: "Treiali küla",
      NIMETUS_LIIGIGA: "Treiali küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008337",
    },
    {
      TASE: "3",
      KOOD: "8340",
      NIMETUS: "Treimani küla",
      NIMETUS_LIIGIGA: "Treimani küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00008340",
    },
    {
      TASE: "3",
      KOOD: "8341",
      NIMETUS: "Triginä küla",
      NIMETUS_LIIGIGA: "Triginä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008341",
    },
    {
      TASE: "3",
      KOOD: "8343",
      NIMETUS: "Treski küla",
      NIMETUS_LIIGIGA: "Treski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008343",
    },
    {
      TASE: "3",
      KOOD: "8346",
      NIMETUS: "Triigi küla",
      NIMETUS_LIIGIGA: "Triigi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00008346",
    },
    {
      TASE: "3",
      KOOD: "8347",
      NIMETUS: "Triigi küla",
      NIMETUS_LIIGIGA: "Triigi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00008347",
    },
    {
      TASE: "3",
      KOOD: "8348",
      NIMETUS: "Triigi küla",
      NIMETUS_LIIGIGA: "Triigi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00008348",
    },
    {
      TASE: "3",
      KOOD: "8351",
      NIMETUS: "Trolla küla",
      NIMETUS_LIIGIGA: "Trolla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008351",
    },
    {
      TASE: "3",
      KOOD: "8353",
      NIMETUS: "Tromsi küla",
      NIMETUS_LIIGIGA: "Tromsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00008353",
    },
    {
      TASE: "3",
      KOOD: "8354",
      NIMETUS: "Tsiamäe küla",
      NIMETUS_LIIGIGA: "Tsiamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00008354",
    },
    {
      TASE: "3",
      KOOD: "8355",
      NIMETUS: "Tsergondõ küla",
      NIMETUS_LIIGIGA: "Tsergondõ küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00008355",
    },
    {
      TASE: "3",
      KOOD: "8356",
      NIMETUS: "Truuta küla",
      NIMETUS_LIIGIGA: "Truuta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00008356",
    },
    {
      TASE: "3",
      KOOD: "9414",
      NIMETUS: "Visela küla",
      NIMETUS_LIIGIGA: "Visela küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00009414",
    },
    {
      TASE: "3",
      KOOD: "9417",
      NIMETUS: "Viskla küla",
      NIMETUS_LIIGIGA: "Viskla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00009417",
    },
    {
      TASE: "3",
      KOOD: "9419",
      NIMETUS: "Visnapuu küla",
      NIMETUS_LIIGIGA: "Visnapuu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00009419",
    },
    {
      TASE: "3",
      KOOD: "9422",
      NIMETUS: "Vissi küla",
      NIMETUS_LIIGIGA: "Vissi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00009422",
    },
    {
      TASE: "3",
      KOOD: "9423",
      NIMETUS: "Vissi küla",
      NIMETUS_LIIGIGA: "Vissi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00009423",
    },
    {
      TASE: "3",
      KOOD: "9425",
      NIMETUS: "Vissuvere küla",
      NIMETUS_LIIGIGA: "Vissuvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00009425",
    },
    {
      TASE: "3",
      KOOD: "9426",
      NIMETUS: "Vissuvere küla",
      NIMETUS_LIIGIGA: "Vissuvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009426",
    },
    {
      TASE: "3",
      KOOD: "9429",
      NIMETUS: "Vistla küla",
      NIMETUS_LIIGIGA: "Vistla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00009429",
    },
    {
      TASE: "3",
      KOOD: "9430",
      NIMETUS: "Visusti küla",
      NIMETUS_LIIGIGA: "Visusti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00009430",
    },
    {
      TASE: "3",
      KOOD: "9431",
      NIMETUS: "Visusti küla",
      NIMETUS_LIIGIGA: "Visusti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00009431",
    },
    {
      TASE: "3",
      KOOD: "9432",
      NIMETUS: "Vitsiku küla",
      NIMETUS_LIIGIGA: "Vitsiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00009432",
    },
    {
      TASE: "3",
      KOOD: "9434",
      NIMETUS: "Viti küla",
      NIMETUS_LIIGIGA: "Viti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00009434",
    },
    {
      TASE: "3",
      KOOD: "9437",
      NIMETUS: "Vitsjärve küla",
      NIMETUS_LIIGIGA: "Vitsjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00009437",
    },
    {
      TASE: "3",
      KOOD: "9440",
      NIMETUS: "Vivva küla",
      NIMETUS_LIIGIGA: "Vivva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009440",
    },
    {
      TASE: "3",
      KOOD: "9444",
      NIMETUS: "Vodava küla",
      NIMETUS_LIIGIGA: "Vodava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY00009444",
    },
    {
      TASE: "3",
      KOOD: "9446",
      NIMETUS: "Vodja küla",
      NIMETUS_LIIGIGA: "Vodja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00009446",
    },
    {
      TASE: "3",
      KOOD: "9449",
      NIMETUS: "Vohnja küla",
      NIMETUS_LIIGIGA: "Vohnja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00009449",
    },
    {
      TASE: "3",
      KOOD: "9452",
      NIMETUS: "Voika küla",
      NIMETUS_LIIGIGA: "Voika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "528",
      ADS_OID: "AY00009452",
    },
    {
      TASE: "3",
      KOOD: "9453",
      NIMETUS: "Voka küla",
      NIMETUS_LIIGIGA: "Voka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00009453",
    },
    {
      TASE: "3",
      KOOD: "9455",
      NIMETUS: "Voka alevik",
      NIMETUS_LIIGIGA: "Voka alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "803",
      ADS_OID: "AY00009455",
    },
    {
      TASE: "3",
      KOOD: "9458",
      NIMETUS: "Voki küla",
      NIMETUS_LIIGIGA: "Voki küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009458",
    },
    {
      TASE: "3",
      KOOD: "9461",
      NIMETUS: "Voldi küla",
      NIMETUS_LIIGIGA: "Voldi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00009461",
    },
    {
      TASE: "3",
      KOOD: "9464",
      NIMETUS: "Voorbahi küla",
      NIMETUS_LIIGIGA: "Voorbahi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY00009464",
    },
    {
      TASE: "3",
      KOOD: "9466",
      NIMETUS: "Voore küla",
      NIMETUS_LIIGIGA: "Voore küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00009466",
    },
    {
      TASE: "3",
      KOOD: "9467",
      NIMETUS: "Voore küla",
      NIMETUS_LIIGIGA: "Voore küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00009467",
    },
    {
      TASE: "3",
      KOOD: "9470",
      NIMETUS: "Vooreküla",
      NIMETUS_LIIGIGA: "Vooreküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY00009470",
    },
    {
      TASE: "3",
      KOOD: "9472",
      NIMETUS: "Voorepalu küla",
      NIMETUS_LIIGIGA: "Voorepalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY00009472",
    },
    {
      TASE: "3",
      KOOD: "9475",
      NIMETUS: "Voorepera küla",
      NIMETUS_LIIGIGA: "Voorepera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY00009475",
    },
    {
      TASE: "3",
      KOOD: "9477",
      NIMETUS: "Vooru küla",
      NIMETUS_LIIGIGA: "Vooru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009477",
    },
    {
      TASE: "3",
      KOOD: "9478",
      NIMETUS: "Voose küla",
      NIMETUS_LIIGIGA: "Voose küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00009478",
    },
    {
      TASE: "3",
      KOOD: "9480",
      NIMETUS: "Voose küla",
      NIMETUS_LIIGIGA: "Voose küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00009480",
    },
    {
      TASE: "3",
      KOOD: "9483",
      NIMETUS: "Vorbuse küla",
      NIMETUS_LIIGIGA: "Vorbuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "793",
      ADS_OID: "AY00009483",
    },
    {
      TASE: "3",
      KOOD: "9484",
      NIMETUS: "Voropi küla",
      NIMETUS_LIIGIGA: "Voropi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009484",
    },
    {
      TASE: "3",
      KOOD: "9485",
      NIMETUS: "Vorsti küla",
      NIMETUS_LIIGIGA: "Vorsti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00009485",
    },
    {
      TASE: "3",
      KOOD: "9486",
      NIMETUS: "Vorsti küla",
      NIMETUS_LIIGIGA: "Vorsti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00009486",
    },
    {
      TASE: "3",
      KOOD: "9487",
      NIMETUS: "Vorstimäe küla",
      NIMETUS_LIIGIGA: "Vorstimäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009487",
    },
    {
      TASE: "3",
      KOOD: "9488",
      NIMETUS: "Vungi küla",
      NIMETUS_LIIGIGA: "Vungi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009488",
    },
    {
      TASE: "3",
      KOOD: "9489",
      NIMETUS: "Võduvere küla",
      NIMETUS_LIIGIGA: "Võduvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00009489",
    },
    {
      TASE: "3",
      KOOD: "9490",
      NIMETUS: "Võduvere küla",
      NIMETUS_LIIGIGA: "Võduvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00009490",
    },
    {
      TASE: "3",
      KOOD: "9491",
      NIMETUS: "Võerdla küla",
      NIMETUS_LIIGIGA: "Võerdla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00009491",
    },
    {
      TASE: "3",
      KOOD: "9492",
      NIMETUS: "Vuti küla",
      NIMETUS_LIIGIGA: "Vuti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00009492",
    },
    {
      TASE: "3",
      KOOD: "9493",
      NIMETUS: "Võeva küla",
      NIMETUS_LIIGIGA: "Võeva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00009493",
    },
    {
      TASE: "3",
      KOOD: "9494",
      NIMETUS: "Võhma küla",
      NIMETUS_LIIGIGA: "Võhma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00009494",
    },
    {
      TASE: "3",
      KOOD: "9495",
      NIMETUS: "Võhma küla",
      NIMETUS_LIIGIGA: "Võhma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "661",
      ADS_OID: "AY00009495",
    },
    {
      TASE: "3",
      KOOD: "9497",
      NIMETUS: "Võhma küla",
      NIMETUS_LIIGIGA: "Võhma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009497",
    },
    {
      TASE: "3",
      KOOD: "9498",
      NIMETUS: "Võhma küla",
      NIMETUS_LIIGIGA: "Võhma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00009498",
    },
    {
      TASE: "3",
      KOOD: "9501",
      NIMETUS: "Võhmanõmme küla",
      NIMETUS_LIIGIGA: "Võhmanõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00009501",
    },
    {
      TASE: "3",
      KOOD: "9503",
      NIMETUS: "Võhmassaare küla",
      NIMETUS_LIIGIGA: "Võhmassaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00009503",
    },
    {
      TASE: "3",
      KOOD: "9505",
      NIMETUS: "Võhmetu küla",
      NIMETUS_LIIGIGA: "Võhmetu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00009505",
    },
    {
      TASE: "3",
      KOOD: "9506",
      NIMETUS: "Võhmuta küla",
      NIMETUS_LIIGIGA: "Võhmuta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY00009506",
    },
    {
      TASE: "3",
      KOOD: "9508",
      NIMETUS: "Võhu küla",
      NIMETUS_LIIGIGA: "Võhu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY00009508",
    },
    {
      TASE: "3",
      KOOD: "9511",
      NIMETUS: "Võiardi küla",
      NIMETUS_LIIGIGA: "Võiardi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00009511",
    },
    {
      TASE: "3",
      KOOD: "9514",
      NIMETUS: "Võibla küla",
      NIMETUS_LIIGIGA: "Võibla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00009514",
    },
    {
      TASE: "3",
      KOOD: "9516",
      NIMETUS: "Võide küla",
      NIMETUS_LIIGIGA: "Võide küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00009516",
    },
    {
      TASE: "3",
      KOOD: "9519",
      NIMETUS: "Võidivere küla",
      NIMETUS_LIIGIGA: "Võidivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00009519",
    },
    {
      TASE: "3",
      KOOD: "9521",
      NIMETUS: "Võidu küla",
      NIMETUS_LIIGIGA: "Võidu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00009521",
    },
    {
      TASE: "3",
      KOOD: "9524",
      NIMETUS: "Võidula küla",
      NIMETUS_LIIGIGA: "Võidula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00009524",
    },
    {
      TASE: "3",
      KOOD: "9526",
      NIMETUS: "Võiera küla",
      NIMETUS_LIIGIGA: "Võiera küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00009526",
    },
    {
      TASE: "3",
      KOOD: "9527",
      NIMETUS: "Võika küla",
      NIMETUS_LIIGIGA: "Võika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00009527",
    },
    {
      TASE: "3",
      KOOD: "9529",
      NIMETUS: "Võikvere küla",
      NIMETUS_LIIGIGA: "Võikvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00009529",
    },
    {
      TASE: "3",
      KOOD: "9531",
      NIMETUS: "Võiküla",
      NIMETUS_LIIGIGA: "Võiküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00009531",
    },
    {
      TASE: "3",
      KOOD: "9534",
      NIMETUS: "Võipere küla",
      NIMETUS_LIIGIGA: "Võipere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY00009534",
    },
    {
      TASE: "3",
      KOOD: "9536",
      NIMETUS: "Võisiku küla",
      NIMETUS_LIIGIGA: "Võisiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00009536",
    },
    {
      TASE: "3",
      KOOD: "9539",
      NIMETUS: "Võiste alevik",
      NIMETUS_LIIGIGA: "Võiste alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY00009539",
    },
    {
      TASE: "3",
      KOOD: "9541",
      NIMETUS: "Võistre küla",
      NIMETUS_LIIGIGA: "Võistre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009541",
    },
    {
      TASE: "3",
      KOOD: "9544",
      NIMETUS: "Võitra küla",
      NIMETUS_LIIGIGA: "Võitra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00009544",
    },
    {
      TASE: "3",
      KOOD: "9546",
      NIMETUS: "Võivaku küla",
      NIMETUS_LIIGIGA: "Võivaku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00009546",
    },
    {
      TASE: "3",
      KOOD: "9549",
      NIMETUS: "Võivere küla",
      NIMETUS_LIIGIGA: "Võivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00009549",
    },
    {
      TASE: "3",
      KOOD: "9552",
      NIMETUS: "Võle küla",
      NIMETUS_LIIGIGA: "Võle küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00009552",
    },
    {
      TASE: "3",
      KOOD: "9554",
      NIMETUS: "Võlla küla",
      NIMETUS_LIIGIGA: "Võlla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "478",
      ADS_OID: "AY00009554",
    },
    {
      TASE: "3",
      KOOD: "9555",
      NIMETUS: "Võlla küla",
      NIMETUS_LIIGIGA: "Võlla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00009555",
    },
    {
      TASE: "3",
      KOOD: "9558",
      NIMETUS: "Võlle küla",
      NIMETUS_LIIGIGA: "Võlle küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00009558",
    },
    {
      TASE: "3",
      KOOD: "9560",
      NIMETUS: "Võlli küla",
      NIMETUS_LIIGIGA: "Võlli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY00009560",
    },
    {
      TASE: "3",
      KOOD: "9561",
      NIMETUS: "Võlli küla",
      NIMETUS_LIIGIGA: "Võlli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00009561",
    },
    {
      TASE: "3",
      KOOD: "9564",
      NIMETUS: "Võlsi küla",
      NIMETUS_LIIGIGA: "Võlsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009564",
    },
    {
      TASE: "3",
      KOOD: "9567",
      NIMETUS: "Võmmorski küla",
      NIMETUS_LIIGIGA: "Võmmorski küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009567",
    },
    {
      TASE: "3",
      KOOD: "9568",
      NIMETUS: "Võnnu küla",
      NIMETUS_LIIGIGA: "Võnnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00009568",
    },
    {
      TASE: "3",
      KOOD: "9570",
      NIMETUS: "Võnnu alevik",
      NIMETUS_LIIGIGA: "Võnnu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00009570",
    },
    {
      TASE: "3",
      KOOD: "9571",
      NIMETUS: "Võpolsova küla",
      NIMETUS_LIIGIGA: "Võpolsova küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009571",
    },
    {
      TASE: "3",
      KOOD: "9572",
      NIMETUS: "Võntküla",
      NIMETUS_LIIGIGA: "Võntküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00009572",
    },
    {
      TASE: "3",
      KOOD: "9575",
      NIMETUS: "Võrevere küla",
      NIMETUS_LIIGIGA: "Võrevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00009575",
    },
    {
      TASE: "3",
      KOOD: "9578",
      NIMETUS: "Võrkla küla",
      NIMETUS_LIIGIGA: "Võrkla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY00009578",
    },
    {
      TASE: "3",
      KOOD: "9580",
      NIMETUS: "Võrnu küla",
      NIMETUS_LIIGIGA: "Võrnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00009580",
    },
    {
      TASE: "3",
      KOOD: "9581",
      NIMETUS: "Võrsna küla",
      NIMETUS_LIIGIGA: "Võrsna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009581",
    },
    {
      TASE: "3",
      KOOD: "9583",
      NIMETUS: "Võruküla",
      NIMETUS_LIIGIGA: "Võruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00009583",
    },
    {
      TASE: "3",
      KOOD: "9584",
      NIMETUS: "Võrungi küla",
      NIMETUS_LIIGIGA: "Võrungi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00009584",
    },
    {
      TASE: "3",
      KOOD: "9585",
      NIMETUS: "Võrumõisa küla",
      NIMETUS_LIIGIGA: "Võrumõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009585",
    },
    {
      TASE: "3",
      KOOD: "9588",
      NIMETUS: "Võrusoo küla",
      NIMETUS_LIIGIGA: "Võrusoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009588",
    },
    {
      TASE: "3",
      KOOD: "9591",
      NIMETUS: "Võsivere küla",
      NIMETUS_LIIGIGA: "Võsivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00009591",
    },
    {
      TASE: "3",
      KOOD: "9592",
      NIMETUS: "Võsu alevik",
      NIMETUS_LIIGIGA: "Võsu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00009592",
    },
    {
      TASE: "3",
      KOOD: "9593",
      NIMETUS: "Võsupere küla",
      NIMETUS_LIIGIGA: "Võsupere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "191",
      ADS_OID: "AY00009593",
    },
    {
      TASE: "3",
      KOOD: "9596",
      NIMETUS: "Võtikvere küla",
      NIMETUS_LIIGIGA: "Võtikvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00009596",
    },
    {
      TASE: "3",
      KOOD: "9599",
      NIMETUS: "Võuküla",
      NIMETUS_LIIGIGA: "Võuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00009599",
    },
    {
      TASE: "3",
      KOOD: "9602",
      NIMETUS: "Võõbu küla",
      NIMETUS_LIIGIGA: "Võõbu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00009602",
    },
    {
      TASE: "3",
      KOOD: "9605",
      NIMETUS: "Võõpste küla",
      NIMETUS_LIIGIGA: "Võõpste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "291",
      ADS_OID: "AY00009605",
    },
    {
      TASE: "3",
      KOOD: "9607",
      NIMETUS: "Võõpsu küla",
      NIMETUS_LIIGIGA: "Võõpsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009607",
    },
    {
      TASE: "3",
      KOOD: "9608",
      NIMETUS: "Võõpsu alevik",
      NIMETUS_LIIGIGA: "Võõpsu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00009608",
    },
    {
      TASE: "3",
      KOOD: "9612",
      NIMETUS: "Vägari küla",
      NIMETUS_LIIGIGA: "Vägari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00009612",
    },
    {
      TASE: "3",
      KOOD: "9615",
      NIMETUS: "Vägeva küla",
      NIMETUS_LIIGIGA: "Vägeva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00009615",
    },
    {
      TASE: "3",
      KOOD: "9616",
      NIMETUS: "Väike-Ahli küla",
      NIMETUS_LIIGIGA: "Väike-Ahli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00009616",
    },
    {
      TASE: "3",
      KOOD: "9618",
      NIMETUS: "Väheru küla",
      NIMETUS_LIIGIGA: "Väheru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00009618",
    },
    {
      TASE: "3",
      KOOD: "9621",
      NIMETUS: "Väike-Kamari küla",
      NIMETUS_LIIGIGA: "Väike-Kamari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00009621",
    },
    {
      TASE: "3",
      KOOD: "9623",
      NIMETUS: "Väike-Kareda küla",
      NIMETUS_LIIGIGA: "Väike-Kareda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00009623",
    },
    {
      TASE: "3",
      KOOD: "9626",
      NIMETUS: "Väike-Kõpu küla",
      NIMETUS_LIIGIGA: "Väike-Kõpu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009626",
    },
    {
      TASE: "3",
      KOOD: "9628",
      NIMETUS: "Väike-Maarja alevik",
      NIMETUS_LIIGIGA: "Väike-Maarja alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00009628",
    },
    {
      TASE: "3",
      KOOD: "9629",
      NIMETUS: "Väike-Pahila küla",
      NIMETUS_LIIGIGA: "Väike-Pahila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009629",
    },
    {
      TASE: "3",
      KOOD: "9631",
      NIMETUS: "Väike-Pungerja küla",
      NIMETUS_LIIGIGA: "Väike-Pungerja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "130",
      ADS_OID: "AY00009631",
    },
    {
      TASE: "3",
      KOOD: "9632",
      NIMETUS: "Väike-Rahula küla",
      NIMETUS_LIIGIGA: "Väike-Rahula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009632",
    },
    {
      TASE: "3",
      KOOD: "9633",
      NIMETUS: "Väike-Rakke küla",
      NIMETUS_LIIGIGA: "Väike-Rakke küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY00009633",
    },
    {
      TASE: "3",
      KOOD: "9634",
      NIMETUS: "Väike-Rootsi küla",
      NIMETUS_LIIGIGA: "Väike-Rootsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009634",
    },
    {
      TASE: "3",
      KOOD: "9635",
      NIMETUS: "Väike-Rõsna küla",
      NIMETUS_LIIGIGA: "Väike-Rõsna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009635",
    },
    {
      TASE: "3",
      KOOD: "9636",
      NIMETUS: "Väimela alevik",
      NIMETUS_LIIGIGA: "Väimela alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009636",
    },
    {
      TASE: "3",
      KOOD: "9637",
      NIMETUS: "Väiko-Serga küla",
      NIMETUS_LIIGIGA: "Väiko-Serga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009637",
    },
    {
      TASE: "3",
      KOOD: "9638",
      NIMETUS: "Väinjärve küla",
      NIMETUS_LIIGIGA: "Väinjärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00009638",
    },
    {
      TASE: "3",
      KOOD: "9639",
      NIMETUS: "Väiko-Härmä küla",
      NIMETUS_LIIGIGA: "Väiko-Härmä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009639",
    },
    {
      TASE: "3",
      KOOD: "9640",
      NIMETUS: "Väiko-Tiilige küla",
      NIMETUS_LIIGIGA: "Väiko-Tiilige küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009640",
    },
    {
      TASE: "3",
      KOOD: "9641",
      NIMETUS: "Väiso küla",
      NIMETUS_LIIGIGA: "Väiso küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY00009641",
    },
    {
      TASE: "3",
      KOOD: "9642",
      NIMETUS: "Väkra küla",
      NIMETUS_LIIGIGA: "Väkra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009642",
    },
    {
      TASE: "3",
      KOOD: "9643",
      NIMETUS: "Väiku-Ruuga küla",
      NIMETUS_LIIGIGA: "Väiku-Ruuga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009643",
    },
    {
      TASE: "3",
      KOOD: "9644",
      NIMETUS: "Välgi küla",
      NIMETUS_LIIGIGA: "Välgi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00009644",
    },
    {
      TASE: "3",
      KOOD: "9645",
      NIMETUS: "Väljaküla",
      NIMETUS_LIIGIGA: "Väljaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00009645",
    },
    {
      TASE: "3",
      KOOD: "9646",
      NIMETUS: "Välgita küla",
      NIMETUS_LIIGIGA: "Välgita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009646",
    },
    {
      TASE: "3",
      KOOD: "9647",
      NIMETUS: "Väike-Rakke küla",
      NIMETUS_LIIGIGA: "Väike-Rakke küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00009647",
    },
    {
      TASE: "3",
      KOOD: "9648",
      NIMETUS: "Väike-Tammiku küla",
      NIMETUS_LIIGIGA: "Väike-Tammiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00009648",
    },
    {
      TASE: "3",
      KOOD: "9649",
      NIMETUS: "Väljaküla",
      NIMETUS_LIIGIGA: "Väljaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009649",
    },
    {
      TASE: "3",
      KOOD: "9651",
      NIMETUS: "Väljaküla",
      NIMETUS_LIIGIGA: "Väljaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00009651",
    },
    {
      TASE: "3",
      KOOD: "9653",
      NIMETUS: "Väljaotsa küla",
      NIMETUS_LIIGIGA: "Väljaotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00009653",
    },
    {
      TASE: "3",
      KOOD: "9654",
      NIMETUS: "Väljataguse küla",
      NIMETUS_LIIGIGA: "Väljataguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY00009654",
    },
    {
      TASE: "3",
      KOOD: "9655",
      NIMETUS: "Väljaotsa küla",
      NIMETUS_LIIGIGA: "Väljaotsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00009655",
    },
    {
      TASE: "3",
      KOOD: "9656",
      NIMETUS: "Väljataguse küla",
      NIMETUS_LIIGIGA: "Väljataguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00009656",
    },
    {
      TASE: "3",
      KOOD: "9658",
      NIMETUS: "Välta küla",
      NIMETUS_LIIGIGA: "Välta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009658",
    },
    {
      TASE: "3",
      KOOD: "9661",
      NIMETUS: "Väluste küla",
      NIMETUS_LIIGIGA: "Väluste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009661",
    },
    {
      TASE: "3",
      KOOD: "9663",
      NIMETUS: "Vändra alev",
      NIMETUS_LIIGIGA: "Vändra alev",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00009663",
    },
    {
      TASE: "3",
      KOOD: "9664",
      NIMETUS: "Vändra küla",
      NIMETUS_LIIGIGA: "Vändra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY00009664",
    },
    {
      TASE: "3",
      KOOD: "9665",
      NIMETUS: "Vänni küla",
      NIMETUS_LIIGIGA: "Vänni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY00009665",
    },
    {
      TASE: "3",
      KOOD: "9666",
      NIMETUS: "Vängla küla",
      NIMETUS_LIIGIGA: "Vängla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00009666",
    },
    {
      TASE: "3",
      KOOD: "9669",
      NIMETUS: "Värati küla",
      NIMETUS_LIIGIGA: "Värati küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY00009669",
    },
    {
      TASE: "3",
      KOOD: "9672",
      NIMETUS: "Värska alevik",
      NIMETUS_LIIGIGA: "Värska alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009672",
    },
    {
      TASE: "3",
      KOOD: "9673",
      NIMETUS: "Vätse küla",
      NIMETUS_LIIGIGA: "Vätse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00009673",
    },
    {
      TASE: "3",
      KOOD: "9674",
      NIMETUS: "Värssu küla",
      NIMETUS_LIIGIGA: "Värssu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00009674",
    },
    {
      TASE: "3",
      KOOD: "9680",
      NIMETUS: "Väägvere küla",
      NIMETUS_LIIGIGA: "Väägvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00009680",
    },
    {
      TASE: "3",
      KOOD: "9683",
      NIMETUS: "Vääna küla",
      NIMETUS_LIIGIGA: "Vääna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00009683",
    },
    {
      TASE: "3",
      KOOD: "9685",
      NIMETUS: "Vääna-Jõesuu küla",
      NIMETUS_LIIGIGA: "Vääna-Jõesuu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY00009685",
    },
    {
      TASE: "3",
      KOOD: "9686",
      NIMETUS: "Väänla küla",
      NIMETUS_LIIGIGA: "Väänla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00009686",
    },
    {
      TASE: "3",
      KOOD: "9688",
      NIMETUS: "Väänikvere küla",
      NIMETUS_LIIGIGA: "Väänikvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00009688",
    },
    {
      TASE: "3",
      KOOD: "9689",
      NIMETUS: "Vedruka küla",
      NIMETUS_LIIGIGA: "Vedruka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY00009689",
    },
    {
      TASE: "3",
      KOOD: "9691",
      NIMETUS: "Õepa küla",
      NIMETUS_LIIGIGA: "Õepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00009691",
    },
    {
      TASE: "3",
      KOOD: "9695",
      NIMETUS: "Õisu alevik",
      NIMETUS_LIIGIGA: "Õisu alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00009695",
    },
    {
      TASE: "3",
      KOOD: "9696",
      NIMETUS: "Õle küla",
      NIMETUS_LIIGIGA: "Õle küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00009696",
    },
    {
      TASE: "3",
      KOOD: "9699",
      NIMETUS: "Õlatu küla",
      NIMETUS_LIIGIGA: "Õlatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00009699",
    },
    {
      TASE: "3",
      KOOD: "9703",
      NIMETUS: "Õngu küla",
      NIMETUS_LIIGIGA: "Õngu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00009703",
    },
    {
      TASE: "3",
      KOOD: "9707",
      NIMETUS: "Õrsava küla",
      NIMETUS_LIIGIGA: "Õrsava küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY00009707",
    },
    {
      TASE: "3",
      KOOD: "9710",
      NIMETUS: "Õru alevik",
      NIMETUS_LIIGIGA: "Õru alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00009710",
    },
    {
      TASE: "3",
      KOOD: "9713",
      NIMETUS: "Õruste küla",
      NIMETUS_LIIGIGA: "Õruste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY00009713",
    },
    {
      TASE: "3",
      KOOD: "9717",
      NIMETUS: "Õssu küla",
      NIMETUS_LIIGIGA: "Õssu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00009717",
    },
    {
      TASE: "3",
      KOOD: "9721",
      NIMETUS: "Õuna küla",
      NIMETUS_LIIGIGA: "Õuna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00009721",
    },
    {
      TASE: "3",
      KOOD: "9725",
      NIMETUS: "Õvanurme küla",
      NIMETUS_LIIGIGA: "Õvanurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00009725",
    },
    {
      TASE: "3",
      KOOD: "9728",
      NIMETUS: "Õvi küla",
      NIMETUS_LIIGIGA: "Õvi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00009728",
    },
    {
      TASE: "3",
      KOOD: "9729",
      NIMETUS: "Äherdi küla",
      NIMETUS_LIIGIGA: "Äherdi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00009729",
    },
    {
      TASE: "3",
      KOOD: "9733",
      NIMETUS: "Ädu küla",
      NIMETUS_LIIGIGA: "Ädu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY00009733",
    },
    {
      TASE: "3",
      KOOD: "9737",
      NIMETUS: "Ähijärve küla",
      NIMETUS_LIIGIGA: "Ähijärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY00009737",
    },
    {
      TASE: "3",
      KOOD: "9741",
      NIMETUS: "Äiamaa küla",
      NIMETUS_LIIGIGA: "Äiamaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00009741",
    },
    {
      TASE: "3",
      KOOD: "9742",
      NIMETUS: "Äila küla",
      NIMETUS_LIIGIGA: "Äila küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00009742",
    },
    {
      TASE: "3",
      KOOD: "9744",
      NIMETUS: "Äigrumäe küla",
      NIMETUS_LIIGIGA: "Äigrumäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY00009744",
    },
    {
      TASE: "3",
      KOOD: "9748",
      NIMETUS: "Äksi alevik",
      NIMETUS_LIIGIGA: "Äksi alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "796",
      ADS_OID: "AY00009748",
    },
    {
      TASE: "3",
      KOOD: "9749",
      NIMETUS: "Äksi küla",
      NIMETUS_LIIGIGA: "Äksi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "338",
      ADS_OID: "AY00009749",
    },
    {
      TASE: "3",
      KOOD: "9752",
      NIMETUS: "Ämari alevik",
      NIMETUS_LIIGIGA: "Ämari alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00009752",
    },
    {
      TASE: "3",
      KOOD: "9755",
      NIMETUS: "Ämbra küla",
      NIMETUS_LIIGIGA: "Ämbra küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00009755",
    },
    {
      TASE: "3",
      KOOD: "9758",
      NIMETUS: "Ämmuste küla",
      NIMETUS_LIIGIGA: "Ämmuste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009758",
    },
    {
      TASE: "3",
      KOOD: "9762",
      NIMETUS: "Änari küla",
      NIMETUS_LIIGIGA: "Änari küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY00009762",
    },
    {
      TASE: "3",
      KOOD: "9765",
      NIMETUS: "Ängi küla",
      NIMETUS_LIIGIGA: "Ängi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00009765",
    },
    {
      TASE: "3",
      KOOD: "9767",
      NIMETUS: "Änglema küla",
      NIMETUS_LIIGIGA: "Änglema küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY00009767",
    },
    {
      TASE: "3",
      KOOD: "9770",
      NIMETUS: "Änkküla",
      NIMETUS_LIIGIGA: "Änkküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY00009770",
    },
    {
      TASE: "3",
      KOOD: "9773",
      NIMETUS: "Änniksaare küla",
      NIMETUS_LIIGIGA: "Änniksaare küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY00009773",
    },
    {
      TASE: "3",
      KOOD: "9780",
      NIMETUS: "Äriküla",
      NIMETUS_LIIGIGA: "Äriküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00009780",
    },
    {
      TASE: "3",
      KOOD: "9783",
      NIMETUS: "Ärina küla",
      NIMETUS_LIIGIGA: "Ärina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY00009783",
    },
    {
      TASE: "3",
      KOOD: "9787",
      NIMETUS: "Äteniidi küla",
      NIMETUS_LIIGIGA: "Äteniidi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00009787",
    },
    {
      TASE: "3",
      KOOD: "9790",
      NIMETUS: "Ätte küla",
      NIMETUS_LIIGIGA: "Ätte küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY00009790",
    },
    {
      TASE: "3",
      KOOD: "9791",
      NIMETUS: "Õhu küla",
      NIMETUS_LIIGIGA: "Õhu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY00009791",
    },
    {
      TASE: "3",
      KOOD: "9794",
      NIMETUS: "Ääsmäe küla",
      NIMETUS_LIIGIGA: "Ääsmäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00009794",
    },
    {
      TASE: "3",
      KOOD: "9799",
      NIMETUS: "Õeste küla",
      NIMETUS_LIIGIGA: "Õeste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009799",
    },
    {
      TASE: "3",
      KOOD: "9800",
      NIMETUS: "Õha küla",
      NIMETUS_LIIGIGA: "Õha küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009800",
    },
    {
      TASE: "3",
      KOOD: "9803",
      NIMETUS: "Österby küla",
      NIMETUS_LIIGIGA: "Österby küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00009803",
    },
    {
      TASE: "3",
      KOOD: "9804",
      NIMETUS: "Ööriku küla",
      NIMETUS_LIIGIGA: "Ööriku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009804",
    },
    {
      TASE: "3",
      KOOD: "9807",
      NIMETUS: "Öötla küla",
      NIMETUS_LIIGIGA: "Öötla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00009807",
    },
    {
      TASE: "3",
      KOOD: "9812",
      NIMETUS: "Üdruma küla",
      NIMETUS_LIIGIGA: "Üdruma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY00009812",
    },
    {
      TASE: "3",
      KOOD: "9816",
      NIMETUS: "Ühtri küla",
      NIMETUS_LIIGIGA: "Ühtri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00009816",
    },
    {
      TASE: "3",
      KOOD: "9820",
      NIMETUS: "Üksnurme küla",
      NIMETUS_LIIGIGA: "Üksnurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY00009820",
    },
    {
      TASE: "3",
      KOOD: "9824",
      NIMETUS: "Ülde küla",
      NIMETUS_LIIGIGA: "Ülde küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY00009824",
    },
    {
      TASE: "3",
      KOOD: "9825",
      NIMETUS: "Ülejõe küla",
      NIMETUS_LIIGIGA: "Ülejõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY00009825",
    },
    {
      TASE: "3",
      KOOD: "9826",
      NIMETUS: "Ülendi küla",
      NIMETUS_LIIGIGA: "Ülendi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY00009826",
    },
    {
      TASE: "3",
      KOOD: "9827",
      NIMETUS: "Ülejõe küla",
      NIMETUS_LIIGIGA: "Ülejõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "141",
      ADS_OID: "AY00009827",
    },
    {
      TASE: "3",
      KOOD: "9829",
      NIMETUS: "Ülejõe küla",
      NIMETUS_LIIGIGA: "Ülejõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY00009829",
    },
    {
      TASE: "3",
      KOOD: "9830",
      NIMETUS: "Ülemõisa küla",
      NIMETUS_LIIGIGA: "Ülemõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY00009830",
    },
    {
      TASE: "3",
      KOOD: "9831",
      NIMETUS: "Ülejõe küla",
      NIMETUS_LIIGIGA: "Ülejõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY00009831",
    },
    {
      TASE: "3",
      KOOD: "9832",
      NIMETUS: "Ülejõe küla",
      NIMETUS_LIIGIGA: "Ülejõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY00009832",
    },
    {
      TASE: "3",
      KOOD: "9833",
      NIMETUS: "Ülensi küla",
      NIMETUS_LIIGIGA: "Ülensi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY00009833",
    },
    {
      TASE: "3",
      KOOD: "9835",
      NIMETUS: "Ülenurme alevik",
      NIMETUS_LIIGIGA: "Ülenurme alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "283",
      ADS_OID: "AY00009835",
    },
    {
      TASE: "3",
      KOOD: "9838",
      NIMETUS: "Ülgase küla",
      NIMETUS_LIIGIGA: "Ülgase küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY00009838",
    },
    {
      TASE: "3",
      KOOD: "9842",
      NIMETUS: "Ünnaste küla",
      NIMETUS_LIIGIGA: "Ünnaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "638",
      ADS_OID: "AY00009842",
    },
    {
      TASE: "3",
      KOOD: "9846",
      NIMETUS: "Ürjaste küla",
      NIMETUS_LIIGIGA: "Ürjaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY00009846",
    },
    {
      TASE: "3",
      KOOD: "9849",
      NIMETUS: "Üru küla",
      NIMETUS_LIIGIGA: "Üru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009849",
    },
    {
      TASE: "3",
      KOOD: "9853",
      NIMETUS: "Üsse küla",
      NIMETUS_LIIGIGA: "Üsse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY00009853",
    },
    {
      TASE: "3",
      KOOD: "9854",
      NIMETUS: "Üüvere küla",
      NIMETUS_LIIGIGA: "Üüvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009854",
    },
    {
      TASE: "3",
      KOOD: "9855",
      NIMETUS: "Üüdibe küla",
      NIMETUS_LIIGIGA: "Üüdibe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY00009855",
    },
    {
      TASE: "3",
      KOOD: "0120",
      NIMETUS: "Ahtme linnaosa",
      NIMETUS_LIIGIGA: "Ahtme linnaosa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "321",
      ADS_OID: "LO00000120",
    },
    {
      TASE: "3",
      KOOD: "0176",
      NIMETUS: "Haabersti linnaosa",
      NIMETUS_LIIGIGA: "Haabersti linnaosa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "784",
      ADS_OID: "LO00000176",
    },
    {
      TASE: "3",
      KOOD: "0265",
      NIMETUS: "Järve linnaosa",
      NIMETUS_LIIGIGA: "Järve linnaosa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "321",
      ADS_OID: "LO00000265",
    },
    {
      TASE: "3",
      KOOD: "EE3_52780",
      NIMETUS: "Kadriorg",
      NIMETUS_LIIGIGA: "Kadriorg",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "784",
      ADS_OID: "EE3_52780",
    },
    {
      TASE: "3",
      KOOD: "0298",
      NIMETUS: "Kesklinna linnaosa",
      NIMETUS_LIIGIGA: "Kesklinna linnaosa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "784",
      ADS_OID: "LO00000298",
    },
    {
      TASE: "3",
      KOOD: "0339",
      NIMETUS: "Kristiine linnaosa",
      NIMETUS_LIIGIGA: "Kristiine linnaosa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "784",
      ADS_OID: "LO00000339",
    },
    {
      TASE: "3",
      KOOD: "0340",
      NIMETUS: "Kukruse linnaosa",
      NIMETUS_LIIGIGA: "Kukruse linnaosa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "321",
      ADS_OID: "LO00000340",
    },
    {
      TASE: "3",
      KOOD: "0387",
      NIMETUS: "Lasnamäe linnaosa",
      NIMETUS_LIIGIGA: "Lasnamäe linnaosa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "784",
      ADS_OID: "LO00000387",
    },
    {
      TASE: "3",
      KOOD: "0482",
      NIMETUS: "Mustamäe linnaosa",
      NIMETUS_LIIGIGA: "Mustamäe linnaosa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "784",
      ADS_OID: "LO00000482",
    },
    {
      TASE: "3",
      KOOD: "0524",
      NIMETUS: "Nõmme linnaosa",
      NIMETUS_LIIGIGA: "Nõmme linnaosa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "784",
      ADS_OID: "LO00000524",
    },
    {
      TASE: "3",
      KOOD: "0553",
      NIMETUS: "Oru linnaosa",
      NIMETUS_LIIGIGA: "Oru linnaosa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "321",
      ADS_OID: "LO00000553",
    },
    {
      TASE: "3",
      KOOD: "0596",
      NIMETUS: "Pirita linnaosa",
      NIMETUS_LIIGIGA: "Pirita linnaosa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "784",
      ADS_OID: "LO00000596",
    },
    {
      TASE: "3",
      KOOD: "0614",
      NIMETUS: "Põhja-Tallinna linnaosa",
      NIMETUS_LIIGIGA: "Põhja-Tallinna linnaosa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "784",
      ADS_OID: "LO00000614",
    },
    {
      TASE: "3",
      KOOD: "EE3_52781",
      NIMETUS: "Vanalinn",
      NIMETUS_LIIGIGA: "Vanalinn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "784",
      ADS_OID: "EE3_52781",
    },
    {
      TASE: "3",
      KOOD: "0747",
      NIMETUS: "Sompa linnaosa",
      NIMETUS_LIIGIGA: "Sompa linnaosa",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "321",
      ADS_OID: "LO00000747",
    },
    {
      TASE: "3",
      KOOD: "6415",
      NIMETUS: "Pukamäe küla",
      NIMETUS_LIIGIGA: "Pukamäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00611518",
    },
    {
      TASE: "3",
      KOOD: "4453",
      NIMETUS: "Lohu küla",
      NIMETUS_LIIGIGA: "Lohu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY00611519",
    },
    {
      TASE: "3",
      KOOD: "8824",
      NIMETUS: "Vaela küla",
      NIMETUS_LIIGIGA: "Vaela küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY01640108",
    },
    {
      TASE: "3",
      KOOD: "7894",
      NIMETUS: "Sõmeru küla",
      NIMETUS_LIIGIGA: "Sõmeru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY01640109",
    },
    {
      TASE: "3",
      KOOD: "3039",
      NIMETUS: "Kiili alev",
      NIMETUS_LIIGIGA: "Kiili alev",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY01640110",
    },
    {
      TASE: "3",
      KOOD: "5125",
      NIMETUS: "Mõisaküla",
      NIMETUS_LIIGIGA: "Mõisaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY01640111",
    },
    {
      TASE: "3",
      KOOD: "5824",
      NIMETUS: "Paekna küla",
      NIMETUS_LIIGIGA: "Paekna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY01640112",
    },
    {
      TASE: "3",
      KOOD: "7880",
      NIMETUS: "Sõgula küla",
      NIMETUS_LIIGIGA: "Sõgula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY01640113",
    },
    {
      TASE: "3",
      KOOD: "4633",
      NIMETUS: "Lähtse küla",
      NIMETUS_LIIGIGA: "Lähtse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY01640116",
    },
    {
      TASE: "3",
      KOOD: "6198",
      NIMETUS: "Piissoo küla",
      NIMETUS_LIIGIGA: "Piissoo küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY01640118",
    },
    {
      TASE: "3",
      KOOD: "4902",
      NIMETUS: "Metsanurga küla",
      NIMETUS_LIIGIGA: "Metsanurga küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY01640119",
    },
    {
      TASE: "3",
      KOOD: "5329",
      NIMETUS: "Nabala küla",
      NIMETUS_LIIGIGA: "Nabala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY01640121",
    },
    {
      TASE: "3",
      KOOD: "3514",
      NIMETUS: "Kudani küla / Gutanäs",
      NIMETUS_LIIGIGA: "Kudani küla / Gutanäs",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY01875378",
    },
    {
      TASE: "3",
      KOOD: "6029",
      NIMETUS: "Paslepa küla / Pasklep",
      NIMETUS_LIIGIGA: "Paslepa küla / Pasklep",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY01875379",
    },
    {
      TASE: "3",
      KOOD: "8187",
      NIMETUS: "Telise küla / Tällnäs",
      NIMETUS_LIIGIGA: "Telise küla / Tällnäs",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY01875380",
    },
    {
      TASE: "3",
      KOOD: "1772",
      NIMETUS: "Harjuküla",
      NIMETUS_LIIGIGA: "Harjuküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY01947653",
    },
    {
      TASE: "3",
      KOOD: "4324",
      NIMETUS: "Liguri küla",
      NIMETUS_LIIGIGA: "Liguri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY01947654",
    },
    {
      TASE: "3",
      KOOD: "7498",
      NIMETUS: "Saviranna küla",
      NIMETUS_LIIGIGA: "Saviranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY01993521",
    },
    {
      TASE: "3",
      KOOD: "2601",
      NIMETUS: "Kallavere küla",
      NIMETUS_LIIGIGA: "Kallavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "245",
      ADS_OID: "AY01993522",
    },
    {
      TASE: "3",
      KOOD: "1805",
      NIMETUS: "Helda küla",
      NIMETUS_LIIGIGA: "Helda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY02104902",
    },
    {
      TASE: "3",
      KOOD: "1931",
      NIMETUS: "Hõreda küla",
      NIMETUS_LIIGIGA: "Hõreda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY02104903",
    },
    {
      TASE: "3",
      KOOD: "1944",
      NIMETUS: "Härgla küla",
      NIMETUS_LIIGIGA: "Härgla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY02104978",
    },
    {
      TASE: "3",
      KOOD: "2569",
      NIMETUS: "Kalda küla",
      NIMETUS_LIIGIGA: "Kalda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY02104979",
    },
    {
      TASE: "3",
      KOOD: "5937",
      NIMETUS: "Pallika küla",
      NIMETUS_LIIGIGA: "Pallika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY02105409",
    },
    {
      TASE: "3",
      KOOD: "4221",
      NIMETUS: "Leibre küla",
      NIMETUS_LIIGIGA: "Leibre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY02105410",
    },
    {
      TASE: "3",
      KOOD: "9086",
      NIMETUS: "Varinurme küla",
      NIMETUS_LIIGIGA: "Varinurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY02105571",
    },
    {
      TASE: "3",
      KOOD: "9005",
      NIMETUS: "Vana-Sonda küla",
      NIMETUS_LIIGIGA: "Vana-Sonda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY02105572",
    },
    {
      TASE: "3",
      KOOD: "7319",
      NIMETUS: "Sadala küla",
      NIMETUS_LIIGIGA: "Sadala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY02105066",
    },
    {
      TASE: "3",
      KOOD: "4602",
      NIMETUS: "Lõiuse küla",
      NIMETUS_LIIGIGA: "Lõiuse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY02105067",
    },
    {
      TASE: "3",
      KOOD: "2330",
      NIMETUS: "Järlepa küla",
      NIMETUS_LIIGIGA: "Järlepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY02105068",
    },
    {
      TASE: "3",
      KOOD: "1344",
      NIMETUS: "Are alevik",
      NIMETUS_LIIGIGA: "Are alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY02255421",
    },
    {
      TASE: "3",
      KOOD: "3648",
      NIMETUS: "Kurena küla",
      NIMETUS_LIIGIGA: "Kurena küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY02255422",
    },
    {
      TASE: "3",
      KOOD: "7591",
      NIMETUS: "Silla küla",
      NIMETUS_LIIGIGA: "Silla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY02255271",
    },
    {
      TASE: "3",
      KOOD: "5864",
      NIMETUS: "Paikuse alev",
      NIMETUS_LIIGIGA: "Paikuse alev",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY02255272",
    },
    {
      TASE: "3",
      KOOD: "9351",
      NIMETUS: "Villavere küla",
      NIMETUS_LIIGIGA: "Villavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY03141853",
    },
    {
      TASE: "3",
      KOOD: "3709",
      NIMETUS: "Kutsala küla",
      NIMETUS_LIIGIGA: "Kutsala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY03141854",
    },
    {
      TASE: "3",
      KOOD: "9341",
      NIMETUS: "Vilivere küla",
      NIMETUS_LIIGIGA: "Vilivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY03141970",
    },
    {
      TASE: "3",
      KOOD: "8976",
      NIMETUS: "Vana-Aespa küla",
      NIMETUS_LIIGIGA: "Vana-Aespa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY03141971",
    },
    {
      TASE: "3",
      KOOD: "1094",
      NIMETUS: "Aespa alevik",
      NIMETUS_LIIGIGA: "Aespa alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "317",
      ADS_OID: "AY03141972",
    },
    {
      TASE: "3",
      KOOD: "9619",
      NIMETUS: "Väikeheinamaa küla / Lillängin",
      NIMETUS_LIIGIGA: "Väikeheinamaa küla / Lillängin",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY02260901",
    },
    {
      TASE: "3",
      KOOD: "8039",
      NIMETUS: "Tagaküla / Bakbyn",
      NIMETUS_LIIGIGA: "Tagaküla / Bakbyn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY02260902",
    },
    {
      TASE: "3",
      KOOD: "4618",
      NIMETUS: "Lõunaküla / Storbyn",
      NIMETUS_LIIGIGA: "Lõunaküla / Storbyn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "890",
      ADS_OID: "AY02260903",
    },
    {
      TASE: "3",
      KOOD: "2353",
      NIMETUS: "Järveküla",
      NIMETUS_LIIGIGA: "Järveküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY03264580",
    },
    {
      TASE: "3",
      KOOD: "8774",
      NIMETUS: "Uuesalu küla",
      NIMETUS_LIIGIGA: "Uuesalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "653",
      ADS_OID: "AY03264581",
    },
    {
      TASE: "3",
      KOOD: "9033",
      NIMETUS: "Vanamõisa küla",
      NIMETUS_LIIGIGA: "Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY03198474",
    },
    {
      TASE: "3",
      KOOD: "1216",
      NIMETUS: "Alliku küla",
      NIMETUS_LIIGIGA: "Alliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY03198475",
    },
    {
      TASE: "3",
      KOOD: "3285",
      NIMETUS: "Koidu küla",
      NIMETUS_LIIGIGA: "Koidu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY03198476",
    },
    {
      TASE: "3",
      KOOD: "7412",
      NIMETUS: "Sandimetsa küla",
      NIMETUS_LIIGIGA: "Sandimetsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY03235917",
    },
    {
      TASE: "3",
      KOOD: "9777",
      NIMETUS: "Äntu küla",
      NIMETUS_LIIGIGA: "Äntu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY03235918",
    },
    {
      TASE: "3",
      KOOD: "1521",
      NIMETUS: "Ebavere küla",
      NIMETUS_LIIGIGA: "Ebavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "928",
      ADS_OID: "AY03235919",
    },
    {
      TASE: "3",
      KOOD: "6536",
      NIMETUS: "Põlva linn",
      NIMETUS_LIIGIGA: "Põlva linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY03290408",
    },
    {
      TASE: "3",
      KOOD: "3895",
      NIMETUS: "Kärdla linn",
      NIMETUS_LIIGIGA: "Kärdla linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY03290550",
    },
    {
      TASE: "3",
      KOOD: "2255",
      NIMETUS: "Jõeääre küla",
      NIMETUS_LIIGIGA: "Jõeääre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309289",
    },
    {
      TASE: "3",
      KOOD: "8666",
      NIMETUS: "Uluste küla",
      NIMETUS_LIIGIGA: "Uluste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309290",
    },
    {
      TASE: "3",
      KOOD: "5174",
      NIMETUS: "Mõisimaa küla",
      NIMETUS_LIIGIGA: "Mõisimaa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309291",
    },
    {
      TASE: "3",
      KOOD: "7087",
      NIMETUS: "Rootsi küla",
      NIMETUS_LIIGIGA: "Rootsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309293",
    },
    {
      TASE: "3",
      KOOD: "9035",
      NIMETUS: "Vanamõisa küla",
      NIMETUS_LIIGIGA: "Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309294",
    },
    {
      TASE: "3",
      KOOD: "7519",
      NIMETUS: "Seli küla",
      NIMETUS_LIIGIGA: "Seli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309295",
    },
    {
      TASE: "3",
      KOOD: "6827",
      NIMETUS: "Rannu küla",
      NIMETUS_LIIGIGA: "Rannu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309296",
    },
    {
      TASE: "3",
      KOOD: "9528",
      NIMETUS: "Võigaste küla",
      NIMETUS_LIIGIGA: "Võigaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309297",
    },
    {
      TASE: "3",
      KOOD: "4429",
      NIMETUS: "Liustemäe küla",
      NIMETUS_LIIGIGA: "Liustemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309298",
    },
    {
      TASE: "3",
      KOOD: "2912",
      NIMETUS: "Keemu küla",
      NIMETUS_LIIGIGA: "Keemu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309299",
    },
    {
      TASE: "3",
      KOOD: "4149",
      NIMETUS: "Laulepa küla",
      NIMETUS_LIIGIGA: "Laulepa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309300",
    },
    {
      TASE: "3",
      KOOD: "7749",
      NIMETUS: "Soovälja küla",
      NIMETUS_LIIGIGA: "Soovälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309301",
    },
    {
      TASE: "3",
      KOOD: "6055",
      NIMETUS: "Peanse küla",
      NIMETUS_LIIGIGA: "Peanse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309302",
    },
    {
      TASE: "3",
      KOOD: "5464",
      NIMETUS: "Nurme küla",
      NIMETUS_LIIGIGA: "Nurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309303",
    },
    {
      TASE: "3",
      KOOD: "7117",
      NIMETUS: "Rumba küla",
      NIMETUS_LIIGIGA: "Rumba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309304",
    },
    {
      TASE: "3",
      KOOD: "9499",
      NIMETUS: "Võhma küla",
      NIMETUS_LIIGIGA: "Võhma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309305",
    },
    {
      TASE: "3",
      KOOD: "7511",
      NIMETUS: "Seira küla",
      NIMETUS_LIIGIGA: "Seira küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309306",
    },
    {
      TASE: "3",
      KOOD: "5845",
      NIMETUS: "Pagasi küla",
      NIMETUS_LIIGIGA: "Pagasi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309307",
    },
    {
      TASE: "3",
      KOOD: "3113",
      NIMETUS: "Kirbla küla",
      NIMETUS_LIIGIGA: "Kirbla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309308",
    },
    {
      TASE: "3",
      KOOD: "4929",
      NIMETUS: "Metsküla",
      NIMETUS_LIIGIGA: "Metsküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309309",
    },
    {
      TASE: "3",
      KOOD: "4820",
      NIMETUS: "Matsalu küla",
      NIMETUS_LIIGIGA: "Matsalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309310",
    },
    {
      TASE: "3",
      KOOD: "4847",
      NIMETUS: "Meelva küla",
      NIMETUS_LIIGIGA: "Meelva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309311",
    },
    {
      TASE: "3",
      KOOD: "6013",
      NIMETUS: "Parivere küla",
      NIMETUS_LIIGIGA: "Parivere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309312",
    },
    {
      TASE: "3",
      KOOD: "6131",
      NIMETUS: "Petaaluse küla",
      NIMETUS_LIIGIGA: "Petaaluse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309313",
    },
    {
      TASE: "3",
      KOOD: "8446",
      NIMETUS: "Tuudi küla",
      NIMETUS_LIIGIGA: "Tuudi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03309314",
    },
    {
      TASE: "3",
      KOOD: "6671",
      NIMETUS: "Püssi linn",
      NIMETUS_LIIGIGA: "Püssi linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY03290600",
    },
    {
      TASE: "3",
      KOOD: "4626",
      NIMETUS: "Lähevere küla",
      NIMETUS_LIIGIGA: "Lähevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY03372414",
    },
    {
      TASE: "3",
      KOOD: "6627",
      NIMETUS: "Pätsavere küla",
      NIMETUS_LIIGIGA: "Pätsavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY03372415",
    },
    {
      TASE: "3",
      KOOD: "4679",
      NIMETUS: "Lümanda küla",
      NIMETUS_LIIGIGA: "Lümanda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03396144",
    },
    {
      TASE: "3",
      KOOD: "8076",
      NIMETUS: "Tahula küla",
      NIMETUS_LIIGIGA: "Tahula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03396145",
    },
    {
      TASE: "3",
      KOOD: "4874",
      NIMETUS: "Mereküla",
      NIMETUS_LIIGIGA: "Mereküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY03465278",
    },
    {
      TASE: "3",
      KOOD: "6925",
      NIMETUS: "Reiu küla",
      NIMETUS_LIIGIGA: "Reiu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "214",
      ADS_OID: "AY03465279",
    },
    {
      TASE: "3",
      KOOD: "1825",
      NIMETUS: "Hermani küla",
      NIMETUS_LIIGIGA: "Hermani küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY03465271",
    },
    {
      TASE: "3",
      KOOD: "3937",
      NIMETUS: "Käsukonna küla",
      NIMETUS_LIIGIGA: "Käsukonna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "255",
      ADS_OID: "AY03465272",
    },
    {
      TASE: "3",
      KOOD: "5567",
      NIMETUS: "Nüpli küla",
      NIMETUS_LIIGIGA: "Nüpli küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY03551769",
    },
    {
      TASE: "3",
      KOOD: "5752",
      NIMETUS: "Otepää küla",
      NIMETUS_LIIGIGA: "Otepää küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY03551770",
    },
    {
      TASE: "3",
      KOOD: "5755",
      NIMETUS: "Otepää linn",
      NIMETUS_LIIGIGA: "Otepää linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY03551771",
    },
    {
      TASE: "3",
      KOOD: "6659",
      NIMETUS: "Pühajärve küla",
      NIMETUS_LIIGIGA: "Pühajärve küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "557",
      ADS_OID: "AY03551772",
    },
    {
      TASE: "3",
      KOOD: "5084",
      NIMETUS: "Mustla alevik",
      NIMETUS_LIIGIGA: "Mustla alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY03560826",
    },
    {
      TASE: "3",
      KOOD: "8247",
      NIMETUS: "Tinnikuru küla",
      NIMETUS_LIIGIGA: "Tinnikuru küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "899",
      ADS_OID: "AY03560827",
    },
    {
      TASE: "3",
      KOOD: "5356",
      NIMETUS: "Narva-Jõesuu linn",
      NIMETUS_LIIGIGA: "Narva-Jõesuu linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY03657754",
    },
    {
      TASE: "3",
      KOOD: "1245",
      NIMETUS: "Ama küla",
      NIMETUS_LIIGIGA: "Ama küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY03657738",
    },
    {
      TASE: "3",
      KOOD: "2380",
      NIMETUS: "Jürimõisa küla",
      NIMETUS_LIIGIGA: "Jürimõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY03657739",
    },
    {
      TASE: "3",
      KOOD: "9311",
      NIMETUS: "Viitna küla",
      NIMETUS_LIIGIGA: "Viitna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY03657740",
    },
    {
      TASE: "3",
      KOOD: "7631",
      NIMETUS: "Sirgala küla",
      NIMETUS_LIIGIGA: "Sirgala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY03657741",
    },
    {
      TASE: "3",
      KOOD: "9314",
      NIMETUS: "Viivikonna küla",
      NIMETUS_LIIGIGA: "Viivikonna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "514",
      ADS_OID: "AY03657742",
    },
    {
      TASE: "3",
      KOOD: "3612",
      NIMETUS: "Kunda linn",
      NIMETUS_LIIGIGA: "Kunda linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "903",
      ADS_OID: "AY03658031",
    },
    {
      TASE: "3",
      KOOD: "5925",
      NIMETUS: "Paldiski linn",
      NIMETUS_LIIGIGA: "Paldiski linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY03657497",
    },
    {
      TASE: "3",
      KOOD: "3805",
      NIMETUS: "Kõrkküla",
      NIMETUS_LIIGIGA: "Kõrkküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656773",
    },
    {
      TASE: "3",
      KOOD: "5257",
      NIMETUS: "Mändjala küla",
      NIMETUS_LIIGIGA: "Mändjala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656774",
    },
    {
      TASE: "3",
      KOOD: "2864",
      NIMETUS: "Kaugatoma küla",
      NIMETUS_LIIGIGA: "Kaugatoma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656775",
    },
    {
      TASE: "3",
      KOOD: "4713",
      NIMETUS: "Maasi küla",
      NIMETUS_LIIGIGA: "Maasi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656776",
    },
    {
      TASE: "3",
      KOOD: "7294",
      NIMETUS: "Saareküla",
      NIMETUS_LIIGIGA: "Saareküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656777",
    },
    {
      TASE: "3",
      KOOD: "7372",
      NIMETUS: "Salavere küla",
      NIMETUS_LIIGIGA: "Salavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656778",
    },
    {
      TASE: "3",
      KOOD: "7837",
      NIMETUS: "Suur-Randvere küla",
      NIMETUS_LIIGIGA: "Suur-Randvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656779",
    },
    {
      TASE: "3",
      KOOD: "2412",
      NIMETUS: "Kaarma-Jõe küla",
      NIMETUS_LIIGIGA: "Kaarma-Jõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656780",
    },
    {
      TASE: "3",
      KOOD: "2413",
      NIMETUS: "Kaarma-Kungla küla",
      NIMETUS_LIIGIGA: "Kaarma-Kungla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656781",
    },
    {
      TASE: "3",
      KOOD: "3013",
      NIMETUS: "Kihelkonna-Liiva küla",
      NIMETUS_LIIGIGA: "Kihelkonna-Liiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656782",
    },
    {
      TASE: "3",
      KOOD: "3816",
      NIMETUS: "Kõruse-Metsaküla",
      NIMETUS_LIIGIGA: "Kõruse-Metsaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656783",
    },
    {
      TASE: "3",
      KOOD: "4343",
      NIMETUS: "Liivaranna küla",
      NIMETUS_LIIGIGA: "Liivaranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656784",
    },
    {
      TASE: "3",
      KOOD: "4346",
      NIMETUS: "Liivanõmme küla",
      NIMETUS_LIIGIGA: "Liivanõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656785",
    },
    {
      TASE: "3",
      KOOD: "4561",
      NIMETUS: "Lussu küla",
      NIMETUS_LIIGIGA: "Lussu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656786",
    },
    {
      TASE: "3",
      KOOD: "6138",
      NIMETUS: "Pidula-Kuusiku küla",
      NIMETUS_LIIGIGA: "Pidula-Kuusiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656787",
    },
    {
      TASE: "3",
      KOOD: "7906",
      NIMETUS: "Sõrve-Hindu küla",
      NIMETUS_LIIGIGA: "Sõrve-Hindu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656788",
    },
    {
      TASE: "3",
      KOOD: "8323",
      NIMETUS: "Torgu-Mõisaküla",
      NIMETUS_LIIGIGA: "Torgu-Mõisaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656789",
    },
    {
      TASE: "3",
      KOOD: "8871",
      NIMETUS: "Vaigu-Rannaküla",
      NIMETUS_LIIGIGA: "Vaigu-Rannaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656790",
    },
    {
      TASE: "3",
      KOOD: "9173",
      NIMETUS: "Veeremäe küla",
      NIMETUS_LIIGIGA: "Veeremäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656791",
    },
    {
      TASE: "3",
      KOOD: "9276",
      NIMETUS: "Viidu-Mäebe küla",
      NIMETUS_LIIGIGA: "Viidu-Mäebe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656792",
    },
    {
      TASE: "3",
      KOOD: "9627",
      NIMETUS: "Väike-Ula küla",
      NIMETUS_LIIGIGA: "Väike-Ula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656793",
    },
    {
      TASE: "3",
      KOOD: "3655",
      NIMETUS: "Kuressaare linn",
      NIMETUS_LIIGIGA: "Kuressaare linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656822",
    },
    {
      TASE: "3",
      KOOD: "7453",
      NIMETUS: "Saue linn",
      NIMETUS_LIIGIGA: "Saue linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY03657495",
    },
    {
      TASE: "3",
      KOOD: "1217",
      NIMETUS: "Allikukivi küla",
      NIMETUS_LIIGIGA: "Allikukivi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY03620179",
    },
    {
      TASE: "3",
      KOOD: "3928",
      NIMETUS: "Kärsu küla",
      NIMETUS_LIIGIGA: "Kärsu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY03620180",
    },
    {
      TASE: "3",
      KOOD: "8213",
      NIMETUS: "Tihemetsa alevik",
      NIMETUS_LIIGIGA: "Tihemetsa alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY03620181",
    },
    {
      TASE: "3",
      KOOD: "8485",
      NIMETUS: "Tõlla küla",
      NIMETUS_LIIGIGA: "Tõlla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY03620182",
    },
    {
      TASE: "3",
      KOOD: "9652",
      NIMETUS: "Väljaküla",
      NIMETUS_LIIGIGA: "Väljaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY03620183",
    },
    {
      TASE: "3",
      KOOD: "1025",
      NIMETUS: "Aarla küla",
      NIMETUS_LIIGIGA: "Aarla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY03620173",
    },
    {
      TASE: "3",
      KOOD: "9395",
      NIMETUS: "Viru-Kabala küla",
      NIMETUS_LIIGIGA: "Viru-Kabala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "901",
      ADS_OID: "AY03620174",
    },
    {
      TASE: "3",
      KOOD: "7747",
      NIMETUS: "Sootaguse küla",
      NIMETUS_LIIGIGA: "Sootaguse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY03620176",
    },
    {
      TASE: "3",
      KOOD: "8688",
      NIMETUS: "Undla küla",
      NIMETUS_LIIGIGA: "Undla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY03620177",
    },
    {
      TASE: "3",
      KOOD: "1207",
      NIMETUS: "Allikalahe küla",
      NIMETUS_LIIGIGA: "Allikalahe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656796",
    },
    {
      TASE: "3",
      KOOD: "2397",
      NIMETUS: "Kaali-Liiva küla",
      NIMETUS_LIIGIGA: "Kaali-Liiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656797",
    },
    {
      TASE: "3",
      KOOD: "3118",
      NIMETUS: "Kirderanna küla",
      NIMETUS_LIIGIGA: "Kirderanna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656798",
    },
    {
      TASE: "3",
      KOOD: "3290",
      NIMETUS: "Koigi-Väljaküla",
      NIMETUS_LIIGIGA: "Koigi-Väljaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656799",
    },
    {
      TASE: "3",
      KOOD: "4041",
      NIMETUS: "Laevaranna küla",
      NIMETUS_LIIGIGA: "Laevaranna küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656800",
    },
    {
      TASE: "3",
      KOOD: "4137",
      NIMETUS: "Laugu-Liiva küla",
      NIMETUS_LIIGIGA: "Laugu-Liiva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656801",
    },
    {
      TASE: "3",
      KOOD: "5505",
      NIMETUS: "Nõmjala küla",
      NIMETUS_LIIGIGA: "Nõmjala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656802",
    },
    {
      TASE: "3",
      KOOD: "6636",
      NIMETUS: "Pöide-Keskvere küla",
      NIMETUS_LIIGIGA: "Pöide-Keskvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656803",
    },
    {
      TASE: "3",
      KOOD: "6654",
      NIMETUS: "Püha-Kõnnu küla",
      NIMETUS_LIIGIGA: "Püha-Kõnnu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656804",
    },
    {
      TASE: "3",
      KOOD: "7470",
      NIMETUS: "Saue-Mustla küla",
      NIMETUS_LIIGIGA: "Saue-Mustla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656805",
    },
    {
      TASE: "3",
      KOOD: "8253",
      NIMETUS: "Tirbi küla",
      NIMETUS_LIIGIGA: "Tirbi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656806",
    },
    {
      TASE: "3",
      KOOD: "8950",
      NIMETUS: "Valjala-Ariste küla",
      NIMETUS_LIIGIGA: "Valjala-Ariste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656807",
    },
    {
      TASE: "3",
      KOOD: "8952",
      NIMETUS: "Valjala-Kogula küla",
      NIMETUS_LIIGIGA: "Valjala-Kogula küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656808",
    },
    {
      TASE: "3",
      KOOD: "8953",
      NIMETUS: "Valjala-Nurme küla",
      NIMETUS_LIIGIGA: "Valjala-Nurme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656809",
    },
    {
      TASE: "3",
      KOOD: "9630",
      NIMETUS: "Väike-Võhma küla",
      NIMETUS_LIIGIGA: "Väike-Võhma küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03656810",
    },
    {
      TASE: "3",
      KOOD: "5860",
      NIMETUS: "Paide linn",
      NIMETUS_LIIGIGA: "Paide linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "567",
      ADS_OID: "AY03657054",
    },
    {
      TASE: "3",
      KOOD: "1692",
      NIMETUS: "Haapsalu linn",
      NIMETUS_LIIGIGA: "Haapsalu linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "184",
      ADS_OID: "AY03657057",
    },
    {
      TASE: "3",
      KOOD: "3194",
      NIMETUS: "Kiviõli linn",
      NIMETUS_LIIGIGA: "Kiviõli linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "442",
      ADS_OID: "AY03659389",
    },
    {
      TASE: "3",
      KOOD: "1698",
      NIMETUS: "Haava-Tsäpsi küla",
      NIMETUS_LIIGIGA: "Haava-Tsäpsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY03659387",
    },
    {
      TASE: "3",
      KOOD: "5145",
      NIMETUS: "Mõisaküla linn",
      NIMETUS_LIIGIGA: "Mõisaküla linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "480",
      ADS_OID: "AY03659393",
    },
    {
      TASE: "3",
      KOOD: "9500",
      NIMETUS: "Võhma linn",
      NIMETUS_LIIGIGA: "Võhma linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY03659395",
    },
    {
      TASE: "3",
      KOOD: "2459",
      NIMETUS: "Kabina küla",
      NIMETUS_LIIGIGA: "Kabina küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY03659755",
    },
    {
      TASE: "3",
      KOOD: "4583",
      NIMETUS: "Luunja alevik",
      NIMETUS_LIIGIGA: "Luunja alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "432",
      ADS_OID: "AY03659756",
    },
    {
      TASE: "3",
      KOOD: "5097",
      NIMETUS: "Mustvee linn",
      NIMETUS_LIIGIGA: "Mustvee linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "486",
      ADS_OID: "AY03659959",
    },
    {
      TASE: "3",
      KOOD: "8918",
      NIMETUS: "Valga linn",
      NIMETUS_LIIGIGA: "Valga linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "855",
      ADS_OID: "AY03658660",
    },
    {
      TASE: "3",
      KOOD: "8529",
      NIMETUS: "Tõrva linn",
      NIMETUS_LIIGIGA: "Tõrva linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "824",
      ADS_OID: "AY03658676",
    },
    {
      TASE: "3",
      KOOD: "6619",
      NIMETUS: "Pärnu linn",
      NIMETUS_LIIGIGA: "Pärnu linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "624",
      ADS_OID: "AY03658877",
    },
    {
      TASE: "3",
      KOOD: "8151",
      NIMETUS: "Tartu linn",
      NIMETUS_LIIGIGA: "Tartu linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "793",
      ADS_OID: "AY03658881",
    },
    {
      TASE: "3",
      KOOD: "4769",
      NIMETUS: "Mammaste küla",
      NIMETUS_LIIGIGA: "Mammaste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY03659298",
    },
    {
      TASE: "3",
      KOOD: "8028",
      NIMETUS: "Taevaskoja küla",
      NIMETUS_LIIGIGA: "Taevaskoja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY03659299",
    },
    {
      TASE: "3",
      KOOD: "1885",
      NIMETUS: "Holvandi küla",
      NIMETUS_LIIGIGA: "Holvandi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY03659294",
    },
    {
      TASE: "3",
      KOOD: "3050",
      NIMETUS: "Kiisa küla",
      NIMETUS_LIIGIGA: "Kiisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY03659295",
    },
    {
      TASE: "3",
      KOOD: "9010",
      NIMETUS: "Vanaküla",
      NIMETUS_LIIGIGA: "Vanaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "622",
      ADS_OID: "AY03659296",
    },
    {
      TASE: "3",
      KOOD: "2444",
      NIMETUS: "Kabala küla",
      NIMETUS_LIIGIGA: "Kabala küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY03659239",
    },
    {
      TASE: "3",
      KOOD: "6526",
      NIMETUS: "Põlliku küla",
      NIMETUS_LIIGIGA: "Põlliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY03659240",
    },
    {
      TASE: "3",
      KOOD: "8842",
      NIMETUS: "Vahastu küla",
      NIMETUS_LIIGIGA: "Vahastu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "668",
      ADS_OID: "AY03659241",
    },
    {
      TASE: "3",
      KOOD: "9256",
      NIMETUS: "Vigala-Vanamõisa küla",
      NIMETUS_LIIGIGA: "Vigala-Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY03659274",
    },
    {
      TASE: "3",
      KOOD: "9340",
      NIMETUS: "Valgu-Vanamõisa küla",
      NIMETUS_LIIGIGA: "Valgu-Vanamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY03659275",
    },
    {
      TASE: "3",
      KOOD: "1587",
      NIMETUS: "Emmaste-Kurisu küla",
      NIMETUS_LIIGIGA: "Emmaste-Kurisu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY03658450",
    },
    {
      TASE: "3",
      KOOD: "1588",
      NIMETUS: "Emmaste-Selja küla",
      NIMETUS_LIIGIGA: "Emmaste-Selja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY03658451",
    },
    {
      TASE: "3",
      KOOD: "3893",
      NIMETUS: "Kärdla-Nõmme küla",
      NIMETUS_LIIGIGA: "Kärdla-Nõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY03658452",
    },
    {
      TASE: "3",
      KOOD: "6660",
      NIMETUS: "Pühalepa-Harju küla",
      NIMETUS_LIIGIGA: "Pühalepa-Harju küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY03658453",
    },
    {
      TASE: "3",
      KOOD: "6909",
      NIMETUS: "Reigi-Nõmme küla",
      NIMETUS_LIIGIGA: "Reigi-Nõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "205",
      ADS_OID: "AY03658454",
    },
    {
      TASE: "3",
      KOOD: "7064",
      NIMETUS: "Rooglaiu küla",
      NIMETUS_LIIGIGA: "Rooglaiu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03658476",
    },
    {
      TASE: "3",
      KOOD: "7091",
      NIMETUS: "Rootsi-Aruküla",
      NIMETUS_LIIGIGA: "Rootsi-Aruküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03658477",
    },
    {
      TASE: "3",
      KOOD: "7373",
      NIMETUS: "Salavere küla",
      NIMETUS_LIIGIGA: "Salavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "430",
      ADS_OID: "AY03658478",
    },
    {
      TASE: "3",
      KOOD: "1586",
      NIMETUS: "Elva linn",
      NIMETUS_LIIGIGA: "Elva linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY03658705",
    },
    {
      TASE: "3",
      KOOD: "2596",
      NIMETUS: "Kallaste linn",
      NIMETUS_LIIGIGA: "Kallaste linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY03658151",
    },
    {
      TASE: "3",
      KOOD: "6537",
      NIMETUS: "Põltsamaa linn",
      NIMETUS_LIIGIGA: "Põltsamaa linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "618",
      ADS_OID: "AY03659980",
    },
    {
      TASE: "3",
      KOOD: "1958",
      NIMETUS: "Häärmäni küla",
      NIMETUS_LIIGIGA: "Häärmäni küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY03660033",
    },
    {
      TASE: "3",
      KOOD: "2516",
      NIMETUS: "Kahrila-Mustahamba küla",
      NIMETUS_LIIGIGA: "Kahrila-Mustahamba küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY03660034",
    },
    {
      TASE: "3",
      KOOD: "4076",
      NIMETUS: "Laitsna-Hurda küla",
      NIMETUS_LIIGIGA: "Laitsna-Hurda küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY03660035",
    },
    {
      TASE: "3",
      KOOD: "4957",
      NIMETUS: "Misso-Saika küla",
      NIMETUS_LIIGIGA: "Misso-Saika küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY03660036",
    },
    {
      TASE: "3",
      KOOD: "5199",
      NIMETUS: "Mäe-Lüütsepä küla",
      NIMETUS_LIIGIGA: "Mäe-Lüütsepä küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY03660037",
    },
    {
      TASE: "3",
      KOOD: "6490",
      NIMETUS: "Põdramõtsa küla",
      NIMETUS_LIIGIGA: "Põdramõtsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY03660038",
    },
    {
      TASE: "3",
      KOOD: "7027",
      NIMETUS: "Rogosi-Mikita küla",
      NIMETUS_LIIGIGA: "Rogosi-Mikita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY03660039",
    },
    {
      TASE: "3",
      KOOD: "7168",
      NIMETUS: "Rõuge-Matsi küla",
      NIMETUS_LIIGIGA: "Rõuge-Matsi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY03660040",
    },
    {
      TASE: "3",
      KOOD: "9445",
      NIMETUS: "Vodi küla",
      NIMETUS_LIIGIGA: "Vodi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "698",
      ADS_OID: "AY03660041",
    },
    {
      TASE: "3",
      KOOD: "2262",
      NIMETUS: "Jõgeva linn",
      NIMETUS_LIIGIGA: "Jõgeva linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "247",
      ADS_OID: "AY03659974",
    },
    {
      TASE: "3",
      KOOD: "4542",
      NIMETUS: "Luhametsa küla",
      NIMETUS_LIIGIGA: "Luhametsa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY03775506",
    },
    {
      TASE: "3",
      KOOD: "7074",
      NIMETUS: "Roosiku küla",
      NIMETUS_LIIGIGA: "Roosiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "142",
      ADS_OID: "AY03775507",
    },
    {
      TASE: "3",
      KOOD: "7607",
      NIMETUS: "Sindi linn",
      NIMETUS_LIIGIGA: "Sindi linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "809",
      ADS_OID: "AY03660898",
    },
    {
      TASE: "3",
      KOOD: "2047",
      NIMETUS: "Ilmandu küla",
      NIMETUS_LIIGIGA: "Ilmandu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY03684422",
    },
    {
      TASE: "3",
      KOOD: "4880",
      NIMETUS: "Meriküla",
      NIMETUS_LIIGIGA: "Meriküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY03684423",
    },
    {
      TASE: "3",
      KOOD: "5038",
      NIMETUS: "Muraste küla",
      NIMETUS_LIIGIGA: "Muraste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY03684424",
    },
    {
      TASE: "3",
      KOOD: "7870",
      NIMETUS: "Suurupi küla",
      NIMETUS_LIIGIGA: "Suurupi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "198",
      ADS_OID: "AY03684425",
    },
    {
      TASE: "3",
      KOOD: "3298",
      NIMETUS: "Koimla küla",
      NIMETUS_LIIGIGA: "Koimla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03684469",
    },
    {
      TASE: "3",
      KOOD: "8158",
      NIMETUS: "Taritu küla",
      NIMETUS_LIIGIGA: "Taritu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03684470",
    },
    {
      TASE: "3",
      KOOD: "7414",
      NIMETUS: "Sandla küla",
      NIMETUS_LIIGIGA: "Sandla küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03756052",
    },
    {
      TASE: "3",
      KOOD: "9662",
      NIMETUS: "Väljamõisa küla",
      NIMETUS_LIIGIGA: "Väljamõisa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "714",
      ADS_OID: "AY03756053",
    },
    {
      TASE: "3",
      KOOD: "3362",
      NIMETUS: "Kolu küla",
      NIMETUS_LIIGIGA: "Kolu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY03693073",
    },
    {
      TASE: "3",
      KOOD: "4224",
      NIMETUS: "Leikude küla",
      NIMETUS_LIIGIGA: "Leikude küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY03693074",
    },
    {
      TASE: "3",
      KOOD: "8860",
      NIMETUS: "Vaiatu küla",
      NIMETUS_LIIGIGA: "Vaiatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "272",
      ADS_OID: "AY03693075",
    },
    {
      TASE: "3",
      KOOD: "2977",
      NIMETUS: "Keskvere küla",
      NIMETUS_LIIGIGA: "Keskvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY03693076",
    },
    {
      TASE: "3",
      KOOD: "3665",
      NIMETUS: "Kurevere küla",
      NIMETUS_LIIGIGA: "Kurevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY03693077",
    },
    {
      TASE: "3",
      KOOD: "8106",
      NIMETUS: "Tammiku küla",
      NIMETUS_LIIGIGA: "Tammiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY03693078",
    },
    {
      TASE: "3",
      KOOD: "3551",
      NIMETUS: "Kuke küla",
      NIMETUS_LIIGIGA: "Kuke küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY03693068",
    },
    {
      TASE: "3",
      KOOD: "6904",
      NIMETUS: "Rehemäe küla",
      NIMETUS_LIIGIGA: "Rehemäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "441",
      ADS_OID: "AY03693069",
    },
    {
      TASE: "3",
      KOOD: "2931",
      NIMETUS: "Keila-Joa alevik",
      NIMETUS_LIIGIGA: "Keila-Joa alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY03751715",
    },
    {
      TASE: "3",
      KOOD: "4878",
      NIMETUS: "Merenuka küla",
      NIMETUS_LIIGIGA: "Merenuka küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "431",
      ADS_OID: "AY03751716",
    },
    {
      TASE: "3",
      KOOD: "2673",
      NIMETUS: "Kangru alevik",
      NIMETUS_LIIGIGA: "Kangru alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY03779931",
    },
    {
      TASE: "3",
      KOOD: "3653",
      NIMETUS: "Kurevere küla",
      NIMETUS_LIIGIGA: "Kurevere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY03779932",
    },
    {
      TASE: "3",
      KOOD: "4549",
      NIMETUS: "Luige alevik",
      NIMETUS_LIIGIGA: "Luige alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY03779933",
    },
    {
      TASE: "3",
      KOOD: "5260",
      NIMETUS: "Männiku küla",
      NIMETUS_LIIGIGA: "Männiku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY03779934",
    },
    {
      TASE: "3",
      KOOD: "7467",
      NIMETUS: "Saustinõmme küla",
      NIMETUS_LIIGIGA: "Saustinõmme küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY03779935",
    },
    {
      TASE: "3",
      KOOD: "7474",
      NIMETUS: "Sausti küla",
      NIMETUS_LIIGIGA: "Sausti küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "305",
      ADS_OID: "AY03779936",
    },
    {
      TASE: "3",
      KOOD: "8034",
      NIMETUS: "Tagadi küla",
      NIMETUS_LIIGIGA: "Tagadi küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "719",
      ADS_OID: "AY03779937",
    },
    {
      TASE: "3",
      KOOD: "3898",
      NIMETUS: "Käre küla",
      NIMETUS_LIIGIGA: "Käre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY03729382",
    },
    {
      TASE: "3",
      KOOD: "8301",
      NIMETUS: "Toomasmäe küla",
      NIMETUS_LIIGIGA: "Toomasmäe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "732",
      ADS_OID: "AY03729383",
    },
    {
      TASE: "3",
      KOOD: "4858",
      NIMETUS: "Meerapalu küla",
      NIMETUS_LIIGIGA: "Meerapalu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY03742652",
    },
    {
      TASE: "3",
      KOOD: "6066",
      NIMETUS: "Pedaspää küla",
      NIMETUS_LIIGIGA: "Pedaspää küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "708",
      ADS_OID: "AY03742653",
    },
    {
      TASE: "3",
      KOOD: "3264",
      NIMETUS: "Kohatu küla",
      NIMETUS_LIIGIGA: "Kohatu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "502",
      ADS_OID: "AY03888645",
    },
    {
      TASE: "3",
      KOOD: "4207",
      NIMETUS: "Lehetu küla",
      NIMETUS_LIIGIGA: "Lehetu küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "725",
      ADS_OID: "AY03888646",
    },
    {
      TASE: "3",
      KOOD: "4599",
      NIMETUS: "Lõhavere küla",
      NIMETUS_LIIGIGA: "Lõhavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY03899214",
    },
    {
      TASE: "3",
      KOOD: "5489",
      NIMETUS: "Nuutre küla",
      NIMETUS_LIIGIGA: "Nuutre küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY03899215",
    },
    {
      TASE: "3",
      KOOD: "6597",
      NIMETUS: "Päraküla",
      NIMETUS_LIIGIGA: "Päraküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY03899216",
    },
    {
      TASE: "3",
      KOOD: "7839",
      NIMETUS: "Suure-Jaani linn",
      NIMETUS_LIIGIGA: "Suure-Jaani linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "615",
      ADS_OID: "AY03899217",
    },
    {
      TASE: "3",
      KOOD: "4376",
      NIMETUS: "Lilleküla",
      NIMETUS_LIIGIGA: "Lilleküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "171",
      ADS_OID: "AY03819404",
    },
    {
      TASE: "3",
      KOOD: "3233",
      NIMETUS: "Kodavere küla",
      NIMETUS_LIIGIGA: "Kodavere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY03804391",
    },
    {
      TASE: "3",
      KOOD: "8176",
      NIMETUS: "Tedreküla",
      NIMETUS_LIIGIGA: "Tedreküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "586",
      ADS_OID: "AY03804392",
    },
    {
      TASE: "3",
      KOOD: "2511",
      NIMETUS: "Kahkva küla",
      NIMETUS_LIIGIGA: "Kahkva küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY03781351",
    },
    {
      TASE: "3",
      KOOD: "6632",
      NIMETUS: "Pääväkese küla",
      NIMETUS_LIIGIGA: "Pääväkese küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY03781352",
    },
    {
      TASE: "3",
      KOOD: "7172",
      NIMETUS: "Rõssa küla",
      NIMETUS_LIIGIGA: "Rõssa küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY03781353",
    },
    {
      TASE: "3",
      KOOD: "1041",
      NIMETUS: "Aasuvälja küla",
      NIMETUS_LIIGIGA: "Aasuvälja küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY03873784",
    },
    {
      TASE: "3",
      KOOD: "3874",
      NIMETUS: "Kändliku küla",
      NIMETUS_LIIGIGA: "Kändliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY03873785",
    },
    {
      TASE: "3",
      KOOD: "3931",
      NIMETUS: "Käru alevik",
      NIMETUS_LIIGIGA: "Käru alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY03873786",
    },
    {
      TASE: "3",
      KOOD: "4155",
      NIMETUS: "Lauri küla",
      NIMETUS_LIIGIGA: "Lauri küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY03873787",
    },
    {
      TASE: "3",
      KOOD: "4477",
      NIMETUS: "Lokuta küla",
      NIMETUS_LIIGIGA: "Lokuta küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY03873788",
    },
    {
      TASE: "3",
      KOOD: "7931",
      NIMETUS: "Särevere alevik",
      NIMETUS_LIIGIGA: "Särevere alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY03873789",
    },
    {
      TASE: "3",
      KOOD: "8078",
      NIMETUS: "Taikse küla",
      NIMETUS_LIIGIGA: "Taikse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY03873790",
    },
    {
      TASE: "3",
      KOOD: "8594",
      NIMETUS: "Türi linn",
      NIMETUS_LIIGIGA: "Türi linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY03873791",
    },
    {
      TASE: "3",
      KOOD: "8597",
      NIMETUS: "Türi-Alliku küla",
      NIMETUS_LIIGIGA: "Türi-Alliku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY03873792",
    },
    {
      TASE: "3",
      KOOD: "9337",
      NIMETUS: "Vilita küla",
      NIMETUS_LIIGIGA: "Vilita küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY03873793",
    },
    {
      TASE: "3",
      KOOD: "9692",
      NIMETUS: "Väätsa alevik",
      NIMETUS_LIIGIGA: "Väätsa alevik",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY03873794",
    },
    {
      TASE: "3",
      KOOD: "9823",
      NIMETUS: "Ülejõe küla",
      NIMETUS_LIIGIGA: "Ülejõe küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "834",
      ADS_OID: "AY03873795",
    },
    {
      TASE: "3",
      KOOD: "1619",
      NIMETUS: "Erastvere küla",
      NIMETUS_LIIGIGA: "Erastvere küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "284",
      ADS_OID: "AY03921902",
    },
    {
      TASE: "3",
      KOOD: "2653",
      NIMETUS: "Kanariku küla",
      NIMETUS_LIIGIGA: "Kanariku küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY03964423",
    },
    {
      TASE: "3",
      KOOD: "6762",
      NIMETUS: "Raiste küla",
      NIMETUS_LIIGIGA: "Raiste küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "917",
      ADS_OID: "AY03964424",
    },
    {
      TASE: "3",
      KOOD: "2647",
      NIMETUS: "Kanaküla",
      NIMETUS_LIIGIGA: "Kanaküla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY03913076",
    },
    {
      TASE: "3",
      KOOD: "6922",
      NIMETUS: "Reinse küla",
      NIMETUS_LIIGIGA: "Reinse küla",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "712",
      ADS_OID: "AY03913077",
    },
    {
      TASE: "3",
      KOOD: "8132",
      NIMETUS: "Tamsalu linn",
      NIMETUS_LIIGIGA: "Tamsalu linn",
      YLEMKOMP_TASE: "2",
      YLEMKOMP_KOOD: "792",
      ADS_OID: "AY03956633",
    },
  ],
};
